import { call, fork, put, select, takeEvery } from "redux-saga/effects";
import { importExportMiddleware, tokenErrorHandler } from "middlewares";
import { importExportActions } from "./actions";
import { UIDates } from "_metronic/_helpers";
import ActionTypes from "./actionTypes";



// Import Example Download
function* importExample({ payload: { data } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(importExportMiddleware.importExample, accessToken, data);

    // CSV dosyasını indir 
    function downloadFile(data, filename) {
      const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
      const linkElement = document.createElement('a');
      linkElement.setAttribute('href', csvContent);
      linkElement.setAttribute('download', filename);
      linkElement.style.display = 'none';
      document.body.appendChild(linkElement);
      linkElement.click();
      document.body.removeChild(linkElement);
    };

    let fileTypeList = {
      customers: {
        de: "KundenImportBeispiel.csv",
        en: "CustomerImportExample.csv",
        tr: "MusteriImportOrnegi.csv"
      },
      products: {
        de: "ProduktImportBeispiel.csv",
        en: "ProductImportExample.csv",
        tr: "UrunImportOrnegi.csv"
      },
      categories: {
        de: "KategorieImportBeispiel.csv",
        en: "CategoryImportExample.csv",
        tr: "KategoriImportOrnegi.csv"
      },
      default: "example.csv"
    };

    let fileName = fileTypeList[data.type]?.[data.locale] || fileTypeList.default;

    downloadFile(response.data, fileName);

    yield put(importExportActions.importExampleSuccess());
  } catch (err) {
    yield call(tokenErrorHandler, err, importExportActions.importExampleFail());
  }
}


// All Export data get
function* getImportExports({ payload: { type, queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);
    const response = yield call(importExportMiddleware.getImportExports, accessToken, type, queryParams);
    const countResponse = yield call(importExportMiddleware.getImportExportsCount, accessToken, type, queryParams);

    yield put(importExportActions.getImportExportsSuccess(response.data, countResponse.data));
  } catch (err) {
    yield call(tokenErrorHandler, err, importExportActions.getImportExportsFail());
  }
}


// Export get
function* getExport({ payload: { export_select, date_range, language, include_archived, ids, queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);
    const response = yield call(importExportMiddleware.getExport, { export_select, date_range, language, include_archived, ids }, accessToken);
    // yield put(importExportActions.getImportExports("export"));
    yield put(importExportActions.getExportSuccess(response.data));
    yield fork(getImportExports, { payload: { type: 'export', queryParams } });
  } catch (err) {
    const errMessage = err.response.data.error
    yield call(tokenErrorHandler, err, importExportActions.getExportFail(errMessage));
  }
}


// Export delete
function* importExportDelete({ payload: { id, type, queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);
    yield call(importExportMiddleware.importExportDelete, accessToken, id);
    yield put(importExportActions.importExportDeleteSuccess(id));
    yield fork(getImportExports, { payload: { type, queryParams } });

  } catch (err) {
    yield call(tokenErrorHandler, err, importExportActions.importExportDeleteFail());
  }
}


// Import Example Download
function* importExportDownload({ payload: { data, locale } }) {
  try {

    const accessToken = yield select(state => state.auth.access_token);
    const response = yield call(importExportMiddleware.importExportDownload, accessToken, data.id);
    function downloadFile(data, filename) {
      const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
      const linkElement = document.createElement('a');
      linkElement.setAttribute('href', csvContent);
      linkElement.setAttribute('download', filename);
      linkElement.style.display = 'none';
      document.body.appendChild(linkElement);
      linkElement.click();
      document.body.removeChild(linkElement);
    };

    let fileTypeList = {
      customers: {
        de: "kunden",
        en: "customers",
        tr: "musteriler"
      },
      products: {
        de: "produkte",
        en: "products",
        tr: "urunler"
      },
      categories: {
        de: "kategorien",
        en: "categories",
        tr: "kategoriler"
      },
      datev: {
        de: "DATEV",
        en: "DATEV",
        tr: "muhasebe"
      },
      accounting: {
        de: "buchhaltung",
        en: "accounting",
        tr: "muhasebe"
      },
      invoices: {
        de: "rechnungs",
        en: "invoices",
        tr: "faturalar"
      },
      credits: {
        de: "gutschriften",
        en: "credits",
        tr: "krediler"
      },
      offers: {
        de: "angebote",
        en: "offers",
        tr: "teklifler"
      },
      orders: {
        de: "bestellung",
        en: "orders",
        tr: "siparisler"
      },
      default: "default"
    };

    let fileType = fileTypeList[data.module]?.[locale] || data.type;

    downloadFile(response.data, `${data.type}_${fileType}_${UIDates.exportName(data.created_at)}.csv`);

    yield put(importExportActions.importExportDownloadSuccess());
  } catch (err) {
    yield call(tokenErrorHandler, err, importExportActions.importExportDownloadFail(err));
  }
}


// Product Import
function* importProductCreate({ payload: { data, language } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(importExportMiddleware.importProductCreate, accessToken, data, language);

    yield put(importExportActions.importProductCreateSuccess(data?.length));

  } catch (err) {
    yield call(tokenErrorHandler, err, importExportActions.importProductCreateFail());
  }
}


// Customer Import
function* importCustomerCreate({ payload: { data, language } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(importExportMiddleware.importCustomerCreate, accessToken, data, language);

    yield put(importExportActions.importCustomerCreateSuccess(data?.length));

  } catch (err) {
    yield call(tokenErrorHandler, err, importExportActions.importCustomerCreateFail());
  }
}


// Customer Import
function* importCategoryCreate({ payload: { data, language } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(importExportMiddleware.importCategoryCreate, accessToken, data, language);

    yield put(importExportActions.importCategorySuccess(data?.length));

  } catch (err) {
    yield call(tokenErrorHandler, err, importExportActions.importCategoryCreateFail());
  }
}


// check data
function* checkData({ payload: { module } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const { data } = yield call(importExportMiddleware.checkData, accessToken, module);

    yield put(importExportActions.checkDataSuccess(data));

  } catch (err) {
    yield call(tokenErrorHandler, err, importExportActions.checkDataFail());
  }
}



export function* importExportSaga() {
  yield takeEvery(ActionTypes.IMPORT_EXAMPLE, importExample);
  yield takeEvery(ActionTypes.IMPORT_EXPORTS_GET, getImportExports);
  yield takeEvery(ActionTypes.EXPORT_GET, getExport);
  yield takeEvery(ActionTypes.IMPORT_EXPORT_DELETE, importExportDelete);
  yield takeEvery(ActionTypes.IMPORT_EXPORT_DOWNLOAD, importExportDownload);
  yield takeEvery(ActionTypes.IMPORT_PRODUCT, importProductCreate);
  yield takeEvery(ActionTypes.IMPORT_CUSTOMER, importCustomerCreate);
  yield takeEvery(ActionTypes.IMPORT_CATEGORY, importCategoryCreate);
  yield takeEvery(ActionTypes.CHECK_DATA, checkData);
}
