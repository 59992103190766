import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import { sortCaret, headerSortingClasses, NoRecordsFoundMessage, getSelectRow, UIDates } from '_metronic/_helpers';
import { useCustomersUIContext } from '../_context/CustomersUIContext';
import { RemotePagination } from '_metronic/_partials/controls';
import * as columnFormatters from './ActionsColumnFormatter';
import * as uiHelpers from '../_context/CustomersUIHelpers';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { Icon } from '_metronic/_icons';
import { useIntl } from 'react-intl';



export function CustomersTable({ entities, countCustomers, loading, queryParams, setPaginationQuery, setOrderQuery, setSearchQuery, setFilterQuery }) {

  const { formatMessage: intl } = useIntl();

  // Customers UI Context
  const UIContext = useCustomersUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    openCustomerDetailsDialog: UIContext.openCustomerDetailsDialog
  }), [UIContext]);


  const COLUMNS = [
    {
      dataField: 'number',
      text: intl({ id: 'GENERAL.NO' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'pl-4',
      headerStyle: { minWidth: "40px", width: '5%' },
      classes: 'pl-3',
      formatter: (cell, row) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a type="button" className="text-decoration-none text-muted text-hover-primary text-nowrap preview" onClick={() => UIProps.openCustomerDetailsDialog(row.id)} id='btn_customer_preview'>
          <span className='font-weight-bold'>{cell}</span>
          <span className='invisible text-dark-25 svg-icon svg-icon-sm pl-2'>
            <Icon.FileEarmarkText />
          </span>
        </a>
      ),
    },
    {
      dataField: 'name',
      text: intl({ id: 'GENERAL.NAME' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: "140px", width: '20%' },
      classes: 'font-weight-bold',
      formatter: (cell, row) => (
        <Link to={ModuleRoutes.EDIT_CUSTOMER_FN(row.id)} type="button" className=" text-decoration-none text-dark text-hover-primary text-nowrap preview" id='btn_customer_preview'>
          <div className='d-flex align-items-center flex-row'>
            <span className='font-weight-bolder text-dark-75 font-size-lg'>{cell}</span>
            <span className='d-flex invisible text-dark-25 svg-icon svg-icon-sm pl-2'>
              <Icon.Pencil />
            </span>
          </div>
          <div className="font-weight-bold text-dark-50">
            {row.company}
          </div>
        </Link>
      ),
    },
    {
      dataField: 'email',
      text: intl({ id: 'GENERAL.EMAIL' }),
      formatter: (cell, row) => (<a href={`mailto:${cell}`} className="text-decoration-none text-dark text-hover-primary">{cell}</a>)
    },
    {
      dataField: 'phone',
      text: intl({ id: 'GENERAL.PHONE' }),
      formatter: (cell, row) => (
        <>
          <a href={`tel:${cell}`} className="d-block text-decoration-none text-dark-75 text-hover-primary mb-1">{cell}</a>
          <a href={`tel:${row.mobile}`} className="d-block text-decoration-none text-dark-75 text-hover-primary">{row.mobile}</a>
        </>
      )
    },
    {
      dataField: 'address[0].country_code',
      text: intl({ id: 'GENERAL.ADDRESS' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap',
      formatter: (cell, row) => {
        const address = row.address.find((item) => item.type === "address_delivery") ?? row.address[0];
        return (
          <div className='d-flex flex-column font-weight-bold mr-1'>
            <span className='text-nowrap'>
              {address?.city}
            </span>
            <span className='text-dark-50'>
              {address?.country_code + '-' + address?.post_code}
            </span>
          </div>
        );
      },
    },
    {
      dataField: 'discount',
      text: intl({ id: 'GENERAL.DISCOUNT' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '50px', width: "8%" },
      headerClasses: 'text-center text-nowrap pl-5',
      classes: 'text-center text-nowrap pr-6',
      formatter: (cell, row) => (cell > 0 ?
        <div className='label label-xl label-inline label-rounded font-weight-bolder label-light-warning text-warning px-2'>
          <span className="label label-xl label-dot mr-2 label-warning"></span>
          {cell}%
        </div>
        : null),
    },
    {
      dataField: 'source.marketplace',
      text: intl({ id: 'CUSTOMER.TABLE.SOURCE' }),
      headerClasses: 'text-center',
      headerStyle: { minWidth: '50px', width: '5%' },
      classes: 'text-center',
      formatter: columnFormatters.SourceColumnFormatter,
    },
    {
      dataField: 'created_at',
      text: intl({ id: 'GENERAL.REGISTRATION_DATE' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center text-nowrap pl-5',
      headerStyle: { minWidth: '100px', width: '8%' },
      classes: 'text-center',
      formatter: (cell, row) => (
        <div className='font-size-sm text-dark-50 font-weight-bold mb-1'>
          {UIDates.formatDateTime(cell)}
        </div>
      )
    },
    {
      dataField: 'action',
      text: intl({ id: 'GENERAL.ACTIONS' }),
      headerClasses: 'text-right pr-3',
      headerStyle: { minWidth: '100px', width: '8%' },
      classes: 'text-right text-nowrap pr-0',
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditCustomerDialog: (id) => ModuleRoutes.EDIT_CUSTOMER_FN(id),
        openDeleteCustomerDialog: (id) => ModuleRoutes.DELETE_CUSTOMER_FN(id),
        openSetDocumentDialog: (id) => ModuleRoutes.SET_DOCUMENT_CUSTOMER_FN(id),
      },
    },
  ];


  return (
    <>
      <BootstrapTable
        keyField="id"
        key={UIProps.ids}
        data={entities || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        striped
        bordered={false}
        condensed
        defaultSorted={uiHelpers.defaultSorted}
        noDataIndication={<NoRecordsFoundMessage loading={loading} onClick={() => { setSearchQuery(''); setFilterQuery('DELETE_PARAMS', ["marketplace"]) }} />}
        selectRow={getSelectRow({
          ids: UIProps.ids,
          setIds: UIProps.setIds,
          entities: entities,
        })}
      />
      <RemotePagination
        queryParams={queryParams.pagination}
        setQueryParams={setPaginationQuery}
        totalCount={countCustomers}
        entitiesCount={entities?.length}
        loading={loading}
      />
    </>
  );
}
