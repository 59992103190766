import React, { useMemo } from 'react';
import { CategorySelectDialog } from './category-select-dialog/CategorySelectDialog';
import { useProductEditUIContext } from '../../_context/ProductEditUIContext';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { SVFormControlLabel } from '_metronic/_partials/controls';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';




export function CategoryAdd({ loading, keys }) {

  const UIContext = useProductEditUIContext();
  const UIProps = useMemo(() => ({
    showCategorySelectDialog: UIContext.showCategorySelectDialog,
    handleCloseCategorySelectDialog: UIContext.handleCloseCategorySelectDialog,
    handleOpenCategorySelectDialog: UIContext.handleOpenCategorySelectDialog,
    selectedKategorie: UIContext.selectedKategorie,
    setSelectedKategorie: UIContext.setSelectedKategorie,
  }),
    [
      UIContext.handleCloseCategorySelectDialog,
      UIContext.handleOpenCategorySelectDialog,
      UIContext.selectedKategorie,
      UIContext.showCategorySelectDialog,
      UIContext.setSelectedKategorie,
    ]
  );

  const showTooltip = keys.length > 0;


  return (
    <Row>
      <Col>

        <SVFormControlLabel>
          <FormattedMessage id="PRODUCT.CATEGORIES" />
          {/* {info && <UserInfoDropdown size="w-75" title={label} description={info} />} */}
        </SVFormControlLabel>

        <div className="d-flex align-items-center border-top pt-4">

          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-select"><FormattedMessage id={showTooltip ? "PRODUCT.CATEGORY.KEYS_WARNING" : "PRODUCT.CATEGORY_SELECT"} /></Tooltip>} disabled={!showTooltip}>
            <Button variant="outline-secondary" size="sm" className="font-weight-bold svg-icon svg-icon-sm" onClick={UIProps.handleOpenCategorySelectDialog} id="btn_select" disabled={loading || showTooltip}>
              <Icon.Plus />
              <span className="d-none d-sm-inline ml-2">
                <FormattedMessage id={UIProps.selectedKategorie?.path ? "PRODUCT.CATEGORY_CHANGE" : "PRODUCT.CATEGORY_SELECT"} />
              </span>
            </Button>
          </OverlayTrigger>

          {(UIProps.selectedKategorie?.path) && (
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-close"><FormattedMessage id={showTooltip ? "PRODUCT.CATEGORY.KEYS_WARNING" : "GENERAL.DELETE"} /></Tooltip>} disabled={!showTooltip}>
              <Button variant="link" className="label label-inline label-light-primary label-xl ml-5" onClick={() => UIProps.setSelectedKategorie({})} id="btn_close" disabled={loading || showTooltip}>
                <span>{UIProps.selectedKategorie.path}</span>
                <Icon.Close className="icon-sm ml-3" />
              </Button>
            </OverlayTrigger>
          )}

          <CategorySelectDialog show={UIProps.showCategorySelectDialog} onHide={UIProps.handleCloseCategorySelectDialog} />

        </div>

      </Col>
    </Row>
  );
};