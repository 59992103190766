import React from 'react';
import { SearchQueryProvider } from './_context/SearchQueryProvider';
import { ShippingDeleteDialog } from './shippings-dialog/ShippingDeleteDialog';
import { PrintLabelsDialog } from './shippings-dialog/PrintLabelsDialog';
import { ShippingsCard } from './ShippingsCard';



export default function ShippingsPage() {
  return (
    <SearchQueryProvider>
      <PrintLabelsDialog />
      <ShippingDeleteDialog />
      <ShippingsCard />
    </SearchQueryProvider>
  );
}