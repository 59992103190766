import React from 'react'
import { FormattedMessage } from 'react-intl'



export default function Introduction() {
  return (
    <>
      <div className="d-flex justify-content-center mb-15">
        <img src="/media/logo/salevali-logo-dark-beta.svg" alt="logo" style={{ width: '40%' }} />
      </div>

      <div className="alert alert-custom alert-light-info p-10 mb-5">
        <div className="font-weight-bold text-justify line-height-xl">
          <div><FormattedMessage id="FIRST_LOGIN.TITLE" /></div>
          <div><FormattedMessage id="FIRST_LOGIN.DESCRIPTION" /></div>
        </div>
      </div>
    </>
  )
}
