import React from 'react';
import styled from 'styled-components';
import { theme } from '_metronic/_helpers';
import CreatableSelect from 'react-select/creatable';
import { SVFeedbackLabel, SVFormControlLabel, SVFormControlWrapper } from './SVFormHelpers';



export function SVReactSelectCreatable({
  label,
  options,
  placeholder,
  formatCreateLabel,
  withFeedbackLabel,
  value,
  onChange,
  isClearable,
  isSearchable,
  isRtl,
  feedbackLabel,
  field,
  form: { setFieldValue, touched, errors, setFieldTouched },
  className,
  disabled,
  ...props
}) {
  return (
    <SVFormControlWrapper className={className}>

      {label && (
        <SVFormControlLabel errors={errors[field.name] && true}>
          {label}
        </SVFormControlLabel>
      )}

      <StyledReactSelect
        {...field}
        {...props}
        fieldError={touched[field.name] && errors[field.name] ? true : false}
        options={options}
        value={value}
        formatCreateLabel={formatCreateLabel}
        onChange={onChange}
        isClearable={isClearable}
        isSearchable={isSearchable}
        isRtl={isRtl}
        placeholder={placeholder}
        isDisabled={disabled}
      />

      <SVFeedbackLabel withFeedbackLabel={withFeedbackLabel} errors={errors[field.name] && true}>
        {errors[field.name] ? errors[field.name] : feedbackLabel}
      </SVFeedbackLabel>

    </SVFormControlWrapper>
  );
}



const StyledReactSelect = styled(CreatableSelect).attrs({
  theme: style => ({
    ...style,
    borderRadius: theme.shape.borderRadius,
    colors: {
      ...style.colors,
      primary: theme.palette.primary['400'],
      primary25: theme.palette.primary['100'],
      primary50: theme.palette.primary['200'],
      primary75: theme.palette.primary['300'],
      danger: theme.palette.danger['color'],
    },
  }),
  styles: {
    control: (styles, state) => ({
      // [state.selectProps] is reaching down to the props of the Select component
      ...styles,
      height: theme.units.input.height['md'],
      border: state.selectProps.fieldError
        ? `1px solid ${theme.palette.danger['color']}`
        : `1px solid ${theme.palette.gray['300']}`,
      boxShadow: theme.shadows.input,
      '&:hover': {
        cursor: 'context-menu',
        outline: `1px solid ${theme.palette.primary['100']}`,
      },
      '&:focus': {
        outline: `1px solid ${theme.palette.primary['300']}`,
        boxShadow: theme.shadows.inputHover,
      },
      backgroundColor: state.isDisabled && theme.palette.gray['50'],
    }),
    menu: base => ({ ...base, zIndex: 1000 }),
  },
})``;
