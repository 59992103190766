import React from "react";
import { FormattedMessage } from 'react-intl';



export const checkImportCategory = (values, checkData) => {

  const errors = [];
  const createError = (field, message, required = true) => {
    errors.push({ field, message, required });
  };

  for (let i = 0; i < values.length; i++) {

    const { id, name, parent_id, level, path } = values[i];


    const checkId = checkData?.includes(id);

    if (!id  || checkId) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CATEGORY.ALERT_DIALOG.CATEGORY_ID" />);
    };

    if (parent_id.length > 200) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CATEGORY.ALERT_DIALOG.CATEGORY_PARENT_ID" />);
    };

    if (level.length > 50) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CATEGORY.ALERT_DIALOG.CATEGORY_LEVEL" />);
    };

    if (name.length > 500) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CATEGORY.ALERT_DIALOG.CATEGORY_NAME" />);
    };

    if (path.length > 500) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CATEGORY.ALERT_DIALOG.CATEGORY_PATH" />);
    };

  }
  return errors;
};
