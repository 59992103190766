import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useDocumentsUIContext } from '../_context/DocumentsUIContext';
import { convertPriceCurrency } from '_metronic/_helpers';
import { CloseButton } from "_metronic/_partials";
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import { Portal } from 'react-portal';



export function OverPaymentInfoDialog() {

  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showOverPaymentInfoDialog.show,
    onHide: UIContext.closeOverPaymentInfoDialog,
    price: UIContext.showOverPaymentInfoDialog.price,
    currency: UIContext.showOverPaymentInfoDialog.currency,
  }),
    [
      UIContext.closeOverPaymentInfoDialog,
      UIContext.showOverPaymentInfoDialog,
    ]
  );


  return (
    <Portal node={document && document.getElementById('modal-portal')}>
      <Modal show={UIProps.show} onHide={UIProps.onHide} size="md" backdrop="static" aria-labelledby="example-modal-sizes-title-md" centered>

        <CloseButton onClick={UIProps.onHide} className="position-absolute top-0 right-0 mr-10 mt-10" />

        <div className="d-flex justify-content-center icon-4x pt-10">
          <Icon.InfoCircle className="text-info" />
        </div>

        <Modal.Body className="text-center line-height-lg h6 py-10 mb-0">
          <FormattedMessage id='DOCUMENT.EDIT.PAYMENT.OVER_PAYMENT' values={{ amount: convertPriceCurrency(UIProps.price, UIProps.currency) }} />
        </Modal.Body>

      </Modal>
    </Portal>
  );
}