import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAmazonItem } from '../_redux/reducer';
// components
import { useProductListingsUIContext } from '../../_context/ProductListingsUIContext';
import { AmazonListingsAddProductHeader } from './AmazonListingsAddProductHeader';
import { Icon } from '_metronic/_icons';
import { Card, CardBody, CardSubHeader, SVInput } from '_metronic/_partials/controls';
import { AmazonSuggestedProductList } from '../amazon-suggested-product-list/AmazonSuggestedProductList';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';
import { AmazonListingsActions } from '../_redux/actions';
import { AmazonListingsAddProductForm } from './AmazonListingsAddProductForm';
import { toast } from 'react-toastify';

const setupInitialValues = (product) => {
  if (!product)
    return {
      sku: '',
      price: 0,
      quantity: 0,
      condition: 'new_new',
      fulfillmentStrategy: 'FBM'
    };
  return {
    sku: product.sku,
    price: product.pricing.gross_price,
    quantity: product.quantity,
    condition: 'new_new',
    fulfillmentStrategy: 'FBM'
  };
};

export function AmazonListingsSearchProduct({ match: { params: { tokenId } } }) {

  const dispatch = useDispatch();

  const productListingsUIContext = useProductListingsUIContext();
  const UIProps = useMemo(() => ({
    backToMainPage: productListingsUIContext.backToMainPage
  }),
    [productListingsUIContext.backToMainPage]
  );

  // get amazon item from store
  const item = useSelector(selectAmazonItem);

  const onSearchSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const asin = formData.get('searchProductByASIN');
    if (!asin) {
      toast.error('Please enter ASIN number to search product on Amazon');
      return;
    }
    dispatch(AmazonListingsActions.amazonFindListingItemRequest({ asin, tokenId }));
  };

  const onAddListingToAmazon = useCallback(
    (values, action) => {
      const payload = {
        tokenId: tokenId,
        sku: values.sku,
        productType: values.productType,
        asin: values.asin,
        attributes: {
          merchant_suggested_asin: values.asin,
          condition_type: values.condition,
          fulfillment_availability: values.fulfillmentStrategy,
          merchant_shipping_group: 'legacy-template-id',
          purchasable_offer: {
            currency: 'EUR',
            price: values.price
          },
          quantity: values.quantity
        }
      };
      dispatch(AmazonListingsActions.amazonCreateListingRequest(payload));
    },
    [dispatch, tokenId]
  );

  return (
    <>
      {/* <small>B07BS89D3Q</small> */}
      <Card className="card-box">
        <AmazonListingsAddProductHeader backToMainPage={UIProps.backToMainPage} showSaveBtn={false} />

        <Card className="gutter-b">
          <CardSubHeader title={'Search product on Amazon'} icon={<Icon.Tag />} id="IdSearchProductOnAmazon" />
          <CardBody>
            <form className="row" onSubmit={onSearchSubmit}>
              <div className="col-sm-12 col-xl-6 d-flex flex-row" style={{ maxWidth: '768px' }}>
                <SVInput type="text" name="searchProductByASIN" placeholder="Give a ASIN number to search product on Amazon" />
                <Button variant="primary" type="submit" className="font-weight-bold ml-2">
                  <FormattedMessage id="GENERAL.SEARCH" />
                </Button>
              </div>
            </form>
          </CardBody>
        </Card>

        {item && (
          <Card className="gutter-b">
            <CardBody>
              <div className="row justify-content-between flex-sm-row-reverse">
                {/* Item info */}
                <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                  <AmazonSuggestedProductList item={item} />
                </div>

                {/* Add product form */}
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <AmazonListingsAddProductForm onAddListingToAmazon={onAddListingToAmazon} item={item} initialValues={setupInitialValues()} />
                </div>
              </div>
            </CardBody>
          </Card>
        )}
      </Card>
    </>
  );
}
