import React from 'react';
import { SVFormControlLabel } from './sv-ui/SVFormHelpers';
import UserInfoDropdown from '_metronic/_partials/user-info/UserInfoDropdown';



export const SwitchControl = ({
  id,
  field,
  size = 'sm',
  label,
  type,
  readOnly,
  checked,
  onChange,
  onFocus,
  disabled,
  className,
  info,
  description,
  defaultValue,
  ...props
}) => {
  if (type === 'mega') {
    return (
      <label className="option mb-5">
        <span className="option-label">
          <span className="option-head">

            <SVFormControlLabel className="option-title">
              {label}
              {info && <UserInfoDropdown size="w-75" description={info} />}
            </SVFormControlLabel>

            <span className="option-focus">
              <span className="option-control">
                <span className={`switch switch-icon switch-${size}`}>
                  <input type='checkbox' name={field.name} id={field.name} checked={field.value ?? defaultValue} {...field} />
                  <span />
                </span>
              </span>
            </span>

          </span>

          {description &&
            <span className="option-body">
              <span className="option-desc">
                {description}
              </span>
            </span>
          }

        </span>
      </label>
    );
  } else {
    return (
      <label className="d-flex mb-3">

        <span className={`switch switch-icon switch-${size} mr-3`}>
          <input type='checkbox' name={field.name} id={field.name} checked={field.value ?? defaultValue} onChange={onChange} {...field} />
          <span />
        </span>
        <SVFormControlLabel className="option-title mb-0">
          {label}
          {info && <UserInfoDropdown size="w-75" description={info} />}
        </SVFormControlLabel>

      </label>
    );
  }
};