import { call, put, select, takeEvery } from "redux-saga/effects";
import { shippingsMiddleware } from "middlewares/shipping/middleware";
import { AuthActions } from "app/pages/auth/_redux/actions";
import { ShippingsActions } from "./actions";
import { tokenErrorHandler } from "middlewares";
import ActionTypes from "./actionTypes";




// Get Shippings
function* getShippings({ payload: { queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const countShipping = yield call(shippingsMiddleware.getCount, accessToken, queryParams);
    const response = yield call(shippingsMiddleware.getShippings, accessToken, queryParams);
    yield put(ShippingsActions.getShippingsSuccess(response.data, countShipping.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, ShippingsActions.getShippingsFail());
  }
}


// Get Shipping
function* getShipping({ payload: { shippingIds } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(shippingsMiddleware.getShipping, accessToken, shippingIds);
    yield put(ShippingsActions.getShippingSuccess(response.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, ShippingsActions.getShippingFail());
  }
}


// Delete Shipping
function* deleteShipping({ payload: { shippingId } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(shippingsMiddleware.deleteShipping, accessToken, shippingId);
    yield put(ShippingsActions.deleteShippingSuccess(shippingId));

  } catch (err) {
    if (err.response && err.response.status === 401) {
      return yield put(AuthActions.logout());
    }
    yield put(ShippingsActions.deleteShippingFail(err));
  }
}



export function* shippingSaga() {
  yield takeEvery(ActionTypes.GET_SHIPPINGS, getShippings);
  yield takeEvery(ActionTypes.GET_SHIPPING, getShipping);
  yield takeEvery(ActionTypes.DELETE_SHIPPING, deleteShipping);
}
