import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { UserUIProvider } from './_context/UserUIContext';
import { AdminRoutes, RELATIVE_PATH } from 'constants/moduleRoutes';
import { NoteEditDialog } from './user-edit/note/NoteEditDialog';
import { ContentRoute } from '_metronic/layout';
import { UserEdit } from './user-edit/UserEdit';
import { UserPage } from './UserPage';



export default function User() {
  return (
    <UserUIProvider>
      <NoteEditDialog />

      <Switch>
        <Redirect exact={true} from={RELATIVE_PATH} to={AdminRoutes.ADMIN_USERS} />
        <ContentRoute path={AdminRoutes.ADMIN_USERS_EDIT} component={UserEdit} />
        <ContentRoute path={AdminRoutes.ADMIN_USERS} component={UserPage} />
      </Switch>

    </UserUIProvider>
  );
}
