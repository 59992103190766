import React from 'react';
import { KauflandListingsCard } from './KauflandListingsCard';
import { KauflandSyncAlertDialog } from './dialogs/KauflandSyncAlertDialog';


export function KauflandListingsPage() {
  return (
    <>
      <KauflandSyncAlertDialog />
      <KauflandListingsCard />
    </>
  );
}
