/* Pagination Helpers */
import React from "react";
import { Icon } from '_metronic/_icons';



export const sortCaret = (order, column) => {

  if (!order)
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary svg-icon-sort text-dark-25">
        <Icon.ArrowDown />
      </span>
    );
  else if (order)
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary">
        {order === "asc" && <Icon.ArrowUp />}
        {order === "desc" && <Icon.ArrowDown />}
      </span>
    );

  return null;
};

export const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => (
  (sortOrder === 'asc' || sortOrder === "desc") ? 'sortable-active' : ''
);