import React, { useMemo } from 'react'
import { AddressFilter } from './AddressFilter'
import { AddressTable } from './AddressTable'
import { AddressEditDialog } from './edit/AddressEditDialog'
import { useCustomersUIContext } from '../../_context/CustomersUIContext';
import { Icon } from '_metronic/_icons';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import clsx from 'clsx';



export function AddressForm({ type }) {

  const UIContext = useCustomersUIContext();
  const UIProps = useMemo(() => ({
    address: UIContext.address,
  }), [UIContext.address,]);

  const { errors } = useFormikContext();

  const errorStyle = (Object.keys(errors).length && UIProps.address.length === 0) ? "text-danger" : "text-gray"

  return (
    <>
      {UIProps.address.length === 0
        ? <Row>
          <Col md="8" lg="6" className='ml-auto'>

            <div className={clsx("d-flex align-items-center justify-content-center", errorStyle)}>
              <div className="svg-icon svg-icon-3x text-gray mr-3">
                <Icon.GeoAlt className={errorStyle} />
              </div>
              <div className='text-left'>
                <h4 className="font-weight-normal mb-0">
                  <FormattedMessage id='CUSTOMER.ADDRESS.BLANK_MESSAGE_TITLE' />
                </h4>
                <FormattedMessage id='CUSTOMER.ADDRESS.BLANK_MESSAGE' />
              </div>
            </div>

          </Col>
          <Col md="4" lg="4" className='d-flex flex-row align-items-center justify-content-end text-gray'>
            <Icon.HandArrowRight className={clsx("mr-5", errorStyle)} />
            <AddressFilter />
          </Col>

        </Row>
        : <AddressTable type={type} />
      }

      <AddressEditDialog />
    </>
  )
}
