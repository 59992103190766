import React, { useMemo } from "react";
import { useProductEditUIContext } from "../../_context/ProductEditUIContext";
import { Card, CardBody, CardSubHeader } from "_metronic/_partials/controls";
import { FeatureEditDialog } from "./feature-edit-dialog/FeatureEditDialog";
import { FormattedMessage } from "react-intl";
import { FeaturesTable } from "./FeaturesTable";
import { Button } from "react-bootstrap";
import { Icon } from '_metronic/_icons';



export function MoreFeatures({ loading }) {

  const UIContext = useProductEditUIContext();
  const UIProps = useMemo(() => ({
    openEditFeatureDialog: UIContext.openEditFeatureDialog
  }), [
    UIContext.openEditFeatureDialog,
  ]);


  return (
    <Card className="sub-card-height">

      <CardSubHeader title={<FormattedMessage id="PRODUCT.MORE_FEATURES" />} icon={<Icon.Tags />} id="more-features">

        <Button variant="outline-primary" size="sm" className='font-weight-bold svg-icon svg-icon-sm' onClick={() => UIProps.openEditFeatureDialog()} disabled={loading} id="btn_feature">
          <Icon.Plus />
          <span className="d-none d-sm-inline ml-2">
            <FormattedMessage id="PRODUCT.FEATURES.NEW" />
          </span>
        </Button>
      </CardSubHeader>

      <CardBody className='px-0 pt-0'>

        <FeaturesTable loading={loading} />
        <FeatureEditDialog />

      </CardBody>

    </Card>
  );
}
