import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getShipperName } from "app/modules/documents/_context/DocumentsUIHelpers";
import { useEmailLogsUIContext } from "../../_context/EmailLogsUIContext";
import { ShippingsActions } from "app/modules/shippings/_redux/actions";
import { PdfViewer } from "_metronic/_partials/components";
import { FormattedMessage, useIntl } from "react-intl";
import { CloseButton } from "_metronic/_partials";
import PDFMerger from "pdf-merger-js/browser";
import { Modal } from "react-bootstrap";
import { Portal } from "react-portal";
import { isArray } from "lodash";



export function PrintLabelsDialog() {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl()

  const UIContext = useEmailLogsUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showPrintLabel,
    onHide: UIContext.closePrintLabelsDialog,
    shipper: UIContext.shipperName,
  }), [
    UIContext.showPrintLabel,
    UIContext.closePrintLabelsDialog,
    UIContext.shipperName,
  ]);


  const { labels } = useSelector(state => ({
    labels: state.shippings.labels,
  }), shallowEqual);


  const [errorStacks, setErrorStacks] = useState([]);
  const [url, setUrl] = useState();


  const onHideCallBack = () => {
    UIProps.onHide();
    setUrl(undefined);
    setErrorStacks([]);
    dispatch(ShippingsActions.cleanStatesInStore({ labels: [] }));
  };


  useEffect(() => {

    const getBlob = async (label) => {
      if (label.includes("data:application/pdf;base64,")) {
        label = label.substr("data:application/pdf;base64,".length);
      }
      const bytes = window.atob(label);
      let length = bytes.length;
      let out = new Uint8Array(length);
      while (length--) { out[length] = bytes.charCodeAt(length) }
      const blob = new Blob([out], { type: "application/pdf" });
      return blob;
    };

    const render = async (labelArray) => {
      const promiseList = [];
      for (const label of labelArray) {
        promiseList.push(
          new Promise((resolve, reject) => {
            getBlob(label.label).then((urls) => resolve(urls)).catch((err) => reject(err));
          })
        );
      }

      const settledBlobs = await Promise.all(promiseList);
      const merger = new PDFMerger();
      await Promise.all(settledBlobs.map(async (file) => await merger.add(file)));
      const mergedPdf = await merger.saveAsBlob();
      const urls = URL.createObjectURL(mergedPdf);
      setUrl(urls);
    };

    if (isArray(labels) && labels.length) {
      const labelFilterByStatusOk = labels.filter((lbl) => !lbl.error && !lbl.unlabeled);
      const labelFilterByStatusError = labels.filter((lbl) => lbl.error || lbl.unlabeled);

      if (labelFilterByStatusOk.length) {
        render(labelFilterByStatusOk).catch((err) => { console.error("error", err) });
      };

      if (labelFilterByStatusError.length) {
        setUrl(undefined);
        const errors = labelFilterByStatusError.map((err) => ({
          message: err.unlabeled ? <FormattedMessage id="DOCUMENT.SHIPPING.PRINT_LABEL.MANUEL_WARNING" values={{ tracking_id: err.tracking_id }} /> : err.error,
          service: err.service,
          documentId: err.relation_documents.map(i => i.id).join(", ") || null,
          unlabeled: err.unlabeled
        }));
        setErrorStacks(errors);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, labels, UIProps.shipper]);


  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={UIProps.show} onHide={onHideCallBack} backdrop="static" keyboard={false} aria-labelledby="example-modal-sizes-title-xl" size="lg">

        <Modal.Header>

          <Modal.Title>
            {(UIProps?.shipper !== "all-labels" ? (getShipperName(UIProps?.shipper) + (labels?.[0]?.type === "return" ? " " + intl({ id: "DOCUMENT.SHIPPING.TYPE_RETURN" }) : "")) : '') + (labels?.length <= 1 ? " Label" : " Labels")}
          </Modal.Title>

          <div className="d-flex">

            <CloseButton onClick={onHideCallBack} />

          </div>

        </Modal.Header>

        {url && <PdfViewer url={url} height="80vh" />}

        <Modal.Body>
          {errorStacks.length > 0 &&
            errorStacks.map((err, i) => (
              <div key={i} className="text-center" style={{ minHeight: '90px' }}>

                <div>
                  <span><FormattedMessage id="CONNECTIONS.SHIPPER" /> : </span>
                  <span>{getShipperName(err?.service)}</span>
                </div>
                <div>
                  <span><FormattedMessage id="DOCUMENT.SHIPPING.PRINT_LABEL.DOCUMENT_ID" /> : </span>
                  <span >{err?.documentId || "---"}</span>
                </div>
                <div className={`${err.unlabeled ? 'text-warning' : 'text-danger'}`}>
                  <span>{err?.message}</span>
                </div>
              </div>
            ))}
        </Modal.Body>

      </Modal>
    </Portal>
  );
}
