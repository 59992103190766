import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { sortCaret, headerSortingClasses, NoRecordsFoundMessage, UIDates } from '_metronic/_helpers';
import { allQueryKeys, TICKET_STATUS, TICKET_TAG, TICKET_PRIORITY, getTopicLabel } from '../_context/SupportUIHelper';
import { ActionsColumnFormatter } from './ActionsColumnFormatter';
import { RemotePagination } from '_metronic/_partials/controls';
import { FormattedMessage, useIntl } from 'react-intl';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { Icon } from '_metronic/_icons';
import clsx from 'clsx';



export function SupportTable({ loading, UIProps, role }) {

  const { formatMessage: intl } = useIntl();

  const { tickets, countTickets } = useSelector(state => ({
    tickets: state.supports.entities,
    countTickets: state.supports.countTickets,
  }));


  const COLUMNS = [
    {
      dataField: 'subject',
      text: intl({ id: 'SUPPORT.TABLE.TICKET' }),
      sort: true,
      sortCaret,
      headerSortingClasses,
      onSort: UIProps.setOrderQuery,
      headerStyle: { minWidth: "200px" },
      classes: 'font-weight-bold',
      formatter: (cell, row) => {
        const status = TICKET_STATUS.find(status => status.value === row.status);
        return (
          <div className="d-flex align-items-center">

            <OverlayTrigger overlay={<Tooltip id="priority-tooltip">{status?.label}</Tooltip>}>
              <span className={clsx("label label-xl label-dot mx-3", `label-${status?.className}`)} />
            </OverlayTrigger>

            <div>
              <Link className="text-dark-75 text-hover-primary preview" to={ModuleRoutes.VIEW_TICKET_FN(row.id)} id="btn_support_view">
                <span className={clsx(`font-weight-bolder`, role === "644c41e257d397eb064fe0f9" ? !row.seen && "text-primary" : !row.read && "text-primary")}>{cell}</span>
                <span className="text-dark-25 svg-icon svg-icon-sm invisible pl-2">
                  <Icon.LeftText />
                </span>
              </Link>
              <div className="font-weight-bold text-dark-50 text-nowrap">
                {row.number}
              </div>
            </div>

          </div>
        )
      },
    },
    {
      dataField: 'name',
      hidden: role !== '644c41e257d397eb064fe0f9',
      text: intl({ id: 'CUSTOMER_TITLE' }),
      sort: true,
      sortCaret,
      headerSortingClasses,
      formatter: (cell, row) => (
        <div className="text-nowrap mb-1">
          <div className='font-weight-bolder text-dark-75'>
            {`${row.name} ${row.surname}`}
          </div>
          <div className="font-weight-bold text-dark-50">
            {row.email}
          </div>
        </div>
      ),
    },
    {
      dataField: 'topic',
      text: intl({ id: "SUPPORT.FILTER.TOPIC" }),
      hidden: role !== '644c41e257d397eb064fe0f9',
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap',
      formatter: (cell, row) => {
        if (!cell) return null;
        return <div className={`label label-inline label-outline-info`}>
          <span className={`label label-dot label-info mr-2`} />
          <span className="font-weight-bold text-nowrap">{getTopicLabel(cell)?.label}</span>
        </div>
      }
    },
    {
      dataField: 'priority',
      text: intl({ id: 'GENERAL.PRIORITY' }),
      hidden: role !== '644c41e257d397eb064fe0f9',
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center text-nowrap',
      classes: 'text-center pr-5',
      formatter: (cell, row) => {
        const priority = TICKET_PRIORITY.find(priority => priority.value === cell)
        return priority && priority?.value !== "medium"
          ? <OverlayTrigger overlay={<Tooltip id="priority-tooltip">{priority?.label}</Tooltip>}>
            <span className={clsx("svg-icon svg-icon-sm", `text-${priority?.className}`)}>
              {priority.icon}
            </span>
          </OverlayTrigger>
          : null
      }
    },
    {
      dataField: 'tag',
      text: intl({ id: 'GENERAL.TAG' }),
      hidden: role !== '644c41e257d397eb064fe0f9',
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center text-nowrap',
      classes: 'text-center pr-5',
      formatter: (cell, row) => {
        const tag = TICKET_TAG.find(tag => tag.value === cell)
        return tag
          ? <div className={`label label-inline label-outline-${tag.className}`}>
            <span className={`label label-dot label-${tag.className} mr-2`} />
            <span className="font-weight-bold text-nowrap">{tag?.label}</span>
          </div>
          : null
      }
    },
    {
      dataField: 'late_alert',
      text: intl({ id: 'GENERAL.ALERT' }),
      hidden: role !== '644c41e257d397eb064fe0f9',
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center text-nowrap',
      classes: 'text-center pr-5',
      formatter: (cell, row) => {
        const daysDiff = UIDates.getDateDiff(UIDates.getCurrentDate(), row.created_at, "days");
        return (daysDiff > 7 && row.status === "open")
          ? <OverlayTrigger overlay={<Tooltip id="alert-tooltip"><FormattedMessage id="GENERAL.ALERT_DAYS" values={{ days: daysDiff }} /></Tooltip>}>
            <span className={clsx('svg-icon svg-icon-sm', daysDiff <= 14 ? "text-warning" : "text-danger")}>
              <Icon.ExclamationCircleFill />
            </span>
          </OverlayTrigger>
          : null
      },
    },
    {
      dataField: 'created_at',
      text: intl({ id: 'GENERAL.DATE' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center text-nowrap',
      classes: 'text-center text-nowrap pr-5',
      formatter: (cell, row) => (UIDates.formatDateTime(cell)),
    },
    {
      dataField: 'updated_at',
      text: intl({ id: 'GENERAL.LAST_UPDATE' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center text-nowrap',
      classes: 'text-center text-nowrap pr-5',
      formatter: (cell, row) => (cell && UIDates.formatDateTime(cell)),
    },
    {
      dataField: 'jira',
      hidden: role !== '644c41e257d397eb064fe0f9',
      text: intl({ id: 'SUPPORT.TABLE.JIRA' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center text-nowrap',
      classes: 'text-center pr-5',
      formatter: (cell, row) => (
        cell ? <a href={`https://bcs-it.atlassian.net/browse/${cell}`} className="d-flex justify-content-center align-items-center" target="_blank" rel="noopener noreferrer" id="btn_jira_view">
          <OverlayTrigger overlay={<Tooltip id="tooltip-support-reopen">{cell}</Tooltip>}>
            <Icon.Jira style={{ fontSize: '1.5em' }} />
          </OverlayTrigger>
        </a>
          : null
      )
    },
    {
      dataField: 'action',
      text: "...",
      classes: 'text-center pr-0',
      headerClasses: 'font-size-h3 text-center pt-0',
      headerStyle: { minWidth: '70px', width: "5%" },
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditTicketDialog: (id) => ModuleRoutes.VIEW_TICKET_FN(id),
        openDeleteTicketDialog: (id) => ModuleRoutes.DELETE_TICKET_FN(id),
        setTicketId: (id) => UIProps.setTicketId(id),
        hidden: role === '644c41e257d397eb064fe0f9'
      },
    },
  ];


  return (
    <>
      <BootstrapTable
        keyField="id"
        data={tickets || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        bordered={false}
        striped
        condensed
        noDataIndication={<NoRecordsFoundMessage loading={loading} onClick={() => { UIProps.changeDateRange(); UIProps.setFilterQuery('DELETE_PARAMS', allQueryKeys) }} />}
        defaultSorted={UIProps.defaultSorted}
      />
      <RemotePagination
        queryParams={UIProps.queryParams.pagination}
        setQueryParams={UIProps.setPaginationQuery}
        totalCount={countTickets}
        entitiesCount={tickets?.length}
        loading={loading}
      />
    </>
  );
}
