export default class ActionTypes {
  static get PREFIX() {
    return '[customer]';
  }


  // Create Customer action types
  static get CREATE_CUSTOMER() {
    return `${ActionTypes.PREFIX}CREATE_CUSTOMER`;
  }
  static get CREATE_CUSTOMER_SUCCESS() {
    return `${ActionTypes.PREFIX}CREATE_CUSTOMER_SUCCESS`;
  }
  static get CREATE_CUSTOMER_FAIL() {
    return `${ActionTypes.PREFIX}CREATE_CUSTOMER_FAIL`;
  }


  // Find Customer By Property action types
  static get FIND_CUSTOMER_BY_PROPERTY() {
    return `${ActionTypes.PREFIX}FIND_CUSTOMER_BY_PROPERTY`;
  }
  static get FIND_CUSTOMER_BY_PROPERTY_SUCCESS() {
    return `${ActionTypes.PREFIX}FIND_CUSTOMER_BY_PROPERTY_SUCCESS`;
  }
  static get FIND_CUSTOMER_BY_PROPERTY_FAIL() {
    return `${ActionTypes.PREFIX}FIND_CUSTOMER_BY_PROPERTY_FAIL`;
  }


  // Update Customer action types
  static get UPDATE_CUSTOMER() {
    return `${ActionTypes.PREFIX}UPDATE_CUSTOMER`;
  }
  static get UPDATE_CUSTOMER_SUCCESS() {
    return `${ActionTypes.PREFIX}UPDATE_CUSTOMER_SUCCESS`;
  }
  static get UPDATE_CUSTOMER_FAIL() {
    return `${ActionTypes.PREFIX}UPDATE_CUSTOMER_FAIL`;
  }


  // Delete Customer action types
  static get DELETE_CUSTOMER() {
    return `${ActionTypes.PREFIX}DELETE_CUSTOMER`;
  }
  static get DELETE_CUSTOMER_SUCCESS() {
    return `${ActionTypes.PREFIX}DELETE_CUSTOMER_SUCCESS`;
  }
  static get DELETE_CUSTOMER_FAIL() {
    return `${ActionTypes.PREFIX}DELETE_CUSTOMER_FAIL`;
  }


  // Get Customers action types
  static get GET_CUSTOMERS() {
    return `${ActionTypes.PREFIX}GET_CUSTOMERS`;
  }
  static get GET_CUSTOMERS_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_CUSTOMERS_SUCCESS`;
  }
  static get GET_CUSTOMERS_FAIL() {
    return `${ActionTypes.PREFIX}GET_CUSTOMERS_FAIL`;
  }


  // Get Customer By Id action types
  static get GET_CUSTOMER_BY_ID() {
    return `${ActionTypes.PREFIX}GET_CUSTOMER_BY_ID`;
  }
  static get GET_CUSTOMER_BY_ID_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_CUSTOMER_BY_ID_SUCCESS`;
  }
  static get GET_CUSTOMER_BY_ID_FAIL() {
    return `${ActionTypes.PREFIX}GET_CUSTOMER_BY_ID_FAIL`;
  }


  // Reset Customer Form handler action types
  static get RESET_CUSTOMER_FORM() {
    return `${ActionTypes.PREFIX}RESET_CUSTOMER_FORM`;
  }


  // Reset Customer Clean action types
  static get CLEAN_CUSTOMER() {
    return `${ActionTypes.PREFIX}CLEAN_CUSTOMER`;
  }


  // Address form handler action types
  static get ADD_ADDRESS() {
    return `${ActionTypes.PREFIX}ADD_ADDRESS`;
  }
  static get UPDATE_ADDRESS() {
    return `${ActionTypes.PREFIX}UPDATE_ADDRESS`;
  }
  static get DELETE_ADDRESS() {
    return `${ActionTypes.PREFIX}DELETE_ADDRESS`;
  }
  static get GET_ADDRESS() {
    return `${ActionTypes.PREFIX}GET_ADDRESS`;
  }


  // Get Customer By Id action types
  static get GET_DOCUMENTS_BY_CUSTOMER_ID() {
    return `${ActionTypes.PREFIX}GET_DOCUMENTS_BY_CUSTOMER_ID`;
  }
  static get GET_DOCUMENTS_BY_CUSTOMER_ID_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_DOCUMENTS_BY_CUSTOMER_ID_SUCCESS`;
  }
  static get GET_DOCUMENTS_BY_CUSTOMER_ID_FAIL() {
    return `${ActionTypes.PREFIX}GET_DOCUMENTS_BY_CUSTOMER_ID_FAIL`;
  }


  // Get Customers action types
  static get GET_CUSTOMER_DOCUMENT_COUNTS() {
    return `${ActionTypes.PREFIX}GET_CUSTOMER_DOCUMENT_COUNTS`;
  }
  static get GET_CUSTOMER_DOCUMENT_COUNTS_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_CUSTOMER_DOCUMENT_COUNTS_SUCCESS`;
  }
  static get GET_CUSTOMER_DOCUMENT_COUNTS_FAIL() {
    return `${ActionTypes.PREFIX}GET_CUSTOMER_DOCUMENT_COUNTS_FAIL`;
  }


  // Clear error handler action types
  static get CLEAN_ERROR_HANDLER() {
    return `${ActionTypes.PREFIX}CLEAN_ERROR_HANDLER`;
  }


  static get CLEAN_STATES_IN_STORE() {
    return `${ActionTypes.PREFIX}CLEAN_STATES_IN_STORE`;
  }


  // Clear loading action types 
  static get CLEAR_LOADING_EFFECTS() {
    return `${ActionTypes.PREFIX}CLEAR_LOADING_EFFECTS`;
  }

}
