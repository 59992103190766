import React from "react";



export function SelectionCheckbox({ isSelected, onChange, page, disabled, id }) {
  return (
    <>
      <input type="checkbox" className="d-none" />
      <label className={`checkbox checkbox-outline checkbox-single`}>
        <input type="checkbox" checked={isSelected} onChange={onChange} disabled={disabled} id={`checkbox_${id}`} />
        <span />
      </label>
    </>
  );
}


function groupingItemOnSelect({ ids, setIds, id, key }) {
  if (ids.some((el) => el[key] === id)) {
    setIds(ids.filter((el) => el[key] !== id));
  } else {
    const newIds = [...ids];
    newIds.push({ [key]: id });
    setIds(newIds);
  }
}


function groupingAllOnSelect({ isSelected, setIds, entities, key }) {
  if (!isSelected) {
    const allIds = [];
    entities.forEach((el) => allIds.push({ [key]: el.id }));
    setIds(allIds);
  } else {
    setIds([]);
  }
  return isSelected;
}


// check official documentations: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Row%20Selection&selectedStory=Custom%20Selection%20Column%20Header%20Style&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
export function remoteGetSelectRow({ entities, ids, setIds, key }) {
  return {
    mode: "checkbox",
    clickToSelect: true,
    hideSelectAll: false,
    // bgColor: '#FDF4FF',
    // onSelect: (row, isSelect, rowIndex, e) => {
    // },
    selectionHeaderRenderer: () => {
      const isSelected = entities && entities.length > 0 && entities.length === ids.length;
      return (
        <SelectionCheckbox isSelected={isSelected} onChange={() => groupingAllOnSelect({ isSelected, entities, setIds, key })}  id="select_all" />
      );
    },
    selectionRenderer: ({ rowKey }) => {
      const isSelected = ids.some((el) => el[key] === rowKey);
      return (
        <SelectionCheckbox isSelected={isSelected} page={key} onChange={() => groupingItemOnSelect({ ids, setIds, id: rowKey, key })} id={rowKey} />
      );
    },
  };
}
