import React from 'react';
import { Card, CardSubHeader, CardBody } from '_metronic/_partials/controls';
import { FormattedMessage } from 'react-intl';
import { ReportForm } from './ReportForm';
import { Icon } from '_metronic/_icons';



export function ReportCard({ loading, id }) {
  return (
    <Card className="card-stretch">

      <CardSubHeader icon={<Icon.Search />} title={<FormattedMessage id="CUSTOMER.FIND_DOCUMENTS" />} />

      <CardBody>
        <ReportForm loading={loading} id={id} />
      </CardBody>
    </Card>
  );
}
