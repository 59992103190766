import ActionTypes from "./actionTypes";



export class DashboardActions {

  // Get Dashboard Data
  static getDashboard = (dateRange) => {
    return {
      type: ActionTypes.GET,
      payload: {
        dateRange
      },
    };
  };

  static getDashboardSuccess = (data) => {
    return {
      type: ActionTypes.GET_SUCCESS,
      payload: {
        data
      },
    };
  };

  static getDashboardFail = () => {
    return {
      type: ActionTypes.GET_FAIL,
    };
  };

}
