export const supplierFieldParams = {
  fields: [
    "id",
    "name",
    "company",
    "number"
  ],
};

export const searchKeys = [
  'name',
  'sku',
  "category.path",
  'variants.sku',
  'variants.ean',
  'variants.asin',
  'identities.ean',
  'identities.asin',
  'pricing.purchase_price',
  'identities.barcode',
  'identities.gtin',
  'identities.isbn',
];

export const appliedFilter = (filter) => {

  if (filter?.published) {
    filter["inventories"] = { $elemMatch: { status: "PUBLISHED" } };
    delete filter.published;
  }
  if (filter?.unpublished) {
    filter["inventories"] = { $not: { $elemMatch: { status: "PUBLISHED" } } };
    delete filter.unpublished;
  }
  if (filter?.low_stock) {
    filter["quantity"] = { $lt: 10 };
    delete filter.low_stock;
  }
  if (filter?.date_from && filter?.date_to) {
    filter["created_at"] = { between: [`${filter.date_from}T00:00:00.001Z`, `${filter.date_to}T23:59:59.999Z`] }
  }

  const {date_from, date_to, ...rest} = filter;

  return rest;
};