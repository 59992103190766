import React, { createContext, useContext, useState } from 'react';


const CategoriesUIContext = createContext();
export const CategoriesUIConsumer = CategoriesUIContext.Consumer;
export const useCategoriesUIContext = () => useContext(CategoriesUIContext)


export function CategoriesUIProvider({ children }) {

  const [ids, setIds] = useState([]);

  const [showCategoryLoadingDialog, setShowCategoryLoadingDialog] = useState(false);
  const handleCloseCategoryLoadingDialog = () => { setShowCategoryLoadingDialog(false) };

  const [showServerRespondDialog, setShowServerRespondDialog] = useState(false);
  const handleCloseServerRespondDialog = () => { setShowServerRespondDialog(false) };
  const handleOpenServerRespondDialog = () => { setShowServerRespondDialog(true) };

  const [categoryId, setCategoryId] = useState();

  //clean up context
  const cleanUpContextHandler = () => {
    setCategoryId(undefined);
    setIds([]);
  };

  const value = {
    cleanUpContextHandler,
    ids,
    setIds,
    categoryId,

    showCategoryLoadingDialog,
    handleCloseCategoryLoadingDialog,
    setShowCategoryLoadingDialog,

    showServerRespondDialog,
    handleOpenServerRespondDialog,
    handleCloseServerRespondDialog,
  };

  return (
    <CategoriesUIContext.Provider value={value}>
      {children}
    </CategoriesUIContext.Provider>
  );
}
