import React from "react";
import { FormattedMessage } from "react-intl";



export const validator = {
  priceIsValid: (cell, row) => {
    if (cell.includes(",")) {
      cell = cell.replace(",", ".");
    }
    if (isNaN(cell)) {
      return {
        valid: false,
        message: <FormattedMessage id="PRODUCT.TABLE.VALIDATOR.IS_VALID.PRICE" />,
      };
    }
  },

  quantityIsValid: (cell, row, intl) => {

    if (cell.includes(",")) {
      cell = cell.replace(",", ".");
    }

    if (row.unit === 'piece') {
      if (cell.includes('.')) {
        return {
          valid: false,
          message: <FormattedMessage id="PRODUCT.GENERAL.INTEGER_REQUIRED" />,
        };
      }
    }

    if (isNaN(cell)) {
      return {
        valid: false,
        message: <FormattedMessage id="PRODUCT.TABLE.VALIDATOR.IS_VALID.QUANTITY" />,
      };
    }
    if (+cell < 0) {
      return {
        valid: false,
        message: intl({ id: "DOCUMENT.POSITION.IS_VALID.QUANTITY1" })
      };
    }

    if (+cell > 999999) {
      return {
        valid: false,
        message: intl({ id: "PRODUCT.VARIANT.IS_VALID.QUANTITY" })
      };
    }
  },
};
