import React, { useState, forwardRef } from 'react';
import { SVFeedbackLabel, SVFormControlLabel, SVFormControlWrapper } from './SVFormHelpers';
import { UserInfoDropdown } from '../../..';
import { theme } from '_metronic/_helpers';
import { Icon } from '_metronic/_icons';
import styled from 'styled-components';
import clsx from 'clsx';



export const SVInput = forwardRef((props, ref) => (
  <StyledInput {...props} ref={ref} className={clsx('form-control', props?.size && `form-control-${props?.size}`)} value={props.value ?? undefined} />
));


export const SVFormControl = ({
  id,
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  size = 'md',
  type = 'text',
  placeholder,
  feedbackLabel = null,
  label,
  min,
  max,
  readOnly,
  onFocus,
  step,
  disabled,
  required,
  price,
  className,
  info,
  symbol,
  onClickSymbol,
  autoComplete,
  minLength,
  maxLength,
  pattern,
  title,
  onBlur,
  ...props
}) => {

  const [showPassword, setShowPassword] = useState(false);

  const fieldCheck = (type, field) => {
    if (field.includes(".")) {
      return type[field.split(".")[0]]?.[field.split(".")[1]];
    } else {
      return type[field];
    }
  };


  return (
    <SVFormControlWrapper className={className}>

      {label &&
        <SVFormControlLabel size={size} errors={fieldCheck(touched, field.name) && (fieldCheck(errors, field.name) || typeof feedbackLabel === "string") && true}>
          {label} {required && <span className="text-danger">*</span>}
          {info && <UserInfoDropdown size="w-75" /* title={label} */ description={info} />}
        </SVFormControlLabel>
      }

      <div className={clsx((symbol || onClickSymbol) && "input-group")}>
        <SVInput
          size={size}
          id={id}
          // value={price && field.value && Math.floor(field.value) !== field.value ? field.value.toFixed(2) : field.value}
          type={type === 'password' ? showPassword ? 'text' : 'password' : type}
          min={min}
          max={max}
          step={step}
          // className={`form-control ${touched[field.name] && errors[field.name] ? 'is-invalid' : ''}`} // input inside icon
          placeholder={placeholder}
          errors={fieldCheck(touched, field.name) && (fieldCheck(errors, field.name) || typeof feedbackLabel === "string") && true}
          readOnly={readOnly}
          onFocus={onFocus}
          disabled={disabled}
          autoComplete={autoComplete}
          minLength={minLength}
          maxLength={maxLength}
          pattern={pattern}
          title={title}
          {...field}
          onBlur={onBlur}
        />

        {symbol &&
          <div className="input-group-append">
            <span className="input-group-text svg-icon svg-icon-sm font-weight-bold">
              {symbol}
            </span>
          </div>
        }

        {onClickSymbol &&
          <div className="input-group-append">
            <span className="input-group-text svg-icon svg-icon-sm font-weight-bold p-0 border-0 bg-secondary">
              {onClickSymbol}
            </span>
          </div>
        }

      </div>


      {type === 'password' &&
        <span className='position-absolute text-primary' type="button"
          style={{ right: "0", top: "35%", fontSize: "1.2rem", padding: "0.85rem 1rem" }}
          onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? <Icon.EyeSlash /> : <Icon.Eye />}
        </span>
      }

      {feedbackLabel &&
        <SVFeedbackLabel size={size} withFeedbackLabel={true} errors={fieldCheck(touched, field.name) && fieldCheck(errors, field.name) && true} label={label}>
          {fieldCheck(touched, field.name) && fieldCheck(errors, field.name) ? fieldCheck(errors, field.name) : <span className='text-danger'>{feedbackLabel}</span>}
        </SVFeedbackLabel>
      }

    </SVFormControlWrapper>
  )
};


export const StyledInput = styled.input`
  height: ${p => theme.units.input.height[p.size || 'md']};
  box-shadow: ${theme.shadows.input};
  border: ${p => p.errors ? `1px solid ${theme.palette.danger['color']}` : `1px solid ${theme.palette.gray['300']}`};
  &:hover {
    cursor: context-menu;
    outline: 1px solid ${theme.palette.primary['100']};   
  };
  &:focus {
    border-color: ${theme.palette.primary['300']};
    outline: 1px solid ${theme.palette.primary['300']};
    // box-shadow: ${theme.shadows.inputHover};
  };
  &::placeholder {
    opacity: 0.7;
  };
  border-radius: 0.5rem;
  &:disabled {
    background: ${theme.palette.gray['50']};
    color: ${theme.palette.gray['500']};
  }
`;
