import { legacy_createStore as createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore } from "redux-persist";
import { persistedRootReducer } from "./rootReducer";
import { rootSaga } from "./rootSaga";



const sagaMiddleware = createSagaMiddleware();

// Enable Redux DevTools only in development
let composeEnhancers = compose;
if (process.env.NODE_ENV !== "production") {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const middleware = composeEnhancers(applyMiddleware(sagaMiddleware));

const store = createStore(persistedRootReducer, middleware);

const persistor = persistStore(store);

export { store, persistor };

sagaMiddleware.run(rootSaga);
