import React from "react";
import { ProgressBar } from "react-bootstrap";



export function ModalProgressBar({ variant = "success", now = 20 }) {
  return (
    <div className="col">
      <div>
        <ProgressBar
          variant={variant}
          animated
          now={now}
          style={{ height: "5px", width: "100%" }}
        />
      </div>
      <div>
        <p>Please wait sometime..</p>
      </div>
    </div>
  );
}
