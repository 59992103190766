import DOMPurify from 'dompurify';



const purifyConfig = {
  ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'p', 'i', 'b', 'ol', 'ul', 'li', 'table', 'tbody', 'th', 'td', 'tr', 'blockquote', 'figure', 'oembed', 'iframe'],
  ADD_ATTR: ['class', 'url'],
  ALLOWED_ATTR: ['url', 'class', 'src'],
};


DOMPurify.addHook('beforeSanitizeElements', (node) => {
  if (node.tagName === 'OEMBED' && node.getAttribute('url')?.includes('youtube.com')) {
    return true;
  }
  if (node.tagName === 'IFRAME' && node.src?.includes('youtube.com')) {
    return true;
  }
});

//TODO: Backend taşınacak global olarak kullanılması için
export function convertText(text) {

  const pattern = /<oembed url="https:\/\/www\.youtube\.com\/watch\?v=(.*?)"><\/oembed>/g;
  const youtubeLinks = text.match(pattern);

  if (!youtubeLinks) {
    return text;
  }
  const convertedText = text.replace(pattern, (match, videoId) => {
    return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
  });

  return convertedText;
}


export const SanitizeHtml = (html) => {
  return DOMPurify.sanitize(html, purifyConfig);
}
