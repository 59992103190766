import React, { createContext, useContext } from 'react';


const ProductListingsUIContext = createContext();
export const useProductListingsUIContext = () => useContext(ProductListingsUIContext);



export function ProductListingsUIProvider({ children, contextUIEvents }) {
  const value = { ...contextUIEvents };
  return (
    <ProductListingsUIContext.Provider value={value}>
      {children}
    </ProductListingsUIContext.Provider>
  );
}
