import ActionTypes from './actionTypes';



const INITIAL_STATE = {
  loading: false,
  version: '',
  orders_count: 0,
  fetch_order_count: [],
  total_order_count: {
    created: 0,
    updated: 0
  }
};


export function notificationReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {

    // Get Notification
    case ActionTypes.GET_NOTIFICATION:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        fetch_order_count: payload.data.fetch_order_count.sort((a, b) => a.status === "success" ? -1 : 0),
        orders_count: payload.data.orders_count,
        total_order_count: payload.data.total_order_count,
        version: payload.data.version
      };

    case ActionTypes.GET_NOTIFICATION_FAIL:
      return {
        ...state,
        loading: false,
      };


    default:
      return state;
  }
}
