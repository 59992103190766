import React from "react";
import styled from "styled-components";
import { theme } from "_metronic/_helpers";

//* label
const StyledLabel = styled.div`
  color: ${(p) => (p.errors ? theme["danger"] : theme["black"])};
  font-size: 1rem;
  margin-bottom: 0.15rem;
`;
export function UILabel({ children, errors }) {
  return <StyledLabel errors={errors}>{children}</StyledLabel>;
}

//*FeedbackLabel
const FeedbackLabel = styled(StyledLabel)`
  display: ${(p) => (p.withFeedbackLabel ? "block" : "none")};
  font-size: 0.8rem;
`;
export function UIFeedbackLabel({ children, errors, withFeedbackLabel }) {
  return (
    <FeedbackLabel withFeedbackLabel={withFeedbackLabel} errors={errors}>
      {children}
    </FeedbackLabel>
  );
}
