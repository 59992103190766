import ActionTypes from "./actionTypes";



export class NotificationActions {

  // Get Dashboard Notification
  static getNotification = (data) => {
    return {
      type: ActionTypes.GET_NOTIFICATION,
      payload: {
        data
      },
    };
  };

  static getNotificationSuccess = (data) => {
    return {
      type: ActionTypes.GET_NOTIFICATION_SUCCESS,
      payload: {
        data
      },
    };
  };

  static getNotificationFail = () => {
    return {
      type: ActionTypes.GET_NOTIFICATION_FAIL,
    };
  };

}
