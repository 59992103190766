import { call, put, select, takeEvery } from "redux-saga/effects";
import { reportMiddleware } from "middlewares";
import { ReportActions } from "./actions";
import ActionTypes from "./actionTypes";



// Get Order list Saga
function* getOrderList({ payload: { dateRange, queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(reportMiddleware.getOrderList, accessToken, dateRange, queryParams);
    yield put(ReportActions.getOrderListSuccess(response.data));

  } catch (err) {

    yield put(ReportActions.getOrderListFail(err));
  }
}

// Get Email Logs Saga
function* getEmailLogs({ payload: { dateRange, queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(reportMiddleware.getEmailLogs, accessToken, dateRange, queryParams);
    const countResponse = yield call(reportMiddleware.getEmailLogsCount, accessToken, dateRange, queryParams);

    yield put(ReportActions.getEmailLogsSuccess(response.data, countResponse.data));

  } catch (err) {

    yield put(ReportActions.getEmailLogsFail(err));
  }
}


export function* reportSaga() {
  yield takeEvery(ActionTypes.GET_ORDER_LIST, getOrderList);
  yield takeEvery(ActionTypes.GET_EMAIL_LOGS, getEmailLogs);
}
