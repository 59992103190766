export default class ActionTypes {
  static get PREFIX() {
    return '[notification]';
  }


  // Get Notification action types 
  static get GET_NOTIFICATION() {
    return `${ActionTypes.PREFIX}GET_NOTIFICATION`;
  }
  static get GET_NOTIFICATION_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_NOTIFICATION_SUCCESS`;
  }
  static get GET_NOTIFICATION_FAIL() {
    return `${ActionTypes.PREFIX}GET_NOTIFICATION_FAIL`;
  }
}
