import { API } from "constants/apiUrl";
import { paginationParams, searchParams } from "../_helpers/query-params";
import { searchKeys } from "./params";



export const supplierMiddleware = {

  createSupplier: async function (accessToken, supplier) {
    return await API.post(`/suppliers`, supplier, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  updateSupplier: async function (accessToken, supplier) {
    return await API.put(`/suppliers/${supplier.id}`, supplier, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  deleteSuppliers: async function (accessToken, ids) {
    return await API.delete(`/suppliers/${ids}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getSupplierById: async function (accessToken, id) {
    return await API.get(`/suppliers/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getSupplier: async function (accessToken, { pagination, search, filter, order }) {
    return await API.get(`/suppliers`, {
      params: {
        filter: {
          ...paginationParams(pagination.page, pagination.limit),
          order: order
            ? order?.includes("country_code")
              ? [order, `address[0].post_code ${order?.split(' ')[1]}`]
              : [order]
            : ['order_date DESC'],
          where: {
            ...(search && {
              ...searchParams(searchKeys, search),
            }),
            ...(filter && filter),
          },
        }
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getCount: async function (accessToken, { search, filter }) {
    return await API.get(`/suppliers/count`, {
      params: {
        where: {
          ...(search && {
            ...searchParams(searchKeys, search),
          }),
          ...(filter && filter),
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  findSupplier: async function (accessToken, key, value, supplierId) {
    return await API.get(`/suppliers`, {
      params: {
        filter: {
          where: {
            [key]: value,
            ...(supplierId && { id: { $ne: supplierId } }),
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

};
