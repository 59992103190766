import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { ListingActions } from '../../_redux/actions';
import { theme } from '_metronic/_helpers';
import { Icon } from '_metronic/_icons'
import { toast } from 'react-toastify';



export default function EbayListingsGrouping({ UIProps, queryParams }) {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const { products, isLoading } = useSelector(state => ({
    products: state.listings.products,
    isLoading: state.listings.isLoading,
  }));


  const selectStatus = UIProps.ids
    .map(id => products.find(product => product.id === id))
    .flatMap(product => {
      if (Array.isArray(product?.inventories)) {
        let productStatus = product?.inventories.filter(item => item.market_id === UIProps.market_id) || [];

        return productStatus.length > 0 ? productStatus : [{ status: 'NOT' }];
      } else {
        return [{ status: 'NOT' }];
      }
    })
    .map(item => item?.status);

  const allDraft = selectStatus.every(item => item === 'DRAFT');
  const allPublished = selectStatus.every(item => item === 'PUBLISHED');

  const publishUnPublish = method => {
    if (method === 'publish' && selectStatus.some(status => status !== 'DRAFT')) {
      toast.error(intl({ id: 'PRODUCT_LISTINGS.EBAY.WITH_DRAFT_OR_PUBLISHED' }, { value: 'DRAFT' }));
      return;
    }

    if (method === 'withdraw' && selectStatus.some(status => status !== 'PUBLISHED')) {
      toast.error(intl({ id: 'PRODUCT_LISTINGS.EBAY.WITH_DRAFT_OR_PUBLISHED' }, { value: 'PUBLISHED' }));
      return;
    }

    dispatch(
      ListingActions.publishEbayInventoryRequest({
        ids: UIProps.ids,
        market_id: UIProps.market_id,
        queryParams: queryParams,
        method,
      })
    );
  };


  return (
    <>
      <OverlayTrigger placement="top" overlay={<Tooltip id="withdraw-tooltip"><FormattedMessage id="PRODUCT_LISTINGS.INACTIVE_PRODUCTS" values={{ shopName: 'Ebay' }} /></Tooltip>}>
        <Button
          variant="light-primary"
          className={`svg-icon svg-icon-sm ml-auto ${!allPublished || UIProps.ids.length < 1 || isLoading ? 'cursor-default' : 'cursor-pointer'}`}
          style={{ height: theme.units.input.height['sm'], }}
          onClick={() => publishUnPublish('withdraw')}
          disabled={!allPublished || UIProps.ids.length < 1 || isLoading}
          id="btn_sync"
        >
          <Icon.BoxArrowDown />
          <span className="d-none d-sm-inline font-weight-bold ml-2">
            <FormattedMessage id="GENERAL.REMOVE" />
          </span>
        </Button>
      </OverlayTrigger>

      <OverlayTrigger placement="top" overlay={<Tooltip id="publish-tooltip"><FormattedMessage id="PRODUCT_LISTINGS.ACTIVE_PRODUCTS" values={{ shopName: 'Ebay' }} /></Tooltip>}>
        <Button
          variant="light-primary"
          className={`svg-icon svg-icon-sm ml-3 ${!allDraft || UIProps.ids.length < 1 || isLoading ? 'cursor-default' : 'cursor-pointer'}`}
          style={{ height: theme.units.input.height['sm'], }}
          onClick={() => publishUnPublish('publish')}
          disabled={!allDraft || UIProps.ids.length < 1 || isLoading}
          id="btn_sync"
        >
          <Icon.BoxArrowUp />
          <span className="d-none d-sm-inline font-weight-bold ml-2">
            <FormattedMessage id="GENERAL.UPLOAD" />
          </span>
        </Button>
      </OverlayTrigger>
    </>
  )
}
