import ActionTypes from './actionTypes';



const INITIAL_STATE = {
  loading: false,
  listLoading: false,
  error: '',
  categoryForEdit: {},
  entities: [],
  category: {},
  searchLoading: false,
  countCategories: 0,
};

export function categoriesReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {

    // Get Categories
    case ActionTypes.GET_CATEGORIES:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_CATEGORIES_SUCCESS:
      //FIXME: Burası nedir?
      const newCategories2 = payload.categories.map(item => {
        const { sav_category, ...restItem } = item;
        return restItem;
      });
      return {
        ...state,
        loading: false,
        entities: newCategories2,
        countCategories: payload.countCategories,
      };

    case ActionTypes.GET_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: 'GENERAL.ERROR_MESSAGE_GENERAL',
      };


    // Delete Category
    case ActionTypes.DELETE_CATEGORY:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        entities: state.entities.filter(category => category.id !== payload.id),
      };

    case ActionTypes.DELETE_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: 'CATEGORIES.ERROR_MESSAGE_DELETE',
      };


    // Create Category
    case ActionTypes.CREATE_CATEGORY:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case ActionTypes.CREATE_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: 'CATEGORIES.ERROR_MESSAGE_CREATE',
      };


    // Update Category
    case ActionTypes.UPDATE_CATEGORY:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case ActionTypes.UPDATE_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: 'CATEGORIES.ERROR_MESSAGE_UPDATE',
      };

    default:
      return state;
  }
}
