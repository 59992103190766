import React, { createContext, useCallback, useContext, useState } from 'react';
import { useSearchQuery } from 'app/hooks';
import { useIntl } from 'react-intl';



const OrderListUIContext = createContext();
export const useOrderListUIContext = () => useContext(OrderListUIContext)


export const OrderListUIProvider = ({ children }) => {

  const { formatMessage: intl } = useIntl();

  const [queryParams, setQueryBase] = useState({
    pagination: {
      page: 1,
      limit: 10,
    },
    order: 'created_at DESC',
    search: '',
    filter: {},
  });
  const setQueryParams = useCallback((params) => setQueryBase(params), [])

  const { setFilterQuery, setPaginationQuery, setSearchQuery, setOrderQuery } = useSearchQuery(setQueryParams);

  const initialDateRange = {
    id: "clear-date",
    label: intl({ id: "GENERAL.CLEAR" }),
  };
  const [dateRange, setDateRange] = useState(initialDateRange);
  const changeDateRange = (date = initialDateRange) => setDateRange(date);

  const value = {
    dateRange,
    setDateRange,
    changeDateRange,

    queryParams,
    setQueryBase,
    setFilterQuery,
    setPaginationQuery,
    setSearchQuery,
    setOrderQuery
  };

  return (
    <OrderListUIContext.Provider value={value}>
      {children}
    </OrderListUIContext.Provider>
  );
};
