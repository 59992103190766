import React from "react";
import { FormattedMessage } from "react-intl";



// ------------------------------------ CONNECTIONS ------------------------------------

export const connectionsAdd = {
    main: {
        title: "CONNECTIONS.ADD_CONNECTIONS",
        location: 'add',
        description: "USER_GUIDE.CONNECTION.ADD",
    },
    ebay: {
        title: <FormattedMessage id="CONNECTIONS.INSTRUCTIONS_TITLE" values={{ name: 'Ebay' }} />,
        description: "USER_GUIDE.CONNECTION.SHOP.EBAY"
    },
    kaufland: {
        title: <FormattedMessage id="CONNECTIONS.INSTRUCTIONS_TITLE" values={{ name: 'Kaufland' }} />,
        description: "USER_GUIDE.CONNECTION.SHOP.KAUFLAND"
    },
    amazon: {
        title: <FormattedMessage id="CONNECTIONS.INSTRUCTIONS_TITLE" values={{ name: 'Amazon' }} />,
        description: "USER_GUIDE.CONNECTION.SHOP.AMAZON"
    },
    hood: {
        title: <FormattedMessage id="CONNECTIONS.INSTRUCTIONS_TITLE" values={{ name: 'Hood' }} />,
        description: "USER_GUIDE.CONNECTION.SHOP.HOOD"
    },
    check24: {
        title: <FormattedMessage id="CONNECTIONS.INSTRUCTIONS_TITLE" values={{ name: 'Check24' }} />,
        description: "USER_GUIDE.CONNECTION.SHOP.CHECK24"
    },
    manomano: {
        title: <FormattedMessage id="CONNECTIONS.INSTRUCTIONS_TITLE" values={{ name: 'Manomano' }} />,
        description: "USER_GUIDE.CONNECTION.SHOP.MANOMANO"
    },
    prestashop: {
        title: <FormattedMessage id="CONNECTIONS.INSTRUCTIONS_TITLE" values={{ name: 'PrestaShop' }} />,
        description: "USER_GUIDE.CONNECTION.SHOP.PRESTASHOP"
    },
    shopware5: {
        title: <FormattedMessage id="CONNECTIONS.INSTRUCTIONS_TITLE" values={{ name: 'Shopware 5' }} />,
        description: "USER_GUIDE.CONNECTION.SHOP.SHOPWARE5"
    },
    shopware6: {
        title: <FormattedMessage id="CONNECTIONS.INSTRUCTIONS_TITLE" values={{ name: 'Shopware 6' }} />,
        description: "USER_GUIDE.CONNECTION.SHOP.SHOPWARE6"
    },
    otto: {
        title: <FormattedMessage id="CONNECTIONS.INSTRUCTIONS_TITLE" values={{ name: 'Otto' }} />,
        description: "USER_GUIDE.CONNECTION.SHOP.OTTO"
    },
    woocommerce: {
        title: <FormattedMessage id="CONNECTIONS.INSTRUCTIONS_TITLE" values={{ name: 'Woocommerce' }} />,
        description: "USER_GUIDE.CONNECTION.SHOP.WOOCOMMERCE"
    },
    shopify: {
        title: <FormattedMessage id="CONNECTIONS.INSTRUCTIONS_TITLE" values={{ name: 'Shopify' }} />,
        description: "USER_GUIDE.CONNECTION.SHOP.SHOPIFY"
    },
    etsy: {
        title: <FormattedMessage id="CONNECTIONS.INSTRUCTIONS_TITLE" values={{ name: 'Etsy' }} />,
        description: "USER_GUIDE.CONNECTION.SHOP.ETSY"
    },
    dhl: {
        title: <FormattedMessage id="CONNECTIONS.SHIPPING_TITLE" values={{ name: 'DHL' }} />,
        description: "USER_GUIDE.CONNECTION.SHIPPING.DHL",
    },
    'dhl_new': {
        title: <FormattedMessage id="CONNECTIONS.SHIPPING_TITLE" values={{ name: 'DHL' }} />,
        description: "USER_GUIDE.CONNECTION.SHIPPING.DHL",
    },
    gls: {
        title: <FormattedMessage id="CONNECTIONS.SHIPPING_TITLE" values={{ name: 'GLS' }} />,
        description: "USER_GUIDE.CONNECTION.SHIPPING.GLS"
    },
    dpd: {
        title: <FormattedMessage id="CONNECTIONS.SHIPPING_TITLE" values={{ name: 'DPD' }} />,
        description: "USER_GUIDE.CONNECTION.SHIPPING.DPD"
    },
    'deutsche_post': {
        title: <FormattedMessage id="CONNECTIONS.SHIPPING_TITLE" values={{ name: 'Deutschepost' }} />,
        description: "USER_GUIDE.CONNECTION.SHIPPING.DEUTSCHEPOST"
    },
}

export const connections = {
    main: {
        title: "MENU.ASIDE.CONNECTIONS",
        location: 'connections',
        description: "USER_GUIDE.CONNECTION",
    },
    // ebay: {
    //     title: <FormattedMessage id="CONNECTIONS.SHOP_TITLE" values={{ name: 'Ebay' }} />,
    //     description: "USER_GUIDE.CONNECTION.SHOP.EBAY"
    // },
    // kaufland: {
    //     title: <FormattedMessage id="CONNECTIONS.SHOP_TITLE" values={{ name: 'Kaufland' }} />,
    //     description: "USER_GUIDE.CONNECTION.SHOP.KAUFLAND"
    // },
    // amazon: {
    //     title: <FormattedMessage id="CONNECTIONS.SHOP_TITLE" values={{ name: 'Amazon' }} />,
    //     description: "USER_GUIDE.CONNECTION.SHOP.AMAZON"
    // },
    // hood: {
    //     title: <FormattedMessage id="CONNECTIONS.SHOP_TITLE" values={{ name: 'Hood' }} />,
    //     description: "USER_GUIDE.CONNECTION.SHOP.HOOD"
    // },
    // check24: {
    //     title: <FormattedMessage id="CONNECTIONS.SHOP_TITLE" values={{ name: 'Check24' }} />,
    //     description: "USER_GUIDE.CONNECTION.SHOP.CHECK24"
    // },
    // manomano: {
    //     title: <FormattedMessage id="CONNECTIONS.SHOP_TITLE" values={{ name: 'Manomano' }} />,
    //     description: "USER_GUIDE.CONNECTION.SHOP.MANOMANO"
    // },
    // prestashop: {
    //     title: <FormattedMessage id="CONNECTIONS.SHOP_TITLE" values={{ name: 'PrestaShop' }} />,
    //     description: "USER_GUIDE.CONNECTION.SHOP.PRESTASHOP"
    // },
    // shopware5: {
    //     title: <FormattedMessage id="CONNECTIONS.SHOP_TITLE" values={{ name: 'Shopware 5' }} />,
    //     description: "USER_GUIDE.CONNECTION.SHOP.SHOPWARE5"
    // },
    // shopware6: {
    //     title: <FormattedMessage id="CONNECTIONS.SHOP_TITLE" values={{ name: 'Shopware 6' }} />,
    //     description: "USER_GUIDE.CONNECTION.SHOP.SHOPWARE6"
    // },
    // otto: {
    //     title: <FormattedMessage id="CONNECTIONS.SHOP_TITLE" values={{ name: 'Otto' }} />,
    //     description: "USER_GUIDE.CONNECTION.SHOP.OTTO"
    // },
    // woocommerce: {
    //     title: <FormattedMessage id="CONNECTIONS.SHOP_TITLE" values={{ name: 'Woocommerce' }} />,
    //     description: "USER_GUIDE.CONNECTION.SHOP.WOOCOMMERCE"
    // },
    // shopify: {
    //     title: <FormattedMessage id="CONNECTIONS.SHOP_TITLE" values={{ name: 'Shopify' }} />,
    //     description: "USER_GUIDE.CONNECTION.SHOP.SHOPIFY"
    // },
    // etsy: {
    //     title: <FormattedMessage id="CONNECTIONS.SHOP_TITLE" values={{ name: 'Etsy' }} />,
    //     description: "USER_GUIDE.CONNECTION.SHOP.ETSY"
    // },
    // dhl: {
    //     title: <FormattedMessage id="CONNECTIONS.SHIPPING_TITLE" values={{ name: 'DHL' }} />,
    //     description: "USER_GUIDE.CONNECTION.SHIPPING.DHL",
    // },
    // gls: {
    //     title: <FormattedMessage id="CONNECTIONS.SHIPPING_TITLE" values={{ name: 'GLS' }} />,
    //     description: "USER_GUIDE.CONNECTION.SHIPPING.GLS"
    // },
    // dpd: {
    //     title: <FormattedMessage id="CONNECTIONS.SHIPPING_TITLE" values={{ name: 'DPD' }} />,
    //     description: "USER_GUIDE.CONNECTION.SHIPPING.DPD"
    // },
    // 'deutsche-post': {
    //     title: <FormattedMessage id="CONNECTIONS.SHIPPING_TITLE" values={{ name: 'Deutschepost' }} />,
    //     description: "USER_GUIDE.CONNECTION.SHIPPING.DEUTSCHEPOST"
    // }

}

// ------------------------------------ SHIPPING ------------------------------------

// export const connectionShipping = {
//     main: {
//         title: "CONNECTIONS.SHIPPER",
//         location: 'shipping-connections',
//         description: "USER_GUIDE.CONNECTION.SHIPPING",
//     },
//     'dhl-settings': {
//         title: <FormattedMessage id="CONNECTIONS.SHIPPING_TITLE" values={{ name: 'DHL' }} />,
//         description: "USER_GUIDE.CONNECTION.SHIPPING.DHL",
//     },
//     'gls-settings': {
//         title: <FormattedMessage id="CONNECTIONS.SHIPPING_TITLE" values={{ name: 'GLS' }} />,
//         description: "USER_GUIDE.CONNECTION.SHIPPING.GLS"
//     },
//     'dpd-settings': {
//         title: <FormattedMessage id="CONNECTIONS.SHIPPING_TITLE" values={{ name: 'DPD' }} />,
//         description: "USER_GUIDE.CONNECTION.SHIPPING.DPD"
//     },
//     'deutsche-post-settings': {
//         title: <FormattedMessage id="CONNECTIONS.SHIPPING_TITLE" values={{ name: 'Deutschepost' }} />,
//         description: "USER_GUIDE.CONNECTION.SHIPPING.DEUTSCHEPOST"
//     }
// }


// ------------------------------------ SHOP ------------------------------------

// export const connectionShops = {
//     main: {
//         title: "CONNECTIONS.SHOP",
//         location: 'shop-connections',
//         description: "USER_GUIDE.CONNECTION.SHOP"
//     },
//     'ebay-settings': {
//         title: <FormattedMessage id="CONNECTIONS.SHOP_TITLE" values={{ name: 'Ebay' }} />,
//         description: "USER_GUIDE.CONNECTION.SHOP.EBAY"
//     },
//     'kaufland-settings': {
//         title: <FormattedMessage id="CONNECTIONS.SHOP_TITLE" values={{ name: 'Kaufland' }} />,
//         description: "USER_GUIDE.CONNECTION.SHOP.KAUFLAND"
//     },
//     'amazon-settings': {
//         title: <FormattedMessage id="CONNECTIONS.SHOP_TITLE" values={{ name: 'Amazon' }} />,
//         description: "USER_GUIDE.CONNECTION.SHOP.AMAZON"
//     },
//     'hood-settings': {
//         title: <FormattedMessage id="CONNECTIONS.SHOP_TITLE" values={{ name: 'Hood' }} />,
//         description: "USER_GUIDE.CONNECTION.SHOP.HOOD"
//     },
//     'check24-settings': {
//         title: <FormattedMessage id="CONNECTIONS.SHOP_TITLE" values={{ name: 'Check24' }} />,
//         description: "USER_GUIDE.CONNECTION.SHOP.CHECK24"
//     },
//     'manomano-settings': {
//         title: <FormattedMessage id="CONNECTIONS.SHOP_TITLE" values={{ name: 'Manomano' }} />,
//         description: "USER_GUIDE.CONNECTION.SHOP.MANOMANO"
//     },
//     'prestashop-settings': {
//         title: <FormattedMessage id="CONNECTIONS.SHOP_TITLE" values={{ name: 'PrestaShop' }} />,
//         description: "USER_GUIDE.CONNECTION.SHOP.PRESTASHOP"
//     },
//     'shopware5-settings': {
//         title: <FormattedMessage id="CONNECTIONS.SHOP_TITLE" values={{ name: 'Shopware 5' }} />,
//         description: "USER_GUIDE.CONNECTION.SHOP.SHOPWARE5"
//     },
//     'shopware6-settings': {
//         title: <FormattedMessage id="CONNECTIONS.SHOP_TITLE" values={{ name: 'Shopware 6' }} />,
//         description: "USER_GUIDE.CONNECTION.SHOP.SHOPWARE6"
//     },
//     'otto-settings': {
//         title: <FormattedMessage id="CONNECTIONS.SHOP_TITLE" values={{ name: 'Otto' }} />,
//         description: "USER_GUIDE.CONNECTION.SHOP.OTTO"
//     },
//     'woocommerce-settings': {
//         title: <FormattedMessage id="CONNECTIONS.SHOP_TITLE" values={{ name: 'Woocommerce' }} />,
//         description: "USER_GUIDE.CONNECTION.SHOP.WOOCOMMERCE"
//     },
//     'shopify-settings': {
//         title: <FormattedMessage id="CONNECTIONS.SHOP_TITLE" values={{ name: 'Shopify' }} />,
//         description: "USER_GUIDE.CONNECTION.SHOP.SHOPIFY"
//     },
//     'etsy-settings': {
//         title: <FormattedMessage id="CONNECTIONS.SHOP_TITLE" values={{ name: 'Etsy' }} />,
//         description: "USER_GUIDE.CONNECTION.SHOP.ETSY"
//     }
// }