import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { CloseButton } from '_metronic/_partials';
import { CustomerActions } from '../../_redux/actions';
import { ReportForm } from './ReportForm';




export function SetDocumentDialog({ show, onHide, id }) {
  return (
    <Modal show={show} onHide={onHide} backdrop="static" aria-labelledby="example-modal-sizes-title-sm" size="sm" centered>

      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id="CUSTOMER.FIND_DOCUMENTS" />
        </Modal.Title>
        <CloseButton onClick={() => { onHide(); CustomerActions.cleanStatesInStore({ countDocuments: {} }) }} />
      </Modal.Header>

      <Modal.Body className='p-3'>
        <ReportForm id={id} onHide={onHide} />
      </Modal.Body>

    </Modal>
  );
};
