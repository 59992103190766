import React from 'react';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import { RemotePagination } from '_metronic/_partials/controls';
import { NoRecordsFoundMessage, getSelectRow, UIDates } from '_metronic/_helpers';
import { allQueryKeys } from '../../reports-users/_context/ReportUIHelper';
import { sortCaret, headerSortingClasses } from '_metronic/_helpers';
import { ActionsColumnFormatter } from './ActionsColumnFormatter';
import { ACCOUNT_STATUS } from 'app/admin/_context/AdminUIHelper';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { AdminRoutes } from 'constants/moduleRoutes';
import { LicenseBadge } from '_metronic/_partials';
import { flags } from '_metronic/_helpers';
import { LastLoginFn } from './LastLoginFn';
import { Icon } from '_metronic/_icons';
import clsx from 'clsx';



export function UserTable({ users, count, loading, UIProps, setFilterParamsBase }) {

  const { formatMessage: intl } = useIntl();

  const COLUMNS = [
    {
      dataField: 'name',
      text: intl({ id: 'GENERAL.NAME' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '150px' },
      headerClasses: 'text-left text-nowrap',
      classes: 'text-left',
      formatter: (cell, row) => {
        const userStatus = ACCOUNT_STATUS?.find(item => item.value === row.status)

        return (
          <div className='d-flex align-items-center'>
            {/* TODO: status passive ve active kullanicida Kullanici db sinden LogEntry dan son giris tarihi alinacak */}
            <OverlayTrigger overlay={<Tooltip id="language-panel-tooltip">
              <>
                <div className='font-weight-bolder'>
                  <FormattedMessage id={userStatus?.label ? userStatus?.label : 'GENERAL.OTHER'} />
                </div>
                {row.status === 'deleted' && <div className='border-top mt-3'>
                  <span className='font-weight-bold mr-1'>
                    <FormattedMessage id='ADMIN.USER.DELETE_REQUEST_DATE' />
                  </span>
                  {UIDates.formatDateTime(row?.fail_log?.deleted_request?.created_at)}
                </div>}
              </>
            </Tooltip>}>
              <div className={`symbol symbol-40 symbol-light-${userStatus?.className} mr-3`}>
                <div className="symbol-label">
                  <span type="button" className={clsx("svg-icon svg-icon-lg", userStatus?.textColor)} onClick={() => UIProps.openUserDetailsDialog(row.id)} id='btn_customer_preview'>
                    <Icon.Person />
                  </span>
                </div>
              </div>
            </OverlayTrigger>
            <div>
              <Link to={AdminRoutes.ADMIN_USERS_EDIT_FN(row.id)} type="button" className="d-flex align-items-center text-decoration-none text-dark text-hover-primary text-nowrap preview" id='btn_customer_preview'>
                <span className='font-size-lg font-weight-bold'>
                  {`${row.name} ${row.surname ? row.surname : ''}`}
                </span>
                <span className='d-flex invisible text-dark-25 svg-icon svg-icon-sm pl-2'>
                  <Icon.Pencil />
                </span>
              </Link>
              <div type="button" className="d-flex align-items-center text-nowrap preview" onClick={() => navigator.clipboard.writeText(row.id)} id='btn_customer_preview'>
                <samp className='font-weight-bold text-dark-50 text-hover-primary'>
                  {row.id}
                </samp>
                <span className='d-flex invisible text-dark-25 svg-icon svg-icon-sm pl-2'>
                  <Icon.Clipboard />
                </span>
              </div>
            </div>

          </div>
        )
      },
    },
    {
      dataField: 'email',
      text: intl({ id: 'GENERAL.EMAIL' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerClasses: 'text-nowrap',
      classes: 'font-weight-bold',
      headerSortingClasses,
      formatter: (cell, row) => (
        <a href={`mailto:${cell}`} className="text-decoration-none text-dark text-hover-primary">
          {cell}
        </a>
      )
    },
    {
      dataField: 'license.type',
      text: intl({ id: 'ADMIN.USER.LICENSE_TYPE' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center text-nowrap',
      classes: 'text-center text-nowrap pr-7',
      headerStyle: { minWidth: '130px' },
      formatter: (cell, row) => (<LicenseBadge license={row.license} />)
    },
    {
      dataField: 'success_login.count',
      text: intl({ id: 'ADMIN.USER.LOGIN_COUNT' }),
      headerClasses: 'text-center',
      classes: 'text-center font-weight-bolder',
      formatter: (cell, row) => (
        row.fail_log?.login?.count ?
          <div className={clsx('label label-xl label-inline label-rounded font-weight-bolder px-2', row.fail_log.login.count <= 2 ? "label-light-warning text-warning" : "label-light-danger text-danger",)}>
            <span className={`label label-xl label-dot mr-2 ${row.fail_log.login.count <= 2 ? "label-warning" : "label-danger"}`}></span>
            {row.fail_log.login.count}
          </div>
          : null
      ),
    },
    {
      dataField: 'success_login.time',
      text: intl({ id: 'ADMIN.USER.LAST_LOGIN' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center text-nowrap pl-4',
      classes: 'text-center',
      headerStyle: { minWidth: '120px' },
      formatter: (cell, row) => (LastLoginFn(cell)),
    },
    {
      dataField: 'newsletter',
      text: 'Newsletter',
      headerClasses: 'text-center',
      classes: 'text-center',
      formatter: (cell, row) => (cell
        ? <label className="svg-icon svg-icon-md text-success">
          <Icon.CheckCircle />
        </label>
        : null
      ),
    },
    {
      dataField: 'language',
      text: intl({ id: 'ADMIN.LANGUAGE' }),
      headerClasses: 'text-center',
      classes: 'text-center',
      formatter: (cell, row) => {
        const currentLanguage = flags.find((x) => x.lang === cell);
        return (
          <OverlayTrigger overlay={<Tooltip id="language-panel-tooltip">{currentLanguage?.name}</Tooltip>}>
            <span className='icon-lg'>
              {currentLanguage?.flag}
            </span>
          </OverlayTrigger>
        )
      },
    },
    {
      dataField: 'cron',
      text: 'Cron',
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center',
      classes: 'text-center pr-7',
      headerStyle: { minWidth: '80px' },
      formatter: (cell, row) => (
        <OverlayTrigger overlay={<Tooltip id="note-tooltip"> <div className='text-left'>{<FormattedMessage id={cell === false ? "ADMIN.USER.ACCOUNT_STATUS.INACTIVE" : "ADMIN.USER.ACCOUNT_STATUS.ACTIVE"} />}</div> </Tooltip>}>
          <div className='svg-icon svg-icon-md'>
            {cell === false
              ? <Icon.ToggleOn className="text-danger" style={{ transform: "rotate(180deg)" }} />
              : <Icon.ToggleOn className="text-success" />
            }
          </div>
        </OverlayTrigger>
      ),
    },
    {
      dataField: 'note',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.NOTE' }),
      headerClasses: 'text-center',
      classes: 'text-center',
      formatter: (cell, row) => {
        return (
          cell && (
            <OverlayTrigger overlay={<Tooltip id="note-tooltip"> <div className='text-left'>{cell}</div> </Tooltip>}>
              <div className='svg-icon text-dark-50' onClick={() => UIProps.openUserNoteDialog({ id: row.id, note: cell })} style={{ cursor: 'pointer' }}>
                <Icon.ChatSquareText />
              </div>
            </OverlayTrigger>
          )
        );
      },
    },
    {
      dataField: 'created_at',
      text: intl({ id: 'GENERAL.REGISTRATION_DATE' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center text-nowrap pl-4',
      classes: 'text-center pr-7',
      headerStyle: { minWidth: '100px' },
      formatter: (cell, row) => (
        <div className='font-size-sm text-dark-50 font-weight-bold mb-1'>
          {UIDates.formatDateTime(cell)}
        </div>
      ),
    },
    {
      dataField: 'action',
      text: '...',
      classes: 'text-center text-nowrap',
      headerClasses: 'text-center icon-lg',
      headerStyle: { minWidth: '100px' },
      formatter: ActionsColumnFormatter,
    },
  ];


  return (
    <>
      <BootstrapTable
        keyField="id"
        data={users || []}
        columns={COLUMNS}
        key={UIProps.ids}
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center"
        bootstrap4
        striped
        bordered={false}
        defaultSorted={UIProps.defaultSorted}
        noDataIndication={<NoRecordsFoundMessage loading={loading} onClick={() => { UIProps.setFilterQuery('DELETE_PARAMS', allQueryKeys); setFilterParamsBase({}); UIProps.changeDateRange() }} />}
        selectRow={getSelectRow({
          ids: UIProps.ids,
          setIds: UIProps.setIds,
          entities: users,
        })}
      />
      <RemotePagination
        queryParams={UIProps.queryParams.pagination}
        setQueryParams={UIProps.setPaginationQuery}
        totalCount={count}
        entitiesCount={users?.length}
        loading={loading}
      />
    </>
  );
}
