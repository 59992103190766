/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function ActionsColumnFormatter(cellContent, row, rowIndex, { openDeleteProductKeysDialog }) {
  return (
    <OverlayTrigger overlay={<Tooltip id="spec-delete-tooltip"><FormattedMessage id={row.order_date ? "PRODUCT.KEYS.DELETE_SENT_WARNING" : "GENERAL.DELETE"} /></Tooltip>}>
      <Button variant='icon' size='sm' className={`btn-hover-danger`} onClick={() => !row.order_date && openDeleteProductKeysDialog([row.id])} disabled={row.order_date}>
        <span className="svg-icon svg-icon-md">
          <Icon.Trash />
        </span>
      </Button>
    </OverlayTrigger>
  );
}