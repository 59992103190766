/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from 'react';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import packagejson from "../../../../../package.json";
import { LANDING_PAGE } from 'constants/apiUrl';
import { FormattedMessage } from 'react-intl';
import { UIDates } from '_metronic/_helpers';
import Contract from './Contract';
import Contact from './Contact';
import clsx from 'clsx';



export function Footer() {

  const [showModal, setShowModal] = useState(false);
  const [showContractModal, setContractShowModal] = useState(false);
  const [contract, setContract] = useState(null);

  const UIService = useHtmlClassService();
  const layoutProps = useMemo(() => ({
    footerClasses: UIService.getClasses('footer', true),
    footerContainerClasses: UIService.getClasses('footer_container', true),
  }), [UIService]);

  return (
    <div className={clsx("footer bg-white py-2 d-flex flex-lg-column", layoutProps.footerClasses)} id="kt_footer">
      <div className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}>

        <div className="nav nav-dark order-2 order-md-1">

          <span type="button" className="nav-link pl-3 pt-0 pt-md-3" onClick={() => setShowModal(true)}>
            &copy; {UIDates.getYear()} SaleVali v{packagejson.version}
          </span>
        </div>

        <div className="nav nav-dark order-1 order-md-2">
          <span type="button" className="nav-link px-3" onClick={() => { setContractShowModal(true); setContract('privacy') }}>
            <FormattedMessage id='AUTH.GENERAL.PRIVACY' />
          </span>
          <span type="button" className="nav-link px-3" onClick={() => { setContractShowModal(true); setContract('conditions') }}>
            <FormattedMessage id='AUTH.GENERAL.TERMS' />
          </span>
          <a href={`${LANDING_PAGE}#scroll_kontakt`} className="nav-link px-3" target="_blank" rel="noopener noreferrer">
            <FormattedMessage id='GENERAL.CONTACT' />
          </a>
          <a href={LANDING_PAGE} className="nav-link px-3" target="_blank" rel="noopener noreferrer">
            <FormattedMessage id='FOOTER.ABOUT' />
          </a>
          {/* <a href={LANDING_PAGE} target="_blank" rel="noopener noreferrer" className="nav-link px-3">
            <FormattedMessage id='FOOTER.TEAM' />
          </a> */}
        </div>

      </div>
      <Contact show={showModal} onHide={() => setShowModal(false)} />
      <Contract show={showContractModal} contract={contract} onHide={() => { setContractShowModal(false); setContract(null) }} />
    </div>
  );
}