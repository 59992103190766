import React from 'react'
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls'
import { FormattedMessage, useIntl } from 'react-intl';
import { BlankMessage } from "_metronic/_partials/components";
import { IMPORT_TITLE } from '../_context/ImportExportUIHelper';
import { Icon } from '_metronic/_icons';



export default function ImportUpload({ importType }) {

  const { formatMessage: intl } = useIntl();

  const selectedType = IMPORT_TITLE.find(item => item.module === importType)


  return (
    <Card className='card-stretch gutter-b'>

      <CardSubHeader
        title={selectedType ? intl({ id: selectedType.label }) : <FormattedMessage id='IMPORT_EXPORT.IMPORT.FILE_UPLOAD' />}
        icon={<Icon.CloudUpload className="mt-1" />}
      />

      <CardBody>

        {importType
          ? selectedType?.component
          : <BlankMessage
            icon={<Icon.CloudUpload />}
            title={<FormattedMessage id='IMPORT_EXPORT.IMPORT.FILE_UPLOAD.BLANK_MESSAGE_TITLE' />}
            message={<FormattedMessage id='IMPORT_EXPORT.IMPORT.FILE_UPLOAD.BLANK_MESSAGE' />}
            className="my-10"
          />
        }

      </CardBody>

    </Card>
  )
}
