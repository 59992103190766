import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Brand } from '../brand/Brand';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { AsideMenuAdmin } from './AsideMenuAdmin';
import { PROD_DOMAIN } from 'constants/apiUrl';
import { AsideMenu } from './AsideMenu';



export function Aside() {

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => ({
    disableScroll: objectPath.get(uiService.config, 'aside.menu.dropdown') === 'true' || false,
    asideClassesFromConfig: uiService.getClasses('aside', true),
  }), [uiService]);

  const { role, licenseType, ordersCount } = useSelector(state => ({
    role: state.auth.user.role,
    licenseType: state.auth.license.type,
    ordersCount: state.notification.orders_count,
  }));

  const production = window.location.hostname === PROD_DOMAIN


  return (
    <div className={`aside aside-left ${layoutProps.asideClassesFromConfig} d-flex flex-column flex-row-auto`} id="kt_aside">

      <Brand />

      {/* begin::Aside Menu */}
      <div className="aside-menu-wrapper flex-column-fluid" id="kt_aside_menu_wrapper">
        {role === "644c41e257d397eb064fe0f9"
          ? <AsideMenuAdmin disableScroll={layoutProps.disableScroll} />
          : <AsideMenu
            ordersCount={ordersCount}
            development={['development', 'tester'].includes(licenseType) && !production}
            disableScroll={layoutProps.disableScroll}
          />
        }
      </div>
      {/* end::Aside Menu */}

    </div>
  );
}
