import React from 'react';
import { UserSettingsUIProvider } from './_context/UserSettingsUIContext';
import { CategoriesUIProvider } from './category/_context/CategoriesUIContext';
import SettingsCard from './SettingsCard';



export default function Settings() {
  return (
    <UserSettingsUIProvider>
      <CategoriesUIProvider>

        <SettingsCard />

      </CategoriesUIProvider>
    </UserSettingsUIProvider>
  );
}