import React, { useEffect, useMemo } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSupplierUIContext } from "../../_context/SupplierUIContext";
import { Portal } from "react-portal";



export function AddressDeleteDialog() {

  const UIContext = useSupplierUIContext();
  const UIProps = useMemo(() => ({
    id: UIContext.selectedId,
    showDeleteAddressDialog: UIContext.showDeleteAddressDialog,
    closeDeleteAddressDialog: UIContext.closeDeleteAddressDialog,
    deleteAddressHandler: UIContext.deleteAddressHandler
  }), [UIContext]);


  useEffect(() => {
    !UIProps.id && UIProps.closeDeleteAddressDialog()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UIProps.id]);


  const deleteAddress = () => {
    UIProps.deleteAddressHandler(UIProps.id);
    UIProps.closeDeleteAddressDialog();
  };


  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={UIProps.showDeleteAddressDialog} onHide={UIProps.closeDeleteAddressDialog} aria-labelledby="example-modal-sizes-title-lg">

        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="SUPPLIER.DELETE_ADDRESS_CONTENT" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FormattedMessage id="SUPPLIER.DELETE_ADDRESS" />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="light" onClick={UIProps.closeDeleteAddressDialog} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant="primary" className="ml-2" onClick={deleteAddress} id="btn_delete_modal">
            <FormattedMessage id="GENERAL.DELETE" />
          </Button>
        </Modal.Footer>

      </Modal>
    </Portal>
  );
}
