import React from 'react'
import { Field } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import { Alert, Col, Row } from 'react-bootstrap'
import { SVFormControl } from '_metronic/_partials/controls';
import { Icon } from '_metronic/_icons';



const INPUT = [
  {
    name: "password",
    label: 'AUTH.INPUT.PASSWORD',
    autoComplete: 'current-password',
    minLength: "6",
    maxLength: "50",
  },
  {
    name: "new_password",
    label: 'AUTH.INPUT.NEW_PASSWORD',
    autoComplete: 'new-password',
    minLength: "8",
    maxLength: "50",
  },
];

export default function PasswordEditForm({ success, loading, message }) {

  const { formatMessage: intl } = useIntl();

  return (
    <Row>

      <Col md={12}>
        <Alert variant='light-info' className="alert-custom alert-notice mb-6">
          <div className="text-dark-75 line-height-xl">
            <FormattedMessage id='SETTINGS.LOGIN.CHANGE_PASSWORD.DESCRIPTION' />
          </div>
        </Alert>
      </Col>

      {INPUT.map((item, i) => (
        <Col md={12} key={i}>
          <Field component={SVFormControl}
            {...{
              type: 'password',
              name: item.name,
              label: intl({ id: item.label }),
              autoComplete: item.autoComplete,
              minLength: item.minLength,
              maxLength: item.maxLength,
              withFeedbackLabel: true,
              feedbackLabel: true,
              disabled: success || loading,
            }}
          />
        </Col>
      ))}

      {success &&
        <Col md={12}>
          <Alert variant='success' className="svg-icon mb-0">
            <Icon.CheckCircle className="mr-2" />
            <FormattedMessage id={message ? message : 'SERVER.LOGIN.CHANGE_PASSWORD_SUCCESS'} />
          </Alert>
        </Col>
      }

    </Row>
  )
}
