import React from "react";
import { Icon } from '_metronic/_icons';



export const flags = [
  {
    lang: "de",
    name: "Deutsch",
    flag: <Icon.FlagGermany className="rounded" />,
  },
  {
    lang: "en",
    name: "English",
    flag: <Icon.FlagUnitedStates className="rounded" />,
  },
  {
    lang: "tr",
    name: "Türkçe (Beta)",
    flag: <Icon.FlagTurkey className="rounded" />,
    hide: true,
  },
];
