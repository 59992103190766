import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Icon } from '_metronic/_icons'



export default function SelectCounter({ count }) {
  return (
    <>
      {count > 0 && (
        <div className="text-primary svg-icon svg-icon-sm text-nowrap font-weight-bold my-2 mr-2" id="btn_select">
          <Icon.CheckCircle className="mr-3" />
          <span className="d-none d-sm-inline mr-1">
            <FormattedMessage id="GENERAL.SELECTED" /> :
          </span>
          {count}
        </div>
      )}
    </>
  )
}
