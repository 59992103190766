import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { ShippingsActions } from "../_redux/actions";
import { Icon } from '_metronic/_icons';



export function ShippingsGrouping({ UIProps }) {

  const dispatch = useDispatch();

  const openPrintLabelsDialog = () => {
    if (UIProps.ids.length) {
      dispatch(ShippingsActions.getShipping(UIProps.ids))
      UIProps.openPrintLabelsDialog("all-labels");
    }
  };


  return (
    <>
      <Button variant="light-primary" className="svg-icon svg-icon-sm font-weight-bold ml-auto"
        onClick={openPrintLabelsDialog}
        disabled={!UIProps.ids || UIProps.ids.length <= 0}
        style={{ cursor: !UIProps.ids || (UIProps.ids.length <= 0 && "not-allowed") }}
        id="btn_print"
      >
        <Icon.Printer className="mr-2" />
        <FormattedMessage id="GENERAL.PRINT" />
      </Button>
    </>
  );
}