/* Pagination Helpers */
import React from "react";
import { Icon } from '_metronic/_icons';
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";


export const sizePerPageList = [
  { text: '5', value: 5 },
  { text: '10', value: 10 },
  { text: '20', value: 20 },
  { text: '30', value: 30 },
  { text: '40', value: 40 },
  { text: '50', value: 50 },
  { text: '100', value: 100 },
];


export const getPagesCount = (entitiesCount, limit) => {
  return Math.ceil(entitiesCount / limit);
};


export const getPages = (page, pagesCount, paginationSize) => {
  const result = [];
  if (!page) {
    return result;
  }

  if (pagesCount === 1) {
    result.push(1);
    return result;
  }

  if (pagesCount < page) {
    return result;
  }

  if (pagesCount < paginationSize + 1) {
    for (let i = 1; i < pagesCount + 1; i++) {
      result.push(i);
    }
    return result;
  }

  if (page === 1) {
    for (let i = 1; i < paginationSize + 1; i++) {
      if (i < pagesCount) {
        result.push(i);
      }
    }
    return result;
  }

  if (page === pagesCount) {
    for (let i = pagesCount - paginationSize + 1; i <= pagesCount; i++) {
      if (i <= pagesCount) {
        result.push(i);
      }
    }
    return result;
  }

  const shiftCount = Math.floor(paginationSize / 2);
  if (shiftCount < 1) {
    result.push(page);
    return result;
  }

  //
  if (page < shiftCount + 2) {
    for (let i = 1; i < paginationSize + 1; i++) {
      result.push(i);
    }
    return result;
  }

  if (pagesCount - page < shiftCount + 2) {
    for (let i = pagesCount - paginationSize; i < pagesCount + 1; i++) {
      result.push(i);
    }
    return result;
  }

  for (let i = page - shiftCount; i < page; i++) {
    if (i > 0) {
      result.push(i);
    }
  }
  result.push(page);
  for (let i = page + 1; i < page + shiftCount + 1; i++) {
    if (i <= pagesCount) {
      result.push(i);
    }
  }

  return result;
};


export function getHandlerTableChange(setQueryParams) {
  return (type, { page, limit, sortField, sortOrder, data }) => {
    const pageNumber = page || 1;
    setQueryParams((prev) =>
      type === "sort"
        ? { ...prev, sortOrder, sortField }
        : type === "pagination"
          ? { ...prev, pageNumber, pageSize: limit }
          : prev
    );
  };
}


export function PleaseWaitMessage({ entities }) {
  return <>{entities === null && <FormattedMessage id="GENERAL.WAIT" />}</>;
}


export function NoRecordsFoundMessage({ loading, onClick }) {
  return (
    loading
      ? <FormattedMessage id="GENERAL.WAIT" />
      : <>
        <Icon.SearchNot className="mb-3 icon-3x" />
        <div className="font-weight-bold">
          <FormattedMessage id="GENERAL.NO_RESULT_FOUND" />
        </div>
        {onClick &&
          <Button variant="link" className="font-weight-bold" onClick={onClick} id="btn_clear_search">
            <FormattedMessage id="GENERAL.CLEAR_SEARCH" />
          </Button>}
      </>
  );
}
