import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { useDashboardUIContext } from '../_context/DashboardUIContext';
import { convertPriceCurrency } from '_metronic/_helpers';
import { CloseButton } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl';
import { Portal } from 'react-portal';
import { isEmpty } from 'lodash';
// import { BASE_URL } from 'constants/apiUrl';
// import { Icon } from '_metronic/_icons';



export function ProductDetails() {

  const UIContext = useDashboardUIContext();

  const UIProps = useMemo(() => ({
    show: UIContext.showProductDetailsDialog,
    onHide: UIContext.closeProductDetailsDialog,
    productId: UIContext.productId,
  }), [
    UIContext.closeProductDetailsDialog,
    UIContext.showProductDetailsDialog,
    UIContext.productId
  ]);

  const { top_product } = useSelector(state => state.dashboard)

  const product = top_product.list?.find((product) => product.product_id === UIProps.productId)

  useEffect(() => {
    !UIProps.productId && UIProps.onHide();
  }, [UIProps]);


  // useEffect(() => {
  //   return () => {
  //     dispatch(ProductActions.resetFormHandler());
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);


  return (
    <Portal node={document && document.getElementById('modal-portal')}>
      <Modal show={UIProps.show} onHide={UIProps.onHide} keyboard={false} aria-labelledby="example-modal-sizes-title-md" size='md' centered>

        <Modal.Body className='font-weight-bold overflow-auto px-10 pt-10 pt-md-15'>

          <CloseButton onClick={UIProps.onHide} className="position-absolute top-0 right-0 mr-10 mt-10" />

          {product && !isEmpty(product) && (
            <div>

              {/* <div className="d-flex justify-content-between flex-column flex-md-row">

                <div className=' d-flex justify-content-center overflow-hidden border p-2 rounded h-200px h-md-auto h-lg-200px h-xxl-auto w-200px'>
                  {product?.length > 0
                    ? <img className='w-100 h-100' src={product && `${BASE_URL}/${product[0]?.thumbnail}`} alt={product && product[0]?.name} />
                    : <Icon.Image className="h-100 w-50" style={{ opacity: 0.3 }} />}
                </div>
              </div> */}


              {/* --------------------------------- Product --------------------------------- */}


              <h1 className="font-weight-boldest text-md-left display-5 text-break mb-5">
                {product?.title}
              </h1>
              <div className="font-weight-boldest text-primary font-size-h2">
                {convertPriceCurrency(product?.price, product?.currency)}
              </div>

              <div className="separator separator-dashed my-5 my-md-8" />


              {/* --------------------------------- Pricing --------------------------------- */}


              <Row className="d-flex justify-content-between pl-5 py-5 bg-light-info ">

                <Col xs="6" md="3" className="py-2 ">
                  <div className="font-weight-bolder text-uppercase mb-2">
                    <FormattedMessage id="GENERAL.SKU" />
                  </div>
                  <div className="opacity-70">
                    {product?.sku}
                  </div>
                </Col>

                {product?.quantity > 0 &&
                  <Col xs="6" md="3" className="text-md-center py-2">
                    <div className="font-weight-bolder text-uppercase mb-2">
                      <FormattedMessage id="GENERAL.QUANTITY" />
                    </div>
                    <div className="opacity-70">
                      {product?.quantity}
                    </div>
                  </Col>}

                <Col xs="6" md="3" className="text-md-right py-2">
                  <div className="font-weight-bolder text-uppercase mb-2">
                    <FormattedMessage id="PRODUCT.TABLE.PRICE_GROSS" />
                  </div>
                  <div className="opacity-70">
                    {convertPriceCurrency(product?.price, product?.currency)}
                  </div>
                </Col>

                <Col xs="6" md="3" className="py-2 text-md-right">
                  <div className="font-weight-bolder text-uppercase mb-2">
                    <FormattedMessage id="GENERAL.SALE" />
                  </div>
                  <div className="text-primary">
                    {convertPriceCurrency(product?.total_gross, product?.currency)}
                  </div>
                </Col>

              </Row>

            </div>
          )}
        </Modal.Body>

        <Modal.Footer>

          <Button variant='light' onClick={UIProps.onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          {/* <Link className="btn btn-primary mr-2" to={ModuleRoutes.EDIT_PRODUCT_FN(UIProps.productId)} id='btn_edit_modal'>
            <FormattedMessage id='GENERAL.EDIT' />
          </Link> */}

        </Modal.Footer>

      </Modal>
    </Portal>
  );
};