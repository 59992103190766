import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Table, Col, Row } from 'react-bootstrap';
import { useDocumentsUIContext } from '../_context/DocumentsUIContext';
import { getCountryList, UIDates, convertPriceCurrency } from '_metronic/_helpers';
import * as UIHelpers from '../_context/DocumentsUIHelpers';
import { ModuleRoutes } from 'constants/moduleRoutes';
import PerfectScrollbar from "react-perfect-scrollbar";
import { DocumentActions } from '../_redux/actions';
import { CloseButton } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl';
import { isEmpty, isArray } from 'lodash';
import { useLang } from '_metronic/i18n';
import { Link } from 'react-router-dom';
import { Portal } from 'react-portal';
import clsx from 'clsx';



export function DocumentDetailsDialog() {

  const locale = useLang();
  const dispatch = useDispatch();

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);

  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showDocumentDetailsDialog,
    onHide: UIContext.closeDocumentDetailsDialog,
    documentId: UIContext.documentId,
    openEditDocumentDialog: UIContext.openEditDocumentDialog,
    requestedDocument: UIContext.requestedDocument,
  }), [
    UIContext.closeDocumentDetailsDialog,
    UIContext.documentId,
    UIContext.showDocumentDetailsDialog,
    UIContext.openEditDocumentDialog,
    UIContext.requestedDocument,
  ]);

  const { documents } = useSelector(state => ({
    documents: state.documents.getDocumentById
  }));

  const { id, name, email, phone, company, delivery_address, discount: customerDiscount } = documents?.customer_details;
  const currency = documents?.pricing_summary?.currency;
  const deliveryDate = documents?.shipping?.find(item => item.type === 'shipment')?.delivery_date

  // yerelde oluşturulan sipariş indirim oranı (%)
  const discount = documents?.pricing_summary?.discount ?? customerDiscount ?? 0;

  // apiden gelen siparişlerdeki indirim tutarı (xx EUR) (ebay için yapıldı sadece)
  const marketPriceDiscount = documents?.pricing_summary?.total_price_discount ?? 0;

  const calculateDiscountTaxTotal = documents?.position?.reduce((totalTax, position) => {
    const positionPriceDiscount = position.price_discount ?? 0;
    if (positionPriceDiscount === 0) {
      return totalTax;
    }

    const taxRate = position.tax_rate ?? 0;
    return totalTax + positionPriceDiscount - positionPriceDiscount / (1 + taxRate / 100);
  }, 0);

  const rest = convertPriceCurrency(+totalPrice - +totalPaid, currency);

  useEffect(() => {
    UIProps.documentId && dispatch(DocumentActions.getDocumentById(UIProps.documentId));
  }, [dispatch, UIProps.documentId]);

  // if !id we should close modal
  useEffect(() => {
    !UIProps.documentId && UIProps.onHide();
  }, [UIProps]);

  useEffect(() => {
    let totalPayment = 0;
    if (isArray(documents.entered_payment)) {
      const paid = documents.entered_payment.reduce((acc, payment) => acc + payment.amount, 0);
      totalPayment = paid;
    }
    const totalPrice = (+documents.pricing_summary.total + +documents.pricing_summary.shipping_price - (discount ?? 0) * (+documents.pricing_summary.total / 100));
    setTotalPaid(totalPayment);
    setTotalPrice(totalPrice);
  }, [documents.entered_payment, documents.pricing_summary, discount]);

  useEffect(() => {
    return () => {
      dispatch(DocumentActions.cleanUpDocumentStore());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Portal node={document && document.getElementById('modal-portal')}>
      <Modal show={UIProps.show} onHide={UIProps.onHide} keyboard={false} aria-labelledby="example-modal-sizes-title-lg" size='xl'>

        <Modal.Body className="font-weight-bold px-10 pt-10 pt-md-25">

          <CloseButton onClick={UIProps.onHide} className="position-absolute top-0 right-0 mr-10 mt-10" />

          {documents && !isEmpty(documents) && (
            <>

              {/* --------------------------------- CUSTOMER --------------------------------- */}

              <div className="d-flex justify-content-between flex-column flex-md-row">
                <h1 className="font-weight-boldest display-4 text-nowrap">
                  <FormattedMessage id='DOCUMENT.DETAIL_DIALOG.TITLE' values={{ title: UIProps.requestedDocument.title }} />
                </h1>
                <div className="d-flex flex-column align-items-md-end px-0 pr-5">
                  <span className="d-flex flex-column text-md-right opacity-70">
                    <Link to={ModuleRoutes.EDIT_CUSTOMER_FN(id)} type="button" className="text-decoration-none text-hover-primary" onClick={UIProps.onHide} id="btn_customer_preview">
                      {name}
                    </Link>
                    <span className='text-break'>{company}</span>
                    <span>{phone}</span>
                    <span>{email}</span>
                  </span>
                </div>
              </div>

              <div className="separator separator-dashed my-5 my-md-10" />

              <PerfectScrollbar options={{ wheelSpeed: 1, wheelPropagation: false }} className="scroll" style={{ maxHeight: "calc(100vh - 400px)", minHeight: "200px" }}>


                {/* --------------------------------- ORDER SUMMARY --------------------------------- */}

                <Row className="d-flex justify-content-between pl-5 mx-0">

                  <Col xs="6" md="3" className="py-5">
                    <div className="font-weight-bolder text-uppercase mb-2">
                      <FormattedMessage id="DOCUMENT.TABLE.COLUMN.DOCUMENT_ID" values={{ title: UIProps.requestedDocument.title }} />
                    </div>
                    <div className="opacity-70">
                      {UIProps.requestedDocument.type === 'orders'
                        ? documents?.order_number
                          ? documents?.order_number
                          : documents?.order_id
                            ? documents?.order_id
                            : documents?.id
                        : documents?.id
                      }
                    </div>
                  </Col>

                  <Col xs="6" md="3" className="py-5">
                    <div className="text-center font-weight-bolder text-uppercase mb-2">
                      <FormattedMessage id={
                        UIProps.requestedDocument.type === 'orders'
                          ? "DOCUMENT.DETAIL_DIALOG.ORDER.DATE"
                          : UIProps.requestedDocument.type === 'invoices'
                            ? "DOCUMENT.DETAIL_DIALOG.INVOICE.DATE"
                            : "DOCUMENT.DETAIL_DIALOG.CREATION_DATE"
                      }
                      />
                    </div>
                    <div className="text-center opacity-70">
                      {UIProps.requestedDocument.type === 'orders'
                        ? UIDates.formatDateTime(documents?.order_date)
                        : UIDates.formatDateTime(documents?.created_at)}
                    </div>
                  </Col>

                  <Col xs="6" md="3" className="py-5">
                    <div className="text-center font-weight-bolder text-uppercase mb-2">
                      <FormattedMessage id="GENERAL.DELIVERY_DATE" />
                    </div>
                    <div className="text-center opacity-70">
                      {deliveryDate ? UIDates.formatDate(deliveryDate) : "-"}
                    </div>
                  </Col>

                  <Col xs="6" md="3" className="py-5">
                    <div className="font-weight-bolder text-uppercase text-right mb-2">
                      <FormattedMessage id="DOCUMENT.DETAIL_DIALOG.DELIVERY_TO" />
                    </div>
                    <div className="text-right opacity-70">
                      <div>{delivery_address?.address_line1} {delivery_address?.address_line2}</div>
                      <div>{delivery_address?.post_code} {delivery_address?.city}</div>
                      <div>{getCountryList(locale).find(code => code.value === delivery_address?.country_code)?.label}</div>
                    </div>
                  </Col>

                </Row>

                <div className="separator separator-dashed my-5 my-md-10" />


                {/* --------------------------------- POSITIONS --------------------------------- */}

                {documents.position?.length > 0 && <>
                  <div className="table-responsive">
                    <Table className='mb-0'>
                      <thead>
                        <tr>
                          <th className="font-weight-bold text-muted text-uppercase pl-5">
                            <FormattedMessage id="GENERAL.DESCRIPTION" />
                          </th>
                          <th className="font-weight-bold text-center text-muted text-uppercase">
                            <FormattedMessage id="GENERAL.QUANTITY" />
                          </th>
                          <th className="font-weight-bold text-right text-muted text-uppercase">
                            <FormattedMessage id="GENERAL.UNIT_PRICE" />
                          </th>

                          {(marketPriceDiscount !== 0 || discount !== 0) &&
                            <th className="font-weight-bold text-right text-muted text-uppercase">
                              <FormattedMessage id="GENERAL.DISCOUNT_VALUE" values={{ name: marketPriceDiscount !== 0 ? `${convertPriceCurrency(-marketPriceDiscount, currency)}` : `${discount} %` }} />
                            </th>
                          }

                          <th className="font-weight-bold text-right text-muted text-uppercase pr-5">
                            <FormattedMessage id="DOCUMENT.POSITION_TOTAL_PRICE" />
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {documents.position.map((position, index) => (
                          <tr className="font-weight-boldest" key={index}>
                            <td className="d-flex flex-column pt-3 pl-5">
                              <span>{position.title}</span>
                              <span className="font-weight-bold text-dark-25">
                                {position.sku}
                              </span>
                            </td>
                            <td className="text-center align-middle pt-3">
                              {position.quantity}
                            </td>
                            <td className="text-right align-middle pt-3">
                              {convertPriceCurrency(+position?.gross_price, currency)}
                            </td>

                            {(marketPriceDiscount !== 0 || discount !== 0) &&
                              <td className="text-right align-middle pt-3">
                                {convertPriceCurrency(-(marketPriceDiscount !== 0 ? position.price_discount : (discount * (+position.gross_price / 100))), currency)}
                              </td>
                            }

                            <td className="text-primary text-right align-middle pt-3 pr-5">
                              {convertPriceCurrency(+position?.total_gross_price, currency)}
                            </td>
                          </tr>
                        ))}
                      </tbody>

                    </Table>
                  </div>

                  <div className="separator separator-dashed my-5 my-md-10" />
                </>
                }


                {/* --------------------------------- SHIPPING ---------------------------------


                {["orders", "invoices", "credits"].includes(UIProps.requestedDocument.type) &&
                  <>

                    <Row className="d-flex justify-content-between font-weight-bold bg-light-info pl-5 py-5 px-6">

                      <Col xs="6" md="3" className="text-center py-5 px-0">
                        <div className="text-muted text-uppercase border-bottom pb-5 mb-5">
                          <FormattedMessage id="DOCUMENT.SHIPPING_METHOD" />
                        </div>
                        <div className="font-weight-bolder">
                          {documents?.shipping_method}
                        </div>
                      </Col>

                      {documents?.tracking_id !== '' ?
                        <Col xs="6" md="3" className=" text-center py-5 px-0 pr-2">
                          <div className="text-muted text-uppercase border-bottom pb-5 mb-5">
                            <FormattedMessage id="DOCUMENT.SHIPPING_TRACKING_ID" />
                          </div>
                          <div className="font-weight-bolder text-break">
                            {documents.tracking_id}
                          </div>
                        </Col>
                        : null}


                      <Col xs="6" md="3" className="text-center py-5 px-0">
                        <div className="text-muted text-uppercase border-bottom pb-5 mb-5">
                          <FormattedMessage id="GENERAL.DELIVERY_DATE" />
                        </div>
                        <div className="font-weight-bolder ">
                          {moment(documents?.delivery_date).format('YYYY-MM-DD')}
                        </div>
                      </Col>

                      <Col xs="6" md="3" className=" text-md-center py-5 px-0">
                        <div className="text-muted text-uppercase border-bottom pb-5 mb-5">
                          <FormattedMessage id="DOCUMENT.SHIPPING_PRICE" />
                        </div>
                        <div className="font-weight-boldest text-md-center">
                          {documents?.pricing_summary.shipping_price} €
                        </div>
                      </Col>

                    </Row>

                    <div className="separator separator-dashed my-5 my-md-10" />
                  </>
                } */}


                {/* --------------------------------- PAYMENT SUMMARY --------------------------------- */}

                {["orders", "invoices", "credits"].includes(UIProps.requestedDocument.type) &&

                  <Row className="d-flex justify-content-between font-weight-bold bg-gray-100 pl-5 py-5 px-6 mx-0">

                    <Col xs="6" md="3" className="text-left text-md-center py-5 px-0">
                      <div className="text-muted text-uppercase border-bottom text-lg-left text-md-left text-sm-left pb-5 mb-5">
                        <FormattedMessage id="DOCUMENT.POSITION_TOTAL_NET" />
                      </div>
                      <div className="font-weight-bolder text-lg-left text-md-left text-sm-left">
                        {convertPriceCurrency(+documents?.pricing_summary?.sub_total, currency)}
                      </div>
                    </Col>

                    {(marketPriceDiscount !== 0 || discount !== 0) &&
                      <Col xs="6" md="2" className="text-right text-md-center py-5 px-0">
                        <div className="text-muted text-uppercase border-bottom pb-5 mb-5 text-nowrap">
                          <FormattedMessage id="GENERAL.DISCOUNT_VALUE" values={{ name: marketPriceDiscount !== 0 ? `Br.: ${convertPriceCurrency(-marketPriceDiscount, currency)}` : `${discount} %` }} />
                        </div>
                        <div className="font-weight-bolder">
                          {convertPriceCurrency((marketPriceDiscount !== 0 ? (marketPriceDiscount + calculateDiscountTaxTotal) : (discount * -(+documents?.pricing_summary?.sub_total / 100))), currency)}
                        </div>
                      </Col>
                    }

                    <Col xs="6" md="2" className="text-md-center py-5 px-0">
                      <div className="text-muted text-uppercase border-bottom pb-5 mb-5">
                        <FormattedMessage id="DOCUMENT.POSITION_TOTAL_ITEM" />
                      </div>
                      <div className="font-weight-bolder">
                        {convertPriceCurrency((+documents?.pricing_summary?.total_tax - (discount ?? 0) * (+documents?.pricing_summary?.total_tax) / 100) - calculateDiscountTaxTotal, currency)}
                      </div>
                    </Col>
                    <Col xs="6" md="2" className="text-right text-md-center py-5 px-0">
                      <div className="text-muted text-uppercase border-bottom pb-5 mb-5">
                        <FormattedMessage id="DOCUMENT.SHIPPING_PRICE" />
                      </div>
                      <div className="font-weight-bolder">
                        {convertPriceCurrency(documents?.pricing_summary?.shipping_price, currency)}
                      </div>
                    </Col>

                    <Col xs="6" md="2" className="py-5 px-0">
                      <div className="text-muted text-uppercase border-bottom text-lg-right text-md-left text-sm-left pb-5 mb-5">
                        <FormattedMessage id="DOCUMENT.POSITION_TOTAL_AMOUNT" />
                      </div>
                      <div className="font-weight-boldest text-primary font-size-h3 text-lg-right text-md-left text-sm-left">
                        {convertPriceCurrency((documents?.pricing_summary?.total + documents?.pricing_summary?.shipping_price - (discount ?? 0) * (documents?.pricing_summary?.total) / 100) + marketPriceDiscount, currency)}
                      </div>
                    </Col>
                  </Row>
                }


                {/* --------------------------------- OPEN PAYMENTS --------------------------------- */}

                {["orders", "invoices", "credits"].includes(UIProps.requestedDocument.type) && documents.payment_status !== 'paid'
                  ? <>
                    <div className="separator separator-dashed my-5 my-md-10" />
                    <Row className="d-flex justify-content-between font-weight-bold bg-danger-o-20 pl-5 py-5 px-6 mx-0">

                      <Col xs="6" md="2" className="py-5 px-0">
                        <div className="text-muted text-uppercase border-bottom pb-5 mb-5">
                          <FormattedMessage id="DOCUMENT.PAYMENT_STATUS" />
                        </div>
                        <div className="font-weight-bolder">
                          {UIHelpers.paymentStatus.find(payment => payment.value === documents.payment_status)?.label ?? <FormattedMessage id="GENERAL.OTHER" />}
                        </div>
                      </Col>

                      <Col xs="6" md="2" className="text-right text-md-center py-5 px-0">
                        <div className="text-muted text-uppercase border-bottom pb-5 mb-5 text-nowrap">
                          <FormattedMessage id="DOCUMENT.PAYMENT.AMOUNT_PAID" />
                        </div>
                        <div className="font-weight-bolder">
                          {convertPriceCurrency(totalPaid, currency)}
                        </div>
                      </Col>

                      <Col xs="6" md="2" className="text-md-center py-5 px-0">
                        <div className="text-muted text-uppercase border-bottom pb-5 mb-5">
                          <FormattedMessage id="DOCUMENT.TABLE.COLUMN.OPEN_AMOUNT" />
                        </div>
                        <div className="font-weight-bolder">
                          {convertPriceCurrency((rest <= 0 ? 0 : rest), currency)}
                        </div>
                      </Col>

                      <Col xs="6" md="2" className="py-5 px-0">
                        <div className="text-muted text-uppercase border-bottom text-lg-right text-md-left text-sm-left pb-5 mb-5">
                          <FormattedMessage id="DOCUMENT.POSITION_TOTAL_AMOUNT" />
                        </div>
                        <div className="font-weight-boldest text-primary font-size-h3 text-lg-right text-md-left text-sm-left">
                          {convertPriceCurrency(totalPrice, currency)}
                        </div>
                      </Col>
                    </Row>
                  </>
                  : null
                }
              </PerfectScrollbar>
            </>
          )}

        </Modal.Body>

        <Modal.Footer>

          {UIProps.requestedDocument.type === 'orders' &&
            <span className={clsx("label label-inline label-lg mr-auto", `label-outline-${UIHelpers.ORDER_STATUS.find(item => item.value === documents.order_status)?.className}`)}>
              {UIHelpers.ORDER_STATUS.find(item => item.value === documents.order_status)?.label ?? <FormattedMessage id="GENERAL.OTHER" />}
            </span>}

          <Button variant="light" onClick={UIProps.onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant="primary" className="ml-2" onClick={() => { UIProps.openEditDocumentDialog(UIProps.documentId); UIProps.onHide() }} id="btn_edit">
            <FormattedMessage id="GENERAL.EDIT" />
          </Button>

        </Modal.Footer>

      </Modal>
    </Portal>
  );
};