import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthActions } from '../auth/_redux/actions';
import DOMPurify from 'dompurify';



export default function Privacy() {

  const dispatch = useDispatch();

  useEffect(() => {    
    //Login sayfası dar ekrandayken tab değişince sayfa başına kaydırması için
    window.scrollTo(0, 0);
    //Login sayfası geniş ekrandayken tab değişince sayfa başına kaydırması için.
    const contentElement = document.getElementById('content');
    if (contentElement) {
      contentElement.scrollTo(0, 0);
    }
    dispatch(AuthActions.contract("privacy"));
  }, [dispatch]);


  const { contract } = useSelector(state => ({
    contract: state.auth.contract,
  }));
  window.scrollTo(0, 0);

  return (
    <>
      {contract && <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(contract) }} />}
    </>
  );
}