import React from 'react';
import { barcodeRegExp, regexCategoryPattern, regexFeaturePattern, regexImgPattern, regexVariantPattern, upcRegExp } from "_metronic/_helpers";



export function convertPriceCurrency(price, currency) {
  const newPrice = price.toLocaleString('de-DE', {
    style: "currency",
    currency: currency ? currency : "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return newPrice;
};


export function SkuFormatter(cell, row, checkData) {
  const checkSku = checkData.includes(cell);
  if (!cell || cell.length > 50 || checkSku) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function NameFormatter(cell, row, rowIndex) {
  if (!cell || cell.length > 250) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function IsVariantFormatter(cell, row, rowIndex) {
  if (!cell || (+cell !== 0 && +cell !== 1)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function VariantSkuFormatter(cell, row, rowIndex) {
  const isVariant = +row.isVariant;
  if ((isVariant === 0 && cell)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    );
  } else if (isVariant === 1 && (!cell || cell?.length > 50)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    );
  };
  return (<div type="button" className="edit-table">{cell}</div>);
};


export function VariantNameFormatter(cell, row, rowIndex) {
  const isVariant = +row.isVariant;
  if (isVariant === 0 && cell) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    );
  } else if (isVariant === 1 && !regexVariantPattern.test(cell)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    );
  };
  return (<div type="button" className="edit-table">{cell}</div>);
};


export function VariantValueFormatter(cell, row, rowIndex) {
  const isVariant = +row.isVariant;
  if (isVariant === 0 && cell) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    );
  } else if (isVariant === 1 && !regexVariantPattern.test(cell)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    );
  };
  return (<div type="button" className="edit-table">{cell}</div>);
};


export function BrandFormatter(cell, row, rowIndex) {
  if (cell?.length > 200) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>);
};


export function QuantityFormatter(cell, row, rowIndex) {
  if (!cell || +cell < 0 || +cell >= 1000000) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>);
};


export function PurchasePriceFormatter(cell, row, rowIndex) {
  if (cell && (+cell < 0 || +cell >= 1000000)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>);
};


export function GrossPriceFormatter(cell, row, rowIndex) {
  if (cell && (+cell < 0 || +cell >= 1000000)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>);
};

export function CurrencyFormatter(cell, row, rowIndex) {
  if (cell && cell?.length !== 3) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    );
  }
  return (<div type="button" className="edit-table">{cell}</div>);

};

export function TaxFormatter(cell, row, rowIndex) {
  if (!cell || +cell < 0 || +cell > 100) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>);
};


export function EanFormatter(cell, row, rowIndex) {
  if (cell?.length > 13) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>);
};

export function AsinFormatter(cell, row, rowIndex) {
  if (cell && cell?.length !== 10) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>);
};


export function BarcodeFormatter(cell, row, rowIndex) {

  if (cell && !barcodeRegExp.test(cell)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>);
};


export function IsbnFormatter(cell, row, rowIndex) {
  if (cell && (cell?.length < 10 || cell?.length > 13)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>);
};


export function GtinFormatter(cell, row, rowIndex) {
  if (cell?.length > 50) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>);
};


export function MpnFormatter(cell, row, rowIndex) {
  if (cell?.length > 50) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>);
};


export function UpcFormatter(cell, row, rowIndex) {
  if (cell && !upcRegExp.test(cell)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>);
};


export function WeightFormatter(cell, row, rowIndex) {
  if (cell && (+cell < 0 || +cell > 1000000)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>);
};


export function WidthFormatter(cell, row, rowIndex) {
  if (cell && (+cell < 0 || +cell > 500)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>);
};


export function HeightFormatter(cell, row, rowIndex) {
  if (cell && (+cell < 0 || +cell > 300)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>);
};


export function LengthFormatter(cell, row, rowIndex) {
  if (cell && (+cell < 0 || +cell > 300)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>);
};


export function DescriptionFormatter(cell, row, rowIndex) {
  if (cell?.length > 1000000) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>);
};


export function CategoryPathFormatter(cell, row, rowIndex) {

  if (cell && !regexCategoryPattern.test(cell)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>);
};


export function UnitFormatter(cell, row, rowIndex) {
  if (cell && (cell !== "weight" && cell !== "piece")) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function Img1Formatter(cell, row, rowIndex) {
  if (cell && !regexImgPattern.test(cell)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function Img2Formatter(cell, row, rowIndex) {
  if (cell && !regexImgPattern.test(cell)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function Img3Formatter(cell, row, rowIndex) {
  if (cell && !regexImgPattern.test(cell)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function Img4Formatter(cell, row, rowIndex) {
  if (cell && !regexImgPattern.test(cell)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function Img5Formatter(cell, row, rowIndex) {
  if (cell && !regexImgPattern.test(cell)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function Img6Formatter(cell, row, rowIndex) {
  if (cell && !regexImgPattern.test(cell)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function Img7Formatter(cell, row, rowIndex) {
  if (cell && !regexImgPattern.test(cell)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function Img8Formatter(cell, row, rowIndex) {
  if (cell && !regexImgPattern.test(cell)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function Img9Formatter(cell, row, rowIndex) {
  if (cell && !regexImgPattern.test(cell)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function Img10Formatter(cell, row, rowIndex) {
  if (cell && !regexImgPattern.test(cell)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function Features1Formatter(cell, row, rowIndex) {
  if (cell && !regexFeaturePattern.test(cell)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function Features2Formatter(cell, row, rowIndex) {
  if (cell && !regexFeaturePattern.test(cell)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function Features3Formatter(cell, row, rowIndex) {
  if (cell && !regexFeaturePattern.test(cell)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function Features4Formatter(cell, row, rowIndex) {
  if (cell && !regexFeaturePattern.test(cell)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function Features5Formatter(cell, row, rowIndex) {
  if (cell && !regexFeaturePattern.test(cell)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function Features6Formatter(cell, row, rowIndex) {
  if (cell && !regexFeaturePattern.test(cell)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function HeaderFormatterRequired(column, colIndex) {

  const words = column.text.split(" ");
  const lastWordWithAsterisk = words[words.length - 1];
  const asteriskIndex = lastWordWithAsterisk.indexOf('*');

  return (
    <div>
      {words.slice(0, -1).join(' ')}
      {asteriskIndex > -1 ? (
        <span>
          {lastWordWithAsterisk.slice(0, asteriskIndex)}
          <span className='text-danger'>{lastWordWithAsterisk.charAt(asteriskIndex)}</span>
          {lastWordWithAsterisk.slice(asteriskIndex + 1)}
        </span>
      ) : (
        lastWordWithAsterisk
      )}
    </div>
  );
};