import React from 'react';
import { toast } from 'react-toastify';
import ActionTypes from './actionTypes';
import { FormattedMessage } from 'react-intl';




export class AuthActions {


  // Login Actions
  static login = (user) => {
    return {
      type: ActionTypes.LOGIN,
      payload: {
        user
      },
    };
  };

  static loginSuccess = (data) => {
    return {
      type: ActionTypes.LOGIN_SUCCESS,
      payload: {
        data,
      },
    };
  };

  static loginFail = (error) => {
    return {
      type: ActionTypes.LOGIN_FAIL,
      payload: {
        error: error,
      },
    };
  };



  // Logout Actions
  static logout = (token) => {
    return {
      type: ActionTypes.LOGOUT,
      payload: {
        token,
      },
    };
  };

  static logoutSuccess = () => {
    return {
      type: ActionTypes.LOGOUT_SUCCESS,
    };
  };

  static logoutFail = () => {
    return {
      type: ActionTypes.LOGOUT_FAIL,
    };
  };



  // Register actions
  static register = (user) => {
    return {
      type: ActionTypes.REGISTER,
      payload: {
        user,
      },
    };
  };

  static registerSuccess = () => {
    return {
      type: ActionTypes.REGISTER_SUCCESS,
    };
  };

  static registerFail = (error, errorType) => {
    return {
      type: ActionTypes.REGISTER_FAIL,
      payload: {
        error,
        errorType,
      },
    };
  };


  // Email Verification actions
  static emailVerification = (user) => {
    return {
      type: ActionTypes.EMAIL_VERIFICATION,
      payload: {
        user,
      },
    };
  };

  static emailVerificationSuccess = (message) => {
    return {
      type: ActionTypes.EMAIL_VERIFICATION_SUCCESS,
      payload: {
        message
      },
    };
  };

  static emailVerificationFail = (error) => {
    return {
      type: ActionTypes.EMAIL_VERIFICATION_FAIL,
      payload: {
        error
      },
    };
  };


  // Get User Data
  static getUser = () => {
    return {
      type: ActionTypes.GET_USER_DATA,
    };
  };

  static getUserSuccess = (user) => {
    return {
      type: ActionTypes.GET_USER_DATA_SUCCESS,
      payload: {
        user
      },
    };
  };

  static getUserFail = (error) => {
    return {
      type: ActionTypes.GET_USER_DATA_FAIL,
      payload: {
        error
      },
    };
  };



  // Reset Password actions
  static resetPassword = (email) => {
    return {
      type: ActionTypes.RESET_PASSWORD,
      payload: {
        email,
      },
    };
  };

  static resetPasswordSuccess = (respond) => {
    return {
      type: ActionTypes.RESET_PASSWORD_SUCCESS,
      payload: {
        respond,
      },
    };
  };

  static resetPasswordFail = (error) => {
    return {
      type: ActionTypes.RESET_PASSWORD_FAIL,
      payload: {
        error,
      },
    };
  };



  // Re-Create Password actions
  static reCreatePassword = (payload) => {
    return {
      type: ActionTypes.RE_CREATE_PASSWORD,
      payload,
    };
  };

  static reCreatePasswordSuccess = () => {
    return {
      type: ActionTypes.RE_CREATE_PASSWORD_SUCCESS,
    };
  };

  static reCreatePasswordFail = (error) => {
    return {
      type: ActionTypes.RE_CREATE_PASSWORD_FAIL,
      payload: {
        error
      },
    };
  };



  // Restart User Server
  static restartUserServer = () => {
    return {
      type: ActionTypes.RESTART_USER_SERVER,
      payload: {},
    };
  };

  static restartUserServerSuccess = () => {
    return {
      type: ActionTypes.RESTART_USER_SERVER_SUCCESS,
    };
  };

  static restartUserServerFail = () => {
    return {
      type: ActionTypes.RESTART_USER_SERVER_FAIL,
    };
  };



  // Update User
  static updateUsers = (userUpdate, actionId) => {
    return {
      type: ActionTypes.UPDATE_USERS,
      payload: {
        userUpdate,
        actionId
      },
    };
  };

  static updateUsersSuccess = (response) => {
    return {
      type: ActionTypes.UPDATE_USERS_SUCCESS,
      payload: {
        response
      },
    };
  };

  static updateUsersFail = (error) => {
    return {
      type: ActionTypes.UPDATE_USERS_FAIL,
      payload: {
        error
      },
    };
  };



  //Login settings 
  static loginSettings = (updateUser) => {
    return {
      type: ActionTypes.LOGIN_SETTINGS,
      payload: {
        updateUser
      },
    };
  };

  static loginSettingsSuccess = (data) => {
    // toast.success(<FormattedMessage id="SETTINGS.REDUX.UPDATE_SUCCESS" />)
    return {
      type: ActionTypes.LOGIN_SETTINGS_SUCCESS,
      payload: {
        data
      }
    };
  };

  static loginSettingsFail = (err) => {
    // toast.error(<FormattedMessage id="SETTINGS.REDUX.CHANGE_PASSWORD.FAIL" />)
    return {
      type: ActionTypes.LOGIN_SETTINGS_FAIL,
    };
  };



  // Change Password 
  static changePassword = (changePassword) => {
    return {
      type: ActionTypes.CHANGE_PASSWORD,
      payload: {
        changePassword
      },
    };
  };

  static changePasswordSuccess = (message) => {
    return {
      type: ActionTypes.CHANGE_PASSWORD_SUCCESS,
      payload: {
        message
      }
    };
  };

  static changePasswordFail = (err) => {
    toast.error(err?.error?.message.includes('SERVER.LOGIN')
      ? <FormattedMessage id={err?.error?.message} />
      : err?.error?.message.includes('SERVER.AUTH')
        ? <FormattedMessage id={err?.error?.message} />
        : err?.error?.message)
    return {
      type: ActionTypes.CHANGE_PASSWORD_FAIL,
    };
  };


  // Change Email 
  static changeEmail = (changeEmail) => {
    return {
      type: ActionTypes.CHANGE_EMAIL,
      payload: {
        changeEmail
      },
    };
  };

  static changeEmailSuccess = (new_email, message) => {
    return {
      type: ActionTypes.CHANGE_EMAIL_SUCCESS,
      payload: {
        new_email,
        message
      }
    };
  };

  static changeEmailFail = (err) => {
    toast.error(err?.error?.message.includes('SERVER.LOGIN')
      ? <FormattedMessage id={err?.error?.message} />
      : err?.error?.message.includes('SERVER.AUTH')
        ? <FormattedMessage id={err?.error?.message} />
        : err?.error?.message)
    return {
      type: ActionTypes.CHANGE_EMAIL_FAIL,
    };
  };



  // Contract Actions
  static contract = (type) => {
    return {
      type: ActionTypes.CONTRACT,
      payload: {
        type
      },
    };
  };

  static contractSuccess = (data) => {
    return {
      type: ActionTypes.CONTRACT_SUCCESS,
      payload: {
        data,
      },
    };
  };

  static contractFail = (error) => {
    return {
      type: ActionTypes.CONTRACT_FAIL,
      payload: {
        error: error,
      },
    };
  };



  // Delete Req Actions
  static deletedRequest = (data) => {
    return {
      type: ActionTypes.DELETED_REQUEST,
      payload: {
        data
      },
    };
  };

  static deletedRequestSuccess = (data) => {
    return {
      type: ActionTypes.DELETED_REQUEST_SUCCESS,
      payload: {
        data,
      },
    };
  }

  static deletedRequestFail = (err) => {
    toast.error(err.error.message.includes('SERVER.LOGIN.CHANGE_EMAIL.PASSWORD_INCORRECT')
      ? <FormattedMessage id={err.error.message} />
      : err?.error?.message.includes('SERVER.AUTH')
        ? <FormattedMessage id={err?.error?.message} />
        : err?.error?.message)
    return {
      type: ActionTypes.DELETED_REQUEST_FAIL,
      payload: {
        error: err,
      },
    };
  };



  // Get Counts Actions
  static getCounts = () => {
    return {
      type: ActionTypes.GET_COUNTS,
      payload: {},
    };
  };

  static getCountsSuccess = (data) => {
    return {
      type: ActionTypes.GET_COUNTS_SUCCESS,
      payload: {
        data,
      },
    };
  }

  static getCountsFail = (err) => {
    return {
      type: ActionTypes.GET_COUNTS_FAIL,
      payload: {
        error: err,
      },
    };
  };

  // Clean up reducer
  static cleanUpReducer = () => {
    return {
      type: ActionTypes.CLEAN_UP_REDUCER,
    };
  };



  // Clear Loading Handler
  static clearLoadingEffects = () => {
    return {
      type: ActionTypes.CLEAR_LOADING_EFFECTS,
    };
  };

}
