import { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { SettingsActions } from 'app/modules/settings/_redux/actions';
import { shallowEqual } from 'react-redux';



export function useToggleDisplayCard(property) {
  const dispatch = useDispatch();

  const { id, default_card_view, currency, settings_type } = useSelector(state => state.settings.dashboardSettings, shallowEqual);

  const dispatchProperty = useCallback(() => {
    const data = {
      settings_type: settings_type,
      currency,
      default_card_view: { ...default_card_view, [property]: !default_card_view[property] }
    }
    id
      ? dispatch(SettingsActions.patchUserSettings(id, settings_type, data))
      : dispatch(SettingsActions.createUserSettings(data))

  }, [default_card_view, id, currency, settings_type, dispatch, property])

  return [default_card_view[property], dispatchProperty]
}