import React, { useState } from 'react';
import { Redirect, Switch, useHistory } from 'react-router-dom';
import { ModuleRoutes, RELATIVE_PATH } from 'constants/moduleRoutes';
import { ProductListingsUIProvider } from '../_context/ProductListingsUIContext';
import { KauflandListingsEdit } from './kaufland-listings-edit/KauflandListingsEdit';
import { KauflandListingsPage } from './KauflandListingsPage';
import { ContentRoute } from '_metronic/layout';



export default function KauflandListings({ match: { params: { market_id } } }) {

  const history = useHistory();
  const [ids, setIds] = useState([]);
  const [showSyncAlertDialog, setShowSyncAlertDialog] = useState(false);
  const [syncErrors, setSyncErrors] = useState(null);
  const [productId, setProductId] = useState(null);
  const [categoryInputValue, setCategoryInputValue] = useState(null);
  const [selectedCategoryAspects, setCategoryAspects] = useState([]);
  const openSyncAlertDialog = (id, syncErrors) => {
    setSyncErrors(syncErrors);
    setShowSyncAlertDialog(true);
    setProductId(id);
  };
  const closeSyncAlertDialog = () => {
    setSyncErrors(null);
    setShowSyncAlertDialog(false);
    setProductId(null);
  };

  const contextUIEvents = {
    market_id,
    ids,
    setIds,
    showSyncAlertDialog,
    openSyncAlertDialog,
    closeSyncAlertDialog,
    syncErrors,
    productId,
    categoryInputValue,
    setCategoryInputValue,
    selectedCategoryAspects,
    setCategoryAspects,
    openPrepareKauflandListingEdit: (market_id, id, method) => history.push(ModuleRoutes.KAUFLAND_LISTINGS_PREPARE_EDIT_FN(market_id, id, method)),
  };

  
  return (
    <ProductListingsUIProvider contextUIEvents={contextUIEvents}>
      <Switch>
        <Redirect exact={true} from={RELATIVE_PATH} to={ModuleRoutes.KAUFLAND_LISTINGS} />

        <ContentRoute path={ModuleRoutes.KAUFLAND_LISTINGS_PREPARE_EDIT} component={KauflandListingsEdit} />
        <ContentRoute path={ModuleRoutes.KAUFLAND_LISTINGS} component={KauflandListingsPage} />
      </Switch>
    </ProductListingsUIProvider>
  );
}
