import React, { useContext, createContext, useState, useCallback } from "react";
import { useSearchQuery } from "app/hooks";
import { useIntl } from "react-intl";


const SearchQueryContext = createContext();
export const useSearchQueryContext = () => useContext(SearchQueryContext);


export function SearchQueryProvider({ children }) {


  const { formatMessage: intl } = useIntl();
  // query params
  const [queryParams, setQueryBase] = useState({
    pagination: {
      page: 1,
      limit: 10,
    },
    order: 'created_at DESC',
    search: '',
    filter: null,
  });

  const setQueryParams = useCallback((params) => setQueryBase(params), [])

  const { setSearchQuery, setFilterQuery, setPaginationQuery, setOrderQuery } = useSearchQuery(setQueryParams);

  const initialDateRange = {
    id: "clear-date",
    label: intl({ id: "GENERAL.CLEAR" }),
  };

  const [dateRange, setDateRange] = useState(
    initialDateRange
  );
  const changeDateRange = (date = initialDateRange) => setDateRange(date);

  const value = {
    queryParams,
    setSearchQuery,
    setFilterQuery,
    setPaginationQuery,
    setOrderQuery,

    initialDateRange,
    dateRange,
    setDateRange,
    changeDateRange,
  }

  return (
    <SearchQueryContext.Provider value={value}>
      {children}
    </SearchQueryContext.Provider>
  )
}