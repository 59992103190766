import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Col, Modal } from 'react-bootstrap';
import { SVFormControl } from '_metronic/_partials/controls';
import { ConnectionsActions } from "../../../_redux/actions";
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Form, Field } from 'formik';
import * as Yup from "yup";



const INPUTS = [
  {
    name: 'dhl.customerNumber',
    label: 'GENERAL.CUSTOMER_NUMBER',
    info: 'CONNECTIONS.DHL.CUSTOMER_NUMBER_INFO',
    required: true,
  },
  {
    name: 'dhl.shipperReference',
    label: 'CONNECTIONS.SHIPPER.DHL_SHIPPER_REFERENCE',
    info: 'CONNECTIONS.DHL.SHIPPER_REFERENCE_INFO',
  },
  {
    name: 'dhl.username',
    label: "ACCOUNT.BENUTZERNAME",
    required: true,
  },
  {
    name: 'dhl.password',
    label: "AUTH.INPUT.PASSWORD",
    type: 'password',
    editable: true,
    required: true,
  },
];


export default function DhlForm({ children, connectionId, initialValue, loading, onHide }) {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const dhlForEdit = useSelector((state) => state.connections.dhlForEdit);

  const validationSchema = Yup.object().shape({
    ...(!connectionId && {
      dhl: Yup.object().shape({
        customerNumber: Yup.string()
          .required(intl({ id: 'GENERAL.REQUIRED' })),
        // shipperReference: Yup.string()
        //   .required(intl({ id: 'GENERAL.REQUIRED' })),
        username: Yup.string()
          .required(intl({ id: 'GENERAL.REQUIRED' })),
        password: Yup.string()
          .required(intl({ id: 'GENERAL.REQUIRED' })),
      })
    })
  });


  const handleSubmit = (values) => {
    connectionId ?
      dispatch(ConnectionsActions.updateSettings(values)) :
      dispatch(ConnectionsActions.createSettings(values))
  };


  return (<>
    <Formik enableReinitialize={true} initialValues={connectionId ? dhlForEdit : initialValue} validationSchema={validationSchema} onSubmit={handleSubmit}>

      <Form autoComplete='off'>

        <Modal.Body className='p-0'>
          <Row className='px-8'>
            {INPUTS.map((input, i) => (
              <Col key={i} lg={input.lg ?? "6"}>
                <Field component={SVFormControl}
                  {...{
                    name: input.name,
                    type: input.type,
                    label: intl({ id: input.label }),
                    withFeedbackLabel: true,
                    feedbackLabel: true,
                    required: input.required,
                    ...(input.info && { info: intl({ id: input.info }) }),
                    disabled: loading || (connectionId && !input.editable),
                  }}
                />
              </Col>
            ))}
          </Row>

          {children}

        </Modal.Body>

        <Modal.Footer>

          <Button variant="light" onClick={onHide} disabled={loading} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant="primary" type="submit" className="ml-2" disabled={loading} id="btn_save_modal">
            <FormattedMessage id="GENERAL.SAVE" />
          </Button>

        </Modal.Footer>
      </Form>

    </Formik>
  </>
  )
}
