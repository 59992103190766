import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { SVReactSelect } from "_metronic/_partials/controls";
import { ConnectionsActions } from "../../../_redux/actions";
import { FormattedMessage, useIntl } from "react-intl";
import { CloseButton } from "_metronic/_partials";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";



export function DeutschePostProductSelect({ show, onHide }) {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const { deutschePostForEdit, productLists } = useSelector(state => ({
    productLists: state.connections.deutschePostConfigs.productLists,
    deutschePostForEdit: state.connections.deutschePostForEdit,
  }));


  const validationSchema = Yup.object().shape({
    product: Yup.string()
      .required(intl({ id: 'GENERAL.REQUIRED' })),
  });


  const handleSubmit = (values) => {
    const product = productLists.find((ls) => ls.id === values.product);
    const updateDeutschePost = {
      ...deutschePostForEdit,
      deutsche_post: {
        ...deutschePostForEdit?.deutsche_post,
        product_list: [...deutschePostForEdit?.deutsche_post?.product_list ?? [], product]
      }
    };
    dispatch(ConnectionsActions.updateSettings(updateDeutschePost));
    onHide();
  }

  const productListIds = (deutschePostForEdit?.deutsche_post?.product_list?.map(({ id }) => id) || []);
  const options = productLists?.filter(({ id }) => !productListIds.includes(id)).map(({ id, name }) => ({ value: id, label: name, }));


  return (
    <Modal show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-xl" size="md" centered>

      <Modal.Header>

        <Modal.Title>
          <FormattedMessage id='CONNECTIONS.SHIPPER.D_POST.LABEL_SETTING' />
        </Modal.Title>

        <CloseButton onClick={onHide} />

      </Modal.Header>

      <Formik enableReinitialize={true} initialValues={{ product: "" }} validationSchema={validationSchema} onSubmit={handleSubmit}>
        <Form autoComplete="off">

          <Modal.Body>
            <Field component={SVReactSelect}
              {...{
                name: "product",
                label: "Products",
                options: options,
                feedbackLabel: true
              }}
            />
          </Modal.Body>

          <Modal.Footer>

            <Button variant="light" onClick={onHide} id="btn_cancel_modal">
              <FormattedMessage id="GENERAL.CANCEL" />
            </Button>

            <Button variant="primary" type="submit" className="ml-2" id="btn_add_modal">
              <FormattedMessage id="GENERAL.ADD" />
            </Button>

          </Modal.Footer>

        </Form>
      </Formik>

    </Modal>
  );
}