import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { productMiddleware, connectionsMiddleware, productListingsMiddleware } from 'middlewares';
import { productQueryFields } from '../ebay-listings/EbayListingsUIHelper';
import { ConnectionsActions } from 'app/modules/connections/_redux/actions';
import { ListingActions } from './actions';
import ActionTypes from './actionTypes';
import { uniqueId } from 'lodash';



// Get Products
function* getProductsQuery({ payload: { queryParams } }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);

    const countProducts = yield call(productMiddleware.getCount, accessToken, queryParams);
    const products = yield call(productMiddleware.getProducts, accessToken, queryParams);

    //if (Array.isArray(products) && products.length > 0) {
    //  for (const product of response.data) {
    //    const images = yield call(uploadMiddleware.getContainer, accessToken, product.id);
    //    const imageUrls = [];
    //    if (Array.isArray(images.data) && images.data.length > 0) {
    //      images.data.forEach(image => imageUrls.push(`${BASE_URL}/containers/${product.id}/download/${image}`));
    //    }
    //    product.imageUrls = imageUrls;
    //  }
    //}

    yield put(ListingActions.getProductsSuccess(products.data, countProducts.data));
  } catch (err) {
    yield put(ListingActions.getProductsFail(err?.response?.data || err?.message));
  }
}


// Get Product by Id
function* ebayInventoryListingsPreparationQuery({ payload: { id, market_id, method } }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);
    // const { data } = yield call(productMiddleware.getProductById, accessToken, id, { fields: productQueryFields });

    const getProductRequest = yield call(productMiddleware.getProductById, accessToken, id, { fields: productQueryFields });
    const product = getProductRequest.data;

    //const images = yield call(productMiddleware.getImages, accessToken, id);
    //const imageUrls = [];
    //if (Array.isArray(images.data) && images.data.length > 0) {
    //  images.data.forEach(image => imageUrls.push(`${BASE_URL}/containers/${id}/download/${image}`));
    //}
    //product.imageUrls = imageUrls;

    const findInventory = Array.isArray(product?.inventories) && product.inventories?.find((inv) => inv.market_id === market_id);

    // const getPolicies = yield call(productMiddleware.getPolicies, access, market_id);
    // const policies = getPolicies.data;
    // const policiesMap = {};
    // if (Array.isArray(policies) && policies.length > 0) {
    //   policies.forEach(p => {
    //     const _policy = {
    //       id: p.identifiedValue,
    //       name: p?.details?.name,
    //     };
    //     if (Array.isArray(policiesMap[p.identifiedKey])) {
    //       policiesMap[p.identifiedKey].push(_policy);
    //     } else {
    //       policiesMap[p.identifiedKey] = [_policy];
    //     }
    //   });
    // }

    if (method === 'edit' && findInventory?.suggestions) {
      product.merchantLocations = findInventory?.merchantLocations?.locations || [];
      product.ebayInventory = findInventory;
      product.merchantLocationKey = findInventory?.merchantLocations?.selectedKey || '';
    }

    yield put(ListingActions.ebayInventoryListingsPreparationSuccess(product, market_id, method));
  } catch (err) {
    yield put(ListingActions.ebayInventoryListingsPreparationFail(err?.response?.data || err?.message));
  }
}


// Get Category by Id
function* ebayInventoryListingsCategory({ payload: { market_id, name } }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);

    const requestCategorySuggestions = yield call(productListingsMiddleware.getEbayInventoryListingsPreparation, accessToken, {
      market_id,
      categorySearch: name
    });
    const { suggestions } = requestCategorySuggestions.data;
    const categorySuggestions = suggestions || [];
    yield put(ListingActions.ebayInventoryListingsCategorySuccess(categorySuggestions));
  } catch (err) {
    yield put(ListingActions.ebayInventoryListingsCategoryFail(err?.response?.data || err?.message));
  }
}


// Listing Market Places Query
function* listingMarketPlacesQuery() {
  try {
    const accessToken = yield select((state) => state.auth.access_token);

    const response = yield call(connectionsMiddleware.getConnections, accessToken, ['shop', 'marketplace']);
    yield put(ListingActions.listingMarketPlacesSuccess(response.data));
  } catch (err) {
    yield put(ListingActions.listingMarketPlacesFail(err?.response?.data || err?.message));
  }
}


// Listing Market Places Query
function* ebayCreateOrReplaceInventoryQuery({ payload: { id, listing, method } }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);

    const getProduct = yield call(productMiddleware.getProductById, accessToken, id, {
      fields: ['id', 'sku', 'inventories', 'features']
    });
    const { inventories, features } = getProduct.data;
    const inventory = Array.isArray(inventories) ? inventories : [];
    const listingIndex = inventory.findIndex((inv) => inv.market_id === listing.market_id);

    if (method === 'edit' && listingIndex > -1) {
      inventory[listingIndex] = listing;
    } else {
      inventory.push(listing);
    }

    const newFeatures = Object.keys(listing?.inventory?.product?.aspects || {}).map((key) => ({
      id: uniqueId().toString(),
      value: listing.inventory.product.aspects?.[key],
      name: key
    }));

    const updateFeatures = features.filter((existingFeature) => !newFeatures.some((newFeature) => newFeature.name === existingFeature.name));

    const updateProduct = {
      inventories: inventory,
      features: [...updateFeatures, ...newFeatures]
    };
    yield call(productMiddleware.patchProduct, accessToken, id, updateProduct);
    yield put(ListingActions.ebayCreateOrReplaceInventorySuccess());
  } catch (err) {
    yield put(ListingActions.ebayCreateOrReplaceInventoryFail(err?.response?.data || err?.message));
  }
}


// Listing Market Places Query
function* publishEbayInventoryQuery({ payload: { ids, market_id, queryParams, method } }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);

    if (method === 'publish') {
      yield call(productListingsMiddleware.publishEbayInventory, accessToken, { ids, market_id });
    } else if (method === 'withdraw') {
      yield call(productListingsMiddleware.withdrawEbayInventory, accessToken, { ids, market_id });
    }

    yield put(ListingActions.publishEbayInventorySuccess());
    yield fork(getProductsQuery, { payload: { queryParams } });
  } catch (err) {
    yield put(ListingActions.publishEbayInventoryFail(err?.response?.data?.error || err?.message));
  }
}


// Listing Market Places Query
function* ebayInventoryUpdateQuery({ payload: { id, /*market_id,*/ updatedEbayInventory, queryParams } }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);

    yield call(productMiddleware.patchProduct, accessToken, id, updatedEbayInventory);
    // yield call(productListingsMiddleware.withdrawEbayInventory, access, {
    //   id,
    //   market_id,
    // });
    yield put(ListingActions.ebayInventoryUpdateSuccess());
    yield fork(getProductsQuery, { payload: { queryParams } });
  } catch (err) {
    yield put(ListingActions.ebayInventoryUpdateFail(err?.response?.data || err?.message));
  }
}


function* kauflandInventoryListingsPreparationQuery({ payload: { id, market_id, method } }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);

    const requestProduct = yield call(productMiddleware.getProductById, accessToken, id, { fields: productQueryFields });
    const product = requestProduct.data;

    if (product.kauflandInventory && !product?.kauflandInventory?.suggestions?.categorySuggestions) {
      yield put(ListingActions.kauflandInventoryListingsPreparationSuccess(product, product.kauflandInventory.suggestions, method));
    } else {
      const requestCategorySuggestions = yield call(productListingsMiddleware.getKauflandInventoryListingsPreparation, accessToken, { id, market_id });
      yield put(ListingActions.kauflandInventoryListingsPreparationSuccess(product, requestCategorySuggestions.data, method));
    }
  } catch (err) {
    yield put(ListingActions.kauflandInventoryListingsPreparationFail(err?.response?.data || err?.message));
  }
}


function* updateArtikelForKauflandQuery({ payload: { id, listing, method } }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);
    const getProduct = yield call(productMiddleware.getProductById, accessToken, id, {
      fields: ['id', 'sku', 'inventories', 'features']
    });

    const { inventories, features } = getProduct.data;
    const inventory = Array.isArray(inventories) ? inventories : [];
    const listingIndex = inventory.findIndex((inv) => inv.market_id === listing.market_id);

    if (method === 'edit' && listingIndex > -1) {
      inventory[listingIndex] = listing;
    } else {
      inventory.push(listing);
    }

    const newFeatures = Object.keys(listing?.inventory?.product?.aspects || {}).map((key) => ({
      id: uniqueId().toString(),
      value: listing.inventory.product.aspects?.[key],
      name: key
    }));
    const updateFeatures = features.filter((existingFeature) => !newFeatures.some((newFeature) => newFeature.name === existingFeature.name));
    const updateProduct = {
      inventories: inventory,
      features: [...updateFeatures, ...newFeatures]
    };

    yield call(productMiddleware.patchProduct, accessToken, id, updateProduct);
    yield put(ListingActions.updateArtikelForKauflandSuccess());
  } catch (err) {
    yield put(ListingActions.updateArtikelForKauflandFail(err?.response?.data || err?.message));
  }
}

// FIXME: This is not used
// function* publishKauflandInventoryQuery({ payload: { id, market_id, queryParams } }) {
//   try {

//     const accessToken = yield select(state => state.auth.access_token);

//     yield call(productListingsMiddleware.kauflandCreateOrReplaceInventory, accessToken, { id, market_id, });
//     yield put(ListingActions.publishKauflandInventorySuccess());
//     yield fork(getProductsQuery, { payload: { queryParams } });

//   } catch (err) {
//     yield put(ListingActions.publishKauflandInventoryFail(err?.response?.data || err?.message));
//   }
// }


function* publishUnPublishKauflandInventoryQuery({ payload: { ids, market_id, queryParams, method } }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);
    if (method === 'publish') {
      yield call(productListingsMiddleware.kauflandCreateOrReplaceInventory, accessToken, { ids, market_id });
      // yield put(ListingActions.publishKauflandInventorySuccess());
    } else if (method === 'withdraw') {
      yield call(productListingsMiddleware.kauflandDeleteInventory, accessToken, { ids, market_id });
    }

    yield put(ListingActions.publishUnPublishKauflandInventorySuccess());
    yield fork(getProductsQuery, { payload: { queryParams } });
  } catch (err) {
    yield put(ListingActions.publishUnPublishKauflandInventoryFail(err?.response?.data || err?.message));
  }
}


function* getKauflandProducts({ payload: { market_id } }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);

    const getProduct = yield call(productListingsMiddleware.getKauflandProducts, accessToken, market_id);

    yield put(ListingActions.getKauflandProductsSuccess(getProduct.data));
  } catch (err) {
    yield put(ListingActions.getKauflandProductsFail(err?.response?.data || err?.message));
  }
}


function* getProductByIdQuery({ payload: { id, filterParams } }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);

    const response = yield call(productMiddleware.getProductById, accessToken, id, filterParams);
    yield put(ListingActions.getProductByIdSuccess(response.data));
  } catch (err) {
    yield put(ListingActions.getProductByIdFail(err?.response?.data || err?.message));
  }
}


function* updateEbayPolicies({ payload: { market_id } }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);

    const updatePolicies = yield call(productListingsMiddleware.updateEbayPolicies, accessToken, market_id);

    yield put(ConnectionsActions.updateSettingsSuccess(updatePolicies.data));
    yield put(ListingActions.updateEbayPoliciesSuccess());
  } catch (err) {
    yield put(ListingActions.updateEbayPoliciesFail(err?.response?.data || err?.message));
  }
}


function* getEbayProducts({ payload: { market_id } }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);

    const getProduct = yield call(productListingsMiddleware.getEbayProducts, accessToken, market_id);

    yield put(ListingActions.getEbayProductsSuccess(getProduct?.data));
  } catch (err) {
    yield put(ListingActions.getEbayProductsFail(err?.response?.data || err?.message));
  }
}


function* getEbayMigrationsProducts({ payload: { user_id, market_id } }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);

    const getProduct = yield call(productListingsMiddleware.getEbayMigrationsProducts, accessToken, user_id, market_id);

    const count = getProduct?.data;
    yield put(ListingActions.getEbayMigrationsProductsSuccess(count));
  } catch (err) {
    yield put(ListingActions.getEbayMigrationsProductsFail(err?.response?.data || err?.message));
  }
}


export function* listingsSaga() {
  yield takeEvery(ActionTypes.GET_PRODUCTS_REQUEST, getProductsQuery);
  yield takeEvery(ActionTypes.LISTING_MARKET_PLACES_REQUEST, listingMarketPlacesQuery);
  yield takeEvery(ActionTypes.EBAY_INVENTORY_LISTINGS_PREPARATION_REQUEST, ebayInventoryListingsPreparationQuery);
  yield takeEvery(ActionTypes.EBAY_CREATE_OR_REPLACE_INVENTORY_REQUEST, ebayCreateOrReplaceInventoryQuery);
  yield takeEvery(ActionTypes.EBAY_INVENTORY_UPDATE_REQUEST, ebayInventoryUpdateQuery);
  yield takeEvery(ActionTypes.EBAY_INVENTORY_LISTINGS_CATEGORY, ebayInventoryListingsCategory);
  yield takeEvery(ActionTypes.EBAY_UPDATE_POLICIES, updateEbayPolicies);
  yield takeEvery(ActionTypes.KAUFLAND_INVENTORY_LISTINGS_PREPARATION_REQUEST, kauflandInventoryListingsPreparationQuery);
  yield takeEvery(ActionTypes.UPDATE_PRODUCT_FOR_KAUFLAND_REQUEST, updateArtikelForKauflandQuery);
  yield takeEvery(ActionTypes.PUBLISH_EBAY_INVENTORY_REQUEST, publishEbayInventoryQuery);
  // yield takeEvery(ActionTypes.KAUFLAND_CREATE_OR_REPLACE_INVENTORY_REQUEST, publishKauflandInventoryQuery);
  yield takeEvery(ActionTypes.GET_PRODUCT_BY_ID_REQUEST, getProductByIdQuery);
  yield takeEvery(ActionTypes.KAUFLAND_DELETE_INVENTORY_REQUEST, publishUnPublishKauflandInventoryQuery);
  yield takeEvery(ActionTypes.EBAY_SHOP_PRODUCTS, getEbayProducts);
  yield takeEvery(ActionTypes.KAUFLAND_SHOP_PRODUCTS, getKauflandProducts);
  yield takeEvery(ActionTypes.EBAY_SHOP_PRODUCTS_MIGRATIONS, getEbayMigrationsProducts);
}
