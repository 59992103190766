import React, { useMemo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useProductEditUIContext } from 'app/modules/products/_context/ProductEditUIContext';
import { VARIANT_ACTION_TYPES } from '../core/helpers';



export function VariantConfirmDialog({ show, onHide, addVariantHandler }) {

  const UIContext = useProductEditUIContext();
  const UIProps = useMemo(() => ({
    dispatchNewVariants: UIContext.dispatchNewVariants,
    showAddSingleVariantDialog: UIContext.newVariantsReducer.showAddSingleVariantDialog,
  }),
    [UIContext.dispatchNewVariants, UIContext.newVariantsReducer]
  );

  const variantGenerate = () => {
    UIProps.dispatchNewVariants({
      type: VARIANT_ACTION_TYPES.OPEN_GENERATE_VARIANT_DIALOG
    })
    onHide();
  };


  return (
    <Modal show={show} onHide={onHide} size="sm" backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered>

      <Modal.Body className="line-height-lg mb-0">
        <FormattedMessage id='PRODUCT.VARIANT.SINGLE_ADD.WARNING' />
        <span className="text-primary cursor-pointer" onClick={() => { addVariantHandler(); onHide() }}>
          <FormattedMessage id='PRODUCT.VARIANT.SINGLE_ADD.CLICK' />
        </span>
      </Modal.Body>

      <Modal.Footer className="flex-nowrap p-0 overflow-hidden">
        <Button variant='ios' className="border-right" onClick={onHide} id="btn_cancel_modal">
          <FormattedMessage id="GENERAL.CANCEL" />
        </Button>

        <Button variant='ios' className="text-primary" onClick={variantGenerate} id="btn_confirm_modal">
          <FormattedMessage id="PRODUCT.VARIANT.PROCESS" />
        </Button>
      </Modal.Footer>

    </Modal>
  );
}
