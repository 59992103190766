export const supportFieldTable = {
  fields: [
    "id",
    "user_id",
    "user_name",
    "email",
    "number",
    "jira",
    "subject",
    "status",
    "tag",
    "priority",
    "topic",
    "read",
    "seen",
    "created_at",
    "updated_at",
  ],
};

export const searchKeys = [
  'id',
  'user_name',
  'email',
  'number',
  'jira',
  //'session[0].creator',
  'subject',
];
