import { call, put, select, takeEvery } from 'redux-saga/effects';
import ActionTypes from './actionTypes';
import { AmazonListingsActions } from './actions';
import { amazonListingsMiddleware } from 'middlewares/product-listings/amazon-middleware';
import { productMiddleware } from 'middlewares';

/**
 * Get the listing item by ASIN number on Amazon
 * @param {string} payload.asin ASIN number of the product to search on Amazon
 * @param {string} payload.tokenId  Token ID of the amazon market place
 */
function* amazonFindListingItem({ payload }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);
    const getItem = yield call(amazonListingsMiddleware.findListingsItem, accessToken, payload);
    yield put(AmazonListingsActions.amazonFindListingItemResolves({ error: undefined, item: getItem.data }));
  } catch (error) {
    yield put(AmazonListingsActions.amazonFindListingItemResolves({ error: JSON.stringify(error), isError: true }));
  }
}

function* amazonCreateListing({ payload }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);
    const createItem = yield call(amazonListingsMiddleware.createListing, accessToken, payload);
    yield put(AmazonListingsActions.amazonCreateListingResolves({ error: undefined, item: createItem.data }));
  } catch (error) {
    yield put(AmazonListingsActions.amazonCreateListingResolves({ error: JSON.stringify(error), isError: true }));
  }
}

function* getProductById({ payload }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);
    const getProduct = yield call(productMiddleware.getProductById, accessToken, payload.productId);
    yield put(AmazonListingsActions.getProductByIdResolves({ error: undefined, product: getProduct.data }));
  } catch (error) {
    yield put(AmazonListingsActions.getProductByIdResolves({ error: JSON.stringify(error), isError: true }));
  }
}

export function* amazonListingSaga() {
  yield takeEvery(ActionTypes.AMAZON_FIND_LISTING_ITEM, amazonFindListingItem);
  yield takeEvery(ActionTypes.AMAZON_CREATE_LISTING, amazonCreateListing);
  yield takeEvery(ActionTypes.GET_PRODUCT_BY_ID, getProductById);
}
