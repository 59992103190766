import React from 'react';



export function CategoryIdFormatter(cell, row, checkData) {
  const checkId = checkData?.includes(cell);
  if (!cell || cell.length > 200 || checkId) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function CategoryParentIdFormatter(cell, row) {

  if (+row.level === 1 && cell) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  } else if (+row.level !== 1 && !cell) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  if (+row.level !== 1 && cell.length > 200) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function CategoryLevelFormatter(cell, row) {
  if (!cell || cell.length > 50) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function CategoryNameFormatter(cell, row) {
  if (!cell || cell.length > 500) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function CategoryPathFormatter(cell, row) {
  if (!cell || cell.length > 500) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};

export function HeaderFormatterRequired(column, colIndex) {

  const words = column.text.split(" ");
  const lastWordWithAsterisk = words[words.length - 1];
  const asteriskIndex = lastWordWithAsterisk.indexOf('*');

  return (
    <div>
      {words.slice(0, -1).join(' ')}
      {asteriskIndex > -1 ? (
        <span>
          {lastWordWithAsterisk.slice(0, asteriskIndex)}
          <span className='text-danger'>{lastWordWithAsterisk.charAt(asteriskIndex)}</span>
          {lastWordWithAsterisk.slice(asteriskIndex + 1)}
        </span>
      ) : (
        lastWordWithAsterisk
      )}
    </div>
  );
};