import React, { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { CounterPriceCard, LineChartDailySales, OrderByMarketplace, TableTopCustomer, TableTopProduct } from './widgets'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '_metronic/_partials/controls';
import { useDashboardUIContext } from './_context/DashboardUIContext';
import { UserGuideHelper } from '../../user-guides/UserGuideHelper';
import { DashboardFilter } from './dashboard-filter/DashboardFilter';
import { LoadingDialog } from "_metronic/_partials/loading";
import { DashboardActions } from './_redux/actions'
import { CURRENCIES } from '_metronic/_helpers';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';



export default function DashboardCard() {

  const dispatch = useDispatch();

  const UIContext = useDashboardUIContext();
  const UIProps = useMemo(() => ({
    queryParams: UIContext.queryParams,
    dateRange: UIContext.dateRange,
    setFilterQuery: UIContext.setFilterQuery,
  }), [UIContext]);

  const { loading, country, daily_sales, marketplace, open_invoices, orders, sales, top_costumer, top_product, entities, total_invoice_count, currency } = useSelector(state => ({
    loading: state.dashboard.loading,
    country: state.dashboard.country,
    daily_sales: state.dashboard.daily_sales,
    marketplace: state.dashboard.marketplace,
    open_invoices: state.dashboard.open_invoices,
    orders: state.dashboard.orders,
    sales: state.dashboard.sales,
    top_costumer: state.dashboard.top_costumer,
    top_product: state.dashboard.top_product,
    entities: state.connections.entities,
    total_invoice_count: state.dashboard.total_invoice_count,
    currency: state.dashboard.currency
  }), shallowEqual);

  useEffect(() => {
    dispatch(DashboardActions.getDashboard(UIProps.queryParams));
  }, [dispatch, UIProps.dateRange, UIProps.queryParams]);

  const currencySymbol = CURRENCIES.find(item => item.code === currency)?.symbol;


  return (
    <Card className="card-box">

      <CardHeader
        icon={<Icon.Dashboard />}
        title={<FormattedMessage id="DASHBOARD.TITLE" />}
        info={<UserGuideHelper />}
        className="display-5"
        sticky
      >
        <CardHeaderToolbar>
          <DashboardFilter setFilterQuery={UIProps.setFilterQuery} connectionEntities={entities} />
        </CardHeaderToolbar>
      </CardHeader>

      <CardBody>
        <Row>

          <Col lg='12' xl='4'>
            <CounterPriceCard
              title="DASHBOARD.ORDERS"
              data={orders}
              dateRange={UIProps.dateRange}
              marketplace={UIProps.queryParams?.filter?.marketplace}
              currencySymbol={currencySymbol}
            />
          </Col>

          <Col lg='12' xl='4'>
            <CounterPriceCard
              title="DASHBOARD.SALES"
              data={sales}
              dateRange={UIProps.dateRange}
              marketplace={UIProps.queryParams?.filter?.marketplace}
              currencySymbol={currencySymbol}
            />
          </Col>

          <Col lg='12' xl='4'>
            <CounterPriceCard
              title="DASHBOARD.OPEN_INVOICES"
              data={open_invoices}
              dateRange={UIProps.dateRange}
              marketplace={UIProps.queryParams?.filter?.marketplace}
              currencySymbol={currencySymbol}
            />
          </Col>

          <Col xl='12' className='col-xxl-6'>
            <OrderByMarketplace
              title='DASHBOARD.SALES_BY_CHANNEL'
              data={marketplace}
              total_invoice_count={total_invoice_count}
              toggleState="sales_by_channel"
            />
          </Col>

          <Col xl='12' className='col-xxl-6'>
            <OrderByMarketplace
              title='DASHBOARD.SALES_BY_COUNTRY'
              data={country}
              total_invoice_count={total_invoice_count}
              toggleState="sales_by_country"
            />
          </Col>

          <Col xs="12">
            <LineChartDailySales
              data={daily_sales}
              total_invoice_count={total_invoice_count}
            />
          </Col>

          <Col xl='12' className='col-xxl-6'>
            <TableTopProduct
              data={top_product}
              total_invoice_count={total_invoice_count}
              currency={currency}
            />
          </Col>

          <Col xl='12' className='col-xxl-6'>
            <TableTopCustomer
              data={top_costumer}
              total_invoice_count={total_invoice_count}
              currency={currency}
            />
          </Col>

        </Row>
      </CardBody>

      <LoadingDialog isLoading={loading} />

    </Card>
  );
}