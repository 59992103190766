import React, { useMemo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Portal } from 'react-portal';
import { useDispatch, useSelector } from 'react-redux';
import { ProductActions } from '../../../_redux/actions';
import { useProductEditUIContext } from '../../../_context/ProductEditUIContext';
import { getVariants, Variant, VARIANT_ACTION_TYPES } from '../core/helpers';
import { EditVariantForm } from './EditVariantForm';
import { useSkuValidationControl } from 'app/hooks';
import { maxValidation } from '_metronic/_helpers';
import { Form, Formik } from 'formik';
import { clone } from 'lodash';
import * as Yup from 'yup';



const initialFormValues = (variants, variantId) => {

  if (!variantId || !variants || variants.length === 0) {
    return null;
  }
  const variant = variants.find(variant => variant.id === variantId);
  if (!variant) {
    return null;
  }

  const initialValues = {
    id: variant.id,
    quantity: variant.quantity || 0,
    gross_price: variant.pricing.gross_price || 0,
    purchase_price: variant.pricing.purchase_price || 0,
    sku: variant.sku || '',
    ean: variant.ean || '',
    asin: variant.asin || '',
  };
  for (const spec of clone(variant.specs)) {
    initialValues[spec.name] = spec.value;
  }
  return initialValues;
};


export const EditVariantDialog = () => {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  // Get state and fn from ProductEditUIContext
  const productEditUIContext = useProductEditUIContext();
  const { showEditVariantDialog, variantId } = useMemo(() => productEditUIContext.newVariantsReducer, [productEditUIContext.newVariantsReducer]);
  const dispatchNewVariants = useMemo(() => productEditUIContext.dispatchNewVariants, [productEditUIContext.dispatchNewVariants]);
  const { checkIsSkuValidForVariants, checkIsSkuProductForEdit } = useSkuValidationControl();

  // Get Current Variants from Redux Store
  const productVariants = useSelector(state => state.products.variants);

  // Close Dialog
  const closeEditVariantDialog = () => dispatchNewVariants({ type: VARIANT_ACTION_TYPES.CLOSE_EDIT_VARIANT_DIALOG });

  // Submit Form
  const saveNewVariant = values => {
    const { id, sku, ean, asin, quantity, gross_price, purchase_price, image_urls, ...specs } = values;
    const variants = clone(productVariants);

    const variantIndex = variants.findIndex(v => v.id === id);
    variants[variantIndex] = new Variant({
      sku,
      quantity,
      pricing: {
        purchase_price: purchase_price,
        gross_price: gross_price,
      },
      image_urls,
      specs: Object.keys(specs).map(key => {
        return { name: key, value: specs[key] };
      }),
    });
    dispatch(ProductActions.setVariants(variants));
    closeEditVariantDialog();
  };

  const validationSchema = Yup.object().shape({
    sku: Yup.string()
      .strict(true)
      .trim(intl({ id: 'PRODUCT.GENERAL.SKU_TRIM' }))
      .required(intl({ id: 'PRODUCT.GENERAL.SKU_REQUIRED' }))
      .max(50, maxValidation('50'))
      .test('is-unique-sku', intl({ id: "PRODUCT.GENERAL.VARIANT_SKU_WARNING" }),
        function (value) { return !checkIsSkuValidForVariants(value) && !checkIsSkuProductForEdit(value); }
      ),
    quantity: Yup.number()
      .moreThan(-1, intl({ id: "PRODUCT.GENERAL.PRICE_THAN" }))
      .max(999999, maxValidation("999.999"))
      .test('is-integer-if-piece', intl({ id: "PRODUCT.GENERAL.INTEGER_REQUIRED" }), function (value) {
        if (value && value?.unit === 'piece' && value != null) { return Number.isInteger(value); }
        return true;
      })
      .test('is-valid-number', intl({ id: "PRODUCT.GENERAL.INVALID_NUMBER" }), function (value) {
        return value === null || /^\d+(\.\d+)?$/.test(value?.toString());
      })
      .nullable(true),
    ean: Yup.string()
      .max(13, maxValidation('13')),
    asin: Yup.string()
      .length(10, intl({ id: "PRODUCT.MORE_SETTINGS.ASIN_INFO" })),
    // .length(13, intl({ id: 'PRODUCT.GENERAL.EAN_LENGTH' })),
    // .matches(eanRegExp, intl({ id: 'PRODUCT.GENERAL.EAN_MATCHES' }))
    // .test('ean', intl({ id: 'PRODUCT.GENERAL.EAN_TEST' }), eanFunctions),
    gross_price: Yup.number()
      .min(0, intl({ id: 'PRODUCT.GENERAL.PRICE_THAN' }))
      .max(10000000, intl({ id: 'PRODUCT.GENERAL.PRICE_MAX' })),
    purchase_price: Yup.number()
      .min(0, intl({ id: 'PRODUCT.GENERAL.PRICE_THAN' }))
      .test('Is positive?', intl({ id: 'PRODUCT.GENERAL.PRICE_THAN' }), value => value >= 0)
      .max(10000000, intl({ id: 'PRODUCT.GENERAL.PRICE_MAX' })),
    // Adding dynamic validation for each selected variant spec
    ...getVariants(productVariants).reduce((acc, curr) => {
      acc[curr.value] = Yup.string().required(intl({ id: 'PRODUCT.GENERAL.SPEC_REQUIRED' }));
      return acc;
    }, {}),
  })

  return (
    <Portal node={document && document.getElementById('modal-portal')}>
      <Modal show={showEditVariantDialog} onHide={closeEditVariantDialog} size="md" backdrop="static" keyboard={false}>
        <Formik enableReinitialize={true} initialValues={initialFormValues(productVariants, variantId)} validationSchema={validationSchema} onSubmit={saveNewVariant}>

          <Form >
            <Modal.Header>
              <Modal.Title>
                <FormattedMessage id='PRODUCT.VARIANT.ATTRIBUTES_SELECT' />
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <EditVariantForm
                initialValues={initialFormValues(productVariants, variantId)}
                currentSpecs={getVariants(productVariants)}
              />
            </Modal.Body>

            <Modal.Footer>
              <Button variant='light' onClick={closeEditVariantDialog} id="btn_cancel_modal">
                <FormattedMessage id='GENERAL.CANCEL' />
              </Button>
              <Button variant='primary' type='submit' className="ml-2" id="btn_save_modal">
                <FormattedMessage id='GENERAL.SAVE' />
              </Button>
            </Modal.Footer>
          </Form>

        </Formik>
      </Modal>

    </Portal>
  );
};
