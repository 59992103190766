import React from 'react';
import { Field, useFormikContext } from 'formik';
import { Card, CardBody, CardSubHeader, SVFormTextarea } from '_metronic/_partials/controls';
import { FormattedMessage, useIntl } from 'react-intl';
import { UserInfoDropdown } from '_metronic/_partials';
import { Row, Col } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';


export const headerLength = 200;
export const footerLength = 1500;


export function Text({ loading }) {

  const { formatMessage: intl } = useIntl();
  const { values: { default_subtitle: { header, footer } } } = useFormikContext();

  const INPUT = [
    {
      name: "default_subtitle.header",
      label: "SETTINGS.DOCUMENT.TEXT_BEFORE_LABEL",
      withFeedbackLabel: true,
      feedbackLabel: header?.length
        ? <span className={`${header && header?.length <= (headerLength * 80 / 100) ? 'text-success' : header && header?.length <= headerLength ? 'text-warning' : 'text-danger'}`}>
          <FormattedMessage id="SETTINGS.DOCUMENT.REMAINING_CHARACTER" values={{ character: headerLength - header?.length }} />
        </span>
        : true,
    },
    {
      name: "default_subtitle.footer",
      label: "SETTINGS.DOCUMENT.TEXT_AFTER_LABEL",
      withFeedbackLabel: true,
      feedbackLabel: footer?.length
        ? <span className={`${footer && footer?.length <= (footerLength * 80 / 100) ? 'text-success' : footer && footer?.length <= footerLength ? 'text-warning' : 'text-danger'}`}>
          <FormattedMessage id="SETTINGS.DOCUMENT.REMAINING_CHARACTER" values={{ character: footerLength - footer?.length }} />
        </span>
        : true,
    }
  ]


  return (
    <Card className='gutter-b'>

      <CardSubHeader
        icon={<Icon.TextIndentLeft />}
        title={<FormattedMessage id='SETTINGS.DOCUMENT.TEXT_TITLE' />}
        info={<UserInfoDropdown
          title={<FormattedMessage id='SETTINGS.DOCUMENT.TEXT_TITLE' />}
          description={<FormattedMessage id='USER_GUIDE.SETTINGS.DOCUMENT.HEADER_FOOTER' />} />}
      />

      <CardBody>
        <Row>

          {INPUT.map((item, i) => (
            <Col lg="6" key={i}>
              <Field component={SVFormTextarea}
                {...{
                  name: item.name,
                  label: intl({ id: item.label }),
                  rows: 6,
                  maxlength: 10,
                  withFeedbackLabel: item.withFeedbackLabel,
                  feedbackLabel: item.feedbackLabel,
                  disabled: loading,
                }}
              />
            </Col>
          ))}

        </Row>
      </CardBody>
    </Card>
  );
}