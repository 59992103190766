import { API } from 'constants/apiUrl';



export const userMiddleware = {

  login: async function (payload) {
    return await API.post(`/user/login`, payload);
  },


  logout: async function (accessToken) {
    return await API.get(`/user/logout`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  fetchUserData: async function (accessToken) {
    return await API.get(`/user`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  patchUserData: async function (accessToken, patchData) {
    return await API.patch(`/user`, patchData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  createUser: async function (payload) {
    return await API.post(`/user`,
      payload
    );
  },


  emailVerification: async function (payload) {
    if (payload.recaptcha_token) {
      return await API.post(`/user/email-verification-again`, payload);
    } else {
      return await API.post(`/user/email-verification`, payload);
    }
  },


  ping: async function () {
    return await API.get(`/ping`);
  },


  resetPassword: async function (email) {
    return await API.post(`/user/reset`, email);
  },


  createNewPassword: async function (payload) {
    return await API.put(`/user/reset-password`, payload);
  },


  changePassword: async function (accessToken, changePassword) {
    return await API.patch(`/user/change-password`, changePassword, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  changeEmail: async function (accessToken, changeEmail) {
    return await API.patch(`/user/change-email`, changeEmail, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  contract: async function (type) {
    return await API.get(`/contract`, {
      params: {
        filter: {
          where: { type },
          order: ["created_at DESC"],
        }
      },
    });
  },


  deletedRequest: async function (accessToken, data) {
    return await API.post(`/user/deleted-request`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getCounts: async function (accessToken) {
    return await API.get(`/user/get-counts`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
};
