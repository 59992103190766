import React, { useMemo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDocumentsUIContext } from '../_context/DocumentsUIContext';
import { FormattedMessage } from 'react-intl';
import { Portal } from 'react-portal';



export function ConvertDocumentAlertDialog() {

  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showConvertDocumentAlertDialog,
    onHide: UIContext.closeConvertDocumentAlertDialog,
    confirmedParams: UIContext.confirmedParams,
    pushConvertDocument: UIContext.pushConvertDocument,
  }),
    [
      UIContext.closeConvertDocumentAlertDialog,
      UIContext.confirmedParams,
      UIContext.pushConvertDocument,
      UIContext.showConvertDocumentAlertDialog,
    ]
  );

  const confirmConvertDocument = () => {
    const { id, from, to } = UIProps.confirmedParams;
    UIProps.pushConvertDocument(id, from, to);
    UIProps.onHide();
  };


  return (
    <Portal node={document && document.getElementById('modal-portal')}>
      <Modal show={UIProps.show} onHide={UIProps.onHide} size="md" backdrop="static" aria-labelledby="example-modal-sizes-title-md" centered>

        <Modal.Body className="text-center line-height-lg h6 py-10 mb-0">
          <FormattedMessage id='DOCUMENT.CONVERT.ALERT_TEXT' />
        </Modal.Body>

        <Modal.Footer className="flex-nowrap overflow-hidden p-0">

          <Button variant='ios' className="border-right" onClick={UIProps.onHide} id="btn_close_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant='ios' className="text-danger" onClick={confirmConvertDocument} id="btn_alert_modal">
            <FormattedMessage id='GENERAL.CONTINUE' />
          </Button>

        </Modal.Footer>

      </Modal>
    </Portal>
  );
}
