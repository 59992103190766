/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDashboardUIContext } from '../_context/DashboardUIContext';
import { UIDates, theme } from '_metronic/_helpers';
import { Dropdown, Form } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';
import moment from 'moment';
import clsx from 'clsx';



export function DatePickerDropdown({ setFilterQuery }) {

  const dashboardUIContext = useDashboardUIContext();
  const { formatMessage: intl } = useIntl()

  const UIProps = useMemo(() => {
    return {
      changeDateRange: dashboardUIContext.changeDateRange,
      dateRange: dashboardUIContext.dateRange,
      setDateRange: dashboardUIContext.setDateRange,
      initialDateRange: dashboardUIContext.initialDateRange,
      setQueryBase: dashboardUIContext.setQueryBase,
      queryParams: dashboardUIContext.queryParams,
      setFilterQuery: dashboardUIContext.setFilterQuery
    };
  }, [dashboardUIContext]);


  const today = UIDates.getCurrentDate()

  const [customDateRange, setCustomDateRange] = useState({
    date_from: UIDates.dayCalculation(-30),
    date_to: today
  })

  const optionData = [
    {
      id: "todays",
      label: intl({ id: "DASHBOARD.DATE.TODAY" }),
      date_from: today,
      date_to: today
    },
    {
      id: "last-7-days",
      label: intl({ id: "DASHBOARD.DATE.LAST_7_DAYS" }),
      date_from: UIDates.dayCalculation(-7),
      date_to: today
    },
    {
      id: "last-14-days",
      label: intl({ id: "DASHBOARD.DATE.LAST_14_DAYS" }),
      date_from: UIDates.dayCalculation(-14),
      date_to: today
    },
    {
      id: "last-30-days",
      label: intl({ id: "DASHBOARD.DATE.LAST_30_DAYS" }),
      date_from: UIDates.dayCalculation(-30),
      date_to: today
    },
    {
      id: "last-90-days",
      label: intl({ id: "DASHBOARD.DATE.LAST_90_DAYS" }),
      date_from: UIDates.dayCalculation(-90),
      date_to: today
    },
    {
      id: "this-month",
      label: intl({ id: "DASHBOARD.DATE.THIS_MONT" }),
      date_from: UIDates.getCurrentDate("YYYY-MM-01"),
      date_to: today
    },
    {
      id: "last-month",
      label: intl({ id: "DASHBOARD.DATE.LAST_MONT" }),
      date_from: moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD"),
      date_to: moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD"),
    },
    {
      id: "this-year",
      label: intl({ id: "DASHBOARD.DATE.THIS_YEAR" }),
      date_from: UIDates.getCurrentDate("YYYY-01-01"),
      date_to: today
    },
  ]

  // useEffect(() => {
  //   setFilterQuery('DATE_RANGE', [UIProps.dateRange.date_from, UIProps.dateRange.date_to])
  // }, [UIProps.dateRange, setFilterQuery]);

  const dateChanger = (e) => {

    const selectedDate = optionData?.find(item => item.id === e.target.id);

    if (e.target.id === 'custom') {
      UIProps.setDateRange({
        id: e.target.id,
        label: intl({ id: "GENERAL.CUSTOM_RANGE" }),
        date_from: moment().add(-30, "d").format("YYYY-MM-DD"),
        date_to: today
      });
      UIProps.setFilterQuery('DATE_RANGE', [moment().add(-30, "d").format("YYYY-MM-DD"), today]);
      return;
    }

    if (e.target.id === "clear-date") {

      UIProps.setQueryBase({
        ...UIProps.queryParams,
        filter: {
          ...UIProps.queryParams.filter,
          date_from: UIDates.dayCalculation(-30),
          date_to: UIDates.getCurrentDate(),
        }
      })
      UIProps.changeDateRange()
      setFilterQuery('DATE_RANGE', [UIDates.dayCalculation(-30), UIDates.getCurrentDate()]);
      return;
    }

    UIProps.setDateRange({
      ...UIProps.dateRange,
      id: selectedDate?.id,
      label: selectedDate?.label,
      date_from: selectedDate?.date_from,
      date_to: selectedDate?.date_to
    });
    setFilterQuery('DATE_RANGE', [selectedDate?.date_from, selectedDate?.date_to])

  };

  const dateChangerCustom = (e) => {
    setCustomDateRange({ ...customDateRange, [e.target.name]: e.target.value })
    setFilterQuery(e.target.name, e.target.value);
    UIProps.setDateRange({
      ...UIProps.dateRange,
      [e.target.name]: e.target.value
    })
  }

  return (
    <Dropdown drop="down" className='dropdown-inline symbol ml-2'>

      <Dropdown.Toggle variant='outline-secondary' className='d-flex align-items-center font-weight-bold' style={{ height: theme.units.input.height['sm'] }} id="btn_date_picker">
        <span className="svg-icon svg-icon-sm">
          <Icon.Calendar />
        </span>
        <span className="d-none d-sm-inline ml-2">
          {UIProps.dateRange?.id === 'clear-date' ? <FormattedMessage id='GENERAL.DATE_FILTER' /> : UIProps.dateRange?.label}
        </span>
      </Dropdown.Toggle>

      {UIProps.dateRange.id !== "last-30-days" && <i className="symbol-badge bg-danger"></i>}

      <Dropdown.Menu className='dropdown-menu dropdown-menu-right dropdown-menu-anim-up' id='dropdown_date_picker'>
        <Form>
          <ul className="navi navi-hover">
            {optionData?.map((item, i) => (
              <li className="navi-item" key={i}>
                <Dropdown.Item className={clsx('navi-link font-weight-bolder', { active: item.id === UIProps.dateRange?.id })} onClick={dateChanger} id={item.id}>
                  <span className="navi-text text-nowrap font-weight-bold" id={item.id}>
                    {item.label}
                  </span>
                </Dropdown.Item>
              </li>
            ))}

            <li className="navi-item">
              <a className='dropdown-item navi-link font-weight-bolder' href="#" id="custom" onClick={dateChanger}>
                <FormattedMessage id='GENERAL.SET_CUSTOM_RANGE' />
              </a>

              {UIProps.dateRange.id === "custom" && <div className='m-3'>
                <div className='d-flex align-items-center flex-row flex-nowrap mb-3'>
                  <Form.Control name="date_from" type="date" value={UIProps?.dateRange.date_from} min={"1990-01-01"} max={UIProps.dateRange.date_to || today}
                    onChange={dateChangerCustom} disabled={UIProps.dateRange.id !== "custom"} />
                  <b className='mx-2'><FormattedMessage id="GENERAL.TO" /></b>
                  <Form.Control name="date_to" type="date" value={UIProps?.dateRange.date_to} max={today} min={UIProps?.dateRange.date_from}
                    onChange={dateChangerCustom} disabled={UIProps.dateRange.id !== "custom"} />
                </div>

              </div>}
            </li>

            <Dropdown.Divider />

            <li className="navi-item">
              <Dropdown.Item className='navi-link font-weight-bolder text-danger justify-content-center bg-white py-1' onClick={dateChanger} id='clear-date'>
                <FormattedMessage id='GENERAL.CLEAR' />
              </Dropdown.Item>
            </li>

          </ul>
        </Form>
      </Dropdown.Menu>

    </Dropdown>

  );
}
