import React from "react";
import { FormattedMessage } from "react-intl";
import { ModuleRoutes } from "constants/moduleRoutes";



export const defaultSorted = [{ dataField: 'id', order: 'desc' }];

export const INITIAL_CUSTOMER = {
  active: true,
  type: 'standard',
  name: '',
  company: '',
  email: '',
  fax: '',
  mobile: '',
  phone: '',
  tax_number: '',
  vat_id: '',
  notes: '',
  discount: 0,
  bank: {
    name: '',
    bic: '',
    iban: '',
  },
  address: [],
  source: {
    buyer_id: '',
    marketplace: ['salevali'],
  }
};


export const customerEditTabs = [
  {
    name: "general",
    label: "GENERAL.GENERAL"
  },
  {
    name: "bank",
    label: "GENERAL.BANK_DETAILS"
  },
  {
    name: "address",
    label: "GENERAL.ADDRESS"
  },
  {
    name: "special",
    label: "CUSTOMER.SPECIAL_TITLE"
  }
];


export const customerTypeOptions = [
  {
    value: 'standard',
    label: <FormattedMessage id="CUSTOMER.TYPE.STANDARD" />
  },
  {
    value: 'private',
    label: <FormattedMessage id="CUSTOMER.TYPE.PRIVATE" />
  },
  {
    value: 'company',
    label: <FormattedMessage id="CUSTOMER.TYPE.BUSINESS" />
  },
];


export const customerTypeOptions1 = [
  {
    value: 'standard',
    label: "CUSTOMER.TYPE.STANDARD"
  },
  {
    value: 'private',
    label: "CUSTOMER.TYPE.PRIVATE"
  },
  {
    value: 'company',
    label: "CUSTOMER.TYPE.BUSINESS"
  },
];


export const DOCUMENTS_COUNT = [
  {
    type: 'invoice',
    label: 'DOCUMENT.INVOICE_TITLE',
    link: ModuleRoutes.DOCUMENT_INVOICE,
  },
  {
    type: 'order',
    label: 'DOCUMENT.ORDER_TITLE',
    link: ModuleRoutes.DOCUMENT_ORDER,
  },
  {
    type: 'offer',
    label: 'DOCUMENT.OFFER_TITLE',
    link: ModuleRoutes.DOCUMENT_OFFER,
  },
  {
    type: 'credit',
    label: 'DOCUMENT.CREDIT_TITLE',
    link: ModuleRoutes.DOCUMENT_CREDIT,
  },
  {
    type: 'waybill',
    label: 'DOCUMENT.WAYBILL_TITLE',
    link: ModuleRoutes.DOCUMENT_WAYBILL,
  },
];