import React, { forwardRef, useEffect, useState } from "react";
import clsx from "clsx";



export const CardHeaderIcon = forwardRef(({ className }, ref) => (
  <span className={clsx("card-head-icon", className)} ref={ref} />
));

export const CardHeaderTitle = forwardRef(({ className, ...props }, ref) => (
  // eslint-disable-next-line jsx-a11y/heading-has-content
  <h2 className={clsx("card-label", className)} ref={ref} {...props} />
));

export const CardHeaderToolbar = forwardRef(({ children, className, ...props }, ref) => (
  <div className={clsx("card-toolbar", className)} ref={ref} {...props}>
    {children}
  </div>
));

export const CardHeader = forwardRef(({
  children,
  icon,
  back,
  title,
  toolbar,
  className,
  sticky = false,
  labelRef,
  info,
  titlePosition,
  id,
  ...props
}, ref) => {

  const [top, setTop] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);

  function handleResize() {
    setWindowHeight(window.innerWidth);
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Skip if sticky is disabled or on initial render when we don't know about window height.
    if (!sticky || windowHeight === 0) {
      return;
    }

    const headerElement = document.querySelector(".header");
    const subheaderElement = document.querySelector(".subheader");
    const headerMobileElement = document.querySelector(".header-mobile");

    let nextMarginTop = 0;

    // mobile header
    if (window.getComputedStyle(headerElement).height === "0px") {
      nextMarginTop = headerMobileElement.offsetHeight;
    } else {
      // desktop header
      if (document.body.classList.contains("header-minimize-topbar")) {
        // hardcoded minimized header height
        nextMarginTop = 60;
      } else {
        // normal fixed header
        if (document.body.classList.contains("header-fixed")) {
          nextMarginTop += headerElement.offsetHeight;
        }

        if (document.body.classList.contains("subheader-fixed")) {
          nextMarginTop += subheaderElement.offsetHeight;
        }
      }
    }

    setTop(nextMarginTop);
  }, [sticky, windowHeight]);


  return (
    <div className="card-header" ref={ref} style={
      !sticky
        ? undefined
        : { top, position: "sticky", backgroundColor: "#fff", zIndex: 20, boxShadow: "0px 0px 40px 0px rgba(82, 63, 105, 0.1)" }
    }>

      {title != null && (
        <h3 className={clsx("card-title m-0", className)} ref={labelRef} id={id}>
          {icon && <span className="d-flex mr-4">{icon}</span>}
          {back && back}
          {title}
          {info}
        </h3>
      )}

      {toolbar}
      {children}
    </div>
  );
});


export const CardSubHeader = forwardRef(({
  children,
  icon,
  title,
  toolbar,
  className,
  labelRef,
  info,
  id,
  ...props
}, ref) => {

  return (
    <div className="card-header card-subheader d-flex flex-wrap align-items-center justify-content-between" ref={ref}>

      {title != null && (
        <div className="preview d-flex justify-items-middle">
          <h4 className={clsx("d-flex align-items-center mb-0", className)} ref={labelRef} id={id}>
            {icon && <span className="d-flex mr-3">{icon}</span>}
            {title}
            {info}
          </h4>
        </div>
      )}

      {toolbar}
      {children}
    </div>
  );
});


export const CardBody = forwardRef(({ fit, fluid, className, ...props }, ref) => (
  <div className={clsx("card-body", { "card-body-fit": fit, "card-body-fluid": fluid }, className)} ref={ref} {...props} />
));

export const CardFooter = forwardRef(({ className, ...props }, ref) => (
  <div className={clsx("card-footer", className)} ref={ref} {...props} />
));

export const Card = forwardRef(({ fluidHeight, className, ...props }, ref) => (
  <div className={clsx("card card-custom", { "card-height-fluid": fluidHeight }, className)} ref={ref} {...props} />
));



// Set display names for debugging.
if (process.env.NODE_ENV !== 'production') {
  Card.displayName = "Card";

  CardHeader.displayName = "CardHeader";
  CardHeaderIcon.displayName = "CardHeaderIcon";
  CardHeaderTitle.displayName = "CardHeaderTitle";
  CardHeaderToolbar.displayName = "CardHeaderToolbar";

  CardBody.displayName = "CardBody";
  CardFooter.displayName = "CardFooter";
}
