import React from "react";
import { Card, CardBody, CardSubHeader, CkEditor } from "_metronic/_partials/controls";
import { useDispatch, useSelector } from "react-redux";
import { ProductActions } from "../../_redux/actions";
import { FormattedMessage } from "react-intl";
import { Icon } from '_metronic/_icons';



export function Descriptions({ loading }) {

  const dispatch = useDispatch();

  const { description } = useSelector((state) => ({
    description: state.products.description,
  }));

  return (
    <Card className="gutter-b">
      <CardSubHeader title={<FormattedMessage id="GENERAL.DESCRIPTION" />} icon={<Icon.JustifyLeft />} id="description" />
      <CardBody>

        <CkEditor name="description" data={description}
          onChange={(e, editor) => {
            const data = editor.getData();
            dispatch(ProductActions.descriptionHandler(data));
          }}
          style={{ height: "400px" }}
          disabled={loading}
        />

      </CardBody>
    </Card>
  );
}
