import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardSubHeader, SVFormControl } from '_metronic/_partials/controls';
import { ProductActions } from '../../_redux/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, useFormikContext } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';



const isMatchedFieldValue = (matchArray, key, value, productId) => {
  let isMatched = false;
  if (matchArray?.length > 0) {
    const isEdit = matchArray.find(fp => fp.id === productId);
    if (isEdit) { isMatched = false; } else { isMatched = matchArray.find(fp => fp.identities[key] === value); }
  }
  return isMatched ? true : false;
};


export function Features({ productId, loading }) {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();

  const { values: { identities }, touched, setFieldTouched } = useFormikContext();

  const { findBarcode, findEan, findGite, findIsbn, findAsin, findUpc, variants } = useSelector(state => ({
    findBarcode: state.products.findProductForMatchingBarcode,
    findEan: state.products.findProductForMatchingEan,
    findGite: state.products.findProductForMatchingGtin,
    findIsbn: state.products.findProductForMatchingIsbn,
    findAsin: state.products.findProductForMatchingAsin,
    findUpc: state.products.findProductForMatchingfindUpc,
    variants: state.products.variants,
  }));

  const INPUTS = [
    {
      name: 'identities.barcode',
      label: "PRODUCT.MORE_SETTINGS.BARCODE",
      feedbackLabel: isMatchedFieldValue(findBarcode, 'barcode', identities?.barcode, productId) ?
        intl({ id: "PRODUCT.MORE_SETTINGS.BARCODE_WARNING" }) : true,
      size: 6
    },
    {
      name: 'identities.ean',
      label: "PRODUCT.MORE_SETTINGS.EAN",
      feedbackLabel: isMatchedFieldValue(findEan, 'ean', identities?.ean, productId) ?
        intl({ id: "PRODUCT.MORE_SETTINGS.EAN_WARNING" }) : true,
      info: "PRODUCT.MORE_SETTINGS.EAN_INFO",
      size: 6
    },
    {
      name: 'identities.asin',
      label: "PRODUCT.MORE_SETTINGS.ASIN",
      feedbackLabel: isMatchedFieldValue(findAsin, 'asin', identities?.asin, productId) ?
        intl({ id: "PRODUCT.MORE_SETTINGS.ASIN_WARNING" }) : true,
      info: "PRODUCT.MORE_SETTINGS.ASIN_INFO",
      size: 6
    },
    {
      name: 'identities.isbn',
      label: "PRODUCT.MORE_SETTINGS.ISBN",
      feedbackLabel: isMatchedFieldValue(findIsbn, 'isbn', identities?.isbn, productId) ?
        intl({ id: "PRODUCT.MORE_SETTINGS.ISBN_WARNING" }) : true,
      info: "PRODUCT.MORE_SETTINGS.ISBN_INFO",
      size: 6
    },

    {
      name: 'identities.upc',
      label: "PRODUCT.MORE_SETTINGS.UPC",
      feedbackLabel: isMatchedFieldValue(findUpc, 'upc', identities?.upc, productId) ?
        intl({ id: "PRODUCT.MORE_SETTINGS.UPC_WARNING" }) : true,
      info: "PRODUCT.MORE_SETTINGS.UPC_INFO",
      size: 6
    },
    {
      name: 'identities.gtin',
      label: "PRODUCT.MORE_SETTINGS.GTIN",
      feedbackLabel: isMatchedFieldValue(findGite, 'gtin', identities?.gtin, productId) ?
        intl({ id: "PRODUCT.MORE_SETTINGS.GTIN_WARNING" }) : true,
      size: 3
    },
    {
      name: "identities.mpn", /* 'herstellergtin', */
      label: intl({ id: "PRODUCT.MORE_SETTINGS.MPN" }),
      feedbackLabel: true,
      size: 3
    },
    {
      name: 'dimensions.weight',
      type: 'number',
      label: "GENERAL.WEIGHT",
      symbol: 'PRODUCT.MORE_SETTINGS.WEIGHT_PLACEHOLDER',
      size: 3,
      feedbackLabel: true
    },
    {
      name: 'dimensions.width',
      type: 'number',
      label: "PRODUCT.MORE_SETTINGS.WIDTH",
      symbol: 'PRODUCT.MORE_SETTINGS.WIDTH_PLACEHOLDER',
      size: 3,
      feedbackLabel: true
    },
    {
      name: 'dimensions.height',
      type: 'number',
      label: "PRODUCT.MORE_SETTINGS.HEIGHT",
      symbol: 'PRODUCT.MORE_SETTINGS.HEIGHT_PLACEHOLDER',
      size: 3,
      feedbackLabel: true
    },
    {
      name: 'dimensions.length',
      type: 'number',
      label: "PRODUCT.MORE_SETTINGS.DEPTH",
      symbol: 'PRODUCT.MORE_SETTINGS.DEPTH_PLACEHOLDER',
      size: 3,
      feedbackLabel: true
    },
  ];


  // Products Saga daki FIND PRODUCT BY PROPERTY WARNING i findProductByPropertyWarning() functionu ile kullanıyoruz
  // setFieldTouched("barcode", false); bunu touched u tekrar false yapmak icin kullaniyoruz. yoksa touched olmussa her zaman true kaliyor

  const identityProperties = ['ean', 'asin', 'isbn', 'gtin', 'barcode'];

  useEffect(() => {
    identityProperties.forEach((property) => {
      const value = identities?.[property];
      const isTouched = touched?.identities?.[property];
      if (value && isTouched) {
        dispatch(ProductActions.findProductByPropertyWarning(`identities.${property}`, value));
      };
      setFieldTouched(`identities.${property}`, true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, setFieldTouched, touched, ...identityProperties.map(prop => identities[prop])]);


  return (
    <Card className="gutter-b">
      <CardSubHeader title={<FormattedMessage id="PRODUCT.FEATURES" />} icon={<Icon.UpcScan />} id="features" />
      <CardBody>
        <Row>

          {INPUTS.map((item, i) => (

            !((item.name === "identities.ean" || item.name === "identities.asin") && !variants.length <= 0) &&

            <Col lg={item.size} key={i}>
              <Field component={SVFormControl}
                {...{
                  name: item.name,
                  type: item.type,
                  min: 0,
                  label: intl({ id: item.label }),
                  withFeedbackLabel: true,
                  feedbackLabel: item.feedbackLabel,
                  info: item.info ? intl({ id: item.info }) : '',
                  symbol: item?.symbol ? intl({ id: item.symbol }) : '',
                  disabled: loading,
                }}
              />
            </Col>
          ))}

        </Row>
      </CardBody>
    </Card>
  );
}
