import React, { useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "_metronic/_partials/controls";
import { CustomersFilter } from "./customer-filter/CustomersFilter";
import { CustomersTable } from "./customer-table/CustomersTable";
import { UserGuideHelper } from "../../user-guides/UserGuideHelper";
import { useSearchQueryContext } from "./_context/SearchQueryProvider";
import { LoadingDialog } from "_metronic/_partials/loading";
import { BlankMessage } from '_metronic/_partials/components';
import { useDispatch, useSelector } from "react-redux";
import { ModuleRoutes } from "constants/moduleRoutes";
import { CustomerActions } from "./_redux/actions";
import { NewButton } from "_metronic/_partials";
import { Icon } from '_metronic/_icons';



export function CustomersCard() {

  const dispatch = useDispatch();

  const { entities, loading, countCustomers, totalCustomers, connectionEntities } = useSelector(state => ({
    entities: state.customers.entities,
    loading: state.customers.loading,
    countCustomers: state.customers.countCustomers,
    totalCustomers: state.customers.totalCustomers,
    connectionEntities: state.connections.entities
  }));

  const queryContext = useSearchQueryContext();
  const { queryParams, setOrderQuery, setPaginationQuery, setSearchQuery, setFilterQuery } = useMemo(() => ({
    queryParams: queryContext.queryParams,
    setOrderQuery: queryContext.setOrderQuery,
    setPaginationQuery: queryContext.setPaginationQuery,
    setSearchQuery: queryContext.setSearchQuery,
    setFilterQuery: queryContext.setFilterQuery,
  }), [queryContext]);


  useEffect(() => {
    let delayDebounceFn;
    if (queryParams.search) {
      delayDebounceFn = setTimeout(() => {
        dispatch(CustomerActions.getCustomers(queryParams))
      }, 350);
    } else {
      dispatch(CustomerActions.getCustomers(queryParams))
    }

    return () => { clearTimeout(delayDebounceFn) };
  }, [dispatch, queryParams]);


  useEffect(() => {
    return () => {
      dispatch(CustomerActions.cleanCustomer())
    };
  }, [dispatch]);



  return (
    <Card className="card-height">

      <CardHeader
        icon={<Icon.Customer />}
        title={<FormattedMessage id="CUSTOMER.TITLE_PLURAL" />}
        info={<UserGuideHelper />}
        className="display-5"
        id='title_customers'
      >

        <CardHeaderToolbar>
          <NewButton label="CUSTOMER.NEW_CUSTOMER" link={ModuleRoutes.NEW_CUSTOMER} />
        </CardHeaderToolbar>

      </CardHeader>

      <CardBody>
        {totalCustomers === 0
          ? <BlankMessage
            icon={<Icon.Customer />}
            title={<FormattedMessage id='CUSTOMER.BLANK_MESSAGE_TITLE' />}
            message={<FormattedMessage id='CUSTOMER.BLANK_MESSAGE' />}
            className='min-50-height'
            loading={loading}
            arrow
          />
          : <>
            <CustomersFilter setSearchQuery={setSearchQuery} setFilterQuery={setFilterQuery} connectionEntities={connectionEntities} />
            <CustomersTable
              loading={loading}
              entities={entities}
              countCustomers={countCustomers}
              queryParams={queryParams}
              setPaginationQuery={setPaginationQuery}
              setOrderQuery={setOrderQuery}
              setSearchQuery={setSearchQuery}
              setFilterQuery={setFilterQuery}
            />
          </>
        }
      </CardBody>

      <LoadingDialog isLoading={loading} />

    </Card>
  );
}