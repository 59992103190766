import React from 'react'
import { StyledInput } from './sv-ui/SVFormControl'
import { Icon } from '_metronic/_icons';
import { useIntl } from 'react-intl';
import clsx from 'clsx';



export function SearchControl({
  search,
  handleSearch,
  name = 'search',
  placeholder = 'GENERAL.SEARCH',
  autoComplete = 'off',
  size = 'sm',
  ...props
}) {
  const { formatMessage: intl } = useIntl();

  return (
    <div className='position-relative d-flex align-items-center justify-content-end'>

      <StyledInput
        type="search"
        name={name}
        placeholder={intl({ id: placeholder })}
        className={clsx('form-control', size && `form-control-${size}`)}
        value={search ?? undefined}
        onChange={e => handleSearch(e.target.value)}
        autoComplete={autoComplete}
        size={size}
        {...props}
      />

      {!search &&
        <span className='position-absolute svg-icon text-dark-25 pr-3'>
          <Icon.Search />
        </span>
      }

    </div>
  )
}
