/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { RemotePagination } from '_metronic/_partials/controls';
import { sortCaret, headerSortingClasses, NoRecordsFoundMessage, UIDates, getSelectRowKeys } from '_metronic/_helpers';
import { useDigitalProductsUIContext } from '../_context/DigitalProductsUIContext';
import * as columnFormatters from './column-formatters/ColumnFormatters';
import * as uiHelpers from '../_context/DigitalProductsUIHelpers';
import { getConnection } from 'constants/connectionSettings';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function DigitalProductsTable({ entities, countDigitalProduct, loading, queryParams, setPaginationQuery, setOrderQuery, changeDateRange, setFilterQuery }) {

  const { formatMessage: intl } = useIntl();

  const UIContext = useDigitalProductsUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    openCustomerDetailsDialog: UIContext.openCustomerDetailsDialog,
    openSendMailKeyDialog: UIContext.openSendMailKeyDialog,
    openReserveDialog: UIContext.openReserveDialog
  }),
    [UIContext]
  );


  const COLUMNS = [
    {
      dataField: 'marketplace',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.MARKETPLACE' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap text-left pl-4',
      classes: 'font-weight-bold text-left pl-4',
      headerStyle: { width: '15%' },
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">

            <div className="d-flex svg-icon svg-icon-xl p-1 mr-4">
              {row.marketplace_url
                ? <OverlayTrigger overlay={<Tooltip id="market-tooltip">{row.marketplace_url}</Tooltip>}>
                  <a href={row.marketplace_url} target="_blank" rel="noopener noreferrer" id={`btn_${row.id}`}>
                    {getConnection(cell)?.icon ?? cell}
                  </a>
                </OverlayTrigger>
                : <Icon.ShopWindow className="text-dark-25" />
              }
            </div>

            <div>
              <div className="font-weight-bolder font-size-lg text-dark-75">
                {row.order_number ? row.order_number && `#${row.order_number}` : ""}
              </div>
              <div className="font-weight-bold text-dark-50">
                {row.order_id}
              </div>
            </div>

          </div>
        )
      },
    },
    {
      dataField: 'sku',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.ARTICLE' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap',
      headerStyle: { minWidth: "200px", width: '20%' },
      formatter: (cell, row) => {
        return (
          <OverlayTrigger overlay={<Tooltip id={`quick-category-tooltip-key-${row.id}`}>{row.key}</Tooltip>}>
            <div className='d-flex flex-column mr-1'>
              <span className='font-weight-bold text-nowrap'>{row.product}</span>
              <span className='text-dark-50'>{row.sku}</span>
            </div>
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: 'email',
      text: intl({ id: 'CUSTOMER_TITLE' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: "150px", width: '20%' },
      formatter: (cell, row) => {
        return (
          <a type="button" className="d-flex align-items-center text-decoration-none text-hover-primary text-nowrap preview" onClick={() => UIProps.openCustomerDetailsDialog(row.order_id)} id='btn_customer_preview'>
            <div className='d-flex flex-column mr-1'>
              <span className='font-weight-bold text-nowrap'>{row.name}</span>
              <span className='text-dark-50'>{row.email}</span>
            </div>
            <span className='d-flex invisible text-dark-25 svg-icon svg-icon-sm pl-2'>
              <Icon.FileEarmarkText />
            </span>
          </a>
        );
      },
    },
    {
      dataField: 'confirmation_date',
      text: intl({ id: 'GENERAL.STATUS' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center',
      headerStyle: { minWidth: "80px", width: '5%' },
      classes: 'text-center pr-6',
      formatter: (cell, row) => {
        return row.order_date && (
          <OverlayTrigger overlay={<Tooltip id={`quick-category-tooltip-date-${row.id}`}><div className='font-weight-bold'><FormattedMessage id={row.confirmation_date ? 'PRODUCT.KEYS.SENT' : 'PRODUCT.KEYS.RESERVE'} /></div>{row.confirmation_date ? UIDates.formatDateTime(row.confirmation_date) : UIDates.formatDateTime(row.order_date)}</Tooltip>}>
            <span className={`icon-md text-${row.confirmation_date ? "success" : "warning"}`}>
              <Icon.CheckCircleFill />
            </span>
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: 'order_date',
      text: intl({ id: 'DOCUMENT.DETAIL_DIALOG.ORDER.DATE' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap text-center',
      classes: 'text-nowrap text-center pr-6',
      headerStyle: { width: '15%' },
      formatter: (cell, row) => UIDates.formatDateTime(cell),
    },
    {
      dataField: 'email_sended_date',
      text: intl({ id: 'GENERAL.EMAIL' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center',
      headerStyle: { minWidth: "90px", width: '5%' },
      classes: 'text-center pr-6',
      formatter: (cell, row) => {
        if (row.order_date && row.email_sended_date) {
          return (
            <OverlayTrigger overlay={<Tooltip id={`quick-category-tooltip-email-${row.id}`}><div className='font-weight-bold'><FormattedMessage id='PRODUCT.KEYS.SENT_EMAIL' /></div>{UIDates.formatDateTime(row.email_sended_date)}</Tooltip>}>
              <span className='icon-md text-success'>
                <Icon.CheckCircleFill />
              </span>
            </OverlayTrigger>
          );
        }
        return null;
      },
    },
    {
      dataField: 'confirmation',
      text: intl({ id: 'GENERAL.USED_DATE' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap text-center',
      classes: 'text-nowrap text-center pr-6',
      headerStyle: { width: '15%' },
      formatter: (cell, row) => row.confirmation_date && UIDates.formatDateTime(row.confirmation_date)
    },
    {
      dataField: 'action',
      text: "...",
      headerStyle: { minWidth: '40px' },
      headerClasses: 'font-size-h3 text-right pt-0 pr-4',
      classes: 'text-right text-nowrap',
      formatter: columnFormatters.ActionFormatter,
      formatExtraData: {
        openSendMailKeyDialog: UIProps.openSendMailKeyDialog,
        openReserveDialog: UIProps.openReserveDialog
      },
    },
  ];


  return (
    <>
      <BootstrapTable
        keyField="id"
        key={UIProps.ids}
        data={entities || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        bordered={false}
        striped
        condensed
        defaultSorted={uiHelpers.defaultSorted}
        noDataIndication={<NoRecordsFoundMessage loading={loading} onClick={() => { setFilterQuery('DELETE_PARAMS', uiHelpers.allQueryKeys); changeDateRange() }} />}
        selectRow={getSelectRowKeys({
          ids: UIProps.ids,
          setIds: UIProps.setIds,
          entities: entities,
        })}
      />
      <RemotePagination
        queryParams={queryParams.pagination}
        setQueryParams={setPaginationQuery}
        totalCount={countDigitalProduct}
        entitiesCount={entities?.length}
        loading={loading}
      />
    </>
  );
}