import React from 'react';
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import { CardItems } from '_metronic/_partials/components';
import { UserInfoDropdown } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function BankAndTaxInfoCard({ companyBaseData }) {

  const BANK_INFO = [
    {
      label: 'BANK.NAME',
      content: companyBaseData.bank?.name,
    },
    {
      label: 'BANK.BIC',
      content: companyBaseData.bank?.bic,
    },
    {
      label: 'BANK.IBAN',
      content: companyBaseData.bank?.iban,
    },
    {
      label: 'GENERAL.US_NUMBER',
      content: companyBaseData?.vat_id,
    },
    {
      label: 'GENERAL.TAX_NUMBER',
      content: companyBaseData?.tax_number,
    },
  ]


  return (
    <Card className="gutter-b">

      <CardSubHeader
        icon={<Icon.Bank />}
        title={<FormattedMessage id="COMPANY.BANK_TITLE" />}
        info={<UserInfoDropdown
          title={<FormattedMessage id='COMPANY.BANK_TITLE' />}
          description={<FormattedMessage id='USER_GUIDE.SETTINGS.COMPANY_BANK_TAX' />} />}
      />

      <CardBody>
        <CardItems Content={BANK_INFO} />
      </CardBody>

    </Card>
  );
}
