import React, { useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { sortCaret, headerSortingClasses, addressType, } from '_metronic/_helpers';
import { useSupplierUIContext } from '../../_context/SupplierUIContext';
import { ActionsColumnFormatter } from './ActionsColumnFormatter'
import { BlankMessage } from '_metronic/_partials/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { getCountryList } from '_metronic/_helpers';
import { useLang } from '_metronic/i18n';
import { Icon } from '_metronic/_icons';



export function AddressTable() {

  const locale = useLang();
  const { formatMessage: intl } = useIntl()

  const UIContext = useSupplierUIContext();
  const UIProps = useMemo(() => ({
    openEditAddressDialog: UIContext.openEditAddressDialog,
    openDeleteAddressDialog: UIContext.openDeleteAddressDialog,
    address: UIContext.address,
  }), [
    UIContext.openDeleteAddressDialog,
    UIContext.openEditAddressDialog,
    UIContext.address,
  ]);


  const COLUMNS = [
    {
      dataField: 'type',
      text: intl({ id: 'ADDRESS.TABLE.TITLE_TYPE' }),
      sort: true,
      sortCaret,
      classes: 'pl-9',
      headerClasses: 'text-nowrap pl-9',
      headerSortingClasses,
      formatter: (cell, row) => (addressType.find(item => item.value === cell)?.label ?? <FormattedMessage id="GENERAL.ADDRESS" />),
    },
    {
      dataField: 'name',
      text: intl({ id: 'GENERAL.NAME' }),
      headerStyle: { minWidth: '120px' },
      formatter: (cell, row) => (
        <div className="d-flex flex-column">
          <div className='font-weight-bold'>{cell}</div>
          <div>{row.company}</div>
        </div>
      )
    },
    {
      dataField: 'address_line1',
      text: intl({ id: 'ADDRESS.TABLE.TITLE_STR' }),
      headerStyle: { minWidth: '120px' },
      formatter: (cell, row) => (<>{cell} {row.address_line2 && row.address_line2}</>),
    },
    {
      dataField: 'country_code',
      text: intl({ id: 'ADDRESS.TABLE.COUNTRY' }),
      formatter: (cell, row) => (
        <div className='d-flex flex-column'>
          <span className='font-weight-bold'>{row.post_code} {row.city}</span>
          <span>{getCountryList(locale).find(item => item.value === cell)?.label}</span>
        </div>
      ),
    },
    {
      dataField: 'action',
      text: intl({ id: 'GENERAL.ACTIONS' }),
      classes: 'text-right pr-9',
      headerClasses: 'text-right pr-9',
      headerStyle: { minWidth: '100px' },
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditAddressDialog: UIProps.openEditAddressDialog,
        openDeleteAddressDialog: UIProps.openDeleteAddressDialog,
      },
    },
  ];


  return (
    UIProps.address?.length === 0
      ? <BlankMessage
        icon={<Icon.GeoAlt />}
        title={<FormattedMessage id='CUSTOMER.ADDRESS.BLANK_MESSAGE_TITLE' />}
        message={<FormattedMessage id='CUSTOMER.ADDRESS.BLANK_MESSAGE' />}
        arrow
      />
      : <BootstrapTable
        keyField="id"
        data={UIProps.address || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        headerClasses="bg-info-o-10"
        classes="table table-head-custom table-vertical-center overflow-hidden mb-0"
        bordered={false}
        bootstrap4
        condensed
      />
  );
}
