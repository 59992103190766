import React from 'react';
import { Card, CardSubHeader, CardBody } from '_metronic/_partials/controls';
import { FormattedMessage } from 'react-intl';
import { GeneralForm } from './GeneralForm';
import { Icon } from '_metronic/_icons';



export function GeneralCard({ loading }) {

  return (
    <Card className="gutter-b">

      <CardSubHeader icon={<Icon.Tag />} title={<FormattedMessage id="GENERAL.GENERAL" />} />

      <CardBody>
        <GeneralForm loading={loading} />
      </CardBody>

    </Card>
  );
}