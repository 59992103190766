import React, { useContext, createContext, useState, useCallback } from "react";
import { useSearchQuery } from "app/hooks";


const DocumentLocalUIContext = createContext();
export const useDocumentLocalUIContext = () => useContext(DocumentLocalUIContext)



export function DocumentLocalUIContextProvider({ children }) {

  // query params
  const [queryParams, setQueryBase] = useState({
    pagination: {
      page: 1,
      limit: 10,
    },
    order: 'order_date DESC',
    search: '',
    filter: {},
  });

  const setQueryParams = useCallback((params) => {
    setQueryBase(params)
  }, [])

  const { setSearchQuery, setFilterQuery, setPaginationQuery, setOrderQuery } = useSearchQuery(setQueryParams);

  const value = {
    queryParams,
    setSearchQuery,
    setFilterQuery,
    setPaginationQuery,
    setOrderQuery,
  }

  return (
    <DocumentLocalUIContext.Provider value={value}>
      {children}
    </DocumentLocalUIContext.Provider>
  )
}