import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { importExportActions } from '../../_redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { validator } from './ImportProductValidators';
import * as columnFormatters from './ImportProductColumnFormatters';
import { IS_VARIANT_OPTIONS, TAX_OPTIONS, UNIT_OPTIONS } from 'app/modules/products/_context/ProductsUIHelpers';
import { getCurrencyList } from '_metronic/_helpers/constant/optionData';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLang } from '_metronic/i18n';
import { Icon } from '_metronic/_icons';



export function ImportProductsTable() {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();
  const locale = useLang();


  const { importPreparation, checkData } = useSelector((state) => ({
    importPreparation: state.importExport.importPreparation,
    checkData: state.importExport.checkData
  }));

  const tableRowHandler = (oldValue, newValue, row, column) => {

    const updatedRow = {
      ...row,
      [column.dataField]: newValue,
    };

    dispatch(importExportActions.importPreparationUpdate(updatedRow));
  };


  const unitOptions = UNIT_OPTIONS.map(item => ({
    value: item.value,
    label: intl({ id: item.label })
  }));

  const currencyOptions = getCurrencyList(locale);

  const editorStyle = () => ({ height: "32px", padding: "0.5rem 0.75rem" })

  const COLUMNS = [
    {
      dataField: '#',
      text: '#',
      headerStyle: { minWidth: '50px', width: '3%' },
      headerClasses: 'text-center',
      classes: 'font-weight-bold text-center',
      editorStyle,
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      editable: false,
    },
    {
      dataField: 'sku',
      text: intl({ id: "IMPORT.PRODUCT.SKU" }),
      headerFormatter: columnFormatters.HeaderFormatterRequired,
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'font-weight-bold',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: (cell, row) => columnFormatters.SkuFormatter(cell, row, checkData),
      validator: (cell, row) => validator.skuValidator(cell, row, checkData, intl),
    },
    {
      dataField: 'name',
      text: intl({ id: "IMPORT.PRODUCT.NAME" }),
      headerFormatter: columnFormatters.HeaderFormatterRequired,
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.NameFormatter,
      validator: (cell) => validator.nameValidator(cell, intl),
    },
    {
      dataField: 'isVariant',
      text: intl({ id: "IMPORT.PRODUCT.IS_VARIANT" }),
      headerFormatter: columnFormatters.HeaderFormatterRequired,
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.IsVariantFormatter,
      validator: (cell) => validator.isVariantValidator(cell, intl),
      editor: { type: Type.SELECT, options: IS_VARIANT_OPTIONS },
    },
    {
      dataField: 'variantSku',
      text: intl({ id: "IMPORT.PRODUCT.VARIANT_SKU" }),
      headerFormatter: columnFormatters.HeaderFormatterRequired,
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.VariantSkuFormatter,
      validator: (cell, row) => validator.variantSkuValidator(cell, row, intl),
    },
    {
      dataField: 'variantName',
      text: intl({ id: "IMPORT.PRODUCT.VARIANT_NAME" }),
      headerFormatter: columnFormatters.HeaderFormatterRequired,
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.VariantNameFormatter,
      validator: (cell, row) => validator.variantNameValidator(cell, row, intl),
    },
    {
      dataField: 'variantValue',
      text: intl({ id: "IMPORT.PRODUCT.VARIANT_VALUE" }),
      headerFormatter: columnFormatters.HeaderFormatterRequired,
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.VariantValueFormatter,
      validator: (cell, row) => validator.variantValueValidator(cell, row, intl),
    },
    {
      dataField: 'brand',
      text: intl({ id: "PRODUCT.MORE_SETTINGS.BRAND" }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.BrandFormatter,
      validator: (cell) => validator.brandValidator(cell, intl),
    },
    {
      dataField: 'quantity',
      text: intl({ id: "IMPORT.PRODUCT.QUANTITY" }),
      headerFormatter: columnFormatters.HeaderFormatterRequired,
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.QuantityFormatter,
      validator: (cell) => validator.quantityValidator(cell, intl),
    },
    {
      dataField: 'purchase_price',
      text: intl({ id: "PRODUCT.GENERAL.PURCHASE" }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.PurchasePriceFormatter,
      validator: (cell) => validator.purchasePriceValidator(cell, intl),
    },
    {
      dataField: 'gross_price',
      text: intl({ id: "PRODUCT.TABLE.PRICE_GROSS" }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.GrossPriceFormatter,
      validator: (cell) => validator.grossPriceValidator(cell, intl),
    },
    {
      dataField: 'currency',
      text: intl({ id: "PRODUCT.GENERAL.CURRENCY" }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.CurrencyFormatter,
      validator: (cell) => validator.currencyValidator(cell, intl),
      editor: { type: Type.SELECT, options: currencyOptions },
    },
    {
      dataField: 'tax',
      text: intl({ id: "IMPORT.PRODUCT.TAX" }),
      headerFormatter: columnFormatters.HeaderFormatterRequired,
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.TaxFormatter,
      validator: (cell) => validator.taxValidator(cell, intl),
      editor: {
        type: Type.SELECT, options: TAX_OPTIONS.map(item => ({
          value: item.value,
          label: intl({ id: item.label })
        }))
      },
    },
    {
      dataField: 'ean',
      text: intl({ id: "PRODUCT.EAN" }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.EanFormatter,
      validator: (cell) => validator.eanValidator(cell, intl),
    },
    {
      dataField: 'asin',
      text: intl({ id: "PRODUCT.ASIN" }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.AsinFormatter,
      validator: (cell) => validator.asinValidator(cell, intl),
    },
    {
      dataField: 'barcode',
      text: intl({ id: "PRODUCT.MORE_SETTINGS.BARCODE" }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.BarcodeFormatter,
      validator: (cell) => validator.barcodeValidator(cell, intl),
    },
    {
      dataField: 'isbn',
      text: intl({ id: "PRODUCT.MORE_SETTINGS.ISBN" }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.IsbnFormatter,
      validator: (cell) => validator.isbnValidator(cell, intl),
    },
    {
      dataField: 'gtin',
      text: intl({ id: "PRODUCT.MORE_SETTINGS.GTIN" }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.GtinFormatter,
      validator: (cell) => validator.gtinValidator(cell, intl),
    },
    {
      dataField: 'mpn',
      text: intl({ id: "PRODUCT.MORE_SETTINGS.MPN" }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.MpnFormatter,
      validator: (cell) => validator.mpnValidator(cell, intl),
    },
    {
      dataField: 'upc',
      text: intl({ id: "PRODUCT.MORE_SETTINGS.UPC" }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.UpcFormatter,
      validator: (cell) => validator.upcValidator(cell, intl),
    },
    {
      dataField: 'weight',
      text: intl({ id: "GENERAL.WEIGHT" }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.WeightFormatter,
      validator: (cell) => validator.weightValidator(cell, intl),
    },
    {
      dataField: 'width',
      text: intl({ id: "PRODUCT.MORE_SETTINGS.WIDTH" }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.WidthFormatter,
      validator: (cell) => validator.widthValidator(cell, intl),
    },
    {
      dataField: 'height',
      text: intl({ id: "PRODUCT.MORE_SETTINGS.HEIGHT" }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.HeightFormatter,
      validator: (cell) => validator.heightValidator(cell, intl),
    },
    {
      dataField: 'length',
      text: intl({ id: "PRODUCT.MORE_SETTINGS.DEPTH" }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.LengthFormatter,
      validator: (cell) => validator.lengthValidator(cell, intl),
    },
    {
      dataField: 'description',
      text: intl({ id: "GENERAL.DESCRIPTION" }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.DescriptionFormatter,
      validator: (cell) => validator.descriptionValidator(cell, intl),
    },
    {
      dataField: 'path',
      text: intl({ id: "CATEGORY.TITLE" }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.CategoryPathFormatter,
      validator: (cell) => validator.categoryPathValidator(cell, intl),
    },
    {
      dataField: 'unit',
      text: intl({ id: "PRODUCT.GENERAL.UNIT" }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.UnitFormatter,
      validator: (cell) => validator.unitValidator(cell, intl),
      editor: { type: Type.SELECT, options: unitOptions },
    },
    {
      dataField: 'img1',
      text: intl({ id: "IMPORT.PRODUCT.PICTURE_NUMBER" }, { number: '1' }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.Img1Formatter,
      validator: (cell) => validator.imgValidator(cell, intl),
    },
    {
      dataField: 'img2',
      text: intl({ id: "IMPORT.PRODUCT.PICTURE_NUMBER" }, { number: '2' }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.Img2Formatter,
      validator: (cell) => validator.imgValidator(cell, intl),
    },
    {
      dataField: 'img3',
      text: intl({ id: "IMPORT.PRODUCT.PICTURE_NUMBER" }, { number: '3' }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.Img3Formatter,
      validator: (cell) => validator.imgValidator(cell, intl),
    },
    {
      dataField: 'img4',
      text: intl({ id: "IMPORT.PRODUCT.PICTURE_NUMBER" }, { number: '4' }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.Img4Formatter,
      validator: (cell) => validator.imgValidator(cell, intl),
    },
    {
      dataField: 'img5',
      text: intl({ id: "IMPORT.PRODUCT.PICTURE_NUMBER" }, { number: '5' }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.Img5Formatter,
      validator: (cell) => validator.imgValidator(cell, intl),
    },
    {
      dataField: 'img6',
      text: intl({ id: "IMPORT.PRODUCT.PICTURE_NUMBER" }, { number: '6' }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.Img6Formatter,
      validator: (cell) => validator.imgValidator(cell, intl),
    },
    {
      dataField: 'img7',
      text: intl({ id: "IMPORT.PRODUCT.PICTURE_NUMBER" }, { number: '7' }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      formatter: columnFormatters.Img7Formatter,
      validator: (cell) => validator.imgValidator(cell, intl),
    },
    {
      dataField: 'img8',
      text: intl({ id: "IMPORT.PRODUCT.PICTURE_NUMBER" }, { number: '8' }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.Img8Formatter,
      validator: (cell) => validator.imgValidator(cell, intl),
    },
    {
      dataField: 'img9',
      text: intl({ id: "IMPORT.PRODUCT.PICTURE_NUMBER" }, { number: '9' }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.Img9Formatter,
      validator: (cell) => validator.imgValidator(cell, intl),
    },
    {
      dataField: 'img10',
      text: intl({ id: "IMPORT.PRODUCT.PICTURE_NUMBER" }, { number: '10' }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.Img10Formatter,
      validator: (cell) => validator.imgValidator(cell, intl),
    },
    {
      dataField: 'features1',
      text: intl({ id: "IMPORT.PRODUCT.FEATURES" }, { number: '1' }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.Features1Formatter,
      validator: (cell) => validator.featuresValidator(cell, intl),
    },
    {
      dataField: 'features2',
      text: intl({ id: "IMPORT.PRODUCT.FEATURES" }, { number: '2' }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.Features2Formatter,
      validator: (cell) => validator.featuresValidator(cell, intl),
    },
    {
      dataField: 'features3',
      text: intl({ id: "IMPORT.PRODUCT.FEATURES" }, { number: '3' }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      formatter: columnFormatters.Features3Formatter,
      validator: (cell) => validator.featuresValidator(cell, intl),
    },
    {
      dataField: 'features4',
      text: intl({ id: "IMPORT.PRODUCT.FEATURES" }, { number: '4' }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.Features4Formatter,
      validator: (cell) => validator.featuresValidator(cell, intl),
    },
    {
      dataField: 'features5',
      text: intl({ id: "IMPORT.PRODUCT.FEATURES" }, { number: '5' }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.Features5Formatter,
      validator: (cell) => validator.featuresValidator(cell, intl),
    },
    {
      dataField: 'features6',
      text: intl({ id: "IMPORT.PRODUCT.FEATURES" }, { number: '6' }),
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.Features6Formatter,
      validator: (cell) => validator.featuresValidator,
    },
    {
      dataField: 'action',
      text: intl({ id: 'GENERAL.ACTIONS' }),
      formatter: (cell, row) => (
        <OverlayTrigger overlay={<Tooltip id="products-delete-tooltip"><FormattedMessage id="GENERAL.DELETE" /></Tooltip>}>
          <button className="btn btn-icon btn-hover-danger btn-sm" onClick={() => dispatch(importExportActions.importPreparationDelete(row.id))} id={`btn_delete_${row.id}`}>
            <span className="svg-icon svg-icon-md">
              <Icon.Trash />
            </span>
          </button>
        </OverlayTrigger>
      ),
      classes: 'text-center pr-0',
      headerClasses: 'text-center pr-3',
      headerStyle: { minWidth: '110px' },
    },
  ];


  return (
    <BootstrapTable
      keyField="id"
      data={importPreparation || []}
      columns={COLUMNS}
      wrapperClasses="table-responsive"
      classes="table table-head-custom table-vertical-center"
      bootstrap4
      striped
      condensed
      cellEdit={cellEditFactory({
        mode: 'dbclick',
        blurToSave: true,
        autoSelectText: true,
        afterSaveCell: tableRowHandler,
      })}
    />
  );
}
