import React from 'react'
import { Field } from 'formik';
import { SVFormControl } from '_metronic/_partials/controls';
import { Col, Row } from "react-bootstrap";
import { useIntl } from 'react-intl';



const INPUTS = [
  {
    name: "bank.name",
    label: "BANK.NAME",
  },
  {
    name: "bank.iban",
    label: "BANK.IBAN",
  },
  {
    name: "bank.bic",
    label: "BANK.BIC",
  },
];



export function BankForm({ loading }) {
  const { formatMessage: intl } = useIntl();
  return (
    <Row>
      {INPUTS.map((item, i) => (
        <Col lg="4" md="6" sm="12" key={i}>
          <Field component={SVFormControl}
            {...{
              name: item.name,
              label: intl({ id: item.label }),
              withFeedbackLabel: false,
              feedbackLabel: true,
              disabled: loading || item.disabled,
            }}
          />
        </Col>
      ))}
    </Row>
  )
}
