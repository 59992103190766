import React, { useEffect, useMemo, useState } from 'react';
import { useProductListingsUIContext } from '../../_context/ProductListingsUIContext';
import { SearchControl } from '_metronic/_partials/controls';
import { KauflandListingsGrouping } from './KauflandListingsGrouping';
import { SelectCounter } from '_metronic/_partials';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from 'app/hooks';



export function KauflandListingsFilter({ queryParams, setSearchQuery }) {

  const query = useQuery();

  const UIContext = useProductListingsUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    market_id: UIContext.market_id,
  }),
    [UIContext.ids, UIContext.market_id,]
  );

  const [search, setSearchBase] = useState(query.get('search') ?? '');

  useEffect(() => {
    setSearchBase(query.get('search') ?? '');
  }, [query]);

  const handleSearch = (value) => {
    setSearchQuery(value);
    setSearchBase(value);
  };


  return (
    <Row className='mb-3'>

      <Col md="4" className="mb-lg-0 mb-2">
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col>

      <Col md="8" className='d-flex align-items-start px-md-0'>
        <SelectCounter count={UIProps.ids.length} />

        <KauflandListingsGrouping UIProps={UIProps} queryParams={queryParams} />
      </Col>
    </Row>
  );
}
