import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Card, CardBody } from "_metronic/_partials/controls";
import { calculatePriceDiffPercentage } from '_metronic/_helpers';
import ShowHideButton from '../components/ShowHideButton';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { useToggleDisplayCard } from "app/hooks";
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import CountUp from 'react-countup'



export function CounterPriceCard({ data, title, dateRange, marketplace, currencySymbol }) {

  const cardList = [
    {
      title: "DASHBOARD.ORDERS",
      icon: <Icon.BagCheck className="card-background text-light" />,
      className: "card-blue text-light text-hover-dark-75",
      toggle: "orders",
      link: `${ModuleRoutes.DOCUMENT_ORDER}?page=1${marketplace ? `&filter.marketplace=${marketplace}` : ""}&filter.status=open&filter.date_from=${dateRange.date_from}&filter.date_to=${dateRange.date_to}&filter.doc_type=orders`,
    },
    {
      title: "DASHBOARD.SALES",
      icon: <Icon.ArrowRepeat className="card-background text-light" />,
      className: "card-yellow text-dark-75 text-hover-light",
      toggle: "sales",
      link: `${ModuleRoutes.DOCUMENT_INVOICE}?page=1${marketplace ? `&filter.marketplace=${marketplace}` : ""}&filter.date_from=${dateRange.date_from}&filter.date_to=${dateRange.date_to}&filter.doc_type=invoices`,
    },
    {
      title: "DASHBOARD.OPEN_INVOICES",
      icon: <Icon.ExclamationCircle className="card-background text-light" />,
      className: "card-red text-light text-hover-dark-75",
      toggle: "open_invoices",
      link: `${ModuleRoutes.DOCUMENT_INVOICE}?page=1${marketplace ? `&filter.marketplace=${marketplace}` : ""}&filter.date_from=${dateRange.date_from}&filter.date_to=${dateRange.date_to}&filter.doc_type=invoices&filter.open_invoice=true`,
    },
  ]

  const cardType = cardList.find(item => item.title === title)

  const [toggleProperty, dispatchProperty] = useToggleDisplayCard(cardType.toggle);
  const percentageDiff = calculatePriceDiffPercentage(data?.previous?.total_gross, data?.total_gross);


  return (
    <Card className={`${cardType.className} card-stretch gutter-b`}>
      <CardBody>

        <Row className="d-flex justify-space-between">

          <Col xs="10">
            {toggleProperty ?
              <div className="d-flex flex-column">
                <Link to={cardType.link} style={{ color: "inherit" }} id={`btn_filtered_${cardType.toggle}`}>

                  <div className='d-flex align-items-start display-3'>
                    <CountUp end={+data?.total_gross} delay={1} duration={5} separator="." />
                    <span className='opacity-50 display-4 pt-1'>
                      {+data?.total_gross > 0 && currencySymbol}
                    </span>
                  </div>

                  <div className='fs-4'>
                    <FormattedMessage id={title} /> {data?.count}
                  </div>

                  <div className="font-weight-bolder text-nowrap mt-1">
                    {percentageDiff > 0 ? <Icon.GraphUpArrow className="mr-2" /> : <Icon.GraphDownArrow className="mr-2" />}
                    {percentageDiff}%
                  </div>
                </Link>

              </div>
              : <div className='fs-4'>
                <FormattedMessage id={title} />
              </div>
            }
          </Col>

          <Col xs="2" className="d-flex justify-content-end">
            {cardType.icon}
            <ShowHideButton toggleProperty={toggleProperty} dispatchProperty={dispatchProperty} id={cardType.toggle} className="text-light" />
          </Col>

        </Row>

      </CardBody>
    </Card>
  )
}
