
export const support = {
    main: {
        title: "MENU.ASIDE.SUPPORT",
        location: 'support',
        description: "USER_GUIDE.SUPPORT"
    },
    new: {
        title: "COMPANY.EDIT.TITLE",
        description: "USER_GUIDE.SUPPORT.EDIT"
    }
}