/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as UIHelpersDocuments from 'app/modules/documents/_context/DocumentsUIHelpers';
import { getConnection } from 'constants/connectionSettings';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import clsx from 'clsx';



export function ActionFormatter(cellContent, row, rowIndex, { openPrintLabelsDialog, openDeleteLabelDialog }) {
  return (
    <>
      {!row?.unlabeled &&
        <OverlayTrigger overlay={<Tooltip id="shipping-print-tooltip"><FormattedMessage id="GENERAL.PRINT" /></Tooltip>}>
          <a className="btn btn-icon btn-hover-info btn-sm" onClick={() => openPrintLabelsDialog(row)} id={`btn_print_${row?.id}`}>
            <span className="svg-icon svg-icon-md">
              <Icon.Printer />
            </span>
          </a>
        </OverlayTrigger>}

      <OverlayTrigger overlay={<Tooltip id="shipping-delete-tooltip"><FormattedMessage id="DOCUMENT.SHIPPING.DELETE" /></Tooltip>}>
        <a className="btn btn-icon btn-hover-danger btn-sm" onClick={() => openDeleteLabelDialog({ id: row?.id, service: row?.service, relation: row?.relation_documents })} id={`btn_delete_${row?.id}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.Trash />
          </span>
        </a>
      </OverlayTrigger>
    </>
  )
}



export function LabelFormatter(cellContent, row, rowIndex, { openPrintLabelsDialog }) {

  const getService = UIHelpersDocuments.getShipperName(row.service)
  const icon = getConnection(row?.service)?.icon

  return (
    <div className="d-flex align-items-center">

      <OverlayTrigger align="auto" overlay={<Tooltip id="shipping-tooltip">
        <div className='font-weight-bolder border-bottom'>
          {getService}
        </div>
        <div className={clsx('font-weight-bold mt-2', row?.error && 'text-danger')}>
          {row?.error ? row?.error : row?.tracking_id}
        </div>
      </Tooltip>}>

        <div type="button" className="d-flex mr-4" style={{ fontSize: "3rem" }} onClick={() => !row?.unlabeled && openPrintLabelsDialog(row)}>
          {row?.unlabeled ? <Icon.SupplierFIll /> : row?.error ? <Icon.ErrorFill /> : icon}
        </div>

      </OverlayTrigger>

      <div>
        <div className="font-weight-bolder font-size-lg text-dark-75">
          {getService}
        </div>
        <div className="font-weight-bold text-dark-50">
          {row.tracking_id}
        </div>
      </div>

    </div>
  );
}