import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ModuleRoutes, RELATIVE_PATH } from 'constants/moduleRoutes';
import { SettingsUIProvider } from './_context/SettingsEditContext';
import { ConnectionSettingsPage } from './connection-edit/ConnectionSettings';
import { ConnectionPage } from './ConnectionPage';
import { ContentRoute } from '_metronic/layout';
import QueryCapture from './QueryCapture';



export default function Connections() {
  return (
    <SettingsUIProvider>

      <QueryCapture />

      <Switch>
        <Redirect exact={true} from={RELATIVE_PATH} to={ModuleRoutes.CONNECTIONS} />
        <ContentRoute path={ModuleRoutes.CONNECTIONS_SETTINGS} component={ConnectionSettingsPage} />
        <ContentRoute path={ModuleRoutes.CONNECTIONS} component={ConnectionPage} />
      </Switch>

    </SettingsUIProvider>
  );
}