import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { DocumentEdit } from './document-edit/DocumentEdit';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { DocumentsPage } from './DocumentsPage';
import { ContentRoute } from '_metronic/layout';



export default function Document({ name }) {
  return (
    <Switch>

      <Redirect exact={true} from={ModuleRoutes.DOCUMENT} to={ModuleRoutes.DOCUMENT_NEW_FN(name)} />

      <ContentRoute path={ModuleRoutes.DOCUMENT_NEW_FN(name)} component={DocumentEdit} />
      <ContentRoute path={ModuleRoutes.DOCUMENT_COPY_FN(name)} component={DocumentEdit} />
      <ContentRoute path={ModuleRoutes.DOCUMENT_EDIT_FN(name)} component={DocumentEdit} />
      <ContentRoute path={ModuleRoutes.DOCUMENT_CONVERT_FN(name)} component={DocumentEdit} />

      <ContentRoute path={ModuleRoutes.DOCUMENT_NAME_FN(name)}>
        {() => <DocumentsPage moduleName={name} />}
      </ContentRoute>

    </Switch>
  );
}
