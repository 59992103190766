import React, { useEffect, useMemo, useState } from 'react';
import { DatePickerDropdown } from '../../../../_metronic/_partials/components/DatePickerDropdown';
import { SearchControl } from '_metronic/_partials/controls';
import { useProductsUIContext } from '../_context/ProductsUIContext';
import { ProductsGrouping } from './ProductsGrouping';
import { Dropdown, Col, Row } from 'react-bootstrap';
import { SelectCounter } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl';
import { theme } from '_metronic/_helpers';
import { Icon } from '_metronic/_icons';
import { useQuery } from 'app/hooks';



export function ProductsFilter({ queryParams, setSearchQuery, setFilterQuery }) {

  const query = useQuery();
  const [search, setSearchBase] = useState(query.get('search') ?? '');

  /* Filter */
  const [filterValue, setFilterValue] = useState({
    published: queryParams?.filter?.published ? true : false,
    unpublished: queryParams?.filter?.unpublished ? true : false,
    low_stock: queryParams?.filter?.low_stock ? true : false,
  });

  const productsUIContext = useProductsUIContext();
  const UIProps = useMemo(() => ({
    ids: productsUIContext.ids,
    setIds: productsUIContext.setIds,
    openExportProductDialog: productsUIContext.openExportProductDialog,
  }), [productsUIContext]);

  const handleFilterChange = (e) => {

    const { name, checked } = e.target;

    setFilterValue((prev) => {
      const updatedFilterValue = { ...prev, [name]: checked };
      if (name === "published" && checked) {
        updatedFilterValue.unpublished = false;
      } else if (name === "unpublished" && checked) {
        updatedFilterValue.published = false;
      }
      return updatedFilterValue;
    });

    setFilterQuery(name, checked);

  };

  const resetFilters = () => {
    setFilterValue({
      published: false,
      unpublished: false,
      low_stock: false,
    });

    // Tüm filtreleri kaldır
    setFilterQuery("DELETE_PARAMS", ["published", "unpublished", "low_stock"]);
  };

  useEffect(() => {
    setSearchBase(query.get('search') ?? '');
  }, [query]);

  const handleSearch = (value) => {
    setSearchQuery(value);
    setSearchBase(value);
  };


  return (
    <Row className='mb-3'>

      <Col sm="12" md="6" lg="4" className="mb-lg-0 mb-2">
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col>

      <Col sm="12" md="6" lg="8" className='d-flex align-items-start px-md-0'>

        <Dropdown className="dropdown-inline symbol mr-3" drop="down">
          <Dropdown.Toggle variant='outline-secondary' className="svg-icon svg-icon-sm font-weight-bold" style={{ height: theme.units.input.height['sm'] }} id="dropdown-basic">
            <Icon.Sliders className="mr-3" />
            <FormattedMessage id='DOCUMENT.FILTER.CONTENT' />
          </Dropdown.Toggle>

          {Object.keys(filterValue)?.some(key => filterValue[key] === true) && <i className="symbol-badge bg-danger"></i>}
          <Dropdown.Menu className="dropdown-menu-right dropdown-menu-anim-down" style={{ minWidth: '180px' }} id="dropdown_document_filter">
            <ul className="navi navi-hover font-weight-bold">

              <li className="navi-item">
                <label className="navi-link checkbox checkbox-md checkbox-outline checkbox-primary">
                  <input type="checkbox" name="published" checked={filterValue.published} onChange={handleFilterChange} />
                  <span />
                  <div className="text-nowrap">
                    <FormattedMessage id="PRODUCT.FILTERS.PUBLISHED" />
                  </div>
                </label>
              </li>

              <li className="navi-item">
                <label className="navi-link checkbox checkbox-md checkbox-outline checkbox-primary">
                  <input type="checkbox" name="unpublished" checked={filterValue.unpublished} onChange={handleFilterChange} />
                  <span />
                  <div className="text-nowrap">
                    <FormattedMessage id="PRODUCT.FILTERS.UNPUBLISHED" />
                  </div>
                </label>
              </li>

              <li className="navi-item">
                <label className="navi-link checkbox checkbox-md checkbox-outline checkbox-primary">
                  <input type="checkbox" name="low_stock" checked={filterValue.low_stock} onChange={handleFilterChange} />
                  <span />
                  <div className="text-nowrap">
                    <FormattedMessage id="PRODUCT.FILTERS.LOW_STOCK" />
                  </div>
                </label>
              </li>

              <Dropdown.Divider />

              <li className="navi-item">
                <Dropdown.Item className='navi-link font-weight-bolder text-danger justify-content-center bg-white py-1' id='clear'>
                  <span onClick={resetFilters}>
                    <FormattedMessage id='GENERAL.CLEAR' />
                  </span>
                </Dropdown.Item>
              </li>
            </ul>
          </Dropdown.Menu>
        </Dropdown>

        <DatePickerDropdown UIContext={productsUIContext} setFilterQuery={setFilterQuery} />
        
        <SelectCounter count={UIProps.ids.length} />

        <ProductsGrouping UIProps={UIProps} />

      </Col>
    </Row>
  );
}
