import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ModuleRoutes, RELATIVE_PATH } from 'constants/moduleRoutes';
import { DigitalProductsUIProvider } from './_context/DigitalProductsUIContext';
import DigitalProductsPage from './DigitalProductsPage';
import { ContentRoute } from '_metronic/layout';



export default function DigitalProducts() {
  return (
    <DigitalProductsUIProvider>

      <Switch>
        <Redirect exact={true} from={RELATIVE_PATH} to={ModuleRoutes.DIGITAL_PRODUCT} />
        <ContentRoute path={ModuleRoutes.DIGITAL_PRODUCT} component={DigitalProductsPage} />
      </Switch>

    </DigitalProductsUIProvider>
  );
}