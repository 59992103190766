import React from 'react';
import { Formik, Form } from 'formik';
import { bicRegExp, nameRegExp, vatRegExp, taxRegExp, phoneRegExp, maxValidation, minValidation, emailRegExp, ibanRegExp } from '_metronic/_helpers';
import { DirtyDialog } from '_metronic/_partials/dialogs';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';



export function FormikStepper({ initialValues, btnRef, saveCustomer, children, resetBtnRef, onClose, success }) {

  const { formatMessage: intl } = useIntl()
  // const typeValidation = <FormattedMessage id="GENERAL.TYPE_ERROR" />

  const customerEditSchema = Yup.object().shape({
    type: Yup.string()
      .max(100, maxValidation("100"))
      .required(intl({ id: "GENERAL.TYPE_REQUIRED" })),
    name: Yup.string()
      .max(100, maxValidation("100"))
      .min(2, minValidation("2"))
      .matches(nameRegExp, intl({ id: 'GENERAL.NAME_MATCHES' }))
      .trim(intl({ id: 'AUTH.GENERAL.BLANK_CHARACTER' }))
      /* .when('firma', {is: firma => firma === undefined,
          then: Yup.string().required('*Name oder Firma sind Pflichtfeld')} )  */
      .required(intl({ id: "GENERAL.NAME_REQUIRED" })),
    email: Yup.string()
      .max(100, maxValidation("100"))
      .matches(emailRegExp, intl({ id: 'GENERAL.EMAIL_EMAIL' }))
      .email(intl({ id: 'GENERAL.EMAIL_EMAIL' }))
      .required(intl({ id: "GENERAL.EMAIL_REQUIRED" })),
    company: Yup.string()
      .when('type', {
        is: 'company',
        then: Yup.string().required(intl({ id: 'GENERAL.COMPANY_REQUIRED' }))
      })
      .max(100, maxValidation("100"))
      .trim(intl({ id: 'AUTH.GENERAL.BLANK_CHARACTER' }))
      .matches(nameRegExp, intl({ id: 'ADDRESS.COMPANY_MATCHES' })),
    phone: Yup.string()
      .max(25, maxValidation("25"))
      .matches(phoneRegExp, intl({ id: 'GENERAL.TEL_MATCHES' }))
      .min(7, minValidation("7")),
    mobile: Yup.string()
      .max(25, maxValidation("25"))
      .matches(phoneRegExp, intl({ id: 'GENERAL.TEL_MATCHES' }))
      .min(7, minValidation("7")),
    fax: Yup.string()
      .max(25, maxValidation("25"))
      .matches(phoneRegExp, intl({ id: 'GENERAL.FAX_MATCHES' }))
      .min(7, minValidation("7")),
    tax_number: Yup.string()
      .when('type', {
        is: 'company',
        then: Yup.string().required(intl({ id: 'GENERAL.TAX_NUMBER_REQUIRED' }))
      })
      .max(15, maxValidation("15"))
      .matches(taxRegExp, intl({ id: 'GENERAL.NUMBER_MATCHES' })),
    vat_id: Yup.string()
      .max(15, maxValidation("15"))
      .matches(vatRegExp, intl({ id: 'GENERAL.VAT_ID_MATCHES' })),
    bank: Yup.object().shape({
      name: Yup.string()
        .max(100, maxValidation("100")),
      iban: Yup.string()
        .max(50, maxValidation("50"))
        .matches(ibanRegExp, intl({ id: 'BANK.IBAN_MATCHES' })),
      bic: Yup.string()
        .max(50, maxValidation("50"))
        .matches(bicRegExp, intl({ id: 'BANK.BIC_MATCHES' })),
    }),
    discount: Yup.number()
      .nullable().notRequired()
      .min(0, intl({ id: 'CUSTOMER.DISCOUNT_MIN_LENGTH' }))
      .max(100, intl({ id: 'CUSTOMER.DISCOUNT_MAX_LENGTH' }))
      .test('is-decimal', intl({ id: 'CUSTOMER.DISCOUNT_MATCHES' }), value => value === null || value === undefined || /^\d+(\.\d{1,2})?$/.test(value)),
    notes: Yup.string()
      .max(1000, maxValidation("1000")),
  });


  return (
    <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={customerEditSchema} onSubmit={saveCustomer}>
      {({ handleSubmit, handleReset }) => (
        <Form autoComplete="off">
          <DirtyDialog onClose={onClose} success={success} />
          {children}
          <button type="button" className='d-none' ref={resetBtnRef} onClick={handleReset} id="btn_reset"></button>
          <button type="submit" className='d-none' ref={btnRef} onSubmit={handleSubmit} id="btn_submit"></button>
        </Form>
      )}
    </Formik>
  );
}
