export const defaultSorted = [{ dataField: "id", order: "desc" }];


export const INITIAL_SUPPLIER = {
  name: '',
  company: '',
  email: '',
  fax: '',
  mobile: '',
  phone: '',
  tax_number: '',
  vat_id: '',
  bank: [],
  address: [],
};
