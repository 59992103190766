import { API } from 'constants/apiUrl';
import { paginationParams, searchParams } from '../_helpers/query-params';
import { searchKeys } from './params';



export const shippingsMiddleware = {


  deleteShipping: async function (accessToken, shippingId) {
    return await API.delete(`/shipping/all/${shippingId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getShippings: async function (accessToken, { pagination, search, filter, order }) {
    return await API.get(`/shipping`, {
      params: {
        filter: {
          ...paginationParams(pagination.page, pagination.limit),
          order: [`${order ? order : 'id DESC'}`],
          where: {
            tracking_id: { neq: "" },
            ...(search && {
              ...searchParams(searchKeys, search),
            }),
            ...(filter && filter)
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getShipping: async function (accessToken, shippingIds, type) {
    return await API.get(`/shipping/${shippingIds}${type ? `?type${type}` : ''}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getCount: async function (accessToken, { search, filter }) {
    return await API.get(`/shipping/count`, {
      params: {
        where: {
          tracking_id: { neq: "" },
          ...(search && {
            ...searchParams(searchKeys, search),
          }),
          ...(filter && filter),
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

};