import React from 'react';
import { useHistory } from 'react-router-dom';
import { Portal } from 'react-portal';
import { Modal } from 'react-bootstrap';
import { ProductEditChildren } from './ProductEditChildren';
import { ProductEditUIProvider } from '../_context/ProductEditUIContext';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { getLastSearch } from '_metronic/_helpers';



export function ProductEdit({ show, onHide, modal, match: { params: { copyId } } }) {

  const history = useHistory();
  const lastSearch = getLastSearch()
  if (show && modal) {
    return (
      <Portal node={document && document.getElementById('modal-portal')}>
        <ProductEditUIProvider>
          <Modal size="xl" show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
            <ProductEditChildren onHide={onHide} copyId={copyId} />
          </Modal>
        </ProductEditUIProvider>
      </Portal>
    );
  } else {
    return (
      <ProductEditUIProvider>
        <ProductEditChildren onHide={() => history.push(ModuleRoutes.PRODUCT + lastSearch)} copyId={copyId} />
      </ProductEditUIProvider>
    );
  }
}
