import React, { useEffect, useState } from 'react';
import ApexChart from 'react-apexcharts';
import { getCountryList } from '_metronic/_helpers';
import ShowHideButton from '../components/ShowHideButton';
import { useToggleDisplayCard } from "app/hooks";
import { useLang } from '_metronic/i18n';
import { Card, CardBody } from '_metronic/_partials/controls';
import { FormattedMessage } from 'react-intl';
import { UserInfoDropdown } from '_metronic/_partials';



const defaultColors = [
  "#EF4236",
  "#15BCD5",
  "#C1CA31",
  "#FFC00E",
  "#3CA5DE",
  "#8DC249",
  "#F9961D",
  "#F0592B",
  "#50B04E",
  "#0A9788",


  // "#EC6C56",
  // "#FD9A47",
  // "#98CF43",
  // "#FDC136",
  // "#4777C0",
  // "#4FB3E8",
  // "#A374C6",
  // "#EA4346",


  // "#C01D2E",
  // "#EA1B24",
  // "#EF5A27",
  // "#FAB143",
  // "#8CC33E",
  // "#01A452",
  // "#016739",
  // "#4D9FFF",
  // "#90288F",
  // "#662D90",
  // "#2F3190",
];



const pie = {
  series: [100],
  options: {
    chart: {
      type: 'donut',
    },
    colors: [
      "#0A9788"
    ],
    labels: [],
    stroke: {
      show: true,
      width: 2,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '45%',
        },
      },
    },
    // fill: {
    //   type: 'gradient',
    // },
    legend: {
      show: true,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: 'right',
          },
        },
      },
    ],
  },
};


function getCountryNames(countryList, countryCode) {
  let result = [];

  for (let i = 0; i < countryCode.length; i++) {
    for (let j = 0; j < countryList.length; j++) {
      if (countryCode[i] === countryList[j].value) {
        result.push(countryList[j].label);
        break;
      }
    }
  }
  return result;
}




export function OrderByMarketplace({ data, title, toggleState }) {

  const locale = useLang();
  const [toggleProperty, dispatchProperty] = useToggleDisplayCard(toggleState)
  const [newPies, setNewPies] = useState(pie);

  useEffect(() => {

    const newColors = [];
    let colorIndex = 0;
    let colorStartIndex = 0;
    for (let index = 0; index < data.series.length; index++) {
      if (colorIndex === 10) {
        colorStartIndex++;
        if (colorStartIndex === 10) {
          if (data.series.length - 1 === index) {
            colorStartIndex = 1;
          } else {
            colorStartIndex = 0;
          }
        }
        colorIndex = colorStartIndex;
      }
      newColors.push(defaultColors[colorIndex]);
      colorIndex++;
    }
    setNewPies(prev => ({
      ...prev,
      series: data.series,
      options: {
        ...prev.options,
        labels: toggleState === "sales_by_country" ? getCountryNames(getCountryList(locale), data.labels) : data.labels,
        colors: newColors,
      },
    }));

  }, [data, locale, toggleState])

  return (
    <Card className='card-stretch gutter-b'>

      <div className="d-flex justify-content-between p-8">
        <div className="d-flex align-items-start flex-column">
          <h4 className="d-flex align-items-center font-weight-bolder text-dark">
            <FormattedMessage id={title} />
            <UserInfoDropdown
              // title={<FormattedMessage id={toggleState === 'sales_by_channel' ? 'DASHBOARD.SALES_BY_CHANNEL' : 'DASHBOARD.SALES_BY_COUNTRY'} />}
              description={<FormattedMessage id={toggleState === 'sales_by_channel' ? 'USER_GUIDE.DASHBOARD.SALES_BY_CHANNEL' : 'USER_GUIDE.DASHBOARD.SALES_BY_COUNTRY'} />}
            />
          </h4>
          <span className="text-muted font-weight-bold font-size-sm">
            <FormattedMessage id={toggleState === "cardSalesByCountryShow" ? "ADDRESS.COUNTRY" : "DOCUMENT.TABLE.COLUMN.MARKETPLACE"} /> : {newPies.series.length}
          </span>
        </div>
        <div className="align-items-start">
          <ShowHideButton toggleProperty={toggleProperty} dispatchProperty={dispatchProperty} id={toggleState} />
        </div>
      </div>

      {!(!toggleProperty || !newPies.series.length > 0) && (
        <CardBody className="pt-0">
          <ApexChart
            className="sparkline-chart"
            type='donut'
            height={200}
            series={newPies.series}
            options={newPies.options}
          />

        </CardBody>
      )}

    </Card>
  );
}
