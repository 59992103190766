import React, { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { ProductActions } from '../../../_redux/actions';
import { useProductEditUIContext } from '../../../_context/ProductEditUIContext';
import { getVariants, Variant, VARIANT_ACTION_TYPES } from '../core/helpers';
import { AddVariantForm } from './AddVariantForm';
import { utils } from '_metronic/_helpers';
import { toast } from 'react-toastify';
import { Portal } from 'react-portal';
import { clone } from 'lodash';


/* const initialFormValues = (variants, variantOptions) => {
  
  const initialValues = {};
  for (const spec of getVariants(variants,variantOptions)) {
    initialValues[spec.name] = '';
  };
  return initialValues;
}; */



export const AddSingleVariantDialog = () => {

  const { formatMessage: intl } = useIntl();

  const productEditUIContext = useProductEditUIContext();
  const { showAddSingleVariantDialog, formValues } = useMemo(() => productEditUIContext.newVariantsReducer, [
    productEditUIContext.newVariantsReducer,
  ]);
  const dispatchNewVariants = useMemo(() => productEditUIContext.dispatchNewVariants, [productEditUIContext.dispatchNewVariants]);

  const closeAddSingleVariantDialog = () => {
    dispatchNewVariants({
      type: VARIANT_ACTION_TYPES.CLOSE_ADD_SINGLE_VARIANT_DIALOG,
    });
  };
  const dispatch = useDispatch();

  const { productVariants, variantOptions } = useSelector(state => ({
    productVariants: state.products.variants,
    variantOptions: state.settings.productSettings.variant_options
  }));

  const onSubmitBtnRef = useRef(null);
  const onSubmitBtnRefClick = () => {
    if (onSubmitBtnRef.current) {
      onSubmitBtnRef.current.click();
    }
  };

  const saveNewVariant = values => {
    const skuValues = [];
    let missingNumber;
    const variantSkuList = productVariants.map(variant => variant.sku);

    //sku_ den sonraki değerleri alıyoruz
    for (let i = 0; i < variantSkuList.length; i++) {
      const item = variantSkuList[i];
      if (item.startsWith(`${formValues.sku}_`)) {
        skuValues.push(item.substring(formValues.sku.length + 1));
      } else {
        skuValues.push('');
      }
    }

    //number olanları al ve sırala
    const numbers = skuValues
      .filter(item => !isNaN(item))
      .map(Number)
      .sort((a, b) => a - b);

    //boş olan ilk değeri bul
    for (let i = 0; i < numbers.length - 1; i++) {
      if (numbers[i + 1] - numbers[i] > 1) {
        missingNumber = numbers[i] + 1;
        break;
      }
    }

    if (missingNumber === undefined) {
      missingNumber = numbers[numbers.length - 1] + 1;
    }
    const newSKU = `${formValues?.sku}_${missingNumber}`;
    //const tax = +formValues?.pricing?.tax || 19;
    const { quantity, gross_price, selectedVariants } = values;

    const sortedVariants = productVariants[0]?.specs.map((variant) => {
      const matchingVariant = selectedVariants.find((selectedVariant) => selectedVariant.name === variant.name);
      return matchingVariant ? matchingVariant : variant;
    });

    const specs = sortedVariants.map((variant, i) => ({
      name: variant.name,
      value: variant.values[0]
    }))

    const isVariantExists = productVariants.some((variant) => {
      const variantSpecs = variant.specs;
      if (variantSpecs.length !== specs.length) {
        return false;
      }
      for (let i = 0; i < variantSpecs.length; i++) {
        const variantSpec = variantSpecs[i];
        const isspec = specs.find((spec) => spec.name === variantSpec.name);

        if (!isspec || isspec.value !== variantSpec.value) {
          return false;
        }
      }
      return true;
    });

    if (isVariantExists) {
      return toast.error(intl({ id: 'SETTINGS.PRODUCT.VARIANT_NAME.EXISTS' }));
    }

    const variants = clone(productVariants);
    const newVariant = new Variant({
      id: utils.uuidv4(),
      sku: newSKU,
      quantity: quantity || 0,
      pricing: {
        purchase_price: 0,
        gross_price: gross_price || 0,
      },
      specs
    });
    variants.push(newVariant);
    dispatch(ProductActions.setVariants(variants));
    closeAddSingleVariantDialog();
  };

  const isVariant = productVariants[0]?.specs.every((variant) =>
    variantOptions.some(i => i.name === variant.name)
  );

  const variantGenerate = () => {
    dispatchNewVariants({ type: VARIANT_ACTION_TYPES.OPEN_GENERATE_VARIANT_DIALOG })
    closeAddSingleVariantDialog();
  };


  return (
    <Portal node={document && document.getElementById('modal-portal')}>
      <Modal show={showAddSingleVariantDialog} onHide={closeAddSingleVariantDialog} size={isVariant ? "md" : "sm"} backdrop="static" keyboard={false} centered>

        {isVariant ?
          <>
            <Modal.Header>
              <Modal.Title>
                <FormattedMessage id='PRODUCT.VARIANT.ATTRIBUTES_SELECT' />
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <AddVariantForm
                //initialValues={initialFormValues(productVariants, variantOptions)}
                currentSpecs={getVariants(productVariants, variantOptions)}
                onSubmit={saveNewVariant}
                submitBtnRef={onSubmitBtnRef}
              />
            </Modal.Body>

            <Modal.Footer>
              <Button variant='light' onClick={closeAddSingleVariantDialog} id="btn_cancel_modal">
                <FormattedMessage id='GENERAL.CANCEL' />
              </Button>
              <Button variant='primary' className="ml-2" onClick={onSubmitBtnRefClick} id="btn_save_modal">
                <FormattedMessage id='GENERAL.SAVE' />
              </Button>
            </Modal.Footer>
          </>
          :
          <>
            <Modal.Body className="line-height-lg text-center mb-0">
              <FormattedMessage id='PRODUCT.ADD_VARIANT.INFO' />
            </Modal.Body>

            <Modal.Footer className="flex-nowrap overflow-hidden p-0">
              <Button variant='ios' className="border-right" onClick={closeAddSingleVariantDialog} id="btn_cancel_modal">
                <FormattedMessage id="GENERAL.CANCEL" />
              </Button>
              <Button variant='ios' className="text-primary" onClick={variantGenerate} id="btn_confirm_modal">
                <FormattedMessage id="GENERAL.CONTINUE" />
              </Button>
            </Modal.Footer>
          </>
        }

      </Modal>
    </Portal>
  );
};
