
export const importInfo = {

    main: {
        title: "IMPORT_EXPORT.IMPORT",
        location: 'import',
        description: "USER_GUIDE.IMPORT_EXPORT.IMPORT",
    },

}

export const exportInfo = {

    main: {
        title: "IMPORT_EXPORT.EXPORT",
        location: 'export',
        description: "USER_GUIDE.IMPORT_EXPORT.EXPORT",
    },

}

