import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { SettingsWarningDialog } from './SettingsDialog';
import { Icon } from '_metronic/_icons';



export function Settings({ user, connections }) {

  const [action, setAction] = useState(false);

  const EbayConnections = connections?.filter(item => item.name === 'ebay');

  const onHandleSubmitTrading = (connection) => {
    setAction({ show: true, type: 'migration', connection });
  };

  const handleCheckbox = (e) => {
    setAction({ show: true, type: 'cron', checked: e.target.checked });
  };

  const INPUTS = [
    {
      label: "Cron",
      info: "ADMIN.SETTINGS.CRONJOB_INFO",
      content: (
        <label className="switch switch-sm switch-icon d-flex align-items-center" key="cron">
          <input type="checkbox" name="cron" id="cron" checked={user.cron !== undefined ? user.cron : true} onChange={(e) => handleCheckbox(e)} />
          <span />
        </label>
      ),
    },
    ...(EbayConnections && EbayConnections.length > 0 ? [{
      label: "Ebay Migration",
      info: "ADMIN.SETTINGS.EBAY_MIGRATIONS_WARNING",
      content: (
        EbayConnections.map((item, i) => (
          <Button key={i} variant="light-primary" size='sm' className="svg-icon svg-icon-sm font-weight-bold mr-2 mb-2" onClick={() => onHandleSubmitTrading(item)} id={`btn_sync_${i}`}>
            <Icon.ArrowRepeat className="mr-2" />
            {item.market_name}
          </Button>
        ))
      ),
    }] : []),
  ];

  const onHide = () => {
    setAction({ show: false, type: "", checked: false, connection: {} });
  };


  return (
    <>
      {INPUTS?.map((item, i) => (
        <h6 className="d-flex align-items-center flex-column flex-md-row mb-2" key={i + 5}>
          <span className="text-dark-50 mw-200px">
            {item.label}

            <OverlayTrigger placement="top" className="mt-0" overlay={<Tooltip id={`info-tooltip-${i}`}>{<FormattedMessage id={item.info} />}</Tooltip>}>
              <Button variant="link" className="p-0 ml-2">
                <Icon.InfoCircle className="text-info" />
              </Button>
            </OverlayTrigger>

          </span>
          <span>
            {item.content}
          </span>
        </h6>
      ))}

      <SettingsWarningDialog userId={user.id} action={action} onHide={onHide} />
    </>
  );
}
