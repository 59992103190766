import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { DocumentActions } from '../_redux/actions';
import { useDocumentsUIContext } from '../_context/DocumentsUIContext';
import { FormattedMessage } from 'react-intl';
import { Portal } from 'react-portal';



export function DocumentDeleteDialog() {

  const dispatch = useDispatch();

  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    show: UIContext.showDeleteDocumentDialog,
    onHide: UIContext.closeDeleteDocumentDialog,
    documentId: UIContext.documentId,
  }), [
    UIContext.ids,
    UIContext.setIds,
    UIContext.showDeleteDocumentDialog,
    UIContext.closeDeleteDocumentDialog,
    UIContext.documentId,
  ]);

  const deleteDocumentAction = () => {

    const DocumentIds = UIProps.documentId
      ? UIProps.documentId
      : UIProps.ids.flatMap(Object.values);

    if (DocumentIds.length) {
      dispatch(DocumentActions.deleteDocument(DocumentIds));
    }

    UIProps.onHide();
    UIProps.setIds([]);
  };


  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={UIProps.show} onHide={UIProps.onHide} size="sm" backdrop="static" aria-labelledby="example-modal-sizes-title-sm" centered>

        <Modal.Body className="text-center line-height-md h6 py-10 mb-0">
          {UIProps.documentId || UIProps.ids.length === 1
            ? <FormattedMessage id="DOCUMENT.DELETE_DIALOG" />
            : <>
              <FormattedMessage id="DOCUMENT.DELETE_DIALOG_BODY_1" />
              <span className="label label-xl label-inline label-light-danger mx-1">{UIProps.ids.length}</span>
              <FormattedMessage id="DOCUMENT.DELETE_DIALOG_BODY_2" />
            </>
          }
        </Modal.Body>

        <Modal.Footer className='flex-nowrap overflow-hidden p-0'>

          <Button variant='ios' className="border-right" onClick={UIProps.onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant='ios' className="text-danger" onClick={deleteDocumentAction} id="btn_delete_modal">
            <FormattedMessage id="DOCUMENT.COLUMN_FORMATTER.SEND_TO_ARCHIVE" />
          </Button>

        </Modal.Footer>

      </Modal>
    </Portal>
  );
}
