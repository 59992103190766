import React from 'react'
import { Field } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import { Alert, Col, Row } from 'react-bootstrap'
import { SVFormControl } from '_metronic/_partials/controls';
import { Icon } from '_metronic/_icons';



export default function EmailEditForm({ isPassword, success, loading, message }) {

  const { formatMessage: intl } = useIntl();

  return (
    <Row>
      {!isPassword
        ? <Col md={12}>

          <Alert variant='light-info' className="flex-column alert-custom alert-notice mb-6">
            <div className="text-dark-75 line-height-xl">
              <FormattedMessage id='SETTINGS.LOGIN.CHANGE_EMAIL.DESCRIPTION' />
            </div>
          </Alert>

          <Field component={SVFormControl}
            {...{
              name: 'new_email',
              label: intl({ id: 'SETTINGS.LOGIN.NEW_EMAIL' }),
              withFeedbackLabel: true,
              feedbackLabel: true,
            }}
          />

        </Col>
        : <Col md={12}>

          <Alert variant='light-info' className="alert-custom alert-notice mb-6">
            <div className="alert-text text-justify text-dark-75 line-height-xl">
              <FormattedMessage id='SETTINGS.LOGIN.CURRENT_PASSWORD' />
            </div>
          </Alert>

          <Field component={SVFormControl}
            {...{
              name: 'password',
              type: 'password',
              label: intl({ id: 'AUTH.INPUT.PASSWORD' }),
              withFeedbackLabel: true,
              feedbackLabel: true,
              disabled: success || loading,
            }}
          />
        </Col>
      }

      {success &&
        <Col md={12}>
          <Alert variant='success' className="svg-icon mb-0">
            <Icon.CheckCircle className="mr-2" />
            <FormattedMessage id={message ? message : 'SERVER.LOGIN.CHANGE_EMAIL.RESET_LINK_SENT'} />
          </Alert>
        </Col>
      }

    </Row>
  )
}


