export default class AmazonListingsActionTypes {
  static get PREFIX() {
    return '[listing/amazon]';
  }



  // Find listing item by ASIN number on Amazon
  static get AMAZON_FIND_LISTING_ITEM() {
    return `${AmazonListingsActionTypes.PREFIX} AMAZON_FIND_LISTING_ITEM`;
  }
  static get AMAZON_FIND_LISTING_ITEM_SUCCESS() {
    return `${AmazonListingsActionTypes.PREFIX} AMAZON_FIND_LISTING_ITEM_SUCCESS`;
  }
  static get AMAZON_FIND_LISTING_ITEM_FAIL() {
    return `${AmazonListingsActionTypes.PREFIX} AMAZON_FIND_LISTING_ITEM_FAIL`;
  }


  // Create new Amazon listing by existing product
  static get AMAZON_CREATE_LISTING() {
    return `${AmazonListingsActionTypes.PREFIX} AMAZON_CREATE_LISTING`;
  }
  static get AMAZON_CREATE_LISTING_SUCCESS() {
    return `${AmazonListingsActionTypes.PREFIX} AMAZON_CREATE_LISTING_SUCCESS`;
  }
  static get AMAZON_CREATE_LISTING_FAIL() {
    return `${AmazonListingsActionTypes.PREFIX} AMAZON_CREATE_LISTING_FAIL`;
  }


  // Get product by id from database
  static get GET_PRODUCT_BY_ID() {
    return `${AmazonListingsActionTypes.PREFIX} GET_PRODUCT_BY_ID`;
  }
  static get GET_PRODUCT_BY_ID_SUCCESS() {
    return `${AmazonListingsActionTypes.PREFIX} GET_PRODUCT_BY_ID_SUCCESS`;
  }
  static get GET_PRODUCT_BY_ID_FAIL() {
    return `${AmazonListingsActionTypes.PREFIX} GET_PRODUCT_BY_ID_FAIL`;
  }


  // reset state to initial state
  static get AMAZON_LISTING_RESET() {
    return `${AmazonListingsActionTypes.PREFIX} AMAZON_LISTING_RESET`;
  }

}
