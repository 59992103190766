import { API } from 'constants/apiUrl';



export const amazonListingsMiddleware = {
  findListingsItem: (accessToken, { asin, tokenId }) => {
    return API.get(`/amazon/find-listing-item/${tokenId}/${asin}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  createListing: (accessToken, payload) => {
    return API.put(`/amazon/put-listing-item`, payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  }
};
