import React, { createContext, useContext, useState } from 'react';


const DigitalProductUIContext = createContext();
export const useDigitalProductsUIContext = () => useContext(DigitalProductUIContext)


export const DigitalProductsUIProvider = ({ children }) => {

  const [ids, setIds] = useState([]);
  const [documentId, setDocumentId] = useState(null);

  //* Customer details dialog
  const [showCustomerDetailsDialog, setShowCustomerDetailsDialog] = useState(false);
  const openCustomerDetailsDialog = id => {
    setDocumentId(id);
    setShowCustomerDetailsDialog(true);
  };
  const closeCustomerDetailsDialog = () => {
    setDocumentId(null);
    setShowCustomerDetailsDialog(false);
  };

  //* Send Mail Key dialog
  const [showSendMailKeyDialog, setShowSendMailKeyDialog] = useState(false);
  const openSendMailKeyDialog = ids => {
    setIds(ids);
    setShowSendMailKeyDialog(true);
  };
  const closeSendMailKeyDialog = () => {
    setIds([]);
    setShowSendMailKeyDialog(false);
  };

  //* Send Mail Key dialog
  const [showReserveDialog, setShowReserveDialog] = useState(false);
  const openReserveDialog = ids => {
    setIds(ids);
    setShowReserveDialog(true);
  };
  const closeReserveDialog = () => {
    setIds([]);
    setShowReserveDialog(false);
  };


  const value = {
    ids,
    setIds,

    documentId,
    setDocumentId,

    showCustomerDetailsDialog,
    openCustomerDetailsDialog,
    closeCustomerDetailsDialog,

    showSendMailKeyDialog,
    openSendMailKeyDialog,
    closeSendMailKeyDialog,

    showReserveDialog,
    openReserveDialog,
    closeReserveDialog,
  };

  return (
    <DigitalProductUIContext.Provider value={value}>
      {children}
    </DigitalProductUIContext.Provider>
  );
};