import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { useProductListingsUIContext } from '../../_context/ProductListingsUIContext';
import { CloseButton } from '_metronic/_partials';
import { Portal } from 'react-portal';
import { Link } from 'react-router-dom';
import { Icon } from '_metronic/_icons';



export function KauflandSyncAlertDialog() {

  const kauflandListingUIContext = useProductListingsUIContext();
  const UIProps = useMemo(() => ({
    show: kauflandListingUIContext.showSyncAlertDialog,
    onHide: kauflandListingUIContext.closeSyncAlertDialog,
    errors: kauflandListingUIContext.syncErrors,
    productId: kauflandListingUIContext.productId,
  }),
    [
      kauflandListingUIContext.showSyncAlertDialog,
      kauflandListingUIContext.closeSyncAlertDialog,
      kauflandListingUIContext.syncErrors,
      kauflandListingUIContext.productId,
    ]
  );

  if (!UIProps.show) return null;

  return (
    <Portal node={document && document.getElementById('modal-portal')}>
      <Modal show={UIProps.show} onHide={UIProps.onHide} size="md" aria-labelledby="contained-modal-title-vcenter" backdrop="static">

        <Modal.Header>
          <Modal.Title>
            <Icon.ExclamationCircle className="mr-3" />
            <FormattedMessage id="PRODUCT_LISTINGS.EBAY.ALERT_DIALOG.CONTENT" />
          </Modal.Title>

          <CloseButton onClick={UIProps.onHide} />
        </Modal.Header>


        <Modal.Body className='px-1'>
          <ul>
            {UIProps.errors?.map((error, index) => (
              <div key={index}>
                <li className='mb-2'>{error.message} (<Link to={ModuleRoutes.EDIT_PRODUCT_FN(UIProps.productId, error.tab)} className='font-weight-bold text-warning'>{error.field}</Link>) </li>
              </div>
            ))}
          </ul>
        </Modal.Body>

        {/*  <Modal.Footer className='pt-2'> 
          <Button className="btn btn-light" onClick={UIProps.onHide}>
            <FormattedMessage id="GENERAL.CLOSE" />
          </Button> 

          <Link to={ModuleRoutes.EDIT_PRODUCT_FN(UIProps.productId)}>
            <Button className="font-weight-bold">
              Edit page
            </Button>
          </Link>
        </Modal.Footer> */}

      </Modal>

    </Portal>
  );
}
