import React from 'react';
import ActionTypes from './actionTypes';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';


export class CategoryActions {


  // Create Category
  static createCategory = category => {
    return {
      type: ActionTypes.CREATE_CATEGORY,
      payload: {
        category: category,
      },
    };
  };

  static createCategorySuccess = category => {
    //toast.success(<FormattedMessage id='CATEGORY.REDUX.CREATE_CATEGORY.SUCCESS' />);
    return {
      type: ActionTypes.CREATE_CATEGORY_SUCCESS,
      payload: {
        category: category,
      },
    };
  };

  static createCategoryFail = () => {
    toast.error(<FormattedMessage id='GENERAL.FAIL' />);
    return {
      type: ActionTypes.CREATE_CATEGORY_FAIL,
    };
  };


  // Update Category
  static updateCategory = (id, category) => {
    return {
      type: ActionTypes.UPDATE_CATEGORY,
      payload: {
        id: id,
        category: category,
      },
    };
  };

  static updateCategorySuccess = () => {
    toast.success(<FormattedMessage id='CATEGORY.REDUX.UPDATE_CATEGORY.SUCCESS' />);
    return {
      type: ActionTypes.UPDATE_CATEGORY_SUCCESS,
    };
  };

  static updateCategoryFail = () => {
    toast.error(<FormattedMessage id='CATEGORY.REDUX.UPDATE_CATEGORY.FAIL' />);
    return {
      type: ActionTypes.UPDATE_CATEGORY_FAIL,
    };
  };


  // Delete Category
  static deleteCategory = (id, queryParams) => {
    return {
      type: ActionTypes.DELETE_CATEGORY,
      payload: {
        id,
        queryParams,
      },
    };
  };

  static deleteCategorySuccess = (id) => {
    toast.success(<FormattedMessage id='CATEGORY.REDUX.DELETE_CATEGORY.SUCCESS' />);
    return {
      type: ActionTypes.DELETE_CATEGORY_SUCCESS,
      payload: {
        id,
      },
    };
  };

  static deleteCategoryFail = (error) => {
    toast.error(error);
    return {
      type: ActionTypes.DELETE_CATEGORY_FAIL,
      payload: {
        error: error,
      },
    };
  };



  // Get Categories
  static getCategories = () => {
    return {
      type: ActionTypes.GET_CATEGORIES,
    };
  };

  static getCategoriesSuccess = (categories, countCategories) => {
    return {
      type: ActionTypes.GET_CATEGORIES_SUCCESS,
      payload: {
        categories,
        countCategories,
      },
    };
  };

  static getCategoriesFail = () => {
    return {
      type: ActionTypes.GET_CATEGORIES_FAIL,
    };
  };
}