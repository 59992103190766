import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { useCategoriesUIContext } from "../_context/CategoriesUIContext";
import { CategoryActions } from "../_redux/actions";
import { FormattedMessage } from "react-intl";



export function CategoryDeleteDialog({ id, name, show, onHide }) {

  // Categories UI Context
  const UIContext = useCategoriesUIContext();
  const UIProps = useMemo(() => ({
    setIds: UIContext.setIds,
  }), [UIContext]);

  // Categories Redux state
  const dispatch = useDispatch();

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const deleteCategoryAction = () => {
    dispatch(CategoryActions.deleteCategory(id));
    UIProps.setIds([]);
    onHide();
  };


  return (
    <Modal show={show} onHide={onHide} size="sm" backdrop="static" aria-labelledby="example-modal-sizes-title-lg" centered>

      <Modal.Body className="text-center line-height-md h6 py-10 mb-0">
        <FormattedMessage id="CATEGORY.DELETE_DIALOG.PERMANENT" values={{ categoryName: decodeURIComponent(name) }} />
      </Modal.Body>

      <Modal.Footer className="flex-nowrap p-0 overflow-hidden">

        <Button variant="ios" className="border-right" onClick={onHide} id='btn_cancel_modal'>
          <FormattedMessage id="GENERAL.CANCEL" />
        </Button>

        <Button variant="ios" className="text-danger" onClick={deleteCategoryAction} id="btn_delete_modal">
          <FormattedMessage id="GENERAL.DELETE" />
        </Button>

      </Modal.Footer>

    </Modal>
  );
}
