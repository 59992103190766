import React, { useState, createContext, useContext, useCallback, useMemo, } from 'react';
import { initialProductQueryParams, initialQueryParams, initialOrderParams, initialSearchParams } from '../../products/_context/ProductsUIHelpers';
import { isFunction } from 'lodash';



const DocumentEditUIContext = createContext();

export function useDocumentEditUIContext() {
  return useContext(DocumentEditUIContext);
}

export function DocumentEditProvider({ children }) {

  //*Product query parameters *START*

  // settings and events handling (pagination, search, filter, order)
  const [productQueryParams, setProductQueryParams] = useState(
    initialProductQueryParams
  );

  // Pagination parameters default(page: 1, limit: 10)
  const [paginationParams, setPaginationParamsBase] = useState(initialQueryParams);
  const setPaginationParams = useCallback(nextQueryParams => {
    if (isFunction(nextQueryParams)) {
      return setPaginationParamsBase(nextQueryParams);
    }
    setPaginationParamsBase(initialQueryParams);
  }, []);
  useMemo(() => {
    setProductQueryParams(prev => ({ ...prev, pagination: paginationParams }));
  }, [paginationParams]);

  // Order parameters (default: id DESC)
  const [orderParams, setOrderParamsBase] = useState(initialOrderParams);
  const setOrderQueryParams = useCallback(params => {
    if (typeof params === 'string') {
      return setOrderParamsBase(params);
    }
    setOrderParamsBase(initialOrderParams);
  }, []);
  useMemo(() => {
    setProductQueryParams(prev => ({ ...prev, order: orderParams }));
  }, [orderParams]);

  // Search parameters (default: searchText: '', searchKeys: searchProperties)
  const [searchParams, setSearchParamsBase] = useState(initialSearchParams);
  const setSearchParams = useCallback(nextSearchParams => {
    if (isFunction(nextSearchParams)) {
      return setSearchParamsBase(nextSearchParams);
    }
    setSearchParamsBase(initialSearchParams);
  }, []);
  useMemo(() => {
    setProductQueryParams(prev => ({ ...prev, search: searchParams }));
    setPaginationParams(prev => initialQueryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  // Filter parameters (default: null)
  const [filterParams, setFilterParamsBase] = useState(null);
  const setFilterParams = useCallback(nextFilterParams => {
    if (isFunction(nextFilterParams)) {
      return setFilterParamsBase(nextFilterParams);
    }
    setFilterParamsBase(null);
  }, []);
  useMemo(() => {
    setProductQueryParams(prev => ({ ...prev, filter: filterParams }));
  }, [filterParams]);
  //*Product query parameters *END*


  const [showProductEditDialog, setProductEditDialog] = useState(false);
  const handleCloseProductEditDialog = () => {
    setShowProductSelectDialog(true);
    setProductEditDialog(false);
  };

  const handleOpenProductEditDialog = () => {
    setShowProductSelectDialog(false);
    setProductEditDialog(true);
  };

  const [showProductSelectDialog, setShowProductSelectDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const handleCloseProductSelectDialog = () => {
    setShowProductSelectDialog(false);
  };

  const handleOpenProductSelectDialog = () => {
    setShowProductSelectDialog(true);
  };

  const [showVariantSelectDialog, setShowVariantSelectDialog] = useState(false);

  const handleCloseVariantSelectDialog = () => {
    setShowVariantSelectDialog(false);
    setSelectedProduct(null);
  };

  const handleOpenVariantSelectDialog = row => {
    setShowVariantSelectDialog(true);
    setSelectedProduct(row);
  };

  const value = {
    productQueryParams,
    setProductQueryParams,
    paginationParams,
    setPaginationParams,
    orderParams,
    setOrderQueryParams,
    searchParams,
    setSearchParams,
    filterParams,
    setFilterParams,

    showProductEditDialog,
    handleCloseProductEditDialog,
    handleOpenProductEditDialog,
    showProductSelectDialog,
    handleCloseProductSelectDialog,
    handleOpenProductSelectDialog,
    showVariantSelectDialog,
    handleCloseVariantSelectDialog,
    handleOpenVariantSelectDialog,
    selectedProduct,
    setSelectedProduct,
  };
  return (
    <DocumentEditUIContext.Provider value={value}>
      {children}
    </DocumentEditUIContext.Provider>
  );
}
