import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { RELATIVE_PATH } from 'constants/moduleRoutes';
import { Icon } from '_metronic/_icons';



export function HeaderMobile() {

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => ({
    headerLogo: uiService.getStickyLogo(),
    asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
    headerMobileCssClasses: uiService.getClasses('header_mobile', true),
    headerMobileAttributes: uiService.getAttributes('header_mobile'),
  }), [uiService]);


  return (
    <>
      {/*begin::Header Mobile*/}
      <div id="kt_header_mobile" className={`header-mobile align-items-center ${layoutProps.headerMobileCssClasses}`} {...layoutProps.headerMobileAttributes}>

        {/*begin::Logo*/}
        <Link to={RELATIVE_PATH}>
          <img style={{ height: '52px', padding: "1rem" }} alt="logo" src={layoutProps.headerLogo} />
        </Link>
        {/*end::Logo*/}

        {/*begin::Toolbar*/}
        <div className="d-flex align-items-center">


          {/*begin::Aside Mobile Toggle*/}
          {layoutProps.asideDisplay && (
            <button className="btn burger-icon burger-icon-left p-0" aria-label="Aside menu toggle" id="kt_aside_mobile_toggle">
              <span />
            </button>
          )}
          {/*end::Aside Mobile Toggle*/}


          {/*begin::Topbar Mobile Toggle*/}
          <button className="btn text-dark-25 btn-hover-text-primary p-0 ml-2" aria-label="Aside header topbar toggle" id="kt_header_mobile_topbar_toggle">
            <span className="svg-icon svg-icon-xl">
              <Icon.PersonFill />
            </span>
          </button>
          {/*end::Topbar Mobile Toggle*/}

        </div>
        {/*end::Toolbar*/}

      </div>
      {/*end::Header Mobile*/}
    </>
  );
}
