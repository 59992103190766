import axios from "axios";
// import packagejson from "../../package.json";



export const PROD_DOMAIN = 'salevali.com';
export const DEV_DOMAIN = 'dev.salevali.com';
export const TEST_DOMAIN = 'test.salevali.com';
export const DEMO_DOMAIN = 'demo.salevali.com';

export const LANDING_PAGE = "https://salevali.com"
export const COMPANY_PAGE = "https://www.bcsit-gmbh.de"
export const location = window.location.hostname;

const DOMAIN = location.includes(DEV_DOMAIN) ? DEV_DOMAIN
  : location.includes(TEST_DOMAIN) ? TEST_DOMAIN
    : location.includes(DEMO_DOMAIN) ? DEMO_DOMAIN
      : PROD_DOMAIN

export const API_URL = `https://api.${DOMAIN}`;
export const API_LOCAL_URL = 'http://localhost:4000';

export let BASE_URL = process.env.NODE_ENV !== 'production' ? API_LOCAL_URL : API_URL;

export const IsDevDomainFn = () => {
  return [DEV_DOMAIN, TEST_DOMAIN, DEMO_DOMAIN, 'localhost'].includes(location)
}

if (process.env.NODE_ENV !== 'production') {
  console.warn('🔗 Connected to', BASE_URL)
}

export let API = axios.create({
  baseURL: BASE_URL,
  timeout: 30000, // 30 seconds timeout
})


// function getAccessToken() {
//   try {
//     const persistData = JSON.parse(localStorage.getItem(`persist:salevali.v${packagejson.version}`));
//     const authData = JSON.parse(persistData.auth);
//     return authData.access_token;
//   } catch (error) {
//     console.error("Error retrieving access token:", error);
//     return null;
//   }
// }


// Request interceptor to add the token to headers
// API.interceptors.request.use(
//   (config) => {
//     const token = getAccessToken();
//     if (token) {
//       console.log("Token:", token);
//       config.headers["Authorization"] = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );


