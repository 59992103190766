export default class ActionTypes {
  static get PREFIX() {
    return '[document]';
  }


  /**
   * *get url access data from store
   * @return action types for get user access
   */
  static get GET_URL_ACCESS_DATA_FROM_STORE() {
    return `${ActionTypes.PREFIX}GET_URL_ACCESS_DATA_FROM_STORE`;
  }


  /**
   * *Print Document
   * @return action types for print document
   */
  static get PRINT_DOCUMENT() {
    return `${ActionTypes.PREFIX}PRINT_DOCUMENT`;
  }
  static get PRINT_DOCUMENT_SUCCESS() {
    return `${ActionTypes.PREFIX}PRINT_DOCUMENT_SUCCESS`;
  }
  static get PRINT_DOCUMENT_FAIL() {
    return `${ActionTypes.PREFIX}PRINT_DOCUMENT_FAIL`;
  }


  /**
   * *Create Label, dhl,dpd,...
   * @return action types for create document
   */
  static get CREATE_LABELS() {
    return `${ActionTypes.PREFIX}CREATE_LABELS`;
  }
  static get CREATE_LABELS_SUCCESS() {
    return `${ActionTypes.PREFIX}CREATE_LABELS_SUCCESS`;
  }
  static get CREATE_LABELS_FAIL() {
    return `${ActionTypes.PREFIX}CREATE_LABELS_FAIL`;
  }


  /**
   * *Get Label, dhl,dpd,...
   * @return action types for create document
   */
  static get GET_LABEL() {
    return `${ActionTypes.PREFIX}GET_LABEL`;
  }
  static get GET_LABEL_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_LABEL_SUCCESS`;
  }
  static get GET_LABEL_FAIL() {
    return `${ActionTypes.PREFIX}GET_LABEL_FAIL`;
  }


  /**
   * *Get shippings Label, dhl,dpd,...
   * @return action types for Get shippings
   */
  // Get shipping action types
  static get GET_SHIPPING() {
    return `${ActionTypes.PREFIX}GET_SHIPPING`;
  }
  static get GET_SHIPPING_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_SHIPPING_SUCCESS`;
  }
  static get GET_SHIPPING_FAIL() {
    return `${ActionTypes.PREFIX}GET_SHIPPING_FAIL`;
  }

  /**
   * *Create Label, dhl,dpd,...
   * @return action types for create document
   */
  static get DELETE_LABEL() {
    return `${ActionTypes.PREFIX}DELETE_LABEL`;
  }
  static get DELETE_LABEL_SUCCESS() {
    return `${ActionTypes.PREFIX}DELETE_LABEL_SUCCESS`;
  }
  static get DELETE_LABEL_FAIL() {
    return `${ActionTypes.PREFIX}DELETE_LABEL_FAIL`;
  }


  /**
   * *Create Document
   * @return action types for create document
   */
  static get CREATE_DOCUMENT() {
    return `${ActionTypes.PREFIX}CREATE_DOCUMENT`;
  }
  static get CREATE_DOCUMENT_SUCCESS() {
    return `${ActionTypes.PREFIX}CREATE_DOCUMENT_SUCCESS`;
  }
  static get CREATE_DOCUMENT_FAIL() {
    return `${ActionTypes.PREFIX}CREATE_DOCUMENT_FAIL`;
  }


  /**
   * *Update Document
   * @return action types for update document
   */
  static get UPDATE_DOCUMENT() {
    return `${ActionTypes.PREFIX}UPDATE_DOCUMENT`;
  }
  static get UPDATE_DOCUMENT_SUCCESS() {
    return `${ActionTypes.PREFIX}UPDATE_DOCUMENT_SUCCESS`;
  }
  static get UPDATE_DOCUMENT_FAIL() {
    return `${ActionTypes.PREFIX}UPDATE_DOCUMENT_FAIL`;
  }


  /**
   * *Update Document
   * @return action types for update document
   */
  static get UPDATE_DOCUMENT_BY_FIELD() {
    return `${ActionTypes.PREFIX}UPDATE_DOCUMENT_BY_FIELD`;
  }
  static get UPDATE_DOCUMENT_BY_FIELD_SUCCESS() {
    return `${ActionTypes.PREFIX}UPDATE_DOCUMENT_BY_FIELD_SUCCESS`;
  }
  static get UPDATE_DOCUMENT_BY_FIELD_FAIL() {
    return `${ActionTypes.PREFIX}UPDATE_DOCUMENT_BY_FIELD_FAIL`;
  }


  /**
   * *Delete Document
   * @return action types for delete document
   */
  static get DELETE_DOCUMENT() {
    return `${ActionTypes.PREFIX}DELETE_DOCUMENT`;
  }
  static get DELETE_DOCUMENT_SUCCESS() {
    return `${ActionTypes.PREFIX}DELETE_DOCUMENT_SUCCESS`;
  }
  static get DELETE_DOCUMENT_FAIL() {
    return `${ActionTypes.PREFIX}DELETE_DOCUMENT_FAIL`;
  }


  /**
   * *Get Documents
   * @return action types for get all documents
   */
  static get GET_DOCUMENTS() {
    return `${ActionTypes.PREFIX}GET_DOCUMENTS`;
  }
  static get GET_DOCUMENTS_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_DOCUMENTS_SUCCESS`;
  }
  static get GET_DOCUMENTS_FAIL() {
    return `${ActionTypes.PREFIX}GET_DOCUMENTS_FAIL`;
  }


  /**
   * *Get Document
   * @return action types for get document by id
   */
  static get GET_DOCUMENT_BY_ID() {
    return `${ActionTypes.PREFIX}GET_DOCUMENT_BY_ID`;
  }
  static get GET_DOCUMENT_BY_ID_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_DOCUMENT_BY_ID_SUCCESS`;
  }
  /*  static get GET_DOCUMENT_BY_ID_FOR_PRINT_ALL_SUCCESS() {
     return `${ActionTypes.PREFIX}GET_DOCUMENT_BY_ID_FOR_PRINT_ALL_SUCCESS`;
   } */
  static get GET_DOCUMENT_BY_ID_FAIL() {
    return `${ActionTypes.PREFIX}GET_DOCUMENT_BY_ID_FAIL`;
  }


  /**
   * *Get Customers
   * @return action types for get document by id
   */
  static get GET_CUSTOMERS() {
    return `${ActionTypes.PREFIX}GET_CUSTOMERS`;
  }
  static get GET_CUSTOMERS_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_CUSTOMERS_SUCCESS`;
  }
  static get GET_CUSTOMERS_FAIL() {
    return `${ActionTypes.PREFIX}GET_CUSTOMERS_FAIL`;
  }


  /**
   * *Get Customer
   * @return action types for get document by id
   */
  static get GET_CUSTOMER() {
    return `${ActionTypes.PREFIX}GET_CUSTOMER`;
  }
  static get GET_CUSTOMER_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_CUSTOMER_SUCCESS`;
  }
  static get GET_CUSTOMER_FAIL() {
    return `${ActionTypes.PREFIX}GET_CUSTOMER_FAIL`;
  }


  /**
   * *Update Customer
   * @return action types for update customer
   */
  static get UPDATE_CUSTOMER() {
    return `${ActionTypes.PREFIX}UPDATE_CUSTOMER`;
  }
  static get UPDATE_CUSTOMER_SUCCESS() {
    return `${ActionTypes.PREFIX}UPDATE_CUSTOMER_SUCCESS`;
  }
  static get UPDATE_CUSTOMER_FAIL() {
    return `${ActionTypes.PREFIX}UPDATE_CUSTOMER_FAIL`;
  }


  /**
   * *Enter payment
   * @return action types for Enter payment
   */
  static get ENTER_PAYMENT() {
    return `${ActionTypes.PREFIX}ENTER_PAYMENT`;
  }
  static get ENTER_PAYMENT_SUCCESS() {
    return `${ActionTypes.PREFIX}ENTER_PAYMENT_SUCCESS`;
  }
  static get ENTER_PAYMENT_FAIL() {
    return `${ActionTypes.PREFIX}ENTER_PAYMENT_FAIL`;
  }


  /**
   * *Update Enter payment
   * @return action types for Enter payment
   */
  static get UPDATE_ENTER_PAYMENT() {
    return `${ActionTypes.PREFIX}UPDATE_ENTER_PAYMENT`;
  }
  static get UPDATE_ENTER_PAYMENT_SUCCESS() {
    return `${ActionTypes.PREFIX}UPDATE_ENTER_PAYMENT_SUCCESS`;
  }
  static get UPDATE_ENTER_PAYMENT_FAIL() {
    return `${ActionTypes.PREFIX}UPDATE_ENTER_PAYMENT_FAIL`;
  }


  /**
   * *Delete Enter payment
   * @return action types for Enter payment
   */
  static get DELETE_ENTERED_PAYMENT() {
    return `${ActionTypes.PREFIX}DELETE_ENTERED_PAYMENT`;
  }
  static get DELETE_ENTERED_PAYMENT_SUCCESS() {
    return `${ActionTypes.PREFIX}DELETE_ENTERED_PAYMENT_SUCCESS`;
  }
  static get DELETE_ENTERED_PAYMENT_FAIL() {
    return `${ActionTypes.PREFIX}DELETE_ENTERED_PAYMENT_FAIL`;
  }


  /**
   * *get document customer details
   * @return action types for document customer details
   */
  static get GET_DOCUMENT_CUSTOMER_DETAILS() {
    return `${ActionTypes.PREFIX}GET_DOCUMENT_CUSTOMER_DETAILS`;
  }
  static get GET_DOCUMENT_CUSTOMER_DETAILS_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_DOCUMENT_CUSTOMER_DETAILS_SUCCESS`;
  }
  static get GET_DOCUMENT_CUSTOMER_DETAILS_FAIL() {
    return `${ActionTypes.PREFIX}GET_DOCUMENT_CUSTOMER_DETAILS_FAIL`;
  }


  /**
   * *get document customer details
   * @return action types for document customer details
   */
  static get GET_SETTINGS_BY_TYPE() {
    return `${ActionTypes.PREFIX}GET_SETTINGS_BY_TYPE`;
  }
  static get GET_SETTINGS_BY_TYPE_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_SETTINGS_BY_TYPE_SUCCESS`;
  }
  static get GET_SETTINGS_BY_TYPE_FAIL() {
    return `${ActionTypes.PREFIX}GET_SETTINGS_BY_TYPE_FAIL`;
  }


  /**
   * *get document customer details
   * @return action types for document customer details
   */
  static get GET_COMPANY_BASE_DATA() {
    return `${ActionTypes.PREFIX}GET_COMPANY_BASE_DATA`;
  }
  static get GET_COMPANY_BASE_DATA_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_COMPANY_BASE_DATA_SUCCESS`;
  }
  static get GET_COMPANY_BASE_DATA_FAIL() {
    return `${ActionTypes.PREFIX}GET_COMPANY_BASE_DATA_FAIL`;
  }


  /**
   * *Select Customer
   * @return action type for select customer
   */
  static get SELECT_CUSTOMER() {
    return `${ActionTypes.PREFIX}SELECT_CUSTOMER`;
  }
  static get EDIT_SELECTED_CUSTOMER() {
    return `${ActionTypes.PREFIX}EDIT_SELECTED_CUSTOMER`;
  }
  static get SELECT_CUSTOMER_INVOICE_ADDRESS() {
    return `${ActionTypes.PREFIX}SELECT_CUSTOMER_INVOICE_ADDRESS`;
  }
  static get EDIT_SELECTED_CUSTOMER_INVOICE_ADDRESS() {
    return `${ActionTypes.PREFIX}EDIT_SELECTED_CUSTOMER_INVOICE_ADDRESS`;
  }
  static get SELECT_CUSTOMER_ADDRESS() {
    return `${ActionTypes.PREFIX}SELECT_CUSTOMER_ADDRESS`;
  }
  static get EDIT_SELECTED_CUSTOMER_SHIPPING_ADDRESS() {
    return `${ActionTypes.PREFIX}EDIT_SELECTED_CUSTOMER_SHIPPING_ADDRESS`;
  }


  /**
   * *Positions
   * @return action types for positions
   */
  static get ADD_POSITION() {
    return `${ActionTypes.PREFIX}ADD_POSITION`;
  }
  static get ADD_POSITION_SUCCESS() {
    return `${ActionTypes.PREFIX}ADD_POSITION_SUCCESS`;
  }
  static get ADD_POSITION_FAIL() {
    return `${ActionTypes.PREFIX}ADD_POSITION_FAIL`;
  }
  static get ADD_MULTIPLE_POSITION_ROW() {
    return `${ActionTypes.PREFIX}ADD_MULTIPLE_POSITION_ROW`;
  }
  static get ADD_MULTIPLE_POSITION_ROW_SUCCESS() {
    return `${ActionTypes.PREFIX}ADD_MULTIPLE_POSITION_ROW_SUCCESS`;
  }
  static get ADD_NEW_POSITION_ROW() {
    return `${ActionTypes.PREFIX}ADD_NEW_POSITION_ROW`;
  }
  static get UPDATE_POSITION() {
    return `${ActionTypes.PREFIX}UPDATE_POSITION`;
  }
  static get UPDATE_POSITION_TAXES_TO_FREI() {
    return `${ActionTypes.PREFIX}UPDATE_POSITION_TAXES_TO_FREI`;
  }
  static get DELETE_POSITION() {
    return `${ActionTypes.PREFIX}DELETE_POSITION`;
  }
  static get UPDATE_DISCOUNT() {
    return `${ActionTypes.PREFIX}UPDATE_DISCOUNT`;
  }


  /**
   * *Clean up Store
   * @return action type for clean up store
   */
  static get CLEAN_UP_STORE() {
    return `${ActionTypes.PREFIX}CLEAN_UP_STORE`;
  }
  static get CLEAN_UP_DHL_STATES() {
    return `${ActionTypes.PREFIX}CLEAN_UP_DHL_STATES`;
  }


  /**
   * *..
   * @return action type for ..
   */
  static get SETUP_REQUESTED_DOCUMENT() {
    return `${ActionTypes.PREFIX}SETUP_REQUESTED_DOCUMENT`;
  }


  /**
   * *..
   * @return action type for ..
   */
  static get SETUP_REQUESTED_DOCUMENT_FAIL() {
    return `${ActionTypes.PREFIX}SETUP_REQUESTED_DOCUMENT_FAIL`;
  }


  /*-- SHOP ACTION TYPES --*/
  /**
   * *
   * @return
   */
  static get GET_REQUESTED_SHOP_ORDERS() {
    return `${ActionTypes.PREFIX}GET_REQUESTED_SHOP_ORDERS`;
  }
  static get GET_REQUESTED_SHOP_ORDERS_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_REQUESTED_SHOP_ORDERS_SUCCESS`;
  }
  static get GET_REQUESTED_SHOP_ORDERS_FAIL() {
    return `${ActionTypes.PREFIX}GET_REQUESTED_SHOP_ORDERS_FAIL`;
  }


  /**
   * *Amazon Prime
   * @return
   */
  static get GET_AMAZON_PRIME_ORDERS() {
    return `${ActionTypes.PREFIX}GET_AMAZON_PRIME_ORDERS`;
  }
  static get GET_AMAZON_PRIME_ORDERS_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_AMAZON_PRIME_ORDERS_SUCCESS`;
  }
  static get GET_AMAZON_PRIME_ORDERS_FAIL() {
    return `${ActionTypes.PREFIX}GET_AMAZON_PRIME_ORDERS_FAIL`;
  }

  static get CREATE_FULFILLMENT_METHODS() {
    return `${ActionTypes.PREFIX}CREATE_FULFILLMENT_METHODS`;
  }
  static get CREATE_FULFILLMENT_METHODS_SUCCESS() {
    return `${ActionTypes.PREFIX}CREATE_FULFILLMENT_METHODS_SUCCESS`;
  }
  static get CREATE_FULFILLMENT_METHODS_FAIL() {
    return `${ActionTypes.PREFIX}CREATE_FULFILLMENT_METHODS_FAIL`;
  }


  /*-- EBAY ACTIONS --*/

  /**
   * *Get user ebay tokens
   * @return action types for get document by id
   */
  static get GET_USER_TOKENS() {
    return `${ActionTypes.PREFIX}GET_USER_TOKENS`;
  }
  static get GET_USER_TOKENS_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_USER_TOKENS_SUCCESS`;
  }
  static get GET_USER_TOKENS_FAIL() {
    return `${ActionTypes.PREFIX}GET_USER_TOKENS_FAIL`;
  }

  static get SET_STATE_IN_REDUCER() {
    return `${ActionTypes.PREFIX}SET_STATE_IN_REDUCER`;
  }


  /**
 * *Get DocumentIds
 * @return action types for get all documents
 */
  static get GET_DOCUMENT_BY_IDS() {
    return `${ActionTypes.PREFIX}GET_DOCUMENT_BY_IDS`;
  }
  static get GET_DOCUMENT_BY_IDS_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_DOCUMENT_BY_IDS_SUCCESS`;
  }
  static get GET_DOCUMENT_BY_IDS_FAIL() {
    return `${ActionTypes.PREFIX}GET_DOCUMENT_BY_IDS_FAIL`;
  }

  /**
   * *Create All shippings
    */
  static get CREATE_ALL_SHIPPINGS() {
    return `${ActionTypes.PREFIX}CREATE_ALL_SHIPPINGS`;
  }
  static get CREATE_ALL_SHIPPINGS_SUCCESS() {
    return `${ActionTypes.PREFIX}CREATE_ALL_SHIPPINGS_SUCCESS`;
  }
  static get CREATE_ALL_SHIPPINGS_FAIL() {
    return `${ActionTypes.PREFIX}CREATE_ALL_SHIPPINGS_FAIL`;
  }


  // Shipping Grouping Actions
  static get UPDATE_SHIPPING_GROUPING() {
    return `${ActionTypes.PREFIX}UPDATE_SHIPPING_GROUPING`;
  }


  /**
 * *Return Label, dhl-dpd
 *  action types for create document
 */
  /*   static get RETURN_LABELS() {
      return `${ActionTypes.PREFIX}RETURN_LABELS`;
    }
    static get RETURN_LABELS_SUCCESS() {
      return `${ActionTypes.PREFIX}RETURN_LABELS_SUCCESS`;
    }
    static get RETURN_LABELS_FAIL() {
      return `${ActionTypes.PREFIX}RETURN_LABELS_FAIL`;
    } */


  /**
 * *Return Label Email
 * @return action types for create document
 */
  static get RETURN_LABEL_EMAIL() {
    return `${ActionTypes.PREFIX}RETURN_LABEL_EMAIL`;
  }
  static get RETURN_LABEL_EMAIL_SUCCESS() {
    return `${ActionTypes.PREFIX}RETURN_LABEL_EMAIL_SUCCESS`;
  }
  static get RETURN_LABEL_EMAIL_FAIL() {
    return `${ActionTypes.PREFIX}RETURN_LABEL_EMAIL_FAIL`;
  }


  /**
 * *Create Label, dhl,dpd,...
 * @return action types for create document
 */
  static get UPDATE_LABEL() {
    return `${ActionTypes.PREFIX}UPDATE_LABEL`;
  }
  static get UPDATE_LABEL_SUCCESS() {
    return `${ActionTypes.PREFIX}UPDATE_LABEL_SUCCESS`;
  }
  static get UPDATE_LABEL_FAIL() {
    return `${ActionTypes.PREFIX}UPDATE_LABEL_FAIL`;
  }
  /**
 * *Create Label, dhl,dpd,...
 * @return action types for create document
 */
  static get CREATE_UNLABELED() {
    return `${ActionTypes.PREFIX}CREATE_UNLABELED`;
  }
  static get CREATE_UNLABELED_SUCCESS() {
    return `${ActionTypes.PREFIX}CREATE_UNLABELED_SUCCESS`;
  }
  static get CREATE_UNLABELED_FAIL() {
    return `${ActionTypes.PREFIX}CREATE_UNLABELED_FAIL`;
  }


  /**
 * *Delete payment warning
 * @return 
 */
  static get DELETE_PAYMENT_WARNING() {
    return `${ActionTypes.PREFIX}DELETE_PAYMENT_WARNING`;
  }
  static get DELETE_PAYMENT_WARNING_SUCCESS() {
    return `${ActionTypes.PREFIX}DELETE_PAYMENT_WARNING_SUCCESS`;
  }
  static get DELETE_PAYMENT_WARNING_FAIL() {
    return `${ActionTypes.PREFIX}DELETE_PAYMENT_WARNING_FAIL`;
  }



  static get DELETE_COMPLATE_LIST() {
    return `${ActionTypes.PREFIX}DELETE_COMPLATE_LIST`;
  }


  static get DELETE_SHIPPINGS_LIST() {
    return `${ActionTypes.PREFIX}DELETE_SHIPPINGS_LIST`;
  }


  /**
   * *clear loading
   * @return action types for clear loading
   */
  static get CLEAR_LOADING_EFFECTS() {
    return `${ActionTypes.PREFIX}CLEAR_LOADING_EFFECTS`;
  }


  /**
   * *Clean Store
   * @return action type for clean up store
   */
  static get CLEAN_STATES_IN_STORE() {
    return `${ActionTypes.PREFIX}CLEAN_STATES_IN_STORE`;
  }
}
