import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Row, Col } from 'react-bootstrap';
import { getCountryList } from '_metronic/_helpers';
import { CloseButton } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import { useLang } from '_metronic/i18n';
import { Portal } from 'react-portal';
import { isEmpty } from 'lodash';
import { AdminActions } from '../_redux/actions';



export function UserDetailsDialog({UIProps}) {

  const locale = useLang();
  const dispatch = useDispatch();

  const { user_company } = useSelector(state => state.admin);
  const { company_name, owner, email, phone, mobile, address, web } = user_company || {};

  useEffect(() => {
    UIProps.userId && dispatch(AdminActions.getUserCompany(UIProps.userId));
  }, [dispatch, UIProps.userId]);

  // if !id we should close modal
  useEffect(() => {
    !UIProps.userId && UIProps.onHide();
  }, [UIProps]);


  // useEffect(() => {
  //   return () => {
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [UIProps.userId]);


  return (
    <Portal node={document && document.getElementById('modal-portal')}>
      <Modal show={UIProps.show} onHide={UIProps.onHide} aria-labelledby="example-modal-sizes-title-md" size="md" centered>

        <Modal.Header>
          <Modal.Title>
            <Icon.Person className="mr-3" />
            <FormattedMessage id="ADMIN.USER.USER_DETAILS" />
          </Modal.Title>
          <CloseButton onClick={UIProps.onHide} />
        </Modal.Header>

        <Modal.Body className='overflow-hidden px-10 py-2'>
          {user_company && !isEmpty(user_company) && (

            <div className='d-flex flex-column font-weight-bold text-break'>

              {owner &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.OWNER" />
                  </Col>
                  <Col lg='7'>
                    {owner}
                  </Col>
                </Row>
              }

              {company_name &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.COMPANY" />
                  </Col>
                  <Col lg='7'>
                    {company_name}
                  </Col>
                </Row>
              }

              {email &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.EMAIL" />
                  </Col>
                  <Col lg='7'>
                    <a href={`mailto:${email}`} className="text-decoration-none text-dark text-hover-primary">{email}</a>
                  </Col>
                </Row>
              }

              {phone &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.PHONE" />
                  </Col>
                  <Col lg='7'>
                    <a href={`tel:${phone}`} className="text-decoration-none text-dark text-hover-primary">{phone}</a>
                  </Col>
                </Row>
              }

              {mobile &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.MOBILE" />
                  </Col>
                  <Col lg='7'>
                    <a href={`tel:${mobile}`} className="text-decoration-none text-dark text-hover-primary">{mobile}</a>
                  </Col>
                </Row>
              }

              {web &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.WEB" />
                  </Col>
                  <Col lg='7'>
                    {/* <a href={`https://${web}`} target='blank_' className="text-decoration-none text-dark text-hover-primary">{web}</a> */}
                    {web}
                  </Col>
                </Row>
              }
              {/* --------------------------------- Address --------------------------------- */}

              {address &&
                <Row className='py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.ADDRESS" />
                  </Col>
                  <Col lg='7'>
                    <div>{address?.address_line1} {address?.address_line2}</div>
                    <div>{address?.post_code} {address?.city}</div>
                    <div>{getCountryList(locale).find(code => code.value === address?.country_code)?.label}</div>
                  </Col>
                </Row>
              }

            </div>
          )}

        </Modal.Body>

        <Modal.Footer>

          {/* <Button variant='primary' size='sm' type="button" className="svg-icon-sm mr-2" onClick={() => { history.push(ModuleRoutes.EDIT_CUSTOMER_FN(id)); UIProps.onHide() }} id='btn_edit_modal'>
            <FormattedMessage id='GENERAL.EDIT' />
          </Button> */}

        </Modal.Footer>

      </Modal>
    </Portal>
  );
};