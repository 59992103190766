import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { useDocumentsUIContext } from '../_context/DocumentsUIContext';
import { importExportActions } from 'app/modules/import-export/_redux/actions';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useLang } from '_metronic/i18n';
import { Portal } from 'react-portal';



export function DocumentExportDialog() {

  const dispatch = useDispatch();
  const locale = useLang();
  const history = useHistory();

  const UIContext = useDocumentsUIContext();
  const { type: documentType } = useMemo(() =>
    UIContext.requestedDocument,
    [UIContext.requestedDocument,]
  );
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    show: UIContext.showExportDocumentDialog,
    onHide: UIContext.closeExportDocumentDialog,
    documentId: UIContext.documentId,
  }), [
    UIContext.ids,
    UIContext.setIds,
    UIContext.showExportDocumentDialog,
    UIContext.closeExportDocumentDialog,
    UIContext.documentId,
  ]);

  const exportDocumentAction = () => {

    const documentIds = UIProps.documentId
      ? UIProps.documentId
      : UIProps.ids.flatMap(Object.values);

    if (documentIds.length) {
      dispatch(importExportActions.getExport({
        export_select: documentType,
        date_range: { title: "none", start_date: "", end_date: "" },
        language: locale,
        ids: documentIds,
      }));
    }

    UIProps.onHide();
    UIProps.setIds([]);
    history.push('/export');
  };


  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={UIProps.show} onHide={UIProps.onHide} size="sm" backdrop="static" aria-labelledby="example-modal-sizes-title-sm" centered>

        <Modal.Body className="text-center line-height-md h6 py-10 mb-0">
          {UIProps.documentId || UIProps.ids.length === 1
            ? <FormattedMessage id="DOCUMENT.EXPORT_DIALOG" />
            : <>
              <FormattedMessage id="DOCUMENT.EXPORT_DIALOG_BODY_1" />
              <span className="label label-xl label-inline label-light-danger mx-1">{UIProps.ids.length}</span>
              <FormattedMessage id="DOCUMENT.EXPORT_DIALOG_BODY_2" />
            </>
          }
        </Modal.Body>

        <Modal.Footer className='flex-nowrap overflow-hidden p-0'>

          <Button variant='ios' className="border-right" onClick={UIProps.onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant='ios' className="text-danger" onClick={exportDocumentAction} id="btn_export_modal">
            <FormattedMessage id="IMPORT_EXPORT.EXPORT" />
          </Button>

        </Modal.Footer>

      </Modal>
    </Portal>
  );
}
