import React from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardSubHeader, DropDownControl } from '_metronic/_partials/controls'
import { FormattedMessage, useIntl } from 'react-intl'
import { Field, Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { Icon } from '_metronic/_icons';
import * as Yup from "yup";



export default function ImportSelect({ getImportType }) {


  const { formatMessage: intl } = useIntl();

  const licenseType = useSelector(state => state.auth.license.type);

  const importOptions = [
    {
      value: 'customers',
      label: intl({ id: 'IMPORT_EXPORT.CUSTOMER.IMPORT.TITLE' })
    },
    {
      value: 'products',
      label: intl({ id: 'IMPORT_EXPORT.PRODUCT.IMPORT.TITLE' })
    },
    ...(licenseType === "development"
      ? [{
        value: 'categories',
        label: intl({ id: 'IMPORT_EXPORT.CATEGORY.IMPORT.TITLE' })
      }]
      : []),
  ];



  const validationSchema = Yup.object().shape({
    import_select: Yup.string().required(intl({ id: 'GENERAL.REQUIRED' })),
  });


  return (
    <Card>

      <CardSubHeader title={<FormattedMessage id='IMPORT_EXPORT.IMPORT_SETTINGS' />} icon={<Icon.Settings />} />

      <CardBody>

        <Formik enableReinitialize={true} initialValues={{ import_select: "" }} validationSchema={validationSchema} onSubmit={(values) => getImportType(values.import_select)}>
          <Form className='d-flex flex-column'>
            <Field component={DropDownControl}
              {...{
                name: "import_select",
                label: intl({ id: 'IMPORT_EXPORT.IMPORT.SELECT_TYPE' }),
                options: importOptions,
                withFeedbackLabel: true,
                feedbackLabel: true,
                required: true,
              }}
            />

            <Button variant='primary' type="submit" className='svg-icon font-weight-bold mt-3' id='btn_import'>
              <FormattedMessage id="IMPORT_EXPORT.IMPORT" />
            </Button>

          </Form>
        </Formik>

      </CardBody>
    </Card>
  )
}
