/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { convertPriceCurrency } from '_metronic/_helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as UIHelpers from '../../_context/DocumentsUIHelpers';
import { Icon } from '_metronic/_icons';
import { isArray } from 'lodash';



export function RestPriceColumnFormatter(cell, row, rowIndex, { openEnterPaymentDialog, openPaymentWarningsDialog }) {
  const discount = row.pricing_summary?.discount ?? 0;
  const totalPriceDiscount = row.pricing_summary?.total_price_discount ?? 0;
  const total = +row.pricing_summary.total - (discount * row.pricing_summary?.total / 100);
  // const total = +row.pricing_summary.total;
  const shippingPrice = +row.pricing_summary.shipping_price;
  const totalPayment = total + shippingPrice;
  const totalPaid = isArray(row.entered_payment)
    ? row.entered_payment.reduce((acc, payment) => acc + +payment.amount, 0)
    : 0;
  const paymentWarningsPrice = row.payment_warnings?.reduce((acc, warning) => acc + +warning.amount, 0) ?? 0;
  const restPayment = row.payment_status === 'paid' ? 0 : (totalPayment - totalPaid + paymentWarningsPrice + totalPriceDiscount);

  const paymentWarnings = row?.payment_warnings || [];

  if (paymentWarnings.length > 0) {

    // const uniqueTypes = [];
    // paymentWarnings.forEach(item => {
    //   !uniqueTypes.includes(item.type) && uniqueTypes.push(item.type);
    // });

    const tooltip = paymentWarnings.map((type, index) => (
      <li key={index} className='text-left pb-0'>
        {UIHelpers.paymentWarnings.find(item => item.value === type.type)?.label}
      </li>
    ));


    return (
      <OverlayTrigger overlay={<Tooltip id="market-tooltip"><ul className='pl-3'>{tooltip}</ul></Tooltip>}>
        <span
          type="button"
          className={`text-decoration-none ${restPayment > 0 ? "text-danger" : ""} text-nowrap svg-icon svg-icon-sm`}
          onClick={() => openPaymentWarningsDialog(row?.id)}
        >
          <Icon.Bell className="mr-2" />
          {convertPriceCurrency(restPayment, row?.pricing_summary?.currency)}
        </span>
      </OverlayTrigger>
    );
  }


  if (restPayment > 0) {
    return (
      <span
        type="button"
        className='text-danger'
        onClick={() => openEnterPaymentDialog(row.id)}
      >
        {convertPriceCurrency(restPayment, row?.pricing_summary?.currency)}
      </span>
    );
  }

  return convertPriceCurrency(restPayment, row?.pricing_summary?.currency)
}
