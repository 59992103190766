
export const searchKeys = [
  'email',
  'phone',
  'name',
  'number',
  'company'
];


/* export const searchParamsForCustomers = function (keys, event) {
  const numericValue = parseInt(event);
  const operator = isNaN(numericValue) ? 'like' : 'eq';
  // keys = ['email', 'phone', 'name', 'id', 'company'];
  if (isArray(keys) && isLength(keys.length)) {
    const searchForKeys = keys.map(key => ({
      [key]: {
        [operator]: event,
        ...(operator === 'like' && { options: 'i' }),
      },
    }));
    return {
      or: searchForKeys,
    };
  }
  return { or: [] };
}; */