import { API } from 'constants/apiUrl';
import { paginationParams, searchParams } from '../_helpers/query-params';
import { fieldParamsForDocument, customerFieldParams, searchKeys, fieldGroupingTable, filterParams, isActiveParams, getAdjustedFields } from './params';



export const documentMiddleware = {

  createDocument: async function (access, document) {
    return await API.post(`/${access.documentModel}`, document, {
      headers: {
        Authorization: `Bearer ${access.accessToken}`,
      },
    });
  },


  updateDocument: async function (access, document) {
    return await API.put(`/${access.documentModel}/${document.id}`, document, {
      headers: {
        Authorization: `Bearer ${access.accessToken}`,
      },
    });
  },


  updateDocumentWithPatch: async function (access, id, document) {
    return await API.patch(`/${access.documentModel}/${id}`, document, {
      headers: {
        Authorization: `Bearer ${access.accessToken}`,
      },
    });
  },


  deleteDocument: async function (access, type, ids) {
    return await API.delete(`/${access.documentModel}/${type}/${ids}`, {
      headers: {
        Authorization: `Bearer ${access.accessToken}`,
      },
    });
  },


  findOneDocuments: async function (access, { where, fields, limit = 1 }) {
    return API.get(`/${access.documentModel}`, {
      params: {
        filter: { limit, where, fields },
      },
      headers: {
        Authorization: `Bearer ${access.accessToken}`,
      },
    });
  },


  getDocumentById: async function (access, id, withFieldParams, withExactFields) {
    return API.get(`/${access.documentModel}/${id}`, {
      params: {
        filter: {
          /* where: isActiveParams(access, null), */
          ...(withFieldParams === 'field_params' && {
            ...fieldParamsForDocument,
          }),
          ...(withFieldParams === 'customer_fields' && { ...customerFieldParams }),
          ...(withExactFields?.fields && { ...withExactFields }),
        },
      },
      headers: {
        Authorization: `Bearer ${access.accessToken}`,
      },
    });
  },


  getDocuments: async function (access, { search, filter, order, pagination, ids }) {
    return await API.get(`/${access.documentModel}`, {
      params: {
        filter: {
          ...paginationParams(+pagination.page, +pagination.limit),
          order: order
            ? order?.includes("country_code")
              ? [order, `customer_details.delivery_address.post_code ${order?.split(' ')[1]}`]
              : [order]
            : ['order_date DESC'],
          where: {
            ...isActiveParams(access, filter),
            ...(ids && { id: { inq: ids } }),
            ...(search && { ...searchParams(searchKeys, search) }),
            ...(filter && filterParams(filter)),
          },
          ...(ids ? fieldGroupingTable : getAdjustedFields(access.documentModel)),
        },
      },
      headers: {
        Authorization: `Bearer ${access.accessToken}`,
      },
    });
  },


  //TODO: Bu kısım müşterinin dokümanlarını getiriyor. İleride kullanılabilir.
  // getDocumentsByCustomerIdAndDocumentName: async function (access, page, limit, customerId) {
  //   return await API.get(`/${access.documentModel}`, {
  //     params: {
  //       filter: {
  //         ...paginationParams(page, limit),
  //         order: ['id DESC'],
  //         where: {
  //           customerId: customerId,
  //         },
  //       },
  //     },
  //     headers: {
  //       Authorization: `Bearer ${access.accessToken}`,
  //     },
  //   });
  // },


  getCount: async function (access, { search, filter }) {
    return await API.get(`/${access.documentModel}/count`, {
      params: {
        where: {
          ...isActiveParams(access, filter),
          ...(filter && filterParams(filter)),
          ...(search && { ...searchParams(searchKeys, search) }),
        },
      },
      headers: {
        Authorization: `Bearer ${access.accessToken}`,
      },
    });
  },


  sendDocumentWithEmail: async function (access, document) {
    return await API.post(`/users/sendEmail`, document, {
      headers: {
        Authorization: `Bearer ${access.accessToken}`,
      },
    });
  },


  printDocument: async function (accessToken, data) {
    return await API.post(`/document-print`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  createLabel: async function (access, shipper, data) {
    return await API.post(`/shipping/${access.documentModel}/${shipper}`, data, {
      headers: {
        Authorization: `Bearer ${access.accessToken}`,
      },
    });
  },


  getLabel: async function (access, id, type) {
    return await API.get(`/shipping/${id}${type ? `?type=${type}` : ''}`, {
      headers: {
        Authorization: `Bearer ${access.accessToken}`,
      },
    });
  },


  deleteLabel: async function (access, documentId, labelId) {
    return await API.delete(`/shipping/${documentId}/${labelId}`, {
      headers: {
        Authorization: `Bearer ${access.accessToken}`,
      },
    });
  },


  getUserTokens: async function (access) {
    return await API.get(`/connections`, {
      headers: {
        Authorization: `Bearer ${access.accessToken}`,
      },
    });
  },


  findUserToken: async function (access, params) {
    return await API.get(`/connections/findOne`, {
      params: {
        filter: {
          where: {
            ...params,
          },
        },
      },
      headers: {
        Authorization: `Bearer ${access.accessToken}`,
      },
    });
  },


  getAmazonPrimeOrders: async function (access, tokenId) {
    return await API.post(`/orders/getOrdersFromAmazonPrime`, { tokenId: tokenId }, {
      headers: {
        Authorization: `Bearer ${access.accessToken}`,
      },
    });
  },


  createFulfillments: async function (access, convertData) {
    return await API.post(`/create-fulfillment`, convertData, {
      headers: {
        Authorization: `Bearer ${access.accessToken}`,
      },
    });
  },


  /*   returnLabel: async function (access, shipper, data) {
      return await API.post(`/shipping-return-label/${access.documentModel}/${shipper}`, data, {
        headers: {
          Authorization: `Bearer ${access.accessToken}`,
        },
      });
    }, */

  returnLabelEmail: async function (access, documentType, documentId, shipperId) {
    return await API.get(`/shipping-email-label/${documentType}/${documentId}/${shipperId}`, {
      headers: {
        Authorization: `Bearer ${access.accessToken}`,
      },
    });
  },


  updateLabel: async function (access, data) {
    return await API.post(`/shipping-update/${access.documentModel}`, data, {
      headers: {
        Authorization: `Bearer ${access.accessToken}`,
      },
    });
  },

  createUnlabeled: async function (access, data) {
    return await API.post(`/shipping-unlabeled/${access.documentModel}`, data, {
      headers: {
        Authorization: `Bearer ${access.accessToken}`,
      },
    });
  },

  updateShippingRelation: async function (access, documentId, shipping) {
    return await API.patch(`/shipping-relation/${access.documentModel}/${documentId}`, shipping, {
      headers: {
        Authorization: `Bearer ${access.accessToken}`,
      },
    });
  },

  deletePaymentWarning: async function (access, documentId, id) {
    return await API.delete(`/payment-warning/${access.documentModel}/${documentId}/${id}`, {
      headers: {
        Authorization: `Bearer ${access.accessToken}`,
      },
    });
  },

};
