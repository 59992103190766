import React from 'react'
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Icon } from '_metronic/_icons';



export default function ShowHideButton({ dispatchProperty, toggleProperty, className, id }) {
   return (
      <OverlayTrigger overlay={<Tooltip id="bottom"><FormattedMessage id={toggleProperty ? "GENERAL.HIDE" : "GENERAL.SHOW"} /></Tooltip>}>
         <span className="cursor-pointer fs-5" onClick={dispatchProperty} id={`btn_${id}`}>
            {toggleProperty
               ? <Icon.EyeSlash className={className ?? "text-alternate"} />
               : <Icon.Eye className={className ?? "text-muted"} />
            }
         </span>
      </OverlayTrigger>
   );
}