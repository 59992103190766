
export function useGetCategories(entities) {

  const sortEntity = entities.sort((a, b) => a.name.localeCompare(b.name));
  let map = {}, node, categories = [], i;

  for (i = 0; i < sortEntity.length; i += 1) {
    map[sortEntity[i].id] = i;
    sortEntity[i].children = [];
  }
  for (i = 0; i < sortEntity.length; i += 1) {
    node = sortEntity[i];
    if (node.parent_id !== '0') {
      sortEntity[map[node.parent_id]].children.push(node);
    } else {
      categories.push(node);
    }
  }
  return categories;
}



/* export function useGetCategories() {

  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const { entities, loading } = useSelector(state => state.categories);

  entities.sort((a, b) => a.category_name.localeCompare(b.category_name));

  useEffect(() => {
    dispatch(CategoryActions.getCategories());
  }, [dispatch]);

  useEffect(() => {
    if (Array.isArray(entities) && entities.length > 0) {
      const reducedCategories = reduceCategoryLists(entities);
      setCategories(reducedCategories);
    }
  }, [entities]);

  return { categories, loading };
}
 */