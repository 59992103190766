import React from 'react';
import { toast } from "react-toastify";
import ActionTypes from "./actionTypes";
import { FormattedMessage } from "react-intl";



export class ArchiveActions {

  // Get Archive Documents
  static getArchive = (model, queryParams, dateRange) => {
    return {
      type: ActionTypes.GET_ARCHIVE,
      payload: {
        model,
        queryParams,
        dateRange
      },
    };
  };

  static getArchiveSuccess = (documents, count) => {
    return {
      type: ActionTypes.GET_ARCHIVE_SUCCESS,
      payload: {
        documents,
        count
      },
    };
  };

  static getArchiveFail = (error) => {
    return {
      type: ActionTypes.GET_ARCHIVE_FAIL,
      payload: {
        error
      },
    };
  };



  // Print Archive Document
  static printRequest = (data) => {
    return {
      type: ActionTypes.PRINT_ARCHIVE,
      payload: {
        data
      },
    };
  };

  static printRequestSuccess = (response) => {
    response?.error && toast.error(response.error);
    return {
      type: ActionTypes.PRINT_ARCHIVE_SUCCESS,
      payload: {
        response
      },
    };
  };

  static printRequestFail = (error) => {
    return {
      type: ActionTypes.PRINT_ARCHIVE_FAIL,
      payload: {
        error
      },
    };
  };


  static deleteDocument = (documentType, ids) => {
    return {
      type: ActionTypes.DELETE_ARCHIVE,
      payload: {
        documentType,
        ids,
      },
    };
  };

  static deleteDocumentSuccess = (ids) => {
    toast.success(<FormattedMessage id='ARCHIVE.REDUX.SEND_DOCUMENT.SUCCESS' />);
    return {
      type: ActionTypes.DELETE_ARCHIVE_SUCCESS,
      payload: {
        ids,
      },
    };
  };

  static deleteDocumentFail = () => {
    return {
      type: ActionTypes.DELETE_ARCHIVE_FAIL,
    };
  };

  // Clean Up Reducer
  static cleanUpReducer = (states) => {
    return {
      type: ActionTypes.CLEAN_UP_REDUCER,
      payload: {
        states
      },
    };
  };

}
