export const validator = {

  isValidSku: (cell, row, filterProduct, productVariants, intl) => {

    const isDuplicate = productVariants.some(variant => variant.sku === cell);

    const isProduct = filterProduct.some(item => {
      if (item.variants && item.variants.length > 0) {
        return item.variants.some(variant => variant.sku === cell);
      } else {
        return item.sku === cell;
      }
    });

    if (cell !== row.sku && (isDuplicate || isProduct)) {
      return {
        valid: false,
        message: intl({ id: "PRODUCT.GENERAL.SKU_WARNING" })
      };
    }

    if (!row.sku || cell === "") {
      return {
        valid: false,
        message: intl({ id: "PRODUCT.GENERAL.SKU_REQUIRED" })
      };
    }
  },


  isValidPrice: (cell, intl) => {

    if (cell.includes(",")) {
      cell = cell.replace(",", ".");
    };

    if (isNaN(cell)) {
      return {
        valid: false,
        message: intl({ id: "DOCUMENT.POSITION.IS_VALID.PRICE" })
      };
    }

    if(cell && +cell < 0) {
      return {
        valid: false,
        message: intl({ id: "PRODUCT.GENERAL.PRICE_THAN" })
      };
    }

    // en fazla iki ondalık sayı olmalı
    const decimalPart = (cell.toString().split('.')[1] || '').length;
    if (decimalPart > 2) {
      return {
        valid: false,
        message: intl({ id: "DOCUMENT.POSITION.IS_VALID.DECIMALS" })
      };
    }
  },


  isValidQuantity: (cell, unit, intl) => {

    if (cell.includes(',')) {
      cell = cell.replace(',', '.');
    };

    if (isNaN(cell)) {
      return {
        valid: false,
        message: intl({ id: "DOCUMENT.POSITION.IS_VALID.QUANTITY" })
      };
    };

    if (+cell < 0) {
      return {
        valid: false,
        message: intl({ id: "DOCUMENT.POSITION.IS_VALID.QUANTITY1" })
      };
    }
    if (+cell > 999999) {
      return {
        valid: false,
        message: intl({ id: "PRODUCT.VARIANT.IS_VALID.QUANTITY" })
      };
    }

    if (unit === 'piece' && cell % 1 !== 0) {
      return {
        valid: false,
        message: intl({ id: "PRODUCT.GENERAL.INTEGER_REQUIRED" })
      };
    }

  },


  isValidEan: (cell, intl) => {

    if (cell.length > 13) {
      return {
        valid: false,
        message: intl({ id: "PRODUCT.VARIANT.IS_VALID.EAN" })
      };
    }
  },

  isValidAsin: (cell, intl) => {

    if (cell && cell.length !== 10) {
      return {
        valid: false,
        message: intl({ id: "PRODUCT.VARIANT.IS_VALID.ASIN" })
      };
    }
  },
};
