import React from 'react'
import { Worker, Viewer } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import packagejson from "../../../../package.json";
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';



export default function PdfViewer({ url, height = "90vh" }) {

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: defaultTabs => [defaultTabs[0]],
  });


  return (
    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${packagejson.dependencies["pdfjs-dist"]}/build/pdf.worker.min.js`}>
      <div style={{ height }}>
        <Viewer fileUrl={url} plugins={[defaultLayoutPluginInstance]} />
      </div>
    </Worker>
  )
}
