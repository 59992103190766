import React from 'react';
import { ReportsUserCard } from './ReportsUserCard';



export function ReportsUserPage() {
  return (
    <>
      <ReportsUserCard />
    </>
  );
}
