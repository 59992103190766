import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useTempUserUIContext } from "../_context/TempUserUIContext";
import { AdminRoutes } from "constants/moduleRoutes";
import { SelectCounter } from "_metronic/_partials";
import { Icon } from "_metronic/_icons";



export function TempUserGrouping() {

  const history = useHistory();

  const UIContext = useTempUserUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    setIds: UIContext.setIds,
  }), [UIContext]);

  const deleteAllSelectedTempUsers = () => {
    const ids = UIProps.ids.join("&");
    history.push(AdminRoutes.DELETE_TEMP_USER_LISTS_FN(ids))
  };

  return (
    <div className="d-flex justify-content-between">

      <SelectCounter count={UIProps.ids.length} />
      <Button variant="light-primary" type="button" className="svg-icon svg-icon-sm font-weight-bold ml-auto" onClick={deleteAllSelectedTempUsers} disabled={UIProps.ids.length <= 0} id="btn_multiple_delete">
        <Icon.Trash />
        <span className="d-none d-sm-inline ml-2">
          <FormattedMessage id="GENERAL.DELETE" />
        </span>
      </Button>

    </div>
  )
}
