import { API } from 'constants/apiUrl';



export const uploadMiddleware = {

  getContainer: async function (accessToken, container) {
    return await API.get(`/containers/${container}/files`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  uploadImages: async function (accessToken, companyId, data) {
    return await API.post(`/containers/${companyId}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getImages: async function (accessToken, id) {
    return await API.get(`/containers/${id}/files`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  deleteContainer: async function (accessToken, containerName) {
    return await API.delete(`/containers/${containerName}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  deleteFile: async function (accessToken, companyId, fileType, fileId) {
    return await API.delete(`/containers/${companyId}/${fileType}/${fileId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

};
