import React, { useEffect, useMemo, useState } from 'react';
import { SearchControl } from '_metronic/_partials/controls';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { DatePickerDropdown } from '../../../../_metronic/_partials/components/DatePickerDropdown';
import { CONNECTION_SETTINGS } from 'constants/connectionSettings';
import { useCustomersUIContext } from '../_context/CustomersUIContext';
import { CustomersGrouping } from './CustomersGrouping';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { SelectCounter } from '_metronic/_partials';
import { theme } from '_metronic/_helpers';
import { Icon } from '_metronic/_icons';
import { useQuery } from 'app/hooks';



export function CustomersFilter({ setSearchQuery, setFilterQuery, connectionEntities }) {

  const query = useQuery();
  const [search, setSearchBase] = useState(query.get('search') ?? '');

  const [filterParamsBase, setFilterParamsBase] = useState({
    marketplace: null,
  });

  const UIContext = useCustomersUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    openExportCustomerDialog: UIContext.openExportCustomerDialog,
  }), [UIContext]);

  useEffect(() => {
    setSearchBase(query.get('search') ?? '');
    const marketplace = query.get('filter.marketplace');
    setFilterParamsBase(prev => ({
      ...prev,
      marketplace: marketplace
    }));
  }, [query]);

  const handleSearch = value => {
    setSearchQuery(value);
    setSearchBase(value);
  };

  const onDeleteFilterHandler = (key, value) => {
    setFilterParamsBase(prev => ({
      ...prev,
      [key]: false,
    }));
    setFilterQuery('DELETE_PARAMS', key);
  };

  const onFilterConnectionHandler = (value) => {
    setFilterQuery('marketplace', value)
    setFilterParamsBase(prev => ({
      ...prev,
      marketplace: value
    }))
  }

  const sortedTokens = CONNECTION_SETTINGS
    .filter(token => token.active && !(token.type === "shipper"))
    .sort((a, b) => {
      const typeOrder = { marketplace: 0, shop: 1, shipper: 2 };
      const typeComparison = typeOrder[a.type] - typeOrder[b.type];
      if (typeComparison === 0) {
        return a.name.localeCompare(b.name);
      }
      return typeComparison;
    });

  const connections = connectionEntities.filter(connection => connection.token_type !== 'shipper')
  // Amazon Prime connection added
  connections.push({
    name: "amazon_prime",
    label: "Amazon Prime",
    icon: <Icon.AmazonPrime />,
  });

  const filteredConnections = sortedTokens.filter(obj => connections.some(connection => connection.name === obj.name));

  // Marketplace none filter
  filteredConnections.push({
    name: 'none',
    label: <FormattedMessage id="GENERAL.FILTER.NONE_MARKET" />,
    icon: <Icon.ShopWindow />
  })

  const selectMarketplace = filteredConnections.find(i => i.name === filterParamsBase.marketplace);


  return (
    <Row className="mb-3">

      <Col md="4" className="mb-lg-0 mb-2">
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col>

      <Col md="8" className='d-flex align-items-start px-md-0'>

        <Dropdown className="dropdown-inline symbol mr-3" drop="down">
          <Dropdown.Toggle variant="outline-secondary" className='d-flex justify-content-between align-items-center font-weight-bold' style={{ height: theme.units.input.height['sm'] }} id="dropdown-basic">
            <span className="svg-icon svg-icon-sm mr-3">
              {selectMarketplace?.icon ?? <Icon.Shop />}
            </span>
            {selectMarketplace?.label ?? <FormattedMessage id="DOCUMENT.FILTER.MARKET" />}
          </Dropdown.Toggle>

          {filterParamsBase.marketplace && <i className="symbol-badge bg-danger"></i>}
          <Dropdown.Menu className="dropdown-menu-right dropdown-menu-anim-down" id="dropdown_market_options">
            <ul className="navi navi-hover">
              {filteredConnections.length
                ? <>
                  {filteredConnections?.map((opt, index) => (
                    <li className="navi-item" key={index}>
                      <Dropdown.Item className="navi-link" onClick={() => onFilterConnectionHandler(opt.name)} id={`dropdown_item_${opt.name}`}>
                        <span className="svg-icon svg-icon-md mr-2">
                          {opt.icon}
                        </span>
                        <span className="navi-text text-nowrap font-weight-bold">
                          {opt.label}
                        </span>
                      </Dropdown.Item>
                    </li>
                  ))}

                  <Dropdown.Divider />

                  <li className="navi-item">
                    <Dropdown.Item
                      className='navi-link font-weight-bolder text-danger justify-content-center bg-white py-1'
                      onClick={() => onDeleteFilterHandler('marketplace', CONNECTION_SETTINGS.find(i => i.name === filterParamsBase.marketplace)?.name)}
                      id='clear-marketplace'
                    >
                      <FormattedMessage id='GENERAL.CLEAR' />
                    </Dropdown.Item>
                  </li>
                </>
                : <li className="navi-item px-3">
                  <span className="svg-icon svg-icon-sm text-info text-hover-primary mr-2">
                    <Icon.InfoCircle />
                  </span>
                  <span className="navi-text">
                    <FormattedHTMLMessage id="DOCUMENT.SHIPPING.NO_MARKETPLACE_FILTER" values={{ url: ModuleRoutes.CONNECTIONS, a: chunks => <a href='/'>{chunks}</a> }} />
                  </span>
                </li>
              }
            </ul>
          </Dropdown.Menu>
        </Dropdown>

        <DatePickerDropdown UIContext={UIContext} setFilterQuery={setFilterQuery} />

        <SelectCounter count={UIProps.ids.length} />

        <CustomersGrouping UIProps={UIProps} />
      </Col>

    </Row>
  );
}
