import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';
import { ModuleRoutes } from 'constants/moduleRoutes';



export function ActionsColumnFormatter({ catId, catName, level, catKey }) {
  const isDisabled = catKey === "digital_product" && level === "1"
  return (
    <>
      {level < 7 &&
        <OverlayTrigger overlay={<Tooltip id="category-document-tooltip"><FormattedMessage id="CATEGORY.NEW_SUB_CATEGORY_BUTTON" /></Tooltip>}>
          <Link className="btn btn-icon btn-hover-info btn-sm" to={ModuleRoutes.NEW_CATEGORY_FN(catId, level)} id={`btn_add_${catId}`}>
            <span className="svg-icon svg-icon-md">
              <Icon.Plus />
            </span>
          </Link>
        </OverlayTrigger>
      }

      <OverlayTrigger overlay={<Tooltip id="category-edit-tooltip"><FormattedMessage id="GENERAL.EDIT" /></Tooltip>}>
        <Link className={`btn btn-icon btn-hover-primary btn-sm ${isDisabled ? 'disabled' : ''}`} to={isDisabled ? '#' : ModuleRoutes.EDIT_CATEGORY_FN(catId)} id={`btn_edit_${catId}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.PencilSquare />
          </span>
        </Link>
      </OverlayTrigger>

      <OverlayTrigger overlay={<Tooltip id="category-delete-tooltip"><FormattedMessage id="GENERAL.DELETE" /></Tooltip>}>
        <Link className={`btn btn-icon btn-hover-danger btn-sm ${isDisabled ? 'disabled' : ''}`} to={isDisabled ? '#' : ModuleRoutes.DELETE_CATEGORY_FN(catId, catName)} id={`btn_delete_${catId}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.Trash />
          </span>
        </Link>
      </OverlayTrigger>
    </>
  );
}
