import { call, put, select, takeEvery } from "redux-saga/effects";
import { settingsMiddleware, tokenErrorHandler } from "middlewares";
import { AuthActions } from "app/pages/auth/_redux/actions";
import { SettingsActions } from "./actions";
import ActionTypes from "./actionTypes";



// Create Document Settings 
function* createSettings({ payload: { userSettings } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(settingsMiddleware.create, accessToken, userSettings);
    yield put(SettingsActions.createUserSettingsSuccess(response.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, SettingsActions.createUserSettingsFail());
  }
}


// Update Document Settings
function* updateSettings({ payload: { settings, id } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(settingsMiddleware.update, accessToken, id, settings);
    yield put(SettingsActions.updateUserSettingsSuccess({ ...settings, id }));

  } catch (err) {
    yield call(tokenErrorHandler, err, SettingsActions.updateUserSettingsFail());
  }
}


// Patch Document Settings
function* patchUserSettings({ payload: { settings, id, settings_type } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(settingsMiddleware.patchUserSettings, accessToken, id, settings);
    yield put(SettingsActions.patchUserSettingsSuccess({ ...settings, id, settings_type }));

  } catch (err) {
    yield call(tokenErrorHandler, err, SettingsActions.patchUserSettingsFail());
  }
}


// Get Document By Type
function* getByType({ payload: { settings_type } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    if (["invoices", "offers", "waybills", "credits"].includes(settings_type)) {
      const resCount = yield call(settingsMiddleware.getCount, accessToken, settings_type);
      yield put(SettingsActions.getDocumentCountSuccess(resCount.data.count));
    }

    const response = yield call(settingsMiddleware.getByType, accessToken, settings_type);
    yield put(SettingsActions.getUserSettingsByTypeSuccess(...response.data));

  } catch (err) {
    if (err.response && err.response.status === 401) {
      return yield put(AuthActions.logout());
    }
    yield put(SettingsActions.getUserSettingsByTypeFail(err));
  }
}

// Get settings 
function* getSettings() {
  try {
    const accessToken = yield select(state => state.auth.access_token);
    const response = yield call(settingsMiddleware.get, accessToken);
    
    yield put(SettingsActions.getUserSettingsSuccess(response.data));

  } catch (err) {
    if (err.response && err.response.status === 401) {
      return yield put(AuthActions.logout());
    }
    yield put(SettingsActions.getUserSettingsFail(err));
  }
}



export function* settingsSaga() {
  yield takeEvery(ActionTypes.CREATE, createSettings);
  yield takeEvery(ActionTypes.UPDATE, updateSettings);
  yield takeEvery(ActionTypes.GET, getSettings);
  yield takeEvery(ActionTypes.GET_BY_TYPE, getByType);
  yield takeEvery(ActionTypes.PATCH_USER_SETTINGS, patchUserSettings);
}
