import React from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import { Prompt } from 'react-router-dom';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';



export function FormikHandler({ initialValues, btnRef, onSubmitHandler, children, autoComplete = 'off' }) {

  const { formatMessage: intl } = useIntl();

  const PromptIfDirty = () => {
    const formik = useFormikContext();
    return (
      <Prompt
        when={formik.dirty && formik.submitCount === 0}
        message={location => intl({ id: 'PRODUCT_LISTINGS.PROMPT_MESSAGE' })}
      />
    );
  };
  //FIXME: remove this
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(80, intl({ id: 'PRODUCT_LISTINGS.ARTIKEL_NAME_MAX' }))
      .required(intl({ id: 'GENERAL.REQUIRED' })),
    listingPolicies: Yup.object().shape({
      fulfillmentPolicyId: Yup.string()
        .required(intl({ id: 'GENERAL.REQUIRED' })),
      paymentPolicyId: Yup.string()
        .required(intl({ id: 'GENERAL.REQUIRED' })),
      returnPolicyId: Yup.string()
        .required(intl({ id: 'GENERAL.REQUIRED' })),
    }),
    merchantLocationKey: Yup.string()
      .required(intl({ id: 'GENERAL.REQUIRED' })),
  });

  return (
    <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitHandler}>

      <Form autoComplete={autoComplete}>
        <PromptIfDirty />
        {children}
        <button type="submit" className='d-none' ref={btnRef} />
      </Form>

    </Formik>
  );
}
