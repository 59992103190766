import React from 'react';
import ActionTypes from './actionTypes';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';



export class SupportActions {

  // Create Ticket
  static createTicket = (ticket, upload) => {
    return {
      type: ActionTypes.CREATE_TICKET,
      payload: {
        ticket,
        upload,
      },
    };
  };

  static createTicketSuccess = (ticket) => {
    toast.success(<FormattedMessage id='SUPPORT.REDUX.CREATE_TICKET.SUCCESS' />);
    return {
      type: ActionTypes.CREATE_TICKET_SUCCESS,
      payload: {
        ticket,
      },
    };
  };

  static createTicketFail = () => {
    toast.error(<FormattedMessage id='GENERAL.FAIL' />);
    return {
      type: ActionTypes.CREATE_TICKET_FAIL,
    };
  };



  // Pictures Handler
  static picturesHandler = image => {
    return {
      type: ActionTypes.PICTURES_HANDLER,
      payload: {
        image: image,
      },
    };
  };



  // Image Delete
  static deleteImage = (imageId, sessionId, ticketId) => {
    return {
      type: ActionTypes.DELETE_IMAGE,
      payload: {
        imageId,
        sessionId,
        ticketId
      },
    };
  };

  static deleteImageSuccess = (ticketId, updatedTicket) => {
    return {
      type: ActionTypes.DELETE_IMAGE_SUCCESS,
      payload: {
        ticketId,
        updatedTicket,
      },
    };
  };

  static deleteImageFail = () => {
    toast.error(<FormattedMessage id='SUPPORT.REDUX.DELETE_IMAGE.FAIL' />);
    return {
      type: ActionTypes.DELETE_IMAGE_FAIL,
    };
  };



  // Update Ticket
  static updateTicket = (ticketId, data, upload, sessionId) => {
    return {
      type: ActionTypes.UPDATE_TICKET,
      payload: {
        ticketId,
        data,
        upload,
        sessionId,
      },
    };
  };

  static updateTicketSuccess = () => {
    toast.success(<FormattedMessage id='SUPPORT.REDUX.UPDATE_TICKET.SUCCESS' />);
    return {
      type: ActionTypes.UPDATE_TICKET_SUCCESS,
    };
  };

  static updateTicketFail = () => {
    toast.error(<FormattedMessage id='SUPPORT.REDUX.UPDATE_TICKET.FAIL' />);
    return {
      type: ActionTypes.UPDATE_TICKET_FAIL,
    };
  };



  // Update Ticket Props: Status, Tag, Priority, Read
  static ticketProps = (id, data) => {
    return {
      type: ActionTypes.TICKET_PROPS,
      payload: {
        id,
        data
      },
    };
  };

  static ticketPropsSuccess = (id, updatedTicket) => {
    toast.success(<FormattedMessage id='SUPPORT.REDUX.UPDATE_TICKET.SUCCESS' />);
    return {
      type: ActionTypes.TICKET_PROPS_SUCCESS,
      payload: {
        id,
        updatedTicket,
      },
    };
  };

  static ticketPropsFail = () => {
    toast.error(<FormattedMessage id='SUPPORT.REDUX.UPDATE_TICKET.FAIL' />);
    return {
      type: ActionTypes.TICKET_PROPS_FAIL,
    };
  };



  // Delete Ticket
  static deleteTicket = (id, queryParams) => {
    return {
      type: ActionTypes.DELETE_TICKET,
      payload: {
        id,
        queryParams
      },
    };
  };

  static deleteTicketSuccess = (id) => {
    toast.success(<FormattedMessage id='SUPPORT.REDUX.DELETE_TICKET.SUCCESS' />);
    return {
      type: ActionTypes.DELETE_TICKET_SUCCESS,
      payload: {
        id
      },
    };
  };

  static deleteTicketFail = error => {
    toast.error(<FormattedMessage id='SUPPORT.REDUX.DELETE_TICKET.FAIL' values={{ error: error }} />);
    return {
      type: ActionTypes.DELETE_TICKET_FAIL,
      payload: {
        error: error,
      },
    };
  };



  // Get Tickets
  static getTickets = (dateRange, queryParams) => {
    return {
      type: ActionTypes.GET_TICKETS,
      payload: {
        dateRange,
        queryParams
      },
    };
  };

  static getTicketsSuccess = (tickets, count) => {
    return {
      type: ActionTypes.GET_TICKETS_SUCCESS,
      payload: {
        tickets,
        count,
      },
    };
  };

  static getTicketsFail = () => {
    return {
      type: ActionTypes.GET_TICKETS_FAIL,
    };
  };



  // Count Tickets
  static countTickets = () => ({
    type: ActionTypes.COUNT_TICKETS,
  });

  static countTicketsSuccess = (count) => ({
    type: ActionTypes.COUNT_TICKETS_SUCCESS,
    payload: {
      count
    },
  });

  static countTicketsFail = (error) => ({
    type: ActionTypes.COUNT_TICKETS_FAIL,
    payload: {
      error
    },
  });



  // Get Customer by id
  static getTicketById = (id) => {
    return {
      type: ActionTypes.GET_TICKET_BY_ID,
      payload: {
        id
      },
    };
  };

  static getTicketByIdSuccess = (ticket) => {
    return {
      type: ActionTypes.GET_TICKET_BY_ID_SUCCESS,
      payload: {
        ticket
      },
    };
  };

  static getTicketByIdFail = () => {
    return {
      type: ActionTypes.GET_TICKET_BY_ID_FAIL,
    };
  };


  // Clean up images
  static cleanUpImages = () => ({
    type: ActionTypes.CLEAN_UP_IMAGES,
  });


  // Clean up reducer
  static cleanUpReducer = () => ({
    type: ActionTypes.CLEAN_UP_REDUCER,
  });


}