

export const supplier = {
    main: {
        title: "SUPPLIER.TITLE",
        location: 'supplier',
        description: "USER_GUIDE.SUPPLIER",
        name: 'SUPPLIER.TITLE',
        name2: 'SUPPLIER.NEW',
    },
    new: {
        title: "SUPPLIER.NEW",
        description: "USER_GUIDE.SUPPLIER_NEW",
        name: 'SUPPLIER.TITLE'
    },
    edit: {
        title: "SUPPLIER.EDIT",
        description: "USER_GUIDE.SUPPLIER_EDIT",
        name: 'SUPPLIER.TITLE'
    },
}




//json
// {
//   "title": "Lieferanten",
//   "location": "lieferanten",
//   "keys": ["Anleitung"],
//   "iconCode":"Warning-1-circle",
//   "hilfeText":"Info",
//   "guides": [
//     {
//       "key": "Anleitung",
//       "contents": [
//         {
//           "title": "",
//           "shortDescription": "Auf dieser Seite sehen Sie die Lieferanten, die Sie in SaleVali angelegt haben",
//           "longDescription": "",
//           "icon": "",
//           "pictures": [],
//           "link": {
//             "title": "",
//             "href": "",
//             "text": ""
//           }
//         },
//         {
//           "title": "",
//           "shortDescription": "In den Aktionen, können Sie einen Lieferanten löschen oder die Information zum Lieferanten bearbeiten",
//           "longDescription": "",
//           "icon": "",
//           "pictures": [],
//           "link": {
//             "title": "",
//             "href": "",
//             "text": ""
//           }
//         },
//         {
//           "title": "",
//           "shortDescription": "Über den Button “Neuer Lieferant”, können Sie einen neuen Lieferanten anlegen",
//           "longDescription": "",
//           "icon": "",
//           "pictures": [],
//           "link": {
//             "title": "",
//             "href": "",
//             "text": ""
//           }
//         }
//       ]
//     }
//   ]
// }