import React from 'react'
import OrderListCard from './OrderListCard'
import { OrderListUIProvider } from '../_context/OrderListUIContext'


export default function OrderListPage() {
  return (
    <OrderListUIProvider>
      <OrderListCard />
    </OrderListUIProvider>
  )
}