import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { useCustomersUIContext } from '../_context/CustomersUIContext';
import { useSearchQueryContext } from '../_context/SearchQueryProvider';
import Spinner from '_metronic/_partials/loading/Spinner';
import { CustomerActions } from '../_redux/actions';
import { FormattedMessage } from 'react-intl';




export function CustomerDeleteDialog({ id, show, multiple, onHide }) {

  // Customers UI Context
  const queryContext = useSearchQueryContext();
  const UIContext = useCustomersUIContext();
  const UIProps = useMemo(() => ({
    setIds: UIContext.setIds,
    ids: UIContext.ids,
    queryParams: queryContext.queryParams,
  }), [UIContext, queryContext]);

  // Customers Redux state
  const dispatch = useDispatch();
  const loading = useSelector(state => state.customers.loading, shallowEqual);

  // if !id we should close modal
  useEffect(() => {
    !id && onHide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const deleteCustomerAction = () => {
    const newIds = multiple ? UIProps.ids : [id];
    dispatch(CustomerActions.deleteCustomer(newIds, UIProps.queryParams));
    UIProps.setIds([]);
    onHide();
  };


  return (
    <Modal show={show} onHide={onHide} size="sm" backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered>

      <Modal.Body className="text-center line-height-md h6 py-10 mb-0">
        {multiple
          ? <>
            <FormattedMessage id="CUSTOMER.DELETE_CUSTOMER.QUESTION_BODY1" />
            <span className="label label-xl label-inline label-light-danger mx-1">
              {UIProps.ids.length}
            </span>
            <FormattedMessage id="CUSTOMER.DELETE_CUSTOMER.QUESTION_BODY2" />
          </>
          : <FormattedMessage id="CUSTOMER.DELETE_CUSTOMER.QUESTION" />
        }
      </Modal.Body>

      <Modal.Footer className="flex-nowrap p-0 overflow-hidden">

        <Button variant='ios' className="border-right" onClick={onHide} id="btn_cancel_modal">
          <FormattedMessage id="GENERAL.CANCEL" />
        </Button>

        <Button variant='ios' className="text-danger" onClick={deleteCustomerAction} id="btn_delete_modal">
          <FormattedMessage id="GENERAL.DELETE" />
          <Spinner loading={loading} color="danger" />
        </Button>

      </Modal.Footer>

    </Modal>
  );
}
