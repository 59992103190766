import ActionTypes from "./actionTypes";
import { isString } from "lodash";


const INITIAL_STATE = {
  loading: false,
  printLoading: false,
  documents: [],
  countDocuments: 0,
  totalDocuments: 0,
  documentPDF: {},
};


export function archiveReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {


    // Get Archive
    case ActionTypes.GET_ARCHIVE:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_ARCHIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: payload.documents,
        countDocuments: payload.count.count,
        totalDocuments: payload.count.total,
      };

    case ActionTypes.GET_ARCHIVE_FAIL:
      return {
        ...state,
        loading: false,
        documents: [],
        countDocuments: 0,
        totalDocuments: 0,
        error: isString(payload.error)
          ? payload.error
          : JSON.stringify(payload.error),
      };



    // Print Archive
    case ActionTypes.PRINT_ARCHIVE: {
      return {
        ...state,
        printLoading: true,
      };
    }

    case ActionTypes.PRINT_ARCHIVE_SUCCESS: {
      return {
        ...state,
        printLoading: false,
        documentPDF: payload.response,
      };
    }

    case ActionTypes.PRINT_ARCHIVE_FAIL: {
      return {
        ...state,
        printLoading: false,
        error: isString(payload.error)
          ? payload.error
          : JSON.stringify(payload.error),
      };
    }


    //* update document
    case ActionTypes.DELETE_ARCHIVE:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.DELETE_ARCHIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: state.documents.filter(document => !payload.ids.includes(document.id)),
        countDocuments: state.countDocuments - payload.ids.length,
        totalDocuments: state.totalDocuments - payload.ids.length,
      };

    case ActionTypes.DELETE_ARCHIVE_FAIL:
      return {
        ...state,
        loading: false,
        error: "GENERAL.ERROR_MESSAGE",
      };


    // Clean up reducer
    case ActionTypes.CLEAN_UP_REDUCER: {
      return {
        ...state,
        ...payload.states,
      };
    }

    default:
      return state;
  }
}
