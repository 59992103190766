import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { CustomerEdit } from './customer-edit/CustomerEdit';
import { ModuleRoutes, RELATIVE_PATH } from 'constants/moduleRoutes';
import { CustomersPage } from './CustomersPage';
import { ContentRoute } from '_metronic/layout';



export default function Customer() {
  return (
    <Switch>
      <Redirect exact={true} from={RELATIVE_PATH} to={ModuleRoutes.CUSTOMER} />

      <ContentRoute path={ModuleRoutes.EDIT_CUSTOMER} component={CustomerEdit} />
      <ContentRoute path={ModuleRoutes.NEW_CUSTOMER} component={CustomerEdit} />
      <ContentRoute path={ModuleRoutes.CUSTOMER} component={CustomersPage} />

    </Switch>
  );
}
