import React, { useEffect, useMemo } from 'react';
import { streetRegExp, nameRegExp, plzRegExp, getCountryList, addressType, maxValidation, salutation, minValidation } from '_metronic/_helpers';
import { useCustomersUIContext } from 'app/modules/customers/_context/CustomersUIContext';
import { SVReactSelect, SVFormControl, RadioControl, } from '_metronic/_partials/controls';
import { useLang } from '_metronic/i18n/Metronici18n';
import { Formik, Form, Field } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';



export function AddressEditForm({ btnRef, loading }) {

  const locale = useLang();
  const { formatMessage: intl } = useIntl();

  // Address UI Context
  const UIContext = useCustomersUIContext();
  const UIProps = useMemo(() => ({
    id: UIContext.customerId,
    initialAddress: UIContext.initialAddress,
    getAddressForEditHandler: UIContext.getAddressForEditHandler,
    updateAddressHandler: UIContext.updateAddressHandler,
    addAddressHandler: UIContext.addAddressHandler,
    addressForEdit: UIContext.addressForEdit,
    hiddenInputs: UIContext.hiddenInputs,
    onHide: UIContext.closeEditAddressDialogHandler,
  }), [UIContext]);


  useEffect(() => {
    UIProps.id && UIProps.getAddressForEditHandler(UIProps.id);
  }, [UIProps, UIProps.id]);



  const AddressEditSchema = Yup.object().shape({
    type: Yup.string()
      .required(intl({ id: "ADDRESS.TYPE_REQUIRED" })),
    salutation: Yup.string(),
    name: Yup.string()
      .max(100, maxValidation("100"))
      .min(2, minValidation("2"))
      .trim(intl({ id: 'AUTH.GENERAL.BLANK_CHARACTER' }))
      .matches(nameRegExp, intl({ id: 'GENERAL.NAME_MATCHES' }))
      // .when('firma', {
      //   is: firma => firma === undefined,
      //   then: Yup.string().required(intl({ id: "ADDRESS.NAME_REQUIRED2" })),
      // })
      .required(intl({ id: "GENERAL.NAME_REQUIRED" })),
    company: Yup.string()
      .max(100, maxValidation("100"))
      .trim(intl({ id: 'AUTH.GENERAL.BLANK_CHARACTER' }))
      .matches(nameRegExp, intl({ id: "ADDRESS.COMPANY_MATCHES" })),
    address_line1: Yup.string()
      .max(150, maxValidation("150"))
      .required(intl({ id: "ADDRESS.STREET_REQUIRED" }))
      .when('country_code', {
        is: 'DE',  // Sadece country_code 'DE' olduğunda matches kontrolü yap
        then: schema => schema.matches(streetRegExp, intl({ id: "ADDRESS.STREET_MATCHES" })),
        otherwise: schema => schema,  // Diğer durumlarda matches kontrolü olmadan devam et
      }),
    address_line2: Yup.string()
      .max(150, maxValidation("150")),
    post_code: Yup.string()
      .max(10, intl({ id: "ADDRESS.MAX_CHAR" }, { name: "10" }))
      .trim(intl({ id: 'AUTH.GENERAL.BLANK_CHARACTER' }))
      .when('country_code', {
        is: 'DE', // only apply the matches validation when country_code is 'DE'
        then: Yup.string().matches(plzRegExp, intl({ id: "ADDRESS.PLZ_MATCHES" }))
      })
      .required(intl({ id: "ADDRESS.POSTCODE_REQUIRED" }))
      .typeError(intl({ id: "ADDRESS.PLZ_TYP" })),
    city: Yup.string()
      .max(50, intl({ id: "ADDRESS.MAX_CHAR" }, { name: "50" }))
      .trim(intl({ id: 'AUTH.GENERAL.BLANK_CHARACTER' }))
      // .matches(ortRegExp, intl({ id: "ADDRESS.LOCATION_MATCHES" }))
      .required(intl({ id: "ADDRESS.LOCATION_REQUIRED" })),
    country_code: Yup.string()
      .required(intl({ id: "ADDRESS.COUNTRY_REQUIRED" })),
  });


  const INPUTS = [
    {
      name: "type",
      label: "ADDRESS.TYPE",
      size: "6",
      component: RadioControl,
      options: addressType,
      required: true
    },
    {
      name: "salutation",
      label: "ADDRESS.SALUTATION",
      size: "6",
      component: RadioControl,
      options: salutation,
    },
    {
      name: "name",
      label: "GENERAL.NAME",
      size: UIProps.hiddenInputs?.includes('company') ? '12' : '6',
      required: true,
    },
    {
      name: "company",
      label: "GENERAL.COMPANY",
      size: "6",
      disabled: UIProps.hiddenInputs?.includes('company'),
    },
    {
      name: "address_line1",
      label: "ADDRESS.STREET",
      size: "6",
      required: true,
    },
    {
      name: "address_line2",
      label: "ADDRESS.SUFFIX",
      size: "6"
    },
    {
      name: "post_code",
      label: "ADDRESS.PLZ",
      size: "4",
      required: true,
    },
    {
      name: "city",
      label: "ADDRESS.CITY",
      size: "4",
      required: true,
    },
    {
      name: "country_code",
      label: "ADDRESS.COUNTRY",
      size: "4",
      component: SVReactSelect,
      options: getCountryList(locale),
      required: true,
    }
  ];

  const addAddress = values => {
    UIProps.id
      ? UIProps.updateAddressHandler(values)
      : UIProps.addAddressHandler({ ...values, id: uuidv4() })
    UIProps.onHide();
  };


  return (
    <Formik enableReinitialize={true} initialValues={UIProps.id ? UIProps.addressForEdit : UIProps.initialAddress} validationSchema={AddressEditSchema} onSubmit={values => addAddress(values)}>
      <Form>

        <Row>
          {INPUTS.map((item, i) => (
            !(item.name === "company" && UIProps.hiddenInputs?.includes('company')) &&
            <Col lg={item.size} key={i}>
              <Field component={item.component || SVFormControl}
                {...{
                  name: item.name,
                  label: intl({ id: item.label }),
                  options: item.options,
                  withFeedbackLabel: true,
                  feedbackLabel: true,
                  required: item.required,
                  disabled: loading || item.disabled,
                  // hidden: item.name === "firma" && UIProps.hiddenInputs.includes('firma'),
                }}
              />
            </Col>
          ))}
        </Row>

        <button type='submit' className='d-none' ref={btnRef} id="btn_submit" />

      </Form>
    </Formik>
  );
}