import { UIDates } from '_metronic/_helpers';
import moment from 'moment';
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';




export const LastLoginFn = (cell) => {

  if (cell) {
    return (
      <OverlayTrigger overlay={<Tooltip id="market-tooltip">{UIDates.formatDateTime(cell)}</Tooltip>}>
        <span type="button" className={`label label-lg label-inline label-rounded ${moment().diff(cell, 'days', true) > 30 ? 'label-outline-danger' : 'label-outline-success'}`}>
          {moment(cell).fromNow()}
        </span>
      </OverlayTrigger>
    )
  }
  return <></>
}
