import React from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Portal } from "react-portal";



export function ServerMessageDialog({ message, show, onHide }) {
  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>

        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="GENERAL.TEAM" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FormattedMessage id={message} />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="light" onClick={onHide} id="btn_close">
            <FormattedMessage id="GENERAL.CLOSE" />
          </Button>
        </Modal.Footer>

      </Modal>
    </Portal>
  );
}
