
export const userSettings = {
    main: {
        title: "MENU.ASIDE.SETTING.DOCUMENT_SETTINGS",
        location: 'settings',
        description: "USER_GUIDE.SETTINGS"
    },
    company: {
        title: "GENERAL.COMPANY",
        description: "USER_GUIDE.SETTINGS.COMPANY"
    },
    category: {
        title: "PRODUCT.CATEGORIES",
        description: "USER_GUIDE.SETTINGS.CATEGORY"
    },
    invoices: {
        title: "DOCUMENT.INVOICE_TITLE",
        description: "USER_GUIDE.SETTINGS.INVOICE"
    },
    orders: {
        title: "DOCUMENT.ORDER_TITLE",
        description: "USER_GUIDE.SETTINGS.ORDER"
    },
    offers: {
        title: "DOCUMENT.OFFER_TITLE",
        description: "USER_GUIDE.SETTINGS.OFFER"
    },
    waybills: {
        title: "DOCUMENT.WAYBILL_TITLE",
        description: "USER_GUIDE.SETTINGS.WAYBILL"
    },
    credits: {
        title: "DOCUMENT.CREDIT_TITLE",
        description: "USER_GUIDE.SETTINGS.CREDIT"
    },
    product: {
        title: "PRODUCT.TITLE",
        description: "USER_GUIDE.SETTINGS.PRODUCT"
    },
    login: {
        title: "AUTH.GENERAL.ACCOUNT_SETTINGS",
        description: "USER_GUIDE.SETTINGS.LOGIN"
    }
}