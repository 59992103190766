import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';



export const useLoadingHandler = (actionLoading, clearLoading, timeout = 6000) => {

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    if (!actionLoading) {
      return setIsLoading(false);
    }

    const effect = setTimeout(() => {
      if (clearLoading) {
        dispatch(clearLoading);
      }
      setIsLoading(false);
      process.NODE_ENV !== 'production' && console.warn(`[useLoadingHandler] timeout: ${timeout}ms`);
    }, timeout);

    return () => {
      clearTimeout(effect);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionLoading, dispatch, timeout]);

  return isLoading;
};
