/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function ActionsColumnFormatter(cellContent, row, rowIndex, { openEditVariantDialog, onDeleteVariant, loading }) {
  return (
    <>
      {openEditVariantDialog && (
        <OverlayTrigger overlay={<Tooltip id="specs-edit-tooltip"><FormattedMessage id="GENERAL.EDIT" /></Tooltip>}>
          <Button variant='icon' size='sm' className="btn-hover-primary" onClick={() => openEditVariantDialog(row.id)} disabled={loading}>
            <span className="svg-icon svg-icon-md">
              <Icon.PencilSquare />
            </span>
          </Button>
        </OverlayTrigger>
      )}

      <OverlayTrigger overlay={<Tooltip id="spec-delete-tooltip"><FormattedMessage id="GENERAL.DELETE" /></Tooltip>}>
        <Button variant='icon' size='sm' className="btn-hover-danger" onClick={() => onDeleteVariant(row.id)} disabled={loading}>
          <span className="svg-icon svg-icon-md">
            <Icon.Trash />
          </span>
        </Button>
      </OverlayTrigger>
    </>
  );
}