import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useDocumentsUIContext } from 'app/modules/documents/_context/DocumentsUIContext';
import { DocumentActions } from 'app/modules/documents/_redux/actions';
import Spinner from '_metronic/_partials/loading/Spinner';



export function SendEmailDialog() {

  const dispatch = useDispatch();

  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showSendEmailLabel,
    onHide: UIContext.closeSendEmailLabelDialog,
    documentId: UIContext.documentId,
    shipperId: UIContext.shipperId,
    documentType: UIContext.requestedDocument?.type,
  }), [UIContext]);

  const { documentForEdit, loading } = useSelector(state => ({
    documentForEdit: state.documents.documentForEdit,
    loading: state.documents.loading,
  }), shallowEqual);


  useEffect(() => {
    if (!loading) {
      UIProps.onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loading]);


  const userNameEmail = documentForEdit.customer_details?.name + " " + documentForEdit.customer_details?.email;

  return (
    <Modal show={UIProps.show} onHide={UIProps.onHide} size="sm" backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered>

      <Modal.Body className="text-center line-height-md h6 py-10 mb-0">
        <FormattedMessage id="DOCUMENT.SHIPPING.EMAIL_LABEL" values={{ name: userNameEmail }} />
      </Modal.Body>

      <Modal.Footer className="flex-nowrap p-0 overflow-hidden">

        <Button variant='ios' className="border-right" onClick={UIProps.onHide} id="btn_cancel_modal">
          <FormattedMessage id="GENERAL.CANCEL" />
        </Button>

        <Button variant='ios' className="text-success" onClick={() => dispatch(DocumentActions.returnLabelEmail( UIProps.documentType, UIProps.documentId, UIProps.shipperId))} id="btn_delete_modal">
          <FormattedMessage id="GENERAL.SEND_EMAIL" />
          <Spinner loading={loading} color="danger" />
        </Button>

      </Modal.Footer>

    </Modal>
  );
}


