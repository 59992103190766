import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardSubHeader, SVReactSelectSearch } from '_metronic/_partials/controls';
import { useDocumentsUIContext } from '../../_context/DocumentsUIContext';
import { CustomerActions } from '../../../customers/_redux/actions';
import { DocumentActions } from '../../_redux/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row, Button } from 'react-bootstrap';
import { Field, useFormikContext } from 'formik';
import { Icon } from '_metronic/_icons';
import { isArray } from 'lodash';



export function CustomerSelectCard({ loading }) {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();

  const [onInputValue, setOnInputValue] = useState('');
  const [customerOptions, setCustomerOptions] = useState([]);

  const { values: { customerId } } = useFormikContext();
  const { customers, newCustomer } = useSelector(state => ({
    newCustomer: state.customers.entities,
    customers: state.documents.customers
  }));


  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    handleOpenCustomerEditDialog: UIContext.handleOpenCustomerEditDialog
  }), [UIContext.handleOpenCustomerEditDialog]);

  useEffect(() => {
    dispatch(CustomerActions.resetFormHandler())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customerId) {
      dispatch(DocumentActions.getCustomer(customerId));
    }

    if (newCustomer?.[0]?.id) {
      dispatch(DocumentActions.getCustomer(newCustomer?.[0]?.id))
    }
  }, [dispatch, customerId, newCustomer]);


  useEffect(() => {

    let getCustomersWithEvent;

    if (onInputValue.length >= 2) {
      getCustomersWithEvent = setTimeout(() => dispatch(DocumentActions.getCustomers(onInputValue)), 350);
    } else { setCustomerOptions([]) };

    return () => clearTimeout(getCustomersWithEvent);
  }, [dispatch, onInputValue]);

  useMemo(() => {
    if (isArray(customers)) {
      const customerOptions = customers.map(item => {
        const { number, name, company, email } = item;
        const label = [number, name, company, email];
        return {
          value: item.id,
          label: label.filter(Boolean).join(' - '),
        };
      });
      setCustomerOptions(customerOptions);
    }
  }, [customers]);


  return (
    <Card className="gutter-b">

      <CardSubHeader icon={<Icon.Search />} title={<FormattedMessage id="DOCUMENT.CUSTOMER.SELECT_LABEL" />}>

        <Button variant="outline-primary" size='sm' className='d-flex svg-icon svg-icon-sm ml-2' onClick={UIProps.handleOpenCustomerEditDialog} id="btn_select">
          <Icon.Plus />
          <span className="d-none d-sm-inline font-weight-bold ml-2">
            <FormattedMessage id="DOCUMENT.CUSTOMER.SELECT_CREATE" />
          </span>
        </Button>

      </CardSubHeader>

      <CardBody>

        <Row className='justify-content-center'>
          <Col lg={8}>
            <Field component={SVReactSelectSearch}
              {...{
                name: "customerId",
                options: customerOptions,
                inputValue: onInputValue,
                onInputChange: value => setOnInputValue(value),
                placeholder: intl({ id: "DOCUMENT.CUSTOMER.SELECT_WARNING" }),
                onKeyDown: e => e.key === 'Enter' && e.preventDefault(),
                isLoading: loading,
                className: 'mb-0'
              }}
            />
          </Col>
        </Row>

      </CardBody>

    </Card>
  );
}
