import AmazonForm from './marketplaces/AmazonForm'
import Check24Form from './marketplaces/Check24Form'
import EbayForm from './marketplaces/EbayForm'
import HoodForm from './marketplaces/HoodForm'
import EtsyForm from './marketplaces/EtsyForm'
import KauflandForm from './marketplaces/KauflandForm'
import ManomanoForm from './marketplaces/ManomanoForm'
import OttoForm from './marketplaces/OttoForm'
import ShopifyForm from './shops/ShopifyForm'
import PrestashopForm from './shops/PrestashopForm'
import ShopwareForm from './shops/Shopware5Form'
import Shopware6Form from './shops/Shopware6Form'
import WoocommerceForm from './shops/WoocommerceForm'
import DeutschePostForm from './shippers/deutsche-post/DeutschePostForm'
import GlsForm from './shippers/gls/GlsForm'
import DhlForm from './shippers/dhl/DhlForm'
import DhlNewForm from './shippers/new-dhl/DhlNewForm'
import DpdForm from './shippers/dpd/DpdForm'



export const formComponents = {
  amazon: { component: AmazonForm },
  check24: { component: Check24Form },
  ebay: { component: EbayForm },
  etsy: { component: EtsyForm },
  hood: { component: HoodForm },
  kaufland: { component: KauflandForm },
  manomano: { component: ManomanoForm },
  otto: { component: OttoForm },
  prestashop: { component: PrestashopForm },
  shopify: { component: ShopifyForm },
  shopware5: { component: ShopwareForm },
  shopware6: { component: Shopware6Form },
  woocommerce: { component: WoocommerceForm },
  deutsche_post: { component: DeutschePostForm },
  dhl: { component: DhlForm },
  dhl_new: { component: DhlNewForm },
  dpd: { component: DpdForm },
  gls: { component: GlsForm },
};

