import React from "react";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import { Button } from "react-bootstrap";
import { DeleteButton } from "_metronic/_partials";
import { FormattedMessage, useIntl } from "react-intl";
import { convertPriceCurrency } from "_metronic/_helpers";
import { Card, CardBody, CardSubHeader } from "_metronic/_partials/controls";
import { ConnectionsActions } from "../../../_redux/actions";
import { BlankMessage } from "_metronic/_partials/components";
import { Icon } from '_metronic/_icons';



export function DeutschePostProductList({ show }) {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl()

  const { deutschePostForEdit } = useSelector(state => ({
    deutschePostForEdit: state.connections.deutschePostForEdit,
  }));

  const COLUMNS = [
    {
      dataField: "id",
      text: intl({ id: "PRODUCT.ID" }),
      headerClasses: "text-center pl-10",
      classes: "font-weight-bold text-center pl-10",
      headerStyle: { minWidth: '50px', width: "6%" },
    },
    {
      dataField: "name",
      text: intl({ id: "GENERAL.NAME" }),
      classes: "font-weight-bolder",
    },
    {
      dataField: "price",
      text: intl({ id: "GENERAL.PRICE" }),
      headerClasses: "text-right pr-10",
      classes: "font-weight-bolder text-right pr-10",
      formatter: (cell, row) => convertPriceCurrency(+row.price / 100),
    },
    {
      dataField: "action",
      text: intl({ id: "GENERAL.ACTIONS" }),
      style: { width: "100px" },
      headerClasses: "text-center pr-10",
      classes: "text-center pr-10",
      formatter: (cell, row) => <DeleteButton onClick={() => deleteSubscriptions(row.id)} id={row.id} />,
    },
  ];

  const deleteSubscriptions = (productId) => {
    const updatedProductList = {
      ...deutschePostForEdit,
      deutsche_post: {
        ...deutschePostForEdit.deutsche_post,
        product_list: deutschePostForEdit.deutsche_post.product_list.filter((sub) => sub.id !== productId),
      }
    };
    dispatch(ConnectionsActions.updateSettings(updatedProductList));
  }


  return (
    <Card className="gutter-b">

      <CardSubHeader icon={<Icon.List />} title={<FormattedMessage id="CONNECTIONS.SHIPPER.D_POST.PRODUCT_LIST" />}>
        <Button variant='outline-primary' size='sm' className="svg-icon svg-icon-sm" onClick={show} id="btn_dp">
          <Icon.Plus />
          <span className="d-none d-sm-inline font-weight-bold ml-2">
            <FormattedMessage id="CONNECTIONS.SHIPPER.D_POST.ADD_PRODUCT" />
          </span>
        </Button>
      </CardSubHeader>

      <CardBody className="pt-0 px-0">

        {deutschePostForEdit.deutsche_post.product_list.length === 0
          ? <BlankMessage
            icon={<Icon.List />}
            title={<FormattedMessage id='CONNECTIONS.SHIPPER.D_POST.BLANK_MESSAGE_TITLE' />}
            message={<FormattedMessage id='CONNECTIONS.SHIPPER.D_POST.BLANK_MESSAGE' />}
            arrow
          />
          : <BootstrapTable
            keyField="id"
            data={deutschePostForEdit?.deutsche_post?.product_list || []}
            columns={COLUMNS}
            wrapperClasses="table-responsive"
            headerClasses="bg-info-o-10"
            classes="table table-head-custom table-vertical-center mb-0"
            bootstrap4
            bordered={false}
            condensed
          />}

      </CardBody>

    </Card>
  );
}