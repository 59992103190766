import React from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { sizePerPageList } from '_metronic/_helpers';



export function RemotePaginationToolbar({ entitiesCount, setQueryParams, queryParams, totalCount = 0, countForPagination }) {

  let newSizePerPageList = sizePerPageList.filter(size => size.value <= (totalCount > 50 ? 100 : Math.ceil(totalCount / 10) * 10))

  // if (countForPagination) {
  //   if (countForPagination > 100) {
  //     newSizePerPageList = newSizePerPageList.filter(x => x.value < countForPagination);
  //     newSizePerPageList.push({ text: 'Alle anzeigen', value: countForPagination });
  //   }
  // }

  const onSizeChange = pageSize => {
    setQueryParams({ page: 1, limit: +pageSize });
  };


  return (
    <>
      {entitiesCount !== 0 &&

        <div className="d-flex align-items-center mt-3 ml-md-auto ml-sm-0">

          <Form.Control as="select" size="sm" className="font-weight-bolder border-0 rounded bg-light mr-3 px-3" onChange={e => onSizeChange(e.target.value)} value={queryParams.limit}>
            {newSizePerPageList.map(item => (
              <option className={`btn ${queryParams.limit === `${item.value}` ? 'active' : ''}`} value={item.value} key={item.text}>
                {item.text}
              </option>
            ))}
          </Form.Control>

          <label className="label label-lg label-inline label-light font-weight-bolder text-nowrap px-4 py-5">
            <FormattedMessage id="GENERAL.AMOUNT" />: {totalCount}
          </label>

        </div>
      }
    </>
  );
}
