import React from 'react'
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl'
import { Icon } from '_metronic/_icons';



export default function UserGuideInfo({ guide }) {
  const { formatMessage: intl } = useIntl();
  return (
    <div className='bg-info-o-10'>

      <div className='px-8 py-4 mt-3'>

        <h5 className='d-flex align-items-center border-bottom py-4 mb-3'>
          <Icon.InfoCircle className='mr-3' />
          <FormattedMessage id={guide.title} />
        </h5>

        <ul className="alert-text text-dark-75 line-height-xl pl-4 mb-0">
          {intl({ id: guide.description }).split("#").map((item, i) => ["href=", "<b>"].some(el => item.includes(el))
            ? <li className="font-weight-bold text-muted mb-3" key={i}>
              <FormattedHTMLMessage id={item} values={{ what: 'react-intl', a: chunks => <a href='/'>{chunks}</a>, b: chunks => <strong>{chunks}</strong> }} />
            </li>
            : <li className="font-weight-bold text-muted mb-3" key={i}>
              <span className="text-muted">
                {item}
              </span>
            </li>
          )}
        </ul>

      </div>
    </div>
  )
}