import ActionTypes from './actionTypes';



const INITIAL_STATE = {
  loading: false,
  success: false,
  isLogin: false,
  message: '',
  user: {
    id: '',
    name: '',
    surname: '',
    email: '',
    new_email: null,
    status: '',
    language: '',
    role: '',
    cron: true,
  },
  license: {
    type: '',
    updated_at: ''
  },
  access_token: '',
  request: {
    loading: false,
    status: '', // Enum: ('fulfilled', 'failed')
    where: '',
    error: null,
  },
  contract: '',
  counts: {},
};




export function authReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {


    // Login
    case ActionTypes.LOGIN:
      return {
        ...state,
        request: {
          loading: true,
          where: 'login',
          error: null,
        },
      };

    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isLogin: true,
        ...payload.data,
        request: {
          loading: false,
          status: 'fulfilled',
          where: 'login',
          error: null,
        },
      };

    case ActionTypes.LOGIN_FAIL:
      const { error } = JSON.parse(payload?.error);
      return {
        ...state,
        isLogin: false,
        access_token: "",
        request: {
          loading: false,
          status: 'failed',
          where: 'login',
          error: error?.message,
        },
      };



    // Get User Data
    case ActionTypes.GET_USER_DATA:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_USER_DATA_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          status: payload.user.status,
        },
        loading: false,
      };

    case ActionTypes.GET_USER_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };



    // Logout
    case ActionTypes.LOGOUT:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.LOGOUT_SUCCESS:
      return INITIAL_STATE;

    case ActionTypes.LOGOUT_FAIL:
      return INITIAL_STATE;



    // Register
    case ActionTypes.REGISTER:
      return {
        ...state,
        request: {
          loading: true,
          where: 'Register',
          error: null,
        },
      };

    case ActionTypes.REGISTER_SUCCESS:
      return {
        ...state,
        request: {
          loading: false,
          status: 'fulfilled',
          where: 'Register',
          error: null,
        },
      };

    case ActionTypes.REGISTER_FAIL:
      return {
        ...state,
        request: {
          loading: false,
          status: 'failed',
          where: 'Register',
          error: payload.errorType === "server"
            ? payload.error.message
            : payload.errorType === 'validation'
              ? 'AUTH.REGISTER.VALIDATION_ERROR_MESSAGE'
              : 'AUTH.REGISTER.ERROR_MESSAGE',
        },
      };



    // Email Verification
    case ActionTypes.EMAIL_VERIFICATION:
      return {
        ...state,
        request: {
          loading: true,
          where: 'emailVerification',
          error: null,
        },
      };

    case ActionTypes.EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        request: {
          loading: false,
          status: 'fulfilled',
          where: 'emailVerification',
          error: null,
          message: payload.message,
        },
      };

    case ActionTypes.EMAIL_VERIFICATION_FAIL:
      return {
        ...state,
        request: {
          loading: false,
          status: 'failed',
          where: 'emailVerification',
          error: payload.error,
        },
      };



    // Reset password
    case ActionTypes.RESET_PASSWORD:
      return {
        ...state,
        request: {
          loading: true,
          where: 'resetPassword',
          error: null,
        },
      };

    case ActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        request: {
          loading: false,
          status: 'fulfilled',
          where: 'resetPassword',
          error: null,
        },
      };

    case ActionTypes.RESET_PASSWORD_FAIL:
      return {
        ...state,
        request: {
          loading: false,
          status: 'failed',
          where: 'resetPassword',
          error: payload.error,
        },
      };



    // Re-Create password
    case ActionTypes.RE_CREATE_PASSWORD:
      return {
        ...state,
        request: {
          loading: true,
          where: 'reCreatePassword',
          error: null,
        },
      };

    case ActionTypes.RE_CREATE_PASSWORD_SUCCESS:
      return {
        ...state,
        request: {
          ...state.request,
          loading: false,
          where: 'reCreatePassword',
          status: 'fulfilled',
          error: null,
        },
      };

    case ActionTypes.RE_CREATE_PASSWORD_FAIL:
      return {
        ...state,
        request: {
          ...state.request,
          loading: false,
          where: 'reCreatePassword',
          status: 'failed',
          error: payload?.error?.message ?? 'Something went wrong',
        },
      };



    // Update user
    case ActionTypes.UPDATE_USERS:
      return {
        ...state,
        request: {
          loading: true,
          where: payload.actionId,
          error: null,
        },
      };

    case ActionTypes.UPDATE_USERS_SUCCESS:
      return {
        ...state,
        request: {
          ...state.request,
          loading: false,
          status: 'fulfilled',
        },
      };

    case ActionTypes.UPDATE_USERS_FAIL:
      return {
        ...state,
        request: {
          ...state.request,
          loading: false,
          status: 'failed',
          error: payload?.error,
        },
      };



    // Login settings 
    case ActionTypes.LOGIN_SETTINGS:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case ActionTypes.LOGIN_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        user: {
          ...state.user,
          new_email: payload?.new_email,
        },
      };

    case ActionTypes.LOGIN_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
      };



    // Change Password
    case ActionTypes.CHANGE_PASSWORD:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case ActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        message: payload.message,
        loading: false,
        success: true,
      };

    case ActionTypes.CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
      };



    // Change Email settings 
    case ActionTypes.CHANGE_EMAIL:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case ActionTypes.CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          new_email: payload?.new_email,
        },
        message: payload.message,
        loading: false,
        success: true,
      };

    case ActionTypes.CHANGE_EMAIL_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
      };



    // Contract
    case ActionTypes.CONTRACT:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.CONTRACT_SUCCESS:
      return {
        ...state,
        loading: false,
        contract: payload.data.content,
      };

    case ActionTypes.CONTRACT_FAIL:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };



    // Delete Request
    case ActionTypes.DELETED_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case ActionTypes.DELETED_REQUEST_SUCCESS:
      return {
        ...state,
        message: payload.message,
        loading: false,
        success: true,
      };

    case ActionTypes.DELETED_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
      };



    // Get Counts
    case ActionTypes.GET_COUNTS_SUCCESS:
      return {
        ...state,
        counts: payload.data,
      };

    // Clear loading effects
    case ActionTypes.CLEAR_LOADING_EFFECTS:
      return {
        ...state,
        loading: false,
        request: {
          loading: false,
          status: '',
          where: '',
          error: null,
        },
      };



    // Clean up reducer effects
    case ActionTypes.CLEAN_UP_REDUCER:
      return {
        ...state,
        loading: false,
        success: false,
        message: "",
        counts: {},
        request: {
          loading: false,
          status: '',
          where: '',
          error: null,
        },
      };



    default:
      return state;
  }
}
