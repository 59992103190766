import React from 'react'
import { Card, CardSubHeader, CardBody } from '_metronic/_partials/controls';
import { FormattedMessage } from 'react-intl';
import { SpecialForm } from './SpecialForm';
import { Icon } from '_metronic/_icons';



export function SpecialCard({ loading }) {
  return (
    <Card className="card-stretch">

      <CardSubHeader icon={<Icon.PersonBounding />} title={<FormattedMessage id='CUSTOMER.SPECIAL_TITLE' />} />

      <CardBody>
        <SpecialForm loading={loading} />
      </CardBody>

    </Card>
  )
}
