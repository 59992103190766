import React, { useContext, createContext, useState, useCallback } from "react";
import { useSearchQuery } from "app/hooks";


const SearchQueryContext = createContext();
export const useSearchQueryContext = () => useContext(SearchQueryContext);


export function SearchQueryProvider({ children }) {

  // query params
  const [queryParams, setQueryBase] = useState({
    pagination: {
      page: 1,
      limit: 10,
    },
    order: 'updated_at DESC',
    search: '',
    filter: { doc_type: 'orders' }
  });

  const setQueryParams = useCallback((params) => setQueryBase(params), [])

  const { setSearchQuery, setFilterQuery, setPaginationQuery, setOrderQuery } = useSearchQuery(setQueryParams);

  const value = {
    queryParams,
    setSearchQuery,
    setFilterQuery,
    setPaginationQuery,
    setOrderQuery,
  }

  return (
    <SearchQueryContext.Provider value={value}>
      {children}
    </SearchQueryContext.Provider>
  )
}