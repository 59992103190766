import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ReportActions } from "../_redux/actions";
import { Card, CardBody, CardHeader } from "_metronic/_partials/controls";
import { useEmailLogsUIContext } from "../_context/EmailLogsUIContext";
import { EmailLogFilter } from "./email-log-filter/EmailLogFilter";
import { UserGuideHelper } from "app/user-guides/UserGuideHelper";
import { EmailLogsTable } from "./email-log-table/EmailLogsTable";
import { BlankMessage } from "_metronic/_partials/components";
import { FormattedMessage } from "react-intl";
import { Icon } from "_metronic/_icons";



export default function EmailLogsCard() {

  const dispatch = useDispatch();

  const UIContext = useEmailLogsUIContext();
  const UIProps = useMemo(() => ({
    queryParams: UIContext.queryParams,
    setFilterQuery: UIContext.setFilterQuery,
    setOrderQuery: UIContext.setOrderQuery,
    setPaginationQuery: UIContext.setPaginationQuery,
    setSearchQuery: UIContext.setSearchQuery,
    changeDateRange: UIContext.changeDateRange,
    dateRange: UIContext.dateRange,
    openPrintDocumentDialog: UIContext.openPrintDocumentDialog,
    handleOpenSendEmailDialog: UIContext.handleOpenSendEmailDialog,
    openPrintLabelsDialog: UIContext.openPrintLabelsDialog,
    setIds: UIContext.setIds,
    setReminder: UIContext.setReminder,

  }), [UIContext]);

  useEffect(() => {

    let delayDebounceFn;
    if (UIProps.queryParams?.search) {
      delayDebounceFn = setTimeout(() => {
        dispatch(ReportActions.getEmailLogs(UIProps.dateRange, UIProps.queryParams));
      }, 350);
    } else {
      dispatch(ReportActions.getEmailLogs(UIProps.dateRange, UIProps.queryParams));
    };

    return () => {
      clearTimeout(delayDebounceFn)
    };
  }, [dispatch, UIProps.queryParams, UIProps.dateRange]);


  const { loading, entities, count, totalCount } = useSelector(state => ({
    loading: state.reports.loading,
    entities: state.reports.entities,
    count: state.reports.count,
    totalCount: state.reports.totalCount,
  }), shallowEqual);

  return (
    <Card className="card-height">

      <CardHeader
        icon={<Icon.EnvelopeAt />}
        title={<FormattedMessage id='REPORT.EMAIL_LOG.CARD_TITLE' />}
        info={<UserGuideHelper />}
        className="display-5"
        id='title_email_logs_list'
      >

      </CardHeader>

      <CardBody>
        {totalCount === 0 && !loading && UIProps.queryParams.search === ''
          ? <BlankMessage
            icon={<Icon.EnvelopeAt />}
            title={<FormattedMessage id='REPORT.EMAIL_LOG.BLANK_MESSAGE_TITLE' />}
            message={<FormattedMessage id='REPORT.EMAIL_LOG.BLANK_MESSAGE' />}
            className='min-50-height'
            loading={loading}
          />
          : <>
            <EmailLogFilter UIProps={UIProps} />
            <EmailLogsTable
              UIProps={UIProps}
              entities={entities}
              loading={loading}
              count={count}
            />
          </>
        }
      </CardBody>

    </Card>
  )
}
