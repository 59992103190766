import React from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { Portal } from 'react-portal';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import { CloseButton } from '_metronic/_partials';



export function EditErrorDialog({ show, onHide, errors }) {
  return (
    <Portal node={document && document.getElementById('modal-portal')}>
      <Modal show={show} backdrop="static" keyboard={false} aria-labelledby="error-contained-modal-title-vcenter" size={'md'} centered>

        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="GENERAL.WARNING" />
          </Modal.Title>
          <CloseButton onClick={onHide} />
        </Modal.Header>

        <Modal.Body>
          {errors.map((error, i) => (
            <Alert variant='light-danger' className="alert-custom svg-icon fade show mb-5" role="alert" key={i}>
              <div className="alert-text">
                <Icon.ExclamationTriangle className="mr-2" />
                <b>{error.field}:</b> {error.message}
              </div>
            </Alert>
          ))}
        </Modal.Body>

      </Modal>
    </Portal>
  );
}