import React from "react";
import { FormattedMessage } from 'react-intl';
import { barcodeRegExp, regexCategoryPattern, regexFeaturePattern, regexImgPattern, regexVariantPattern, upcRegExp } from "_metronic/_helpers";



export const checkImportProduct = (values, checkData) => {

  const errors = [];
  const createError = (field, message, required = true) => {
    errors.push({ field, message, required });
  };

  for (let i = 0; i < values.length; i++) {

    const {
      name, sku, brand, isVariant, variantValue, variantName, variantSku,
      quantity, purchase_price, gross_price, currency, tax,
      ean, barcode, isbn, gtin, mpn, upc, asin,
      weight, width, height, length,
      description, path, unit,
      img1, img2, img3, img4, img5, img6, img7, img8, img9, img10,
      features1, features2, features3, features4, features5, features6,
    } = values[i];


    if (!name || name?.length > 250) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="PRODUCT.GENERAL.NAME.MAX_LENGTH" />,);
    };

    const checkSku = checkData.includes(sku);

    if (!sku) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="PRODUCT.GENERAL.SKU_REQUIRED" />,);
    };
    if (sku?.length > 50) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_SKU" />,);
    };
    if (checkSku) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="PRODUCT.GENERAL.SKU_WARNING" />,);
    };

    // check variant
    if (!isVariant) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_VARIANT.MISSING" />);
    } else if (+isVariant === 0) {

      if (variantValue) {
        createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_VARIANT.VALUE_MISSING" />);
      }
      if (variantName) {
        createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_VARIANT.NAME_MISSING" />);
      }
      if (variantSku) {
        createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="PRODUCT.GENERAL.SKU_REQUIRED" />);
      }
    } else if (+isVariant === 1) {

      if (!regexVariantPattern.test(variantValue)) {
        createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_VARIANT.VALUE_IS_VARIANT" />);
      }
      if (!regexVariantPattern.test(variantName)) {
        createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_VARIANT.NAME_IS_VARIANT" />);
      }
      if (!variantSku) {
        createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="PRODUCT.GENERAL.SKU_REQUIRED" />);
      }
      if (variantSku?.length > 50) {
        createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_SKU" />);
      }
    }

    if (brand?.length > 200) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_BRAND" />);
    };

    // check pricing
    if (!quantity || +quantity < 0 || +quantity >= 1000000) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_QUANTITY" />);
    };

    if (purchase_price && (+purchase_price < 0 || +purchase_price >= 1000000)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_COST_PRICE" />);
    };

    if (gross_price && (+gross_price < 0 || +gross_price >= 1000000)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_GROSS_PRICE" />);
    };
    if (currency?.length !== 3) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_CURRENCY" />);
    }

    if (!tax || +tax < 0 || +tax > 100) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_TAX" />);
    }

    // check identities
    if (ean && ean?.length > 13) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_EAN" />);
    };

    if (asin && asin?.length !== 10) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_ASIN" />);
    };

    if (barcode && !barcodeRegExp.test(barcode)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_BARCODE" />);
    };

    if (isbn && (isbn?.length < 10 || isbn?.length > 13)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_ISBN" />);
    };

    if (gtin && gtin?.length > 59) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_GTIN" />);
    };

    if (mpn?.length > 50) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_MPN" />);
    };

    if (upc && !upcRegExp.test(upc)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_UPC" />);
    };

    // check dimension
    if (length && (+length < 0 || +length > 300)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_DEPTH" />);
    };

    if (width && (+width < 0 || +width > 500)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_WIDTH" />);
    };

    if (height && (+height < 0 || +height > 300)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_HEIGHT" />);
    };

    if (weight && (+weight < 0 || +weight > 1000000)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_WEIGHT" />);
    };

    // check description
    if (description?.length > 1000000) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_DEPTH" />);
    };

    // check category
    if (path && !regexCategoryPattern.test(path)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_CATEGORY_PATH" />);
    };

    // check unit
    if (unit && (unit !== "weight" && unit !== "piece")) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_UNIT" />);
    };

    // check img
    if (img1 && !regexImgPattern.test(img1)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_PICTURE" values={{ number: '1' }} />);
    };

    if (img2 && !regexImgPattern.test(img2)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_PICTURE" values={{ number: '2' }} />);
    };

    if (img3 && !regexImgPattern.test(img3)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_PICTURE" values={{ number: '3' }} />);
    };

    if (img4 && !regexImgPattern.test(img4)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_PICTURE" values={{ number: '4' }} />);
    };

    if (img5 && !regexImgPattern.test(img5)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_PICTURE" values={{ number: '5' }} />);
    };

    if (img6 && !regexImgPattern.test(img6)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_PICTURE" values={{ number: '6' }} />);
    };

    if (img7 && !regexImgPattern.test(img7)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_PICTURE" values={{ number: '7' }} />);
    };

    if (img8 && !regexImgPattern.test(img8)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_PICTURE" values={{ number: '8' }} />);
    };

    if (img9 && !regexImgPattern.test(img9)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_PICTURE" values={{ number: '9' }} />);
    };

    if (img10 && !regexImgPattern.test(img10)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_PICTURE" values={{ number: '10' }} />);
    };

    // check features
    if (features1 && !regexFeaturePattern.test(features1)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_FEATURES" values={{ number: '1' }} />);
    };
    if (features2 && !regexFeaturePattern.test(features2)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_FEATURES" values={{ number: '2' }} />);
    };
    if (features3 && !regexFeaturePattern.test(features3)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_FEATURES" values={{ number: '3' }} />);
    };
    if (features4 && !regexFeaturePattern.test(features4)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_FEATURES" values={{ number: '4' }} />);
    };
    if (features5 && !regexFeaturePattern.test(features5)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_FEATURES" values={{ number: '5' }} />);
    };
    if (features6 && !regexFeaturePattern.test(features6)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_FEATURES" values={{ number: '6' }} />);
    };

  }
  return errors;
};



