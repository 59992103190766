import React, { useRef, useEffect, useMemo } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Card, CardBody, CardHeader, CardHeaderToolbar, } from '_metronic/_partials/controls';
import { useSupplierUIContext } from '../_context/SupplierUIContext';
import { UserGuideHelper } from '../../../user-guides/UserGuideHelper';
import { BackButton, ResetButton, SaveButton } from '_metronic/_partials';
import { capitalizeFirstLetter, getLastSearch } from '_metronic/_helpers';
import { CreationAndUpdateInfo } from '_metronic/_partials';
import { FormattedMessage, useIntl } from 'react-intl';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { SupplierActions } from '../_redux/actions';
import { FormikStepper } from './FormikHandler';
import { General } from './general/General';
import { Address } from './address/Address';
import { toast } from 'react-toastify';
import { Bank } from './bank/Bank';



export const SuppliersEdit = ({ history, match: { params: { id } } }) => {

  const dispatch = useDispatch();
  const lastSearch = getLastSearch()
  const { formatMessage: intl } = useIntl();

  //Context
  const UIContext = useSupplierUIContext();
  const UIProps = useMemo(() => ({
    openNewAddressDialog: UIContext.openNewAddressDialog,
    address: UIContext.address,
    bank: UIContext.bank,
    setAddress: UIContext.setAddress,
  }),
    [
      UIContext.openNewAddressDialog,
      UIContext.address,
      UIContext.bank,
      UIContext.setAddress,
    ]
  );

  const { supplierForEdit, findSupplier, success } = useSelector(state => ({
    supplierForEdit: state.suppliers.supplierForEdit,
    findSupplier: state.suppliers.findSupplierForMatching,
    success: state.suppliers.success,
  }),
    shallowEqual
  );

  useEffect(() => {
    id && dispatch(SupplierActions.getSupplierById(id));
  }, [id, dispatch]);

  const handleSubmit = (values) => {

    if (findSupplier.length > 0) {
      return toast.error(<FormattedMessage id='SUPPLIER.ERROR_MESSAGE_SUPPLIER.MATCHING' values={{ email: findSupplier[0]?.email }} />);
    }

    if (UIProps.address == null || UIProps.address.length === 0) {
      toast.error(<FormattedMessage id="SUPPLIER.ALERT" />);
      UIProps.openNewAddressDialog()
      return
    }

    values.name = capitalizeFirstLetter(values.name ?? '');
    values.company = capitalizeFirstLetter(values.company ?? '');

    const supplier = {
      ...values,
      bank: UIProps.bank,
      ...(UIProps.bank && { bank: UIProps.bank }),
      address: UIProps.address,
      created_at: values.created_at,
    };

    if (id) {
      dispatch(SupplierActions.updateSupplier(supplier));
    } else {
      dispatch(SupplierActions.createSupplier(supplier));
    }
  };


  const btnRef = useRef();
  const submitForm = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const resetBtnRef = useRef();
  const resetForm = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(intl({ id: "GENERAL.CONFIRM" }))) {
      if (resetBtnRef && resetBtnRef.current) {
        resetBtnRef.current.click();
      }
      dispatch(SupplierActions.handleCleanUpForm());
    }
  };

  useEffect(() => {
    return () => {
      dispatch(SupplierActions.handleCleanUpForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className="card-box">

      <CardHeader
        back={<BackButton link={ModuleRoutes.SUPPLIER + lastSearch} />}
        title={<FormattedMessage id={id ? 'SUPPLIER.UPDATE' : 'SUPPLIER.NEW'} />}
        info={<UserGuideHelper />}
        id={id ? 'title_supplier_edit' : 'title_supplier_new'}
        sticky
      >
        <CardHeaderToolbar>
          {!id && <ResetButton onClick={resetForm} />}
          <SaveButton onClick={submitForm} />
        </CardHeaderToolbar>

      </CardHeader>

      <CardBody>

        <FormikStepper supplier={supplierForEdit} btnRef={btnRef} resetBtnRef={resetBtnRef} handleSubmit={handleSubmit} success={success} onClose={() => { history.push(ModuleRoutes.SUPPLIER); UIProps.setAddress([]); dispatch(SupplierActions.handleCleanUpForm()) }}>
          <General />
        </FormikStepper>

        <Bank />
        <Address />

      </CardBody>

      <CreationAndUpdateInfo data={supplierForEdit} />

    </Card>
  );
};
