import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardSubHeader } from "_metronic/_partials/controls";
import { LayoutSelect } from "../connection-forms/shippers/deutsche-post/LayoutSelect";
import { connectionForEdit } from "../_context/SettingsUIHelpers";
import { ConnectionsActions } from "../_redux/actions";
import { Button, Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { BlankMessage } from "_metronic/_partials/components";
import { UserInfoDropdown } from "_metronic/_partials";
import { Icon } from "_metronic/_icons";



const SWITCH = {
  kaufland: [
    {
      name: 'invoice_upload',
      label: 'CONNECTIONS.SHIPPER.KAUFLAND_CHECKBOX',
    }
  ],
  amazon: [
    {
      name: 'prime',
      label: 'CONNECTIONS.SHOP.AMAZON_AUTO_PRIME',
    },
    {
      name: 'invoice_upload',
      label: 'CONNECTIONS.SHOP.AMAZON_INVOICE_UPLOAD',
    },
    {
      name: 'credit_upload',
      label: 'CONNECTIONS.SHOP.AMAZON_CREDIT_UPLOAD',
    }
  ]
}


export function ConnectionSettingsCard({ connectionName }) {

  const dispatch = useDispatch();
  const [showLayouts, setShowLayouts] = useState(false)
  const { connection, layouts, loading } = useSelector((state) => ({
    loading: state.connections.loading,
    connection: state.connections[connectionForEdit(connectionName)],
    layouts: state.connections?.deutschePostConfigs?.dpLayout?.map(x => ({ value: x.id, label: x.name })) ?? [],
  }));

  const handleCheckbox = (e) => {
    connection[connectionName][e.target.name] = e.target.checked;
    dispatch(ConnectionsActions.updateSettings(connection))
  }

  useEffect(() => {
    connectionName === 'deutsche_post' &&
      dispatch(ConnectionsActions.getDeutschePostConfigs());
    // eslint-disable-next-line
  }, [connectionName]);

  const isSettings = ["kaufland", "amazon", 'deutsche_post'].includes(connectionName)
  const isDPostLayout = typeof connection?.deutsche_post?.Layout === 'string' && connection.deutsche_post.Layout.length === 0;

  return (<>
    <Card className="card-stretch gutter-b">

      <CardSubHeader
        icon={<Icon.Settings />}
        title={<FormattedMessage id="SETTINGS.TITLE" />}
        info={isDPostLayout && <UserInfoDropdown
          title={<FormattedMessage id='SETTINGS.TITLE' />}
          description={<FormattedMessage id={'CONNECTIONS.SHIPPER.D_POST.LAYOUT_ADD_INFO'} />} />}>

        {isDPostLayout &&
          <Button variant='outline-primary' size='sm' className="svg-icon svg-icon-sm" onClick={() => setShowLayouts(true)} id="btn_layout">
            <Icon.Plus />
            <span className="d-none d-sm-inline font-weight-bold ml-2">
              <FormattedMessage id="CONNECTIONS.SHIPPER.D_POST.LAYOUT_ADD" />
            </span>
          </Button>}
      </CardSubHeader>

      <CardBody>
        {(isSettings && !isDPostLayout) ?
          <>
            {(connectionName === 'amazon' || connectionName === 'kaufland') && SWITCH[connectionName]?.map(item => (
              <label className="switch switch-sm mb-5 switch-icon" key={item.name}>
                <input type="checkbox" name={item.name} checked={connection[connectionName][item.name]} onChange={(e) => handleCheckbox(e)} id={`checkbox_${item.name}`} disabled={loading} />
                <span />
                <div className="font-weight-bold ml-2">
                  <FormattedMessage id={item.label} />
                </div>
              </label>
            ))}

            <Row className="mb-3">
              {(connectionName === 'deutsche_post' && !isDPostLayout) &&
                <Col lg="12">
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex flex-column mr-3'>
                      <span className='font-weight-bolder mb-1'>
                        <FormattedMessage id='CONNECTIONS.SHIPPER.D_POST.LAYOUT' />
                      </span>
                      <span>
                        {layouts.find(option => option.value === connection?.deutsche_post?.Layout)?.label}
                      </span>
                    </div>

                    <Button variant='primary' size='sm' className='font-weight-bold' onClick={() => setShowLayouts(true)} id="btn_layout_change">
                      <FormattedMessage id='CONNECTIONS.SHIPPER.D_POST.LAYOUT_CHANGE' />
                    </Button>
                  </div>
                </Col>
              }
            </Row>
          </>
          : <BlankMessage
            icon={<Icon.Settings />}
            title={<FormattedMessage id={isDPostLayout ? 'CONNECTIONS.SHIPPER.D_POST_LAYOUT.BLANK_MESSAGE_TITLE' : 'CONNECTIONS.NO_FOUND_SETTINGS'} />}
            message={<FormattedMessage id={isDPostLayout ? 'CONNECTIONS.SHIPPER.D_POST_LAYOUT.BLANK_MESSAGE' : 'CONNECTIONS.NO_FOUND_SETTINGS'} />}
            arrow={isDPostLayout}
          />
        }

      </CardBody>

    </Card>

    <LayoutSelect layouts={layouts} connection={connection} show={showLayouts} onHide={() => setShowLayouts(false)} />
  </>
  );
}