/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { UIDates, convertPriceCurrency } from '_metronic/_helpers';
import * as uiHelpers from '../../_context/DocumentsUIHelpers';
import { Icon } from '_metronic/_icons';



export function ActionFormatter(cellContent, row, rowIndex, { openPaymentWarningsDialog, paymentWarnings, openPaymentWarningsDelete, openSendEmailsDialog }) {

  const endPaymentWarnings = paymentWarnings
    .map(item => ({ id: item.id, created_at: item.created_at }))
    .sort(function (a, b) {
      return new Date(b.created_at) - new Date(a.created_at);
    })?.[0]?.id;

  return (
    <>
      <OverlayTrigger overlay={<Tooltip id="shipping-mail-tooltip"><FormattedMessage id="GENERAL.EMAIL" /></Tooltip>}>
        <a className="btn btn-icon btn-hover-danger btn-sm" onClick={() => openSendEmailsDialog({ isEmail: true, reminder: row })} id={`btn_email_${row?.id}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.EnvelopeAt />
          </span>
        </a>
      </OverlayTrigger>

      <OverlayTrigger overlay={<Tooltip id="shipping-print-tooltip"><FormattedMessage id="GENERAL.PRINT" /></Tooltip>}>
        <a className="btn btn-icon btn-hover-info btn-sm" onClick={() => openPaymentWarningsDialog({ isEmail: false, reminder: row })} id={`btn_print_${row?.id}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.Printer />
          </span>
        </a>
      </OverlayTrigger>

      <OverlayTrigger overlay={<Tooltip id="products-delete-tooltip"><FormattedMessage id="GENERAL.DELETE" /></Tooltip>}>
        <a className="btn btn-icon btn-hover-danger btn-sm" onClick={() => openPaymentWarningsDelete(row.id)} style={endPaymentWarnings !== row.id ? { pointerEvents: "none", opacity: 0.5 } : null} id={`btn_delete_${row.id}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.Trash />
          </span>
        </a>
      </OverlayTrigger>
    </>
  )
}


export function DateFormatter(cell, row, rowIndex) {
  return (
    <OverlayTrigger overlay={<Tooltip id="market-tooltip">{UIDates.formatDateTime(cell)}</Tooltip>}>
      <span>
        {UIDates.formatDate(cell)}
      </span>
    </OverlayTrigger>
  );
}


export function TotalAmountFormatter(cell, row, paymentWarnings, pricingSummary) {
  const type = row?.type;
  let totalAmount = uiHelpers.totalAmountPaymentWarning(pricingSummary);
  const reminders = paymentWarnings?.filter(i => i.type === "reminder");
  const totalWarningsAmount = paymentWarnings?.reduce((acc, curr) => acc + curr.amount, 0);
  const reminderDates = reminders.map((i) => new Date(i.created_at).getTime());
  if (type === "reminder" && reminders?.length > 1 && new Date(Math.min(...reminderDates)) < new Date(row?.created_at)) {
    totalAmount += totalWarningsAmount;
  } else if (type === 'warning_1') {
    totalAmount += row.amount;
  } else if (type === 'warning_2') {
    totalAmount += totalWarningsAmount;
  }

  return convertPriceCurrency(totalAmount, pricingSummary?.currency)
}