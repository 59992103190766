export default class ActionTypes {
  static get PREFIX() {
    return '[category]';
  }


  // Create Category action types
  static get CREATE_CATEGORY() {
    return `${ActionTypes.PREFIX}CREATE_CATEGORY`;
  }
  static get CREATE_CATEGORY_SUCCESS() {
    return `${ActionTypes.PREFIX}CREATE_CATEGORY_SUCCESS`;
  }
  static get CREATE_CATEGORY_FAIL() {
    return `${ActionTypes.PREFIX}CREATE_CATEGORY_FAIL`;
  }


  // Update Category action types
  static get UPDATE_CATEGORY() {
    return `${ActionTypes.PREFIX}UPDATE_CATEGORY`;
  }
  static get UPDATE_CATEGORY_SUCCESS() {
    return `${ActionTypes.PREFIX}UPDATE_CATEGORY_SUCCESS`;
  }
  static get UPDATE_CATEGORY_FAIL() {
    return `${ActionTypes.PREFIX}UPDATE_CATEGORY_FAIL`;
  }


  // Delete Category action types
  static get DELETE_CATEGORY() {
    return `${ActionTypes.PREFIX}DELETE_CATEGORY`;
  }
  static get DELETE_CATEGORY_SUCCESS() {
    return `${ActionTypes.PREFIX}DELETE_CATEGORY_SUCCESS`;
  }
  static get DELETE_CATEGORY_FAIL() {
    return `${ActionTypes.PREFIX}DELETE_CATEGORY_FAIL`;
  }


  // Get Categories action types
  static get GET_CATEGORIES() {
    return `${ActionTypes.PREFIX}GET_CATEGORIES`;
  }
  static get GET_CATEGORIES_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_CATEGORIES_SUCCESS`;
  }
  static get GET_CATEGORIES_FAIL() {
    return `${ActionTypes.PREFIX}GET_CATEGORIES_FAIL`;
  }

}