import React, { useState } from 'react';
import { Redirect, Switch, useHistory } from 'react-router-dom';
import { ModuleRoutes, RELATIVE_PATH } from 'constants/moduleRoutes';
import { ProductListingsUIProvider } from '../_context/ProductListingsUIContext';
import { EbayListingsEdit } from './ebay-listings-edit/EbayListingsEdit';
import { EbayListingsPage } from './EbayListingsPage';
import { ContentRoute } from '_metronic/layout';



export default function EbayListings({ match: { params: { market_id } } }) {

  const history = useHistory();

  const [ids, setIds] = useState([]);
  const [showSyncAlertDialog, setShowSyncAlertDialog] = useState(false);
  const [syncErrors, setSyncErrors] = useState(null);
  const [productId, setProductId] = useState(null);
  const [isPublished, setIsPublished] = useState(false);
  const [categoryInputValue, setCategoryInputValue] = useState(null);
  const [selectedCategoryAspects, setCategoryAspects] = useState([]);

  const openSyncAlertDialog = (id, syncErrors, isPublished) => {
    setSyncErrors(syncErrors);
    setShowSyncAlertDialog(true);
    setProductId(id);
    setIsPublished(isPublished);
  };
  const closeSyncAlertDialog = () => {
    setSyncErrors(null);
    setShowSyncAlertDialog(false);
    setProductId(null);
    setIsPublished(false);
  };

  const contextUIEvents = {
    market_id,
    ids,
    setIds,
    showSyncAlertDialog,
    openSyncAlertDialog,
    closeSyncAlertDialog,
    syncErrors,
    productId,
    isPublished,
    categoryInputValue,
    setCategoryInputValue,
    selectedCategoryAspects,
    setCategoryAspects,
    openPrepareEbayListingEdit: (market_id, id, method) => history.push(ModuleRoutes.EBAY_LISTINGS_PREPARE_EDIT_FN(market_id, id, method)),
  };

  
  return (
    <ProductListingsUIProvider contextUIEvents={contextUIEvents}>
      <Switch>
        <Redirect exact={true} from={RELATIVE_PATH} to={ModuleRoutes.EBAY_LISTINGS} />

        <ContentRoute path={ModuleRoutes.EBAY_LISTINGS_PREPARE_EDIT} component={EbayListingsEdit} />
        <ContentRoute path={ModuleRoutes.EBAY_LISTINGS} component={EbayListingsPage} />
      </Switch>
    </ProductListingsUIProvider>
  );
}
