import { useState, useEffect } from "react";
import { isEmpty, isString } from "lodash";



export function usePdfMaker(document) {

  const [pdf, setPdf] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    try {

      if (!document) {
        setPdf(null);
        setError("Pdf not found");
        return;
      }

      if (!isEmpty(document?.pdf) && isString(document.pdf)) {
        const bytes = window.atob(document.pdf);
        let length = bytes.length;
        let out = new Uint8Array(length);
        while (length--) { out[length] = bytes.charCodeAt(length) }
        const blob = new Blob([out], { type: "application/document" });
        const urls = blob && URL.createObjectURL(blob);
        setPdf(urls);
      }

      if (document?.error) {
        setError(document.error);
      }

    } catch (error) {
      setPdf(null);
      setError(JSON.stringify(error));
    }

  }, [document]);

  const resetPdfMaker = () => {
    setPdf(null);
    setError(null);
  };

  return [pdf, error, resetPdfMaker];
}