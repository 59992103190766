import React from 'react';
import styled from 'styled-components';
import { theme } from '_metronic/_helpers';



export const SelectInterface = ({
  field,
  form: { touched, errors },
  label,
  placeholder,
  height,
  disabled,
  withFeedbackLabel,
  feedbackLabel = '',
  options,
  props,
}) => (
  <Wrapper>
    {label && (
      <UILabel errors={touched[field.name] && errors[field.name] && true}>
        {label}
      </UILabel>
    )}
    <UISelect
      placeholder={placeholder}
      errors={touched[field.name] && errors[field.name] && true}
      height={height}
      disabled={disabled}
      {...props}
      {...field}
    >
      <UIOption value={''} d_none>
        Select..
      </UIOption>
      {options?.length > 0 &&
        options.map((option, index) => (
          <UIOption key={index} value={option.value} {...option}>
            {option.label}
          </UIOption>
        ))}
    </UISelect>
    <FeedbackLabel
      withFeedbackLabel={withFeedbackLabel}
      errors={touched[field.name] && errors[field.name] && true}
    >
      {touched[field.name] && errors[field.name] ? errors[field.name] : feedbackLabel}
    </FeedbackLabel>
  </Wrapper>
);

export const SelectInterfaceWithoutFormik = ({
  label,
  placeholder,
  min,
  feedbackLabel = '',
  onChange,
  value,
  height,
  options = [],
  props,
}) => (
  <Wrapper>
    {label && <UILabel>{label}</UILabel>}
    <UISelect
      placeholder={placeholder}
      min={min}
      onChange={onChange}
      value={value}
      height={height}
      {...props}
    >
      {options.map((option, index) => (
        <UIOption key={index} value={option.value}>
          {option.label}
        </UIOption>
      ))}
    </UISelect>
    <FeedbackLabel>{feedbackLabel}</FeedbackLabel>
  </Wrapper>
);

const Wrapper = styled.div`
  /* min-height: 7.35rem; */
  padding: 0.25rem 0;
  position: relative;
  box-sizing: border-box;
`;
const UILabel = styled.div`
  color: ${p => (p.errors ? theme.danger : theme.black)};
  font-size: 1.05rem;
  margin-bottom: 0.25rem;
`;
const UISelect = styled.select`
  border: 2px solid ${p => (p.errors ? theme.danger : theme.gray)};
  padding: 0 ${p => p.size || '0.75rem'};
  width: 100%;
  height: ${p => p.height || '3.8rem'};
  font-size: inherit;
  border-radius: 4px;
  margin-bottom: 0.15rem;
  outline: none;
  &:hover,
  &:focus {
    border: 1px solid ${theme.primary};
  }
  &::placeholder {
    opacity: 0.7;
  }
`;
const UIOption = styled.option`
  font-size: 1.2rem;
  margin: 2px;
  padding-bottom: 2px;
  display: ${p => (p.d_none ? 'none' : 'block')};
  &:hover,
  :active,
  :focus,
  :checked {
    background-color: antiquewhite !important;
    box-shadow: 0 0 10px 100px ${theme.primary} inset;
  }
`;
const FeedbackLabel = styled.small`
  color: ${props => (props.errors ? theme.danger : theme.black)};
  display: ${p => (p.withFeedbackLabel ? 'block' : 'none')};
`;
