import React from 'react';
import styled from 'styled-components';
import { UILabel, UIFeedbackLabel } from './InterfaceFormHelpers';
import { theme } from '_metronic/_helpers';



export const InputInterface = ({
  field,
  form: { touched, errors },
  label,
  type = 'text',
  id,
  placeholder,
  min,
  height,
  disabled,
  withFeedbackLabel,
  feedbackLabel = '',
  margin = '8px 0',
  max,
  readOnly,
  onFocus,
  props,
}) => (
  <Wrapper margin={margin}>

    {label && (
      <UILabel errors={touched[field.name] && errors[field.name] && true}>
        {label}
      </UILabel>
    )}

    <UiInput
      id={id}
      type={type}
      placeholder={placeholder}
      errors={touched[field.name] && errors[field.name] && true}
      min={min}
      max={max}
      height={height}
      disabled={disabled}
      readOnly={readOnly}
      onFocus={onFocus}
      {...props}
      {...field}
    />

    <UIFeedbackLabel withFeedbackLabel={withFeedbackLabel} errors={touched[field.name] && errors[field.name] && true}>
      {touched[field.name] && errors[field.name] ? errors[field.name] : feedbackLabel}
    </UIFeedbackLabel>

  </Wrapper>
);

export const InputInterfaceWithoutFormik = ({
  label,
  name,
  type = 'text',
  id,
  placeholder,
  min,
  feedbackLabel = '',
  onChange,
  value,
  withFeedbackLabel,
  height,
  className,
  autoComplete = 'true',
  autoCorrect = 'true',
  props,
}) => (
  <Wrapper className={className}>
    {label && <UILabel>{label}</UILabel>}
    <UiInput
      autoComplete={autoComplete}
      autoCorrect={autoCorrect}
      name={name}
      id={id}
      type={type}
      placeholder={placeholder}
      min={min}
      height={height}
      onChange={onChange}
      value={value}
      {...props}
    />
    <UIFeedbackLabel withFeedbackLabel={withFeedbackLabel}>
      {feedbackLabel}
    </UIFeedbackLabel>
  </Wrapper>
);



const Wrapper = styled.div`
  /* min-height: 7.35rem; */
  /* padding: 0.25rem 0; */
  margin: ${p => p.margin || 0};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const UiInput = styled.input`
  border: ${p => (p.errors ? `1px solid ${theme.danger}` : `1px solid ${theme.gray}`)};
  padding: 0 ${p => p.size || '0.75rem'};
  width: 100%;
  height: ${p => p.height || '3.8rem'};
  font-size: inherit;
  border-radius: 4px;
  margin-bottom: 0.15rem;
  outline: none;
  &:hover,
  &:focus {
    border: 1px solid ${theme.primary};
  }
  &::placeholder {
    opacity: 0.7;
  }
  &:disabled {
    background: #f2f2f2;
  }
`;
