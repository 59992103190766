import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useProductEditUIContext } from "app/modules/products/_context/ProductEditUIContext";
import { Importer, ImporterField, deDE, enUS, trTR } from "react-csv-importer";
import { ProductActions } from "app/modules/products/_redux/actions";
import { CloseButton } from "_metronic/_partials";
import { useLang } from '_metronic/i18n';
import { toast } from "react-toastify";
import { Icon } from "_metronic/_icons";



export function ProductKeysImport({ productId }) {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();
  const locale = useLang();

  const [productKeys, setProductKeys] = useState([]);
  const [showSample, setShowSample] = useState(true);

  const UIContext = useProductEditUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showImportProductKeys,
    onHide: UIContext.closeImportProductKeys,
  }), [
    UIContext.showImportProductKeys,
    UIContext.closeImportProductKeys,
  ]);


  const processChunk = async (rows) => {
    setProductKeys((prevCustomers) => [...prevCustomers, ...rows.map(row => row.key)]);
  };

  const onHideHandler = () => {
    UIProps.onHide();
    setShowSample(true);
    setProductKeys([]);
  }

  const langList = {
    "de": deDE,
    "en": enUS,
    "tr": trTR
  }

  return (
    <Modal show={UIProps.show} onHide={onHideHandler} size="lg" aria-labelledby="example-modal-sizes-title-md" centered backdrop="static" keyboard={false}>

      <Modal.Header>
        <Modal.Title>
          <Icon.Password className="mr-2" />
          <FormattedMessage id="PRODUCT.KEYS_IMPORT" />
        </Modal.Title>
        <CloseButton onClick={onHideHandler} />
      </Modal.Header>

      <Modal.Body className="d-flex flex-column">
        <Importer
          locale={langList[locale]}
          assumeNoHeaders={false}
          restartable={false}
          processChunk={processChunk}
          onStart={({ file, preview, fields, columnFields }) => { setShowSample(false) }}

          onComplete={({ file, preview, fields, columnFields }) => {
            if (!file.name.toLowerCase().endsWith('.csv')) {
              return toast.error(<FormattedMessage id="IMPORT_EXPORT.INVALID_FILE" />);
            };
            if (file.size > 250000) {
              return toast.warning(<FormattedMessage id="IMPORT_EXPORT.INVALID_LENGTH_SIZE" />);
            };
            if (productKeys.length > 500) {
              return toast.warning(<FormattedMessage id="IMPORT_EXPORT.INVALID_LENGTH_MAX" />);
            };
            if (productKeys.length === 0) {
              return toast.warning(<FormattedMessage id="IMPORT_EXPORT.INVALID_LENGTH_MIN" />)
            }

            toast.success(intl({ id: 'import.complete', defaultMessage: 'Import completed successfully' }));

            if (productKeys.length) {
              productId
                ? dispatch(ProductActions.addProductKeys(productId, productKeys))
                : dispatch(ProductActions.addProductKeysSuccess(null, productKeys));
            }
          }}
          onClose={onHideHandler}
          onError={(error) => toast.error(intl({ id: 'import.error', defaultMessage: 'Error during import' }))}
          delimiter=","
          encoding="utf-8"
        >
          <ImporterField name="key" label='Keys' />
        </Importer>

        {showSample &&
          <span className="text-center mt-3">
            <FormattedMessage id='IMPORT_EXPORT.PRODUCT.IMPORT.SAMPLE_DOWNLOAD' />
            {/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */}
            <a href="#!" onClick={() => dispatch(ProductActions.importExample({ locale, type: "digital_product_keys" }))} className='font-weight-bold ml-1 p-0' id='btn_example'>
              <FormattedMessage id='IMPORT_EXPORT.PRODUCT.IMPORT.SAMPLE_CSV' />
            </a>
          </span>}

      </Modal.Body>

    </Modal>
  );
}
