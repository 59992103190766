import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { CloseButton } from "_metronic/_partials";
import { FormattedMessage } from 'react-intl';
import { Portal } from 'react-portal';
import { Icon } from '_metronic/_icons';



export function CreditWarningDialog({  show, onHide, onConfirm }) {

  const handleSubmit = () => {
    onConfirm();
    onHide();
  };

  return (
    <Portal node={document && document.getElementById('modal-portal')}>
         <Modal show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-xl" size="md" centered>

        <CloseButton onClick={onHide} className="position-absolute top-0 right-0 mr-10 mt-10" />

        <div className="d-flex justify-content-center icon-4x pt-10">
          <Icon.InfoCircle className="text-info" />
        </div>

        <Modal.Body className="text-center line-height-lg h6 py-15 mb-0">
          <FormattedMessage id="DOCUMENT.EDIT.CREDIT.KEY_WARNING" />
        </Modal.Body>

        <Modal.Footer className="flex-nowrap p-0 overflow-hidden">

          <Button variant="ios" className="border-right" onClick={onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant="ios" className="text-danger" onClick={handleSubmit} id="btn_delete_modal">
            <FormattedMessage id="GENERAL.CONTINUE" />
          </Button>

        </Modal.Footer>

      </Modal>
    </Portal>
  );
}