import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { ProductActions } from 'app/modules/products/_redux/actions';
import { useProductEditUIContext } from 'app/modules/products/_context/ProductEditUIContext';
import { FormattedMessage } from 'react-intl';



export function ProductKeysDeleteDialog({ productId }) {

  const dispatch = useDispatch();
  const UIContext = useProductEditUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showDeleteProductKeys,
    onHide: UIContext.closeDeleteProductKeysDialog,
    deleteKeyIds: UIContext.deleteKeyIds,
  }),
    [
      UIContext.deleteKeyIds,
      UIContext.showDeleteProductKeys,
      UIContext.closeDeleteProductKeysDialog
    ]
  );

  const deleteProductAction = () => {
    if (UIProps.deleteKeyIds) {
      productId
        ? dispatch(ProductActions.deleteProductKeys(productId, UIProps.deleteKeyIds))
        : dispatch(ProductActions.deleteProductKeysSuccess({ deletedKeys: UIProps.deleteKeyIds, notDeletedKeys: [] }));
    }
    UIProps.onHide();
  };


  return (
    <Modal show={UIProps.show} onHide={UIProps.onHide} size="sm" backdrop="static" aria-labelledby="example-modal-sizes-title-sm" centered>

      <Modal.Body className="text-center line-height-md h6 py-10 mb-0">
        {UIProps.deleteKeyIds?.length > 1
          ? <FormattedMessage id="PRODUCT.KEYS_DELETE" values={{ keys: UIProps.deleteKeyIds?.length, s: chunk => <span className="text-danger">{chunk}</span> }} />
          : <FormattedMessage id="PRODUCT.KEY_DELETE" />
        }
      </Modal.Body>

      <Modal.Footer className="flex-nowrap p-0 overflow-hidden">

        <Button variant="ios" className="border-right" onClick={UIProps.onHide} id="btn_cancel_modal">
          <FormattedMessage id="GENERAL.CANCEL" />
        </Button>

        <Button variant="ios" className="text-danger" onClick={deleteProductAction} id="btn_delete_modal">
          <FormattedMessage id="GENERAL.DELETE" />
        </Button>

      </Modal.Footer>

    </Modal>
  );
}
