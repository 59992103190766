import { INITIAL_SUPPLIER } from "../_context/SuppliersUIHelpers";
import ActionTypes from "./actionTypes";



const INITIAL_STATE = {
  loading: false,
  entities: [],
  countSupplier: 0,
  totalSupplier: 0,
  address: [],
  bank: [],
  addressForEdit: {},
  bankForEdit: {},
  supplierForEdit: INITIAL_SUPPLIER,
  findSupplierForMatching: '',
  error: '',
  success: false,
};


export function supplierReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {

    // Create Supplier
    case ActionTypes.CREATE_SUPPLIER:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case ActionTypes.CREATE_SUPPLIER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        address: [],
        bank: [],
        entities: [
          payload.supplier,
          ...state.entities,
        ],
        countSupplier: state.countSupplier + 1,
        totalSupplier: state.totalSupplier + 1,
      };

    case ActionTypes.CREATE_SUPPLIER_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: "GENERAL.ERROR_MESSAGE_GENERAL",
      };



    // Update Supplier
    case ActionTypes.UPDATE_SUPPLIER:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case ActionTypes.UPDATE_SUPPLIER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: "",
        supplierForEdit: {},
      };

    case ActionTypes.UPDATE_SUPPLIER_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: "GENERAL.ERROR_MESSAGE_GENERAL",
        supplierForEdit: {},
      };



    // Get Supplier
    case ActionTypes.GET_SUPPLIER:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_SUPPLIER_SUCCESS:
      return {
        ...state,
        loading: false,
        entities: payload.supplier,
        countSupplier: payload.count.count,
        totalSupplier: payload.count.total,
      };

    case ActionTypes.GET_SUPPLIER_FAIL:
      return {
        ...state,
        loading: false,
        error: "GENERAL.ERROR_MESSAGE_GENERAL",
      };



    // Get Supplier By Id
    case ActionTypes.GET_SUPPLIER_BY_ID:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_SUPPLIER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        supplierForEdit: payload.supplier,
      };

    case ActionTypes.GET_SUPPLIER_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: "GENERAL.ERROR_MESSAGE_GENERAL",
      };


    // Find Supplier Property
    case ActionTypes.FIND_SUPPLIER_BY_PROPERTY:
      return {
        ...state,
        findPropertyLoading: true,
      };

    case ActionTypes.FIND_SUPPLIER_BY_PROPERTY_SUCCESS:
      return {
        ...state,
        findPropertyLoading: false,
        findSupplierForMatching: payload.supplier,
      };

    case ActionTypes.FIND_SUPPLIER_BY_PROPERTY_FAIL:
      return {
        ...state,
        findPropertyLoading: false,
        error: 'GENERAL.ERROR_MESSAGE_GENERAL',
      };



    // Delete Supplier
    case ActionTypes.DELETE_SUPPLIER:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.DELETE_SUPPLIER_SUCCESS:
      return {
        ...state,
        loading: false,
        entities: state.entities.filter(supplier => !payload.ids.includes(supplier.id)),
        countSupplier: state.countSupplier - payload.ids.length,
        totalSupplier: state.totalSupplier - payload.ids.length,
      };

    case ActionTypes.DELETE_SUPPLIER_FAIL:
      return {
        ...state,
        loading: false,
        error: "GENERAL.ERROR_MESSAGE_GENERAL",
      };



    // Clean Up Supplier
    case ActionTypes.HANDLE_CLEAN_UP_FORM:
      return {
        ...state,
        address: [],
        bank: [],
        addressForEdit: {},
        bankForEdit: {},
        supplierForEdit: INITIAL_SUPPLIER,
        error: "",
        success: false,
      };


    default:
      return state;
  }
}
