import { API } from 'constants/apiUrl';



export const settingsMiddleware = {

  create: async function (accessToken, userSettings) {
    return await API.post(`/user-settings`, userSettings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  get: async function (accessToken) {
    return await API.get(`/user-settings`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getCount: async function (accessToken, settings_type) {
    return await API.get(`${settings_type}/count`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getById: async function (accessToken, settingId) {
    return await API.get(`/user-settings/${settingId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getByType: async function (accessToken, settings_type) {
    return await API.get(`/user-settings`, {
      params: {
        filter: {
          where: {
            settings_type,
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  update: async function (accessToken, id, settings) {
    return await API.put(`/user-settings/${id}`, settings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  patchUserSettings: async function (accessToken, id, settings) {
    return await API.patch(`/user-settings/${id}`, settings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  delete: async function (accessToken, settingsId) {
    return await API.delete(`/user-settings/${settingsId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

};
