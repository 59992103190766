import React from 'react'
import { Route } from 'react-router-dom'
import { ModuleRoutes } from 'constants/moduleRoutes'
import { TicketEdit } from '../support-dialogs/TicketEdit'
import { TicketStatusDialog } from '../support-dialogs/TicketStatusDialog'
import SupportEdit from './SupportEdit'



export default function SupportEditPage({ match: { params: { id } } }) {
  return (
    <>
      <SupportEdit id={id} />
      <TicketStatusDialog />

      <Route path={ModuleRoutes.EDIT_TICKET}>
        {({ history, match }) => (
          <TicketEdit
            show={match != null}
            id={match && match.params.id}
            onHide={() => history.push(ModuleRoutes.VIEW_TICKET_FN(match.params.id))}
          />
        )}
      </Route>
    </>
  )
}
