import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { ModalProgressBar } from '_metronic/_partials/loading';
import { useProductsUIContext } from '../_context/ProductsUIContext';
import { ConnectionsActions } from '../../connections/_redux/actions';
import { ProductActions } from '../_redux/actions';
import { FormattedMessage } from 'react-intl';
import { Portal } from 'react-portal';



export function UpdateShopsQuantityDialog({ show, onHide, ids }) {

  const dispatch = useDispatch();
  const UIContext = useProductsUIContext();

  const UIProps = useMemo(() => ({
    setIds: UIContext.setIds,
    ids: UIContext.ids,
  }), [UIContext]);


  const { connections, loading, productSettings } = useSelector(state => ({
    productSettings: state.settings.productSettings,
    connections: state.connections.entities,
    loading: state.products.loading,
  }), shallowEqual);

  useEffect(() => {
    dispatch(ConnectionsActions.getConnections());
  }, [dispatch]);


  // if !id we should close modal
  useEffect(() => {
    !UIProps.ids.length && onHide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UIProps.ids.length]);

  const updateAllSelectedProducts = () => {
    const newIds = UIProps.ids.length > 0 ? UIProps.ids : [ids];
    const productData = {
      ids: newIds,
      tokenIds: checked,
    };
    dispatch(
      ProductActions.updateAllSelectedProductsBeiShops(productData)
    );
    //UIProps.setIds([]);
    setChecked([]);
    onHide();
  };

  const [checked, setChecked] = useState([]);

  const handleChange = id => {
    const findInpuit = checked.find(che => che === id);
    if (findInpuit) {
      setChecked(prev => prev.filter(p => p !== id));
    } else {
      setChecked(prev => [...prev, id]);
    }
  };


  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal backdrop="static" show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg">
        {loading && <ModalProgressBar variant="query" />}

        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="PRODUCT.SHOPS_QUANTITY.TITLE" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className=" font-weight-bold">

          <FormattedMessage id="PRODUCT.SHOPS_QUANTITY.INFORM" />
          <span className="label label-xl label-inline label-light-danger mb-5">
            {UIProps.ids.length > 0 ? UIProps.ids.length : 1}
          </span>

          <Row>
            {connections?.length > 0 && connections.map((shop, index) => (shop.token_type !== 'shipper' && productSettings.stock_control?.[shop.name]) &&
              <Col lg="12" key={index} className="mb-3">
                <label className="checkbox checkbox-md checkbox-outline checkbox-primary text-capitalize">
                  <input type="checkbox" name="shops" checked={checked.find(che => che === shop.id) ? true : false} onChange={() => handleChange(shop.id)} id={`checkbox_${shop.id}`} />
                  <span />
                  <div className="ml-2">{shop.name}</div>
                </label>
              </Col>
            )}
          </Row>

        </Modal.Body>


        <Modal.Footer className="flex-nowrap p-0 overflow-hidden">

          <Button variant='ios' className="border-right" onClick={onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button
            variant='ios'
            className="text-success"
            onClick={updateAllSelectedProducts}
            disabled={checked.length === 0}
            style={{ pointerEvents: checked.length > 0 ? 'auto' : 'none' }}
            id="btn_quantity_modal"
          >
            Stock Shop Update
          </Button>
        </Modal.Footer>
      </Modal>
    </Portal>
  );
}
