import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DOCUMENTS_COUNT } from '../../_context/CustomersUIHelpers';
import { CustomerActions } from '../../_redux/actions';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function ReportForm({ id, onHide }) {

  const dispatch = useDispatch();

  const { countDocuments } = useSelector(state => ({
    countDocuments: state.customers.countDocuments,
  }));

  useEffect(() => {
    if (id) {
      dispatch(CustomerActions.getCustomerDocumentCounts(id))
    } else {
      onHide && onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);


  return (
    <ul className="navi navi-hover">
      {DOCUMENTS_COUNT.map((item, i) => (
        <li key={i} className="navi-item font-weight-bold">
          {countDocuments[item.type] > 0
            ? <Link className="navi-link"
              to={{
                pathname: item.link,
                search: `page=1&search=${id}${item.type === "order" ? '&filter.status=all-orders' : ''}`,
                hash: '',
              }}
              id={`btn_${item.label}`}
            >
              <span className="navi-icon">
                <Icon.Search />
              </span>
              <span className="navi-text">
                <FormattedMessage id={item.label} />
              </span>
              <span className="menu-text">
                <span className="label label-lg label-pill label-inline label-light-primary ml-auto font-weight-bolder">
                  {countDocuments[item.type] > 9999 ? "9999+" : countDocuments[item.type]}
                </span>
              </span>
            </Link>
            : <div className="navi-link">
              <span className="navi-icon">
                <Icon.Search />
              </span>
              <span className="navi-text">
                <FormattedMessage id={item.label} />
              </span>
            </div>}
        </li>
      ))}
    </ul>
  )
}
