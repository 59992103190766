import React, { useEffect } from 'react';
import { SVFormControl, SVReactSelect } from '_metronic/_partials/controls';
import { ProductActions } from 'app/modules/products/_redux/actions';
import { useSkuValidationControl } from 'app/hooks';
import { Field, useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';



export const EditVariantForm = ({ initialValues, currentSpecs }) => {

  const { productId } = useParams();
  const { values } = useFormikContext();
  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();
  const { checkIsSkuValidForVariants ,checkIsSkuProductForEdit} = useSkuValidationControl();

  useEffect(() => {

    if (values?.sku) {
      dispatch(ProductActions.findProductBySku([values?.sku], productId, 'variant'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.sku]);

  if (!initialValues) {
    return (
      <div className="text-center my-30">
        <h3 className="text-dark-25">No variants found</h3>
      </div>
    );
  }

  const INPUTS = [
    {
      name: "sku",
      type: "text",
      label: "GENERAL.SKU",
      required: true,
      feedbackLabel: (checkIsSkuValidForVariants(values.sku) || checkIsSkuProductForEdit(values.sku)) ? intl({ id: "PRODUCT.GENERAL.SKU_WARNING" }) : true,
    },
    {
      name: "ean",
      type: "text",
      label: "PRODUCT.MORE_SETTINGS.EAN",
    },
    {
      name: "asin",
      type: "text",
      label: "PRODUCT.MORE_SETTINGS.ASIN",
    },
    {
      name: "quantity",
      type: "number",
      label: "PRODUCT.GENERAL.QUANTITY",
    },
    {
      name: "purchase_price",
      type: "number",
      label: "PRODUCT.GENERAL.PURCHASE",
    },
    {
      name: "gross_price",
      type: "number",
      label: "PRODUCT.GENERAL.SALE_PRICE_GROSS",
    },
    ...currentSpecs.map(spec => ({
      name: spec.value,
      component: SVReactSelect,
      label: spec.label,
      options: spec.aspects
    }))
  ];

  return (
      <Row>
        {INPUTS.map((item, i) => (
          <Col lg={item.size || '12'} key={i}>
            <Field component={item.component || SVFormControl}
              {...{
                name: item.name,
                type: item.type,
                label: intl({ id: item.label }),
                options: item.options,
                withFeedbackLabel: true,
                required: item.required,
                feedbackLabel: item.feedbackLabel || true,
              }}
            />
          </Col>
        ))}
      </Row>
  );
};
