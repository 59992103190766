import storage from 'redux-persist/lib/storage';
import packagejson from "../../package.json";



export const persistConfigs = {
  root: {
    key: `salevali.v${packagejson.version}`,
    storage,
    blacklist: ['documents', 'products', 'listings', 'customers', 'suppliers', 'archive', 'admin', "amazonListings"],
  },
};
