import { API } from "constants/apiUrl";
import { paginationParams, searchParams } from "../_helpers/query-params";
import { searchKeys } from "./params";



export const archiveMiddleware = {

  getDocumentById: async function (access, id, withExactFields) {
    return API.get(`/${access.model}/${id}`, {
      params: {
        filter: {
          where: {
            archive: true,
          },
          ...(withExactFields && { ...withExactFields }),
        },
      },
      headers: {
        Authorization: `Bearer ${access.accessToken}`,
      },
    });
  },


  getDocuments: async function (accessToken, model, dateRange, { pagination, filter, order, search }) {
    return await API.get(`/archive/${model}`, {
      params: {
        filter: {
          ...paginationParams(pagination.page, pagination.limit),
          order: [`${order ? order : "id DESC"}`],
          where: {
            archive: true,
            ...((dateRange.date_from && dateRange.date_to) && {
              created_at: { between: [`${dateRange.date_from}T00:00:00.001Z`, `${dateRange.date_to}T23:59:59.999Z`] }
            }),
            ...(search && {
              ...searchParams(searchKeys, search),
            }),
            //Will be used when other filters are added
            // ...(filter && filter),
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getDocumentsCount: async function (accessToken, model, dateRange, { pagination, filter, order, search }) {
    return await API.get(`/archive/${model}/count`, {
      params: {
        where: {
          archive: true,
          ...((dateRange.date_from && dateRange.date_to) && {
            created_at: { between: [`${dateRange.date_from}T00:00:00.001Z`, `${dateRange.date_to}T23:59:59.999Z`] }
          }),
          ...(search && {
            ...searchParams(searchKeys, search),
          }),
          //Will be used when other filters are added
          // ...(filter && filter),
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  printDocument: async function (access, data) {
    return await API.post(`/document-print`, data, {
      headers: {
        Authorization: `Bearer ${access.accessToken}`,
      },
    })
  },

};
