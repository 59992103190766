import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { RemotePagination } from '_metronic/_partials/controls';
import { NoRecordsFoundMessage, getSelectRow } from '_metronic/_helpers';
import { sortCaret, headerSortingClasses } from '_metronic/_helpers';
import { ActionsColumnFormatter } from './ActionsColumnFormatter';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AdminRoutes } from 'constants/moduleRoutes';
import { UIDates, flags } from '_metronic/_helpers';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Icon } from '_metronic/_icons';
import moment from 'moment';



export function TempUserTable({ tempUsers, tempCount, loading, UIProps }) {

  const { formatMessage: intl } = useIntl();

  const COLUMNS = [
    {
      dataField: 'name',
      text: intl({ id: 'GENERAL.NAME' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap',
      classes: 'font-weight-bold',
      headerStyle: { minWidth: '150px', },
      formatter: (cell, row) => (
        <Link to={AdminRoutes.ADMIN_TEMP_USERS_EDIT_FN(row.id)} type="button" className="d-flex align-items-center text-decoration-none text-dark text-hover-primary text-nowrap preview" id='btn_customer_preview'>
          <span className='font-size-lg font-weight-bold'>{`${row.name} ${row.surname ? row.surname : ''}`}</span>
          <span className='d-flex invisible text-dark-25 svg-icon svg-icon-sm pl-2'>
            <Icon.Pencil />
          </span>
        </Link>
      ),
    },
    {
      dataField: 'email',
      text: intl({ id: 'GENERAL.EMAIL' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerClasses: 'text-nowrap',
      classes: 'font-weight-bold',
      headerSortingClasses,
      formatter: (cell, row) => (
        <a href={`mailto:${cell}`} className="text-decoration-none text-dark text-hover-primary">
          {cell}
        </a>
      )
    },
    {
      dataField: 'created_at',
      text: intl({ id: 'GENERAL.REGISTRATION_DATE' }),
      classes: 'text-center text-nowrap',
      headerClasses: 'text-center',
      formatter: (cell, row) => (
        <OverlayTrigger overlay={<Tooltip id="market-tooltip">{UIDates.formatDateTime(cell)}</Tooltip>}>
          <span type="button" className={`label label-lg label-inline label-rounded ${moment().diff(cell, 'days', true) > 2 ? 'label-outline-danger' : 'label-outline-success'}`}>
            {moment(cell).fromNow()}
          </span>
        </OverlayTrigger>
      ),
    },
    {
      dataField: 'email_verification.sending_date',
      text: intl({ id: 'ADMIN.VERIFICATION_SENDING' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      classes: 'text-center',
      headerClasses: 'text-center text-nowrap',
      formatter: (cell, row) => (UIDates.formatDateTime(cell)),
    },
    {
      dataField: 'newsletter',
      text: 'Newsletter',
      classes: 'text-center',
      headerClasses: 'text-center',
      headerStyle: { minWidth: '100px' },
      formatter: (cell, row) => (cell
        ? <label className="svg-icon svg-icon-md text-success">
          <Icon.CheckCircle />
        </label>
        : null
      ),
    },
    {
      dataField: 'language',
      text: intl({ id: 'ADMIN.LANGUAGE' }),
      classes: 'text-center',
      headerClasses: 'text-center',
      formatter: (cell, row) => {
        const currentLanguage = flags.find((x) => x.lang === cell);
        return (
          <OverlayTrigger overlay={<Tooltip id="language-panel-tooltip">{currentLanguage?.name}</Tooltip>}>
            <span className='icon-lg'>
              {currentLanguage?.flag}
            </span>
          </OverlayTrigger>
        )
      },
    },
    {
      dataField: 'action',
      text: "...",
      headerClasses: 'font-size-h3 text-center pt-0',
      classes: 'text-center',
      headerStyle: { minWidth: '100px' },
      formatter: ActionsColumnFormatter,
    },
  ];


  return (
    <>
      <BootstrapTable
        keyField="id"
        data={tempUsers || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center"
        bootstrap4
        striped
        bordered={false}
        noDataIndication={<NoRecordsFoundMessage loading={loading} onClick={() => UIProps.setSearchQuery('')} />}
        selectRow={getSelectRow({
          ids: UIProps.ids,
          setIds: UIProps.setIds,
          entities: tempUsers,
        })}
      />
      <RemotePagination
        queryParams={UIProps.queryParams.pagination}
        setQueryParams={UIProps.setPaginationQuery}
        totalCount={tempCount}
        entitiesCount={tempUsers?.length}
        loading={loading}
      />
    </>
  );
}
