import React from 'react'
import { GeneralForm } from './general/GeneralForm';
import { SpecialForm } from './special/SpecialForm';
import { AddressForm } from './address/AddressForm';
import { BankForm } from './bank/BankForm';



export default function Navigator() {
  return (
    <div>

      <GeneralForm />

      <div className="separator separator-solid mt-2 mb-6" />

      <BankForm />

      <div className="separator separator-solid mt-2 mb-6" />

      <SpecialForm type="modal" />

      <div className="separator separator-solid mt-2 mb-6" />

      <AddressForm type="modal" />

    </div>
  )
}
