import { call, put, takeEvery, select } from "redux-saga/effects";
import { archiveMiddleware, tokenErrorHandler, documentMiddleware } from "middlewares";
import { getNotification } from "_metronic/layout/components/header/_redux/saga";
import { ArchiveActions } from "./actions";
import ActionTypes from "./actionTypes";



// Get Documents
function* getArchive({ payload: { queryParams, model, dateRange } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const countDocuments = yield call(archiveMiddleware.getDocumentsCount, accessToken, model, dateRange, queryParams);
    const response = yield call(archiveMiddleware.getDocuments, accessToken, model, dateRange, queryParams);
    yield put(ArchiveActions.getArchiveSuccess(response.data, countDocuments.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, ArchiveActions.getArchiveFail());
  }
}


// Get Documents
function* printArchive({ payload: { data } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(documentMiddleware.printDocument, accessToken, data);
    yield put(ArchiveActions.printRequestSuccess(response.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, ArchiveActions.printRequest());
  }
}


function* deleteDocument({ payload: { documentType, ids } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const access = {
      accessToken: accessToken,
      documentModel: documentType
    }

    yield call(documentMiddleware.deleteDocument, access, "unarchive", ids);
    yield put(ArchiveActions.deleteDocumentSuccess(ids));

    // Notification update
    yield call(getNotification);

  } catch (err) {
    yield call(tokenErrorHandler, err, ArchiveActions.deleteDocumentFail(err));
  }
}



export function* archiveSaga() {
  yield takeEvery(ActionTypes.GET_ARCHIVE, getArchive);
  yield takeEvery(ActionTypes.PRINT_ARCHIVE, printArchive);
  yield takeEvery(ActionTypes.DELETE_ARCHIVE, deleteDocument);
}
