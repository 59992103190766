import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, CardHeader, CardHeaderToolbar, CardBody } from '_metronic/_partials/controls';
import { useProductListingsUIContext } from '../_context/ProductListingsUIContext';
import { ListingActions } from '../_redux/actions';
import { AmazonListingsFilter } from './amazon-listings-filter/AmazonListingsFilter';
import { AmazonListingsTable } from './amazon-listings-table/AmazonListingsTable';
import { Icon } from '_metronic/_icons';
import { NewButton } from '_metronic/_partials';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { useGetProductsEffect, useSearchQuery } from 'app/hooks';

export function AmazonListingsCard() {

  const [queryParams, setQueryBase] = useState({
    pagination: {
      page: 1,
      limit: 10
    },
    order: 'id DESC',
    search: '',
    filter: null
  });

  const setQueryParams = useCallback((params) => setQueryBase(params), []);
  const { setPaginationQuery, setSearchQuery } = useSearchQuery(setQueryParams);

  const UIContext = useProductListingsUIContext();
  const UIProps = useMemo(() => ({
    queryParams: UIContext.queryParams,
    tokenId: UIContext.tokenId
  }),
    [UIContext.queryParams, UIContext.tokenId]
  );

  useGetProductsEffect(queryParams, ListingActions.getProductsRequest);

  return (
    <Card>
      <CardHeader title={<FormattedMessage id="PRODUCT_LISTINGS.TITLE" values={{ shopName: 'Amazon' }} />} icon={<Icon.Shop />} className="display-5">
        <CardHeaderToolbar>
          <NewButton link={ModuleRoutes.AMAZON_LISTINGS_SEARCH_PRODUCT_FN(UIProps.tokenId)} label="PRODUCT.PRODUCT_NEW" />
        </CardHeaderToolbar>
      </CardHeader>

      <CardBody>
        <AmazonListingsFilter />
        <AmazonListingsTable setPaginationQuery={setPaginationQuery} queryParams={queryParams} setSearchQuery={setSearchQuery} />
      </CardBody>
    </Card>
  );
}
