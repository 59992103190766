import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Portal } from 'react-portal';
import { Button, Modal } from 'react-bootstrap';
import { useUserSettingsUIContext } from '../../_context/UserSettingsUIContext';
import { AuthActions } from 'app/pages/auth/_redux/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import AccountDeleteForm from './AccountDeleteForm';
import { CloseButton } from '_metronic/_partials';
import { maxValidation } from '_metronic/_helpers';
import { Icon } from '_metronic/_icons';
import { Form, Formik } from 'formik';
import * as Yup from 'yup'



export default function AccountDelete() {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isSurveyed, setIsSurveyed] = useState(false);
  const [surveyButton, setSurveyButton] = useState(false);
  const [toggle, setToggle] = useState(false);

  const { success, loading, message, counts, user } = useSelector(state => ({
    loading: state.auth.loading,
    success: state.auth.success,
    message: state.auth.message,
    counts: state.auth.counts,
    user: state.auth.user,
  }));

  const UIContext = useUserSettingsUIContext();
  const UIProps = useMemo(() => ({
    closeAccountDeleteDialog: UIContext.closeAccountDeleteDialog,
    showDeleteAccountDialog: UIContext.showDeleteAccountDialog,
  }),
    [
      UIContext.closeAccountDeleteDialog,
      UIContext.showDeleteAccountDialog,
    ]
  );

  const SURVEY = [
    {
      name: "deleted_request.surveys.service",
      type: 'service',
      label: intl({ id: 'SETTINGS.LOGIN.DELETE_ACCOUNT.SURVEY.POOR' }),
    },
    {
      name: "deleted_request.surveys.price",
      type: 'price',
      label: intl({ id: 'SETTINGS.LOGIN.DELETE_ACCOUNT.SURVEY.EXPENSIVE' }),
    },
    {
      name: "deleted_request.surveys.dissatisfaction",
      type: 'dissatisfaction',
      label: intl({ id: 'SETTINGS.LOGIN.DELETE_ACCOUNT.SURVEY.DISSATISFACTION' }),
    },
    {
      name: "deleted_request.surveys.difficulty",
      type: 'difficulty',
      label: intl({ id: 'SETTINGS.LOGIN.DELETE_ACCOUNT.SURVEY.DIFFICULTY' }),
    },
    {
      name: "deleted_request.surveys.other",
      type: 'other',
      label: intl({ id: 'SETTINGS.LOGIN.DELETE_ACCOUNT.SURVEY.OTHER' }),
    },
  ]

  const handleSubmit = (values) => {

    const selectedSurvey = [];
    const anyValueTrue = Object.values(values.deleted_request.surveys).some(value => value === true);

    if (values.confirm && values.confirm === intl({ id: 'SETTINGS.LOGIN.DELETE_ACCOUNT.CONFIRM' })) {
      setIsConfirmed(true);
    }

    if (anyValueTrue || values.deleted_request.description.length > 0) {
      setIsSurveyed(true);
      setToggle(false);
    }

    if (values.password.length > 0) {
      SURVEY.forEach((item) => {
        if (values.deleted_request.surveys[item.type]) {
          selectedSurvey.push(item.label);
        }
      });

      const data = {
        password: values.password,
        surveys: selectedSurvey,
        description: values.deleted_request.description,
        status: true,
        counts: counts,
      }

      dispatch(AuthActions.deletedRequest(data))
    }
  }

  const deletedRequestValidationSchema = Yup.object().shape({
    confirm: Yup.string()
      .trim(intl({ id: 'AUTH.GENERAL.BLANK_CHARACTER' }))
      .strict(true)
      // .min(6, intl({ id: 'AUTH.REGISTER.PASSWORD.LENGTH_MIN' }))
      .max(50, intl({ id: 'AUTH.REGISTER.PASSWORD.LENGTH_MAX' }))
      // .matches(passwordRedExp, intl({ id: 'AUTH.REGISTER.PASSWORD.MATCHES' }))
      // .required(intl({ id: 'SETTINGS.LOGIN.DELETE_ACCOUNT.CONFIRM_REQUIRED' }))
      .required(intl({ id: 'GENERAL.REQUIRED' }))
      .test('match', intl({ id: 'SETTINGS.LOGIN.DELETE_ACCOUNT.CONFIRM_BLANK_MESSAGE' }), (value) => {
        return value === intl({ id: 'SETTINGS.LOGIN.DELETE_ACCOUNT.CONFIRM' })
      }),
    deleted_request: Yup.object().shape({
      description: Yup.string()
        .max(500, maxValidation(500)),
    }),
    password: Yup.string().when('confirm', {
      is: (confirm) => !toggle && confirm === intl({ id: 'SETTINGS.LOGIN.DELETE_ACCOUNT.CONFIRM' }),
      then: Yup.string()
        .trim(intl({ id: 'AUTH.GENERAL.BLANK_CHARACTER' }))
        .strict(true)
        // .min(6, intl({ id: 'AUTH.REGISTER.PASSWORD.LENGTH_MIN' }))
        .max(50, intl({ id: 'AUTH.REGISTER.PASSWORD.LENGTH_MAX' }))
        // .matches(passwordRedExp, intl({ id: 'AUTH.REGISTER.PASSWORD.MATCHES' }))
        .required(intl({ id: 'AUTH.VALIDATION.PASSWORD_REQUIRED' })),
    }),

  });

  const deletedRequestInitialValues = {
    deleted_request: {
      surveys: {
        service: false,
        price: false,
        dissatisfaction: false,
        difficulty: false,
        other: false,
      },
      description: '',
    },
    password: '',
    confirm: ''
  }

  useEffect(() => {

    if (success && UIProps.showDeleteAccountDialog) {
      setTimeout(() => {
        UIProps.closeAccountDeleteDialog();
        setIsConfirmed(false);
        setIsSurveyed(false);
        dispatch(AuthActions.cleanUpReducer());
        dispatch(AuthActions.logout());
      }, 2500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, dispatch]);


  return (
    <Portal node={document && document.getElementById('modal-portal')}>
      <Modal show={UIProps.showDeleteAccountDialog} onHide={UIProps.closeAccountDeleteDialog} size="md" aria-labelledby="contained-modal-title-vcenter" backdrop="static" keyboard={false} centered>

        <Formik enableReinitialize={true} initialValues={deletedRequestInitialValues} validationSchema={deletedRequestValidationSchema} onSubmit={handleSubmit}>
          <Form autoComplete='off'>

            <Modal.Body className='px-8 pt-8 pb-4'>
              <CloseButton onClick={() => { UIProps.closeAccountDeleteDialog(); setIsConfirmed(false); setIsSurveyed(false) }} loading={loading} success={success} />

              <div className="d-flex flex-column align-items-center text-danger my-8">
                <Icon.PersonX className="icon-5x mb-3" />
                <h3>
                  <FormattedMessage id='SETTINGS.LOGIN.DELETE_ACCOUNT' />
                </h3>
              </div>

              <AccountDeleteForm success={success} loading={loading} message={message} isConfirmed={isConfirmed} isSurveyed={isSurveyed} survey={SURVEY} setSurveyButton={setSurveyButton} counts={counts} userName={user?.name} />

            </Modal.Body>

            <Modal.Footer>
              <Button variant='light' type='button' onClick={() => { UIProps.closeAccountDeleteDialog(); setIsConfirmed(false); setIsSurveyed(false) }} disabled={success || loading} id='btn_close_modal_password'>
                <FormattedMessage id="GENERAL.CLOSE" />
              </Button>
              {isConfirmed
                ? isSurveyed
                  ? <Button variant='danger' type="submit" disabled={success || loading}>
                    <FormattedMessage id='SETTINGS.LOGIN.DELETE_ACCOUNT' />
                  </Button>
                  : <Button variant='primary' type="submit" disabled={!surveyButton}>
                    <FormattedMessage id='GENERAL.SAVE' />
                  </Button>
                : <Button variant='primary' type="submit" onClick={() => setToggle(true)}>
                  <FormattedMessage id='ADMIN.USER.CONFIRM' />
                </Button>
              }
            </Modal.Footer>

          </Form>
        </Formik>

      </Modal>
    </Portal>
  )
}
