import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { CategoryActions } from '../../../../settings/category/_redux/actions';
import { useProductEditUIContext } from '../../../_context/ProductEditUIContext';
import { CategoryExtendedList } from './CategoryExtendedList';
import { BlankMessage } from '_metronic/_partials/components';
import { CloseButton } from '_metronic/_partials';
import { useGetCategories } from 'app/hooks';
import { Icon } from '_metronic/_icons';
import { Portal } from 'react-portal';



export function CategorySelectDialog({ show, onHide }) {

  const dispatch = useDispatch();

  const { entities } = useSelector(state => state.categories);
  const categories = useGetCategories(entities)

  const UIContext = useProductEditUIContext();
  const UIProps = useMemo(() => ({
    setSelectedKategorie: UIContext.setSelectedKategorie
  }), [UIContext.setSelectedKategorie]);

  const onHideCallback = (node) => {
    UIProps.setSelectedKategorie(node);
    onHide()
  };

  useEffect(() => {
    show && dispatch(CategoryActions.getCategories())
  }, [dispatch, show]);


  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={show} onHide={onHide} size="md" backdrop="static" keyboard={false} centered>

        <Modal.Header>
          <Modal.Title>
            <Icon.BookmarkPlus className="mr-3" />
            <FormattedMessage id="PRODUCT.CATEGORY_SELECT" />
          </Modal.Title>
          <CloseButton onClick={onHide} />
        </Modal.Header>

        <Modal.Body>
          {categories.length === 0
            ? <BlankMessage
              icon={<Icon.BookmarkX />}
              title={<FormattedMessage id='PRODUCT.CATEGORY_DESCRIPTION' />}
            // message={<FormattedMessage id='CATEGORY.BLANK_MESSAGE' />}
            // message={<FormattedHTMLMessage id={item} values={{ a: chunks => <a href='/'>{chunks}</a> }} />}
            />
            : <div className='mr-7'>
              <CategoryExtendedList onHideCallback={onHideCallback} categories={categories} />
            </div>
          }
        </Modal.Body>

        <Modal.Footer>
          <Button variant='light' onClick={onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>
        </Modal.Footer>

      </Modal>
    </Portal>
  );
}
