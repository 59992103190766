import React, { useEffect } from "react";
import { LayoutSplashScreen } from "_metronic/layout";
import { useDispatch, useSelector } from "react-redux";
import { AuthActions } from "./_redux/actions";



export default function Logout() {

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  useEffect(() => {
    dispatch(AuthActions.logout());
  }, [dispatch]);

  return loading ? <LayoutSplashScreen /> : <></>;
};