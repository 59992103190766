import React, { useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { ActionsColumnFormatter } from './column-formatters/ActionsColumnFormatter';
import { useProductEditUIContext } from '../../_context/ProductEditUIContext';
import { BlankMessage } from '_metronic/_partials/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { ProductActions } from '../../_redux/actions';
import { Icon } from '_metronic/_icons';



export function FeaturesTable({ loading }) {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const features = useSelector(state => state.products.features, shallowEqual);

  const UIContext = useProductEditUIContext();
  const UIProps = useMemo(() => ({
    openEditFeatureDialog: UIContext.openEditFeatureDialog,
  }), [
    UIContext.openEditFeatureDialog,
  ]);

  const deleteFeatureHandler = (featureId) => {
    dispatch(ProductActions.deleteFeature(featureId));
  };

  const COLUMNS = [
    {
      dataField: '#',
      text: intl({ id: 'GENERAL.NO' }),
      isDummyField: true,
      headerStyle: { minWidth: '50px', width: '5%' },
      headerClasses: 'text-center pl-7',
      classes: 'font-weight-bold text-center pl-7',
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: 'name',
      text: intl({ id: 'PRODUCT.FEATURES.NAME' }),
      classes: 'font-weight-bold',
    },
    {
      dataField: 'value',
      text: intl({ id: 'PRODUCT.FEATURES.VALUE' }),
      classes: 'font-weight-bold',
    },
    {
      dataField: 'action',
      text: "...",
      headerClasses: 'font-size-h3 text-right pr-14 pt-0',
      classes: 'text-right text-nowrap pr-9',
      headerStyle: { minWidth: '80px', width: '8%' },
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditFeatureDialog: UIProps.openEditFeatureDialog,
        deleteFeatureHandler,
        loading: loading,
      },
    },
  ];


  return (
    features?.length === 0
      ? <BlankMessage
        icon={<Icon.Tags />}
        title={<FormattedMessage id='PRODUCT.FEATURES.BLANK_MESSAGE_TITLE' />}
        message={<FormattedMessage id='PRODUCT.FEATURES.BLANK_MESSAGE' />}
        arrow
      />
      : <BootstrapTable
        wrapperClasses="table-responsive"
        headerClasses='bg-info-o-10'
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bordered={false}
        bootstrap4
        remote
        keyField="id"
        data={features || []}
        columns={COLUMNS}
        condensed
      />
  );
}
