import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { ConnectionsActions } from 'app/modules/connections/_redux/actions';
import { DocumentActions } from 'app/modules/documents/_redux/actions';
import { CONNECTION_SETTINGS } from 'constants/connectionSettings';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { checkIsActive } from '_metronic/_helpers';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import clsx from 'clsx';



export function AsideMenu({ ordersCount, development, disableScroll }) {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ConnectionsActions.getConnections());
  }, [dispatch]);

  const { connections, unreadTickets, settings } = useSelector(state => ({
    connections: state.connections.entities,
    unreadTickets: state.supports.unreadTickets,
    settings: state.settings.productSettings,
  }),
    shallowEqual
  );

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => ({
    layoutConfig: uiService.config,
    asideMenuAttr: uiService.getAttributes('aside_menu'),
    ulClasses: uiService.getClasses('aside_menu_nav', true),
    asideClassesFromConfig: uiService.getClasses('aside_menu', true),
  }), [uiService]);

  const location = useLocation();

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return clsx('menu-item', (checkIsActive(location, url) && !hasSubmenu) && 'menu-item-active')
  };

  const handleClick = () => {
    //FIXME: kaldırılacak
    const queryParams = {
      pagination: { page: 1, limit: 10 },
      filter: { status: "open" },
      order: "order_date DESC",
    }
    dispatch(DocumentActions.getDocuments(queryParams));
  };

  return (
    <div data-menu-vertical="1" className={`aside-menu my-4 ${layoutProps.asideClassesFromConfig} d-flex flex-column flex-row-auto justify-content-between`} id="kt_aside_menu" {...layoutProps.asideMenuAttr}>

      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>


        {/* ::begin:: Dashboard */}
        <li className={`${getMenuItemActive(ModuleRoutes.DASHBOARD)}`}>
          <NavLink className="menu-link" to={ModuleRoutes.DASHBOARD_PAGE} id='menu_dashboard'>
            <span className="svg-icon menu-icon">
              <Icon.Dashboard />
            </span>
            <span className="menu-text">
              <FormattedMessage id="MENU.ASIDE.DASHBOARD" />
            </span>
          </NavLink>
        </li>
        {/* ::end:: Dashboard */}


        {/* ::begin:: Orders */}
        <li className={`${getMenuItemActive(ModuleRoutes.DOCUMENT_ORDER)}`}>
          <NavLink className="menu-link" to={`${ModuleRoutes.DOCUMENT_ORDER}?page=1&filter.status=open&order=order_date%20DESC`} onClick={handleClick} id='menu_order'>
            <span className="svg-icon menu-icon">
              <Icon.Order />
            </span>
            <span className="menu-text">
              <FormattedMessage id="DOCUMENT.ORDER_PLURAL" />
              {ordersCount > 0 &&
                <span className="label label-lg label-rounded label-light-primary ml-auto font-weight-bolder">
                  {ordersCount > 99 ? "99+" : ordersCount}
                </span>
              }
            </span>
          </NavLink>
        </li>
        {/* ::end:: Orders */}


        {/* begin:: Level - Document */}
        <li className={`${getMenuItemActive('/', true)} menu-item-submenu`}>
          <NavLink className="menu-link menu-toggle" to="/" id='menu_document'>
            <span className="svg-icon menu-icon">
              <Icon.Document />
            </span>
            <span className="menu-text">
              <FormattedMessage id="MENU.ASIDE.DOCUMENTS" />
            </span>
            <i className="menu-arrow" />
          </NavLink>

          <div className="menu-submenu">

            <ul className="menu-subnav">

              <li className="menu-item menu-item-parent">
                <span className="menu-link">
                  <span className="menu-text">
                    <FormattedMessage id="MENU.ASIDE.DOCUMENTS" />
                  </span>
                </span>
              </li>

              {/* Offer start */}
              <li className={`${getMenuItemActive(ModuleRoutes.DOCUMENT_OFFER)} pl-6`}>
                <NavLink className="menu-link" to={`${ModuleRoutes.DOCUMENT_OFFER}?page=1&order=created_at%20DESC`} id='menu_offer'>
                  <span className="menu-text">
                    <FormattedMessage id="DOCUMENT.OFFER_PLURAL" />
                  </span>
                </NavLink>
              </li>

              {/* Invoice start */}
              <li className={`${getMenuItemActive(ModuleRoutes.DOCUMENT_INVOICE)} pl-6`}>
                <NavLink className="menu-link" to={`${ModuleRoutes.DOCUMENT_INVOICE}?page=1&order=created_at%20DESC`} id='menu_invoice'>
                  <span className="menu-text">
                    <FormattedMessage id="DOCUMENT.INVOICE_PLURAL" />
                  </span>
                </NavLink>
              </li>

              {/* Credit start */}
              <li className={`${getMenuItemActive(ModuleRoutes.DOCUMENT_CREDIT)} pl-6`}>
                <NavLink className="menu-link" to={`${ModuleRoutes.DOCUMENT_CREDIT}?page=1&order=created_at%20DESC`} id='menu_credit'>
                  <span className="menu-text">
                    <FormattedMessage id="DOCUMENT.CREDIT_PLURAL" />
                  </span>
                </NavLink>
              </li>

              {/* Waybill start */}
              <li className={`${getMenuItemActive(ModuleRoutes.DOCUMENT_WAYBILL)} pl-6`}>
                <NavLink className="menu-link" to={`${ModuleRoutes.DOCUMENT_WAYBILL}?page=1&order=created_at%20DESC`} id='menu_delivery_note'>
                  <span className="menu-text">
                    <FormattedMessage id="DOCUMENT.WAYBILL_PLURAL" />
                  </span>
                </NavLink>
              </li>

              {/* Archive start */}
              <li className={`${getMenuItemActive(ModuleRoutes.ARCHIVE)} pl-6`}>
                <NavLink className="menu-link" to={ModuleRoutes.ARCHIVE_PAGE} id='menu_setting_archive'>
                  <span className="menu-text">
                    <FormattedMessage id="MENU.ASIDE.SETTING.ARCHIVE" />
                  </span>
                </NavLink>
              </li>

            </ul>
          </div>
        </li>
        {/* end:: Level - Document */}


        {/* ::begin:: Level - Report */}
        <li className={`${getMenuItemActive('/', true)} menu-item-submenu`}>
          <NavLink className="menu-link menu-toggle" to="/" id='menu_import_export'>
            <span className="svg-icon menu-icon">
              {/* <Icon.GraphUp /> */}
              <Icon.Clipboard2Data />
            </span>
            <span className="menu-text">
              <FormattedMessage id="GENERAL.REPORT" />
            </span>
            <i className="menu-arrow" />
          </NavLink>

          <div className="menu-submenu">

            <ul className="menu-subnav">

              <li className="menu-item menu-item-parent">
                <span className="menu-link">
                  <span className="menu-text">
                    <FormattedMessage id="GENERAL.REPORT" />
                  </span>
                </span>
              </li>

              {/* Order-List start */}
              <li className={`${getMenuItemActive(ModuleRoutes.ORDER_LIST)} pl-6`}>
                <NavLink className="menu-link" to={ModuleRoutes.ORDER_LIST_PAGE} id='menu_order_list'>
                  <span className="menu-text">
                    <FormattedMessage id='REPORT.ORDER_LIST.CARD_TITLE' />
                  </span>
                </NavLink>
              </li>

              {/* Shipping start */}
              <li className={`${getMenuItemActive(ModuleRoutes.SHIPPING)} pl-6`}>
                <NavLink className="menu-link" to={ModuleRoutes.SHIPPING_PAGE} id='menu_setting_shippings'>
                  <span className="menu-text">
                    <FormattedMessage id="MENU.ASIDE.SETTING.SHIPPINGS" />
                  </span>
                </NavLink>
              </li>

              {/* Digital product start */}
              {settings?.digital_product?.status &&
                <li className={`${getMenuItemActive(ModuleRoutes.DIGITAL_PRODUCT)} pl-6`}>
                  <NavLink className="menu-link" to={ModuleRoutes.DIGITAL_PRODUCT_PAGE} id='menu_setting_digital_products'>
                    <span className="menu-text">
                      <FormattedMessage id="MENU.ASIDE.SETTING.DIGITAL_PRODUCTS" />
                    </span>
                  </NavLink>
                </li>
              }

              {/* LogEmail start */}
     
                <li className={`${getMenuItemActive(ModuleRoutes.EMAIL_LOG)} pl-6`}>
                  <NavLink className="menu-link" to={ModuleRoutes.EMAIL_LOG_PAGE} id='menu_setting_email_log'>
                    <span className="menu-text">
                      <FormattedMessage id="MENU.ASIDE.SETTING.EMAIL_LOG" />
                    </span>
                  </NavLink>
                </li>

            </ul>
          </div>
        </li>
        {/* ::end:: Level - Report */}


        {/* ::begin:: Level - Customer */}
        <li className={`${getMenuItemActive(ModuleRoutes.CUSTOMER)}`}>
          <NavLink className="menu-link" to={ModuleRoutes.CUSTOMER_PAGE} id='menu_customer'>
            <span className="svg-icon menu-icon">
              <Icon.Customer />
            </span>
            <span className="menu-text">
              <FormattedMessage id="CUSTOMER.TITLE_PLURAL" />
            </span>
          </NavLink>
        </li>
        {/* ::end:: Level - Customer */}


        {/* ::begin:: Level - Supplier */}
        <li className={`${getMenuItemActive(ModuleRoutes.SUPPLIER)}`}>
          <NavLink className="menu-link" to={ModuleRoutes.SUPPLIER_PAGE} id='menu_supplier'>
            <span className="svg-icon menu-icon">
              <Icon.Buildings />
            </span>
            <span className="menu-text">
              <FormattedMessage id="SUPPLIER.TITLE" />
            </span>
          </NavLink>
        </li>
        {/* ::end:: Level - Supplier */}


        {/* ::begin:: Level - Catalog */}
        <li className={`${getMenuItemActive('/', true)} menu-item-submenu`}>

          <NavLink className="menu-link menu-toggle" to="/" id='menu_catalog'>
            <span className="svg-icon menu-icon">
              <Icon.Catalog />
            </span>
            <span className="menu-text">
              <FormattedMessage id="MENU.ASIDE.CATALOG" />
            </span>
            <i className="menu-arrow" />
          </NavLink>

          <div className="menu-submenu ">

            <ul className="menu-subnav">

              <li className="menu-item menu-item-parent">
                <span className="menu-link">
                  <span className="menu-text">
                    <FormattedMessage id="MENU.ASIDE.CATALOG" />
                  </span>
                </span>
              </li>

              {/* Product start */}
              <li className={`${getMenuItemActive(ModuleRoutes.PRODUCT)} pl-6`}>
                <NavLink className="menu-link" to={ModuleRoutes.PRODUCT_PAGE} id='menu_product'>
                  <span className="menu-text">
                    <FormattedMessage id="PRODUCT.TITLE_PLURAL" />
                  </span>
                </NavLink>
              </li>

              {/* Listing if there is no token  */}
              {CONNECTION_SETTINGS.filter(item => item.type !== "shipper").every(token => !(token.active && token.product(development) &&
                connections?.some(market => market.name.includes(token.name)))) && (
                  <li className={`${getMenuItemActive(ModuleRoutes.LISTINGS)} pl-6`}>
                    <NavLink className="menu-link" to={ModuleRoutes.LISTINGS} id='menu_listings'>
                      <span className="menu-text">
                        <FormattedMessage id="MENU.ASIDE.LISTINGS" />
                      </span>
                    </NavLink>
                  </li>
                )}

              {/* Listing if there is token  */}
              {CONNECTION_SETTINGS.filter(item => item.type !== "shipper").map(token => (token.active && token.product(development) && connections?.length > 0 &&
                connections.map((market, i) => (market.name.includes(token.name) &&
                  <li className={`${getMenuItemActive(token.listingRoute(market.id))} pl-6`} key={i}>
                    <NavLink className="menu-link" to={token.listingRoute(market.id)} id={`menu_${token.name}_listings`}>
                      <span className="menu-text">
                        <FormattedMessage id="PRODUCT_LISTINGS.TITLE" values={{ shopName: token.label }} /> <br /> {market[market.name].market_name && market[market.name].market_name}
                      </span>
                    </NavLink>
                  </li>
                ))
              ))}

            </ul>

          </div>
        </li>
        {/* ::end:: Level - Catalog */}


        {/* ::begin:: Level - Import Export */}
        <li className={`${getMenuItemActive('/', true)} menu-item-submenu`}>
          <NavLink className="menu-link menu-toggle" to="/" id='menu_import_export'>
            <span className="svg-icon menu-icon">
              <Icon.ImportExport />
            </span>
            <span className="menu-text">
              <FormattedMessage id="MENU.ASIDE.IMPORT_EXPORT" />
            </span>
            <i className="menu-arrow" />
          </NavLink>

          <div className="menu-submenu">

            <ul className="menu-subnav">

              <li className="menu-item menu-item-parent">
                <span className="menu-link">
                  <span className="menu-text">
                    <FormattedMessage id="MENU.ASIDE.IMPORT_EXPORT" />
                  </span>
                </span>
              </li>

              {/* Export-Controller */}
              <li className={`${getMenuItemActive(ModuleRoutes.EXPORT)} pl-6`}>
                <NavLink className="menu-link" to={ModuleRoutes.EXPORT} id='menu_export'>
                  <span className="menu-text">
                    <FormattedMessage id="IMPORT_EXPORT.EXPORT" />
                  </span>
                </NavLink>
              </li>

              {/* Import-Controller */}
              <li className={`${getMenuItemActive(ModuleRoutes.IMPORT)} pl-6`}>
                <NavLink className="menu-link" to={ModuleRoutes.IMPORT} id='menu_import'>
                  <span className="menu-text">
                    <FormattedMessage id="IMPORT_EXPORT.IMPORT" />
                  </span>
                </NavLink>
              </li>

            </ul>
          </div>
        </li>
        {/* ::end:: Level - Import Export */}


        {/* ::begin:: Level - Connections */}
        <li className={`${getMenuItemActive(ModuleRoutes.CONNECTIONS)}`}>
          <NavLink className="menu-link menu-toggle" to={ModuleRoutes.CONNECTIONS} id='menu_connections'>
            <span className="svg-icon menu-icon">
              <Icon.Connection />
            </span>
            <span className="menu-text">
              <FormattedMessage id="MENU.ASIDE.CONNECTIONS" />
            </span>
          </NavLink>
        </li>
        {/* ::end:: Level - Connections */}

      </ul>
      {/* end::Menu Nav */}


      {/* start::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>

        {/* ::begin:: Icon-Controller */}
        {development &&
          <li className={`${getMenuItemActive(ModuleRoutes.ICON)}`}>
            <NavLink className="menu-link" to={ModuleRoutes.ICON} id='menu_icons'>
              <span className="svg-icon menu-icon">
                <Icon.Salevali />
              </span>
              <span className="menu-text">
                <FormattedMessage id="ICONS" />
              </span>
            </NavLink>
          </li>
        }
        {/* ::end:: Icon-Controller */}


        {/* ::begin:: Level - Support */}
        <li className={`${getMenuItemActive(ModuleRoutes.SUPPORT)}`}>
          <NavLink className="menu-link" to={ModuleRoutes.SUPPORT_PAGE_ALL} id='menu_support'>
            <span className="svg-icon menu-icon">
              <Icon.Support />
            </span>
            <span className="menu-text">
              <FormattedMessage id="MENU.ASIDE.SUPPORT" />
              {unreadTickets > 0 &&
                <span className="label label-lg label-rounded label-light-primary ml-auto font-weight-bolder">
                  {unreadTickets > 99 ? "99+" : unreadTickets}
                </span>
              }
            </span>
          </NavLink>
        </li>
        {/* ::end:: Level - Support */}


        {/* ::begin:: Level - Support */}
        <li className={`${getMenuItemActive(ModuleRoutes.SETTINGS)} border-top border-secondary-50`}>
          <NavLink className="menu-link" to={ModuleRoutes.SETTINGS} id='menu_settings'>
            <span className="svg-icon menu-icon">
              <Icon.Settings />
            </span>
            <span className="menu-text">
              <FormattedMessage id="SETTINGS.TITLE" />
            </span>
          </NavLink>
        </li>
        {/* ::end:: Level - Support */}


      </ul>
      {/* end::Menu Nav */}

    </div>
  );
}
