import React from 'react';
import { SVFormControl } from '_metronic/_partials/controls';
import { Field, useFormikContext } from 'formik';
import { Row, Col } from 'react-bootstrap';
import { useIntl } from 'react-intl';



export function FeatureEditForm({ checkFeatureByValue, id }) {

  const { formatMessage: intl } = useIntl();
  const { values: { name } } = useFormikContext();

  const INPUT = [
    {
      name: "name",
      label: "PRODUCT.FEATURES.NAME",
      feedbackLabel: checkFeatureByValue(name, id) ? intl({ id: "PRODUCT.GENERAL.FEATURE_NAME_WARNING" }) : true,
    },
    {
      name: "value",
      label: "PRODUCT.FEATURES.VALUE",
    }
  ]

  return (
    <Row>
      {INPUT.map((item, i) => (
        <Col sm={12} key={i}>
          <Field component={SVFormControl}
            {...{
              name: item.name,
              label: intl({ id: item.label }),
              value: item.value,
              withFeedbackLabel: true,
              feedbackLabel: item.feedbackLabel || true,
              required: true,
            }}
          />
        </Col>
      ))}
    </Row>
  );
}
