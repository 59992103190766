import React from 'react'
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon } from '_metronic/_icons';
import clsx from 'clsx';



export default function NewButton({ label, link, values, variant = "primary", className }) {
  const { formatMessage: intl } = useIntl();
  return (
    <Link to={link} className={clsx(`btn btn-${variant} svg-icon svg-icon-sm font-weight-bold`, className)} title={intl({ id: label }, { name: values })} id='btn_new'>
      <Icon.Plus />
      <span className="d-none d-sm-inline ml-2">
        <FormattedMessage id={label} values={{ name: values }} />
      </span>
    </Link>
  )
}
