import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { importExportActions } from '../../_redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { validator } from './ImportCategoryValidators';
import * as columnFormatters from './ImportCategoryColumnFormatters';
import { useIntl } from 'react-intl';
import { CATEGORY_LEVEL_OPTIONS } from 'app/modules/settings/category/_context/CategoryUIHelper';



export function ImportCategoriesTable() {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();

  const { importPreparation, checkData } = useSelector((state) => ({
    importPreparation: state.importExport.importPreparation,
    checkData: state.importExport.checkData,
  }));

  const tableRowHandler = (oldValue, newValue, row, column) => {

    const updatedRow = {
      ...row,
      [column.dataField]: newValue,
    };

    dispatch(importExportActions.importPreparationUpdate(updatedRow));
  };

  const editorStyle = () => ({ height: "32px", padding: "0.5rem 0.75rem" })


  const COLUMNS = [
    {
      dataField: '#',
      text: '#',
      headerStyle: { minWidth: '50px', width: '3%' },
      headerClasses: 'text-center',
      classes: 'font-weight-bold text-center',
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      editable: false,
    },
    {
      dataField: 'id',
      text: intl({ id: 'IMPORT.CATEGORY.CATEGORY_ID' }),
      headerFormatter: columnFormatters.HeaderFormatterRequired,
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: (cell, row) => columnFormatters.CategoryIdFormatter(cell, row, checkData),
      validator: (cell) => validator.categoryIdValidator(cell, checkData, intl),
    },
    {
      dataField: 'parent_id',
      text: intl({ id: 'IMPORT.CATEGORY.CATEGORY_PARENT_ID' }),
      headerFormatter: columnFormatters.HeaderFormatterRequired,
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.CategoryParentIdFormatter,
      validator: (cell, row) => validator.categoryParentIdValidator(cell, row, intl),
    },
    {
      dataField: 'level',
      text: intl({ id: 'IMPORT.CATEGORY.CATEGORY_LEVEL' }),
      headerFormatter: columnFormatters.HeaderFormatterRequired,
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.CategoryLevelFormatter,
      validator: (cell) => validator.categoryLevelValidator(cell, intl),
      editor: { type: Type.SELECT, options: CATEGORY_LEVEL_OPTIONS },
    },
    {
      dataField: 'name',
      text: intl({ id: 'IMPORT.CATEGORY.CATEGORY_NAME' }),
      headerFormatter: columnFormatters.HeaderFormatterRequired,
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.CategoryNameFormatter,
      validator: (cell) => validator.categoryNameValidator(cell, intl),
    },
    {
      dataField: 'path',
      text: intl({ id: 'IMPORT.CATEGORY.CATEGORY_PATH' }),
      headerFormatter: columnFormatters.HeaderFormatterRequired,
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.CategoryPathFormatter,
      validator: (cell) => validator.categoryPathValidator(cell, intl),
    },
  ];


  return (
    <BootstrapTable
      keyField="id"
      data={importPreparation || []}
      columns={COLUMNS}
      wrapperClasses="table-responsive"
      classes="table table-head-custom table-vertical-center overflow-hidden"
      bootstrap4
      striped
      condensed
      cellEdit={cellEditFactory({
        mode: 'dbclick',
        blurToSave: true,
        autoSelectText: true,
        afterSaveCell: tableRowHandler,
      })}
    />
  );
}
