import React from 'react';
import ActionTypes from './actionTypes';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';



export class ListingActions {

  // Get Products Actions
  static getProductsRequest = (queryParams) => {
    return {
      type: ActionTypes.GET_PRODUCTS_REQUEST,
      payload: {
        queryParams
      },
    };
  };

  static getProductsSuccess = (products, count) => {
    return {
      type: ActionTypes.GET_PRODUCTS_SUCCESS,
      payload: {
        products,
        count
      },
    };
  };

  static getProductsFail = (error) => {
    return {
      type: ActionTypes.GET_PRODUCTS_FAIL,
      payload: {
        error
      },
    };
  };




  // Get Product by id Actions 
  static getProductByIdRequest = (id, filterParams) => {
    return {
      type: ActionTypes.GET_PRODUCT_BY_ID_REQUEST,
      payload: {
        id,
        filterParams
      },
    };
  };

  static getProductByIdSuccess = (product) => {
    return {
      type: ActionTypes.GET_PRODUCT_BY_ID_SUCCESS,
      payload: {
        product
      },
    };
  };

  static getProductByIdFail = (error) => {
    return {
      type: ActionTypes.GET_PRODUCT_BY_ID_FAIL,
      payload: {
        error
      },
    };
  };



  // inventory Listings PreparationRequest Actions
  static ebayInventoryListingsPreparationRequest = (id, market_id, method) => {
    return {
      type: ActionTypes.EBAY_INVENTORY_LISTINGS_PREPARATION_REQUEST,
      payload: {
        id,
        market_id,
        method
      },
    };
  };

  static ebayInventoryListingsPreparationSuccess = (product, market_id, method) => {
    return {
      type: ActionTypes.EBAY_INVENTORY_LISTINGS_PREPARATION_SUCCESS,
      payload: {
        product,
        market_id,
        method
      },
    };
  };

  static ebayInventoryListingsPreparationFail = (error) => {
    return {
      type: ActionTypes.EBAY_INVENTORY_LISTINGS_PREPARATION_FAIL,
      payload: {
        error
      },
    };
  };



  // inventory Listings Category Actions
  static ebayInventoryListingsCategory = (market_id, name) => {
    return {
      type: ActionTypes.EBAY_INVENTORY_LISTINGS_CATEGORY,
      payload: {
        market_id,
        name
      },
    };
  };

  static ebayInventoryListingsCategorySuccess = (categorySuggestions) => {
    return {
      type: ActionTypes.EBAY_INVENTORY_LISTINGS_CATEGORY_SUCCESS,
      payload: {
        categorySuggestions
      },
    };
  };

  static ebayInventoryListingsCategoryFail = (error) => {
    return {
      type: ActionTypes.EBAY_INVENTORY_LISTINGS_CATEGORY_FAIL,
      payload: {
        error
      },
    };
  };



  // Create or replace Inventory Actions
  static ebayCreateOrReplaceInventoryRequest = (id, listing, method) => {
    return {
      type: ActionTypes.EBAY_CREATE_OR_REPLACE_INVENTORY_REQUEST,
      payload: {
        id,
        listing,
        method
      },
    };
  };

  static ebayCreateOrReplaceInventorySuccess = () => {
    return {
      type: ActionTypes.EBAY_CREATE_OR_REPLACE_INVENTORY_SUCCESS,
      payload: {},
    };
  };

  static ebayCreateOrReplaceInventoryFail = (error) => {
    return {
      type: ActionTypes.EBAY_CREATE_OR_REPLACE_INVENTORY_FAIL,
      payload: {
        error
      },
    };
  };

  static ebayInventoryUpdateRequest = (id, market_id, updatedEbayInventory, queryParams) => {
    return {
      type: ActionTypes.EBAY_INVENTORY_UPDATE_REQUEST,
      payload: {
        id,
        market_id,
        updatedEbayInventory,
        queryParams
      },
    };
  };

  static ebayInventoryUpdateSuccess = () => {
    return {
      type: ActionTypes.EBAY_INVENTORY_UPDATE_SUCCESS,
      payload: {},
    };
  };

  static ebayInventoryUpdateFail = (error) => {
    return {
      type: ActionTypes.EBAY_INVENTORY_UPDATE_FAIL,
      payload: {
        error
      },
    };
  };



  // Publish Ebay Inventory Actions
  static publishEbayInventoryRequest = (params) => {
    return {
      type: ActionTypes.PUBLISH_EBAY_INVENTORY_REQUEST,
      payload: {
        ...params
      },
    };
  };

  static publishEbayInventorySuccess = () => {
    return {
      type: ActionTypes.PUBLISH_EBAY_INVENTORY_SUCCESS,
      payload: {},
    };
  };

  static publishEbayInventoryFail = (error) => {
    const errString = error?.message;
    const startIndex = errString.indexOf('{');
    const jsonString = errString.substring(startIndex);
    const jsonObject = JSON.parse(jsonString);
    const errorMessage = jsonObject.errors[0].message;
    toast.error(errorMessage);
    return {
      type: ActionTypes.PUBLISH_EBAY_INVENTORY_FAIL,
      payload: {
        error
      },
    };
  };



  // Listing Market Places Actions
  static listingMarketPlacesRequest = () => {
    return {
      type: ActionTypes.LISTING_MARKET_PLACES_REQUEST,
    };
  };

  static listingMarketPlacesSuccess = (marketPlaces) => {
    return {
      type: ActionTypes.LISTING_MARKET_PLACES_SUCCESS,
      payload: {
        marketPlaces
      },
    };
  };

  static listingMarketPlacesFail = (error) => {
    return {
      type: ActionTypes.LISTING_MARKET_PLACES_FAIL,
      payload: {
        error
      },
    };
  };



  // inventory Listings PreparationRequest Actions Kaufland
  static kauflandInventoryListingsPreparationRequest = (id, market_id, method) => {
    return {
      type: ActionTypes.KAUFLAND_INVENTORY_LISTINGS_PREPARATION_REQUEST,
      payload: {
        id,
        market_id,
        method
      },
    };
  };

  static kauflandInventoryListingsPreparationSuccess = (product, categorySuggestions, method) => {
    return {
      type: ActionTypes.KAUFLAND_INVENTORY_LISTINGS_PREPARATION_SUCCESS,
      payload: {
        product,
        categorySuggestions,
        method
      },
    };
  };

  static kauflandInventoryListingsPreparationFail = (error) => {
    return {
      type: ActionTypes.KAUFLAND_INVENTORY_LISTINGS_PREPARATION_FAIL,
      payload: {
        error
      },
    };
  };



  // Create or update Inventory Actions for Kaufland
  static createOrUpdateKauflandInventory = (id, listing, method, actionId) => {
    return {
      type: ActionTypes.UPDATE_PRODUCT_FOR_KAUFLAND_REQUEST,
      payload: {
        id,
        listing,
        method,
        actionId
      },
    };
  };

  static updateArtikelForKauflandSuccess = () => {
    return {
      type: ActionTypes.UPDATE_PRODUCT_FOR_KAUFLAND_SUCCESS,
      payload: {},
    };
  };

  static updateArtikelForKauflandFail = (error) => {
    return {
      type: ActionTypes.UPDATE_PRODUCT_FOR_KAUFLAND_FAIL,
      payload: {
        error
      },
    };
  };


  // Get Kaufland Products Actions
  static getKauflandProducts = (market_id) => {
    return {
      type: ActionTypes.KAUFLAND_SHOP_PRODUCTS,
      payload: {
        market_id
      },
    };
  };

  static getKauflandProductsSuccess = (data) => {

    const successLength = data?.success?.length
    if (successLength > 0) {
      const sku = data.success.map((item, index) => `${index + 1}-) ${item}`);
      const skuMessage = sku.join('\n');
      toast.success(<FormattedMessage id='PRODUCT_LISTINGS.SYNC_SUCCESS' values={{ length: successLength, sku: skuMessage, marketName: 'Kaufland' }} />);
    }

    const fail = data?.fail;
    if (fail > 0) {
      toast.warning(<FormattedMessage id='PRODUCT_LISTINGS.EBAY_SYNC.FAIL' values={{ length: fail, marketName: 'Kaufland' }} />);
    }

    return {
      type: ActionTypes.KAUFLAND_SHOP_PRODUCTS_SUCCESS,
      payload: {
        data
      },
    };
  };

  static getKauflandProductsFail = (error) => {
    return {
      type: ActionTypes.KAUFLAND_SHOP_PRODUCTS_FAIL,
      payload: {
        error
      },
    };
  };

  // Publish Kaufland Inventory Actions
  // static publishKauflandInventoryRequest = (id, market_id, queryParams) => {
  //   return {
  //     type: ActionTypes.KAUFLAND_CREATE_OR_REPLACE_INVENTORY_REQUEST,
  //     payload: {
  //       id,
  //       market_id,
  //       queryParams
  //     },
  //   };
  // };

  // static publishKauflandInventorySuccess = () => {

  //   toast.success(<FormattedMessage id='PRODUCT_LISTINGS.KAUFLAND_CREATE.SUCCESS' />);

  //   return {
  //     type: ActionTypes.KAUFLAND_CREATE_OR_REPLACE_INVENTORY_SUCCESS,
  //     payload: {},
  //   };
  // };

  // static publishKauflandInventoryFail = (error) => {
  //   toast.error(<FormattedMessage id='PRODUCT_LISTINGS.KAUFLAND_CREATE.FAIL' />);
  //   return {
  //     type: ActionTypes.KAUFLAND_CREATE_OR_REPLACE_INVENTORY_FAIL,
  //     payload: {
  //       error
  //     },
  //   };
  // };



  //FIXME:  Publish and Un Publish Kaufland Inventory Actions
  static publishUnPublishKauflandInventoryRequest = (params) => {
    return {
      type: ActionTypes.KAUFLAND_DELETE_INVENTORY_REQUEST,
      payload: {
        ...params
      },
    };
  };

  static publishUnPublishKauflandInventorySuccess = () => {
    // toast.success(<FormattedMessage id='PRODUCT_LISTINGS.KAUFLAND_DELETE.SUCCESS' />);
    return {
      type: ActionTypes.KAUFLAND_DELETE_INVENTORY_SUCCESS,
      payload: {
        message: "Löschen war erfolgreich"
      },
    };
  };

  static publishUnPublishKauflandInventoryFail = error => {
    toast.error(<FormattedMessage id='PRODUCT_LISTINGS.KAUFLAND_DELETE.FAIL' />);
    return {
      type: ActionTypes.KAUFLAND_DELETE_INVENTORY_FAIL,
      payload: {
        error
      },
    };
  };



  // Handling Actions
  static variantDeleteHandler = (id) => {
    return {
      type: ActionTypes.INVENTORY_VARIANT_DELETE_HANDLER,
      payload: {
        id
      },
    };
  };

  static variantUpdateHandler = (rowIndex, update) => {
    return {
      type: ActionTypes.INVENTORY_VARIANT_UPDATE_HANDLER,
      payload: {
        rowIndex,
        update
      },
    };
  };



  //Clean up the redux state
  static cleanUpReducer = (cleanedUpStates) => {
    return {
      type: ActionTypes.CLEAN_UP_REDUX_STATE,
      payload: { cleanedUpStates },
    };
  };



  // Policies update
  static updateEbayPolicies = (market_id) => {
    return {
      type: ActionTypes.EBAY_UPDATE_POLICIES,
      payload: {
        market_id
      },
    };
  };

  static updateEbayPoliciesSuccess = () => {
    return {
      type: ActionTypes.EBAY_UPDATE_POLICIES_SUCCESS,
    };
  };

  static updateEbayPoliciesFail = (error) => {
    return {
      type: ActionTypes.EBAY_UPDATE_POLICIES_FAIL,
      payload: {
        error
      },
    };
  };



  // Get Ebay Products Actions
  static getEbayProducts = (market_id) => {
    return {
      type: ActionTypes.EBAY_SHOP_PRODUCTS,
      payload: {
        market_id
      },
    };
  };

  static getEbayProductsSuccess = (data) => {

    const successLength = data?.success?.length
    if (successLength > 0) {
      const sku = data.success.map((item, index) => `${index + 1}-) ${item}`);
      const skuMessage = sku.join('\n');
      toast.success(<FormattedMessage id='PRODUCT_LISTINGS.SYNC_SUCCESS' values={{ length: successLength, sku: skuMessage, marketName: 'eBay' }} />);
    }

    const fail = data?.fail;
    if (fail > 0) {
      toast.warning(<FormattedMessage id='PRODUCT_LISTINGS.EBAY_SYNC.FAIL' values={{ length: fail, marketName: 'eBay' }} />);
    }

    if (successLength === 0 && fail === 0) {
      toast.warning("Es gibt keine schleppbaren Produkte");
    }
    return {
      type: ActionTypes.EBAY_SHOP_PRODUCTS_SUCCESS,
      payload: {
        data
      },
    };
  };

  static getEbayProductsFail = (error) => {
    return {
      type: ActionTypes.EBAY_SHOP_PRODUCTS_FAIL,
      payload: {
        error
      },
    };
  };


  // Get Ebay Products TradingApi Migration Actions
  static getEbayMigrationsProducts = (user_id, market_id) => {
    return {
      type: ActionTypes.EBAY_SHOP_PRODUCTS_MIGRATIONS,
      payload: {
        user_id,
        market_id
      },
    };
  };

  static getEbayMigrationsProductsSuccess = (count) => {

    if (count?.success && Array.isArray(count.success)) {
      const successLength = count.success.length
      const listingId = count.success.map((item) => item.listingId)
      //FIXME:
      // successLength > 0 && toast.success(`${successLength} products successfully migrated. listingId: ${listingId}`);
      successLength > 0 && toast.success(<FormattedMessage id='PRODUCT.REDUX.EBAY_MIGRATION.SUCCESS' values={{ length: successLength, id: listingId }} />);
    }

    if (count?.fail && Array.isArray(count.fail)) {
      const failLength = count.fail.length
      const listingId = count.fail.map((item) => item.listingId + ":" + item.message)
      //FIXME:
      // failLength > 0 && toast.warning(`${failLength} items already migrated. ${listingId}`);
      failLength > 0 && toast.warning(<FormattedMessage id='PRODUCT.REDUX.EBAY_MIGRATION.FAIL' values={{ length: failLength, id: listingId }} />);
    }

    return {
      type: ActionTypes.EBAY_SHOP_PRODUCTS_MIGRATIONS_SUCCESS,
      payload: {
        count
      },
    };
  };

  static getEbayMigrationsProductsFail = (error) => {
    toast.error(error.error.message);
    return {
      type: ActionTypes.EBAY_SHOP_PRODUCTS_MIGRATIONS_FAIL,
      payload: {
        error
      },
    };
  };


  static getEbayMigrationsProductsSuccess = (count) => {

    if (count?.success && Array.isArray(count.success)) {
      const successLength = count.success.length
      const listingId = count.success.map((item) => item.listingId)
      //FIXME:
      successLength > 0 && toast.success(`${successLength} products successfully migrated. listingId: ${listingId}`);
    }

    if (count?.fail && Array.isArray(count.fail)) {
      const failLength = count.fail.length
      const listingId = count.fail.map((item) => item.listingId + ":" + item.message)
      //FIXME:
      failLength > 0 && toast.warning(`${failLength} items already migrated. ${listingId}`);
    }

    return {
      type: ActionTypes.EBAY_SHOP_PRODUCTS_MIGRATIONS_SUCCESS,
      payload: {
        count
      },
    };
  };

  static getEbayMigrationsProductsFail = (error) => {
    toast.error(error.error.message);
    return {
      type: ActionTypes.EBAY_SHOP_PRODUCTS_MIGRATIONS_FAIL,
      payload: {
        error
      },
    };
  };
}
