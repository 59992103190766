import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useShippingsUIContext } from '../_context/ShippingsUIContext';
import Spinner from '_metronic/_partials/loading/Spinner';
import { ShippingsActions } from '../_redux/actions';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Portal } from 'react-portal';



export function ShippingDeleteDialog() {

  const dispatch = useDispatch();
  const [isDeleting, setIsDeleting] = useState(false);

  const UIContext = useShippingsUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showDeleteLabel,
    onHide: UIContext.closeDeleteLabelDialog,
    onHidePrint: UIContext.closePrintLabelsDialog,
    service: UIContext.deleteLabelParams?.service,
    shippingId: UIContext.deleteLabelParams?.id,
    relation: UIContext.deleteLabelParams?.relation,
  }), [
    UIContext.showDeleteLabel,
    UIContext.closeDeleteLabelDialog,
    UIContext.closePrintLabelsDialog,
    UIContext.deleteLabelParams
  ]);

  const loading = useSelector(state => state.documents.loading, shallowEqual);

  useEffect(() => {
    if (!loading && isDeleting) {
      setIsDeleting(false);
      UIProps.onHide();
      UIProps.onHidePrint();
      dispatch(ShippingsActions.cleanStatesInStore({ label: [] }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loading, isDeleting]);


  const deleteLabelAction = () => {
    if (UIProps.shippingId) {
      dispatch(ShippingsActions.deleteShipping(UIProps.shippingId));
    }
    setIsDeleting(true);
  };


  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={UIProps.show} onHide={UIProps.onHide} size="md" backdrop="static" aria-labelledby="contained-modal-delete-label-title-center" centered>

        <Modal.Body className="text-center line-height-lg h6 py-10 mb-0">
          {UIProps.service === "deutsche_post"
            ? <FormattedMessage id="DOCUMENT.SHIPPING.DEUTSCHE_POST.DELETE.MESSAGE" values={{ b: chunks => <u>{chunks}</u> }} />
            : <FormattedMessage id="DOCUMENT.SHIPPING.DELETE_LABEL" />
          }

          {UIProps?.relation?.length ?
            <span className='pl-1'>
              <FormattedMessage id="DOCUMENT.SHIPPING.DELETE_LABEL_RELATION" />
              <div className='mt-1'>
                {UIProps?.relation?.map((item, index) => (
                  <span key={index}>
                    <span className='text-danger'>{item?.id}</span>
                    <span> ({item?.type})</span>
                    {index < UIProps?.relation?.length - 1 && ', '}
                  </span>
                ))}
              </div>
            </span> : <></>
          }
        </Modal.Body>

        <Modal.Footer className="flex-nowrap p-0 overflow-hidden">
          <Button variant='ios' className="border-right" onClick={UIProps.onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant='ios' className="text-danger" onClick={deleteLabelAction} id="btn_delete_modal">
            <FormattedMessage id="GENERAL.DELETE" />
            <Spinner loading={loading} color="danger" />
          </Button>
        </Modal.Footer>

      </Modal>
    </Portal>
  );
}
