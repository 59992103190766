import React from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import UserInfoDropdown from "../user-info/UserInfoDropdown";



export default function CardItems({ Content }) {
  return (
    Content?.length
      ? Content?.map((item, i) => (
        <Row key={i} className={Content.length - 1 === i ? "mb-3" : "mb-5"}>

          <Col sm="4" className="d-flex align-items-center text-dark-50 font-size-h6 font-weight-bold">
            <FormattedMessage id={item.label} />
            {item.info && <UserInfoDropdown description={<FormattedMessage id={item.info} />} />}
          </Col>

          <Col sm="8" className="font-size-h6 font-weight-bolder">
            {item.content}
          </Col>

        </Row>
      ))
      : <></>
  )
}