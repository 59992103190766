import ActionTypes from "./actionTypes";



export class digitalProductsActions {

  // Get digital product action types
  static getDigitalProducts = (queryParams) => {
    return {
      type: ActionTypes.GET_DIGITAL_PRODUCTS,
      payload: {
        queryParams
      },
    };
  };

  static getDigitalProductsSuccess = (digitalProducts, count) => {
    return {
      type: ActionTypes.GET_DIGITAL_PRODUCTS_SUCCESS,
      payload: {
        digitalProducts,
        count
      },
    };
  };

  static getDigitalProductsFail = () => {
    return {
      type: ActionTypes.GET_DIGITAL_PRODUCTS_FAIL,
    };
  };


  // Get customer details action types
  static getCustomerDetails = (documentId) => {
    return {
      type: ActionTypes.GET_CUSTOMER_DETAILS,
      payload: {
        documentId
      },
    };
  };

  static getCustomerDetailsSuccess = (data) => {
    return {
      type: ActionTypes.GET_CUSTOMER_DETAILS_SUCCESS,
      payload: {
        data,
      },
    };
  };

  static getCustomerDetailsFail = (error) => {
    return {
      type: ActionTypes.GET_CUSTOMER_DETAILS_FAIL,
      payload: {
        error
      },
    };
  };


  // Send Mail Key action types
  static sendMailKey = (ids, queryParams) => {
    return {
      type: ActionTypes.SEND_MAIL_KEY,
      payload: {
        ids,
        queryParams
      },
    };
  };

  static sendMailKeySuccess = (data) => {
    return {
      type: ActionTypes.SEND_MAIL_KEY_SUCCESS,
      payload: {
        data,
      },
    };
  };

  static sendMailKeyFail = (error) => {
    return {
      type: ActionTypes.SEND_MAIL_KEY_FAIL,
      payload: {
        error
      },
    };
  };


  // Key Reserve action types
  static deleteKeyReserve = (ids, queryParams) => {
    return {
      type: ActionTypes.DELETE_KEY_RESERVE,
      payload: {
        ids,
        queryParams
      },
    };
  };

  static deleteKeyReserveSuccess = (data) => {
    return {
      type: ActionTypes.DELETE_KEY_RESERVE_SUCCESS,
      payload: {
        data,
      },
    };
  };

  static deleteKeyReserveFail = (error) => {
    return {
      type: ActionTypes.DELETE_KEY_RESERVE_FAIL,
      payload: {
        error
      },
    };
  };


  static cleanStatesInStore = (cleanedStates) => {
    return {
      type: ActionTypes.CLEAN_STATES_IN_STORE,
      payload: {
        cleanedStates
      },
    };
  };

}