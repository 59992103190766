import React, { useEffect, useMemo } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { SupplierActions } from "../_redux/actions";
import { FormattedMessage } from "react-intl";
import { useSupplierUIContext } from "../_context/SupplierUIContext";



export function SuppliersDeleteDialog({ id, show, multiple, onHide }) {

  const dispatch = useDispatch();

  const UIContext = useSupplierUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    setIds: UIContext.setIds,
  }), [UIContext]);

  // if !id we should close modal
  useEffect(() => {
    !id && onHide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const deleteSupplierAction = () => {
    const newIds = multiple ? UIProps.ids : [id];
    dispatch(SupplierActions.deleteSupplier(newIds));
    UIProps.setIds([]);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} size="sm" backdrop="static" aria-labelledby="example-modal-sizes-title-sm" centered>

      <Modal.Body className="text-center line-height-md h6 py-10 mb-0">
        {multiple
          ? <>
            <FormattedMessage id="SUPPLIER.DELETE_SUPPLIERS_BODY1" />
            <span className="label label-xl label-inline label-light-danger mx-1">
              {UIProps.ids.length}
            </span>
            <FormattedMessage id="SUPPLIER.DELETE_SUPPLIERS_BODY2" />
          </>
          : <FormattedMessage id="SUPPLIER.DELETE_SUPPLIERS" />
        }
      </Modal.Body>

      <Modal.Footer className="flex-nowrap p-0 overflow-hidden">

        <Button variant='ios' className="border-right" onClick={onHide} id="btn_cancel_modal">
          <FormattedMessage id="GENERAL.CANCEL" />
        </Button>

        <Button variant='ios' className="text-danger" onClick={deleteSupplierAction} id="btn_delete_modal">
          <FormattedMessage id="GENERAL.DELETE" />
        </Button>

      </Modal.Footer>
    </Modal>
  );
}