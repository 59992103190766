import React from 'react';
import { QuantityStyle } from '_metronic/_helpers';



export function QuantityColumnFormatter(cell, row, rowIndex, { market_id }) {
  const shopValue = row?.inventories?.find((item) => item.market_id === market_id)?.shopValue;
  const newCell = shopValue?.quantity ?? cell;

  return <span>{QuantityStyle(newCell)}</span>;
}
