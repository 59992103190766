import React from 'react'
import { Col, Row } from 'react-bootstrap'
import EmailSettings from './email-edit/EmailSettings'
import PasswordSettings from './password-edit/PasswordSettings'
import EmailEdit from './email-edit/EmailEdit'
import PasswordEdit from './password-edit/PasswordEdit'
import AccountSettings from './account-delete/AccountSettings'
import AccountDelete from './account-delete/AccountDelete'
import ClearCache from './clear-cache/ClearCache'



export default function LoginSettings() {
  return (
    <>
      <EmailEdit />
      <PasswordEdit />
      <AccountDelete />

      <Row>
        <Col lg={6}>
          <EmailSettings />
        </Col>
        <Col lg={6}>
          <PasswordSettings />
        </Col>
        <Col lg={6}>
          <AccountSettings />
        </Col>
        <Col lg={6}>
         <ClearCache />
        </Col>
      </Row>

    </>
  )
}
