import React from "react";
import { FormattedMessage } from "react-intl";



export const maxValidation = (name) => <FormattedMessage id="GENERAL.MAX" values={{ name }} />
export const minValidation = (name) => <FormattedMessage id="GENERAL.MIN" values={{ name }} />



export const isbnFunctions = isbn => {

  isbn = isbn?.replace(/-/g, '');

  if (!isbn) {
    return true
  } else if (isbn === "0000000000") {
    return false;
  } else if (isbn?.length === 10) {
    return isValidISBN10(isbn);
  } else if (isbn?.length === 13 && !isNaN(isbn)) {
    return isValidISBN13(isbn);
  }
  return false;

  //isbn-10 kontrolu ise
  function isValidISBN10(isbn) {
    let sum = 0;
    for (let i = 0; i < isbn.length; i++) {
      //isbn arası - varsa geç
      if (isbn.charAt(i) === '-') {
        continue;
      }
      //isbn son hanesi X, x ise 10 olarak hesapla
      if (isbn.charAt(i) === ('X' || 'x')) {
        sum += 10;
        continue;
      }
      sum += parseInt(isbn.charAt(i)) * (10 - i);
    };
    return sum % 11 === 0;
  };

  //isbn-13 kontrolu ise
  function isValidISBN13(isbn) {
    let sum = 0;
    for (let i = 0; i < isbn.length; i++) {
      //isbn arası - varsa geç
      if (isbn.charAt(i) === '-') {
        continue;
      }
      sum += (i % 2 === 0 ? 1 : 3) * parseInt(isbn.charAt(i));
    };
    return sum % 10 === 0;
  };

};


export const eanFunctions = ean => {
  //13 rakamdan oluşan ean kontrolü
  if (!ean) {
    return true;
  }
  if (ean === "0000000000000") {
    return false;
  }
  let even = 0;
  let odd = 0;

  for (let i = 0; i < ean.length - 1; i++) {
    if (i % 2 === 0) {
      even += parseInt(ean.charAt(i));
    } else {
      odd += parseInt(ean.charAt(i));
    }
  }

  let checksum = (10 - ((even + 3 * odd) % 10)) % 10;

  return parseInt(ean.charAt(12)) === checksum;
};


export const upcFunctions = upc => {
  //12 rakamdan oluşan upc kontrolü
  if (!upc) {
    return true
  }

  if (upc === "000000000000") {
    return false;
  }

  var total = 0;
  for (var i = 0; i < 12; i++) {
    var digit = Number(upc[i]);
    if (i % 2 === 0) {
      total += digit * 3;
    } else {
      total += digit;
    }
  }

  return total % 10 === 0;
};


export const capitalizeFirstLetter = sentence => {
  var words = sentence?.trim()?.split(" ");
  if (words.length > 0) {
    words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
  }
  return words.join(" ");
};