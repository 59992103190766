import React from 'react'
import { Card, CardBody, CardSubHeader, SVFormControl, SVReactSelect } from '_metronic/_partials/controls';
import { maxValidation, emailRegExp } from '_metronic/_helpers';
import { NEWSLETTER_STATUS } from '../../../_context/AdminUIHelper';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Form, Field } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';
import * as Yup from 'yup';



const INPUTS = [
  {
    name: "name",
    label: "AUTH.REGISTER.NAME",
    required: true,
  },
  {
    name: "surname",
    label: "AUTH.REGISTER.SURNAME",
    required: true,
  },
  {
    type: "email",
    name: "email",
    label: "GENERAL.EMAIL",
    required: true,
  },
  {
    name: "newsletter",
    label: "Newsletter",
    component: SVReactSelect,
    options: NEWSLETTER_STATUS,
  },
];


export function General({ initialValues, handleSubmit, btnRef }) {

  const { formatMessage: intl } = useIntl();

  const tempUserEditSchema = Yup.object().shape({
    name: Yup.string()
      .min(1, intl({ id: 'AUTH.REGISTER.NAME.LENGTH_MIN' }))
      .max(100, maxValidation("100"))
      .required(intl({ id: 'AUTH.VALIDATION.NAME_REQUIRED' })),
    surname: Yup.string()
      .min(1, intl({ id: 'AUTH.REGISTER.NAME.LENGTH_MIN' }))
      .max(100, maxValidation("100"))
      .required(intl({ id: 'AUTH.VALIDATION.SURNAME_REQUIRED' })),
    email: Yup.string()
      .max(100, maxValidation("100"))
      .matches(emailRegExp, intl({ id: 'GENERAL.EMAIL_EMAIL' }))
      .email(intl({ id: 'GENERAL.EMAIL_EMAIL' }))
      .required(intl({ id: "GENERAL.EMAIL_REQUIRED" })),
  });


  return (
    <Card className="card-stretch gutter-b">

      <CardSubHeader icon={<Icon.Tag />} title={<FormattedMessage id="GENERAL.GENERAL" />} />

      <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={tempUserEditSchema} onSubmit={handleSubmit}>

        <Form autoComplete="off">
          <CardBody>
            <Row>
              {INPUTS.map((item, i) => (
                <Col key={i} lg={item.size ?? "6"}>
                  <Field component={item.component || SVFormControl}
                    {...{
                      name: item.name,
                      type: item.type,
                      options: item.options,
                      label: intl({ id: item.label }),
                      withFeedbackLabel: true,
                      feedbackLabel: true,
                      required: item.required
                    }}
                  />
                </Col>
              ))}
            </Row>
          </CardBody>

          <button type="submit" className='d-none' ref={btnRef} />
        </Form>

      </Formik>

    </Card>
  );
}
