import React from "react";
import { DashboardUIProvider } from "./_context/DashboardUIContext";
import { CustomerDetails } from "./dashboard-dialogs/CustomerDetails";
import { ProductDetails } from "./dashboard-dialogs/ProductDetails";
import DashboardCard from "./DashboardCard";



export default function AdminDashboard() {
  return (
    <DashboardUIProvider>
      <CustomerDetails />
      <ProductDetails />
      <DashboardCard />
    </DashboardUIProvider>
  );
}
