import React from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Icon } from '_metronic/_icons';



export default function ResetButton({ onClick }) {
  return (
    <Button variant='light-primary' className="font-weight-bold svg-icon svg-icon-sm ml-2" onClick={onClick} id='btn_reset'>
      <Icon.ArrowClockWise />
      <span className="d-none d-sm-inline ml-2">
        <FormattedMessage id="GENERAL.RESET" />
      </span>
    </Button>
  )
}