/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useMemo, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { SupportActions } from "../_redux/actions";
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls'
import { TICKET_STATUS, TICKET_TAG, TICKET_PRIORITY, TICKET_TOPIC } from '../_context/SupportUIHelper';
import { Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { SanitizeHtml, convertText, UIDates } from '_metronic/_helpers';
import { useSupportUIContext } from '../_context/SupportUIContext';
import PicturesModal from '../support-dialogs/PicturesModal';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { FormattedMessage } from 'react-intl'
import { BASE_URL } from 'constants/apiUrl';
import { Icon } from '_metronic/_icons';
import clsx from 'clsx';



export default function SupportPreviewCard({ ticket }) {

  const history = useHistory();

  const handleEditReply = (sessionId) => {
    UIContext.setSessionId(sessionId);
    history.push(ModuleRoutes.EDIT_TICKET_FN(ticket.id));
  };

  const { loading, role } = useSelector((state) => ({
    loading: state.supports.loading,
    role: state.auth.user.role,
  }));

  const UIContext = useSupportUIContext();
  const UIProps = useMemo(() => ({
    setTicketStatus: UIContext.setTicketStatus,
  }), [UIContext]);


  const dispatch = useDispatch();

  // Big Picture Modal
  const [showModal, setShowModal] = useState(false);
  const [imageIndex, setImageIndex] = useState({ sessionIndex: null, imageIndex: null });

  const findStatus = TICKET_STATUS.find(item => item.value === ticket?.status)
  const findTag = TICKET_TAG.find(item => item.value === ticket?.tag)
  const findPriority = TICKET_PRIORITY.find(item => item.value === ticket?.priority)
  const findTopic = TICKET_TOPIC.find(item => item.value === ticket?.topic)

  const selectStatus = (status) => {
    UIProps.setTicketStatus({ id: ticket.id, status })
  }

  /* Tag, Priority, and Read will not be changed using modal. So, we dispatch it directly, not like status. */
  const selectProp = (name, value) => {
    dispatch(SupportActions.ticketProps(ticket.id, { [name]: value }));
  }

  // Clear function to reset the field
  const clearField = (field) => {
    dispatch(SupportActions.ticketProps(ticket.id, { [field]: '' }));
  };

  const [copyIcon, setCopyIcon] = useState(<Icon.Copy />);

  const copyToClipboard = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopyIcon(<Icon.Check className="text-success" />);
      setTimeout(() => {
        setCopyIcon(<Icon.Copy />);
      }, 1500);
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  }


  return (
    <Card>

      <CardSubHeader
        icon={<Icon.LeftText />}
        title={<><FormattedMessage id='GENERAL.SUBJECT' /> {loading && !ticket?.id ? "" : `: "${ticket?.subject ? ticket?.subject : ticket?.id}"`}</>}
        className="flex-nowrap"
        info={role === "644c41e257d397eb064fe0f9"
          ? <a type="button" className="ml-3" onClick={() => copyToClipboard(`[${ticket.number} Ticket] "${ticket?.subject ? ticket?.subject : ticket?.id}"`)} id="btn_card_copy_reply">
            <span className="d-flex invisible text-dark-25 svg-icon svg-icon-sm" id="icon_copy_success">
              {copyIcon}
            </span>
          </a>
          : null
        }
      >

        <div className="d-flex flex-nowrap justify-content-end">

          {role === "644c41e257d397eb064fe0f9"
            ? <>
              {/* Topic */}
              <Dropdown className="dropdown-inline ml-2" drop="down">
                <Dropdown.Toggle size='sm' variant="outline-secondary" className="font-weight-bold" id="dropdown-topic" disabled={loading}>
                  {findTopic?.label || <FormattedMessage id="SUPPORT.FILTER.TOPIC" />}
                </Dropdown.Toggle>
                <Dropdown.Menu id='dropdown_tag_grouping'>
                  <ul className="navi navi-hover">
                    {TICKET_TOPIC.map((opt, index) => (
                      <Fragment key={index}>
                        <li className="navi-item font-weight-bold px-2">
                          <a className={clsx("navi-link svg-icon svg-icon-sm rounded py-2", opt.value === ticket?.topic && "active")} onClick={() => opt.value !== ticket.topic ? selectProp("topic", opt.value) : null} id={`ticket_${opt.value}`}>
                            <span className="navi-text">{opt.label}</span>
                            {opt.value === ticket?.topic && <Icon.Check className="ml-auto" />}
                          </a>
                        </li>
                      </Fragment>
                    ))}

                    <Dropdown.Divider />

                    <li className="navi-item">
                      <Dropdown.Item className='navi-link font-weight-bolder text-danger justify-content-center bg-white py-1' onClick={() => clearField("topic")} id="ticket_clear_topic">
                        <FormattedMessage id='GENERAL.CLEAR' />
                      </Dropdown.Item>
                    </li>

                  </ul>
                </Dropdown.Menu>
              </Dropdown>

              {/* Tag */}
              <Dropdown className="dropdown-inline ml-2" drop="down">
                <Dropdown.Toggle size='sm' variant="outline-secondary" className="font-weight-bold" id="dropdown-tag" disabled={loading}>
                  {findTag?.label || <FormattedMessage id="SUPPORT.FILTER.TAG" />}
                </Dropdown.Toggle>
                <Dropdown.Menu id='dropdown_tag_grouping'>
                  <ul className="navi navi-hover">
                    {TICKET_TAG.map((opt, index) => (
                      <Fragment key={index}>
                        <li className="navi-item font-weight-bold px-2">
                          <a className={clsx("navi-link svg-icon svg-icon-sm rounded py-2", opt.value === ticket?.tag && "active")} onClick={() => opt.value !== ticket.tag ? selectProp("tag", opt.value) : null} id={`ticket_${opt.value}`}>
                            <span className="navi-text">{opt.label}</span>
                            {opt.value === ticket?.tag && <Icon.Check className="ml-auto" />}
                          </a>
                        </li>
                      </Fragment>
                    ))}

                    <Dropdown.Divider />

                    <li className="navi-item">
                      <Dropdown.Item className='navi-link font-weight-bolder text-danger justify-content-center bg-white py-1' onClick={() => clearField("tag")} id="ticket_clear_tag">
                        <FormattedMessage id='GENERAL.CLEAR' />
                      </Dropdown.Item>
                    </li>
                  </ul>
                </Dropdown.Menu>
              </Dropdown>


              {/* Priority*/}
              <Dropdown className="dropdown-inline ml-2" drop="down">
                <Dropdown.Toggle size='sm' variant="outline-secondary" className="font-weight-bold" id="dropdown-priority" disabled={loading}>
                  <span className={`text-${findPriority?.className} mr-3`}>
                    {findPriority?.icon}
                  </span>
                  <span className="navi-text">
                    {findPriority?.label || <FormattedMessage id="SUPPORT.FILTER.PRIORITY" />}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu id='dropdown_priority_grouping'>
                  <ul className="navi navi-hover">
                    {TICKET_PRIORITY.map((opt, index) => (
                      <Fragment key={index}>
                        <li className="navi-item font-weight-bold px-2">
                          <a className={clsx("navi-link svg-icon svg-icon-sm rounded py-2", opt.value === ticket?.priority && "active")} onClick={() => opt.value !== ticket.priority ? selectProp("priority", opt.value) : null} id={`ticket_${opt.value}`}>
                            <span className={clsx("svg-icon svg-icon-sm mr-2", `text-${opt?.className}`)}>
                              {opt.icon}
                            </span>
                            <span className="navi-text">
                              {opt.label}
                            </span>
                            {opt.value === ticket?.priority && <Icon.Check className="ml-auto" />}
                          </a>
                        </li>
                      </Fragment>
                    ))}

                    <Dropdown.Divider />

                    <li className="navi-item">
                      <Dropdown.Item className='navi-link font-weight-bolder text-danger justify-content-center bg-white py-1' onClick={() => clearField("priority")} id="ticket_clear_tag">
                        <FormattedMessage id='GENERAL.CLEAR' />
                      </Dropdown.Item>
                    </li>
                  </ul>
                </Dropdown.Menu>
              </Dropdown>

            </>
            : null
          }

          {/* Status */}
          {ticket?.id && ticket?.status !== 'open' && ticket?.status !== 'reopened' && role !== "644c41e257d397eb064fe0f9"
            ? <>

              <span className={`font-weight-bold ml-auto btn btn-${findStatus?.className}`} style={{ pointerEvents: 'none' }}>
                {findStatus?.label}
              </span>

              {ticket?.status === 'completed' && UIDates.hourComparison(ticket.created_at, 72)
                ? <OverlayTrigger overlay={<Tooltip id="tooltip-support-reopen"><FormattedMessage id="SUPPORT.REOPEN_INFO" /></Tooltip>}>
                  <Button variant='outline-success' className="font-weight-bold ml-2" onClick={() => selectStatus("reopened")} id="btn_reopen">
                    <FormattedMessage id='SUPPORT.REOPEN_TICKET' />
                  </Button>
                </OverlayTrigger>
                : null
              }

            </>
            : <Dropdown className="dropdown-inline ml-2" drop="down">
              <Dropdown.Toggle size='sm' variant={findStatus?.className} className="font-weight-bold" id="dropdown-status" disabled={loading}>
                {findStatus?.label}
              </Dropdown.Toggle>
              <Dropdown.Menu id='dropdown_status_grouping'>
                <ul className="navi navi-hover">
                  {TICKET_STATUS.map((opt, index) => ((['open', 'reopened', 'completed', 'archived'].includes(opt?.value) && role !== "644c41e257d397eb064fe0f9")
                    ? null
                    : (ticket.status === "open" && opt.value === "reopened") || (ticket.status !== "open" && opt.value === "open")
                      ? null
                      : <Fragment key={index}>
                        {TICKET_STATUS.length - 1 === index && <li className="navi-separator my-1" />}
                        <li className="navi-item font-weight-bold px-2">
                          <a className={clsx("navi-link svg-icon svg-icon-sm rounded py-2", opt.value === ticket?.status && "active")} onClick={() => opt.value !== ticket.status ? selectStatus(opt.value) : null} id={`ticket_${opt.value}`}>
                            <span className={clsx("navi-text", TICKET_STATUS.length - 1 === index && "text-success")}>{opt.action}</span>
                            {opt.value === ticket?.status && <Icon.Check className="ml-auto" />}
                          </a>
                        </li>
                      </Fragment>
                  ))}
                </ul>
              </Dropdown.Menu>
            </Dropdown>
          }

        </div>
      </CardSubHeader>

      <CardBody>
        {loading && !ticket?.id
          ? <div className="d-flex justify-content-center align-items-center my-30">
            <div className="spinner spinner-primary spinner-xl mr-15"></div>
          </div>
          : ticket?.session?.map((item, sessionIndex) => (
            <div key={sessionIndex} className={`position-relative m-10 ${item.creator_type === "TEAM" ? "ticket-team" : ""}`}>
              <div className={`ticket-author ${item.creator_type === "TEAM" ? "ticket-author-team" : ""}`} style={{ zIndex: 19 }}>
                <span className="ticket-icon text-uppercase">
                  {item.creator_type === "TEAM"
                    ?
                    <span className='svg-icon svg-icon-xl'><Icon.Salevali /></span>
                    : ticket?.contact_name
                      ? ticket?.contact_name?.split(' ').length > 1
                        ? `${ticket?.contact_name?.split(' ')[0][0]}${ticket?.contact_name?.split(' ')[1][0]}`
                        : `${ticket?.contact_name?.split(' ')[0][0]}${ticket?.contact_name?.split(' ')[0][1]}`
                      : "AZ"
                  }
                </span>
              </div>

              <div className="ticket-body"
                style={{
                  borderColor: item.creator_type === "TEAM"
                    ? item.read ? "#FFE2E5" : "#F54E60"
                    : item.read ? "#fde5b4" : "#FFA800"
                }}
              >
                <div className={"d-flex justify-items-middle preview"}>
                  <div className="font-weight-bold font-size-lg" dangerouslySetInnerHTML={{ __html: convertText(SanitizeHtml(item.message)), }}></div>
                  <div className="d-flex align-items-end mb-4">

                    {item.edited
                      ? <div className="text-dark-50 font-size-xs ml-2">
                        <FormattedMessage id='GENERAL.EDITED' />
                      </div>
                      : null
                    }

                    {role === "644c41e257d397eb064fe0f9" && item.creator_type === "TEAM" && ticket?.session?.length - 1 === sessionIndex
                      ? <OverlayTrigger placement="top" overlay={<Tooltip id="quick-edit-tooltip"><FormattedMessage id='GENERAL.EDIT' /></Tooltip>}>
                        <a type="button" className="ml-3" onClick={() => handleEditReply(item.id)} id="btn_edit_reply" disabled={loading}>
                          <span className="d-flex invisible text-dark-25 svg-icon svg-icon-sm">
                            <Icon.Pencil />
                          </span>
                        </a>
                      </OverlayTrigger>
                      : null
                    }

                  </div>
                </div>

                {item.images?.length > 0 &&
                  <div className='border-top pt-5'>
                    {item.images.map((image, imageIndex) => (
                      <Button key={imageIndex}
                        variant='link'
                        onClick={() => { setShowModal(true); setImageIndex({ sessionIndex, imageIndex }) }}
                        className="img-thumbnail mr-5 mb-5"
                        style={{ padding: '1px', width: '9rem', height: '9rem', overflow: 'hidden' }}
                      >
                        <img
                          src={image.file ? `${image.thumbnail}` : `${BASE_URL}/${image.thumbnail}`}
                          width="100%"
                          height="100%"
                          style={{ pointerEvents: 'none', objectFit: 'cover' }}
                          alt={`supports ${imageIndex}`}
                        />
                      </Button>
                    ))}
                  </div>}

                <div className={item.creator_type === "TEAM" ? "text-right mt-5" : "text-left mt-5"}>
                  <div className='font-weight-bold'>{item.creator_type === "TEAM" ? "Salevali Support Team" : `${ticket?.contact_name} ${ticket?.contact_surname ? ticket?.contact_surname : ''}`}</div>
                  <div className='small'>{UIDates.formatDateTime(item?.created_at)}</div>
                </div>

              </div>

            </div>

          )).reverse()}
      </CardBody>

      <PicturesModal
        show={showModal}
        onHide={() => { setImageIndex(null); setShowModal(false) }}
        imageIndex={imageIndex?.imageIndex}
        images={ticket?.session?.[imageIndex?.sessionIndex]?.images}
      />

    </Card>
  )
}
