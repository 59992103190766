import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { useProductListingsUIContext } from '../../_context/ProductListingsUIContext';
import { getSelectRow, sortCaret, headerSortingClasses } from '_metronic/_helpers';
import { RemotePagination } from '_metronic/_partials/controls';
import * as columnFormatters from './column-formatters';
import { useIntl } from 'react-intl';



export function AmazonListingsTable({ setPaginationQuery, setSearchQuery, queryParams, setOrderQuery }) {

  const { formatMessage: intl } = useIntl();

  const UIContext = useProductListingsUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    tokenId: UIContext.tokenId,
    openSyncAlertDialog: UIContext.openSyncAlertDialog,
    openAddProducts: UIContext.openAddProducts,
    getAddProductsUrl: UIContext.getAddProductsUrl
  }),
    [UIContext.ids, UIContext.setIds, UIContext.tokenId, UIContext.openSyncAlertDialog, UIContext.openAddProducts, UIContext.getAddProductsUrl]
  );

  const { products, count, isLoading } = useSelector((state) => ({
    products: state.listings.products,
    count: state.listings.count,
    isLoading: state.listings.isLoading
  }));

  const COLUMNS = [
    {
      dataField: 'name',
      text: intl({ id: 'GENERAL.NAME' }),
      sort: true,
      sortCaret,
      headerSortingClasses,
      headerStyle: { width: '40%' },
      formatter: columnFormatters.NameColumnFormatter,
      formatExtraData: {
        market_id: UIProps.tokenId,
        openPrepareEbayListingEdit: UIProps.openPrepareEbayListingEdit,
        openProductDetailsDialog: UIProps.openProductDetailsDialog
      }
    },
    {
      dataField: 'identities.asin',
      text: intl({ id: 'GENERAL.ASIN' }),
      sort: true,
      sortCaret,
      headerSortingClasses,
      headerClasses: 'text-nowrap',
      headerStyle: { minWidth: '50px', width: '10%' },
    },
    {
      dataField: 'inventories',
      text: intl({ id: 'GENERAL.STATUS' }),
      //sort: true,
      sortCaret,
      headerSortingClasses,
      classes: 'text-center',
      headerClasses: 'text-center',
      headerStyle: { minWidth: '150px', width: '15%' },
      formatter: columnFormatters.StatusColumnFormatter,
      formatExtraData: {
        market_id: UIProps.market_id,
        isLoading
      },
    },
    {
      dataField: 'prepare',
      text: intl({ id: 'GENERAL.PREPARE' }),
      editable: false,
      classes: 'text-center',
      headerClasses: 'text-center',
      headerStyle: { minWidth: '70px', width: '7%' },
      formatter: columnFormatters.ActionsColumnFormatterPublish,
      formatExtraData: {
        openSyncAlertDialog: UIProps.openSyncAlertDialog
      },
    },
    {
      dataField: 'quantity',
      text: intl({ id: 'PRODUCT.GENERAL.QUANTITY' }),
      classes: 'font-weight-bolder text-center',
      headerClasses: 'text-center',
      headerStyle: { minWidth: '50px', width: '10%' },
      formatter: columnFormatters.QuantityColumnFormatter,
      formatExtraData: {
        market_id: UIProps.tokenId
      }
    },
    {
      dataField: 'pricing',
      text: intl({ id: 'PRODUCT.TABLE.PRICE_GROSS' }),
      classes: 'font-weight-bolder text-right text-dark-75',
      headerClasses: 'text-right',
      headerStyle: { minWidth: '110px', width: '10%' },
      formatter: columnFormatters.PricingColumnFormatter,
      formatExtraData: {
        market_id: UIProps.tokenId
      }
    },
    {
      dataField: 'action',
      text: intl({ id: 'GENERAL.ACTIONS' }),
      classes: 'text-center',
      headerClasses: 'text-center',
      headerStyle: { minWidth: '100px', width: '15%' },
      editable: false,
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        market_id: UIProps.tokenId,
        getAddProductsUrl: UIProps.getAddProductsUrl
      }
    }
  ];

  return (
    <>
      <BootstrapTable
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden"
        striped
        bootstrap4
        bordered={false}
        keyField="id"
        data={products || []}
        columns={COLUMNS}
        condensed
        selectRow={getSelectRow({
          ids: UIProps.ids,
          setIds: UIProps.setIds,
          entities: products != null ? products : []
        })}
      />
      <RemotePagination
        queryParams={queryParams.pagination}
        setQueryParams={setPaginationQuery}
        totalCount={count}
        entitiesCount={products?.length}
        loading={isLoading}
        countForPagination={count}
        paginationSize={8}
      />
    </>
  );
}
