import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { ConnectionsActions } from "../../_redux/actions";
import { SVFormControl } from '_metronic/_partials/controls';
import { DEV_DOMAIN } from 'constants/apiUrl';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";



export const amazonGetTokenUrl = `https://sellercentral.amazon.de/apps/authorize/consent?application_id=amzn1.sellerapps.app.b9f3f6ad-0693-48d4-bfd6-0a0b54270a24&version=beta&state=${process.env.NODE_ENV !== 'production' ? "local" : window.location.hostname.includes(DEV_DOMAIN) ? "develop" : "online"}`

const INPUTS = [
  {
    name: 'amazon.market_name',
    label: 'CONNECTIONS.SHOP.MARKET_NAME',
    lg: "12"
  }
];


export default function AmazonForm({ children, connectionId, initialValue, loading, onHide }) {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const { amazonForEdit, success, connections } = useSelector(state => ({
    amazonForEdit: state.connections.amazonForEdit,
    success: state.connections.success,
    connections: state.connections.entities.filter(connection => connection.name === "amazon") ?? []
  }), shallowEqual);

  const existingMarketName = connections.map(connection => connection.amazon.market_name) ?? [];

  const validationSchema = Yup.object().shape({
    ...(!connectionId && {
      amazon: Yup.object().shape({
        market_name: Yup.string()
          .required(intl({ id: 'GENERAL.REQUIRED' }))
          .test('match', intl({ id: "CONNECTIONS.SHOP.TOKEN_MATCH" }), (value) => !existingMarketName.includes(value))
      })
    })
  });

  const handleSubmit = (values) => {

    dispatch(ConnectionsActions.amazonConnection(values));
    const errors = validationSchema.validateSync(values, { abortEarly: false }).inner;

    if (!errors) {
      window.location.href = amazonGetTokenUrl;
    }

  };

  useEffect(() => {
    return () => {
      dispatch(ConnectionsActions.clearLoadingEffects())
    }
  }, [dispatch])


  return (
    <Formik enableReinitialize={true} initialValues={amazonForEdit} validationSchema={validationSchema} onSubmit={handleSubmit}>
      <Form autoComplete='off'>

        <Modal.Body className='p-0'>
          <Row className='px-8'>
            {INPUTS.map((input, i) => (
              <Col key={i} lg={input.lg ?? "6"}>
                <Field component={SVFormControl}
                  {...{
                    name: input.name,
                    type: input.type,
                    label: intl({ id: input.label }),
                    withFeedbackLabel: true,
                    feedbackLabel: true,
                    required: true,
                    disabled: loading || (connectionId && !input.editable),
                  }}
                />
              </Col>
            ))}
          </Row>

          {children}

        </Modal.Body>

        <Modal.Footer>
          <Button variant="light" onClick={onHide} disabled={loading} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button type='submit' variant='primary' className='ml-2' disabled={loading || success} id='btn_get_token'>
            <FormattedMessage id="GENERAL.GET_TOKEN" />
          </Button>

        </Modal.Footer>
      </Form>

    </Formik>
  )
}