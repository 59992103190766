import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { SupportActions } from "../_redux/actions";
import { useSupportUIContext } from "../_context/SupportUIContext";
import { Portal } from "react-portal";



export function TicketDeleteDialog({ id, show, onHide }) {

  const dispatch = useDispatch();

  const UIContext = useSupportUIContext();
  const UIProps = useMemo(() => ({
    queryParams: UIContext.queryParams,
    ticketId: UIContext.ticketId,
    setTicketId: UIContext.setTicketId,
  }), [UIContext]);

  const deleteTicketAction = () => {
    dispatch(SupportActions.deleteTicket(id, UIProps.queryParams));
    closeModal();
  };

  const closeModal = () => {
    UIProps.setTicketId(undefined);
    onHide();
  };


  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={show} onHide={closeModal} size="sm" backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered>

        <Modal.Body className="text-center line-height-md h6 py-10 mb-0">
          <FormattedMessage id="SUPPORT.DELETE_TICKET_CONTENT" values={{ ticketName: UIProps.ticketId }} />
        </Modal.Body>

        <Modal.Footer className="flex-nowrap p-0 overflow-hidden">

          <Button variant='ios' className="border-right" onClick={closeModal} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant='ios' className="text-danger" onClick={deleteTicketAction} id="btn_delete_modal">
            <FormattedMessage id="GENERAL.DELETE" />
          </Button>

        </Modal.Footer>

      </Modal>
    </Portal>
  );
}
