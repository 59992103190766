import ActionTypes from './actionTypes';

export class AmazonListingsActions {
  // Find listing item by ASIN number on Amazon
  static amazonFindListingItemRequest = (payload) => ({
    type: ActionTypes.AMAZON_FIND_LISTING_ITEM,
    payload
  });
  static amazonFindListingItemResolves = ({ item, error, isError }) => ({
    type: isError ? ActionTypes.AMAZON_FIND_LISTING_ITEM_FAIL : ActionTypes.AMAZON_FIND_LISTING_ITEM_SUCCESS,
    payload: { item, error }
  });

  // Create new Amazon listing by existing product
  static amazonCreateListingRequest = (payload) => ({
    type: ActionTypes.AMAZON_CREATE_LISTING,
    payload
  });
  static amazonCreateListingResolves = ({ item, error, isError }) => ({
    type: isError ? ActionTypes.AMAZON_CREATE_LISTING_FAIL : ActionTypes.AMAZON_CREATE_LISTING_SUCCESS,
    payload: { item, error }
  });

  // Get product by id from database
  static getProductByIdRequest = (productId) => ({
    type: ActionTypes.GET_PRODUCT_BY_ID,
    payload: { productId }
  });
  static getProductByIdResolves = ({ product, error, isError }) => ({
    type: isError ? ActionTypes.GET_PRODUCT_BY_ID_FAIL : ActionTypes.GET_PRODUCT_BY_ID_SUCCESS,
    payload: { product, error }
  });

  // reset state to initial state
  static amazonListingReset = () => ({
    type: ActionTypes.AMAZON_LISTING_RESET
  });
}
