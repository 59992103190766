import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { ticketMiddleware, tokenErrorHandler } from 'middlewares';
import { SupportActions } from './actions';
import ActionTypes from './actionTypes';



// Create Ticket
function* createTicket({ payload: { ticket, upload } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);
    const response = yield call(ticketMiddleware.createTicket, accessToken, ticket);

    if (response && upload.is) {
      const sessionId = 'new';
      yield call(ticketMiddleware.uploadFile, accessToken, response.data.id, upload.file, sessionId);
    }
    yield put(SupportActions.createTicketSuccess(response.data));
    yield put(SupportActions.getTickets());

  } catch (err) {
    yield call(tokenErrorHandler, err, SupportActions.createTicketFail());
  }
}


// Update Ticket
function* updateTicket({ payload: { ticketId, data, upload, sessionId } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(ticketMiddleware.putTicket, accessToken, ticketId, data, sessionId);

    if (response && upload.is) {
      yield call(ticketMiddleware.uploadFile, accessToken, ticketId, upload.file, sessionId);
    }

    yield put(SupportActions.updateTicketSuccess());
    yield put(SupportActions.getTicketById(ticketId));

  } catch (err) {
    yield call(tokenErrorHandler, err, SupportActions.updateTicketFail());
  }
}


// Delete Image
function* deleteImage({ payload: { imageId, sessionId, ticketId } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const updatedTicket = yield call(ticketMiddleware.deleteImage, accessToken, imageId, sessionId, ticketId);
    yield put(SupportActions.deleteImageSuccess(ticketId, updatedTicket.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, SupportActions.deleteImageFail());
  }
}


// Ticket Props
function* ticketProps({ payload: { id, data } }) {
  try {

    const accessToken = yield select(state => state.auth.access_token);

    const updatedTicket = yield call(ticketMiddleware.patchTicket, accessToken, id, data);

    yield put(SupportActions.ticketPropsSuccess(id, updatedTicket.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, SupportActions.updateTicketFail());
  }
}


// Get Tickets
function* getTickets({ payload: { dateRange, queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const countTickets = yield call(ticketMiddleware.getCount, accessToken, dateRange, queryParams);
    const response = yield call(ticketMiddleware.getTickets, accessToken, dateRange, queryParams);

    yield put(SupportActions.getTicketsSuccess(response.data, countTickets.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, SupportActions.getTicketsFail());
  }
}


// Get Ticket Counts
export function* getTicketCounts() {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const countTickets = yield call(ticketMiddleware.getCount, accessToken, "", { search: "", filter: { status: "open" } });
    yield put(SupportActions.countTicketsSuccess(countTickets.data.unread));

  } catch (err) {
    yield put(SupportActions.countTicketsFail(JSON.stringify(err?.response)));
  }
}


// Delete Ticket
function* deleteTicket({ payload: { id, queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(ticketMiddleware.deleteTicket, accessToken, id);
    yield put(SupportActions.deleteTicketSuccess(id));

    yield fork(getTickets, { payload: { queryParams } });

  } catch (err) {
    yield call(tokenErrorHandler, err, SupportActions.deleteTicketFail(err.response.data.error.msg));
  }
}


// Get Tickets
function* getTicketById({ payload: { id } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(ticketMiddleware.getTicketById, accessToken, id);

    yield put(SupportActions.getTicketByIdSuccess(response.data));

    /* yield call(ticketMiddleware.ticketRead, accessToken, id); */

  } catch (err) {
    yield call(tokenErrorHandler, err, SupportActions.getTicketByIdFail());
  }
}


export function* supportSaga() {
  yield takeEvery(ActionTypes.GET_TICKETS, getTickets);
  yield takeEvery(ActionTypes.DELETE_TICKET, deleteTicket);
  yield takeEvery(ActionTypes.CREATE_TICKET, createTicket);
  yield takeEvery(ActionTypes.UPDATE_TICKET, updateTicket);
  yield takeEvery(ActionTypes.DELETE_IMAGE, deleteImage);
  yield takeEvery(ActionTypes.TICKET_PROPS, ticketProps);
  yield takeEvery(ActionTypes.COUNT_TICKETS, getTicketCounts);
  yield takeEvery(ActionTypes.GET_TICKET_BY_ID, getTicketById);
}
