import React from 'react';
import { BanksDeleteDialog } from './BanksDeleteDialog';
import { BanksEditDialog } from './edit/BankEditDialog';
import { BankCard } from './BankCard';



export function Bank() {
  return (
    <>
      <BanksEditDialog />
      <BanksDeleteDialog />
      <BankCard />
    </>
  );
}
