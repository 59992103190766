import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Row, Col } from 'react-bootstrap';
import { DocumentActions } from '../_redux/actions';
import { useDocumentsUIContext } from '../_context/DocumentsUIContext';
import { getCountryList, addressType } from '_metronic/_helpers';
import { customerTypeOptions } from '../../customers/_context/CustomersUIHelpers';
import { initialDocument } from '../_context/DocumentsUIHelpers';
import { CloseButton } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl';
import { useLang } from '_metronic/i18n';
import { Icon } from '_metronic/_icons';
import { Portal } from 'react-portal';
import { isEmpty } from 'lodash';



export function CustomerDetailsDialog() {

  const locale = useLang();
  const dispatch = useDispatch();

  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showCustomerDetailsDialog,
    onHide: UIContext.closeCustomerDetailsDialog,
    documentId: UIContext.documentId,
  }), [
    UIContext.closeCustomerDetailsDialog,
    UIContext.documentId,
    UIContext.showCustomerDetailsDialog,
  ]);

  const { customer_details } = useSelector(state => ({
    customer_details: state.documents.getDocumentCustomerDetails.customer_details,
  }));

  const { id, number, type, name, email, phone, company, invoice_address, delivery_address, } = customer_details

  useEffect(() => {
    UIProps.documentId && dispatch(DocumentActions.getDocumentCustomerDetails(UIProps.documentId));
  }, [dispatch, UIProps.documentId]);

  // if !id we should close modal
  useEffect(() => {
    !UIProps.documentId && UIProps.onHide();
  }, [UIProps]);


  useEffect(() => {
    return () => {
      dispatch(DocumentActions.cleanStatesInStore({
        documentForEdit: initialDocument
      }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UIProps.documentId]);


  return (
    <Portal node={document && document.getElementById('modal-portal')}>
      <Modal show={UIProps.show} onHide={UIProps.onHide} aria-labelledby="example-modal-sizes-title-md" size="md" centered>

        <Modal.Header>

          <Modal.Title>
            <Icon.Person className="mr-3" />
            <FormattedMessage id="DOCUMENT.CUSTOMER_TITLE" />
          </Modal.Title>

          <CloseButton onClick={UIProps.onHide} />

        </Modal.Header>

        <Modal.Body className='overflow-hidden px-10 py-2'>

          {customer_details && !isEmpty(customer_details) && (

            <div className='d-flex flex-column font-weight-bold text-break'>

              {(id || number) &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.CUSTOMER_NUMBER" />
                  </Col>
                  <Col lg='7'>
                    {number ? number : id}
                  </Col>
                </Row>
              }

              {type &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.TYPE" />
                  </Col>
                  <Col lg='7'>
                    {customerTypeOptions.find(item => item.value === type)?.label}
                  </Col>
                </Row>
              }

              {name &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.NAME" />
                  </Col>
                  <Col lg='7'>
                    {name}
                  </Col>
                </Row>
              }

              {email &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.EMAIL" />
                  </Col>
                  <Col lg='7'>
                    {email}
                  </Col>
                </Row>
              }

              {phone &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.PHONE" />
                  </Col>
                  <Col lg='7'>
                    {phone}
                  </Col>
                </Row>
              }

              {company &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.COMPANY" />
                  </Col>
                  <Col lg='7'>
                    {company}
                  </Col>
                </Row>
              }

              {/* --------------------------------- Address --------------------------------- */}

              {[invoice_address, delivery_address].map((address, i) => (
                <Row className={`${i === 0 ? "border-bottom" : ""} py-3`} key={i}>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    {addressType.find(item => item.value === address?.type)?.label ?? <FormattedMessage id="GENERAL.ADDRESS" />}
                  </Col>
                  <Col lg='7'>
                    <div>{address?.name}</div>
                    <div>{address?.company}</div>
                    <div>{address?.address_line1} {address?.address_line2}</div>
                    <div>{address?.post_code} {address?.city}</div>
                    <div>{getCountryList(locale).find(code => code.value === address?.country_code)?.label}</div>
                  </Col>
                </Row>
              ))}

            </div>
          )}
        </Modal.Body>

        <Modal.Footer>

          {/* <Button variant='primary' size='sm' type="button" className="svg-icon-sm mr-2" onClick={() => { history.push(ModuleRoutes.EDIT_CUSTOMER_FN(id)); UIProps.onHide() }} id='btn_edit_modal'>
            <FormattedMessage id='GENERAL.EDIT' />
          </Button> */}

        </Modal.Footer>

      </Modal>
    </Portal>
  );
};