export const theme = {
  screens: {
    sm: '480px', // => @media (min-width: 640px) { ... }
    md: '768px', // => @media (min-width: 768px) { ... }
    lg: '976px', // => @media (min-width: 992px) { ... }
    xl: '1440px', // => @media (min-width: 1280px) { ... }
    xxl: '1536px', // => @media (min-width: 1536px) { ... }
  },
  typography: {
    fontFamily: {
      base: 'Inter, sans-serif',
      mono: '"Roboto Mono", "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
    },
    fontSize: {
      xs: '0.75rem',
      sm: '0.88rem',
      md: '1rem',
      lg: '1.25rem',
      xl: '1.5rem',
      xxl: '2rem',
    },
    fontWeight: {
      light: 300,
      normal: 400,
      medium: 500,
      bold: 600,
      bolder: 700,
    },
    lineHeight: {
      base: 1.5,
      xs: 1,
      sm: 1.25,
      md: 1.5,
      lg: 1.75,
      xl: 2,
      xxl: 2.5,
    },
  },
  shape: {
    borderRadius: '0.5rem',
  },
  shadows: {
    input: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    inputHover: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;',
  },
  units: {
    input: {
      height: {
        default: '3.5rem',
        sm: '2.8rem', // '3rem'
        md: '3.1rem', // '3.5rem'
        lg: '4rem',
        xl: '4.5rem',
      },
      padding: '0.625rem 0.875rem',
    },
    textarea: {
      height: {
        default: '8.5rem',
        sm: '7rem',
        md: '8.5rem',
        lg: '12rem',
      },
      padding: '0.625rem 0.875rem',
    },
  },
  controls: {},
  spacing: {},
  palette: {
    base: {
      black: '#000000',
      white: '#ffffff',
    },
    gray: {
      '25': '#FCFCFC',
      '50': '#FAFAFA',
      '100': '#F2F4F7',
      '200': '#EDEDED',
      '300': '#D6D6D6',
      '400': '#A6A6A6',
      '500': '#767676',
      '600': '#A6A6A6',
      '700': '#444444',
      '800': '#444444',
      '900': '#1C1C1C',
    },
    primary: {
      color: '#C756D9',
      contrastLight: '#ffF',
      contrastDark: '#B441C6',
      '25': '#FEFAFF',
      '50': '#FEF5FF', // light
      '100': '#FCEBFF',
      '200': '#F9D7FE',
      '300': '#F2BBFB',
      '400': '#E992F6',
      '500': '#C756D9', // default
      '600': '#CC33E4',
      '700': '#91389E', // hover
      '800': '#91389E',
      '900': '#73307D',
    },
    secondary: {
      color: '#D6D6D6',
      contrastLight: '#ffF',
      contrastDark: '#151515',
      '25': '#fafafa',
      '50': '#f6f6f6', // light
      '100': '#f2f2f2',
      '200': '#eaeaea',
      '300': '#e6e6e6',
      '400': '#dedede',
      '500': '#D6D6D6', // default
      '600': '#c0c0c0',
      '700': '#959595', // hover
      '800': '#555555',
      '900': '#2a2a2a',
    },
    danger: {
      color: '#F04438',
      contrastLight: '#ffF',
      contrastDark: '#B42318',
      '25': '##FFFBFA',
      '50': '#FEF3F2',
      '100': '#FEE4E2',
      '200': '#FECDCA',
      '300': '#FDA29B',
      '400': '#F97066',
      '500': '#F04438',
      '600': '#D92D20',
      '700': '#B42318',
      '800': '#912018',
      '900': '#7A271A',
    },
    warning: {
      color: '#F79009',
      contrastLight: '#ffF',
      contrastDark: '#B54708',
      '25': '#FFFCF5',
      '50': '#FFFAEB',
      '100': '#FEF0C7',
      '200': '#FEDF89',
      '300': '#FEC84B',
      '400': '#FDB022',
      '500': '#F79009',
      '600': '#DC6803',
      '700': '#B54708',
      '800': '#93370D',
      '900': '#7A2E0E',
    },
    success: {
      color: '#12B76A',
      contrastLight: '#ffF',
      contrastDark: '#027A48',
      '25': '#F6FEF9',
      '50': '#ECFDF3',
      '100': '#D1FADF',
      '200': '#A6F4C5',
      '300': '#6CE9A6',
      '400': '#32D583',
      '500': '#12B76A',
      '600': '#039855',
      '700': '#027A48',
      '800': '#05603A',
      '900': '#054F31',
    },
    info: {
      color: '#2E90FA',
      contrastLight: '#ffF',
      contrastDark: '#5f6fbd',
      '25': '#F5FAFF',
      '50': '#EFF8FF',
      '100': '#D1E9FF',
      '200': '#B2DDFF',
      '300': '#84CAFF',
      '400': '#53B1FD',
      '500': '#2E90FA',
      '600': '#1570EF',
      '700': '#175CD3',
      '800': '#1849A9',
      '900': '#194185',
    },
  },
  // primary: '#30a5fc',
  // primary_hover: '#0994fb',
  // primary_light: '#def1ff',
  // primary_inverse: '#ffffff',

  // alternate: '#fc8730',
  // alternate_hover: '#fb6208',
  // alternate_light: '#ffecde',
  // alternate_inverse: '#ffffff',

  // success: '#03ee67',
  // success_hover: '##02b44e',
  // success_light: '#deffec',
  // success_inverse: '#ffffff',

  // info: '#8730fc',
  // info_hover: '#7109fb',
  // info_light: '#ecdeff',
  // info_inverse: '#ffffff',

  // warning: '#fced30',
  // warning_hover: '#fbea09',
  // warning_light: '#fffcde',
  // warning_inverse: '#000000',

  // danger: '#fc303f',
  // danger_hover: '#fb091b',
  // danger_light: '#ffdee0',
  // danger_inverse: '#ffffff',

  // black: '#000000',
  // dark: '#181C32',
  // white: '#ffffff',
  // gray: '#D1D3E0',
};