import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useShippingsUIContext } from '../_context/ShippingsUIContext';
import { SearchControl } from '_metronic/_partials/controls';
import { ShippingsGrouping } from './ShippingsGrouping';
import { SelectCounter } from '_metronic/_partials';
import { useQuery } from 'app/hooks';



export function ShippingsFilter({ setSearchQuery }) {

  const query = useQuery();
  const [search, setSearchBase] = useState(query.get('search') ?? '');

  const UIContext = useShippingsUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    openPrintLabelsDialog: UIContext.openPrintLabelsDialog,

  }), [UIContext]);

  useEffect(() => {
    setSearchBase(query.get('search') ?? '');
  }, [query]);

  const handleSearch = value => {
    setSearchQuery(value);
    setSearchBase(value);
  };


  return (
    <Row className="mb-3">

      <Col sm="12" md="6" lg="4" className='mb-lg-0 mb-2'>
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col>

      <Col sm="12" md="6" lg="8" className='d-flex align-items-center px-md-0'>
        <SelectCounter count={UIProps.ids.length} />

        <ShippingsGrouping UIProps={UIProps} />
      </Col>

    </Row>
  );
}
