import React from 'react';
import { useSelector } from 'react-redux';
import { getVariantSpecs } from './core/helpers';
import { FormattedMessage } from 'react-intl';



export function VariantFilter() {
  const productVariants = useSelector(state => state.products.variants);
  return (
    productVariants.length > 0 &&
    <div className="d-flex justify-content-between align-items-start border-top py-5 px-9">

      <div className='d-flex flex-wrap'>
        {getVariantSpecs(productVariants).map((option, index) => (
          <div className="d-flex mr-5" key={index}>
            <span className="font-weight-bolder text-muted text-nowrap mb-2 mr-2">
              {`${option.name} : `}
            </span>
            <div className="d-flex flex-wrap mb-2">
              {option.values.map((value, index) => (
                <label className="label label-inline label-outline-secondary text-nowrap mb-2 mr-2" key={index}>
                  {value}
                </label>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div>
        <label className="form-control form-control-sm font-weight-bolder border-0 bg-light mb-0 line-height-lg text-center text-nowrap px-3">
          <FormattedMessage id="GENERAL.AMOUNT" />: {productVariants.length}
        </label>
      </div>

    </div>
  );
}