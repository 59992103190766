import { API } from 'constants/apiUrl';
import { paginationParams, searchParams } from '../_helpers/query-params';
import { fieldDigitalProduct, searchKeys } from './params';



export const digitalProductsMiddleware = {

  deleteProductKeys: async function (accessToken, productId, keyIds) {
    return await API.delete(`/digital-product/delete-key/${productId}/${keyIds}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  addProductKeys: async function (accessToken, productId, keys) {
    return await API.post(`/digital-product/add-key/${productId}`, keys, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getDigitalProducts: async function (accessToken, { pagination, search, filter, order }) {
    const { date_from, date_to, key_status, marketplace, ...rest } = filter;

    return await API.get(`/digital-product`, {
      params: {
        filter: {
          fields: fieldDigitalProduct,
          ...paginationParams(pagination.page, pagination.limit),
          order: [`${order ? order : 'id DESC'}`],
          where: {
            ...(search && {
              ...searchParams(searchKeys, search),
            }),
            ...((date_from && date_to) && {
              created_at: { between: [`${date_from}T00:00:00.001Z`, `${date_to}T23:59:59.999Z`] }
            }),
            ...(key_status !== 'all_status' && {
              key_status: key_status
            }),
            marketplace: marketplace === 'none' ? '' : marketplace,
            ...(filter && rest),
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },



  getCount: async function (accessToken, { search, filter }) {
    const { date_from, date_to, key_status, marketplace, ...rest } = filter;

    return await API.get(`/digital-product/count`, {
      params: {
        where: {
          ...(search && {
            ...searchParams(searchKeys, search),
          }),
          ...((date_from && date_to) && {
            created_at: { between: [`${date_from}T00:00:00.001Z`, `${date_to}T23:59:59.999Z`] }
          }),
          ...(key_status !== 'all_status' && {
            key_status: key_status
          }),
          marketplace: marketplace === 'none' ? '' : marketplace,
          ...(filter && rest),
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  sendMailKey: async function (accessToken, ids) {
    return await API.post(`/digital-product/send-mail`, ids, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  deleteKeyReserve: async function (accessToken, ids) {
    return await API.post(`/digital-product/delete-key-reserve`, ids, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

};