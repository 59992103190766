export const searchProperties = [
  'name',
  'sku',
  'variants.sku',
  'variants.ean',
  'variants.asin',
  'identities.ean',
  'identities.asin',
  'pricing.purchase_price',
  'identities.barcode',
  'identities.gtin',
  'identities.isbn',
];

export const allFilterKeys = [
  'published',
  'unpublished',
  'low_stock',
  'search',
];


export const initialQueryParams = { page: 1, limit: 10 };
export const initialOrderParams = 'id DESC';

export const initialSearchParams = {
  searchText: '',
  searchKeys: searchProperties,
};

export const initialProductQueryParams = {
  pagination: initialQueryParams,
  search: initialSearchParams,
  filter: null,
  order: initialOrderParams,
};

export const initShippingValue = {
  method: '',
  shippingPrice: 0,
  shippingAdditionalPrice: 0,
  interShippingCheckbox: [],
  processingTime: '',
  type: '',
};

export const initialProductValues = {
  id: '',
  name: '',
  sku: '',
  condition: 'NEW',
  brand: '',
  quantity: 0,
  unit: '',
  supplier_id: '',
  pricing: {
    purchase_price: 0,
    /* net_price: 0, */
    gross_price: 0,
    tax: 19,
    currency: '',
  },
  category: {
    id: '',
    name: '',
    path: '',
    key: ""
  },
  images: [],
  description: '',
  //short_description: '',
  identities: {
    asin: '',
    gtin: '',
    isbn: '',
    ean: '',
    upc: '',
    barcode: '',
    mpn: '',
  },
  dimensions: {
    length: 0,
    width: 0,
    height: 0,
    weight: 0,
  },
  features: [],
  variants: [],
  inventories: [],
  digital_product: {},
};

export const initialRequestValues = {
  loading: false,
  error: '',
  status: '', // success || error || pending
  id: null, // request id
};

export const initialProductKey = {
  key: '',
  order_id: '',
  order_date: null,
  email_sended_date: null,
  confirmation_date: null,
  created_at: null,
  updated_at: new Date(),
};


export const TAX_OPTIONS = [
  {
    value: 0,
    label: "PRODUCT.GENERAL.TAX_SELECT",
  },
  {
    value: 2,
    label: '2% - ES',
  },
  {
    value: 2.1,
    label: '2.1% - FR',
  },
  {
    value: 2.6,
    label: '2.6% - CH',
  },
  {
    value: 3,
    label: '3% - CY/LU',
  },
  {
    value: 3.8,
    label: '3.8% - CH',
  },
  {
    value: 4,
    label: '4% - EL/ES/IT/PT',
  },
  {
    value: 4.8,
    label: '4.8% - IE',
  },
  {
    value: 5,
    label: '5% - CY/EE/HU/HR/IT/LV/LT/MT/PL/RO/SK/SI/GB',
  },
  {
    value: 5.5,
    label: '5.5% - FR',
  },
  {
    value: 6,
    label: '6% - BE/EL/PT/SE',
  },
  {
    value: 7,
    label: '7% - DE/MT',
  },
  {
    value: 8,
    label: '8% - LU/PL',
  },
  {
    value: 8.1,
    label: '8.1% - CH',
  },
  {
    value: 9,
    label: '9% - BG/CY/EE/EL/IE/LT/NL/PT/RO',
  },
  {
    value: 9.5,
    label: '9.5% - SI',
  },
  {
    value: 10,
    label: '10% - AT/FI/FR/IT/SK/ES',
  },
  {
    value: 12,
    label: '12% - BE/CZ/LV/MT/NO/PT- Madeira/SE',
  },
  {
    value: 13,
    label: '13% - AT/EL/HR/PT',
  },
  {
    value: 13.5,
    label: '13.5% - IE',
  },
  {
    value: 14,
    label: '14% - FI/LU',
  },
  {
    value: 15,
    label: '15% - NO',
  },
  {
    value: 16,
    label: '16% - PT- Azores',
  },
  {
    value: 17,
    label: '17% - EL/LU',
  },
  {
    value: 18,
    label: '18% - HU/MT',
  },
  {
    value: 19,
    label: '19% - DE/CY/RO',
  },
  {
    value: 20,
    label: '20% - AT/BG/FR/SK/GB',
  },
  {
    value: 21,
    label: '21% - BE/CZ/LV/LT/NL/ES',
  },
  {
    value: 22,
    label: '22% - EE/IT/PT- Madeira/SI',
  },
  {
    value: 23,
    label: '23% - IE/PL/PT',
  },
  {
    value: 24,
    label: '24% - EL',
  },
  {
    value: 25,
    label: '25% - HR/DK/NO/SE',
  },
  {
    value: 25.5,
    label: '25.5% - FI',
  },
  {
    value: 27,
    label: '27% - HU',
  },
];


export const UNIT_OPTIONS = [
  {
    value: 'piece',
    label: 'PRODUCT.GENERAL.CONDITIONS.PIECE',
  },
  {
    value: 'weight',
    label: 'PRODUCT.GENERAL.CONDITIONS.WEIGHT',
  },
  {
    value: 'hour',
    label: 'PRODUCT.GENERAL.CONDITIONS.HOUR',
  },
  {
    value: 'length',
    label: 'PRODUCT.GENERAL.CONDITIONS.LENGTH',
  },
  {
    value: 'area',
    label: 'PRODUCT.GENERAL.CONDITIONS.AREA',
  },
  {
    value: 'volume',
    label: 'PRODUCT.GENERAL.CONDITIONS.VOLUME',
  },
  {
    value: 'parcel',
    label: 'PRODUCT.GENERAL.CONDITIONS.PARCEL',
  }
];

export const pictureSpecs = {
  width: 1200,
  height: 1200,
  size: 3145728, // 3MB
  format: ['jpeg', 'jpg', 'gif', 'png', 'webp'],
  maxNumber: 10,
};

export const IS_VARIANT_OPTIONS = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
];



// const productConditions = [
//   {
//     value: 'NEW',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.NEW' }),
//   },
//   {
//     value: 'USED',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.USED' }),
//   },
//   {
//     value: 'REFURBISHED',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.REFURBISHED' }),
//   },
//   {
//     value: 'LIKE_NEW',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.LIKE_NEW' }),
//   },
//   {
//     value: 'VERY_GOOD',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.VERY_GOOD' }),
//   },
//   {
//     value: 'GOOD',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.GOOD' }),
//   },
//   {
//     value: 'ACCEPTABLE',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.ACCEPTABLE' }),
//   },
//   {
//     value: 'FOR_PARTS_OR_NOT_WORKING',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.FOR_PARTS_OR_NOT_WORKING' }),
//   },
//   {
//     value: 'REPAIR_OR_EXCHANGE',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.REPAIR_OR_EXCHANGE' }),
//   },
//   {
//     value: 'UNSPECIFIED',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.UNSPECIFIED' }),
//   },
// ];
