import React, { useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useProductEditUIContext } from "../_context/ProductEditUIContext";
import { FormattedMessage } from "react-intl";



export function ServerRespondDialog() {

  const history = useHistory();

  const UIContext = useProductEditUIContext();
  const UIProps = useMemo(() => ({
    handleCloseServerRespondDialog: UIContext.handleCloseServerRespondDialog,
    showServerRespondDialog: UIContext.showServerRespondDialog,
  }), [UIContext]);

  const { error, actionLoading } = useSelector((state) => ({
    error: state.products.error,
    actionLoading: state.products.loading,
  }), shallowEqual);

  useEffect(() => {
    if (!actionLoading && !error) {
      UIProps.handleCloseServerRespondDialog();
      return history.goBack();
    }
  }, [UIProps, actionLoading, error, history]);

  const onHideHandler = () => UIProps.handleCloseServerRespondDialog();


  return (
    <Modal show={UIProps.showServerRespondDialog && !actionLoading} onHide={onHideHandler} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>

      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id="GENERAL.TEAM" />
        </Modal.Title>
      </Modal.Header>

      {actionLoading
        ? <Modal.Body className="mx-auto my-3 text-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
        : <Modal.Body>
          {error && <p>{<FormattedMessage id={error} />}</p>}
        </Modal.Body>
      }

      <Modal.Footer>
        <Button variant="light" onClick={onHideHandler} disabled={actionLoading} id='btn_close_modal'>
          <FormattedMessage id="GENERAL.CLOSE" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
