
export const products = {
    main: {
        location: 'product',
        title: "PRODUCT.TITLE_PLURAL",
        description: "USER_GUIDE.PRODUCT"
    },
    new: {
        title: "PRODUCT.PRODUCT_NEW",
        description: "USER_GUIDE.PRODUCT.NEW"
    },
    edit: {
        title: "PRODUCT.PRODUCT_EDIT",
        description: "USER_GUIDE.PRODUCT.EDIT"
    }
}
