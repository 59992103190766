import ActionTypes from './actionTypes';



const initialActionTracker = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: null,
  id: null,
  productId: null
};

const INITIAL_STATE = {
  marketPlaces: [],
  products: [],
  product: null,
  categorySuggestions: [],
  selectedCategory: null,
  count: 0,
  totalCount: 0,
  productForEdit: {},
  isLoading: false,
  error: null,
  policies: {},
  warehouses: [],
  shippingGroups: [],
  actionTracker: initialActionTracker,
  isLoaded: false,
  listingPolicies: {
    fulfillmentPolicyId: '',
    paymentPolicyId: '',
    returnPolicyId: ''
  },
  aspects: [],
  aspectRows: [],
  amazonSellerId: null,
  amazonCatalog: []
};

export const listingsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    // Inventory Listings Preparation Request
    case ActionTypes.EBAY_INVENTORY_LISTINGS_PREPARATION_REQUEST:
    case ActionTypes.EBAY_CREATE_OR_REPLACE_INVENTORY_REQUEST:
    case ActionTypes.EBAY_INVENTORY_UPDATE_REQUEST:
    case ActionTypes.EBAY_UPDATE_POLICIES:
    case ActionTypes.PUBLISH_EBAY_INVENTORY_REQUEST:
    case ActionTypes.KAUFLAND_CREATE_OR_REPLACE_INVENTORY_REQUEST:
    case ActionTypes.KAUFLAND_INVENTORY_LISTINGS_PREPARATION_REQUEST:
    case ActionTypes.UPDATE_PRODUCT_FOR_KAUFLAND_REQUEST:
    case ActionTypes.LISTING_MARKET_PLACES_REQUEST:
    case ActionTypes.GET_PRODUCTS_REQUEST:
    case ActionTypes.GET_PRODUCT_BY_ID_REQUEST:
    case ActionTypes.EBAY_SHOP_PRODUCTS_MIGRATIONS:
    case ActionTypes.KAUFLAND_SHOP_PRODUCTS:
    case ActionTypes.EBAY_SHOP_PRODUCTS: {
      return {
        ...state,
        isLoading: true,
        actionTracker: {
          ...state.actionTracker,
          isLoading: true,
          id: payload?.actionId || null,
          productId: payload?.id || null
        }
      };
    }

    // Get Products Success
    case ActionTypes.GET_PRODUCTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        products: payload.products,
        count: payload.count.count,
        totalCount: payload.count.total,
        productForEdit: {},
        actionTracker: initialActionTracker
      };
    }

    case ActionTypes.EBAY_SHOP_PRODUCTS_MIGRATIONS_SUCCESS: {
      /*   payload.count:{
          success: [{listingId: '325631384150', message: 'success'},{},..],
          fail: [{listingId: '325631208625', message: 'A user error ha...},{},..]
        } */

      return {
        ...state,
        isLoading: false
        //count: payload.count.count,
      };
    }

    // Inventory Listings Preparation Success
    case ActionTypes.EBAY_INVENTORY_LISTINGS_PREPARATION_SUCCESS: {
      const { product, market_id, method } = payload;
      const findInventory = Array.isArray(product?.inventories) && product.inventories?.find((inv) => inv.market_id === market_id);

      let categorySuggestions = [];

      if (method === 'edit') {
        product.merchantLocations = findInventory?.merchantLocations?.locations || [];
        product.ebayInventory = findInventory;
        product.merchantLocationKey = findInventory?.merchantLocations?.selectedKey || '';

        if (findInventory?.suggestions) {
          categorySuggestions = findInventory?.suggestions || [];
        }

        if (findInventory?.shopValue?.name) {
          product.name = findInventory.shopValue.name;
        }

        if (findInventory?.shopValue?.quantity) {
          product.quantity = findInventory?.shopValue?.quantity;
        }

        if (!findInventory.isGrouped && findInventory?.shopValue?.pricing) {
          product.pricing.gross_price = findInventory?.shopValue?.pricing;
        }

        if (findInventory.isGrouped && findInventory?.variantInventories.length > 0) {
          const shopVariants = findInventory?.shopValue?.variants.map((v) => v.id) || [];
          const productVariants = product.variants.filter((item) => !shopVariants.includes(item.id));
          product.variants = [...findInventory?.shopValue?.variants, ...productVariants];
        } else {
          product.variants = [];
        }
      }

      return {
        ...state,
        isLoading: false,
        productForEdit: {
          ...product,
          description: product.description ?? '',
          ...(product?.variants?.length > 0 && { groupId: product.sku }),
          listingPolicies: product?.ebayInventory?.listingPolicies ?? INITIAL_STATE.listingPolicies,
          merchantLocationKey: product?.merchantLocationKey ?? ''
        },
        categorySuggestions: categorySuggestions,
        actionTracker: initialActionTracker
      };
    }

    // Inventory Listings Category Success
    case ActionTypes.EBAY_INVENTORY_LISTINGS_CATEGORY_SUCCESS: {
      const { categorySuggestions } = payload;
      return {
        ...state,
        isLoaded: true,
        categorySuggestions: Array.isArray(categorySuggestions) ? categorySuggestions : []
      };
    }

    // Clean State
    case ActionTypes.CLEAN_UP_REDUX_STATE: {
      const { cleanedUpStates } = payload;
      return {
        ...state,
        ...cleanedUpStates
      };
    }

    // Inventory Listings for Kaufland Preparation Success
    case ActionTypes.KAUFLAND_INVENTORY_LISTINGS_PREPARATION_SUCCESS: {
      const { product, categorySuggestions } = payload;
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        productForEdit: {
          ...product,
          description: product.description ?? '',
          brand: product.brand ?? '',
          mpn: product.mpn ?? '',
          ...(product?.variants?.length > 0 && { variantGroupId: '' }),
          id_shipping: product?.kauflandInventory?.id_shipping,
          id_warehouse: product?.kauflandInventory?.id_warehouse
        },
        categorySuggestions: Array.isArray(categorySuggestions.categories) ? categorySuggestions.categories : [],
        warehouses: categorySuggestions.warehouses,
        shippingGroups: categorySuggestions.shippingGroups
      };
    }

    case ActionTypes.GET_PRODUCT_BY_ID_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        product: payload.product,
        actionTracker: initialActionTracker
      };
    }

    // Listing market Places
    case ActionTypes.LISTING_MARKET_PLACES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        marketPlaces: payload.marketPlaces,
        actionTracker: initialActionTracker
      };
    }

    // Create or replace Inventory Success
    case ActionTypes.UPDATE_PRODUCT_FOR_KAUFLAND_SUCCESS:
    case ActionTypes.PUBLISH_EBAY_INVENTORY_SUCCESS:
    case ActionTypes.EBAY_INVENTORY_UPDATE_SUCCESS:
    case ActionTypes.KAUFLAND_CREATE_OR_REPLACE_INVENTORY_SUCCESS:
    case ActionTypes.KAUFLAND_DELETE_INVENTORY_SUCCESS:
    case ActionTypes.EBAY_CREATE_OR_REPLACE_INVENTORY_SUCCESS:
    case ActionTypes.KAUFLAND_SHOP_PRODUCTS_SUCCESS:
    case ActionTypes.EBAY_SHOP_PRODUCTS_SUCCESS: {
      const count = payload.count;
      return {
        ...state,
        isLoading: false,
        actionTracker: {
          ...state.actionTracker,
          isSuccess: true,
          isLoading: false,
          message: count ? `Products successfully created ${count?.success ?? 0}, Products already created ${count.fail ?? 0}` : ""
        }
      };
    }

    case ActionTypes.EBAY_INVENTORY_LISTINGS_PREPARATION_FAIL:
    case ActionTypes.EBAY_CREATE_OR_REPLACE_INVENTORY_FAIL:
    case ActionTypes.EBAY_INVENTORY_UPDATE_FAIL:
    case ActionTypes.EBAY_UPDATE_POLICIES_FAIL:
    case ActionTypes.KAUFLAND_INVENTORY_LISTINGS_PREPARATION_FAIL:
    case ActionTypes.UPDATE_PRODUCT_FOR_KAUFLAND_FAIL:
    case ActionTypes.KAUFLAND_CREATE_OR_REPLACE_INVENTORY_FAIL:
    case ActionTypes.KAUFLAND_DELETE_INVENTORY_FAIL:
    case ActionTypes.LISTING_MARKET_PLACES_FAIL:
    case ActionTypes.PUBLISH_EBAY_INVENTORY_FAIL:
    case ActionTypes.GET_PRODUCTS_FAIL:
    case ActionTypes.EBAY_SHOP_PRODUCTS_FAIL:
    case ActionTypes.KAUFLAND_SHOP_PRODUCTS_FAIL:
    case ActionTypes.EBAY_SHOP_PRODUCTS_MIGRATIONS_FAIL:
    case ActionTypes.GET_PRODUCT_BY_ID_FAIL:
    case ActionTypes.INVENTORY_VARIANT_DELETE_HANDLER: {
      const { id } = payload;
      const productForEdit = { ...state.productForEdit };
      const variants = productForEdit.variants.filter((variant) => variant.id !== id);
      productForEdit.variants = variants;
      return {
        ...state,
        productForEdit
      };
    }

    case ActionTypes.INVENTORY_VARIANT_UPDATE_HANDLER: {
      const { rowIndex, update } = payload;
      const productForEdit = { ...state.productForEdit };
      productForEdit.variants[rowIndex] = update;
      return {
        ...state,
        productForEdit
      };
    }

    // Clean up the reducer
    case ActionTypes.CLEAN_UP_REDUCER: {
      const { cleanedUpStates } = payload;
      return {
        ...state,
        actionTracker: initialActionTracker,
        ...cleanedUpStates
      };
    }

    default:
      return state;
  }
};
