import { call, put, select, takeEvery } from 'redux-saga/effects';
import { categoryMiddleware, tokenErrorHandler } from 'middlewares';
import { CategoryActions } from './actions';
import ActionTypes from './actionTypes';



// Create Category
function* createCategory({ payload: { category } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(categoryMiddleware.createCategory, accessToken, category);
    yield put(CategoryActions.createCategorySuccess(response.data));
    yield put(CategoryActions.getCategories());

  } catch (err) {
    yield call(tokenErrorHandler, err, CategoryActions.createCategoryFail());
  }
}

// Update Category
function* updateCategory({ payload: { id, category } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(categoryMiddleware.putCategory, accessToken, id, category);
    yield put(CategoryActions.updateCategorySuccess());
    yield put(CategoryActions.getCategories());

  } catch (err) {
    yield call(tokenErrorHandler, err, CategoryActions.updateCategoryFail());
  }
}

// Get Categories
function* getCategories() {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const countCategories = yield call(categoryMiddleware.getCount, accessToken);
    const response = yield call(categoryMiddleware.getCategories, accessToken);
    yield put(CategoryActions.getCategoriesSuccess(response.data, countCategories.data.count));

  } catch (err) {
    yield call(tokenErrorHandler, err, CategoryActions.getCategoriesFail());
  }
}

// Delete Category
function* deleteCategory({ payload: { id } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(categoryMiddleware.deleteCategory, accessToken, id);

    yield put(CategoryActions.deleteCategorySuccess(id));

  } catch (err) {
    const errMsg = err?.response?.data?.error?.message;
    const error = errMsg ? errMsg : "Etwas schief gelaufen!";
    yield call(tokenErrorHandler, err, CategoryActions.deleteCategoryFail(error));
  }
}


export function* categoriesSaga() {
  yield takeEvery(ActionTypes.GET_CATEGORIES, getCategories);
  yield takeEvery(ActionTypes.DELETE_CATEGORY, deleteCategory);
  yield takeEvery(ActionTypes.CREATE_CATEGORY, createCategory);
  yield takeEvery(ActionTypes.UPDATE_CATEGORY, updateCategory);
}
