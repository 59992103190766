import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { customerMiddleware, tokenErrorHandler } from 'middlewares';
import { CustomerActions } from './actions';
import ActionTypes from './actionTypes';



// Create Customer
function* createCustomer({ payload: { customer } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(customerMiddleware.createCustomer, accessToken, customer);
    yield put(CustomerActions.createCustomerSuccess(response.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, CustomerActions.createCustomerFail());
  }
}


// Update Customer
function* updateCustomer({ payload: { customer } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(customerMiddleware.putCustomer, accessToken, customer);
    yield put(CustomerActions.updateCustomerSuccess());

  } catch (err) {
    yield call(tokenErrorHandler, err, CustomerActions.updateCustomerFail());
  }
}


// Get Customers
function* getCustomers({ payload: { queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const countCustomers = yield call(customerMiddleware.getCount, accessToken, queryParams);
    const response = yield call(customerMiddleware.getCustomers, accessToken, queryParams);

    yield put(CustomerActions.getCustomersSuccess(response.data, countCustomers.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, CustomerActions.getCustomersFail());
  }
}


// Get Customer By Id
function* getCustomerById({ payload: { id } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(customerMiddleware.getCustomerById, accessToken, id);
    yield put(CustomerActions.getCustomerByIdSuccess(response.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, CustomerActions.getCustomerByIdFail());
  }
}


// Delete Customer
function* deleteCustomer({ payload: { ids, queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(customerMiddleware.deleteCustomer, accessToken, ids);
    yield put(CustomerActions.deleteCustomerSuccess(ids));

    yield fork(getCustomers, { payload: { queryParams } });

  } catch (err) {
    yield call(tokenErrorHandler, err, CustomerActions.deleteCustomerFail());
  }
}


// Find Customer By Property
function* findCustomerByProperty({ payload: { key, value, customerId } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const customer = yield call(customerMiddleware.findCustomer, accessToken, key, value, customerId);
    yield put(CustomerActions.findCustomerByPropertySuccess(customer.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, CustomerActions.findCustomerByPropertyFail());
  }
}

// Get Customers
function* getCustomerDocumentCounts({ payload: { customerId } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(customerMiddleware.getCustomerDocumentCounts, accessToken, customerId);

    yield put(CustomerActions.getCustomerDocumentCountsSuccess(response.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, CustomerActions.getCustomerDocumentCountsFail());
  }
}

export function* customerSaga() {
  yield takeEvery(ActionTypes.GET_CUSTOMERS, getCustomers);
  yield takeEvery(ActionTypes.GET_CUSTOMER_BY_ID, getCustomerById);
  yield takeEvery(ActionTypes.CREATE_CUSTOMER, createCustomer);
  yield takeEvery(ActionTypes.UPDATE_CUSTOMER, updateCustomer);
  yield takeEvery(ActionTypes.DELETE_CUSTOMER, deleteCustomer);
  yield takeEvery(ActionTypes.FIND_CUSTOMER_BY_PROPERTY, findCustomerByProperty);
  yield takeEvery(ActionTypes.GET_CUSTOMER_DOCUMENT_COUNTS, getCustomerDocumentCounts);
  // yield takeEvery(ActionTypes.GET_DOCUMENTS_BY_CUSTOMER_ID, getDocumentsByCustomerId);
}
