import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useArchiveUIContext } from '../_context/ArchiveUIContext';
import { ArchiveActions } from '../_redux/actions';
import { Portal } from 'react-portal';



export function ArchiveDeleteDialog() {

  const dispatch = useDispatch();

  const UIContext = useArchiveUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    show: UIContext.showArchiveRelocateDialog,
    onHide: UIContext.closeArchiveRelocateDialog,
    requestedArchive: UIContext.requestedArchive,
  }), [
    UIContext.ids,
    UIContext.showArchiveRelocateDialog,
    UIContext.closeArchiveRelocateDialog,
    UIContext.requestedArchive
  ]);


  const deleteDocumentAction = () => {
    dispatch(ArchiveActions.deleteDocument(UIProps.requestedArchive.value, UIProps.ids));
    UIProps.onHide();
  };


  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={UIProps.show} onHide={UIProps.onHide} size="sm" backdrop="static" aria-labelledby="example-modal-sizes-title-sm" centered>

        <Modal.Body className="text-center line-height-md h6 py-10 mb-0">
          {UIProps.ids.length > 1
            ? <>
              <FormattedMessage id="ARCHIVE.DELETE_DIALOG_BODY_1" />
              <span className="label label-xl label-inline label-light-danger mx-1">{UIProps.ids.length}</span>
              <FormattedMessage id="ARCHIVE.DELETE_DIALOG_BODY_2" />
            </>
            : <FormattedMessage id="ARCHIVE.DELETE_DIALOG" />
          }
        </Modal.Body>

        <Modal.Footer className='flex-nowrap p-0 overflow-hidden'>

          <Button variant='ios' className="border-right" onClick={UIProps.onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant='ios' className="text-danger" onClick={deleteDocumentAction} id="btn_delete_modal">
            <FormattedMessage id={UIProps.requestedArchive.value === "orders" ? "DOCUMENT.ORDER_PLURAL" : "DOCUMENT.OFFER_PLURAL"} />
          </Button>

        </Modal.Footer>

      </Modal>
    </Portal>
  )
}