import React from "react";
import { Icon } from '_metronic/_icons';



export function ScrollTop() {
  return (
    <div id="kt_scrolltop" className="scrolltop">
      <span className="svg-icon text-light">
        <Icon.ChevronUp />
      </span>
    </div>
  );
}
