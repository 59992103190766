import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { Portal } from 'react-portal';
import { FormattedMessage } from 'react-intl';
import { CloseButton } from '_metronic/_partials';
import { CustomerActions } from '../_redux/actions';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { useCustomersUIContext } from '../_context/CustomersUIContext';
import { getCountryList, addressType } from '_metronic/_helpers';
import { customerTypeOptions } from '../_context/CustomersUIHelpers';
import { useLang } from '_metronic/i18n';
import { Icon } from '_metronic/_icons';
import { isEmpty } from 'lodash';



export function CustomerDetailsDialog() {

  const locale = useLang();
  const history = useHistory();
  const dispatch = useDispatch();

  const UIContext = useCustomersUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showCustomerDetailsDialog,
    onHide: UIContext.closeCustomerDetailsDialog,
    customerId: UIContext.customerId,
    cleanUpContextHandler: UIContext.cleanUpContextHandler
  }), [
    UIContext.closeCustomerDetailsDialog,
    UIContext.showCustomerDetailsDialog,
    UIContext.customerId,
    UIContext.cleanUpContextHandler
  ]);


  const { customer } = useSelector(state => ({
    customer: state.customers.customerForEdit,
  }));

  useEffect(() => {
    UIProps.customerId && dispatch(CustomerActions.getCustomerById(UIProps.customerId));
  }, [dispatch, UIProps.customerId]);

  useEffect(() => {
    !UIProps.customerId && UIProps.onHide();
  }, [UIProps]);


  useEffect(() => {
    return () => {
      dispatch(CustomerActions.resetFormHandler());
      UIProps.cleanUpContextHandler();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Portal node={document && document.getElementById('modal-portal')}>
      <Modal show={UIProps.show} onHide={UIProps.onHide} backdrop={false} aria-labelledby="example-modal-sizes-title-md" size="md" centered>

        <Modal.Header>

          <Modal.Title>
            <Icon.Person className="mr-3" />
            <FormattedMessage id="DOCUMENT.CUSTOMER_TITLE" />
          </Modal.Title>

          <CloseButton onClick={UIProps.onHide} />

        </Modal.Header>

        <Modal.Body className='overflow-hidden px-10 py-2'>

          {UIProps.customerId && !isEmpty(customer) && (
            <div className='font-weight-bold text-break d-flex flex-column'>

              {customer.number &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.CUSTOMER_NUMBER" />
                  </Col>
                  <Col lg='7'>
                    {customer.number}
                  </Col>
                </Row>}

              {customer.type &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.TYPE" />
                  </Col>
                  <Col lg='7'>
                    {customerTypeOptions.find(item => item.value === customer.type)?.label}
                  </Col>
                </Row>}

              {customer.name &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.NAME" />
                  </Col>
                  <Col lg='7'>
                    {customer.name}
                  </Col>
                </Row>}

              {customer.email &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.EMAIL" />
                  </Col>
                  <Col lg='7'>
                    {customer.email}
                  </Col>
                </Row>}

              {customer.phone &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.PHONE" />
                  </Col>
                  <Col lg='7'>
                    {customer.phone}
                  </Col>
                </Row>}

              {customer.company &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.COMPANY" />
                  </Col>
                  <Col lg='7'>
                    {customer.company}
                  </Col>
                </Row>
              }

              {customer.bank?.name &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="BANK.NAME" />
                  </Col>
                  <Col lg='7'>
                    {customer.bank?.name}
                  </Col>
                </Row>
              }

              {customer.notes &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="DOCUMENT.TABLE.COLUMN.NOTE" />
                  </Col>
                  <Col lg='7'>
                    <span className=' text-break'>{customer.notes}</span>
                  </Col>
                </Row>}

              {customer?.address.map((address, i) =>
                <Row className={`${customer?.address.length - 1 === i ? "" : "border-bottom"} py-3`} key={i}>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    {addressType.find(item => item.value === address?.type)?.label ?? <FormattedMessage id="GENERAL.ADDRESS" />}
                  </Col>
                  <Col lg='7'>
                    <div>{address.name}</div>
                    <div>{address.company}</div>
                    <div>{address.address_line1} {address.address_line2}</div>
                    <div>{address.post_code} {address.city}</div>
                    <div>{getCountryList(locale).find(code => code.value === address?.country_code)?.label}</div>
                  </Col>
                </Row>
              )}

            </div>
          )}
        </Modal.Body>

        <Modal.Footer>

          <Button variant='primary' onClick={() => { history.push(ModuleRoutes.EDIT_CUSTOMER_FN(UIProps.customerId)); UIProps.onHide() }} id='btn_edit_modal'>
            <FormattedMessage id='GENERAL.EDIT' />
          </Button>

        </Modal.Footer>

      </Modal>
    </Portal>
  );
};