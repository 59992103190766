import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useDocumentsUIContext } from 'app/modules/documents/_context/DocumentsUIContext';
import { DocumentActions } from 'app/modules/documents/_redux/actions';
import * as uiHelpers from '../_context/DocumentsUIHelpers';
import { convertPriceCurrency } from '_metronic/_helpers';
import Spinner from '_metronic/_partials/loading/Spinner';
import { CloseButton } from '_metronic/_partials';
import { Icon } from '_metronic/_icons';



export function PaymentWarningsDeleteDialog() {

  const dispatch = useDispatch();

  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showDeletePaymentWarnings,
    onHide: UIContext.closeDeletePaymentWarningsDialog,
    PaymentWarningsId: UIContext.deletePaymentWarningsId,
  }), [UIContext]);

  const { document, loading } = useSelector(state => ({
    document: state.documents.getDocumentById,
    loading: state.documents.loading,
  }), shallowEqual);


  const overPayment = uiHelpers.paymentAmountDifference(document?.position, document)
  const paymentAmount = document?.payment_warnings?.find(i => i.id === UIProps.PaymentWarningsId)?.amount;
  const paymentDifference = overPayment - paymentAmount;

  useEffect(() => {
    if (!loading) {
      UIProps.onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loading]);


  const deletePaymentWarningsAction = (documentId, id) => {
    if (documentId && id) {
      dispatch(DocumentActions.deletePaymentWarning(documentId, id))
    };
  }


  return (
    <Modal show={UIProps.show} onHide={UIProps.onHide} size={paymentDifference < 0 ? "md" : "sm"} backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered>

      {paymentDifference < 0 ?
        <>
          <CloseButton onClick={UIProps.onHide} className="position-absolute top-0 right-0 mr-10 mt-10" />
          <div className="d-flex justify-content-center icon-4x pt-10">
            <Icon.InfoCircle className="text-info" />
          </div>

          <Modal.Body className="text-center line-height-lg h6 py-10 mb-0">
            <FormattedMessage id='DOCUMENT.EDIT.PAYMENT_WARNING.OVER_PAYMENT' values={{ amaunt: convertPriceCurrency(-paymentDifference, document?.pricing_summary?.currency) }} />
          </Modal.Body>
        </>
        :
        <>
          <Modal.Body className="text-center line-height-md h6 py-10 mb-0">
            <FormattedMessage id="DOCUMENT.PAYMENT.WARNINGS_DELETE" />
          </Modal.Body>

          <Modal.Footer className="flex-nowrap p-0 overflow-hidden">

            <Button variant='ios' className="border-right" onClick={UIProps.onHide} id="btn_cancel_modal">
              <FormattedMessage id="GENERAL.CANCEL" />
            </Button>

            <Button variant='ios' className="text-danger" onClick={() => deletePaymentWarningsAction(document.id, UIProps.PaymentWarningsId)} id="btn_delete_modal">
              <FormattedMessage id="GENERAL.DELETE" />
              <Spinner loading={loading} color="danger" />
            </Button>

          </Modal.Footer>
        </>}
    </Modal>
  );
}