import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddressCard } from './company-cards/AddressCard';
import { BankAndTaxInfoCard } from './company-cards/BankAndTaxInfoCard';
import { LogoCard } from './company-cards/LogoCard';
import { CompanyActions } from './_redux/action';
import { Col, Row } from 'react-bootstrap';



export function CompanyCard() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CompanyActions.getCompanyBaseData());
  }, [dispatch]);

  const { companyBaseData } = useSelector(state => ({
    companyBaseData: state.company.companyBaseData,
  }));


  return (
    <Row>

      <Col md="12" lg="12" className="col-xl-6">
        <AddressCard companyBaseData={companyBaseData} />
      </Col>

      <Col md="12" lg="12" className="col-xl-6 flex-lg-column">
        <BankAndTaxInfoCard companyBaseData={companyBaseData} />
        <LogoCard logo={companyBaseData?.logo?.thumbnail} />
      </Col>

    </Row>
  );
}
