import React, { useState, useEffect, useMemo } from 'react';
import { SearchControl } from '_metronic/_partials/controls';
import { SuppliersGrouping } from './SuppliersGrouping';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from 'app/hooks';
import { useSupplierUIContext } from '../_context/SupplierUIContext';
import { SelectCounter } from '_metronic/_partials';



export function SuppliersFilter({ setSearchQuery }) {

  const query = useQuery();
  const [search, setSearchBase] = useState(query.get('search') ?? '');

  const UIContext = useSupplierUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    setIds: UIContext.setIds,
  }), [UIContext]);

  useEffect(() => {
    setSearchBase(query.get('search') ?? '');
  }, [query]);

  const handleSearch = (value) => {
    setSearchQuery(value);
    setSearchBase(value);
  };


  return (
    <Row className="mb-3">

      <Col md="4" className="mb-lg-0 mb-2">
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col>

      <Col md="8" className='d-flex align-items-start px-md-0'>

        <SelectCounter count={UIProps.ids.length} />

        <SuppliersGrouping UIProps={UIProps} />
      </Col>

    </Row>
  );
}
