import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useDocumentsUIContext } from 'app/modules/documents/_context/DocumentsUIContext';
import { DocumentActions } from 'app/modules/documents/_redux/actions';
import Spinner from '_metronic/_partials/loading/Spinner';



export function ShippingDeleteDialog() {

  const dispatch = useDispatch();
  const [isDeleting, setIsDeleting] = useState(false);

  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showDeleteLabel,
    onHide: UIContext.closeDeleteLabelDialog,
    onHidePrint: UIContext.closePrintLabelsDialog,
    labelParams: UIContext.deleteLabelParams,
    relation: UIContext.deleteLabelParams?.relation_documents,
  }), [
    UIContext.showDeleteLabel,
    UIContext.closeDeleteLabelDialog,
    UIContext.closePrintLabelsDialog,
    UIContext.deleteLabelParams,
  ]);

  const service = UIProps?.labelParams?.service === "deutsche_post";

  const { loading } = useSelector(state => ({
    loading: state.documents.loading,
  }), shallowEqual);

  useEffect(() => {
    if (!loading && isDeleting) {
      setIsDeleting(false);
      UIProps.onHide();
      UIProps.onHidePrint();
      dispatch(DocumentActions.cleanStatesInStore({ labels: [] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loading, isDeleting]);


  const deleteLabel = () => {
    const { documentId, id } = UIProps.labelParams
    dispatch(DocumentActions.deleteLabel(id, documentId));
    dispatch(DocumentActions.cleanStatesInStore({ labels: [] }));
    setIsDeleting(true);
  };

  const relation = UIProps?.relation?.filter(item => item.id !== UIProps?.labelParams?.documentId);

  return (
    <Modal show={UIProps.show} onHide={UIProps.onHide} size={(service || relation?.length) ? "md" : "sm"} backdrop="static" aria-labelledby="contained-modal-delete-label-title-center" centered>

      <Modal.Body className="text-center line-height-lg h6 py-10 mb-0">
        {service
          ? <FormattedMessage id="DOCUMENT.SHIPPING.DEUTSCHE_POST.DELETE.MESSAGE" values={{ b: chunks => <u>{chunks}</u> }} />
          : <FormattedMessage id={UIProps.labelParams?.error ? "DOCUMENT.SHIPPING.DELETE_ERROR_LABEL" : "DOCUMENT.SHIPPING.DELETE_LABEL"} />
        }
        {relation?.length ?
          <span className='pl-1'>
            <FormattedMessage id="DOCUMENT.SHIPPING.DELETE_LABEL_RELATION" />
            <div className='mt-1'>
              {relation.map((item, index) => (
                <span key={index}>
                  <span className='text-danger'>{item?.id}</span>
                  <span> ({item?.type})</span>
                  {index < relation?.length - 1 && ', '}
                </span>
              ))}
            </div>
          </span> : <></>
        }

      </Modal.Body>

      <Modal.Footer className="flex-nowrap p-0 overflow-hidden">

        <Button variant='ios' className="border-right" onClick={UIProps.onHide} id="btn_cancel_modal">
          <FormattedMessage id="GENERAL.CANCEL" />
        </Button>

        <Button variant='ios' className="text-danger" onClick={deleteLabel} id="btn_delete_modal">
          <FormattedMessage id="GENERAL.DELETE" />
          <Spinner loading={loading} color="danger" />
        </Button>

      </Modal.Footer>

    </Modal>
  );
}


