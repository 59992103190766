import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { SupportActions } from "../_redux/actions";
import { useSupportUIContext } from "../_context/SupportUIContext";
import { TICKET_STATUS } from "../_context/SupportUIHelper";



export function TicketStatusDialog() {

  const dispatch = useDispatch();

  const UIContext = useSupportUIContext();
  const UIProps = useMemo(() => ({
    ticketStatus: UIContext.ticketStatus,
    setTicketStatus: UIContext.setTicketStatus,
    initialStatus: UIContext.initialStatus,
  }), [UIContext]);

  const statusTicketAction = () => {
    const ticketFilter = {
      status: UIProps.ticketStatus.status
    };
    dispatch(SupportActions.ticketProps(UIProps.ticketStatus.id, ticketFilter));
    closeModal();
  };

  const closeModal = () => {
    UIProps.setTicketStatus(UIProps.initialStatus);
  };


  return (
    <Modal show={UIProps.ticketStatus.id !== ""} onHide={closeModal} size="sm" backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered>

      <Modal.Body className="text-center line-height-md h6 py-10 mb-0">
        <FormattedMessage id="SUPPORT.TICKET_STATUS_CONTENT" values={{ status: UIProps.ticketStatus?.status }} />
      </Modal.Body>

      <Modal.Footer className="flex-nowrap p-0 overflow-hidden">

        <Button variant='ios' className="border-right" onClick={closeModal} id="btn_cancel_modal">
          <FormattedMessage id="GENERAL.CANCEL" />
        </Button>

        <Button variant='ios' className="text-danger" onClick={statusTicketAction} id="btn_delete_modal">
          {TICKET_STATUS.find(status => status.value === UIProps.ticketStatus?.status)?.action}
        </Button>

      </Modal.Footer>

    </Modal>

  );
}
