import React from 'react'
import { Icon } from '_metronic/_icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'



export default function TableButton({ type, link, disabled, id }) {
  return (
    <>

      {type === 'settings' &&
        <OverlayTrigger overlay={<Tooltip id={`tooltip-settings-${id}`}><FormattedMessage id="SETTINGS.TITLE" /></Tooltip>}>
          <Link to={link} className={`btn btn-icon btn-hover-primary btn-sm ${disabled ? "disabled" : ""}`} id={`btn-settings-${id}`}>
            <span className="svg-icon svg-icon-md">
              <Icon.Settings />
            </span>
          </Link>
        </OverlayTrigger>
      }

      {type === 'delete' &&
        <OverlayTrigger overlay={<Tooltip id={`tooltip-delete-${id}`}><FormattedMessage id="GENERAL.DELETE" /></Tooltip>}>
          <Link to={link} className={`btn btn-icon btn-hover-danger btn-sm ${disabled ? "disabled" : ""}`} id={`btn-delete-${id}`}>
            <span className="svg-icon svg-icon-md">
              <Icon.Trash />
            </span>
          </Link>
        </OverlayTrigger>
      }

    </>
  )
}
