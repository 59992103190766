import React from 'react'
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon } from '_metronic/_icons';



export default function LicenseBadge({ license }) {

  const { formatMessage: intl } = useIntl();

  const licenseType = [
    {
      type: 'trial',
      label: intl({ id: "ADMIN.USER.LICENSE_TYPE.TRIAL" }),
      color: moment(license?.updated_at).add(23, 'days') <= moment() ? "label-danger" : "label-warning",
    },
    {
      type: 'tester',
      label: intl({ id: "ADMIN.USER.LICENSE_TYPE.TESTER" }),
      color: 'label-outline-warning',
    },
    {
      type: 'standard',
      label: intl({ id: "ADMIN.USER.LICENSE_TYPE.STANDARD" }),
      color: 'label-outline-success',
    },
    {
      type: 'premium',
      label: intl({ id: "ADMIN.USER.LICENSE_TYPE.PREMIUM" }),
      color: 'label-outline-primary',
    },
    {
      type: 'test_user',
      label: intl({ id: "ADMIN.USER.LICENSE_TYPE.TEST_USER" }),
      color: 'label-outline-warning',
    },
    {
      type: 'development',
      label: intl({ id: "ADMIN.USER.LICENSE_TYPE.DEVELOPMENT" }),
      color: 'label-outline-info',
    },
    {
      type: 'admin',
      label: intl({ id: "ADMIN.USER.LICENSE_TYPE.ADMIN" }),
      color: 'label-outline-dark',
    },
  ];

  const currentLicense = licenseType.find(item => item.type === license?.type)


  return (
    <OverlayTrigger overlay={
      <Tooltip id="license-tooltip">
        <div>
          <div className="font-weight-bold">{currentLicense?.label}</div>
          <div className="text-muted">{license?.updated_at && moment(license?.updated_at).format('DD MMM YYYY, HH.mm')}</div>
        </div>
      </Tooltip>
    }>
      <h6 className='mb-0'>

        <label className={`label label-inline label-lg svg-icon svg-icon-sm ${currentLicense?.color}`}>
          {license?.type === "trial"
            ? moment(license?.updated_at).add(30, 'days') <= moment()
              ? <><Icon.CircleSlash className="mr-2" /><FormattedMessage id='ADMIN.USER.LICENSE.EXPIRED' /> </>
              : <><Icon.ExclamationCircle className="mr-2" />{moment(license?.updated_at).add(30, 'days').diff(moment(), 'days')} <FormattedMessage id='ADMIN.USER.LICENSE.DAYS_LEFT' />  </>
            : currentLicense?.label
          }
        </label>

      </h6>
    </OverlayTrigger>
  );
}