import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Icon } from "_metronic/_icons";
import { Link } from "react-router-dom";



export function ActionsColumnFormatter(cellContent, row, rowIndex, { openEditSupplierDialog, openDeleteSupplierDialog }) {
  return (
    <>
      <OverlayTrigger overlay={<Tooltip id="supplier-edit-tooltip"><FormattedMessage id="GENERAL.EDIT" /></Tooltip>}>
        <Link className="btn btn-icon btn-hover-primary btn-sm" to={openEditSupplierDialog(row.id)} id={`btn_edit_${row.id}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.PencilSquare />
          </span>
        </Link>
      </OverlayTrigger>

      <OverlayTrigger overlay={<Tooltip id="supplier-delete-tooltip"><FormattedMessage id="GENERAL.DELETE" /></Tooltip>}>
        <Link className="btn btn-icon btn-hover-danger btn-sm" to={openDeleteSupplierDialog(row.id)} id={`btn_delete_${row.id}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.Trash />
          </span>
        </Link>
      </OverlayTrigger>
    </>
  );
}
