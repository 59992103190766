
export const searchKeys = [
  'type',
  'service',
  'delivery_address.name',
  'delivery_address.email',
  'delivery_address.company',
  'delivery_address.country_code',
  'delivery_address.city',
  'delivery_address.post_code',
  "tracking_id",
  "relation_documents",
  "price",
  "weight",
];
