import React from 'react'
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DropdownTopbarItemToggler } from '_metronic/_partials/dropdowns';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function OrderNotification() {

  const { fetch_order_count } = useSelector(state => ({
    fetch_order_count: state.notification.fetch_order_count,
    role: state.auth.user.role,
  }));


  return (
    <Dropdown drop="down" alignRight>

      <Dropdown.Toggle as={DropdownTopbarItemToggler} id="kt_quick_notifications_toggle">

        <OverlayTrigger placement="left" overlay={<Tooltip id="quick-actions-tooltip"><FormattedMessage id="DOCUMENT.ORDER_PLURAL" /></Tooltip>}>
          <div className="topbar-item">
            <div className="btn btn-icon btn-clean btn-lg" id="btn_notification">
              <div className="symbol">
                <span className="svg-icon">
                  <Icon.Order />
                </span>
                {fetch_order_count?.length > 0 && <i className="symbol-badge bg-danger"></i>}
              </div>
            </div>
          </div>
        </OverlayTrigger>

      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-right dropdown-menu-anim dropdown-menu-lg p-0" id='dropdown_notification'>

        <div className="d-flex align-items-center justify-content-between flex-wrap px-8 py-8 bgi-size-cover bgi-no-repeat rounded-top mb-4" style={{ backgroundImage: "url(/media/bg/bg-1.jpg)" }}>

          <span className="svg-icon svg-icon-3x text-light mr-4">
            <Icon.Order />
          </span>

          <div className="text-white m-0 flex-grow-1 mr-3 font-size-h3">
            <FormattedMessage id="DOCUMENT.ORDER_PLURAL" />
          </div>

        </div>

        {/* TODO: Tamamlanmadı */}
        <div className="px-8 mb-4">

          {fetch_order_count?.length > 0 ?
            fetch_order_count?.map((item, index) => (item.status === "success" &&
              <div className="d-flex align-items-center justify-content-between border-bottom py-2" key={index}>

                <span className='font-weight-bold text-capitalize'>
                  <Icon.Shop className="mr-2" />
                  {item.name}
                </span>

                <span className="label label-lg label-light-success label-inline">
                  <FormattedMessage id="HEADER.NOTIFICATIONS.ORDERS_FETCHED" values={{ piece: item?.result?.split(" ")[0] ?? 0 }} />
                </span>

              </div>

            ))
            : <div className='py-20 text-center svg-icon svg-icon-6x' style={{ opacity: "0.05" }}>
              <Icon.BellOff />
            </div>
          }

        </div>

      </Dropdown.Menu>
    </Dropdown>
  )
}
