import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { useUserUIContext } from '../_context/UserUIContext';
import { SVFormControl } from '_metronic/_partials/controls';
import { AdminActions } from '../../_redux/actions';
import { Field, Formik, Form } from 'formik';
import { Icon } from '_metronic/_icons';
import * as Yup from 'yup';



export function UserDeleteDialog({ id, show, onHide }) {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const UIContext = useUserUIContext();
  const UIProps = useMemo(() => ({
    queryParams: UIContext.queryParams,
  }), [UIContext]);

  const [confirmed, setConfirmed] = useState(false);

  const { loading, users, reports } = useSelector(state => ({
    loading: state.admin.loading,
    users: state.admin.users,
    reports: state.admin.reports,
  }), shallowEqual);

  const initialValues = {
    password: '',
  }
  const user = users.find(user => user.id === id);
  const counts = reports?.find(report => report.user_id === id);

  useEffect(() => {
    if (!id) onHide();

    return () => {
      setConfirmed(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, show]);

  useEffect(() => {
    if (id) {
      UIProps.queryParams.filter.user_id = id;
      dispatch(AdminActions.getReportsUsers({ id: 'clear-date' }, UIProps.queryParams))
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, intl({ id: 'AUTH.REGISTER.PASSWORD.LENGTH_MIN' }))
      .max(50, intl({ id: 'AUTH.REGISTER.PASSWORD.LENGTH_MAX' }))
      .required(intl({ id: 'AUTH.VALIDATION.PASSWORD_REQUIRED' })),
  });


  const handleSubmit = (values) => {
    dispatch(AdminActions.deleteUser(id, values, UIProps.queryParams));
    onHide();
  }


  return (
    <Modal show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg">
      <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        <Form autoComplete='off'>

          <Modal.Body className='p-10'>
            <Row className='flex-column'>

              <Col className="d-flex justify-content-center mt-6 mb-12">
                <Icon.UserDelete className="icon-6x text-danger" />
              </Col>

              {!confirmed
                ? <Col className="font-size-lg font-weight-bold">
                  <p><FormattedMessage id='ADMIN.USER.DELETE_TITLE' /></p>
                  <p> {intl({ id: 'ADMIN.USER.DELETE_CONTENT' }, { name: `${user?.name} ${user?.surname}` }).split("#").map((item, i) => ["href=", "<b>", "class="].some(el => item.includes(el))
                    ? <FormattedHTMLMessage key={i} id={item} values={{ what: 'react-intl', a: chunks => <a href='/'>{chunks}</a>, b: chunks => <strong>{chunks}</strong>, span: chunks => <span class="">{chunks}</span> }} />
                    : <FormattedHTMLMessage key={i} id={item} />
                  )} </p>
                  <span className='text-danger'><FormattedMessage id='ADMIN.USER.DELETE_FOOTER' /></span>
                </Col>
                : <>
                  <Alert variant='light-warning' className="alert-custom alert-notice text-dark-75 flex-column line-height-xl font-weight-bold py-4 mb-8">
                    {intl({ id: 'ADMIN.USER.DELETE_PERSON_NAME' }, { name: `${user?.name} ${user?.surname}` }).split("#").map((item, i) => ["href=", "<b>", "class="].some(el => item.includes(el))
                      ? <FormattedHTMLMessage key={i} id={item} values={{ what: 'react-intl', a: chunks => <a href='/'>{chunks}</a>, b: chunks => <strong>{chunks}</strong>, span: chunks => <span class="">{chunks}</span> }} />
                      : <FormattedHTMLMessage key={i} id={item} />
                    )}
                    <p className='mt-2'><FormattedMessage id='ADMIN.DELETE_USER.MESSAGE_TWO' /></p>
                    {intl({ id: 'ADMIN.DELETE_USER.MESSAGE_THREE' }, { order: counts?.orders, offer: counts?.offers, invoice: counts?.invoices, credit: counts?.credits, waybill: counts?.waybills, archive: counts?.archives, customer: counts?.customers, supplier: counts?.suppliers, product: counts?.products, connection: counts?.connections?.length }).split("#").map((item, i) => ["href=", "<b>", "class="].some(el => item.includes(el))
                      ? <p key={i}>
                        <FormattedHTMLMessage id={item} values={{ what: 'react-intl', a: chunks => <a href='/'>{chunks}</a>, b: chunks => <strong>{chunks}</strong>, span: chunks => <span>{chunks}</span> }} />
                      </p>
                      : <p key={i}>{item}</p>
                    )}

                    {/* <p><FormattedMessage id='SETTINGS.LOGIN.DELETE_ACCOUNT.MESSAGE_FOUR' /></p> */}

                  </Alert>

                  <Col className='my-9 pt-1'>
                    <Field component={SVFormControl}
                      {...{
                        type: "password",
                        name: "password",
                        label: intl({ id: "ADMIN.USER.DELETE_SECURITY_PURPOSES" }),
                        withFeedbackLabel: true,
                        feedbackLabel: true,
                        placeholder: intl({ id: "ADMIN.USER.DELETE_PASSWORD_PLACEHOLDER" }),
                        required: true,
                        disabled: loading,
                        className: "mb-0"
                      }}
                    />
                  </Col>

                </>
              }
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="light" onClick={() => confirmed ? setConfirmed(false) : onHide()} id="btn_cancel_modal">
              <FormattedMessage id="GENERAL.CANCEL" />
            </Button>
            {/* 
              Button yerine span kullanıldı. Sebebi formik yup tetiklenmemesi için. 
              Button içinde <FormattedMaessage Kullanılmadı hata veriyor modal kapandığında 
            */}
            {!confirmed
              ? <span type="button" className="btn btn-primary font-weight-bold" onClick={() => setConfirmed(true)} id="btn_confirm_modal">
                <FormattedMessage id="ADMIN.USER.CONFIRM" />
              </span>
              : <Button variant="danger" type="submit" id="btn_delete_modal">
                <FormattedMessage id="ADMIN.USER.DELETE" />
              </Button>
            }
          </Modal.Footer>

        </Form>
      </Formik>
    </Modal>
  );
}
