import React, { useContext, createContext } from 'react';

// importing UI context from modules
import { ProductsUIProvider } from '../products/_context/ProductsUIContext';
import { CustomersUIProvider } from '../customers/_context/CustomersUIContext';
import { DocumentUIProvider } from '../documents/_context/DocumentsUIContext';



const UIContext = createContext();
export const UICntextConsumer = UIContext.Consumer;
export const useUIContext = () => useContext(UIContext)



export function UIContextProvider({ children }) {


  const UIValue = {};

  return (
    <UIContext.Provider value={UIValue}>
      <CustomersUIProvider>
        <ProductsUIProvider>

          <DocumentUIProvider>{children}</DocumentUIProvider>

        </ProductsUIProvider>
      </CustomersUIProvider>
    </UIContext.Provider>
  );
}
