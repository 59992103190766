export default class ActionTypes {
  static get PREFIX() {
    return '[listing]';
  }



  // Get Products
  static get GET_PRODUCTS_REQUEST() {
    return `${this.PREFIX}GET_PRODUCTS_REQUEST`;
  }
  static get GET_PRODUCTS_SUCCESS() {
    return `${this.PREFIX}GET_PRODUCTS_SUCCESS`;
  }
  static get GET_PRODUCTS_FAIL() {
    return `${this.PREFIX}GET_PRODUCTS_FAIL`;
  }


  // Get Product by id
  static get GET_PRODUCT_BY_ID_REQUEST() {
    return `${this.PREFIX}GET_PRODUCT_BY_ID_REQUEST`;
  }
  static get GET_PRODUCT_BY_ID_SUCCESS() {
    return `${this.PREFIX}GET_PRODUCT_BY_ID_SUCCESS`;
  }
  static get GET_PRODUCT_BY_ID_FAIL() {
    return `${this.PREFIX}GET_PRODUCT_BY_ID_FAIL`;
  }


  // Get Product with suggestions and aspects
  static get EBAY_INVENTORY_LISTINGS_PREPARATION_REQUEST() {
    return `${this.PREFIX}EBAY_INVENTORY_LISTINGS_PREPARATION_REQUEST`;
  }
  static get EBAY_INVENTORY_LISTINGS_PREPARATION_SUCCESS() {
    return `${this.PREFIX}EBAY_INVENTORY_LISTINGS_PREPARATION_SUCCESS`;
  }
  static get EBAY_INVENTORY_LISTINGS_PREPARATION_FAIL() {
    return `${this.PREFIX}EBAY_INVENTORY_LISTINGS_PREPARATION_FAIL`;
  }


  // Get Product with category and aspects
  static get EBAY_INVENTORY_LISTINGS_CATEGORY() {
    return `${this.PREFIX}EBAY_INVENTORY_LISTINGS_CATEGORY`;
  }
  static get EBAY_INVENTORY_LISTINGS_CATEGORY_SUCCESS() {
    return `${this.PREFIX}EBAY_INVENTORY_LISTINGS_CATEGORY_SUCCESS`;
  }
  static get EBAY_INVENTORY_LISTINGS_CATEGORY_FAIL() {
    return `${this.PREFIX}EBAY_INVENTORY_LISTINGS_CATEGORY_FAIL`;
  }


  // Listing market places
  static get LISTING_MARKET_PLACES_REQUEST() {
    return `${this.PREFIX}LISTING_MARKET_PLACES_REQUEST`;
  }
  static get LISTING_MARKET_PLACES_SUCCESS() {
    return `${this.PREFIX}LISTING_MARKET_PLACES_SUCCESS`;
  }
  static get LISTING_MARKET_PLACES_FAIL() {
    return `${this.PREFIX}LISTING_MARKET_PLACES_FAIL`;
  }


  // Create or replace inventory
  static get EBAY_CREATE_OR_REPLACE_INVENTORY_REQUEST() {
    return `${this.PREFIX}EBAY_CREATE_OR_REPLACE_INVENTORY_REQUEST`;
  }
  static get EBAY_CREATE_OR_REPLACE_INVENTORY_SUCCESS() {
    return `${this.PREFIX}EBAY_CREATE_OR_REPLACE_INVENTORY_SUCCESS`;
  }
  static get EBAY_CREATE_OR_REPLACE_INVENTORY_FAIL() {
    return `${this.PREFIX}EBAY_CREATE_OR_REPLACE_INVENTORY_FAIL`;
  }


  // Create or replace inventory
  static get EBAY_INVENTORY_UPDATE_REQUEST() {
    return `${this.PREFIX}EBAY_INVENTORY_UPDATE_REQUEST`;
  }
  static get EBAY_INVENTORY_UPDATE_SUCCESS() {
    return `${this.PREFIX}EBAY_INVENTORY_UPDATE_SUCCESS`;
  }
  static get EBAY_INVENTORY_UPDATE_FAIL() {
    return `${this.PREFIX}EBAY_INVENTORY_UPDATE_FAIL`;
  }


  // Publish Ebay inventory
  static get PUBLISH_EBAY_INVENTORY_REQUEST() {
    return `${this.PREFIX}PUBLISH_EBAY_INVENTORY_REQUEST`;
  }
  static get PUBLISH_EBAY_INVENTORY_SUCCESS() {
    return `${this.PREFIX}PUBLISH_EBAY_INVENTORY_SUCCESS`;
  }
  static get PUBLISH_EBAY_INVENTORY_FAIL() {
    return `${this.PREFIX}PUBLISH_EBAY_INVENTORY_FAIL`;
  }


  // Get Kaufland listings
  static get KAUFLAND_INVENTORY_LISTINGS_PREPARATION_REQUEST() {
    return `${this.PREFIX}KAUFLAND_INVENTORY_LISTINGS_PREPARATION_REQUEST`;
  }
  static get KAUFLAND_INVENTORY_LISTINGS_PREPARATION_SUCCESS() {
    return `${this.PREFIX}KAUFLAND_INVENTORY_LISTINGS_PREPARATION_SUCCESS`;
  }
  static get KAUFLAND_INVENTORY_LISTINGS_PREPARATION_FAIL() {
    return `${this.PREFIX}KAUFLAND_INVENTORY_LISTINGS_PREPARATION_FAIL`;
  }


  // Update for kaufland
  static get UPDATE_PRODUCT_FOR_KAUFLAND_REQUEST() {
    return `${this.PREFIX}UPDATE_PRODUCT_FOR_KAUFLAND`;
  }
  static get UPDATE_PRODUCT_FOR_KAUFLAND_SUCCESS() {
    return `${this.PREFIX}UPDATE_PRODUCT_FOR_KAUFLAND_SUCCESS`;
  }
  static get UPDATE_PRODUCT_FOR_KAUFLAND_FAIL() {
    return `${this.PREFIX}UPDATE_PRODUCT_FOR_KAUFLAND_FAIL`;
  }


  // Create or replace inventory for kaufland
  static get KAUFLAND_CREATE_OR_REPLACE_INVENTORY_REQUEST() {
    return `${this.PREFIX}KAUFLAND_CREATE_OR_REPLACE_INVENTORY_REQUEST`;
  }
  static get KAUFLAND_CREATE_OR_REPLACE_INVENTORY_SUCCESS() {
    return `${this.PREFIX}KAUFLAND_CREATE_OR_REPLACE_INVENTORY_SUCCESS`;
  }
  static get KAUFLAND_CREATE_OR_REPLACE_INVENTORY_FAIL() {
    return `${this.PREFIX}KAUFLAND_CREATE_OR_REPLACE_INVENTORY_FAIL`;
  }


  //Delete inventory for kaufland
  static get KAUFLAND_DELETE_INVENTORY_REQUEST() {
    return `${this.PREFIX}KAUFLAND_DELETE_INVENTORY_REQUEST`;
  }
  static get KAUFLAND_DELETE_INVENTORY_SUCCESS() {
    return `${this.PREFIX}KAUFLAND_DELETE_INVENTORY_SUCCESS`;
  }
  static get KAUFLAND_DELETE_INVENTORY_FAIL() {
    return `${this.PREFIX}KAUFLAND_DELETE_INVENTORY_FAIL`;
  }


  // Get Kaufland Products
  static get KAUFLAND_SHOP_PRODUCTS() {
    return `${this.PREFIX}KAUFLAND_SHOP_PRODUCTS`;
  }
  static get KAUFLAND_SHOP_PRODUCTS_SUCCESS() {
    return `${this.PREFIX}KAUFLAND_SHOP_PRODUCTS_SUCCESS`;
  }
  static get KAUFLAND_SHOP_PRODUCTS_FAIL() {
    return `${this.PREFIX}KAUFLAND_SHOP_PRODUCTS_FAIL`;
  }


  // Inventory Variant Delete Handler
  static get INVENTORY_VARIANT_DELETE_HANDLER() {
    return `${this.PREFIX}INVENTORY_VARIANT_DELETE_HANDLER`;
  }


  // Inventory Variant Update Handler
  static get INVENTORY_VARIANT_UPDATE_HANDLER() {
    return `${this.PREFIX}INVENTORY_VARIANT_UPDATE_HANDLER`;
  }


  // Clean up redux state
  static get CLEAN_UP_REDUX_STATE() {
    return `${this.PREFIX}CLEAN_UP_REDUX_STATE`;
  }


  // Update for Ebay Policies
  static get EBAY_UPDATE_POLICIES() {
    return `${this.PREFIX}EBAY_UPDATE_POLICIES`;
  }
  static get EBAY_UPDATE_POLICIES_SUCCESS() {
    return `${this.PREFIX}EBAY_UPDATE_POLICIES_SUCCESS`;
  }
  static get EBAY_UPDATE_POLICIES_FAIL() {
    return `${this.PREFIX}EBAY_UPDATE_POLICIES_FAIL`;
  }


  // Get Ebay Products
  static get EBAY_SHOP_PRODUCTS() {
    return `${this.PREFIX}EBAY_SHOP_PRODUCTS`;
  }
  static get EBAY_SHOP_PRODUCTS_SUCCESS() {
    return `${this.PREFIX}EBAY_SHOP_PRODUCTS_SUCCESS`;
  }
  static get EBAY_SHOP_PRODUCTS_FAIL() {
    return `${this.PREFIX}EBAY_SHOP_PRODUCTS_FAIL`;
  }


  // Get Ebay Products TradingApi Migration
  static get EBAY_SHOP_PRODUCTS_MIGRATIONS() {
    return `${this.PREFIX}EBAY_SHOP_PRODUCTS_MIGRATIONS`;
  }
  static get EBAY_SHOP_PRODUCTS_MIGRATIONS_SUCCESS() {
    return `${this.PREFIX}EBAY_SHOP_PRODUCTS_MIGRATIONS_SUCCESS`;
  }
  static get EBAY_SHOP_PRODUCTS_TRADING_FAIL() {
    return `${this.PREFIX}EBAY_SHOP_PRODUCTS_MIGRATIONS_FAIL`;
  }

}
