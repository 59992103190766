import React from 'react';
import { nameRegExp, phoneRegExp, maxValidation, minValidation, emailRegExp, vatRegExp, taxRegExp } from '_metronic/_helpers';
import { DirtyDialog } from '_metronic/_partials/dialogs';
import { useIntl } from 'react-intl';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';



export function FormikStepper({ supplier, handleSubmit, children, btnRef, resetBtnRef, success, onClose }) {

  const { formatMessage: intl } = useIntl()
  // const typeValidation = <FormattedMessage id="GENERAL.TYPE_ERROR" />

  const DeliveryEditSchema = Yup.object().shape({
    name: Yup.string()
      .max(100, maxValidation("100"))
      .min(2, minValidation("2"))
      .matches(nameRegExp, intl({ id: 'GENERAL.NAME_MATCHES' }))
      .required(intl({ id: "GENERAL.NAME_REQUIRED" })),
    company: Yup.string()
      .max(100, maxValidation("100"))
      .matches(nameRegExp, intl({ id: 'GENERAL.COMPANY_MATCHES' }))
      .required(intl({ id: "GENERAL.COMPANY_REQUIRED" })),
    email: Yup.string()
      .max(100, maxValidation("100"))
      .matches(emailRegExp, intl({ id: 'GENERAL.EMAIL_EMAIL' }))
      .email(intl({ id: 'GENERAL.EMAIL_EMAIL' }))
      .required(intl({ id: "GENERAL.EMAIL_REQUIRED" })),
    phone: Yup.string()
      .max(25, maxValidation("25"))
      .matches(phoneRegExp, intl({ id: 'GENERAL.TEL_MATCHES' }))
      .min(7, minValidation("7")),
    mobile: Yup.string()
      .max(25, maxValidation("25"))
      .matches(phoneRegExp, intl({ id: 'GENERAL.TEL_MATCHES' }))
      .min(7, minValidation("7")),
    fax: Yup.string()
      .max(25, maxValidation("25"))
      .matches(phoneRegExp, intl({ id: 'GENERAL.FAX_MATCHES' }))
      .min(7, minValidation("7")),
    tax_number: Yup.string()
      .max(15, maxValidation("15"))
      .matches(taxRegExp, intl({ id: 'GENERAL.NUMBER_MATCHES' })),
    vat_id: Yup.string()
      .max(15, maxValidation("15"))
      .matches(vatRegExp, intl({ id: 'GENERAL.VAT_ID_MATCHES' })),
  });


  return (

    <Formik enableReinitialize={true} initialValues={supplier} validationSchema={DeliveryEditSchema} onSubmit={handleSubmit}>
      {({ handleSubmit, handleReset }) => (
        <Form autoComplete="off">
          <DirtyDialog success={success} onClose={onClose} />
          {children}
          <button type="button" className='d-none' ref={resetBtnRef} onClick={() => handleReset()} />
          <button type="submit" className='d-none' ref={btnRef} onSubmit={() => handleSubmit()} />
        </Form>
      )}
    </Formik>
  );
}
