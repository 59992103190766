import React, { useEffect, useState } from "react";
import { Card, CardSubHeader } from "_metronic/_partials/controls";
import { Icon } from '_metronic/_icons';
import { CardBody } from "_metronic/_partials/controls";
import { ImportProductsTable } from "./ImportProductsTable";
import { UserGuideHelper } from "app/user-guides/UserGuideHelper";
import { useDispatch, useSelector } from "react-redux";
import { importExportActions } from "../../_redux/actions";
import { Variant } from "app/modules/products/product-edit/variants/core/helpers";
import { checkImportProduct } from "./ImportProductHelper";
import { EditErrorDialog } from "app/modules/product-listings/ebay-listings/ebay-listings-edit/EditErrorDialog";
import { ModuleRoutes } from "constants/moduleRoutes";
import { useHistory } from "react-router-dom";
import { BlankMessage } from "_metronic/_partials/components";
import { FormattedMessage } from "react-intl";
import { useLang } from "_metronic/i18n";



export default function ImportProductPreparation({ submitBtnRef }) {

  const dispatch = useDispatch();
  const history = useHistory();
  const language = useLang();
  const [openErrorDialog, setOpenErrorDialog] = useState({ show: false, errors: [] });

  const { importPreparation, checkData, isImport } = useSelector((state) => ({
    importPreparation: state.importExport.importPreparation,
    checkData: state.importExport.checkData,
    isImport: state.importExport.isImport,
  }));

  const closeErrorDialog = () => setOpenErrorDialog({ show: false, errors: [] });

  const handlerProduct = () => {

    // variantlı ve variantsız ürünlerin ayrılması
    const data = []
    const unique = [...new Set(importPreparation.map(item => item.sku))];
    for (const key in unique) {
      const distinctProduct = importPreparation.filter(x => x.sku === unique[key]);
      data.push(distinctProduct);
    };

    // product create
    const productsCreate = [];
    for (const ProductList of data) {
      const {
        name, sku, brand,
        quantity, purchase_price, gross_price, currency, tax,
        ean, barcode, isbn, gtin, mpn, upc, asin,
        weight, width, height, length,
        description,
        path,
        unit,
        img1, img2, img3, img4, img5, img6, img7, img8, img9, img10,
        features1, features2, features3, features4, features5, features6,
      } = ProductList[0];

      // variant design
      const variants = [];
      let variantQuantity = 0
      if (ProductList.length > 1) {
        for (const variantList of ProductList) {
          const { purchase_price, gross_price, variantValue, variantName, variantSku, ean, asin, quantity, img1, img2, img3, img4, img5, img6, img7, img8, img9, img10 } = variantList;
          variantQuantity += +quantity ?? 0
          const variantsValues = variantValue?.split(',');
          const variantsName = variantName?.split(',');
          const variantSpecs = variantsName.map((name, i) => {
            const value = variantsValues[i];
            return { name, value };
          });

          const variant = new Variant({
            specs: variantSpecs,
            sku: variantSku,
            ean: ean,
            asin: asin,
            quantity: +quantity ?? 0,
            pricing: {
              purchase_price: +purchase_price ?? 0,
              gross_price: +gross_price ?? 0,
            },
            image_urls: [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10]
          });

          variants.push(variant);
        }
      }

      // features design
      const features = [];
      const featuresArr = [features1, features2, features3, features4, features5, features6];
      featuresArr.forEach((item, i) => {
        if (item) {
          const nameUndValues = item.split(':');
          features.push({
            id: `f_${i + 1}`,
            name: nameUndValues[0],
            value: nameUndValues[1],
          });
        }
      });


      // images design
      const images = [];
      const imagesArr = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10];
      imagesArr.forEach((item, i) => {
        if (item) {
          images.push({ market_url: item, order: i + 1 });
        }
      });

      const productObject = {
        name,
        sku,
        identities: {
          gtin: gtin ?? '',
          isbn: isbn ?? '',
          ean: ProductList.length > 1 ? "" : ean ?? '',
          asin: ProductList.length > 1 ? "" : asin ?? '',
          upc: upc ?? '',
          barcode: barcode ?? '',
          mpn: mpn ?? '',
        },
        dimensions: {
          length: length ?? 0,
          width: width ?? 0,
          height: height ?? 0,
          weight: weight ?? 0,
        },
        images: images,
        pricing: {
          gross_price: ProductList.length > 1 ? 0 : +gross_price ?? 0,
          purchase_price: ProductList.length > 1 ? 0 : +purchase_price ?? 0,
          tax: +tax ?? 0,
          currency: currency?.toUpperCase() ?? '',
        },
        unit: unit ?? 'piece',
        brand: brand ?? '',
        quantity: ProductList.length > 1 ? +variantQuantity : +quantity ?? 0,
        description: description ?? '',
        variants: variants,
        features: features,
        category: { id: '', name: '', path: path ?? '' },
        supplier_id: '',
        condition: 'NEW'
      };

      productsCreate.push(productObject);
    }


    const errors = checkImportProduct(importPreparation, checkData);
    if (Array.isArray(errors) && errors.length > 0) {
      setOpenErrorDialog({ show: true, errors: errors });
      return;
    };

    dispatch(importExportActions.importProductCreate(productsCreate, language))
  }


  useEffect(() => {
    if (isImport) {
      history.push(ModuleRoutes.PRODUCT)
    }

    return () => {
      dispatch(importExportActions.resetFormHandler());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isImport]);


  return (
    <>
      <EditErrorDialog show={openErrorDialog.show} onHide={closeErrorDialog} errors={openErrorDialog.errors} />

      <Card className="card-height">

        <CardSubHeader
          icon={<Icon.PencilSquare />}
          title={<FormattedMessage id='IMPORT.PRODUCT.PREPARE_LIST' />}
          info={<UserGuideHelper />}
          id={'import_product'}
        />

        <CardBody>
          {importPreparation.length > 0
            ? <ImportProductsTable />
            : <BlankMessage
              icon={<Icon.TextIndentLeft />}
              title={<FormattedMessage id='IMPORT_EXPORT.IMPORT.BLANK_MESSAGE_TITLE' />}
              message={<FormattedMessage id='IMPORT_EXPORT.IMPORT.BLANK_MESSAGE' />}
            />}
          <button type="submit" className='d-none' ref={submitBtnRef} onClick={handlerProduct} />
        </CardBody>

      </Card>
    </>
  )
}
