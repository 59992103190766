
export const validator = {

  categoryIdValidator: (cell, checkData, intl) => {
    const checkId = checkData?.includes(cell);
    if (!cell) {
      return {
        valid: false,
        message: intl({ id: 'IMPORT.CATEGORY.ALERT_DIALOG.CATEGORY_ID_REQUIRED' }),
      };
    } else if (cell.length > 200 || checkId) {
      return {
        valid: false,
        message: intl({ id: 'IMPORT.CATEGORY.ALERT_DIALOG.CATEGORY_ID' }),
      };
    }
  },

  categoryParentIdValidator: (cell, row, intl) => {

    if (+row.level === 1 && cell) {
      return {
        valid: false,
        message: intl({ id: 'IMPORT.CATEGORY.ALERT_DIALOG.CATEGORY.PARENT_ID_NOT_BE' }),
      };
    } else if (+row.level !== 1 && !cell) {
      return {
        valid: false,
        message: intl({ id: 'IMPORT.CATEGORY.ALERT_DIALOG.CATEGORY.PARENT_ID_REQUIRED' }),
      };
    }
    if (+row.level !== 1 && cell.length > 200) {
      return {
        valid: false,
        message: intl({ id: 'IMPORT.CATEGORY.ALERT_DIALOG.CATEGORY_PARENT_ID' }),
      };
    };
  },

  categoryLevelValidator: (cell, intl) => {
    if (!cell) {
      return {
        valid: false,
        message: intl({ id: 'IMPORT.CATEGORY.ALERT_DIALOG.CATEGORY_LEVEL_REQUIRED' }),
      };
    } else if (cell.length > 50) {
      return {
        valid: false,
        message: intl({ id: 'IMPORT.CATEGORY.ALERT_DIALOG.CATEGORY_LEVEL' }),
      };
    }
  },

  categoryNameValidator: (cell, intl) => {
    if (!cell) {
      return {
        valid: false,
        message: intl({ id: 'IMPORT.CATEGORY.ALERT_DIALOG.CATEGORY_NAME_REQUIRED' }),
      };
    } else if (cell.length > 500) {
      return {
        valid: false,
        message: intl({ id: 'IMPORT.CATEGORY.ALERT_DIALOG.CATEGORY_NAME' }),
      };
    }
  },

  categoryPathValidator: (cell, intl) => {
    if (!cell) {
      return {
        valid: false,
        message: intl({ id: 'IMPORT.CATEGORY.ALERT_DIALOG.CATEGORY_PATH_REQUIRED' }),
      };
    } else if (cell.length > 500) {
      return {
        valid: false,
        message: intl({ id: 'IMPORT.CATEGORY.ALERT_DIALOG.CATEGORY_PATH' }),
      };
    }
  },

};