import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useSupplierUIContext } from "../../../_context/SupplierUIContext";
import { BanksEditForm } from "./BankEditForm";
import { FormattedMessage } from "react-intl";
import { v4 as uuidv4 } from 'uuid';



export function BanksEditDialog() {

  const UIContext = useSupplierUIContext();
  const UIProps = useMemo(() => ({
    id: UIContext.selectedBankId,
    show: UIContext.showEditBankDialog,
    onHide: UIContext.closeEditBankDialog,
    INITIAL_BANK: UIContext.INITIAL_BANK,
    bankForEdit: UIContext.bankForEdit,
    createBankHandler: UIContext.createBankHandler,
    updateBankHandler: UIContext.updateBankHandler,
    bank: UIContext.bank,
  }), [
    UIContext.bankForEdit,
    UIContext.closeEditBankDialog,
    UIContext.createBankHandler,
    UIContext.INITIAL_BANK,
    UIContext.selectedBankId,
    UIContext.showEditBankDialog,
    UIContext.updateBankHandler,
    UIContext.bank,
  ]);

  const handleSubmit = (values) => {

    UIProps.id
      ? UIProps.updateBankHandler({ ...values, id: UIProps.id })
      : UIProps.createBankHandler({ ...values, id: uuidv4() })

    UIProps.onHide();
  };

  return (
    <Modal show={UIProps.show} onHide={UIProps.onHide} aria-labelledby="example-modal-sizes-title-lg" backdrop="static" keyboard={false} centered>

      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id={UIProps.id ? "SUPPLIER.BANK" : "GENERAL.BANK.CREATE"} />
        </Modal.Title>
      </Modal.Header>

      <BanksEditForm handleSubmit={handleSubmit} initialState={UIProps.bankForEdit ? UIProps.bankForEdit : UIProps.INITIAL_BANK} onHide={UIProps.onHide} bank={UIProps.bank} />

    </Modal>
  );
}
