import React from "react";
import { Route, Switch } from "react-router-dom";
import { BaseRoutes } from "constants/moduleRoutes";
import { Page404 } from "./Page404";


export default function ErrorsPage() {
  return (
    <Switch>
      <Route path={BaseRoutes.ERROR} component={Page404} />
    </Switch>
  );
}
