export function convertPriceCurrency(price, currency) {
  const newPrice = price?.toLocaleString('de-DE', {
    style: "currency",
    currency: currency ? currency : "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return newPrice;
}



export function calculatePriceDiffPercentage(previousPrice, currentPrice) {

  const diff = currentPrice - previousPrice;
  const percentageDiff = (diff / previousPrice) * 100;

  if (previousPrice === 0 && currentPrice === 0) {
    return 0.00;
  }

  if (previousPrice === 0) {
    return diff > 0 ? 100.00 : -100.00;
  }

  if (currentPrice === 0) {
    return -100.00;
  }

  if (Math.abs(percentageDiff) > 1000) {
    return percentageDiff > 0 ? 100.00 : -100.00;
  }

  return percentageDiff.toFixed(2);

}