import { API } from 'constants/apiUrl';



export const companyMiddleware = {

  create: async function (accessToken, company) {
    return await API.post(`/company`, company, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  update: async function (accessToken, company) {
    return await API.put(`/company/${company.id}`, company, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  get: async function (accessToken, params) {
    return await API.get(`/company`, {
      params: params || {},
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

};
