import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import { useDocumentsUIContext } from '../../_context/DocumentsUIContext';
import { ShippingDeleteDialog } from './shipping-dialogs/ShippingDeleteDialog';
import { ShippingEditDialog } from './shipping-add/ShippingEditDialog';
import { ShippingAddDialog } from './shipping-add/ShippingAddDialog';
import { UserInfoDropdown } from '_metronic/_partials';
import { ShippingTable } from './ShippingTable';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function ShippingCard({ openLabelSettingDialog, documentId, isConvert, saveDocumentClick, loading }) {

  const { shipping, document, positions } = useSelector(state => ({
    shipping: state.documents.documentForEdit.shipping,
    document: state.documents.getDocumentById,
    positions: state.documents.positions,
  }), shallowEqual);

  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    requestedDocument: UIContext.requestedDocument,
  }), [UIContext.requestedDocument,]);

  const disabledButton = loading || (!['credits'].includes(UIProps.requestedDocument.type) && positions?.length > 0 && (UIProps.requestedDocument.type !== "orders" || document?.order_status === "open") && document?.marketplace !== "amazon_prime");


  return (<>
    <Card className="gutter-b">

      <CardSubHeader
        icon={<Icon.Supplier />}
        title={<FormattedMessage id="GENERAL.SHIPPING" />}
        info={<UserInfoDropdown
          title={<FormattedMessage id='DOCUMENT.SHIPPING.INFO_TITLE' />}
          description={<FormattedMessage id='DOCUMENT.SHIPPING.INFO_DESCRIPTION' />} />}
      >

        <OverlayTrigger overlay={<Tooltip id="market-tooltip"><FormattedMessage id={positions?.length ? "DOCUMENT.SHIPPING.ADD_TITLE" : "DOCUMENT.POSITION.BLANK_MESSAGE"} /></Tooltip>}>
          <Button variant='outline-primary' size='sm' className="svg-icon svg-icon-sm" onClick={() => openLabelSettingDialog(documentId)} id="btn_shipping" disabled={!disabledButton}>
            <Icon.Plus />
            <span className="d-none d-sm-inline font-weight-bold ml-2">
              <FormattedMessage id="DOCUMENT.SHIPPING.ADD_TITLE" />
            </span>
          </Button>
        </OverlayTrigger>

      </CardSubHeader>

      <CardBody className="pt-0 px-0">

        <ShippingTable shipping={shipping} documentId={documentId} isConvert={isConvert} documentType={UIProps.requestedDocument?.type} />

      </CardBody>

    </Card>

    <ShippingAddDialog saveDocumentClick={saveDocumentClick} />
    <ShippingEditDialog />
    <ShippingDeleteDialog />

  </>);
}