import React from 'react'
import { SVFormControlLabel, SVFormControlWrapper } from './sv-ui/SVFormHelpers'
import UserInfoDropdown from '_metronic/_partials/user-info/UserInfoDropdown'
import { theme } from '_metronic/_helpers'



export const RadioControl = ({
  id,
  field,
  form: { setFieldValue, touched, errors, setFieldTouched },
  label,
  type,
  size = "md",
  readOnly,
  onFocus,
  disabled,
  className,
  info,
  options,
  required,
  ...props
}) => {
  return (
    <SVFormControlWrapper className={className}>

      {label &&
        <SVFormControlLabel>
          {label} {required && <span className="text-danger">*</span>}
          {info && <UserInfoDropdown size="w-75" title={label} description={info} />}
        </SVFormControlLabel>
      }

      <div className="radio-inline" style={{ height: theme.units.input.height[size] }}>

        {options && options.map((item) => (
          <label className="radio font-size-lg mb-0" key={item.value}>

            <input
              type="radio"
              name={item.value}
              id={item.value}
              value={item.value}
              checked={field.value === item.value}
              onChange={() => setFieldValue(field.name, item.value)}
              onBlur={() => setFieldTouched(field.name, true)}
              disabled={disabled}
            />
            <span />
            {item.label}
          </label>
        ))}

      </div>

    </SVFormControlWrapper>
  )
}
