/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSupplierUIContext } from "../../_context/SupplierUIContext";



export function BanksDeleteDialog() {

  const UIContext = useSupplierUIContext();
  const UIProps = useMemo(() => ({
    id: UIContext.selectedBankId,
    openDeleteBankDialog: UIContext.openDeleteBankDialog,
    closeDeleteBankDialog: UIContext.closeDeleteBankDialog,
    showDeleteBankDialog: UIContext.showDeleteBankDialog,
    deleteBankHandler: UIContext.deleteBankHandler
  }), [
    UIContext.closeDeleteBankDialog,
    UIContext.openDeleteBankDialog,
    UIContext.selectedBankId,
    UIContext.showDeleteBankDialog,
    UIContext.deleteBankHandler
  ]);


  useEffect(() => {
    if (!UIProps.id) { UIProps.closeDeleteBankDialog() }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UIProps.id]);


  const deleteBank = () => {
    UIProps.deleteBankHandler(UIProps.id);
    UIProps.closeDeleteBankDialog();
  };


  return (
    <Modal show={UIProps.showDeleteBankDialog} onHide={UIProps.closeDeleteBankDialog} aria-labelledby="example-modal-sizes-title-lg" centered>

      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id="BANK.DELETE" />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <FormattedMessage id="BANK.DELETE_CONTENT" />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="light" onClick={UIProps.closeDeleteBankDialog} id="btn_cancel_modal">
          <FormattedMessage id="GENERAL.CANCEL" />
        </Button>

        <Button variant="primary" className="ml-2" onClick={deleteBank} id="btn_delete_modal">
          <FormattedMessage id="GENERAL.DELETE" />
        </Button>
      </Modal.Footer>

    </Modal>
  );
}
