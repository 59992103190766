/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { AdminRoutes } from 'constants/moduleRoutes';
import { Icon } from '_metronic/_icons';
import { Link } from 'react-router-dom';



export const ActionsColumnFormatter = (cellContent, row, rowIndex) => {
  return (
    <>
      <OverlayTrigger overlay={<Tooltip id="customers-edit-tooltip"><FormattedMessage id="ADMIN.USER.EDIT" /></Tooltip>}>
        <Link to={AdminRoutes.ADMIN_USERS_EDIT_FN(row.id)} className="btn btn-icon btn-hover-primary btn-sm" id={`btn_edit_${row.id}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.PencilSquare />
          </span>
        </Link>
      </OverlayTrigger>

      <OverlayTrigger overlay={<Tooltip id="products-delete-tooltip"><FormattedMessage id="ADMIN.USER.DELETE" /></Tooltip>}>
        <Link to={AdminRoutes.DELETE_USER_FN(row.id)} className="btn btn-icon btn-hover-danger btn-sm" id={`btn_delete_${row.id}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.Trash />
          </span>
        </Link>
      </OverlayTrigger>
    </>
  )
};
