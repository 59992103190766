import React from 'react';
import { Route } from 'react-router-dom';
import { UserDeleteDialog } from './user-dialogs/UserDeleteDialog';
import { AdminRoutes } from 'constants/moduleRoutes';
import { getLastSearch } from '_metronic/_helpers';
import { UserCard } from './UserCard';



export function UserPage() {
  const lastSearch = getLastSearch()
  return (
    <>
      <UserCard />
      <Route exact path={AdminRoutes.DELETE_USER}>
        {({ history, match }) => (
          <UserDeleteDialog
            show={match != null}
            id={match && match.params.userId}
            onHide={() => history.push(AdminRoutes.USER + lastSearch)}
          />
        )}
      </Route>
    </>
  );
}
