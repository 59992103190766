import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { SVFeedbackLabel, SVFormControlLabel, SVFormControlWrapper } from './sv-ui/SVFormHelpers'
import UserInfoDropdown from '_metronic/_partials/user-info/UserInfoDropdown'
import { FormattedMessage } from 'react-intl'
import { theme } from '_metronic/_helpers'
import { Icon } from '_metronic/_icons'



export const DropDownControl = ({
  id,
  field,
  form: { setFieldValue, touched, errors, setFieldTouched },
  label,
  type,
  readOnly,
  onFocus,
  onBlur,
  disabled,
  className,
  info,
  variant = "outline-secondary",
  options,
  size = "md",
  required,
  placeholder,
  noOptionsMessage,
  feedbackLabel = null,
  ...props
}) => {
  const currentOption = options.find(option => option.value === field.value)

  const fieldCheck = (type, field) => {
    if (field.includes(".")) {
      return type[field.split(".")[0]]?.[field.split(".")[1]];
    } else {
      return type[field];
    }
  };

  const errorFn = fieldCheck(touched, field.name) && fieldCheck(errors, field.name)

  const style = {
    height: theme.units.input.height[size],
    borderColor: errorFn ? theme.palette.danger['color'] : theme.palette.gray['300'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: '500',
    width: '100%'
  }


  return (
    <SVFormControlWrapper className={className}>

      {label &&
        <SVFormControlLabel size={size} errors={fieldCheck(touched, field.name) && (fieldCheck(errors, field.name) || typeof feedbackLabel === "string") && true}>
          {label} {required && <span className="text-danger">*</span>}
          {info && <UserInfoDropdown size="w-75" title={label} description={info} />}
        </SVFormControlLabel>
      }

      <Dropdown className="dropdown-inline" drop="down">

        <Dropdown.Toggle variant={variant} className="text-truncate" style={style} disabled={disabled} id={`dropdown_${field.name}`}>
          {currentOption
            ? <>
              {currentOption?.icon && <span className="svg-icon svg-icon-lg mr-2">
                {currentOption?.icon}
              </span>}
              <span className="navi-text text-nowrap mr-auto">
                {currentOption?.label}
              </span>
            </>
            : <span className="text-muted">
              {placeholder ?? <FormattedMessage id='GENERAL.SELECT' />}
            </span>
          }
        </Dropdown.Toggle>

        <Dropdown.Menu className="overflow-hidden" id={`dropdown_menu_${field.name}`}>
          <ul className="navi navi-hover">
            {options.length > 0
              ? options?.map((item, i) => (
                <li className="navi-item" key={i}>
                  <Dropdown.Item className="navi-link py-3" onClick={() => setFieldValue(field.name, item.value)} onBlur={onBlur} id={`dropdown_item_${item.value}`}>
                    {item && <span className="svg-icon svg-icon-lg mr-2">
                      {item.icon}
                    </span>}
                    <span className="font-weight-bold navi-text text-nowrap">
                      {item.label}
                    </span>
                  </Dropdown.Item>
                </li>
              ))
              : <li className="navi-item px-3">
                <span className="svg-icon svg-icon-sm text-info text-hover-primary mr-2">
                  <Icon.InfoCircle />
                </span>
                <span className="navi-text">
                  {noOptionsMessage ?? <FormattedMessage id='GENERAL.NO_OPTIONS_AVAILABLE' />}
                </span>
              </li>
            }
          </ul>
        </Dropdown.Menu>

      </Dropdown>

      {feedbackLabel &&
        <SVFeedbackLabel size={size} withFeedbackLabel={true} errors={errorFn && true} label={label}>
          {errorFn ? fieldCheck(errors, field.name) : <span className='text-danger'>{feedbackLabel}</span>}
        </SVFeedbackLabel>
      }

    </SVFormControlWrapper>
  )
}
