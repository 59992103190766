import ActionTypes from './actionTypes';



const INITIAL_STATE = {
  loading: false,
  orders: {
    count: 0,
    total_count: 0,
    total_gross: 0,
  },
  sales: {
    count: 0,
    total_gross: 0,
    total_vat: 0,
    total_net: 0
  },
  open_invoices: {
    count: 0,
    total_gross: 0
  },
  marketplace: {
    labels: [],
    series: []
  },
  country: {
    labels: [],
    series: []
  },
  daily_sales: {
    count: 0,
    count_alt: 0,
    yearly_order: []
  },
  top_product: {
    list: [],
    count: 0
  },
  top_costumer: {
    list: [],
    count: 0
  },
  total_invoice_count: 0,
  currency: "",
};



export function dashboardReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {

    // Get Dashboard Data
    case ActionTypes.GET:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        ...payload.data
      };

    case ActionTypes.GET_FAIL:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
