export default class ActionTypes {
  static get PREFIX() {
    return '[shippings]';
  }


  // Get shippings action types
  static get GET_SHIPPINGS() {
    return `${ActionTypes.PREFIX}GET_SHIPPINGS`;
  }
  static get GET_SHIPPINGS_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_SHIPPINGS_SUCCESS`;
  }
  static get GET_SHIPPINGS_FAIL() {
    return `${ActionTypes.PREFIX}GET_SHIPPINGS_FAIL`;
  }


  // Get shipping action types
  static get GET_SHIPPING() {
    return `${ActionTypes.PREFIX}GET_SHIPPING`;
  }
  static get GET_SHIPPING_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_SHIPPING_SUCCESS`;
  }
  static get GET_SHIPPING_FAIL() {
    return `${ActionTypes.PREFIX}GET_SHIPPING_FAIL`;
  }


  // Delete shippings action types
  static get DELETE_SHIPPING() {
    return `${ActionTypes.PREFIX}DELETE_SHIPPING`;
  }
  static get DELETE_SHIPPING_SUCCESS() {
    return `${ActionTypes.PREFIX}DELETE_SHIPPING_SUCCESS`;
  }
  static get DELETE_SHIPPING_FAIL() {
    return `${ActionTypes.PREFIX}DELETE_SHIPPING_FAIL`;
  }


  // Clean states in store
  static get CLEAN_STATES_IN_STORE() {
    return `${ActionTypes.PREFIX}CLEAN_STATES_IN_STORE`;
  }

}
