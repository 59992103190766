import React from 'react';
import { AmazonListingsCard } from './AmazonListingsCard';

export function AmazonListingsPage() {
  return (
    <>
      {/*  <AmazonSyncAlertDialog /> */}
      <AmazonListingsCard />
    </>
  );
}
