import React from 'react';
import { Card, CardSubHeader, CardBody } from '_metronic/_partials/controls';
import { FormattedMessage } from 'react-intl';
import { BankForm } from './BankForm';
import { Icon } from '_metronic/_icons';



export function BankCard({ loading }) {

  return (
    <Card className="gutter-b">

      <CardSubHeader icon={<Icon.Bank />} title={<FormattedMessage id="GENERAL.BANK_DETAILS" />} />

      <CardBody>
        <BankForm loading={loading} />
      </CardBody>
    </Card>
  );
}
