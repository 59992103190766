import React from 'react';
import { bicRegExp, emailRegExp, nameRegExp, phoneRegExp, plzRegExp, streetRegExp, taxRegExp, vatRegExp } from '_metronic/_helpers';



export function TypeFormatter(cell, row) {
  if (cell && cell !== "standard" && cell !== "private" && cell !== "company") {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    );
  } else if (cell && cell?.length > 100) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell} (Type is too long)
      </div>
    );
  }
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function NameFormatter(cell, row) {

  if (!cell || cell?.length > 100 || !nameRegExp.test(cell)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function CompanyFormatter(cell, row) {
  if (row.type === "company" && !cell) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  if (cell?.length > 200) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function PhoneFormatter(cell, row) {
  if (cell && (!phoneRegExp.test(cell) || cell?.length > 25 || cell?.length < 7)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function MobileFormatter(cell, row) {
  if (cell && (!phoneRegExp.test(cell) || cell?.length > 25 || cell?.length < 7)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function TaxFormatter(cell, row) {
  if (cell && (!taxRegExp.test(cell) || cell?.length > 15)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function VatFormatter(cell, row) {
  if (row.type === "company" && !cell) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  if (cell && !vatRegExp.test(cell)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function FaxFormatter(cell, row) {
  if (cell && !phoneRegExp.test(cell)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function EmailFormatter(cell, row, checkData, emailsTable) {

  const checkEmail = checkData.includes(cell);
  const checkEmailTable = emailsTable.filter(email => email === cell).length > 1 ? true : false;

  if (!emailRegExp.test(cell.trim()) || cell?.length > 100 || checkEmail || checkEmailTable) {

    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function BankFormatter(cell, row) {
  if (cell?.length > 100) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function IbanFormatter(cell, row) {

  if (cell && (/*!ibanRegExp.test(cell) || bu kisim iban karar regex karar verilince acilacak*/ cell?.length > 27)) {

    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function BicFormatter(cell, row) {
  if (!bicRegExp.test(cell) && cell?.length > 40) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function DeliveryCustomerNameFormatter(cell, row) {
  if (cell?.length > 50 || (cell && !nameRegExp.test(cell))) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};

export function InvoiceCustomerNameFormatter(cell, row) {
  if (cell?.length > 50 || (cell && !nameRegExp.test(cell))) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function CompanyNameFormatter(cell, row) {
  if (cell && (cell?.length > 100 || !nameRegExp.test(cell))) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function DeliveryAddressLine1Formatter(cell, row) {
  if (!cell || cell?.length > 150 || !streetRegExp.test(cell)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};

export function InvoiceAddressLine1Formatter(cell, row) {
  if (cell?.length > 150 || (cell && !streetRegExp.test(cell))) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function AddressLine2Formatter(cell, row) {
  if (cell?.length > 150) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function DeliveryPostCodeFormatter(cell, row) {
  if (!cell || cell?.length > 10 || (row?.country_code_1 === "DE" && !plzRegExp.test(cell))) {

    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function InvoicePostCodeFormatter(cell, row) {
  if (cell?.length > 10 || (cell && row?.country_code_2 === "DE" && !plzRegExp.test(cell))) {

    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function DeliveryCityFormatter(cell, row) {
  if (!cell || cell?.length > 50) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function InvoiceCityFormatter(cell, row) {
  if (cell?.length > 50) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function DeliveryCountryCodeFormatter(cell, row) {
  if (!cell || cell?.length > 10) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};


export function InvoiceCountryCodeFormatter(cell, row) {
  if (cell?.length > 10) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};

export function DiscountFormatter(cell, row) {
  if (cell < 0 || cell > 100) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};

export function NotesFormatter(cell, row) {
  if (cell?.length > 1000) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};



export function HeaderFormatter(column, colIndex) {
  const words = column.text.split(" ");
  const firstWord = words[0];
  const restOfText = words.slice(1).join(" ");
  const asteriskIndex = restOfText.indexOf('*');
  return (
    <>
      <div>{firstWord}</div>
      {asteriskIndex > -1 ? (
        <div>
          {restOfText && restOfText.slice(0, asteriskIndex)}
          <span className='text-danger'>{restOfText.charAt(asteriskIndex)}</span>
          {restOfText && restOfText.slice(asteriskIndex + 1)}
        </div>
      ) : (
        <div>{restOfText && restOfText}</div>
      )}

    </>
  );
};


export function HeaderFormatterRequired(column, colIndex) {

  const words = column.text.split(" ");
  const lastWordWithAsterisk = words[words?.length - 1];
  const asteriskIndex = lastWordWithAsterisk.indexOf('*');

  return (
    <div>
      {words.slice(0, -1).join(' ')}
      {asteriskIndex > -1 ? (
        <span>
          {lastWordWithAsterisk.slice(0, asteriskIndex)}
          <span className='text-danger'>{lastWordWithAsterisk.charAt(asteriskIndex)}</span>
          {lastWordWithAsterisk.slice(asteriskIndex + 1)}
        </span>
      ) : (
        lastWordWithAsterisk
      )}
    </div>
  );
};