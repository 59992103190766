import React from "react";



function SelectionCheckbox({ isSelected, onChange, id }) {
  return (
    <>
      <input type="checkbox" className="d-none" />
      <label className="checkbox checkbox-outline checkbox-single">
        <input type="checkbox" checked={isSelected} onChange={onChange} id={`checkbox_${id}`} />
        <span />
      </label>
    </>
  );
}


function groupingAllOnSelectKeys({ isSelected, entities, setIds }) {
  if (isSelected) {
    setIds([]);
  } else {
    setIds(entities.map(entity => ({ id: entity.id, orderId: entity.order_id, paymentStatus: entity.email_sended_date ? 'paid' : 'open', confirmationDate: entity.confirmation_date })));
  }
}

function groupingItemOnSelectKeys({ ids, setIds, entity }) {
  const isSelected = ids.some((el) => el.id === entity.id);
  if (isSelected) {
    setIds(ids.filter((el) => el.id !== entity.id));
  } else {
    setIds([...ids, { id: entity.id, orderId: entity.order_id, paymentStatus: entity.email_sended_date ? 'paid' : 'open', confirmationDate: entity.confirmation_date }]);
  }
}


export function getSelectRowKeys(props) {
  const { entities, ids, setIds } = props;
  return {
    mode: "checkbox",
    clickToSelectAndEditCell: true,
    hideSelectAll: false,
    selectionHeaderRenderer: () => {
      const isSelected = entities && entities.length > 0 && entities.length === ids.length;
      const props = { isSelected, entities, setIds };
      return (
        <SelectionCheckbox isSelected={isSelected} onChange={() => groupingAllOnSelectKeys(props)} id="select_all"  />
      );
    },
    selectionRenderer: ({ rowKey }) => {
      const entity = entities.find(entity => entity.id === rowKey);
      const isSelected = ids.some((el) => el.id === rowKey);
      const props = { ids, setIds, entity };
      return (
        <SelectionCheckbox isSelected={isSelected} onChange={() => groupingItemOnSelectKeys(props)} id={rowKey} />
      );
    },
  };
}

