import React from 'react';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DropdownTopbarItemToggler } from '_metronic/_partials/dropdowns';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function UserGuidesDropdown({ userGuide, values }) {
  const { formatMessage: intl } = useIntl();
  return (
    <Dropdown drop="down">
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id="kt_quick_notifications_toggle">
        <OverlayTrigger placement="top" overlay={<Tooltip id="user-notification-tooltip">Info</Tooltip>}>
          <div type="button" className="svg-icon svg-icon-sm text-info text-hover-primary ml-2" id="kt_quick_notifications_toggle">
            <Icon.InfoCircle />
          </div>
        </OverlayTrigger>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0 m-0" id="dropdown_user_guide">

        <div className="d-flex flex-column bgi-size-cover bgi-no-repeat rounded-top" style={{ backgroundImage: "url(/media/bg/big-bg.webp)" }}>
          <h3 className="d-flex align-items-center m-8">
            <Icon.InfoCircle className="text-white mr-4" />
            <span className="text-white">
              <FormattedMessage id={userGuide.title} values={{ name: values ? values : null }} />
            </span>
          </h3>
        </div>

        <div className="p-5 rounded-bottom">
          <PerfectScrollbar className="scroll pr-5 mr-n5" style={{ maxHeight: '300px' }}>

            {intl({ id: userGuide.description }, { name: userGuide.name ? intl({ id: userGuide.name }) : null, name2: userGuide.name2 ? intl({ id: userGuide.name2 }) : null, name3: userGuide.name3 ? intl({ id: userGuide.name3 }) : null }).split("#").map((item, i) =>
              ["href=", "<b>"].some(el => item.includes(el))
                ? <div className="d-flex align-items-start mb-6" key={i}>
                  <div className="symbol symbol-40 symbol-light-info mr-5">
                    <span className="symbol-label">
                      <span className='svg-icon svg-icon-lg'>
                        <Icon.Bookmark />
                      </span>
                    </span>
                  </div>
                  <div className="d-flex flex-column font-weight-bold">
                    <div className="text-muted">
                      <FormattedHTMLMessage id={item} values={{ what: 'react-intl', a: chunks => <a href='/'>{chunks}</a>, b: chunks => <strong>{chunks}</strong> }} />
                    </div>
                  </div>
                </div>
                : <div className="d-flex align-items-start mb-6" key={i}>
                  <div className="symbol symbol-40 symbol-light-info mr-5">
                    <span className="symbol-label">
                      <span className='svg-icon svg-icon-lg'>
                        <Icon.Bookmark />
                      </span>
                    </span>
                  </div>
                  <div className="d-flex flex-column font-weight-bold">
                    <span className="text-muted">
                      {item}
                    </span>
                  </div>
                </div>
            )}

          </PerfectScrollbar>
        </div>

      </Dropdown.Menu>
    </Dropdown>
  );
}