import React from "react";
import { FormattedMessage } from "react-intl";



export const DOCUMENTS_OPTIONS = [
  {
    value: "orders",
    label: <FormattedMessage id="DOCUMENT.ORDER_TITLE" />,
  },
  {
    value: "offers",
    label: <FormattedMessage id="DOCUMENT.OFFER_TITLE" />,
  },
];

export const searchKeys = [
  "id",
  "order_id",
  "marketplace",
  "customer_details.number",
  "customer_details.name",
  "customer_details.company",
  "customer_details.email",
  "customer_details.phone",
];

export const initialQueryParams = { page: 1, limit: 10 };
export const initialHeaderParams = "id DESC";
export const initialSearchParams = {
  searchText: "",
  searchKeys: searchKeys,
};
export const initialDocumentQueryParams = {
  pagination: initialQueryParams,
  search: initialSearchParams,
  filter: null,
  order: initialHeaderParams,
};
