import React from 'react';
import { SVFeedbackLabel, SVFormControlLabel, SVFormControlWrapper } from './SVFormHelpers';
import Select, { components } from 'react-select';
import { FormattedMessage } from 'react-intl';
import { theme } from '_metronic/_helpers';
import { Icon } from '_metronic/_icons';
import styled from 'styled-components';



const NoOptionsMessage = props => {
  return (
    <components.NoOptionsMessage {...props}>
      {props.selectProps.inputValue.length < 2
        ? <FormattedMessage id="DOCUMENT.CUSTOMER.SELECT_WARNING" />
        : <FormattedMessage id="GENERAL.NO_RESULT" />
      }
    </components.NoOptionsMessage>
  );
};


export const SVReactSelectInput = props => {
  return <StyledReactSelect {...props} />;
};


const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Icon.Search className="icon-xl" />
      </components.DropdownIndicator>
    )
  );
};


export function SVReactSelectSearch({
  field,
  form: { setFieldValue, touched, errors, setFieldTouched },
  options,
  label,
  Option,
  onChangeAction,
  placeholder,
  isSearchable = true,
  isClearable = true,
  components,
  isMulti,
  isDisabled,
  isLoading,
  withFeedbackLabel,
  feedbackLabel = '',
  onInputChange,
  inputValue,
  className,
  ...props
}) {
  return (
    <SVFormControlWrapper className={className}>

      {label && (
        <SVFormControlLabel errors={errors[field.name] && true}>
          {label}
        </SVFormControlLabel>
      )}

      <SVReactSelectInput
        {...field}
        {...props}
        fieldError={touched[field.name] && errors[field.name] ? true : false}
        options={options}
        value={options ? options.find(option => option.value === field.value) : ''}
        onChange={option => { setFieldValue(field.name, option != null ? option.value : ''); }}
        isClearable={isClearable}
        isSearchable={isSearchable}
        // defaultMenuIsOpen={defaultMenuIsOpen}
        isRtl={false}
        isDisabled={isDisabled}
        isLoading={isLoading}
        placeholder={placeholder}
        isMulti={isMulti}
        components={{ NoOptionsMessage, DropdownIndicator }}
        onInputChange={onInputChange}
        inputValue={inputValue}
        onBlur={() => { setFieldTouched(field.name, true); }}
      />

      <SVFeedbackLabel withFeedbackLabel={withFeedbackLabel} errors={errors[field.name] && true}>
        {errors[field.name] ? errors[field.name] : feedbackLabel}
      </SVFeedbackLabel>

    </SVFormControlWrapper>
  );
}


const StyledReactSelect = styled(Select).attrs({
  theme: style => ({
    ...style,
    borderRadius: theme.shape.borderRadius,
    colors: {
      ...style.colors,
      primary: theme.palette.primary['400'],
      primary25: theme.palette.primary['100'],
      primary50: theme.palette.primary['200'],
      primary75: theme.palette.primary['300'],
      danger: theme.palette.danger['color'],
    },
  }),
  styles: {
    control: (styles, state) => ({
      // [state.selectProps] is reaching down to the props of the Select component
      ...styles,
      height: theme.units.input.height[state.selectProps.size ?? 'md'],
      border: state.selectProps.fieldError ? `1px solid ${theme.palette.danger['color']}` : `1px solid ${theme.palette.gray['300']}`,
      boxShadow: theme.shadows.input,
      '&:hover': {
        cursor: 'context-menu',
        outline: `1px solid ${theme.palette.primary['100']}`,
      },
      '&:focus': {
        outline: `1px solid ${theme.palette.primary['300']}`,
        boxShadow: theme.shadows.inputHover,
      },
      fontSize: theme.typography.fontSize[state.selectProps.size ?? 'md'],
    }),
    menu: (base, state) => ({
      ...base,
      zIndex: 1000,
      fontSize: theme.typography.fontSize[state.selectProps.size ?? 'md'],
    }),
  },
})``;
