import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { useIntl } from 'react-intl';
import { getSelectRow, sortCaret, headerSortingClasses, NoRecordsFoundMessage } from '_metronic/_helpers';
import { QuantityFormatter } from 'app/modules/products/product-table/column-formatters';
import { useProductListingsUIContext } from '../../_context/ProductListingsUIContext';
import { RemotePagination } from '_metronic/_partials/controls';
import * as columnFormatters from './column-formatters';
import { ListingActions } from '../../_redux/actions';



export function KauflandListingsTable({ queryParams, setPaginationQuery, setSearchQuery, setOrderQuery, products, count, isLoading }) {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const UIContext = useProductListingsUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    openPrepareKauflandListingEdit: UIContext.openPrepareKauflandListingEdit,
    openSyncAlertDialog: UIContext.openSyncAlertDialog,
    market_id: UIContext.market_id,
  }),
    [
      UIContext.ids,
      UIContext.setIds,
      UIContext.openPrepareKauflandListingEdit,
      UIContext.market_id,
      UIContext.openSyncAlertDialog
    ]
  );


  const publishKauflandInventoryHandler = (id, market_id) => dispatch(ListingActions.publishKauflandInventoryRequest(id, market_id, queryParams));

  const deleteKauflandInventoryHandler = (id, market_id, inventory) => {
    const updateKauflandInventory = inventory.filter((inv) => inv.market_id !== market_id);
    dispatch(ListingActions.ebayInventoryUpdateRequest(id, market_id, { inventories: updateKauflandInventory }, queryParams));
  };

  const COLUMNS = [
    {
      dataField: 'name',
      text: intl({ id: 'GENERAL.NAME' }),
      sort: true,
      sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.NameColumnFormatter,
      headerStyle: { minWidth: '500px' },
      formatExtraData: {
        market_id: UIProps.market_id,
        openPrepareKauflandListingEdit: UIProps.openPrepareKauflandListingEdit,
      },
    },
    {
      dataField: 'inventories',
      text: intl({ id: 'GENERAL.STATUS' }),
      //sort: true,
      sortCaret,
      headerSortingClasses,
      classes: 'text-center',
      headerClasses: 'text-center',
      headerStyle: { minWidth: '150px', width: '15%' },
      formatter: columnFormatters.StatusColumnFormatter,
      formatExtraData: {
        market_id: UIProps.market_id,
        isLoading
      },
    },
    {
      dataField: 'prepare',
      text: intl({ id: 'GENERAL.PREPARE' }),
      editable: false,
      classes: 'text-center',
      headerClasses: 'text-center',
      headerStyle: { minWidth: '70px', width: '7%' },
      formatter: columnFormatters.ActionsColumnFormatterPublish,
      formatExtraData: {
        openSyncAlertDialog: UIProps.openSyncAlertDialog
      },
    },
    {
      dataField: 'quantity',
      text: intl({ id: 'PRODUCT.GENERAL.QUANTITY' }),
      // type: 'number',
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '60px', width: "10%" },
      headerClasses: 'text-center text-nowrap pl-5',
      classes: 'text-center text-nowrap',
      formatter: QuantityFormatter,
    },
    {
      dataField: 'pricing',
      text: intl({ id: 'PRODUCT.TABLE.PRICE_GROSS' }),
      classes: 'font-weight-bolder text-right text-dark-75',
      headerClasses: 'text-right',
      headerStyle: { minWidth: '110px', width: '10%' },
      formatter: (cell, row) => columnFormatters.PricingColumnFormatter(cell, row, UIProps.market_id),
    },
    {
      dataField: 'action',
      text: intl({ id: 'GENERAL.ACTIONS' }),
      editable: false,
      classes: 'text-right',
      headerClasses: 'text-right',
      headerStyle: { minWidth: '150px', width: '10%' },
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openPrepareKauflandListingEdit: UIProps.openPrepareKauflandListingEdit,
        market_id: UIProps.market_id,
        publishKauflandInventoryHandler,
        deleteKauflandInventoryHandler
      },
    },
  ];

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={products || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        bordered={false}
        striped
        condensed
        noDataIndication={<NoRecordsFoundMessage loading={isLoading} onClick={() => setSearchQuery('')} />}
        selectRow={getSelectRow({
          ids: UIProps.ids,
          setIds: UIProps.setIds,
          entities: products != null ? products : [],
        })}
      />
      <RemotePagination
        queryParams={queryParams.pagination}
        setQueryParams={setPaginationQuery}
        totalCount={count}
        entitiesCount={products?.length}
        countForPagination={count}
        paginationSize={8}
        loading={isLoading}
      />
    </>
  );
}
