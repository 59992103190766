import { bicRegExp, emailRegExp, nameRegExp, phoneRegExp, plzRegExp, streetRegExp, taxRegExp, vatRegExp } from "_metronic/_helpers";



export const validator = {

  typeValidator: (cell, intl) => {
    if (cell !== "standard" && cell !== "private" && cell !== "company") {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG.CUSTOMER_TYPE" }),
      };
    } else if (cell.length > 100) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG.CUSTOMER_LONG_TYPE" }),
      };
    }
  },

  nameValidator: (cell, intl) => {
    if (!cell) {
      return {
        valid: false,
        message: intl({ id: "GENERAL.NAME_REQUIRED" }),
      };
    } else if (cell.length > 100 || !nameRegExp.test(cell)) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG.CUSTOMER_NAME" }),
      };
    }
  },

  companyValidator: (cell, row, intl) => {
    if (row.type === "company" && !cell) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG.COMPANY_REQUIRED" }),
      };
    }
    if (cell?.length > 200) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG.COMPANY" }),
      };
    }
  },

  emailValidator: (cell, checkData, intl) => {
    const checkEmail = checkData.includes(cell);
    if (!cell) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG.EMAIL_REQUIRED" })
      };
    } else if (!emailRegExp.test(cell.trim()) || cell.length > 100) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG" }, { title: 'Email' })
      };
    }
    if (checkEmail) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG.EMAIL_UNIQUE" })
      };
    }
  },

  phoneValidator: (cell, intl) => {
    if (cell && (!phoneRegExp.test(cell) || cell.length > 25 || cell.length < 7)) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG.PHONE" }),
      };
    }

  },

  mobileValidator: (cell, intl) => {
    if (cell && (!phoneRegExp.test(cell) || cell.length > 25 || cell.length < 7)) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG.MOBIL" }),
      };
    }

  },

  taxValidator: (cell, intl) => {
    if (!taxRegExp.test(cell) || cell.length > 15) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG.TAX_NUMBER" }),
      };
    }
  },

  vatValidator: (cell, row, intl) => {
    if (row.type === "company" && !cell) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG.VAT_ID_REQUIRED" }),
      };
    }
    if (cell && !vatRegExp.test(cell)) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG.VAT_ID" }),
      };
    }
  },

  faxValidator: (cell, intl) => {
    if (cell && !phoneRegExp.test(cell)) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG" }, { title: 'Fax' }),
      };
    }
  },

  bankValidator: (cell, intl) => {
    if (cell.length > 100) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG" }, { title: 'Bank' }),
      };
    }
  },

  ibanValidator: (cell, intl) => {
    if (cell && (/*!ibanRegExp.test(cell) || bu kisim iban karar regex karar verilince acilacak*/cell.length > 27)) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG" }, { title: 'Iban' }),
      };
    }
  },

  bicValidator: (cell, intl) => {
    if (cell && (!bicRegExp.test(cell) || cell.length > 50)) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG" }, { title: 'Bic' }),
      };
    }
  },

  deliveryCustomerNameValidator: (cell, intl) => {
    if (cell.length > 50 || (cell && !nameRegExp.test(cell))) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG.CUSTOMER_NAME" }),
      };
    }
  },

  companyNameValidator: (cell, intl) => {
    if (cell && (cell.length > 100 || !nameRegExp.test(cell))) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG.COMPANY_NAME" }),
      };
    }
  },

  deliveryAddressLine1Validator: (cell, intl) => {
    if (!cell) {
      return {
        valid: false,
        message: intl({ id: "ADDRESS.STREET_REQUIRED" }),
      };
    } else if (cell?.length > 150 || !streetRegExp.test(cell)) {
      return {
        valid: false,
        message: intl({ id: "ADDRESS.STREET_MATCHES" }),
      };
    }
  },

  invoiceAddressLine1Validator: (cell, intl) => {
    if (cell?.length > 150 || (cell && !streetRegExp.test(cell))) {
      return {
        valid: false,
        message: intl({ id: "ADDRESS.STREET_MATCHES" }),
      };
    }
  },

  invoiceCustomerName: (cell, intl) => {

    if (cell.length > 50) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG.CUSTOMER_NAME" }),
      };
    }

  },

  addressLine2Validator: (cell, intl) => {
    if (cell.length > 150) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG.ADDRESS_LINE_2" }),
      };
    }
  },


  deliveryPostCodeValidator: (cell, row, intl) => {
    if (!cell) {
      return {
        valid: false,
        message: intl({ id: "ADDRESS.POSTCODE_REQUIRED" }),
      };
    } else if (cell.length > 10 || (row?.country_code_1 === "DE" && !plzRegExp.test(cell))) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG.ADDRESS_POSTCODE" }),
      };
    }
  },


  invoicePostCodeValidator: (cell, row, intl) => {
    if (cell.length > 10 || (cell && row?.country_code_2 === "DE" && !plzRegExp.test(cell))) {

      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG.ADDRESS_POSTCODE" }),
      };
    }
  },

  deliveryCityValidator: (cell, intl) => {
    if (!cell) {
      return {
        valid: false,
        message: intl({ id: "ADDRESS.LOCATION_REQUIRED" }),
      };
    } else if (cell.length > 50) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG.ADDRESS_CITY" }),
      };
    }
  },

  invoiceCityValidator: (cell, intl) => {
    if (cell.length > 50) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG.ADDRESS_CITY" }),
      };
    }
  },

  deliveryCountryCodeValidator: (cell, intl) => {
    if (!cell) {
      return {
        valid: false,
        message: intl({ id: "ADDRESS.COUNTRY_REQUIRED" }),
      };
    } else if (cell.length > 2) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG.ADDRESS_COUNTRY" }),
      };
    }
  },

  invoiceCountryCodeValidator: (cell, intl) => {
    if (cell.length > 2) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG.ADDRESS_COUNTRY" }),
      };
    }
  },

  DiscountValidator: (cell, intl) => {
    if (cell < 0 || cell > 100) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG.DISCOUNT" }),
      };
    }
  },

  NotesValidator: (cell, intl) => {
    if (cell?.length > 1000) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.CUSTOMER.ALERT_DIALOG.NOTES" }),
      };
    }
  },
};
