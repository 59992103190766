import React, { useEffect, useState } from 'react';
import { CustomAlert, SVFormControl } from '_metronic/_partials/controls';
import { Link, useHistory } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery, useLoadingHandler } from 'app/hooks';
import { AuthRoutes } from 'constants/moduleRoutes';
import { Field, Form, Formik } from 'formik';
import { AuthActions } from './_redux/actions';
import { Spinner } from '_metronic/_partials';
import { Button } from 'react-bootstrap';
import AuthHeader from './AuthHeader';
import * as Yup from 'yup';



export default function ResetPassword({ recaptchaRef }) {

  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const { formatMessage: intl } = useIntl();


  const [resetKey, setResetKey] = useState();

  const { request: { where, loading, status, error } } = useSelector(state => ({
    request: state.auth.request,
  }));

  const isLoading = useLoadingHandler(
    loading,
    AuthActions.clearLoadingEffects(),
    10000
  );

  useEffect(() => {
    if (query.get('resetKey')) {
      setResetKey(query.get('resetKey'));
    } else {
      history.push('/');
    }
  }, [history, query]);


  const resetPasswordSchema = Yup.object().shape({
    new_password: Yup.string()
      .trim(intl({ id: 'AUTH.GENERAL.BLANK_CHARACTER' }))
      .strict(true)
      .min(6, intl({ id: 'AUTH.REGISTER.PASSWORD.LENGTH_MIN' }))
      .max(50, intl({ id: 'AUTH.REGISTER.PASSWORD.LENGTH_MAX' }))
      .required(intl({ id: 'AUTH.VALIDATION.PASSWORD_REQUIRED' })),
  });

  const requestResult = () => {
    if (!isLoading && where === 'reCreatePassword') {
      return status
    }
  };


  const handleSubmit = (values) => {
    query.get('recaptcha_token')
      ? dispatch(AuthActions.login({ ...values, recaptcha_token: query.get('recaptcha_token') }))
      : onSubmitWithReCAPTCHA(values)
  }

  const onSubmitWithReCAPTCHA = async (values) => {
    const recaptcha_token = await recaptchaRef.current.executeAsync();
    dispatch(AuthActions.reCreatePassword({
      ...values,
      resetKey,
      recaptcha_token
    }));
  }


  useEffect(() => {
    recaptchaRef.current.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, error])

  const [timer, setTimer] = useState(10);

  useEffect(() => {
    let countDown;
    if (requestResult() === 'fulfilled' && timer > 0) {
      countDown = setInterval(() => {
        setTimer(timer - 1)
      }, 1000);
    }

    if (timer === 0) {
      clearInterval(countDown)
      history.push(AuthRoutes.LOGIN)
    }

    return () => clearInterval(countDown)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer, requestResult]);


  useEffect(() => {
    return () => {
      dispatch(AuthActions.cleanUpReducer());
    };
  }, [dispatch]);


  return (
    <div className="login-form">

      <AuthHeader
        title={<FormattedMessage id="AUTH.RESET.TITLE" />}
        subtitle={<FormattedMessage id="AUTH.RESET.CONTENT" />}
      />

      <Formik enableReinitialize={true} initialValues={{ new_password: '' }} validationSchema={resetPasswordSchema} onSubmit={handleSubmit}>
        <Form>

          <Field component={SVFormControl}
            {...{
              type: 'password',
              name: "new_password",
              label: intl({ id: 'AUTH.INPUT.NEW_PASSWORD' }),
              withFeedbackLabel: true,
              feedbackLabel: true,
              disabled: isLoading || requestResult() === 'fulfilled',
            }}
          />

          <Button variant='primary' type="submit" className="font-weight-bold w-100 py-4 my-3" disabled={isLoading || requestResult() === 'fulfilled'} id='btn_reset'>
            <FormattedMessage id="GENERAL.SAVE" />
            <Spinner loading={isLoading} />
          </Button>

        </Form>
      </Formik>


      <div className='d-flex text-center my-4 my-lg-6'>
        <Link to={AuthRoutes.LOGIN} className="text-hover-primary font-weight-bold w-100" id='btn_back_login'>
          <FormattedMessage id="AUTH.GENERAL.BACK_TO_LOGIN" /> {requestResult() === 'fulfilled' && `(${timer})`}
        </Link>
      </div>

      {requestResult() === 'fulfilled' &&
        <CustomAlert
          type="success"
          title={intl({ id: 'GENERAL.TEAM' })}
          message={intl({ id: "AUTH.RESET.SUCCESS" })}
        />
      }

      {requestResult() === 'failed' &&
        <CustomAlert
          type="danger"
          title={intl({ id: 'AUTH.GENERAL.ERRORS.TITLE' })}
          message={intl({ id: `${error}` })}
          onClose={() => dispatch(AuthActions.cleanUpReducer())}
        />
      }

    </div>
  );
}