import { API } from 'constants/apiUrl';



export const notificationMiddleware = {

  getNotification: async function (accessToken) {
    return await API.get(`/notification`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });
  },

}
