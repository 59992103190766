import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Icon } from "_metronic/_icons";
import { toast } from "react-toastify";



export function ProductKeysGrouping({UIProps}) {

  const { keys } = useSelector(state => state.products.digitalProductKeys || [], shallowEqual);

  const deleteAllProductKeys = () => {

    const idsToDelete = UIProps?.keyIds?.filter(id => {
      return keys.find(item => item.id === id);
    });

    if (idsToDelete.length) {
      UIProps.openDeleteProductKeysDialog(idsToDelete);
    } else {
      toast.warning("You can't delete sold keys");
    }
  };


  return (
      <Button variant="light-primary" className="svg-icon svg-icon-sm font-weight-bold ml-auto" onClick={deleteAllProductKeys} disabled={UIProps.keyIds.length <= 0} id="btn_multiple_delete_key">
        <Icon.Trash />
        <span className="d-none d-sm-inline ml-2">
          <FormattedMessage id="GENERAL.DELETE" />
        </span>
      </Button>
  );
}
