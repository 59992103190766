export default class ActionTypes {
  static get PREFIX() {
    return '[supplier]';
  }


  // Create supplier action types
  static get CREATE_SUPPLIER() {
    return `${ActionTypes.PREFIX}CREATE_SUPPLIER`;
  }
  static get CREATE_SUPPLIER_SUCCESS() {
    return `${ActionTypes.PREFIX}CREATE_SUPPLIER_SUCCESS`;
  }
  static get CREATE_SUPPLIER_FAIL() {
    return `${ActionTypes.PREFIX}CREATE_SUPPLIER_FAIL`;
  }


  // Update supplier action types
  static get UPDATE_SUPPLIER() {
    return `${ActionTypes.PREFIX}UPDATE_SUPPLIER`;
  }
  static get UPDATE_SUPPLIER_SUCCESS() {
    return `${ActionTypes.PREFIX}UPDATE_SUPPLIER_SUCCESS`;
  }
  static get UPDATE_SUPPLIER_FAIL() {
    return `${ActionTypes.PREFIX}UPDATE_SUPPLIER_FAIL`;
  }


  // Delete supplier action types
  static get DELETE_SUPPLIER() {
    return `${ActionTypes.PREFIX}DELETE_SUPPLIER`;
  }
  static get DELETE_SUPPLIER_SUCCESS() {
    return `${ActionTypes.PREFIX}DELETE_SUPPLIER_SUCCESS`;
  }
  static get DELETE_SUPPLIER_FAIL() {
    return `${ActionTypes.PREFIX}DELETE_SUPPLIER_FAIL`;
  }


  // Get Lieferanten action types
  static get GET_SUPPLIER() {
    return `${ActionTypes.PREFIX}GET_SUPPLIER`;
  }
  static get GET_SUPPLIER_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_SUPPLIER_SUCCESS`;
  }
  static get GET_SUPPLIER_FAIL() {
    return `${ActionTypes.PREFIX}GET_SUPPLIER_FAIL`;
  }


  // Search Lieferanten action types
  static get SEARCH_SUPPLIER() {
    return `${ActionTypes.PREFIX}SEARCH_SUPPLIER`;
  }
  static get SEARCH_SUPPLIER_SUCCESS() {
    return `${ActionTypes.PREFIX}SEARCH_SUPPLIER_SUCCESS`;
  }
  static get SEARCH_SUPPLIER_FAIL() {
    return `${ActionTypes.PREFIX}SEARCH_SUPPLIER_FAIL`;
  }


  // Get supplier by id action types 
  static get GET_SUPPLIER_BY_ID() {
    return `${ActionTypes.PREFIX}GET_SUPPLIER_BY_ID`;
  }
  static get GET_SUPPLIER_BY_ID_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_SUPPLIER_BY_ID_SUCCESS`;
  }
  static get GET_SUPPLIER_BY_ID_FAIL() {
    return `${ActionTypes.PREFIX}GET_SUPPLIER_BY_ID_FAIL`;
  }


  // Find Supplier By Property action types
  static get FIND_SUPPLIER_BY_PROPERTY() {
    return `${ActionTypes.PREFIX}FIND_SUPPLIER_BY_PROPERTY`;
  }
  static get FIND_SUPPLIER_BY_PROPERTY_SUCCESS() {
    return `${ActionTypes.PREFIX}FIND_SUPPLIER_BY_PROPERTY_SUCCESS`;
  }
  static get FIND_SUPPLIER_BY_PROPERTY_FAIL() {
    return `${ActionTypes.PREFIX}FIND_SUPPLIER_BY_PROPERTY_FAIL`;
  }

  // Clean Up Form Handler action types
  static get HANDLE_CLEAN_UP_FORM() {
    return `${ActionTypes.PREFIX}HANDLE_CLEAN_UP_FORM`;
  }
}
