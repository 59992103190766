import React from "react";
import { FormattedMessage } from "react-intl";



export const productListings = {
    main: {
        title: <FormattedMessage id="MENU.ASIDE.LISTINGS" />,
        location: 'listings',
        description: "USER_GUIDE.LISTINGS"
    },
    ebay: {
        title: <FormattedMessage id="PRODUCT_LISTINGS.TITLE" values={{ shopName: 'Ebay' }} />,
        description: "USER_GUIDE.LISTINGS.EBAY"
    },
    kaufland: {
        title: <FormattedMessage id="PRODUCT_LISTINGS.TITLE" values={{ shopName: 'Kaufland' }} />,
        description: "USER_GUIDE.LISTINGS.KAUFLAND"
    }
}

