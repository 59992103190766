import React from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import EnterPaymentBody from '../../enter-payment-dialog/EnterPaymentBody';
import { BlankMessage } from '_metronic/_partials/components';
import { UserInfoDropdown } from '_metronic/_partials';
import { Icon } from '_metronic/_icons';



export function PaymentCard({ documentId, requestedDocument, handleOpenEnterPaymentEditDialog }) {

  const { values } = useFormikContext();
  const positions = useSelector(state => state.documents.positions);

  return (
    !['offers', 'waybills'].includes(requestedDocument) &&
    <Card className="gutter-b">

      <CardSubHeader
        icon={<Icon.Cash />}
        title={<FormattedMessage id="DOCUMENT.PAYMENT_TITLE" />}
        info={<UserInfoDropdown
          title={<FormattedMessage id='DOCUMENT.PAYMENT.INFO_TITLE' />}
          description={<FormattedMessage id='DOCUMENT.PAYMENT.INFO_DESCRIPTION' />} />}
      >

        {values.payment_status === 'open' && positions.length &&
          <Button variant='outline-primary' size='sm' className="svg-icon svg-icon-sm" onClick={() => handleOpenEnterPaymentEditDialog()} id="btn_payment">
            <Icon.Plus />
            <span className="d-none d-sm-inline font-weight-bold ml-2">
              <FormattedMessage id='DOCUMENT.PAYMENT.ENTER_TITLE' />
            </span>
          </Button>}

      </CardSubHeader>

      <CardBody className="pt-0 px-0">

        {positions.length
          ? <EnterPaymentBody documentId={documentId} />
          : <BlankMessage
            icon={<Icon.Cash />}
            title={<FormattedMessage id='DOCUMENT.POSITION.BLANK_MESSAGE_TITLE' />}
            message={<FormattedMessage id='DOCUMENT.POSITION.BLANK_MESSAGE' />}
            arrow
          />
        }

      </CardBody>

    </Card>
  );
}
