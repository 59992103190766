import React from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { AuthActions } from 'app/pages/auth/_redux/actions';
import { useDispatch } from 'react-redux';
import { CloseButton } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import { Portal } from 'react-portal';


export const clearCacheFN = (dispatch) => {
  localStorage.clear();
  sessionStorage.clear();
  window.location.reload();
  dispatch(AuthActions.logout());
}

export default function ClearCacheDialog({ UIProps }) {

  const dispatch = useDispatch();

  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={UIProps.showClearCacheDialog} backdrop="static" centered>

        <Modal.Body className='text-center p-10'>
          <CloseButton onClick={() => UIProps.closeClearCacheDialog()} />

          <div className="d-flex flex-column align-items-center text-dark-75 my-8">
            <Icon.Eraser className="icon-5x mb-3" />
            <h3>
              <FormattedMessage id='SETTINGS.LOGIN.CLEAR_CACHE' />
            </h3>
          </div>

          <Alert variant='light-info' className="alert-custom alert-notice mb-6">
            <div className="alert-text text-justify text-dark-75 line-height-xl">
              <FormattedMessage id='SETTINGS.LOGIN.CLEAR_CACHE.INFO' />
            </div>
          </Alert>

        </Modal.Body>

        <Modal.Footer>
          <Button variant='light' type='button'
            onClick={() => { UIProps.closeClearCacheDialog() }} id='btn_close_modal_clear_cache'>
            <FormattedMessage id="GENERAL.CLOSE" />
          </Button>
          <Button variant='primary' type="button" onClick={() => { clearCacheFN(dispatch); UIProps.closeClearCacheDialog() }} >
            <FormattedMessage id='SETTINGS.LOGIN.CLEAR_CACHE' />
          </Button>
        </Modal.Footer>

      </Modal>
    </Portal>
  )
}
