import React from 'react'
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { UIDates } from '_metronic/_helpers';
import { Icon } from '_metronic/_icons';
import moment from 'moment';



export default function DeleteRequest({ userBaseData }) {

  const info = [
    {
      label: "ADMIN.USER.DELETED_REQUEST.DATE",
      content: UIDates.formatDateTime(userBaseData?.fail_log?.deleted_request?.created_at),
    },
    {
      label: "ADMIN.USER.DELETED_REQUEST.REMAINING",
      content: RemainingDays(userBaseData?.fail_log?.deleted_request?.created_at)
    }
  ];


  return (
    <Card className="card-stretch gutter-b">

      <CardSubHeader icon={<Icon.PersonX />} title={<FormattedMessage id="ADMIN.USER.ACCOUNT_STATUS.DELETED_REQUEST" />} />

      <CardBody>
        {info.length && (
          <div className="py-2 px-2 d-flex flex-column">
            {info?.map((item, i) => (
              <h6 className={`d-flex align-items-start flex-column flex-md-row ${info.length - 1 === i ? "" : "mb-6"}`} key={i}>
                <span className="text-dark-50 mw-200px mb-3">
                  <FormattedMessage id={item.label} />
                </span>
                <span className={i === info.length - 1 ? "ml-2" : ""}>
                  {item.content}
                </span>
              </h6>
            ))}
          </div>
        )}

        <div className="separator separator-dashed my-5 my-md-5" />

        <Row>
          <Col md='6'>
            <div className="py-2 px-2 d-flex flex-column">
              <h6 className={`d-flex align-items-start flex-column flex-md-row mb-4`}>
                <span className="text-dark-50 mw-200px mb-3">
                  <FormattedMessage id="ADMIN.USER.SURVEY_RESULT" />
                </span>
              </h6>

              {userBaseData?.fail_log?.deleted_request?.surveys?.length > 0 &&
                <ul>
                  {userBaseData?.fail_log?.deleted_request?.surveys?.map((survey, index) => (
                    <li key={index} className="text-dark-75 font-weight-bolder mw-200px mb-3">
                      {survey}
                    </li>
                  ))}
                </ul>
              }

            </div>
          </Col>
          <Col md='6'>
            <div className="py-2 px-4 d-flex flex-column">
              <h6 className={`d-flex align-items-start flex-column flex-md-row mb-4`}>
                <span className="text-dark-50 mw-200px mb-3">
                  <FormattedMessage id="GENERAL.DESCRIPTION" />
                </span>
              </h6>
              <span className="text-dark-75 font-weight-bolder mw-200px mb-3">
                {userBaseData?.fail_log?.deleted_request?.description}
              </span>
            </div>
          </Col>
        </Row>
      </CardBody>

    </Card>
  )
}



export function RemainingDays(targetDate) {
  if (!targetDate) return null;

  const creationDate = moment(targetDate);
  const currentDate = moment();
  const expirationDate = creationDate.clone().add(30, 'days');
  const remainingDays = expirationDate.diff(currentDate, 'days');

  const remainingColors = (day) => {
    return day > 0
      ? day < 7
        ? "warning"
        : day < 3
          ? "danger"
          : "success"
      : "danger";
  }

  return (
    <OverlayTrigger overlay={<Tooltip id="connection-check-tooltip">{creationDate.format('DD MMM YYYY, HH.mm')}</Tooltip>}>
      <label className={`label label-inline label-lg svg-icon svg-icon-sm text-nowrap label-outline-${remainingColors(remainingDays)}`}>
        <span className={`label label-lg label-dot mr-2 label-${remainingColors(remainingDays)}`}></span>
        <span>{remainingDays > 0 ? `${remainingDays} days left` : `Expired`}</span>
      </label>
    </OverlayTrigger>
  );
}
