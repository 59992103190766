import React from 'react';
import { Icon } from '_metronic/_icons';
import { CloseButton } from '../..';
import clsx from 'clsx';


const ALERT_TYPES = [
  {
    type: 'success',
    icon: <Icon.HandUp />,
  },
  {
    type: 'warning',
    icon: <Icon.ExclamationTriangle />,
  },
  {
    type: 'danger',
    icon: <Icon.ExclamationTriangle />,
  },
];


export function CustomAlert({ title, message, type, onClose, className }) {
  return (
    <div className={clsx(`alert alert-custom alert-bold-border alert-outline-${type} fade show`, className)} role="alert">

      <div className={`alert-icon text-${type} h1`}>
        {ALERT_TYPES.map((item, index) => (
          <span key={index}>{item.type === type && item.icon}</span>
        ))}
      </div>

      <div className="alert-text">
        <div className={`font-weight-bold text-uppercase text-${type} mb-1`}>
          {title}
        </div>
        <div className="text-dark-50 font-weight-bold">
          {message}
        </div>
      </div>

      {onClose &&
        <div className="alert-close">
          <CloseButton className="text-danger" onClick={onClose} />
        </div>
      }
    </div>
  );
}