/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Icon } from '_metronic/_icons';



export default function DeleteButton({ onClick, className, disabled, id }) {
  return (
    <OverlayTrigger overlay={<Tooltip id="document-print-tooltip"><FormattedMessage id="GENERAL.DELETE" /></Tooltip>}>
      <Button variant='icon' size='sm' className={`btn-hover-danger svg-icon svg-icon-md ${className ?? className}`} onClick={onClick} disabled={disabled} id={`btn_delete`}>
        <Icon.Trash />
      </Button>
    </OverlayTrigger>
  )
}