import React from "react";
import { Icon } from '_metronic/_icons';
import { ModuleRoutes } from "./moduleRoutes";
import { IsDevDomainFn } from "./apiUrl";



export const CONNECTION_SETTINGS = [
  {
    active: true,
    multiple: true,
    type: "marketplace",
    name: "ebay",
    label: "Ebay",
    icon: <Icon.Ebay />,
    iconLong: <Icon.EbayLong />,
    // route: ConnectionsActions.getEbayAuthToken(),
    listingRoute: (id) => ModuleRoutes.EBAY_LISTINGS_FN(id),
    product: (license) => true,
  },
  {
    active: true,
    multiple: true,
    type: "marketplace",
    name: "kaufland",
    label: "Kaufland",
    icon: <Icon.Kaufland />,
    iconLong: <Icon.KauflandLong />,
    listingRoute: (id) => ModuleRoutes.KAUFLAND_LISTINGS_FN(id),
    product: (license) => license //Development license ile kullanılabilecek
  },
  {
    active: true,
    multiple: true,
    type: "marketplace",
    name: "amazon",
    label: "Amazon",
    icon: <Icon.Amazon />,
    iconLong: <Icon.AmazonLong />,
    listingRoute: (id) => ModuleRoutes.AMAZON_LISTINGS_FN(id),
    product: (license) => license //Development license ile kullanılabilecek
  },
  {
    active: true,
    multiple: false,
    type: "marketplace",
    name: "amazon_prime",
    label: "Amazon Prime",
    icon: <Icon.AmazonPrime />,
    iconLong: <Icon.AmazonPrime />,
    listingRoute: (id) => ModuleRoutes.AMAZON_LISTINGS_FN(id),
    product: (license) => license //Development license ile kullanılabilecek
  },
  {
    active: true,
    multiple: false,
    type: "marketplace",
    name: "hood",
    label: "Hood",
    icon: <Icon.Hood />,
    iconLong: <Icon.HoodLong />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: false,
    multiple: false,
    type: "marketplace",
    name: "check24",
    label: "Check24",
    icon: <Icon.Check24 />,
    iconLong: <Icon.Check24Long />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: true,
    multiple: false,
    type: "marketplace",
    name: "manomano",
    label: "Manomano",
    icon: <Icon.Manomano />,
    iconLong: <Icon.ManomanoLong />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: true,
    type: "shop",
    multiple: true,
    name: "prestashop",
    label: "PrestaShop",
    icon: <Icon.PrestaShop />,
    iconLong: <Icon.PrestaShopLong />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: true,
    multiple: true,
    type: "shop",
    name: "shopware5",
    label: "Shopware 5",
    icon: <Icon.Shopware />,
    iconLong: <Icon.ShopwareLong />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: true,
    multiple: true,
    type: "shop",
    name: "shopware6",
    label: "Shopware 6",
    icon: <Icon.Shopware />,
    iconLong: <Icon.Shopware6Long />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: IsDevDomainFn(),
    multiple: false,
    type: "marketplace",
    name: "otto",
    label: "Otto",
    icon: <Icon.Otto />,
    iconLong: <Icon.OttoLong />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: true,
    multiple: true,
    type: "shop",
    name: "woocommerce",
    label: "Woocommerce",
    icon: <Icon.Woocommerce />,
    iconLong: <Icon.WoocommerceLong />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: true,
    multiple: true,
    type: "shop",
    name: "shopify",
    label: "Shopify",
    icon: <Icon.Shopify />,
    iconLong: <Icon.ShopifyLong />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: IsDevDomainFn(),
    multiple: true,
    type: "marketplace",
    name: "etsy",
    label: "Etsy",
    icon: <Icon.Etsy />,
    iconLong: <Icon.EtsyLong />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: true,
    multiple: false,
    type: "shipper",
    name: "dhl",
    label: "DHL",
    icon: <Icon.Dhl className='rounded' />,
    iconLong: <Icon.DhlLong />,
    product: (license) => true,
  },
  {
    active: true,
    multiple: false,
    type: "shipper",
    name: "dhl_new",
    label: "DHL",
    icon: <Icon.DhlNew className='rounded' />,
    iconLong: <Icon.DhlNewLong />,
    product: (license) => license //Development license ile kullanılabilecek
  },
  {
    active: true,
    multiple: false,
    type: "shipper",
    name: "dhl_warenpost",
    label: "DHL Warenpost",
    icon: <Icon.DhlWarenpost className='rounded' />,
    iconLong: <Icon.DhlWarenpostLong />,
    product: (license) => true,
  },
  {
    active: true,
    multiple: false,
    type: "shipper",
    name: "dhl_new_warenpost",
    label: "DHL Warenpost",
    icon: <Icon.DhlNewWarenpost className='rounded' />,
    iconLong: <Icon.DhlNewWarenpostLong />,
    product: (license) => license //Development license ile kullanılabilecek
  },
  {
    active: true,
    multiple: false,
    type: "shipper",
    name: "gls",
    label: "GLS",
    icon: <Icon.Gls className='rounded' />,
    iconLong: <Icon.GlsLong />,
    product: (license) => true,
  },
  {
    active: true,
    multiple: false,
    type: "shipper",
    name: "dpd",
    label: "DPD",
    icon: <Icon.Dpd className='rounded' />,
    iconLong: <Icon.DpdLong />,
    product: (license) => true,
  },
  {
    active: true,
    multiple: false,
    type: "shipper",
    name: "deutsche_post",
    label: "Deutsche Post",
    icon: <Icon.DeutschePost className='rounded' />,
    iconLong: <Icon.DeutschePostLong />,
    product: (license) => true,
  },
  {
    // Dummy data sadece customer table için eklendi
    active: false,
    multiple: false,
    type: "shop",
    name: "salevali",
    label: "SaleVali",
    icon: <Icon.Shop />,
    iconLong: <Icon.Shop />,
    product: (license) => false,
  }
]

export const getConnection = (name) => (CONNECTION_SETTINGS.find(market => market.name === name))
