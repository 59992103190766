import React from 'react';
import { Route } from 'react-router-dom';
import { CustomerDeleteDialog, CustomerDetailsDialog, SetDocumentDialog } from './customer-dialogs';
import { CustomerExportDialog } from './customer-dialogs/CustomerExportDialog';
import { SearchQueryProvider } from './_context/SearchQueryProvider';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { getLastSearch } from '_metronic/_helpers';
import { CustomersCard } from './CustomersCard';



export function CustomersPage() {
  const lastSearch = getLastSearch()
  return (
    <SearchQueryProvider>
      <CustomerDetailsDialog />
      <CustomerExportDialog />
      <Route path={ModuleRoutes.DELETE_CUSTOMER}>
        {({ history, match }) => (
          <CustomerDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => history.push(ModuleRoutes.CUSTOMER + lastSearch)}
          />
        )}
      </Route>
      <Route path={ModuleRoutes.DELETE_CUSTOMER_LISTS}>
        {({ history, match }) => (
          <CustomerDeleteDialog
            show={match != null}
            id={match && match.params.ids}
            multiple={true}
            onHide={() => history.push(ModuleRoutes.CUSTOMER + lastSearch)}
          />
        )}
      </Route>
      <Route path={ModuleRoutes.SET_DOCUMENT_CUSTOMER}>
        {({ history, match }) => (
          <SetDocumentDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => history.push(ModuleRoutes.CUSTOMER + lastSearch)}
          />
        )}
      </Route>
      <CustomersCard />
    </SearchQueryProvider>
  );
}