import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ConnectionsActions } from "../../_redux/actions";
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Formik, Form } from "formik";



export default function OttoForm({ children, loading, onHide }) {

  const dispatch = useDispatch();

  const { ottoForEdit, success, licenseType } = useSelector(state => ({
    ottoForEdit: state.connections.ottoForEdit,
    success: state.connections.success,
    licenseType: state.auth.license.type,
  }), shallowEqual);

  // State for checkbox value
  const [isSandboxChecked, setSandboxChecked] = useState(false);

  const handleSubmit = () => {
    const sandbox = isSandboxChecked ? 'true' : 'false';
    dispatch(ConnectionsActions.getOttoAuthToken(sandbox, "auth", null));
  };

  const handleSandboxChange = (e) => {
    setSandboxChecked(e.target.checked);
  };

  useEffect(() => {
    return () => {
      dispatch(ConnectionsActions.clearLoadingEffects())
    }
  }, [dispatch])


  return (
    <Formik enableReinitialize={true} initialValues={ottoForEdit} onSubmit={handleSubmit}>
      <Form autoComplete='off'>

        <Modal.Body className='p-0'>
          {children}
        </Modal.Body>

        <Modal.Footer>

          {(licenseType === 'development' || licenseType === 'tester') &&
            <ul className="navi font-weight-bold mr-auto">
              <li className="navi-item">
                <label className="navi-link checkbox checkbox-md checkbox-outline checkbox-primary">
                  <input type="checkbox" name="sandbox" checked={isSandboxChecked} onChange={handleSandboxChange} id="checkbox_sandbox" />
                  <span />
                  <div className="text-nowrap">
                    <FormattedMessage id="GENERAL.SANDBOX" />
                  </div>
                </label>
              </li>
            </ul>
          }

          <Button variant="light" onClick={onHide} disabled={loading} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant='primary' type="submit" className='ml-2' disabled={loading || success} id='btn_get_token'>
            <FormattedMessage id="GENERAL.GET_TOKEN" />
          </Button>

        </Modal.Footer>

      </Form>
    </Formik>
  )
}
