import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Portal } from 'react-portal';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useUserSettingsUIContext } from '../../_context/UserSettingsUIContext';
import { AuthActions } from '../../../../pages/auth/_redux/actions';
import { emailRegExp, maxValidation } from '_metronic/_helpers';
import { CloseButton } from '_metronic/_partials';
import EmailEditForm from './EmailEditForm';
import { Icon } from '_metronic/_icons';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';



export default function EmailEdit() {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();
  const [isPassword, setIsPassword] = useState(false);

  const { user, success, loading, message } = useSelector(state => ({
    user: state.auth.user,
    success: state.auth.success,
    loading: state.auth.loading,
    message: state.auth.message,
  }));

  const UIContext = useUserSettingsUIContext();
  const UIProps = useMemo(() => ({
    closeEmailEditDialog: UIContext.closeEmailEditDialog,
    showEmailEditDialog: UIContext.showEmailEditDialog,
  }),
    [
      UIContext.closeEmailEditDialog,
      UIContext.showEmailEditDialog,
    ]
  );


  const handleEmailSubmit = (values) => {

    if (values.password === '') {
      setIsPassword(true);
    } else {
      const newValues = {
        new_email: values.new_email,
        password: values.password,
      }

      dispatch(AuthActions.changeEmail(newValues))
    }
  }

  const handleCloseModal = () => {
    UIProps.closeEmailEditDialog();
    dispatch(AuthActions.cleanUpReducer());
    setIsPassword(false)
  }

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        UIProps.closeEmailEditDialog();
        dispatch(AuthActions.cleanUpReducer());
        setIsPassword(false)
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, dispatch]);

  const emailValidationSchema = Yup.object().shape({
    new_email: Yup.string()
      .max(100, maxValidation("100"))
      .matches(emailRegExp, intl({ id: 'GENERAL.EMAIL_EMAIL' }))
      .email(intl({ id: 'GENERAL.EMAIL_EMAIL' }))
      .required(intl({ id: 'AUTH.VALIDATION.EMAIL_REQUIRED' }))
      .test('match', intl({ id: 'SETTINGS.LOGIN.MATCH_EMAIL' }), (value) => value !== user.email),
    password: Yup.string()
      .trim(intl({ id: 'AUTH.GENERAL.BLANK_CHARACTER' }))
      .strict(true)
      // .min(8, intl({ id: 'AUTH.REGISTER.PASSWORD.LENGTH_MIN' }))
      .max(50, intl({ id: 'AUTH.REGISTER.PASSWORD.LENGTH_MAX' }))
    // .matches(passwordRedExp, intl({ id: 'AUTH.REGISTER.PASSWORD.MATCHES' }))
    // .required(intl({ id: 'AUTH.VALIDATION.PASSWORD_REQUIRED' })),
  });


  return (
    <Portal node={document && document.getElementById('modal-portal')}>
      <Modal show={UIProps.showEmailEditDialog} onHide={handleCloseModal} size="md" aria-labelledby="contained-modal-title-vcenter" backdrop="static" keyboard={false} centered>

        <Formik enableReinitialize={true} initialValues={{ new_email: '', password: '' }} validationSchema={emailValidationSchema} onSubmit={handleEmailSubmit}>
          <Form>

            <Modal.Body className='p-10'>

              <CloseButton onClick={handleCloseModal} />

              <div className="d-flex flex-column align-items-center text-dark-75 my-8">
                <Icon.EnvelopeAt className="icon-5x mb-3" />
                <h3>
                  <FormattedMessage id='SETTINGS.LOGIN.CHANGE_EMAIL' />
                </h3>
              </div>

              <EmailEditForm isPassword={isPassword} loading={loading} success={success} message={message} />

            </Modal.Body>

            <Modal.Footer>
              <Button variant='light' onClick={handleCloseModal} id='btn_close_modal_email'>
                <FormattedMessage id="GENERAL.CLOSE" />
              </Button>

              <Button variant='primary' type="submit" className='ml-2' disabled={loading || success}>
                <FormattedMessage id='SETTINGS.LOGIN.CHANGE_SAVE' />
              </Button>
            </Modal.Footer>

          </Form>
        </Formik>

      </Modal>
    </Portal>
  )
}
