import React, { useState } from 'react';
import { Button, Col, ListGroup, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { SVFormControl } from '_metronic/_partials/controls';
import { FormattedMessage, useIntl } from 'react-intl';
import PerfectScrollbar from "react-perfect-scrollbar";
import { maxValidation } from '_metronic/_helpers';
import { Field, Form, Formik } from 'formik';
import { Icon } from '_metronic/_icons';
import { Portal } from 'react-portal';
import * as Yup from 'yup';



export const VariantAddForm = ({ show, onHide, variantOptions, handleSubmitSaveVariant }) => {

  const { formatMessage: intl } = useIntl();
  const [aspects, setAspects] = useState([]);

  const handleAddAspect = (values, { setFieldValue }) => {
    const aspectName = values.aspect_names?.trim();
    const valid = aspects.some(item => item?.toLowerCase() === aspectName?.toLowerCase());
    if (aspectName && !valid) {
      setFieldValue("aspect_names", "")
      setAspects([...aspects, aspectName]);
    }
  };

  const deleteAspect = (index) => setAspects(aspects.filter((item, i) => i !== index));


  const isExistVariant = (value) => {
    if (variantOptions) {
      const isVariantExists = variantOptions?.some(item => item.name?.toLowerCase() === value?.trim()?.toLowerCase())
      return !isVariantExists
    }
    return true;
  }

  const variantValidationSchema = Yup.object().shape({
    variant_name: Yup.string()
      .max(50, maxValidation("50"))
      .required(intl({ id: "GENERAL.REQUIRED" }))
      .test('variant_name', intl({ id: 'SETTINGS.PRODUCT.VARIANT_NAME.EXISTS' }), isExistVariant),
    aspect_names: Yup.string()
      .max(50, maxValidation("50"))
      .test('aspect_names', intl({ id: 'SETTINGS.PRODUCT.ASPECT_NAME.EXISTS' }), value =>
        !aspects?.some(item => item?.toLowerCase() === value?.trim()?.toLowerCase()))
  });

  const handleOnHide = () => {
    onHide();
    setAspects([])
  }


  return (
    <Portal node={document && document.getElementById('modal-portal')}>
      <Modal show={show} onHide={handleOnHide} size="md" backdrop="static" keyboard={false} className="mt-40">

        <Modal.Header>
          <Modal.Title>
            <Icon.Diagram3 className='mr-3' />
            <FormattedMessage id="PRODUCT.VARIANT.ATTRIBUTES_SELECT" />
          </Modal.Title>
        </Modal.Header>

        <Formik enableReinitialize={true} initialValues={{ aspect_names: '', variant_name: "" }} validationSchema={variantValidationSchema} onSubmit={handleAddAspect}>
          {({ values }) => (
            <Form>

              <Modal.Body>
                <Row>

                  <Col lg="6">
                    <Field component={SVFormControl}
                      {...{
                        name: "variant_name",
                        label: intl({ id: "PRODUCT.ADD_VARIANT.VARIANT_NAME" }),
                        feedbackLabel: true,
                        required: true,
                      }}
                    />
                  </Col>

                  <Col lg="6">
                    <Field component={SVFormControl}
                      {...{
                        name: "aspect_names",
                        label: intl({ id: "PRODUCT.ADD_VARIANT.ASPECT_NAME" }),
                        feedbackLabel: true,
                        required: true,
                        onClickSymbol: <Button variant='secondary' type="submit" id='btn_add_variant'><Icon.Plus /></Button>
                      }}
                    />
                  </Col>

                  {aspects?.length > 0 &&
                    <Col lg="12" className='mt-4 ml-2'>
                      <PerfectScrollbar className="scroll pr-5 mr-n5" options={{ wheelSpeed: 1, wheelPropagation: false }} style={{ maxHeight: '400px' }}>

                        <ListGroup variant="flush" className="active">

                          {aspects?.map((aspect, index) =>
                            <ListGroup.Item className="d-flex justify-content-between align-items-center p-0 pr-2" key={aspect}>
                              <span className='font-weight-bolder'>{aspect}</span>

                              <OverlayTrigger overlay={<Tooltip id="customers-delete-tooltip"><FormattedMessage id="GENERAL.DELETE" /></Tooltip>}>
                                <span className="btn btn-icon btn-sm text-muted text-hover-danger" onClick={() => deleteAspect(index)} id="btn_delete">
                                  <span className="svg-icon">
                                    <Icon.Trash />
                                  </span>
                                </span>
                              </OverlayTrigger>

                            </ListGroup.Item>
                          )}

                        </ListGroup>

                      </PerfectScrollbar>
                    </Col>
                  }

                </Row>
              </Modal.Body>

              <Modal.Footer>
                <Button variant='light' onClick={handleOnHide} id='btn_cancel_modal'>
                  <FormattedMessage id="GENERAL.CANCEL" />
                </Button>

                <Button variant='primary' className="ml-2" onClick={() => { handleSubmitSaveVariant(values, aspects); handleOnHide() }} disabled={!(aspects?.length && isExistVariant(values.variant_name) && values.variant_name)} id='btn_save_modal'>
                  <FormattedMessage id="GENERAL.SAVE" />
                </Button>
              </Modal.Footer>

            </Form>
          )}
        </Formik>

      </Modal>
    </Portal>
  );
}