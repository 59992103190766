import React from 'react'
import { Alert } from 'react-bootstrap'
import { Icon } from '_metronic/_icons';
import { FormattedHTMLMessage, useIntl } from 'react-intl'
import clsx from 'clsx';



export default function AlertInfo({ title, description, className, button }) {
   const { formatMessage: intl } = useIntl();

   return (
      <Alert variant='light-info' className={clsx("alert-custom alert-notice bg-white py-3 px-7 mb-0", className)}>
         <span className="svg-icon svg-icon-4x text-info mr-6">
            <Icon.InfoCircle />
         </span>

         <div className="alert-text text-justify text-dark-75 line-height-xl">
            <Alert.Heading>
               {intl({ id: title })}
            </Alert.Heading>
            <ul className='pl-6 mb-0'>
               {intl({ id: description }).split("#").map((item, i) => ["href=", "<b>", "class="].some(el => item.includes(el))
                  ? <li key={i}> <FormattedHTMLMessage id={item} /></li>
                  : <li key={i}>{item}</li>)}
               <li>{button}</li>
            </ul>

         </div>

      </Alert>
   )
}