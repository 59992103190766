import ActionTypes from './actionTypes';

const initiaState = {
  product: null,
  items: [],
  item: null,
  status: 'idle',
  marketPlace: null,
  error: null
};

export function amazonListingReducer(state = initiaState, action) {
  switch (action.type) {
    case ActionTypes.AMAZON_FIND_LISTING_ITEM:
      return { ...state, error: null, item: null, status: `loading/${action.type}` };
    case ActionTypes.AMAZON_FIND_LISTING_ITEM_SUCCESS:
      return { ...state, item: action.payload.item, status: `succeeded/${action.type}` };
    case ActionTypes.AMAZON_FIND_LISTING_ITEM_FAIL:
      return { ...state, error: action.payload.error, status: `failed/${action.type}` };

    // Create new Amazon listing by existing product
    case ActionTypes.AMAZON_CREATE_LISTING:
      return { ...state, error: null, item: null, status: `loading/${action.type}` };
    case ActionTypes.AMAZON_CREATE_LISTING_SUCCESS:
      return { ...state, status: `succeeded/${action.type}` };
    case ActionTypes.AMAZON_CREATE_LISTING_FAIL:
      return { ...state, error: action.payload.error, status: `failed/${action.type}` };

    // Get product by id from database
    case ActionTypes.GET_PRODUCT_BY_ID:
      return { ...state, error: null, product: null, status: `loading/${action.type}` };
    case ActionTypes.GET_PRODUCT_BY_ID_SUCCESS:
      return { ...state, product: action.payload.product, status: `succeeded/${action.type}` };
    case ActionTypes.GET_PRODUCT_BY_ID_FAIL:
      return { ...state, error: action.payload.error, status: `failed/${action.type}` };

    // reset state to initial state
    case ActionTypes.AMAZON_LISTING_RESET:
      return { ...state, error: null, item: null, status: 'idle', marketPlace: null };
    default:
      return state;
  }
}

export const selectAmazonItem = (state) => {
  const _item = state.amazonListings.item;
  if (_item && _item.summaries && _item.summaries.length > 0) {
    const summaries = _item.summaries[0];
    const mainImage = _item.images && _item.images.length > 0 ? _item.images[0]?.images[0] : null;
    const productType = _item.productTypes[0].productType;
    return {
      asin: _item.asin,
      title: summaries.itemName,
      displayName: summaries.browseClassification.displayName,
      brand: summaries.brand,
      image: mainImage?.link,
      productType: productType
    };
  }
};

export const selectAmazonListings = (state) => state.amazonListings;
