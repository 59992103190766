import React, { useMemo } from 'react';
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import { useSupplierUIContext } from '../../_context/SupplierUIContext';
import { FormattedMessage } from 'react-intl';
import { AddressTable } from './AddressTable';
import { Button } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';



export function AddressCard() {

  const UIContext = useSupplierUIContext();
  const UIProps = useMemo(() => ({
    openNewAddressDialog: UIContext.openNewAddressDialog,
    address: UIContext.address,
  }), [UIContext.openNewAddressDialog, UIContext.address]);


  return (
    <Card>

      <CardSubHeader icon={<Icon.GeoAlt />} title={<FormattedMessage id="GENERAL.ADDRESS" />}>
        {UIProps.address?.length < 5 && (
          <Button variant='outline-primary' size="sm" type="button" className='font-weight-bold svg-icon svg-icon-sm' onClick={() => UIProps.openNewAddressDialog()} id='btn_new_address'>
            <Icon.Plus />
            <span className="d-none d-sm-inline ml-2">
              <FormattedMessage id="ADDRESS.TITLE_NEW" />
            </span>
          </Button>
        )}
      </CardSubHeader>

      <CardBody className="pt-0 px-0">
        <AddressTable />
      </CardBody>

    </Card>
  );
}