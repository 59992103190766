export default class ActionTypes {
  static get PREFIX() {
    return '[auth]';
  }



  // Login actions types
  static get LOGIN() {
    return `${ActionTypes.PREFIX}LOGIN`;
  }
  static get LOGIN_SUCCESS() {
    return `${ActionTypes.PREFIX}LOGIN_SUCCESS`;
  }
  static get LOGIN_FAIL() {
    return `${ActionTypes.PREFIX}LOGIN_FAIL`;
  }


  // Get User actions types
  static get GET_USER_DATA() {
    return `${ActionTypes.PREFIX}GET_USER_DATA`;
  }
  static get GET_USER_DATA_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_USER_DATA_SUCCESS`;
  }
  static get GET_USER_DATA_FAIL() {
    return `${ActionTypes.PREFIX}GET_USER_DATA_FAIL`;
  }


  // Register actions types
  static get REGISTER() {
    return `${ActionTypes.PREFIX}REGISTER`;
  }
  static get REGISTER_SUCCESS() {
    return `${ActionTypes.PREFIX}REGISTER_SUCCESS`;
  }
  static get REGISTER_FAIL() {
    return `${ActionTypes.PREFIX}REGISTER_FAIL`;
  }


  // Email Verification actions types
  static get EMAIL_VERIFICATION() {
    return `${ActionTypes.PREFIX}EMAIL_VERIFICATION`;
  }
  static get EMAIL_VERIFICATION_SUCCESS() {
    return `${ActionTypes.PREFIX}EMAIL_VERIFICATION_SUCCESS`;
  }
  static get EMAIL_VERIFICATION_FAIL() {
    return `${ActionTypes.PREFIX}EMAIL_VERIFICATION_FAIL`;
  }


  // Ping register actions types
  static get PING_REGISTER() {
    return `${ActionTypes.PREFIX}PING_REGISTER`;
  }
  static get PING_REGISTER_SUCCESS() {
    return `${ActionTypes.PREFIX}PING_REGISTER_SUCCESS`;
  }
  static get PING_REGISTER_FAIL() {
    return `${ActionTypes.PREFIX}PING_REGISTER_FAIL`;
  }


  // Logout actions types
  static get LOGOUT() {
    return `${ActionTypes.PREFIX}LOGOUT`;
  }
  static get LOGOUT_SUCCESS() {
    return `${ActionTypes.PREFIX}LOGOUT_SUCCESS`;
  }
  static get LOGOUT_FAIL() {
    return `${ActionTypes.PREFIX}LOGOUT_FAIL`;
  }


  // Reset Password actions types
  static get RESET_PASSWORD() {
    return `${ActionTypes.PREFIX}RESET_PASSWORD`;
  }
  static get RESET_PASSWORD_SUCCESS() {
    return `${ActionTypes.PREFIX}RESET_PASSWORD_SUCCESS`;
  }
  static get RESET_PASSWORD_FAIL() {
    return `${ActionTypes.PREFIX}RESET_PASSWORD_FAIL`;
  }


  // ReCreate Password actions types
  static get RE_CREATE_PASSWORD() {
    return `${ActionTypes.PREFIX}RE_CREATE_PASSWORD`;
  }
  static get RE_CREATE_PASSWORD_SUCCESS() {
    return `${ActionTypes.PREFIX}RE_CREATE_PASSWORD_SUCCESS`;
  }
  static get RE_CREATE_PASSWORD_FAIL() {
    return `${ActionTypes.PREFIX}RE_CREATE_PASSWORD_FAIL`;
  }


  // Get User Data actions types
  static get RESTART_USER_SERVER() {
    return `${ActionTypes.PREFIX}RESTART_USER_SERVER`;
  }
  static get RESTART_USER_SERVER_SUCCESS() {
    return `${ActionTypes.PREFIX}RESTART_USER_SERVER_SUCCESS`;
  }
  static get RESTART_USER_SERVER_FAIL() {
    return `${ActionTypes.PREFIX}RESTART_USER_SERVER_FAIL`;
  }


  // Update user actions types
  static get UPDATE_USERS() {
    return `${ActionTypes.PREFIX}UPDATE_USERS`;
  }
  static get UPDATE_USERS_SUCCESS() {
    return `${ActionTypes.PREFIX}UPDATE_USERS_SUCCESS`;
  }
  static get UPDATE_USERS_FAIL() {
    return `${ActionTypes.PREFIX}UPDATE_USERS_FAIL`;
  }


  // Login Settings action types
  static get LOGIN_SETTINGS() {
    return `${ActionTypes.PREFIX}LOGIN_SETTINGS`;
  }
  static get LOGIN_SETTINGS_SUCCESS() {
    return `${ActionTypes.PREFIX}LOGIN_SETTINGS_SUCCESS`;
  }
  static get LOGIN_SETTINGS_FAIL() {
    return `${ActionTypes.PREFIX}LOGIN_SETTINGS_FAIL`;
  }


  // Change Password Settings action types
  static get CHANGE_PASSWORD() {
    return `${ActionTypes.PREFIX}CHANGE_PASSWORD`;
  }
  static get CHANGE_PASSWORD_SUCCESS() {
    return `${ActionTypes.PREFIX}CHANGE_PASSWORD_SUCCESS`;
  }
  static get CHANGE_PASSWORD_FAIL() {
    return `${ActionTypes.PREFIX}CHANGE_PASSWORD_FAIL`;
  }


  // Change Email Settings action types
  static get CHANGE_EMAIL() {
    return `${ActionTypes.PREFIX}CHANGE_EMAIL`;
  }
  static get CHANGE_EMAIL_SUCCESS() {
    return `${ActionTypes.PREFIX}CHANGE_EMAIL_SUCCESS`;
  }
  static get CHANGE_EMAIL_FAIL() {
    return `${ActionTypes.PREFIX}CHANGE_EMAIL_FAIL`;
  }


  // Contract actions types
  static get CONTRACT() {
    return `${ActionTypes.PREFIX}CONTRACT`;
  }
  static get CONTRACT_SUCCESS() {
    return `${ActionTypes.PREFIX}CONTRACT_SUCCESS`;
  }
  static get CONTRACT_FAIL() {
    return `${ActionTypes.PREFIX}CONTRACT_FAIL`;
  }


  // Deleted actions types
  static get DELETED_REQUEST() {
    return `${ActionTypes.PREFIX}DELETED_REQUEST`;
  }
  static get DELETED_REQUEST_SUCCESS() {
    return `${ActionTypes.PREFIX}DELETED_REQUEST_SUCCESS`;
  }
  static get DELETED_REQUEST_FAIL() {
    return `${ActionTypes.PREFIX}DELETED_REQUEST_FAIL`;
  }


  //Get counts types
  static get GET_COUNTS() {
    return `${ActionTypes.PREFIX}GET_COUNTS`;
  }
  static get GET_COUNTS_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_COUNTS_SUCCESS`;
  }
  static get DELETE_ACCOUNT_FAIL() {
    return `${ActionTypes.PREFIX}GET_COUNTS_FAIL`;
  }


  // Clean Up Reducer actions types
  static get CLEAN_UP_REDUCER() {
    return `${ActionTypes.PREFIX}CLEAN_UP_REDUCER`;
  }


  // Clear loading actions types
  static get CLEAR_LOADING_EFFECTS() {
    return `${ActionTypes.PREFIX}CLEAR_LOADING_EFFECTS`;
  }

}
