import { select } from "redux-saga/effects";



export class DocumentGenerator {
  /**
   **Access data
   * @returns { { accessToken:'', documentModel:''}} access data includes user server url, user accessToken and document model url
   */
  static getAccessDataFromStore = function* () {
    try {
      const { accessToken, documentModel } = yield select((state) => ({
        accessToken: state.auth.access_token,
        documentModel: state.documents.requestedDocument.type,
      }));
      return { accessToken, documentModel };
    } catch (error) {
      throw error;
    }
  };
}