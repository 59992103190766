import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDocumentsUIContext } from 'app/modules/documents/_context/DocumentsUIContext';
import { SHIPPER_TYPE_OPTIONS, getShipperOptions } from 'app/modules/documents/_context/DocumentsUIHelpers';
import { RadioControl, SVFormControl, SVReactSelect } from '_metronic/_partials/controls';
import { DocumentActions } from 'app/modules/documents/_redux/actions';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { maxValidation } from '_metronic/_helpers';



export function ShippingEditDialog() {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const document = useSelector(state => state.documents.documentForEdit);

  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showLabelEditDialog,
    onHide: UIContext.closeLabelEditDialog,
    editLabelParams: UIContext.editLabelParams,
    documentId: UIContext.documentId,
  }),
    [
      UIContext.showLabelEditDialog,
      UIContext.closeLabelEditDialog,
      UIContext.editLabelParams,
      UIContext.documentId,
    ]);


  const validationSchema = Yup.object().shape({
    service: Yup.string().required(intl({ id: 'GENERAL.REQUIRED' })),
    type: Yup.string().required(intl({ id: 'GENERAL.REQUIRED' })),
    tracking_id: Yup.string()
    .max(25, maxValidation("25")),
    price: Yup.number()
      .min(0, intl({ id: 'PRODUCT.GENERAL.PRICE_THAN' }))
      .max(999999, intl({ id: 'DOCUMENT.SHIPPING.MAX_PRICE' })),
    weight: Yup.number()
      .transform((originalValue, originalObject) => {
        const cleanedValue = originalValue ? originalValue.toString().replace(',', '.') : originalValue;
        return isNaN(cleanedValue) ? undefined : parseFloat(cleanedValue);
      })
      .required(intl({ id: 'GENERAL.REQUIRED' }))
      .min(0.001, intl({ id: 'DOCUMENT.SHIPPING.WEIGHT.LESS_THAN_NOT_0001' }))
      .max(1000, intl({ id: 'DOCUMENT.SHIPPING.WEIGHT.GREATER_THAN_NOT_1000' }))
      .test('no-trailing-zeros', intl({ id: 'DOCUMENT.SHIPPING.WEIGHT.TRAILING_ZEROS' }), function (value) {
        if (value) {
          const weightString = value.toString();
          return !weightString.includes('.') || !weightString.split('.')[1].endsWith('0');
        }
        return true;
      })
      .test('max-decimal-places', intl({ id: 'DOCUMENT.SHIPPING.WEIGHT.DECIMAL_PLACES' }), function (value) {
        if (value) {
          const decimalPlaces = (value.toString().split('.')[1] || '').length;
          return decimalPlaces <= 3;
        }
        return true;
      })
      .when(['service'], {
        is: (service) => (service === 'dhl_warenpost' || service === 'dhl_new_warenpost'),
        then: Yup.number()
          .typeError(intl({ id: 'DOCUMENT.SHIPPING.WEIGHT.MUST_A_NUMBER' }))
          .max(1, intl({ id: 'DOCUMENT.SHIPPING.WEIGHT.GREATER_THAN_1' })),
      })
  });


  const updateUnLabeled = async (values) => {

    const { relation_documents, ...rest } = values;

    if (values.documentId) {
      dispatch(DocumentActions.updateLabel(rest))
    } else {
      dispatch(DocumentActions.updateLabelSuccess(rest))
    }

    UIProps.onHide();
  };

  const INPUTS = [
    {
      name: 'service',
      type: 'text',
      component: SVReactSelect,
      options: getShipperOptions(),
      required: true,
    },
    {
      name: 'type',
      component: RadioControl,
      options: SHIPPER_TYPE_OPTIONS
    },
    {
      name: 'tracking_id',
      type: 'text',
      label: 'DOCUMENT.SHIPPING_TRACKING_ID'
    },
    {
      name: 'delivery_date',
      type: 'date',
      label: 'GENERAL.DELIVERY_DATE',
    },
    {
      name: 'weight',
      type: 'number',
      label: 'GENERAL.WEIGHT',
      required: true,
    },
    {
      name: 'price',
      type: 'number',
      label: 'DOCUMENT.SHIPPING_PRICE',
      disabled: document?.marketplace !== '',
    },
  ];


  return (
    <Modal show={UIProps.show} onHide={UIProps.onHide} size="md" backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>

      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id='GENERAL.SHIPPING' />
        </Modal.Title>
      </Modal.Header>

      <Formik enableReinitialize={true} initialValues={UIProps.editLabelParams} validationSchema={validationSchema} onSubmit={updateUnLabeled}>
        <Form autoComplete="off">

          <Modal.Body>
            <Row>
              {INPUTS.map((item, i) => (
                <Col key={i} lg="6">
                  <Field component={item.component ?? SVFormControl}
                    {...{
                      name: item.name,
                      type: item.type,
                      label: item.label ? intl({ id: item.label }) : null,
                      options: item.options,
                      withFeedbackLabel: true,
                      feedbackLabel: true,
                      required: item.required
                    }}
                  />
                </Col>
              ))}
            </Row>

          </Modal.Body>

          <Modal.Footer>

            <Button variant='light' onClick={UIProps.onHide} id="btn_cancel_modal">
              <FormattedMessage id="GENERAL.CANCEL" />
            </Button>

            <Button variant='primary' type="submit" className="ml-2" id="btn_save_modal">
              <FormattedMessage id="GENERAL.SAVE" />
            </Button>

          </Modal.Footer>

        </Form>
      </Formik>

    </Modal>
  );
}