import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCategoriesUIContext } from '../_context/CategoriesUIContext';
import { CategoryActions } from '../_redux/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { CancelButton, SaveButton } from '_metronic/_partials';
import { SVFormControl } from '_metronic/_partials/controls';
import { maxValidation } from '_metronic/_helpers';
import { Formik, Form, Field } from "formik";
import { Modal } from 'react-bootstrap';
import { isArray } from 'lodash';
import * as Yup from "yup";
import { toast } from 'react-toastify';



export function CategoryEdit({ id, show, onHide }) {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();
  // const inputRef = useRef(null);

  const UIContext = useCategoriesUIContext();
  const UIProps = useMemo(() => ({
    setIds: UIContext.setIds,
  }), [UIContext]);

  const [values, setValues] = useState({
    name: '',
    parent_id: '',
    level: '',
    path: '',
    is_leaf: false,
  });

  const categories = useSelector(state => state.categories.entities);

  useEffect(() => {
    if (!id) {
      return onHide();
    }
    // if (inputRef.current) {
    //   inputRef.current.focus();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const category = categories.find(cat => cat.id === id);
    if (category != null) {
      setValues(category);
    }
  }, [categories, id]);

  const handleSubmit = (inputValue) => {

    const value = inputValue.name;

    const parentName = categories.filter(x => x.id !== id).map(x => x.name);
    if (parentName.includes(value)) {
      toast.warning(intl({ id: 'CATEGORY.PARENT_NAME_EXISTS' }));
      return;
    };

    const pathArray = values.path.split(' > ');
    pathArray[pathArray.length - 1] = value;
    const newPath = isArray(pathArray) ? pathArray.join(' > ') : pathArray;

    dispatch(CategoryActions.updateCategory(id, { ...values, name: value, path: newPath }));
    UIProps.setIds([]);

    onHide();
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(80, maxValidation("80"))
      .required(intl({ id: 'GENERAL.REQUIRED' })),
  });

  return (
    <Modal show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg" backdrop="static" keyboard={false} centered>

      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id="CATEGORY.TITLE" />
        </Modal.Title>
      </Modal.Header>

      <Formik enableReinitialize={true} initialValues={id ? values : { name: '' }} validationSchema={validationSchema} onSubmit={handleSubmit}>
        <Form>

          <Modal.Body>
            <Field component={SVFormControl}
              {...{
                name: 'name',
                type: 'text',
                label: intl({ id: "CATEGORY.CATEGORY_NAME" }),
                withFeedbackLabel: true,
                feedbackLabel: true,
                required: true,
              }}
            />
          </Modal.Body>

          <Modal.Footer>
            <CancelButton onClick={onHide} id="modal" />
            <SaveButton id="modal" />
          </Modal.Footer>

        </Form>
      </Formik>
    </Modal>
  );
}
