// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Icon } from "_metronic/_icons";



export function ActionsColumnFormatter(cellContent, row, rowIndex, { openEditAddressDialog, openDeleteAddressDialog }) {
  return (
    <>
      <OverlayTrigger overlay={<Tooltip id="address-edit-tooltip"><FormattedMessage id="GENERAL.EDIT" /></Tooltip>}>
        <a className="btn btn-icon btn-hover-primary btn-sm" onClick={() => openEditAddressDialog(row.id)} id={`btn_edit_${row?.id}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.PencilSquare />
          </span>
        </a>
      </OverlayTrigger>

      <OverlayTrigger overlay={<Tooltip id="address-delete-tooltip"><FormattedMessage id="GENERAL.DELETE" /></Tooltip>}>
        <a className="btn btn-icon btn-hover-danger btn-sm" onClick={() => openDeleteAddressDialog(row.id)} id={`btn_delete_${row?.id}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.Trash />
          </span>
        </a>
      </OverlayTrigger>
    </>
  );
}
