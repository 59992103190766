import React from 'react';
import { FormattedMessage } from 'react-intl';
import ActionTypes from './actionTypes';
import { toast } from 'react-toastify';



export class ProductActions {

  // Create Product
  static createProduct = (product, upload, requestId) => {
    return {
      type: ActionTypes.CREATE_PRODUCT,
      payload: {
        product,
        upload,
        requestId
      },
    };
  };

  static createProductSuccess = () => {
    toast.success(<FormattedMessage id="PRODUCT.REDUX.CREATE_PRODUCT.SUCCESS" />);
    return {
      type: ActionTypes.CREATE_PRODUCT_SUCCESS,
    };
  };

  static createProductFail = error => {
    return {
      type: ActionTypes.CREATE_PRODUCT_FAIL,
      payload: { error },
    };
  };



  // Create Product Container
  static createProductContainer = (product, images) => {
    return {
      type: ActionTypes.CREATE_PRODUCT_CONTAINER,
      payload: {
        product: product,
        images: images,
      },
    };
  };

  static createProductContainerSuccess = () => {
    return {
      type: ActionTypes.CREATE_PRODUCT_CONTAINER_SUCCESS,
    };
  };

  static createProductContainerFail = () => {
    return {
      type: ActionTypes.CREATE_PRODUCT_CONTAINER_FAIL,
    };
  };



  // Image Upload
  static imageUpload = (container, dataForm) => {
    return {
      type: ActionTypes.IMAGE_UPLOAD,
      payload: {
        container: container,
        dataForm: dataForm,
      },
    };
  };

  static imageUploadSuccess = () => {
    return {
      type: ActionTypes.IMAGE_UPLOAD_SUCCESS,
    };
  };

  static imageUploadFail = () => {
    return {
      type: ActionTypes.IMAGE_UPLOAD_FAIL,
    };
  };



  // File Delete
  static deleteFile = (productId, fileType, fileId) => {
    return {
      type: ActionTypes.DELETE_FILE,
      payload: {
        productId,
        fileType,
        fileId
      },
    };
  };

  static deleteFileSuccess = (fileType, fileId) => {
    return {
      type: ActionTypes.DELETE_FILE_SUCCESS,
      payload: {
        fileType,
        fileId,
      },
    };
  };

  static deleteFileFail = () => {
    return {
      type: ActionTypes.DELETE_FILE_FAIL,
    };
  };



  // Get Products
  static getProducts = queryParams => {
    return {
      type: ActionTypes.GET_PRODUCTS,
      payload: { queryParams },
    };
  };

  static getProductsSuccess = (products, count) => {
    return {
      type: ActionTypes.GET_PRODUCTS_SUCCESS,
      payload: {
        products,
        count
      },
    };
  };

  static getProductsFail = () => {
    return {
      type: ActionTypes.GET_PRODUCTS_FAIL,
    };
  };



  // Get Product By Id
  static getProductById = (id, isCopy) => {
    return {
      type: ActionTypes.GET_PRODUCT_BY_ID,
      payload: {
        id,
        isCopy
      },
    };
  };

  static getProductByIdSuccess = (product, isCopy) => {
    return {
      type: ActionTypes.GET_PRODUCT_BY_ID_SUCCESS,
      payload: {
        product,
        isCopy
      },
    };
  };

  static getProductByIdFail = () => {
    return {
      type: ActionTypes.GET_PRODUCT_BY_ID_FAIL,
    };
  };



  // Find Product By Sku
  static findProductBySku = (value, productId, type) => {
    return {
      type: ActionTypes.FIND_PRODUCT_BY_SKU,
      payload: {
        value,
        productId,
        type
      },
    };
  };

  static findProductBySkuSuccess = (product, type) => {
    return {
      type: ActionTypes.FIND_PRODUCT_BY_SKU_SUCCESS,
      payload: {
        product: product,
        type
      },
    };
  };

  static findProductBySkuFail = () => {
    return {
      type: ActionTypes.FIND_PRODUCT_BY_SKU_FAIL,
    };
  };



  // Find Product By Property
  static findProductByProperty = (key, value) => {
    return {
      type: ActionTypes.FIND_PRODUCT_BY_PROPERTY,
      payload: {
        key,
        value,
      },
    };
  };

  static findProductByPropertySuccess = product => {
    return {
      type: ActionTypes.FIND_PRODUCT_BY_PROPERTY_SUCCESS,
      payload: {
        product: product,
      },
    };
  };

  static findProductByPropertyFail = () => {
    return {
      type: ActionTypes.FIND_PRODUCT_BY_PROPERTY_FAIL,
    };
  };



  // Find Product By Property Warning
  static findProductByPropertyWarning = (key, value) => {
    return {
      type: ActionTypes.FIND_PRODUCT_BY_PROPERTY_WARNING,
      payload: {
        key,
        value,
      },
    };
  };

  static findProductByPropertyBarcodeSuccess = product => {
    return {
      type: ActionTypes.FIND_PRODUCT_BY_PROPERTY_BARCODE_SUCCESS,
      payload: {
        product: product,
      },
    };
  };

  static findProductByPropertyEanSuccess = product => {
    return {
      type: ActionTypes.FIND_PRODUCT_BY_PROPERTY_EAN_SUCCESS,
      payload: {
        product: product,
      },
    };
  };

  static findProductByPropertyGtinSuccess = product => {
    return {
      type: ActionTypes.FIND_PRODUCT_BY_PROPERTY_GTIN_SUCCESS,
      payload: {
        product: product,
      },
    };
  };

  static findProductByPropertyIsbnSuccess = product => {
    return {
      type: ActionTypes.FIND_PRODUCT_BY_PROPERTY_ISBN_SUCCESS,
      payload: {
        product: product,
      },
    };
  };

  static findProductByPropertyAsinSuccess = product => {
    return {
      type: ActionTypes.FIND_PRODUCT_BY_PROPERTY_ASIN_SUCCESS,
      payload: {
        product: product,
      },
    };
  };

  static findProductByPropertyUpcSuccess = product => {
    return {
      type: ActionTypes.FIND_PRODUCT_BY_PROPERTY_UPC_SUCCESS,
      payload: {
        product: product,
      },
    };
  };

  static findProductByPropertySeriennummerSuccess = product => {
    return {
      type: ActionTypes.FIND_PRODUCT_BY_PROPERTY_SERIENNUMMER_SUCCESS,
      payload: {
        product: product,
      },
    };
  };

  static findProductByPropertyBarcodeFail = () => {
    return {
      type: ActionTypes.FIND_PRODUCT_BY_PROPERTY_BARCODE_FAIL,
    };
  };



  // Delete Product
  static deleteProduct = (id) => {
    return {
      type: ActionTypes.DELETE_PRODUCT,
      payload: {
        id
      },
    };
  };

  static deleteProductSuccess = (id) => {
    toast.success(<FormattedMessage id="PRODUCT.REDUX.DELETE_PRODUCT.SUCCESS" />);
    return {
      type: ActionTypes.DELETE_PRODUCT_SUCCESS,
      payload: {
        id
      },
    };
  };

  static deleteProductFail = () => {
    toast.error(<FormattedMessage id="PRODUCT.REDUX.DELETE_PRODUCT.FAIL" />);
    return {
      type: ActionTypes.DELETE_PRODUCT_FAIL,
    };
  };


  // Delete All Selected Products
  static deleteAllSelectedProducts = (selectedProductIds, queryParams) => {
    return {
      type: ActionTypes.DELETE_ALL_SELECTED_PRODUCTS,
      payload: {
        selectedProductIds,
        queryParams,
      },
    };
  };

  static deleteAllSelectedProductsSuccess = () => {
    return {
      type: ActionTypes.DELETE_ALL_SELECTED_PRODUCTS_SUCCESS,
    };
  };

  static deleteAllSelectedProductsFail = () => {
    return {
      type: ActionTypes.DELETE_ALL_SELECTED_PRODUCTS_FAIL,
    };
  };



  // Update Product
  static updateProduct = (productId, product, upload, requestId) => {
    return {
      type: ActionTypes.UPDATE_PRODUCT,
      payload: {
        productId,
        product,
        upload,
        requestId
      },
    };
  };

  static updateProductSuccess = () => {
    toast.success(<FormattedMessage id="PRODUCT.REDUX.UPDATE_PRODUCT.SUCCESS" />);
    return {
      type: ActionTypes.UPDATE_PRODUCT_SUCCESS,
    };
  };

  static updateProductFail = error => {
    return {
      type: ActionTypes.UPDATE_PRODUCT_FAIL,
      payload: {
        error,
      },
    };
  };



  // Update Product from field
  static updateProductFromTableField = (id, updatedFields, queryParams) => {
    return {
      type: ActionTypes.UPDATE_PRODUCT_FROM_TABLE_FIELD,
      payload: {
        id,
        updatedFields,
        queryParams,
      },
    };
  };

  static updateProductFromTableFieldSuccess = products => {
    return {
      type: ActionTypes.UPDATE_PRODUCT_FROM_TABLE_FIELD_SUCCESS,
      payload: {
        products,
      },
    };
  };

  static updateProductFromTableFieldFail = error => {
    return {
      type: ActionTypes.UPDATE_PRODUCT_FROM_TABLE_FIELD_FAIL,
      payload: { error },
    };
  };



  // Patch Product
  static patchProductByField = (id, dataObject) => {
    return {
      type: ActionTypes.PATCH_PRODUCT_BY_FIELD,
      payload: {
        id,
        dataObject,
      },
    };
  };

  static patchProductByFieldSuccess = () => {
    return {
      type: ActionTypes.PATCH_PRODUCT_BY_FIELD_SUCCESS,
    };
  };

  static patchProductByFieldFail = () => {
    return {
      type: ActionTypes.PATCH_PRODUCT_BY_FIELD_FAIL,
    };
  };



  // Get Supplier
  static getSupplier = () => {
    return {
      type: ActionTypes.GET_SUPPLIER,
    };
  };

  static getSupplierSuccess = (suppliers) => {
    return {
      type: ActionTypes.GET_SUPPLIER_SUCCESS,
      payload: {
        suppliers,
      },
    };
  };

  static getSupplierFail = (error) => {
    return {
      type: ActionTypes.GET_SUPPLIER_FAIL,
      payload: {
        error,
      },
    };
  };



  // Description Handler
  /* static shortDescriptionHandler = data => {
    return {
      type: ActionTypes.SHORT_DESCRIPTION_HANDLER,
      payload: {
        data: data,
      },
    };
  }; */

  static descriptionHandler = data => {
    return {
      type: ActionTypes.DESCRIPTION_HANDLER,
      payload: {
        data: data,
      },
    };
  };



  // Pictures Handler
  static picturesHandler = image => {
    return {
      type: ActionTypes.PICTURES_HANDLER,
      payload: {
        image: image,
      },
    };
  };



  // Update Stoks by Shops
  static updateAllSelectedProductsBeiShops = productData => {
    return {
      type: ActionTypes.UPDATE_STOCK_BY_SHOPS,
      payload: {
        productData,
      },
    };
  };

  static updateAllSelectedProductsBeiShopsSuccess = () => {
    return {
      type: ActionTypes.UPDATE_STOCK_BY_SHOPS_SUCCESS,
    };
  };

  static updateAllSelectedProductsBeiShopsFail = () => {
    return {
      type: ActionTypes.UPDATE_STOCK_BY_SHOPS_FAIL,
    };
  };



  /* New Variant actions -- start -- */
  static setVariants = variants => {
    return {
      type: ActionTypes.SET_VARIANTS,
      payload: {
        variants,
      },
    };
  };
  /* New Variant actions -- end -- */


  // Add Key Row
  static addProductKeys = (productId, keys) => {
    return {
      type: ActionTypes.ADD_PRODUCT_KEYS,
      payload: {
        productId,
        keys
      },
    };
  };

  static addProductKeysSuccess = (productId, keys) => {
    return {
      type: ActionTypes.ADD_PRODUCT_KEYS_SUCCESS,
      payload: {
        productId,
        keys
      },
    };
  };

  static addProductKeysFail = () => {
    return {
      type: ActionTypes.ADD_PRODUCT_KEYS_FAIL,
    };
  };


  // Delete Key Row
  static deleteProductKeys = (productId, keyIds) => {
    return {
      type: ActionTypes.DELETE_PRODUCT_KEYS,
      payload: {
        productId,
        keyIds
      },
    };
  };

  static deleteProductKeysSuccess = (res) => {
    res?.notDeletedKeys?.length > 0
      ? toast.error(<FormattedMessage id="PRODUCT.KEYS.TOAST.DELETE_SENT" values={{ keys: res.notDeletedKeys }} />)
      : toast.success(<FormattedMessage id="PRODUCT.KEYS.TOAST.DELETE_SUCCESS" />);

    return {
      type: ActionTypes.DELETE_PRODUCT_KEYS_SUCCESS,
      payload: {
        res
      },
    };
  };

  static deleteProductKeysFail = () => {
    return {
      type: ActionTypes.DELETE_PRODUCT_KEYS_FAIL,
    };
  };


  static importExample = (data) => {
    return {
      type: ActionTypes.IMPORT_EXAMPLE_PRODUCT_KEYS,
      payload: {
        data
      },
    };
  };

  static importExampleSuccess = () => {
    return {
      type: ActionTypes.IMPORT_EXAMPLE_PRODUCT_KEYS_SUCCESS,
    };
  };

  static importExampleFail = () => {
    return {
      type: ActionTypes.IMPORT_EXAMPLE_PRODUCT_KEYS_FAIL,
    };
  };

  // Pictures Handler
  static addProductGuides = guides => {
    return {
      type: ActionTypes.ADD_PRODUCT_GUIDES,
      payload: {
        guides,
      },
    };
  };

  // Features Handler
  static updateFeature = feature => {
    return {
      type: ActionTypes.UPDATE_FEATURE,
      payload: {
        feature
      },
    };
  };

  static deleteFeature = featureId => {
    return {
      type: ActionTypes.DELETE_FEATURE,
      payload: {
        featureId
      },
    };
  };

  // Reset Form Handler
  static resetFormHandler = () => {
    return {
      type: ActionTypes.RESET_FORM_HANDLER,
    };
  };



  // Clear Loading Handler
  static clearLoadingEffects = () => {
    return {
      type: ActionTypes.CLEAR_LOADING_EFFECTS,
    };
  };



  static clearRequest = () => {
    return {
      type: ActionTypes.CLEAR_REQUEST,
    };
  };
}
