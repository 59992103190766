import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useSettingsEditContext } from './_context/SettingsEditContext';
import { connectionForEdit } from './_context/SettingsUIHelpers';
import { ConnectionsActions } from './_redux/actions';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { useQuery } from 'app/hooks';



export default function QueryCapture() {

  const dispatch = useDispatch();
  const query = useQuery();
  const { hash, search } = useLocation();
  const history = useHistory();

  const UIContext = useSettingsEditContext();
  const UIProps = useMemo(() => ({
    close: UIContext.closeConnectionAddDialog,
    connectionName: UIContext.connection.connectionName,
    openConnectionAddDialog: UIContext.openConnectionAddDialog,
  }), [UIContext]);

  const { etsyConnection, success, amazonForEdit, [connectionForEdit(UIProps.connectionName)]: connection } = useSelector(state => state.connections);


  useEffect(() => {

    // Ottodan installation sonrası yönlendirmeden geldiyse auth token almaya gönder
    if(query.get('session_state') === "installation"){    
      const sandbox = query.get('iss')?.includes("sandbox") ? 'true' : 'false';
      const callType = "auth";
      const code = query.get('code');
      dispatch(ConnectionsActions.getOttoAuthToken(sandbox, callType, code));
    }

    // Ottodan auth sonrası yönlendirmeden geldiyse installation token alarak veritabanına bağlantı eklemeye gönder
    if (query.get('code') && query.get('session_state')) {
      // auth sonrası gelen iss = "https://sandbox.api.otto.market/sec-api/auth/realms/deepsea-sandbox"/ başka 
      // installation sonrası gelen iss = sandbox/ production      
      const code = query.get('code');
      const sandbox = query.get('iss').includes("sandbox") ? 'true' : 'false';
      dispatch(ConnectionsActions.setOttoAuthToken(code, sandbox));
    }

    //Ebay token capture
    if (hash && query.get('code')) {
      const code = `${query.get('code')}${hash}`;
      dispatch(ConnectionsActions.setEbayAuthToken({ code }));
    }

    //Amazon token capture
    if ((hash, query.get('MarketplaceID')) && query.get('AuthToken')) {
      const MarketplaceID = query.get("MarketplaceID");
      const MerchantToken = query.get("MerchantToken");
      const AuthToken = query.get("AuthToken");
      const market_name = amazonForEdit?.amazon?.market_name;
      const newValues = {
        name: 'amazon',
        token_type: 'marketplace',
        amazon: { MarketplaceID, MerchantToken, AuthToken, market_name, prime: false, invoice_upload: false, credit_upload: false },
      };

      dispatch(ConnectionsActions.createSettings(newValues));
    }

    //Etsy token capture
    if (query.get('code') && query.get('state')) {
      const code = query.get('code');
      dispatch(ConnectionsActions.setEtsyAuthToken({ code, code_verifier: etsyConnection.verifier }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, hash, query]);

  // connections başarılı ise settings sayfasına yönlendir
  useEffect(() => {
    if (success && connection?.id) {
      history.push(ModuleRoutes.CONNECTIONS_SETTINGS_FN(UIProps.connectionName, connection?.id));
      UIProps.close();
    }
    // eslint-disable-next-line
  }, [success])

  // ebay amazon etsy db ye yazılma sürecinde modal açıp loading sürecini göstermek için
  useEffect(() => {
    if (search) {
      if (search.includes('code=v')) {
        UIProps.openConnectionAddDialog('ebay', null)
      }
      if (search.includes('MarketplaceID') && search.includes('AuthToken')) {
        UIProps.openConnectionAddDialog('amazon', null);
      }
      if (search.includes('code') && search.includes('state') && !search.includes('session_state')) {
        UIProps.openConnectionAddDialog('etsy', null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, search])


  return (<></>)
}