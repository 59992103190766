export default class ActionTypes {
  static get PREFIX() {
    return '[dashboard]';
  }


  // Get dashboard action types
  static get GET() {
    return `${ActionTypes.PREFIX}GET`;
  }
  static get GET_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_SUCCESS`;
  }
  static get GET_FAIL() {
    return `${ActionTypes.PREFIX}GET_FAIL`;
  }

}
