/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown, Form } from 'react-bootstrap';
import { UIDates, theme } from '_metronic/_helpers';
import { Icon } from '_metronic/_icons';
import moment from 'moment';

/*
Geliştirici Kılavuzu:

1. Bu componenti içinde kullancağınız filter.js dosyasına aşağıdaki gibi ekleyin. 
Buraya ilgili context ve filtreleme sorgusu pas edilmeli.

  <DatePickerDropdown UIContext={UIContext} setFilterQuery={setFilterQuery} />

2. Aşağıdaki fonksiyonları ilgili context dosyasına dahil edin ve burada export edilen fonksiyonlara dahil edin.

  # Importlar Kısmına:
    import { useIntl } from 'react-intl';

  # Tanımlar Kısmına:
    const { formatMessage: intl } = useIntl();

  # Fonksiyonlar Kısmına:
    const initialDateRange = {
      id: "clear-date",
      label: intl({ id: "GENERAL.CLEAR" }),
    };

    const [dateRange, setDateRange] = useState(
      initialDateRange
    );
    const changeDateRange = (date = initialDateRange) => setDateRange(date);

  # Export edilen array içine:
    setDateRange,
    dateRange,
    changeDateRange,

3.  İlgili middleware.js dosyasını düzenleyin. 
    # date_from ve date_to you filtreden çıkartın:
        const {date_from, date_to, ...rest} = filter;

    # where altında filtreyi bu iki parametre olmadan işletin tarih filtresini ekleyin:
      ...rest,
      ...((date_from && date_to) && {
        created_at: { between: [`${date_from}T00:00:00.001Z`, `${date_to}T23:59:59.999Z`] }
      }),
*/

export function DatePickerDropdown({ UIContext, setFilterQuery }) {
  const { formatMessage: intl } = useIntl();

  const UIProps = useMemo(() => ({
    changeDateRange: UIContext.changeDateRange,
    dateRange: UIContext.dateRange,
    setDateRange: UIContext.setDateRange,
    initialDateRange: UIContext.initialDateRange,
  }), [UIContext]);

  const today = UIDates.getCurrentDate();

  const optionData = [
    {
      id: "todays",
      label: intl({ id: "DASHBOARD.DATE.TODAY" }),
      date_from: today,
      date_to: today,
    },
    {
      id: "last-7-days",
      label: intl({ id: "DASHBOARD.DATE.LAST_7_DAYS" }),
      date_from: UIDates.dayCalculation(-7),
      date_to: today,
    },
    {
      id: "last-14-days",
      label: intl({ id: "DASHBOARD.DATE.LAST_14_DAYS" }),
      date_from: UIDates.dayCalculation(-14),
      date_to: today,
    },
    {
      id: "last-30-days",
      label: intl({ id: "DASHBOARD.DATE.LAST_30_DAYS" }),
      date_from: UIDates.dayCalculation(-30),
      date_to: today,
    },
    {
      id: "last-90-days",
      label: intl({ id: "DASHBOARD.DATE.LAST_90_DAYS" }),
      date_from: UIDates.dayCalculation(-90),
      date_to: today,
    },
    {
      id: "this-month",
      label: intl({ id: "DASHBOARD.DATE.THIS_MONT" }),
      date_from: UIDates.getCurrentDate("YYYY-MM-01"),
      date_to: today,
    },
    {
      id: "last-month",
      label: intl({ id: "DASHBOARD.DATE.LAST_MONT" }),
      date_from: moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD"),
      date_to: moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD"),
    },
    {
      id: "this-year",
      label: intl({ id: "DASHBOARD.DATE.THIS_YEAR" }),
      date_from: UIDates.getCurrentDate("YYYY-01-01"),
      date_to: today,
    },
  ];

  const dateChanger = (e) => {
    e.preventDefault(); // Varsayılan davranışı engelle

    const selectedDate = optionData.find(item => item.id === e.target.id);

    if (e.target.id === 'custom') {
      UIProps.setDateRange({
        id: e.target.id,
        label: intl({ id: "GENERAL.CUSTOM_RANGE" }),
        date_from: moment().add(-30, "d").format("YYYY-MM-DD"),
        date_to: today,
      });
      setFilterQuery('DATE_RANGE', [moment().add(-30, "d").format("YYYY-MM-DD"), today]);
      return;
    }

    if (e.target.id === "clear-date") {
      UIProps.setDateRange({
        id: e.target.id,
        label: intl({ id: "GENERAL.CLEAR" }),
      });
      setFilterQuery('DELETE_PARAMS', ['date_from', 'date_to']);
      return;
    }

    UIProps.setDateRange({
      ...UIProps.dateRange,
      id: selectedDate.id,
      label: selectedDate.label,
      date_from: selectedDate.date_from,
      date_to: selectedDate.date_to,
    });

    setFilterQuery('DATE_RANGE', [selectedDate.date_from, selectedDate.date_to]);
  };

  const dateChangerCustom = (e) => {
    if (!UIProps.dateRange) return; // Hata kontrolü

    setFilterQuery(e.target.name, e.target.value);
    UIProps.setDateRange({
      ...UIProps.dateRange,
      label: intl({ id: "GENERAL.CUSTOM_RANGE" }),
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Dropdown drop="down" className='d-flex justify-content-end symbol mr-2 mb-2'>
      <Dropdown.Toggle variant='outline-secondary' className='d-flex align-items-center font-weight-bold svg-icon svg-icon-sm' style={{ height: theme.units.input.height['sm'] }} id="btn_date_picker">
        <Icon.Calendar className="mr-3" />
        {UIProps.dateRange?.id === 'clear-date' ? <FormattedMessage id='GENERAL.DATE_FILTER' /> : UIProps.dateRange?.label}
      </Dropdown.Toggle>

      {UIProps.dateRange?.date_from && <i className="symbol-badge bg-danger"></i>}

      <Dropdown.Menu className="dropdown-menu dropdown-menu-right dropdown-menu-anim-up" id='dropdown_date_picker'>
        <Form>
          <ul className="navi navi-hover">
            {optionData.map((item, i) => (
              <li className="navi-item" key={i}>
                <Dropdown.Item className='navi-link font-weight-bolder' id={item.id} onClick={dateChanger}>
                  {item.label}
                </Dropdown.Item>
              </li>
            ))}

            <li className="navi-item">
              <a className='dropdown-item navi-link font-weight-bolder' href="#" id="custom" onClick={dateChanger}>
                <FormattedMessage id='GENERAL.SET_CUSTOM_RANGE' />
              </a>

              {UIProps.dateRange.id === "custom" && <div className='m-3'>
                <div className='d-flex align-items-center flex-row flex-nowrap mb-3'>
                  <Form.Control
                    name="date_from"
                    type="date"
                    value={UIProps?.dateRange.date_from}
                    min={"1990-01-01"}
                    max={UIProps.dateRange.date_to || today}
                    onChange={dateChangerCustom}
                    disabled={UIProps.dateRange.id !== "custom"}
                  />
                  <b className='mx-2'><FormattedMessage id="GENERAL.TO" /></b>
                  <Form.Control
                    name="date_to"
                    type="date"
                    value={UIProps?.dateRange.date_to}
                    max={today}
                    min={UIProps?.dateRange.date_from}
                    onChange={dateChangerCustom}
                    disabled={UIProps.dateRange.id !== "custom"}
                  />
                </div>
              </div>}
            </li>

            <Dropdown.Divider />
            <li className="navi-item">
              <Dropdown.Item className='navi-link font-weight-bolder text-danger justify-content-center bg-white py-1' id='clear-date' onClick={dateChanger}>
                <FormattedMessage id='GENERAL.CLEAR' />
              </Dropdown.Item>
            </li>
          </ul>
        </Form>
      </Dropdown.Menu>
    </Dropdown>
  );
}