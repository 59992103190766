export default class ActionTypes {
  static get PREFIX() {
    return '[support]';
  }


  // Create Category action types
  static get CREATE_TICKET() {
    return `${ActionTypes.PREFIX}CREATE_TICKET`;
  }
  static get CREATE_TICKET_SUCCESS() {
    return `${ActionTypes.PREFIX}CREATE_TICKET_SUCCESS`;
  }
  static get CREATE_TICKET_FAIL() {
    return `${ActionTypes.PREFIX}CREATE_TICKET_FAIL`;
  }


  // Update Category action types
  static get UPDATE_TICKET() {
    return `${ActionTypes.PREFIX}UPDATE_TICKET`;
  }
  static get UPDATE_TICKET_SUCCESS() {
    return `${ActionTypes.PREFIX}UPDATE_TICKET_SUCCESS`;
  }
  static get UPDATE_TICKET_FAIL() {
    return `${ActionTypes.PREFIX}UPDATE_TICKET_FAIL`;
  }


  // Update Props Category action types
  static get TICKET_PROPS() {
    return `${ActionTypes.PREFIX}TICKET_PROPS`;
  }
  static get TICKET_PROPS_SUCCESS() {
    return `${ActionTypes.PREFIX}TICKET_PROPS_SUCCESS`;
  }
  static get TICKET_PROPS_FAIL() {
    return `${ActionTypes.PREFIX}TICKET_PROPS_FAIL`;
  }


  // Delete Category action types
  static get DELETE_TICKET() {
    return `${ActionTypes.PREFIX}DELETE_TICKET`;
  }
  static get DELETE_TICKET_SUCCESS() {
    return `${ActionTypes.PREFIX}DELETE_TICKET_SUCCESS`;
  }
  static get DELETE_TICKET_FAIL() {
    return `${ActionTypes.PREFIX}DELETE_TICKET_FAIL`;
  }


  // Get ticket action types
  static get GET_TICKETS() {
    return `${ActionTypes.PREFIX}GET_TICKETS`;
  }
  static get GET_TICKETS_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_TICKETS_SUCCESS`;
  }
  static get GET_TICKETS_FAIL() {
    return `${ActionTypes.PREFIX}GET_TICKETS_FAIL`;
  }


  // Get Counts action types
  static get COUNT_TICKETS() {
    return `${ActionTypes.PREFIX}COUNT_TICKETS`;
  }
  static get COUNT_TICKETS_SUCCESS() {
    return `${ActionTypes.PREFIX}COUNT_TICKETS_SUCCESS`;
  }
  static get COUNT_TICKETS_FAIL() {
    return `${ActionTypes.PREFIX}COUNT_TICKETS_FAIL`;
  }


  // Get Categories action types
  static get GET_TICKET_BY_ID() {
    return `${ActionTypes.PREFIX}GET_TICKET_BY_ID`;
  }
  static get GET_TICKET_BY_ID_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_TICKET_BY_ID_SUCCESS`;
  }
  static get GET_TICKET_BY_ID_FAIL() {
    return `${ActionTypes.PREFIX}GET_TICKET_BY_ID_FAIL`;
  }


  // {Pictures} Handler
  static get PICTURES_HANDLER() {
    return `${ActionTypes.PREFIX}PICTURES_HANDLER`;
  }


  // Clean up images
  static get CLEAN_UP_IMAGES() {
    return `${ActionTypes.PREFIX}CLEAN_UP_IMAGES`;
  }


  // Clean up reducer
  static get CLEAN_UP_REDUCER() {
    return `${ActionTypes.PREFIX}CLEAN_UP_REDUCER`;
  }


  // Image Delete action types
  static get DELETE_IMAGE() {
    return `${ActionTypes.PREFIX}DELETE_IMAGE`;
  }
  static get DELETE_IMAGE_SUCCESS() {
    return `${ActionTypes.PREFIX}DELETE_IMAGE_SUCCESS`;
  }
  static get DELETE_IMAGE_FAIL() {
    return `${ActionTypes.PREFIX}DELETE_IMAGE_FAIL`;
  }

}