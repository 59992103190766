export default class ActionTypes {
  static get PREFIX() {
    return '[company]';
  }



  // Complete user register actions types
  static get CREATE_COMPANY() {
    return `${ActionTypes.PREFIX}CREATE_COMPANY`;
  }
  static get CREATE_COMPANY_SUCCESS() {
    return `${ActionTypes.PREFIX}CREATE_COMPANY_SUCCESS`;
  }
  static get CREATE_COMPANY_FAIL() {
    return `${ActionTypes.PREFIX}CREATE_COMPANY_FAIL`;
  }

  static get COMPLETE_USER_REGISTER() {
    return `${ActionTypes.PREFIX}COMPLETE_USER_REGISTER`;
  }
  static get COMPLETE_USER_REGISTER_SUCCESS() {
    return `${ActionTypes.PREFIX}COMPLETE_USER_REGISTER_SUCCESS`;
  }
  static get COMPLETE_USER_REGISTER_FAIL() {
    return `${ActionTypes.PREFIX}COMPLETE_USER_REGISTER_FAIL`;
  }


  // Update company action types
  static get UPDATE_COMPANY_BASE_DATA() {
    return `${ActionTypes.PREFIX}UPDATE_COMPANY_BASE_DATA`;
  }
  static get UPDATE_COMPANY_BASE_DATA_SUCCESS() {
    return `${ActionTypes.PREFIX}UPDATE_COMPANY_BASE_DATA_SUCCESS`;
  }
  static get UPDATE_COMPANY_BASE_DATA_FAIL() {
    return `${ActionTypes.PREFIX}UPDATE_COMPANY_BASE_DATA_FAIL`;
  }


  // Delete company action types
  static get DELETE_COMPANY_BASE_DATA() {
    return `${ActionTypes.PREFIX}DELETE_COMPANY_BASE_DATA`;
  }
  static get DELETE_COMPANY_BASE_DATA_SUCCESS() {
    return `${ActionTypes.PREFIX}DELETE_COMPANY_BASE_DATA_SUCCESS`;
  }
  static get DELETE_COMPANY_BASE_DATA_FAIL() {
    return `${ActionTypes.PREFIX}DELETE_COMPANY_BASE_DATA_FAIL`;
  }


  // Get company action types
  static get GET_COMPANY_BASE_DATA() {
    return `${ActionTypes.PREFIX}GET_COMPANY_BASE_DATA`;
  }
  static get GET_COMPANY_BASE_DATA_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_COMPANY_BASE_DATA_SUCCESS`;
  }
  static get GET_COMPANY_BASE_DATA_FAIL() {
    return `${ActionTypes.PREFIX}GET_COMPANY_BASE_DATA_FAIL`;
  }


  // Logo handlers action types
  static get LOGO_ONCHANGE_HANDLER() {
    return `${ActionTypes.PREFIX}LOGO_ONCHANGE_HANDLER`;
  }


  // Clean handlers action types
  static get CLEAN_ERROR_HANDLER() {
    return `${ActionTypes.PREFIX}CLEAN_ERROR_HANDLER`;
  }


  // Clear loading action types
  static get CLEAR_LOADING_EFFECTS() {
    return `${ActionTypes.PREFIX}CLEAR_LOADING_EFFECTS`;
  }


  // Image Delete
  static get DELETE_FILE() {
    return `${ActionTypes.PREFIX}DELETE_FILE`;
  }
  static get DELETE_FILE_SUCCESS() {
    return `${ActionTypes.PREFIX}DELETE_FILE_SUCCESS`;
  }
  static get DELETE_FILE_FAIL() {
    return `${ActionTypes.PREFIX}DELETE_FILE_FAIL`;
  }

}