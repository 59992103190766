import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { Icon } from '_metronic/_icons';



export function Brand() {

  const UIService = useHtmlClassService();
  const layoutProps = useMemo(() => ({
    brandClasses: UIService.getClasses('brand', true),
    asideSelfMinimizeToggle: objectPath.get(UIService.config, 'aside.self.minimize.toggle'),
    headerLogo: UIService.getLogo(),
    headerStickyLogo: UIService.getStickyLogo(),
  }), [UIService]);


  return (
    <>
      {/* begin::Brand */}
      <div className={`brand flex-column-auto ${layoutProps.brandClasses}`} id="kt_brand">

        {/* begin::Logo */}
        <Link to="" className="brand-logo">
          <img style={{ width: '100%' }} alt="logo" src={layoutProps.headerLogo} />
        </Link>
        {/* end::Logo */}

        {layoutProps.asideSelfMinimizeToggle && (
          <>
            {/* begin::Toggle */}
            <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
              <span className="svg-icon svg-icon-lg text-white">
                <Icon.MenuDoubleLeft />
              </span>
            </button>
            {/* end::Toolbar */}
          </>
        )}
      </div>
      {/* end::Brand */}
    </>
  );
}
