import React from 'react';
import { FormattedMessage } from 'react-intl';



export const LICENSE_TYPE = [
  {
    value: 'trial',
    label: <FormattedMessage id="ADMIN.USER.LICENSE_TYPE.TRIAL" />,
    className: 'danger',
  },
  {
    value: 'premium',
    label: <FormattedMessage id="ADMIN.USER.LICENSE_TYPE.PREMIUM" />,
    className: 'primary',
  },
  {
    value: 'development',
    label: <FormattedMessage id="ADMIN.USER.LICENSE_TYPE.DEVELOPMENT" />,
    className: 'info',
  },
  {
    value: 'tester',
    label: <FormattedMessage id="ADMIN.USER.LICENSE_TYPE.TESTER" />,
    className: 'warning',
  },
  {
    value: 'test_user',
    label: <FormattedMessage id="ADMIN.USER.LICENSE_TYPE.TEST_USER" />,
    className: 'warning',
  },
  {
    value: 'standard',
    label: <FormattedMessage id="ADMIN.USER.LICENSE_TYPE.STANDARD" />,
    className: 'success',
  },

];

export const LICENSE_STATUS = [
  ...LICENSE_TYPE,
  {
    value: 'all_licenses',
    label: <FormattedMessage id="ADMIN.USER.LICENSE_TYPE.ALL_LICENSES" />,
    className: 'secondary',
  },
]

export const ACCOUNT_STATUS = [
  {
    value: 'active',
    label: <FormattedMessage id="ADMIN.USER.ACCOUNT_STATUS.ACTIVE" />,
    className: 'success',
  },
  {
    value: 'passive',
    label: <FormattedMessage id="ADMIN.USER.ACCOUNT_STATUS.INACTIVE" />,
    className: 'secondary',
  },
  {
    value: 'deleted',
    label: <FormattedMessage id="ADMIN.USER.ACCOUNT_STATUS.DELETED_REQUEST" />,
    className: 'danger',
  },
  {
    value: 'first_login',
    label: <FormattedMessage id="ADMIN.USER.ACCOUNT_STATUS.FIRST_LOGIN" />,
    className: 'warning',
  },
];

export const ACCOUNT_STATUS_FILTER = [
  ...ACCOUNT_STATUS,
  {
    value: 'all_statuses',
    label: <FormattedMessage id="ADMIN.USER.ACCOUNT_STATUS.ALL" />,
    className: 'secondary',
  },
]

export const NEWSLETTER_STATUS = [
  {
    value: true,
    label: <FormattedMessage id="ADMIN.TEMP_USER.SUBSCRIBED" />
  },
  {
    value: false,
    label: <FormattedMessage id="ADMIN.TEMP_USER.NOT_SUBSCRIBED" />
  },
];
