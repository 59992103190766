import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CkEditorFormControl, SVFormControl } from '_metronic/_partials/controls';
import { Button, Modal } from 'react-bootstrap';
import { SupportActions } from '../_redux/actions';
import { CloseButton } from '_metronic/_partials';
import { FormattedMessage, useIntl } from 'react-intl';
import { ImageUpload } from '../support-edit/ImageUpload';
import { initialValues } from '../_context/SupportUIHelper';
import { DirtyDialog } from '_metronic/_partials/dialogs';
import { SanitizeHtml, maxValidation } from '_metronic/_helpers';
import { Field, Formik, Form } from 'formik';
import { Icon } from '_metronic/_icons';
import * as Yup from 'yup';
import { useSupportUIContext } from '../_context/SupportUIContext';



export function TicketEdit({ id, show, onHide }) {

  const UIContext = useSupportUIContext();
  const UIProps = useMemo(() => ({
    sessionId: UIContext.sessionId,
  }), [UIContext]);

  const [existingImages, setExistingImages] = useState([]);

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();

  const { ticket, ticketImages, loading, success, sessionReply, session } = useSelector(state => {
    const session = state.supports.ticket?.session || [];
    const sessionIndex = session.findIndex((session) => session.id === UIProps?.sessionId);
    const sessionReply = session[sessionIndex]?.message;
    return {
      ticket: state.supports.ticket,
      ticketImages: state.supports.ticketImages,
      loading: state.supports.loading,
      success: state.supports.success,
      sessionReply,
      session
    };
  });

  const updateTicket = (values) => {

    const { message } = values;
    const form_data = new FormData();
    let ticketIsExisted = false;

    if (ticketImages.length) {
      ticketIsExisted = true;
      ticketImages.forEach(image => form_data.append('ticket_images', image.file));
    }

    const upload = { is: ticketIsExisted, file: form_data };

    if (id) {
      const data = { message, images: ticketIsExisted };
      dispatch(SupportActions.updateTicket(id, data, upload, UIProps?.sessionId ? UIProps?.sessionId : 'new'));
    } else {
      const data = { ...values, images: ticketIsExisted };
      dispatch(SupportActions.createTicket(data, upload));
    }

    UIContext.setSessionId(null);
    setExistingImages([]);
  };


  const closeModalAction = () => {
    id
      ? dispatch(SupportActions.cleanUpImages())
      : dispatch(SupportActions.cleanUpReducer())

    UIContext.setSessionId(null);
    setExistingImages([]);
    onHide();
  };

  // Edit reply butonuna basıldığında o sessionda bulunan fotoyu göstererek açılması için
  useEffect(() => {
    const sessionIndex = session?.findIndex(session => session.id === UIProps?.sessionId);
    if (session[sessionIndex] && session[sessionIndex]?.images?.length !== -1) {
      setExistingImages(session[sessionIndex]?.images ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, session, UIProps?.sessionId])


  useEffect(() => {
    return () => {
      id ? dispatch(SupportActions.cleanUpImages()) : dispatch(SupportActions.cleanUpReducer());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handlePaste = (e) => {
    if (e.clipboardData.files.length) {
      const fileObject = e.clipboardData.files[0];
      const fileReader = new FileReader();

      fileReader.onload = (event) => {
        const base64Data = event.target.result;
        const data = {
          thumbnail: base64Data,
          file: fileObject,
        };
        dispatch(SupportActions.picturesHandler([...ticketImages, data]));
      };
      fileReader.readAsDataURL(fileObject);
    }
  };

  const validationSchema = Yup.object().shape({
    subject: Yup.string()
      .when('$id', {
        is: () => !id,
        then: Yup.string()
          .required(intl({ id: 'SUPPORT.TICKET.SUBJECT_REQUIRED' }))
          .max(80, maxValidation("80")),
        otherwise: Yup.string().nullable()
      }),
    message: Yup.string()
      .max(5000, maxValidation("5000"))
      .required(intl({ id: 'SUPPORT.TICKET.DESCRIPTION_REQUIRED' })),
  });


  return (
    <Modal size="lg" show={show} onHide={onHide} backdrop="static" aria-labelledby="example-modal-sizes-title-lg" centered>

      <Formik enableReinitialize={true} initialValues={{ ...initialValues, message: sessionReply || "" }} validationSchema={validationSchema} onSubmit={updateTicket}>
        {({ setFieldValue, values, dirty }) => (
          <Form autoComplete="off">
            <DirtyDialog success={success} onClose={closeModalAction} />
            <Modal.Header>
              <Modal.Title>
                <Icon.PencilSquare className="mr-3" />
                <FormattedMessage id={!id ? "SUPPORT.NEW_TICKET" : 'GENERAL.SUBJECT'} /> {id && `: "${ticket?.subject}"`}
              </Modal.Title>
              <CloseButton onClick={() => { !dirty && ticketImages.length >= 1 ? closeModalAction() : onHide(); }} />
            </Modal.Header>

            <Modal.Body onPaste={handlePaste}>

              {!id && (
                <Field component={SVFormControl}
                  {...{
                    name: "subject",
                    label: intl({ id: "GENERAL.SUBJECT" }),
                    withFeedbackLabel: true,
                    feedbackLabel: values.subject?.length
                      ? <span className={`${values.subject && values.subject?.length >= 80 ? 'text-danger' : values.subject && values.subject?.length >= (80 * 80 / 100) ? 'text-warning' : 'text-success'}`}>
                        <FormattedMessage id="SETTINGS.DOCUMENT.REMAINING_CHARACTER" values={{ character: 80 - values.subject?.length }} />
                      </span>
                      : true,
                    required: true,
                    disabled: loading,
                  }}
                />
              )}

              <Field component={CkEditorFormControl}
                {...{
                  name: "message",
                  data: UIProps?.sessionId ? sessionReply : "",
                  label: intl({ id: "GENERAL.DESCRIPTION" }),
                  onChange: (e, editor) => { setFieldValue("message", SanitizeHtml(editor.getData())); },
                  withFeedbackLabel: true,
                  feedbackLabel: values.message?.length
                    ? <span className={`${values.message && values.message?.length >= 5000 ? 'text-danger' : values.message && values.message?.length >= (5000 * 80 / 100) ? 'text-warning' : 'text-success'}`}>
                      <FormattedMessage id="SETTINGS.DOCUMENT.REMAINING_CHARACTER" values={{ character: 5000 - values.message?.length }} />
                    </span>
                    : true,
                  required: true,
                  disabled: loading,
                }}
              />

              <ImageUpload existingImages={existingImages} ticketImages={ticketImages} sessionId={UIProps?.sessionId} ticketId={id} />

            </Modal.Body>

            <Modal.Footer>

              {id && <span className='font-weight-bold mr-auto'>
                <FormattedMessage id='SUPPORT.TICKET_NO' values={{ ticketNo: ticket?.number }} />
              </span>}

              <Button variant='light' onClick={() => { !dirty && ticketImages.length >= 1 ? closeModalAction() : onHide(); }} id="btn_cancel_modal">
                <FormattedMessage id="GENERAL.CANCEL" />
              </Button>

              <Button variant='primary' type="submit" className="ml-2" id='btn_send_modal'>
                <FormattedMessage id="GENERAL.SEND" />
              </Button>

            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
