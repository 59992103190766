import React, { useEffect, useState } from 'react';
import { SearchControl } from '_metronic/_partials/controls';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { Col, Dropdown, Form, Row } from 'react-bootstrap';
import { ORDER_STATUS } from '../_context/DocumentsUIHelpers';
import { CONNECTION_SETTINGS } from 'constants/connectionSettings';
import * as UIHelpers from '../_context/DocumentsUIHelpers';
import { theme, UIDates } from '_metronic/_helpers';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { Icon } from '_metronic/_icons';
import { useQuery } from 'app/hooks';
import moment from 'moment';
import clsx from 'clsx';



export function DocumentsFilter({ queryParams, setSearchQuery, setFilterQuery, requestedDocument, connectionEntities }) {

  const query = useQuery();
  const { formatMessage: intl } = useIntl();

  const [search, setSearchBase] = useState(query.get('search') ?? '');
  const [filterParamsBase, setFilterParamsBase] = useState({
    no_invoice: null,
    open_invoice: null,
    credit_created: false,
    non_labeled: false,
    date_filter: false,
    date_from: null,
    date_to: null,
    status: "open",
    marketplace: null,
  });


  useEffect(() => {

    setSearchBase(query.get('search') ?? '');
    const date_from = query.get('filter.date_from');
    const date_to = query.get('filter.date_to');
    const no_invoice = query.get('filter.no_invoice');
    const open_invoice = query.get('filter.open_invoice');
    const credit_created = query.get('filter.credit_created');
    const non_labeled = query.get('filter.non_labeled');
    const status = query.get('filter.status');
    const marketplace = query.get('filter.marketplace');

    setFilterParamsBase(prev => ({
      ...prev,
      date_filter: date_from || date_to ? true : false,
      date_from: date_from ? date_from : null,
      date_to: date_to ? date_to : null,
      no_invoice: no_invoice ? true : false,
      open_invoice: open_invoice ? true : false,
      credit_created: credit_created ? true : false,
      non_labeled: non_labeled ? true : false,
      status: ORDER_STATUS.map(item => item.value).includes(status) ? status : "open",
      marketplace: marketplace
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])


  const handleSearch = (value) => {
    setSearchQuery(value);
    setSearchBase(value);
  };

  const onFilterHandler = e => {
    const { name, checked } = e.target;
    setFilterParamsBase(prev => ({
      ...prev,
      [name]: checked,
    }));
    setFilterQuery(name, checked);
  };

  const onFilterStatusHandler = (value) => {
    setFilterQuery('status', value);
    setFilterParamsBase(prev => ({
      ...prev,
      status: value,
    }));
  };

  const onFilterDateHandler = e => {
    const { name, value } = e.target;
    setFilterParamsBase(prev => ({
      ...prev,
      [name]: value,
    }));
    setFilterQuery(name, value, requestedDocument.type);
  };


  const onDateSwitcherHandler = (value) => {

    if (!value) {
      setFilterParamsBase(prev => ({
        ...prev,
        date_filter: false,
        date_from: null,
        date_to: null,
      }));
      setFilterQuery('DELETE_PARAMS', ['date_from', 'date_to', 'doc_type']);
    }

    if (value) {
      setFilterParamsBase(prev => ({
        ...prev,
        date_filter: true,
        date_from: moment().add(-7, 'days').format('YYYY-MM-DD'),
        date_to: moment().format('YYYY-MM-DD'),
      }));
      setFilterQuery('DATE_FILTER', ['date_from', 'date_to'], requestedDocument.type);
    }

  };


  const onDeleteFilterHandler = (key, value) => {
    if (key === 'date_from' || key === 'date_to') {
      return onDateSwitcherHandler(false);
    }
    setFilterParamsBase(prev => ({
      ...prev,
      [key]: false,
    }));
    setFilterQuery('DELETE_PARAMS', key);
  };

  const onFilterConnectionHandler = (value) => {
    setFilterQuery('marketplace', value)
    setFilterParamsBase(prev => ({
      ...prev,
      marketplace: value
    }))
  }

  const getFilterParams = [
    {
      value: "no_invoice",
      label: intl({ id: "DOCUMENT.FILTER.NO_INVOICE" })
    },
    {
      value: "open_invoice",
      label: intl({ id: "DOCUMENT.FILTER.UNPAID" }, { docType: requestedDocument.title })
    },
    {
      value: "credit_created",
      label: intl({ id: "DOCUMENT.FILTER.CANCELLED" })
    },
    {
      value: "non_labeled",
      label: intl({ id: "DOCUMENT.FILTER.UNLABELED" })
    },
  ];

  const sortedTokens = CONNECTION_SETTINGS
    .filter(token => token.active && !(token.type === "shipper"))
    .sort((a, b) => {
      const typeOrder = { marketplace: 0, shop: 1, shipper: 2 };
      const typeComparison = typeOrder[a.type] - typeOrder[b.type];
      if (typeComparison === 0) {
        return a.name.localeCompare(b.name);
      }
      return typeComparison;
    });

  const connections = connectionEntities.filter(connection => connection.token_type !== 'shipper')
  // Amazon Prime connection added
  connections.push({
    name: "amazon_prime",
    label: "Amazon Prime",
    icon: <Icon.AmazonPrime />,
  });

  const filteredConnections = sortedTokens.filter(obj => connections.some(connection => connection.name === obj.name));

  // Marketplace none filter
  filteredConnections.push({
    name: 'none',
    label: <FormattedMessage id="GENERAL.FILTER.NONE_MARKET" />,
    icon: <Icon.ShopWindow />
  })

  const selectMarketplace = filteredConnections.find(i => i.name === filterParamsBase.marketplace);


  return (
    <Row className='mb-3'>

      <Col sm="12" md="6" lg="4" className="mb-lg-0 mb-2">
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col>

      <Col sm="12" md="6" lg="8" className='d-flex align-items-start justify-content-start px-md-0'>


        {/* -------------------------------------- Order status filter start ------------------------------------ */}

        {requestedDocument.type === 'orders' &&
          <Dropdown className="dropdown-inline symbol mr-3" drop="down">

            <Dropdown.Toggle variant="outline-secondary" className="d-flex align-items-center svg-icon svg-icon-sm font-weight-bold" style={{ height: theme.units.input.height['sm'] }} id="dropdown-basic">
              <span className={clsx("label label-xl label-dot mr-3", `label-${ORDER_STATUS.find(item => item.value === filterParamsBase.status)?.className}`)}></span>
              {ORDER_STATUS.find(i => i.value === filterParamsBase.status)?.label}
            </Dropdown.Toggle>

            {filterParamsBase.status !== "open" && <i className="symbol-badge bg-danger"></i>}
            <Dropdown.Menu className='dropdown-menu-right dropdown-menu-anim-down' id='dropdown_document_grouping'>
              <ul className="navi navi-hover">
                {ORDER_STATUS.map((filter, index) => (
                  <li className="navi-item" key={index}>
                    {filter.value === 'all-orders' && <Dropdown.Divider />}
                    <Dropdown.Item className='navi-link font-weight-bold' onClick={() => onFilterStatusHandler(filter.value)} id={`dropdown_item_${filter.value}`}>
                      <span className={clsx("label label-xl label-dot mr-1", `label-${filter.className}`)}></span>
                      <span className="navi-text text-nowrap">{filter.long_label}</span>
                    </Dropdown.Item>
                  </li>
                ))}
              </ul>
            </Dropdown.Menu>

          </Dropdown>}

        {/* --------------------------------------- Order status filter end ------------------------------------- */}



        {/* -------------------------------------- Order general filter start ------------------------------------ */}

        <Dropdown className="dropdown-inline symbol mr-3" drop="down">

          <Dropdown.Toggle variant='outline-secondary' className="svg-icon svg-icon-sm font-weight-bold" style={{ height: theme.units.input.height['sm'] }} id="dropdown-basic">
            <Icon.Sliders className="mr-3" />
            <FormattedMessage id='DOCUMENT.FILTER.CONTENT' />
          </Dropdown.Toggle>

          {Object.keys(filterParamsBase)?.some(key => filterParamsBase[key] === true) && <i className="symbol-badge bg-danger"></i>}
          <Dropdown.Menu className="dropdown-menu-right dropdown-menu-anim-down" style={{ minWidth: '180px' }} id="dropdown_document_filter">
            <ul className="navi navi-hover font-weight-bold">

              {(['invoices', 'credits', 'orders'].includes(requestedDocument.type)) &&
                <li className="navi-item">
                  <label className="navi-link checkbox checkbox-md checkbox-outline checkbox-primary">
                    <input type="checkbox" name="open_invoice" checked={filterParamsBase['open_invoice']} onChange={onFilterHandler} id="checkbox_open_invoice" />
                    <span />
                    <div className="text-nowrap">
                      <FormattedMessage id="DOCUMENT.FILTER.OPEN_PAYMENTS" />
                    </div>
                  </label>
                </li>
              }

              {requestedDocument.type === 'orders' &&
                <li className="navi-item">
                  <label className="navi-link checkbox checkbox-md checkbox-outline checkbox-primary">
                    <input type="checkbox" name="no_invoice" checked={filterParamsBase['no_invoice']} onChange={onFilterHandler} id="checkbox_no_invoice" />
                    <span />
                    <div className="text-nowrap">
                      <FormattedMessage id="DOCUMENT.FILTER.NO_INVOICE" />
                    </div>
                  </label>
                </li>
              }

              {requestedDocument.type === 'invoices' &&
                <li className="navi-item">
                  <label className="navi-link checkbox checkbox-md checkbox-outline checkbox-primary">
                    <input type="checkbox" name="credit_created" checked={filterParamsBase['credit_created']} onChange={onFilterHandler} />
                    <span />
                    <div className="text-nowrap">
                      <FormattedMessage id="DOCUMENT.FILTER.CANCELLED" />
                    </div>
                  </label>
                </li>
              }

              {(['invoices', 'offers', 'orders'].includes(requestedDocument.type)) &&
                <li className="navi-item">
                  <label className="navi-link checkbox checkbox-md checkbox-outline checkbox-primary">
                    <input type="checkbox" name="non_labeled" checked={filterParamsBase['non_labeled']} onChange={onFilterHandler} id="checkbox_none_labeled" />
                    <span />
                    <div className="text-nowrap">
                      <FormattedMessage id="DOCUMENT.FILTER.WITHOUT_SHIPPING_LABEL" />
                    </div>
                  </label>
                </li>
              }

              <li className="navi-item">
                <label className="navi-link checkbox checkbox-md checkbox-outline checkbox-primary">
                  <input type="checkbox" name="date_switcher" checked={filterParamsBase.date_filter}
                    onChange={() => onDateSwitcherHandler(!filterParamsBase.date_filter)}
                    id="checkbox_date_switcher"
                  />
                  <span />
                  <div className="text-nowrap">
                    <FormattedMessage id="DOCUMENT.FILTER.LIMIT_DATE" />
                  </div>
                </label>
              </li>

              {filterParamsBase.date_filter && (
                <>
                  <Dropdown.Divider />
                  <li className="navi-item py-2 px-4">
                    <Form.Group as={Row} controlId="btn_date_from" className="mb-3">
                      <Form.Label column sm="3" className="font-weight-bold pr-0">
                        <FormattedMessage id="GENERAL.FROM" />
                      </Form.Label>
                      <Col sm="9" className="pl-0">
                        <Form.Control name="date_from" type="date" onChange={onFilterDateHandler} value={filterParamsBase['date_from']} />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="btn_date_to" className="mb-0">
                      <Form.Label column sm="3" className="font-weight-bold pr-0">
                        <FormattedMessage id="GENERAL.TO" />
                      </Form.Label>
                      <Col sm="9" className="pl-0">
                        <Form.Control name="date_to" type="date" min={filterParamsBase['date_from']} max={moment().format('YYYY-MM-DD')} onChange={onFilterDateHandler} value={filterParamsBase['date_to']} />
                      </Col>
                    </Form.Group>
                  </li>
                </>
              )}

              <Dropdown.Divider />
              <li className="navi-item">
                <Dropdown.Item className='navi-link font-weight-bolder text-danger justify-content-center bg-white py-1' id='clear' onClick={() => setFilterQuery('DELETE_PARAMS', UIHelpers.allFilterKeys)}>
                  <FormattedMessage id='GENERAL.CLEAR' />
                </Dropdown.Item>
              </li>

            </ul>
          </Dropdown.Menu>
        </Dropdown>

        {/* -------------------------------------- Order general filter end ------------------------------------- */}


        {/* --------------------------------------- Market filter started --------------------------------------- */}

        {(['orders', 'invoices', 'credits', 'waybills',].includes(requestedDocument.type)) &&
          <Dropdown className="dropdown-inline symbol" drop="down">

            <Dropdown.Toggle variant="outline-secondary" className='d-flex justify-content-between align-items-center font-weight-bold' style={{ height: theme.units.input.height['sm'] }} id="dropdown-basic">
              <span className="svg-icon svg-icon-sm mr-3">
                {selectMarketplace?.icon ?? <Icon.Shop />}
              </span>
              {selectMarketplace?.label ?? <FormattedMessage id="DOCUMENT.FILTER.MARKET" />}
            </Dropdown.Toggle>

            {filterParamsBase.marketplace && <i className="symbol-badge bg-danger"></i>}
            <Dropdown.Menu className="dropdown-menu-right dropdown-menu-anim-down" id="dropdown_market_options">
              <ul className="navi navi-hover">
                {filteredConnections.length
                  ? <>
                    {filteredConnections?.map((opt, index) => (
                      <li className="navi-item" key={index}>
                        <Dropdown.Item className="navi-link" onClick={() => onFilterConnectionHandler(opt.name)} id={`dropdown_item_${opt.name}`}>
                          <span className="svg-icon svg-icon-md mr-2">
                            {opt.icon}
                          </span>
                          <span className="navi-text text-nowrap font-weight-bold">
                            {opt.label}
                          </span>
                        </Dropdown.Item>
                      </li>
                    ))}

                    <Dropdown.Divider />

                    <li className="navi-item">
                      <Dropdown.Item
                        className='navi-link font-weight-bolder text-danger justify-content-center bg-white py-1'
                        onClick={() => onDeleteFilterHandler('marketplace', CONNECTION_SETTINGS.find(i => i.name === filterParamsBase.marketplace)?.name)}
                        id='clear-marketplace'
                      >
                        <FormattedMessage id='GENERAL.CLEAR' />
                      </Dropdown.Item>
                    </li>
                  </>
                  : <li className="navi-item px-3">
                    <span className="svg-icon svg-icon-sm text-info text-hover-primary mr-2">
                      <Icon.InfoCircle />
                    </span>
                    <span className="navi-text">
                      <FormattedHTMLMessage id="DOCUMENT.SHIPPING.NO_MARKETPLACE_FILTER" values={{ url: ModuleRoutes.CONNECTIONS, a: chunks => <a href='/'>{chunks}</a> }} />
                    </span>
                  </li>
                }
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        }

        {/* --------------------------------------- Market filter ended --------------------------------------- */}


        {/* --------------------------------------- Filter tags started --------------------------------------- */}

        <div className="d-flex align-content-center align-items-center flex-wrap">
          {queryParams?.filter && queryParams.filter.constructor === Object &&
            Object.entries(queryParams.filter).map(([key, value]) => {
              if (!value || key === "date_to") return null;
              if (['doc_type', 'status', 'marketplace'].includes(key)) return null;
              return (
                <label type="button" key={'filter_' + key} className="label label-inline label-light-primary label-xl text-nowrap m-2" onClick={() => onDeleteFilterHandler(key, value)} id="btn_close">
                  <span>
                    {key === "date_from"
                      ? `${UIDates.formatDate(queryParams.filter?.date_from)} - ${UIDates.formatDate(queryParams.filter?.date_to)}`
                      : getFilterParams.find(item => item.value === key)?.label}
                  </span>
                  <Icon.Close className="icon-sm ml-3" />
                </label>
              );
            })}
        </div>

        {/* --------------------------------------- Filter tags ended --------------------------------------- */}

      </Col>
    </Row>
  );
}
