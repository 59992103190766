import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useProductEditUIContext } from "../../../_context/ProductEditUIContext";
import { ProductActions } from "app/modules/products/_redux/actions";
import { FeatureEditForm } from "./FeatureEditForm";
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon } from "_metronic/_icons";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { maxValidation } from "_metronic/_helpers";



export function FeatureEditDialog() {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const UIContext = useProductEditUIContext();
  const UIProps = useMemo(() => ({
    id: UIContext.featureId,
    show: UIContext.showFeatureDialog,
    onHide: UIContext.closeEditFeatureDialog,
  }), [UIContext]);

  const saveFeature = (feature) => {
    dispatch(ProductActions.updateFeature(feature));
    UIProps.onHide();
  };

  const features = useSelector(state => state.products.features);

  const checkFeatureByValue = (value) => {
    return features.some(feature => feature.name.toLowerCase() === value.toLowerCase() && feature.id !== UIProps?.id);
  }

  const currentFeature = features?.find(feature => feature.id === UIProps?.id);
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(intl({ id: "GENERAL.REQUIRED" }))
      .max(50, maxValidation('50'))
      .test('is-unique-name', intl({ id: "PRODUCT.GENERAL.FEATURE_NAME_WARNING" }),
        function (value) { return !checkFeatureByValue(value, UIProps.id)}
      ),
    value: Yup.string()
      .required(intl({ id: "GENERAL.REQUIRED" }))
      .max(100, maxValidation("100"))
  })

  return (
    <Modal show={UIProps.show} onHide={UIProps.onHide} aria-labelledby="example-modal-sizes-title-lg" centered backdrop="static" keyboard={false}>
      <Formik enableReinitialize={true} initialValues={UIProps?.id ? currentFeature : { name: "", value: "" }} validationSchema={validationSchema} onSubmit={saveFeature}>
        <Form>
          <Modal.Header>
            <Modal.Title>
              <Icon.Tags className="mr-3" />
              <FormattedMessage id={UIProps?.id ? "PRODUCT.FEATURES.UPDATE" : "PRODUCT.FEATURES.ADD"} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="overlay overlay-block cursor-default">

            <FeatureEditForm checkFeatureByValue={checkFeatureByValue} id={UIProps.id} />
          </Modal.Body>

          <Modal.Footer>
            <Button variant='light' onClick={UIProps.onHide} id="btn_cancel_modal">
              <FormattedMessage id="GENERAL.CANCEL" />
            </Button>

            <Button variant='primary' type="submit" className="ml-2" id="btn_add_modal">
              <FormattedMessage id={currentFeature?.id ? "GENERAL.UPDATE" : "GENERAL.CREATE"} />
            </Button>
          </Modal.Footer>
        </Form>
      </Formik>
    </Modal>
  );
}