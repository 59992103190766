import React from 'react';
import styled from 'styled-components';
import { theme } from '_metronic/_helpers';



export const SVFormControlLabel = (props) => (
  <StyledLabel {...props}>{props.children}</StyledLabel>
);

export const SVFeedbackLabel = (props) => (
  <StyledFeedbackLabel {...props}>{props.children}</StyledFeedbackLabel>
);

export const SVFeedbackLabelBottom = (props) => (
  <StyledFeedbackLabelBottom {...props}>{props.children}</StyledFeedbackLabelBottom>
);

export const SVFeedbackUnlabeled = (props) => (
  <StyledFeedbackUnlabeled {...props}>{props.children}</StyledFeedbackUnlabeled>
);

export const SVFormControlWrapper = ({ className, children }) => (
  <StyledFormControlWrapper className={className}>{children}</StyledFormControlWrapper>
);

const StyledLabel = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.375rem;
  color: ${p => p.errors ? theme.palette.danger['color'] : theme.palette.gray['700']};
  font-weight: ${theme.typography.fontWeight.medium};
  // font-size: ${p => theme.typography.fontSize[p.size]};
  // line-height: ${p => theme.typography.lineHeight[p.size]};
`;

const StyledFeedbackLabel = styled.div`
  display: ${p => (p.withFeedbackLabel ? 'block' : 'none')};
  color: ${p => p.errors ? theme.palette.danger['color'] : theme.palette.gray['100']};
  text-align: right;
  position: absolute;
  top: ${p => (p.size === "sm" ? p.label ? '5rem' : '3rem' : p.label ? "5.2rem" : "3.2rem")};
  right: 0;
  // font-size: ${p => theme.typography.fontSize['sm']};
  // line-height: ${p => theme.typography.lineHeight['sm']};
`;

const StyledFeedbackLabelBottom = styled.div`
  display: ${p => (p.withFeedbackLabel ? 'block' : 'none')};
  color: ${p => p.errors ? theme.palette.danger['color'] : theme.palette.gray['100']};
  text-align: right;
  position: absolute;
  bottom: -1.6rem;
  right: 0;
  // font-size: ${p => theme.typography.fontSize['sm']};
  // line-height: ${p => theme.typography.lineHeight['sm']};
`;

const StyledFeedbackUnlabeled = styled.div`
  display: ${p => (p.withFeedbackLabel ? 'block' : 'none')};
  color: ${p => p.errors ? theme.palette.danger['color'] : theme.palette.gray['100']};
  text-align: right;
  position: absolute;
  bottom: -1.8rem;
  right: 0;
`;

const StyledFormControlWrapper = styled.div`
  margin-bottom: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
`;
