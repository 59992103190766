/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { DropdownTopbarItemToggler } from '_metronic/_partials/dropdowns';
import { Icon } from '_metronic/_icons';



export default function UserInfoDropdown({ title, description, size }) {
  return (
    <Dropdown drop="up">

      <Dropdown.Toggle as={DropdownTopbarItemToggler}>
        <div type="button" className="d-flex svg-icon svg-icon-sm text-info text-hover-primary ml-2" id="info_toggle">
          <Icon.InfoCircle />
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu dropdown-menu-left dropdown-menu-anim-up dropdown-menu-lg p-5 m-0" id='dropdown_user_info'>
        <div className="d-flex flex-column font-weight-bold">
          {title &&
            <h6 className="text-dark font-size-lg mb-1">
              {title}
            </h6>
          }
          <span className="text-muted">
            {description}
          </span>
        </div>
      </Dropdown.Menu>

    </Dropdown>
  );
}
