import { API } from 'constants/apiUrl';



export const categoryMiddleware = {

  getCategories: async function (accessToken) {
    return await API.get(`/categories`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getCount: async function (accessToken) {
    return await API.get(`/categories/count`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  createCategory: async function (accessToken, category) {
    return await API.post(`/categories`, category, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  putCategory: async function (accessToken, id, category) {
    return await API.put(`/categories/${id}`, category, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  deleteCategory: async function (accessToken, id) {
    return await API.delete(`/categories/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

};
