import React from 'react'
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import { getCountryList } from "_metronic/_helpers";
import { FormattedMessage } from 'react-intl';
import { useLang } from '_metronic/i18n';
import { Icon } from '_metronic/_icons';



export default function Address({ companyInfo }) {

  const locale = useLang();
  const { company_name, owner, email, phone, mobile, address, web } = companyInfo || {};

  const ADDRESS_INFO = [
    {
      label: "GENERAL.OWNER",
      content: owner,
    },
    {
      label: "GENERAL.COMPANY",
      content: company_name,
    },
    {
      label: "GENERAL.EMAIL",
      content: <a href={`mailto:${email}`} className="text-decoration-none text-dark text-hover-primary">{email}</a>,
    },
    (phone && {
      label: "GENERAL.PHONE",
      content: <a href={`tel:${phone}`} className="text-decoration-none text-dark text-hover-primary">{phone}</a>,
    }),
    (mobile && {
      label: "GENERAL.MOBILE",
      content: <a href={`tel:${mobile}`} className="text-decoration-none text-dark text-hover-primary">{mobile}</a>,
    }),
    (web && {
      label: "GENERAL.WEB",
      content: <a href={web.trim().replace(/^(https?:\/\/)?(www\.)?/i, "https://").replace(/\/$/, "")} target="_blank" rel="noopener noreferrer" className="text-decoration-none text-dark text-hover-primary">{web}</a>,
    }),
    {
      label: "GENERAL.ADDRESS",
      content:
        address && <div>
          <div className='mb-3'>{address?.address_line1} {address?.address_line2}</div>
          <div>{address?.post_code} {address?.city} / {getCountryList(locale).find(code => code.value === address?.country_code)?.label}</div>
        </div>
    },
  ].filter(Boolean);


  return (
    <Card className="card-stretch gutter-b">

      <CardSubHeader icon={<Icon.House />} title={<FormattedMessage id="GENERAL.COMPANY" />} />

      <CardBody>
        {ADDRESS_INFO?.length !== 0
          ? <div className="py-2 px-4 d-flex flex-column">
            {ADDRESS_INFO?.map((item, i) => (
              <h6 className={`d-flex align-items-start flex-column flex-md-row ${ADDRESS_INFO.length - 1 === i ? "" : "mb-4"}`} key={i}>
                <span className="text-dark-50 mw-200px mb-3">
                  {item.label && <FormattedMessage id={item.label} />}
                </span>
                <span>
                  {item.content}
                </span>
              </h6>
            ))}
          </div>
          : null}
      </CardBody>

    </Card>
  );
}
