import React, { useEffect, useState, createContext, useContext } from 'react';
import { isArray, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';



const SupplierUIContext = createContext();
export const SupplierUIConsumer = SupplierUIContext.Consumer;
export const useSupplierUIContext = () => useContext(SupplierUIContext)



export function SupplierUIProvider({ children }) {

  const { supplierForEdit } = useSelector(state => ({
    supplierForEdit: state.suppliers.supplierForEdit,
  }));

  const INITIAL_ADDRESS = {
    type: 'address_delivery',
    salutation: '',
    name: supplierForEdit.name,
    company: supplierForEdit.company,
    address_line1: '',
    address_line2: '',
    post_code: '',
    city: '',
    country_code: 'DE',
  };

  const INITIAL_BANK = {
    name: '',
    iban: '',
    bic: '',
  };


  // ------------------------------ Address ------------------------------ //

  // Address
  const [address, setAddress] = useState([]);
  const [addressForEdit, setAddressForEdit] = useState(null);

  useEffect(() => {
    if (!isEmpty(supplierForEdit) && isArray(supplierForEdit.address) && supplierForEdit.address.length > 0) {
      return setAddress(supplierForEdit.address);
    }
    return setAddress([]);
  }, [supplierForEdit]);

  const [ids, setIds] = useState([]);
  const [selectedId, setSelectedId] = useState(null);


  useEffect(() => {
    return () => setIds([]);;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // Address Edit Dialog
  const [showEditAddressDialog, setShowEditAddressDialog] = useState(false);
  const openNewAddressDialog = () => {
    setSelectedId(undefined);
    setShowEditAddressDialog(true);
  };
  const openEditAddressDialog = id => {
    setSelectedId(id);
    const findAddress = address.find(adr => adr.id === id);
    setAddressForEdit(findAddress);
    setShowEditAddressDialog(true);
  };
  const closeEditAddressDialog = () => {
    setSelectedId(undefined);
    setShowEditAddressDialog(false);
    setAddressForEdit(null);
  };


  // Address Delete Dialog
  const [showDeleteAddressDialog, setShowDeleteAddressDialog] = useState(false);
  const openDeleteAddressDialog = id => {
    setSelectedId(id);
    setShowDeleteAddressDialog(true);
  };
  const closeDeleteAddressDialog = () => {
    setSelectedId(undefined);
    setShowDeleteAddressDialog(false);
  };
  const deleteAddressHandler = id => {
    setAddress(prev => prev.filter(p => p.id !== id));
  };
  const createAddressHandler = address => {
    setAddress(prev => [...prev, address]);
    setAddressForEdit(null);
  };
  const updateAddressHandler = address => {
    setAddress(prev => {
      const currentAddress = isArray(prev) ? [...prev] : [];
      const updatedAddressIndex = currentAddress.findIndex(p => p.id === address.id);
      currentAddress[updatedAddressIndex] = address;
      return currentAddress;
    });
    setAddressForEdit(null);
  };



  //--------------------------------- Bank ---------------------------------//

  // Bank
  const [selectedBankId, setSelectedBankId] = useState(null);
  const [bank, setBank] = useState([]);
  const [bankForEdit, setBankForEdit] = useState(null);

  useEffect(() => {
    if (!isEmpty(supplierForEdit) && isArray(supplierForEdit.bank) && supplierForEdit.bank.length > 0) {
      const initialBankEdit = supplierForEdit.bank;
      return setBank(initialBankEdit);
    }
    return setBank([]);
  }, [supplierForEdit]);


  //Bank Edit Dialog
  const [showEditBankDialog, setShowEditBankDialog] = useState(false);
  const openNewBankDialog = () => {
    setSelectedBankId(undefined);
    setShowEditBankDialog(true);
  };
  const openEditBankDialog = id => {
    setSelectedBankId(id);
    const findBank = bank.find(bnk => bnk.id === id);
    setBankForEdit(findBank);
    setShowEditBankDialog(true);
  };
  const closeEditBankDialog = () => {
    setSelectedBankId(undefined);
    setShowEditBankDialog(false);
    setBankForEdit(null);
  };


  //Bank Delete Dialog
  const [showDeleteBankDialog, setShowDeleteBankDialog] = useState(false);
  const openDeleteBankDialog = id => {
    setSelectedBankId(id);
    setShowDeleteBankDialog(true);
  };
  const closeDeleteBankDialog = () => {
    setSelectedBankId(undefined);
    setShowDeleteBankDialog(false);
  };
  const deleteBankHandler = id => {
    setBank(prev => prev.filter(p => p.id !== id));
  };
  const createBankHandler = address => {
    setBank(prev => [...prev, address]);
    setBankForEdit(null);
  };
  const updateBankHandler = bank => {
    setBank(prev => {
      const currentBank = prev;
      const updatedBankIndex = currentBank.findIndex(p => p.id === bank.id);
      currentBank[updatedBankIndex] = bank;
      return currentBank;
    });
    setBankForEdit(null);
  };


  // Server Respond Dialog
  const [showServerRespondDialog, setShowServerRespondDialog] = useState(false);
  const handleCloseServerRespondDialog = () => {
    setShowServerRespondDialog(false)
  };
  const handleOpenServerRespondDialog = () => {
    setShowServerRespondDialog(true)
  };


  const value = {
    selectedId,
    INITIAL_ADDRESS,
    address,
    addressForEdit,
    setAddress,

    openNewAddressDialog,
    showEditAddressDialog,
    openEditAddressDialog,
    closeEditAddressDialog,

    showDeleteAddressDialog,
    openDeleteAddressDialog,
    closeDeleteAddressDialog,

    createAddressHandler,
    updateAddressHandler,
    deleteAddressHandler,

    selectedBankId,
    INITIAL_BANK,
    bank,
    bankForEdit,

    openNewBankDialog,
    showEditBankDialog,
    openEditBankDialog,
    closeEditBankDialog,

    showDeleteBankDialog,
    openDeleteBankDialog,
    closeDeleteBankDialog,

    createBankHandler,
    updateBankHandler,
    deleteBankHandler,

    ids,
    setIds,
    showServerRespondDialog,
    handleCloseServerRespondDialog,
    handleOpenServerRespondDialog,
  };

  return (
    <SupplierUIContext.Provider value={value}>
      {children}
    </SupplierUIContext.Provider>
  );
}
