import React from 'react';
import { streetRegExp, plzRegExp, phoneRegExp, bicRegExp, vatRegExp, taxRegExp, maxValidation, minValidation, emailRegExp, ibanRegExp } from '_metronic/_helpers';
import { Formik, Form } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';



export function FormikHandler({ company, saveCompany, children, companyBtnRef }) {

  const { formatMessage: intl } = useIntl()

  // const typeValidation = <FormattedMessage id="ADDRESS.PLZ_TYP" />

  const productValidationSchema = Yup.object().shape({
    company_name: Yup.string()
      .max(100, maxValidation("100"))
      .trim(intl({ id: 'AUTH.GENERAL.BLANK_CHARACTER' }))
      .required(intl({ id: "GENERAL.COMPANY_NAME_REQUIRED" })),
    owner: Yup.string()
      .max(50, maxValidation("50"))
      .trim(intl({ id: 'AUTH.GENERAL.BLANK_CHARACTER' }))
      .required(intl({ id: "GENERAL.OWNER_REQUIRED" })),
    address: Yup.object().shape({
      address_line1: Yup.string()
        .max(150, maxValidation("150"))
        .required(intl({ id: "ADDRESS.STREET_REQUIRED" }))
        .when('country_code', {
          is: 'DE',  // Sadece country_code 'DE' olduğunda matches kontrolü yap
          then: schema => schema.matches(streetRegExp, intl({ id: "ADDRESS.STREET_MATCHES" })),
          otherwise: schema => schema,  // Diğer durumlarda matches kontrolü olmadan devam et
        }),
      post_code: Yup.string()
        .max(10, intl({ id: "ADDRESS.MAX_CHAR" }, { name: "10" }))
        .when('country_code', {
          is: 'DE', // only apply the matches validation when country_code is 'DE'
          then: Yup.string().matches(plzRegExp, intl({ id: "ADDRESS.PLZ_MATCHES" }))
        })
        .required(intl({ id: "ADDRESS.POSTCODE_REQUIRED" }))
        .typeError(intl({ id: "ADDRESS.PLZ_TYP" })),
      city: Yup.string()
        .max(50, intl({ id: "ADDRESS.MAX_CHAR" }, { name: "50" }))
        .trim(intl({ id: 'AUTH.GENERAL.BLANK_CHARACTER' }))
        .required(intl({ id: "ADDRESS.LOCATION_REQUIRED" })),
      country_code: Yup.string()
        .required(intl({ id: "ADDRESS.COUNTRY_REQUIRED" })),
    }),
    phone: Yup.string()
      .required(intl({ id: "GENERAL.PHONE_REQUIRED" }))
      .max(25, maxValidation("25"))
      .matches(phoneRegExp, intl({ id: 'GENERAL.TEL_MATCHES' }))
      .min(7, minValidation("7")),
    mobile: Yup.string()
      .max(25, maxValidation("25"))
      .matches(phoneRegExp, intl({ id: 'GENERAL.TEL_MATCHES' }))
      .min(7, minValidation("7")),
    fax: Yup.string()
      .max(25, maxValidation("25"))
      .matches(phoneRegExp, intl({ id: 'GENERAL.TEL_MATCHES' }))
      .min(7, minValidation("7")),
    email: Yup.string()
      .max(50, maxValidation("50"))
      .matches(emailRegExp, intl({ id: 'GENERAL.EMAIL_EMAIL' }))
      .email(intl({ id: "GENERAL.EMAIL_EMAIL" }))
      .required(intl({ id: "GENERAL.EMAIL_REQUIRED" })),
    web: Yup.string()
      .max(100, maxValidation("100")),
    vat_id: Yup.string()
      .max(15, maxValidation("15"))
      .matches(vatRegExp, intl({ id: 'GENERAL.VAT_ID_MATCHES' })),
    tax_number: Yup.string()
      .max(15, maxValidation("15"))
      .matches(taxRegExp, intl({ id: 'GENERAL.NUMBER_MATCHES' })),
    bank: Yup.object().shape({
      name: Yup.string()
        .max(100, maxValidation("100")),
      iban: Yup.string()
        .max(50, maxValidation("50"))
        .matches(ibanRegExp, intl({ id: 'BANK.IBAN_MATCHES' })),
      bic: Yup.string()
        .max(50, maxValidation("50"))
        .matches(bicRegExp, intl({ id: 'BANK.BIC_MATCHES' })),
    }),
  });


  return (
    <Formik enableReinitialize={true} initialValues={company} validationSchema={productValidationSchema} onSubmit={saveCompany}>
      <Form autoComplete="off">
        {children}
        <button type="submit" className='d-none' ref={companyBtnRef} />
      </Form>
    </Formik>
  );
}
