import React from 'react'
import { Carousel, Modal } from 'react-bootstrap';
import { CloseButton } from '_metronic/_partials';
import { BASE_URL } from 'constants/apiUrl';
import { Portal } from 'react-portal';



export default function PicturesModal({ show, onHide, imageIndex, images }) {
  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={show} onHide={onHide} size="lg" className="bg-transparent" aria-labelledby="contained-modal-title-vcenter" centered>

        <CloseButton className="modal-close-btn text-white text-hover-primary icon-xl" onClick={onHide} />

        <Carousel defaultActiveIndex={imageIndex} interval={null}>
          {images?.map((item, index) => (
            <Carousel.Item key={index} className='rounded-lg overflow-hidden'>
              <img
                className="d-block w-100"
                src={item.file ? `${item.thumbnail}` : `${BASE_URL}/${item.url}`}
                alt={item.file ? item.file.name : item.name}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </Modal>
    </Portal>
  );
}