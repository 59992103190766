import React from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'



export default function CancelButton({ onClick, id }) {
  return (
    <Button variant='light' className="font-weight-bold mr-2" onClick={onClick} id={`btn_cancel${id ? "_" + id : ""}`}>
      <FormattedMessage id="GENERAL.CANCEL" />
    </Button>
  )
}