import React from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export default function MailButton({ onClick, disabled, id }) {
  return (
    <OverlayTrigger overlay={<Tooltip id="document-print-tooltip"><FormattedMessage id="GENERAL.EMAIL" /></Tooltip>}>
      <Button variant='icon' size='sm' className="btn-hover-danger svg-icon svg-icon-md text-muted mr-3" onClick={onClick} disabled={disabled} id={`btn_delete_modal_${id}`}>
        <Icon.EnvelopeAt />
      </Button>
    </OverlayTrigger>
  )
}