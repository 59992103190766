import React from 'react';
import { EbaySyncAlertDialog } from './dialogs/EbaySyncAlertDialog';
import { EbayListingsCard } from './EbayListingsCard';



export function EbayListingsPage() {
  return (
    <>
      <EbaySyncAlertDialog />
      <EbayListingsCard />
    </>
  );
}
