import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Importer, ImporterField, deDE, enUS, trTR } from 'react-csv-importer';
import { importExportActions } from '../../_redux/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { useHistory } from 'react-router-dom';
import { useLang } from '_metronic/i18n';
import { toast } from 'react-toastify';
import { utils } from '_metronic/_helpers';



export function ImportCustomer() {

  const locale = useLang();
  const history = useHistory();
  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();


  const langList = {
    "de": deDE,
    "en": enUS,
    "tr": trTR
  }
  
  const { error, loading, importPreparation } = useSelector(state => ({
    loading: state.importExport.loading,
    error: state.importExport.error,
    importPreparation: state.importExport.importPreparation,
  }), shallowEqual);

  const [customersToSave, setCustomersToSave] = useState([]);

  const processChunk = async (rows) => {
    const customers = rows
      .filter(row => row.name !== "NAME *" && row.email !== "EMAIL *")
      .map(row => ({ ...row, id: utils.uuidv4() }));
    setCustomersToSave((prevCustomers) => [...prevCustomers, ...customers]);
  };


  useEffect(() => {
    if (error) {
      toast.error(<FormattedMessage id={error} />);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  useEffect(() => {
    return () => {
      dispatch(importExportActions.cleanUpReducer());
    };
  }, [dispatch]);


  return (
    <Importer
      onComplete={({ file, preview, fields, columnFields }) => {

        if (!file.name.toLowerCase().endsWith('.csv')) {
          toast.error(<FormattedMessage id="IMPORT_EXPORT.INVALID_FILE" />);
          return;
        }

        if (file.size > 250000) {
          toast.warning(<FormattedMessage id="IMPORT_EXPORT.INVALID_LENGTH_SIZE" />);
          return;
        }

        if (customersToSave.length > 500) {
          toast.warning(<FormattedMessage id="IMPORT_EXPORT.INVALID_LENGTH_MAX" />);
          return;
        }

        if (customersToSave.length === 0) {
          toast.warning(<FormattedMessage id="IMPORT_EXPORT.INVALID_LENGTH_MIN" />)
          return;
        }

        dispatch(importExportActions.importPreparation(customersToSave, "customers"))

      }}

      locale={langList[locale]}
      assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
      restartable={false} // optional, lets user choose to upload another file when import is complete
      onStart={({ file, preview, fields, columnFields }) => { }}
      processChunk={processChunk}
      onClose={({ file, preview, fields, columnFields }) => {
        importPreparation.length > 0 && history.push(ModuleRoutes.IMPORT_CUSTOMER);
      }}
      onPageChange={() => { }}
    // CSV options passed directly to PapaParse if specified:
    // delimiter={...}
    // newline={...}
    // quoteChar={...}
    // escapeChar={...}
    // comments={...}
    // skipEmptyLines={...}
    // delimitersToGuess={...}
    // chunkSize={...} // defaults to 10000
    // encoding={...} // defaults to utf-8, see FileReader API
    >

      <ImporterField name="type" label={intl({ id: 'IMPORT.CSV.CUSTOMER.CUSTOMER_TYPE' })} optional />
      <ImporterField name="name" label={intl({ id: 'IMPORT.CSV.CUSTOMER.CUSTOMER_NAME' })} />
      <ImporterField name="company" label={intl({ id: 'IMPORT.CSV.CUSTOMER.CUSTOMER_COMPANY' })} optional />
      <ImporterField name="email" label={intl({ id: 'IMPORT.CSV.CUSTOMER.CUSTOMER_EMAIL' })} />
      <ImporterField name="phone" label={intl({ id: 'IMPORT.CSV.CUSTOMER.CUSTOMER_PHONE' })} optional />
      <ImporterField name="mobile" label={intl({ id: 'IMPORT.CSV.CUSTOMER.CUSTOMER_MOBILE' })} optional />
      <ImporterField name="tax_number" label={intl({ id: 'IMPORT.CSV.CUSTOMER.CUSTOMER_TAX_NUMBER' })} optional />
      <ImporterField name="vat_id" label={intl({ id: 'IMPORT.CSV.CUSTOMER.CUSTOMER_VAT_ID' })} optional />
      <ImporterField name="fax" label="FAX" optional />
      <ImporterField name="bank" label={intl({ id: 'IMPORT.CSV.CUSTOMER.CUSTOMER_BANK' })} optional />
      <ImporterField name="iban" label="IBAN" optional />
      <ImporterField name="bic" label="BIC" optional />

      {/* Delivery Address */}
      <ImporterField name="address_customer_name_1" label={intl({ id: 'IMPORT.CSV.CUSTOMER.DELIVERY_CUSTOMER_NAME' })} optional />
      <ImporterField name="address_company_name_1" label={intl({ id: 'IMPORT.CSV.CUSTOMER.DELIVERY_COMPANY_NAME' })} optional />
      <ImporterField name="address_line1_1" label={intl({ id: 'IMPORT.CSV.CUSTOMER.DELIVERY_LINE1' })} />
      <ImporterField name="address_line2_1" label={intl({ id: 'IMPORT.CSV.CUSTOMER.DELIVERY_LINE2' })} optional />
      <ImporterField name="post_code_1" label={intl({ id: 'IMPORT.CSV.CUSTOMER.DELIVERY_POST_CODE' })} />
      <ImporterField name="city_1" label={intl({ id: 'IMPORT.CSV.CUSTOMER.DELIVERY_CITY' })} />
      <ImporterField name="country_code_1" label={intl({ id: 'IMPORT.CSV.CUSTOMER.DELIVERY_COUNTRY_CODE' })} />


      {/* Invoice Address */}
      <ImporterField name="address_customer_name_2" label={intl({ id: 'IMPORT.CSV.CUSTOMER.INVOICE_CUSTOMER_NAME' })} optional />
      <ImporterField name="address_company_name_2" label={intl({ id: 'IMPORT.CSV.CUSTOMER.INVOICE_COMPANY_NAME' })} optional />
      <ImporterField name="address_line1_2" label={intl({ id: 'IMPORT.CSV.CUSTOMER.INVOICE_LINE1' })} optional />
      <ImporterField name="address_line2_2" label={intl({ id: 'IMPORT.CSV.CUSTOMER.INVOICE_LINE2' })} optional />
      <ImporterField name="post_code_2" label={intl({ id: 'IMPORT.CSV.CUSTOMER.INVOICE_POST_CODE' })} optional />
      <ImporterField name="city_2" label={intl({ id: 'IMPORT.CSV.CUSTOMER.INVOICE_CITY' })} optional />
      <ImporterField name="country_code_2" label={intl({ id: 'IMPORT.CSV.CUSTOMER.INVOICE_COUNTRY_CODE' })} optional />

      <ImporterField name="discount" label={intl({ id: 'IMPORT.CSV.CUSTOMER.DISCOUNT' })} optional />
      <ImporterField name="notes" label={intl({ id: 'IMPORT.CSV.CUSTOMER.NOTES' })} optional />

    </Importer>
  );
}
