import React from 'react';



export default function Imprint() {
  
    //Login sayfası dar ekrandayken tab değişince sayfa başına kaydırması için
    window.scrollTo(0, 0);
    //Login sayfası geniş ekrandayken tab değişince sayfa başına kaydırması için.
    const contentElement = document.getElementById('content');
    if (contentElement) {
      contentElement.scrollTo(0, 0);
    }
  
  return (
    <div>

      <h3>Impressum</h3>
      <br />

      <b>BCS-IT GmbH</b>
      <p>
        diese vertreten durch den Geschäftsführer Halit Tura<br />
        Hans-Böckler-Straße 40<br />
        40764 Langenfeld<br />
        Deutschland<br />
      </p>

      <p>
        Telefon: +49 (0)21732697183<br />
        E-Mail: kontakt@salevali.de<br />
      </p>

      <p>
        USt-IdNr.: DE298830666<br />
        Eingetragen im Handelsregister des Amtsgerichtes Düsseldorf<br />
        Handelsregisternummer HRB 74382
      </p>

      <p>
        Wir sind seit 14.05.2018 Mitglied der Initiative "FairCommerce".<br />
        Nähere Informationen hierzu finden Sie unter <a href="https://www.haendlerbund.de/de/haendlerbund/interessenvertretung/faircommerce" target="_blank" rel="noreferrer noopener">www.haendlerbund.de/de/haendlerbund/interessenvertretung/faircommerce</a>.
      </p>


      <b>Information zur Online-Streitbeilegung gemäß Art. 14 § 1 ODR-VO (Online-Streibeilegungs-Verordnung):</b>
      <p>
        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie hier finden: <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="nofollow noopener noreferrer">ec.europa.eu/consumers/odr</a>. Verbraucher haben die Möglichkeit, diese Plattform für die Beilegung von Streitigkeiten über online getätigte Käufe oder abgeschlossene Dienstleistungsverträge zu nutzen.
      </p>

      <b>Alternative Streitbeilegung:</b>
      <p>
        Die Europäische Kommission stellt eine Plattform für die außergerichtliche Online-Streitbeilegung (OS-Plattform) bereit, aufrufbar unter <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noreferrer noopener">ec.europa.eu/consumers/odr</a>.
      </p>

    </div>
  );
}