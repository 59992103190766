/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modal, OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';
import { useSettingsEditContext } from '../_context/SettingsEditContext';
import { getConnection } from 'constants/connectionSettings';
import { ConnectionsActions } from '../_redux/actions';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { CloseButton } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import { Portal } from 'react-portal';
import clsx from 'clsx';



export function FetchOrdersDialog() {

  const dispatch = useDispatch();

  const { loading, orders } = useSelector(state => ({
    loading: state.connections.loading,
    orders: state.connections.orders,
  }), shallowEqual);

  const UIContext = useSettingsEditContext();
  const UIProps = useMemo(() => ({
    connectionName: UIContext.connectionName,
    show: UIContext.showFetchOrdersDialog,
    onHide: UIContext.closeFetchOrdersDialog
  }), [
    UIContext.connectionName,
    UIContext.showFetchOrdersDialog,
    UIContext.closeFetchOrdersDialog,
  ]);

  const handlingOnHide = () => {
    UIProps.onHide();
    dispatch(ConnectionsActions.clearLoadingEffects());
  }


  const FETCH_ORDERS = [
    {
      name: "new",
      label: <FormattedMessage id='CONNECTIONS.FETCH_ORDER.NEW' />,
      content: orders?.filter(order => order.status === 'new') ?? [],
      icon: <Icon.CloudDownload />,
    },
    {
      name: "updated",
      label: <FormattedMessage id='CONNECTIONS.FETCH_ORDER.UPDATE' />,
      content: orders?.filter(order => order.status === 'updated') ?? [],
      icon: <Icon.Cloud />,
    },
    {
      name: "error",
      label: <FormattedMessage id='CONNECTIONS.FETCH_ORDER.ERROR' />,
      content: orders?.filter(order => order.status === 'error') ?? [],
      icon: <Icon.CloudSlash />,
    },
  ];


  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={UIProps.show} onHide={handlingOnHide} aria-labelledby="example-fetch-orders-modal-title-md" backdrop="static" keyboard={false} centered>

        <CloseButton onClick={handlingOnHide} className="position-absolute top-0 right-0 mr-10 mt-10" />

        <div className="d-flex justify-content-center icon-4x mt-15 mb-5">
          {getConnection(UIProps.connectionName)?.iconLong}
        </div>

        <Modal.Body className={clsx(loading && "overlay overlay-block")}>

          <div className="separator separator-dashed mb-5" />

          <ul className="navi navi-link-rounded navi-hover">
            {FETCH_ORDERS.map((item, i) => (

              <li className="navi-item" key={i}>
                <Link className="navi-link" to={item?.content?.length ? `${ModuleRoutes.DOCUMENT_ORDER}?page=1&filter.status=open&order=order_date%20DESC&search=${UIProps.connectionName}` : "#"} id='btn_product_preview'>

                  <span className='navi-icon svg-icon svg-icon-md'>
                    {item.icon}
                  </span>

                  <span className="navi-text font-weight-bold font-size-h6">
                    <FormattedMessage id={item.label} />
                  </span>

                  <OverlayTrigger placement="right" overlay={<Tooltip id="market-tooltip">{item?.content?.length ? item?.content?.map(i => <div key={i.orderId} className='text-nowrap'>{i.orderId}</div>) : <FormattedMessage id='CONNECTIONS.FETCH_ORDER.NO_ORDERS' />}</Tooltip>}>
                    <span className='navi-label'>
                      <span className="label label-light-primary label-rounded label-xl font-weight-bolder font-size-h6">
                        {item.content?.length}
                      </span>
                    </span>
                  </OverlayTrigger>

                </Link>
              </li>

            ))}
          </ul>

          {!loading && !orders?.length &&
            <Alert variant='light-warning' className="d-flex align-items-center alert-custom alert-notice font-weight-bold py-4 mt-5 mb-0">
              <Icon.InfoCircle className="mr-2" />
              <FormattedMessage id='CONNECTIONS.SHOP_FETCH_ORDERS.WARNING' />
            </Alert>}

          {loading && <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-track spinner-lg spinner-primary" />
          </div>}

        </Modal.Body>

      </Modal>
    </Portal>
  );
}