import React from 'react'
import UserContactCard from './UserContactCard'



export function UserContactPage() {
  return (
    <>
      <UserContactCard />
    </>
  )
}
