import React from 'react';
import { Modal } from 'react-bootstrap';
import { CloseButton } from '_metronic/_partials';
import { COMPANY_PAGE, LANDING_PAGE } from 'constants/apiUrl';
import { UIDates } from '_metronic/_helpers';
import { FormattedMessage } from 'react-intl';
import { Portal } from 'react-portal';



export default function Contact({ show, onHide }) {
  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={show} onHide={onHide} size='sm' centered>
        <Modal.Body className='text-center p-10'>

          <img className='mt-5 mb-15' width="200px" height="auto" src="/media/logo/salevali-logo-dark-beta.svg" alt="logo" />

          <CloseButton className="modal-close-btn text-white text-hover-primary icon-xl" onClick={onHide} />

          <div className='font-weight-bold text-dark-75 mb-15'>
            <h3 className='mb-5'>
              <FormattedMessage id='FOOTER.CONTACT.TITLE' />
            </h3>
            <span>
              <FormattedMessage id='FOOTER.CONTACT.DESCRIPTION' />
            </span>
          </div>

          <a href={LANDING_PAGE} className="btn btn-outline-primary nav-link mb-5" target="_blank" rel="noopener noreferrer">
            <FormattedMessage id='FOOTER.CONTACT.MORE_INFO' />
          </a>

          <a href={COMPANY_PAGE} className="text-dark-50 text-hover-primary" target="_blank" rel="noopener noreferrer">
            &copy;{UIDates.getYear()} BCS-IT GmbH. <br /> <FormattedMessage id='FOOTER.CONTACT.ALL_RIGHTS_RESERVED' />
          </a>

        </Modal.Body>
      </Modal>
    </Portal>
  );
}
