/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useEffect } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDashboardUIContext } from '../_context/DashboardUIContext';
import { theme } from '_metronic/_helpers';
import { Icon } from '_metronic/_icons';
import moment from 'moment';



export function DatePickerDropdown() {

  const dashboardUIContext = useDashboardUIContext();
  const { formatMessage: intl } = useIntl()

  const uiProps = useMemo(() => {
    return {
      changeDateRange: dashboardUIContext.changeDateRange,
    };
  }, [dashboardUIContext]);


  const today = moment().format("YYYY-MM-DD")

  const initialState = {
    label: intl({ id: "DASHBOARD.DATE.LAST_30_DAYS" }),
    startDate: moment().add(-30, "d").format("YYYY-MM-DD"),
    endDate: today
  }

  const dayCalculation = (day) => {
    return moment().add(-day, "d").format("YYYY-MM-DD")
  }

  const optionData = [
    {
      label: intl({ id: "DASHBOARD.DATE.TODAY" }),
      id: "todays", startDate: today, endDate: today
    }, //TODO: Apiden bir gün aralığında veri gelmiyor
    {
      label: intl({ id: "DASHBOARD.DATE.LAST_7_DAYS" }),
      id: "last-7-days",
      startDate: dayCalculation(7),
      endDate: today
    },
    {
      label: intl({ id: "DASHBOARD.DATE.LAST_14_DAYS" }),
      id: "last-14-days",
      startDate: dayCalculation(14),
      endDate: today
    },
    {
      label: intl({ id: "DASHBOARD.DATE.LAST_30_DAYS" }),
      id: "last-30-days",
      startDate: dayCalculation(30),
      endDate: today
    },
    {
      label: intl({ id: "DASHBOARD.DATE.LAST_90_DAYS" }),
      id: "last-90-days",
      startDate: dayCalculation(90),
      endDate: today
    },
    {
      label: intl({ id: "DASHBOARD.DATE.THIS_MONT" }),
      id: "this-month",
      startDate: moment().format("YYYY-MM-01"),
      endDate: today
    }, //TODO: Apiden bir gün aralığında veri gelmiyor
    {
      label: intl({ id: "DASHBOARD.DATE.LAST_MONT" }),
      id: "last-month",
      startDate: moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD"),
      endDate: moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD"),
    },
    {
      label: intl({ id: "DASHBOARD.DATE.THIS_YEAR" }),
      id: "this-year",
      startDate: moment().format("YYYY-01-01"),
      endDate: today
    },
  ]


  const [dateRange, setDateRange] = useState(initialState)
  const [customDateRange, setCustomDateRange] = useState({
    startDate: moment().add(-30, "d").format("YYYY-MM-DD"),
    endDate: today
  })

  const dateChanger = (e) => {

    const selectedDate = optionData.find(item => item.id === e.target.id)

    setDateRange({
      ...dateRange,
      id: selectedDate.id,
      label: selectedDate.label,
      startDate: selectedDate.startDate,
      endDate: selectedDate.endDate
    })
  }

  const dateChangerCustom = (e) => {
    setCustomDateRange({ ...customDateRange, [e.target.name]: e.target.value })
    setDateRange({
      ...dateRange,
      label: intl({ id: "GENERAL.CUSTOM_RANGE" }),
      startDate: customDateRange.startDate,
      endDate: customDateRange.endDate
    })
  }



  useEffect(() => {
    uiProps.changeDateRange({ startDate: dateRange.startDate, endDate: dateRange.endDate })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange])




  return (
    <Dropdown className='d-flex justify-content-end' drop="down">

      {/* <span className='text-muted small font-weight-bolder mt-1'>{moment(dateRange.startDate).format("DD MMM YYYY") + " - " + moment(dateRange.endDate).format("DD MMM YYYY")}</span> */}
      <Dropdown.Toggle variant='outline-secondary' className='d-flex align-items-center font-weight-bold svg-icon svg-icon-sm' style={{ height: theme.units.input.height['sm'] }} id="btn_date_picker">
        <Icon.Calendar className="mr-3" />
        {dateRange.label}
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu dropdown-menu-left dropdown-menu-anim-up" id='dropdown_date_picker'>

        <Form >
          <ul className="navi navi-hover">
            {optionData.map((item, i) => (
              <li className="navi-item" key={i}>
                <Dropdown.Item className='navi-link font-weight-bolder' id={item.id} onClick={dateChanger}>
                  {item.label}
                </Dropdown.Item>
              </li>
            ))}

            <Dropdown.Divider />

            <li className="navi-item">
              <a className='dropdown-item navi-link font-weight-bolder' href="#" id="custom" onClick={(e) => setDateRange({ ...dateRange, id: e.target.id, })}>
                <FormattedMessage id='GENERAL.SET_CUSTOM_RANGE' />
              </a>
            </li>

            {dateRange.id === "custom" && <div className='m-3'>

              <div className='d-flex align-items-center flex-row flex-nowrap mb-3'>
                <Form.Control name="startDate" type="date" value={customDateRange.startDate} min={"1990-01-01"} max={dateRange.endDate || today}
                  onChange={dateChangerCustom} disabled={dateRange.id !== "custom"} />
                <b className='mx-2'>to</b>
                <Form.Control name="endDate" type="date" value={customDateRange.endDate} max={today}
                  onChange={dateChangerCustom} disabled={dateRange.id !== "custom"} />
              </div>

              <div className='d-flex justify-content-end'>
                <Dropdown.Item className='btn btn-light d-flex justify-content-center w-25 mr-2' eventKey="1" id="dropdown_item_cancel">
                  <FormattedMessage id='GENERAL.CANCEL' />
                </Dropdown.Item>
              </div>

            </div>}
          </ul>
        </Form>
      </Dropdown.Menu>
    </Dropdown>
  );
}
