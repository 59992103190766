import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';
import { Portal } from 'react-portal';



export default function DirtyDialog({ success, onClose }) {

  const history = useHistory();

  const [showModal, setShowModal] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);
  const [unblock, setUnblock] = useState(() => () => { });

  const formik = useFormikContext();

  useEffect(() => {

    if (success) {
      formik.resetForm()
      onClose()
    }

    const unblock = history.block((location, action) => {
       //FIXME: product form kirli değilken formik.dirty=true geliyor bakılacak
      // Dış bağlantılar için dirty kontrolü yapma
      if (formik.dirty && location.hash !== '#!') {
        setShowModal(true);
        setNextLocation({ location, action }); // Store the location to navigate after confirmation
        return false; // Prevent navigation
      }
      return true; // Allow navigation
    });

    setUnblock(() => unblock);
    return () => {
      unblock();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, formik.dirty, success]);


  const handleConfirmLeave = () => {
    setShowModal(false);
    unblock();
    formik.resetForm();
    onClose();

    // FIXME: Customer page nextlocation wrong path. Product-edit,product-new form dirty farklı çalışıyor geçici cözüm??
    if (nextLocation && nextLocation.location.pathname.includes('/products/')) {
      return history.push('/products');
    } else if (nextLocation && !nextLocation.location.pathname.includes('customer')) {
      return history.replace(nextLocation.location);
    } else {
      history.goBack();
    }
  };


  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={showModal} onHide={() => setShowModal(false)} size="sm" backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered>

        <Modal.Body className="text-center line-height-md h6 py-10 mb-0">
          <FormattedMessage id="GENERAL.DIRTY_MESSAGE" />
        </Modal.Body>

        <Modal.Footer className="flex-nowrap p-0 overflow-hidden">

          <Button variant='ios' className="border-right" onClick={() => setShowModal(false)} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant='ios' className="text-danger" onClick={handleConfirmLeave} id="btn_leave_modal">
            <FormattedMessage id="GENERAL.LEAVE" />
          </Button>

        </Modal.Footer>

      </Modal>
    </Portal>
  );
}
