import React from 'react';
import UserInfoDropdown from '_metronic/_partials/user-info/UserInfoDropdown';
import clsx from 'clsx';



export function Checkbox({
  id,
  form,
  field,
  label,
  size = "md",
  info,
  parentClass,
  withFeedbackLabel,
  feedbackLabel,
  disabled,
  ...props
}) {
  return (
    <label className={clsx(`checkbox checkbox-${size} checkbox-single mb-3`, parentClass)}>
      <input type="checkbox" {...field} {...props} id={`checkbox_${id ?? field.name}`} disabled={disabled} />
      <span />
      <div className="d-flex align-items-center font-weight-bold ml-2">
        {label}
        {info && <UserInfoDropdown size="w-75" description={info} />}
      </div>
    </label>
  );
}
