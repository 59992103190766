import React from 'react'
import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { AuthRoutes } from 'constants/moduleRoutes'
import { FormattedMessage } from 'react-intl'
import { Icon } from '_metronic/_icons';



export function Logout() {
  return (
    <OverlayTrigger placement="auto" overlay={<Tooltip id="quick-actions-tooltip"><FormattedMessage id='HEADER.LOGOUT' /></Tooltip>}>
      <div className="topbar-item">
        <Link to={AuthRoutes.LOGOUT} className="btn btn-icon btn-clean btn-lg text-dark-75 text-hover-primary" id="btn_logout2">
          <span className="svg-icon svg-icon-md">
            <Icon.Logout />
          </span>
        </Link>
      </div>
    </OverlayTrigger>
  )
}