
export const customer = {
    main: {
        title: "CUSTOMER.TITLE_PLURAL",
        location: 'customer',
        description: "USER_GUIDE.CUSTOMER",
        name: 'CUSTOMER.TITLE_PLURAL',
        name2: 'CUSTOMER.NEW_CUSTOMER',
    },
    new: {
        title: "CUSTOMER.NEW_CUSTOMER",
        description: "USER_GUIDE.CUSTOMER_NEW",
        name: 'CUSTOMER.TITLE_PLURAL'
    },
    edit: {
        title: "CUSTOMER.UPDATE_CUSTOMER",
        description: "USER_GUIDE.CUSTOMER_EDIT",
        name: 'CUSTOMER.TITLE_PLURAL'
    },
}