import React, { useEffect, useState } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { Card, CardBody, CardSubHeader } from "_metronic/_partials/controls";
import { FormattedMessage, useIntl, } from "react-intl";
import * as columnFormatters from '../ColumnFormatters';
import { useFormikContext } from "formik";
import { useWindowSize } from 'app/hooks';



export default function KauflandVariant({ onDeletedRowChange, method, loading }) {

  const { values } = useFormikContext();
  const { windowSize } = useWindowSize();
  const { formatMessage: intl } = useIntl();

  const [deletedRow, setDeletedRow] = useState([]);

  useEffect(() => {
    if (values && values.variants && method === 'edit') {
      values.variants.map(item => !item?.active && setDeletedRow(prevDeletedRow => [...prevDeletedRow, item.id]))
    }
  }, [method, values]);

  const handleAfterSaveVariantCell = (oldValue, newValue, row, column) => {
    if (column.dataField === 'pricing.gross_price') {
      const tax = +values?.pricing?.tax || 19;
      const netPrice = +newValue / (1 + tax / 100);
      row.pricing = {
        ...row.pricing,
        gross_price: +newValue,
        net_price: +netPrice,
      };
    }
  };

  useEffect(() => {
    onDeletedRowChange(deletedRow);
  }, [deletedRow, onDeletedRowChange]);

  const rowStyle = (row) => {
    if (deletedRow.includes(row.id)) {
      return { opacity: 0.5 };
    }
    return null;
  };

  const COLUMNS = [
    {
      dataField: '#',
      isDummyField: true,
      text: intl({ id: 'GENERAL.NO' }),
      headerStyle: { width: '5%' },
      headerClasses: 'text-center pl-10',
      classes: 'font-weight-bold text-center pl-10',
      editable: false,
      formatter: (cell, row, rowIndex, formatExtraData) => rowIndex + 1,
    },
    {
      dataField: 'specs',
      text: intl({ id: 'PRODUCT.VARIANTS' }),
      headerStyle: { width: '20%' },
      headerClasses: 'text-left',
      classes: 'text-left',
      editable: false,
      formatter: columnFormatters.ActionFormatterSpecs,
    },
    {
      dataField: 'sku',
      text: intl({ id: 'GENERAL.SKU' }),
      headerStyle: { width: '15%' },
      headerClasses: 'text-left',
      classes: 'font-weight-bold',
      editable: false,
    },
    {
      dataField: 'ean',
      text: intl({ id: 'PRODUCT.EAN' }),
      headerStyle: { width: '15%' },
      headerClasses: 'text-left',
      classes: 'font-weight-bold',
      editable: false,
    },
    {
      dataField: 'quantity',
      text: intl({ id: 'PRODUCT.GENERAL.QUANTITY' }),
      headerStyle: { width: '10%' },
      headerClasses: 'text-center',
      classes: 'font-weight-bolder text-center',
      editable: (cell, row) => !deletedRow.includes(row.id),
      editorStyle: () => ({ height: '32px', textAlign: 'center', padding: "0.5rem 0.75rem" }),
      formatter: (cell, row) => columnFormatters.ActionFormatterQuantity(cell, row, !deletedRow.includes(row.id))
    },
    {
      dataField: 'pricing.net_price',
      text: intl({ id: 'PRODUCT.TABLE.PRICE_NET' }),
      headerStyle: { width: '10%' },
      headerClasses: 'text-right pr-4',
      classes: 'font-weight-bolder text-right',
      editable: false,
      editorStyle: () => ({ height: '32px', textAlign: 'center', padding: "0.5rem 0.75rem" }),
      formatter: (cell, row) => columnFormatters.ActionFormatterNetPrice(cell, row, values?.pricing?.tax),
    },
    {
      dataField: 'pricing.gross_price',
      text: intl({ id: 'PRODUCT.TABLE.PRICE_GROSS' }),
      headerStyle: { width: '10%' },
      headerClasses: 'text-right pr-4',
      classes: 'font-weight-bolder text-right',
      editable: (cell, row) => !deletedRow.includes(row.id),
      editorStyle: () => ({ height: '32px', textAlign: 'center', padding: "0.5rem 0.75rem" }),
      formatter: (cell, row) => columnFormatters.ActionFormatterGrossPrice(cell, row, !deletedRow.includes(row.id)),
    },
    {
      dataField: 'action',
      text: "...",
      headerStyle: { width: '5%' },
      headerClasses: 'font-size-h3 text-center pt-0 px-10',
      classes: 'text-center px-10',
      editable: false,
      formatter: columnFormatters.ActionFormatterVariant,
      formatExtraData: {
        deletePositionAction: id => setDeletedRow(prevDeletedRow => [...prevDeletedRow, id]),
        createPositionAction: id => setDeletedRow(prevDeletedRow => prevDeletedRow.filter(rowId => rowId !== id)),
        deleteLength: values?.variants?.length,
        isDeleteIds: deletedRow
      },
    },
  ];


  return (
    values?.variants?.length > 0 && (
      <Card className="gutter-b">
        <CardSubHeader title={<FormattedMessage id="PRODUCT.EBAY.VARIANT_TITLE" />} />
        <CardBody className="pt-0 px-0">
          <BootstrapTable
            keyField="id"
            data={values?.variants || []}
            columns={COLUMNS}
            wrapperClasses="table-responsive"
            headerClasses="bg-info-o-10 text-center"
            classes="table table-head-custom table-vertical-center my-0"
            bootstrap4
            bordered={false}
            condensed
            cellEdit={cellEditFactory({
              mode: windowSize < 576 ? 'click' : 'dbclick',
              blurToSave: true,
              afterSaveCell: handleAfterSaveVariantCell,
            })}
            rowStyle={rowStyle}
          />
        </CardBody>
      </Card>
    )
  )
}
