import React from 'react'
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export default function BackButton({ link = '#', onClick, disabled }) {
  return (
    <OverlayTrigger overlay={<Tooltip id="market-tooltip"><FormattedMessage id="GENERAL.BACK" /></Tooltip>}>
      <Link to={link}
        type="button"
        className='btn btn-sm btn-hover-light-primary svg-icon px-2 mr-1'
        style={{ "marginLeft": "-8px" }}
        onClick={onClick}
        disabled={disabled}
        id='btn_back'
      >
        <Icon.ChevronLeft />
      </Link>
    </OverlayTrigger>
  )
}