import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { getPages, getPagesCount } from "_metronic/_helpers";
import { useWindowSize } from 'app/hooks';
import { Icon } from '_metronic/_icons';



export function RemotePaginationLinks({ queryParams, setQueryParams, totalCount = 0, paginationSize = 5, entitiesCount = 0 }) {

  const { windowSize } = useWindowSize();

  const pagesCount = getPagesCount(totalCount, +queryParams.limit);
  const pages = getPages(+queryParams.page, pagesCount, windowSize < 576 ? 3 : paginationSize);
  const currentPage = +queryParams.page;

  const tablePaginationHandler = (page) => {
    page === 'previous'
      ? setQueryParams({ page: +currentPage === 1 ? +currentPage : +currentPage - 1 })
      : page === 'next'
        ? setQueryParams({ page: +pagesCount === +currentPage ? +currentPage : +currentPage + 1 })
        : page === 'first-page'
          ? setQueryParams({ page: 1 })
          : page === 'last-page'
            ? setQueryParams({ page: +pagesCount })
            : setQueryParams({ page: +page });
  };

  //Son sayfada silme işlemi sonrası sayfada veri olmaması durumunda bir önceki sayfaya geçiş işlemi
  useEffect(() => {
    if (entitiesCount === 0) {
      tablePaginationHandler('previous')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entitiesCount])


  return (
    <>
      {pagesCount > 1 && (

        <div className='d-flex flex-wrap mr-3 mt-3'>

          {pagesCount > 3 &&
            <Button
              variant="light"
              size="sm"
              className="btn-icon btn-hover-primary mr-2"
              onClick={() => tablePaginationHandler("first-page")}
              disabled={pagesCount < 2 || currentPage === 1}
              id="btn_first_page"
            >
              <Icon.ChevronDoubleLeft />
            </Button>
          }

          <Button
            variant="light"
            size="sm"
            className="btn-icon btn-hover-primary mr-2"
            onClick={() => tablePaginationHandler("previous")}
            disabled={pagesCount < 2 || currentPage === 1}
            id="btn_prev_page"
          >
            <Icon.ChevronLeft />
          </Button>

          {currentPage > 3 && <span className="mr-2 my-auto">...</span>}

          {pages.map(p => (
            <Button
              key={p}
              variant="light"
              size="sm"
              className={`btn-icon btn-hover-primary font-weight-bolder mr-2 ${currentPage === p ? 'active' : ''}`}
              onClick={() => (currentPage !== p && tablePaginationHandler(p, queryParams.limit))}
              id={`btn_page_${p}`}
            >
              {p}
            </Button>
          ))}

          {currentPage < pagesCount - 3 && <span className="mr-2 my-auto">...</span>}

          <Button
            variant="light"
            size="sm"
            className="btn-icon btn-hover-primary mr-2"
            onClick={() => tablePaginationHandler("next")}
            disabled={pagesCount < 2 || pages.at(pages.length - 1) === currentPage}
            id="btn_next_page"
          >
            <Icon.ChevronRight />
          </Button>

          {pagesCount > 3 &&
            <Button
              variant="light"
              size="sm"
              className="btn-sm btn-hover-primary"
              onClick={() => tablePaginationHandler("last-page")}
              disabled={pagesCount < 2 || pages.at(pages.length - 1) === currentPage}
              id="btn_last_page"
            >
              <Icon.ChevronDoubleRight />
            </Button>
          }

        </div>
      )}
    </>
  );
}
