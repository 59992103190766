import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import { Card, CardBody } from '_metronic/_partials/controls';
import { SETTINGS_MENU } from './_context/SettingsHelper';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';



export default function SettingsMenu() {

  const { pathname } = useLocation();

  return (
    <Card className="gutter-b" style={{ position: "sticky", top: "140px" }}>
      <CardBody className="p-5">

        <ul className="navi navi-bold navi-hover navi-active navi-link-rounded" role="tablist">
          {SETTINGS_MENU.map((item, index) => (item.hidden ? null :
            <li className="navi-item mb-2" key={index}>
              <Link to={item.route} className={clsx("navi-link", { active: pathname.includes(item.route) })}>
                <span className="navi-icon">
                  {item.icon}
                </span>
                <span className="navi-text">
                  <FormattedMessage id={item.title} />
                </span>
              </Link>
            </li>
          ))}
        </ul>

      </CardBody>
    </Card>
  )
}