/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AdminRoutes } from "constants/moduleRoutes";
import { Icon } from '_metronic/_icons';




export const ActionsColumnFormatter = (cellContent, row, rowIndex) => (
  <>
    <OverlayTrigger overlay={<Tooltip id="customers-edit-tooltip"><FormattedMessage id="ADMIN.TEMP_USER.EDIT" /></Tooltip>}>
      <Link className="btn btn-icon btn-hover-primary btn-sm" to={AdminRoutes.ADMIN_TEMP_USERS_EDIT_FN(row.id)} id={`btn_edit_${row.id}`}>
        <span className="svg-icon svg-icon-md">
          <Icon.PencilSquare />
        </span>
      </Link>
    </OverlayTrigger>

    <OverlayTrigger overlay={<Tooltip id="customers-delete-tooltip"><FormattedMessage id="ADMIN.TEMP_USER.DELETE" /></Tooltip>}>
      <Link className="btn btn-icon btn-hover-danger btn-sm" to={AdminRoutes.DELETE_TEMP_USER_FN(row.id)} id={`btn_delete_${row.id}`}>
        <span className="svg-icon svg-icon-md">
          <Icon.Trash />
        </span>
      </Link>
    </OverlayTrigger>
  </>
);