import React from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Portal } from 'react-portal';
import { getLastSearch } from '_metronic/_helpers';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { CustomerEditChildren } from './CustomerEditChildren';



export function CustomerEdit({ show, onHide, modal }) {

  const history = useHistory();
  const lastSearch = getLastSearch()


  if ((show, modal)) {
    return (
      <Portal node={document && document.getElementById('modal-portal')}>
        <Modal size="xl" show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
          <CustomerEditChildren show={show} onHide={onHide} modal />
        </Modal>
      </Portal>
    );
  } else {
    return <CustomerEditChildren onHide={() => history.push(ModuleRoutes.CUSTOMER + lastSearch)} />;
  }
}
