import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useDocumentsUIContext } from '../_context/DocumentsUIContext';
import { CloseButton } from "_metronic/_partials";
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Icon } from '_metronic/_icons';
import { Portal } from 'react-portal';



export function ConvertDocumentInfoDialog() {

  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showConvertDocumentInfoDialog,
    onHide: UIContext.closeConvertDocumentInfoDialog,
    confirmedParams: UIContext.confirmedParams,
  }),
    [
      UIContext.confirmedParams,
      UIContext.closeConvertDocumentInfoDialog,
      UIContext.showConvertDocumentInfoDialog,
    ]
  );

  const relationInvoiceId = UIProps?.confirmedParams?.isDuplicateInvoceId

  return (
    <Portal node={document && document.getElementById('modal-portal')}>

      <Modal show={UIProps.show} onHide={UIProps.onHide} size="md" backdrop="static" aria-labelledby="example-modal-sizes-title-md" centered>

        <CloseButton onClick={UIProps.onHide} className="position-absolute top-0 right-0 mr-10 mt-10" />

        <div className="d-flex justify-content-center icon-4x pt-10">
          <Icon.InfoCircle className="text-info" />
        </div>

        <Modal.Body className="text-center line-height-lg h6 py-10 mb-0">
          <FormattedMessage id='DOCUMENT.CONVERT.DUPLICATE_INVOICE.ALERT_TEXT' values={{ id: <Link to={`invoices?page=1&search=${relationInvoiceId}`} onClick={UIProps.onHide}>{relationInvoiceId}</Link> }} />
        </Modal.Body>

      </Modal>
    </Portal>
  );
}