import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { ModuleRoutes } from "constants/moduleRoutes";
import { Icon } from '_metronic/_icons';
import { theme } from "_metronic/_helpers";



export function SuppliersGrouping({ UIProps }) {

  const history = useHistory();

  const deleteAllSelectedSuppliers = () => {
    const ids = UIProps.ids.join("&");
    history.push(ModuleRoutes.DELETE_SUPPLIER_LISTS_FN(ids))
  };


  return (
    <>
      <Button
        variant="light-primary"
        className="svg-icon svg-icon-sm font-weight-bold ml-auto"
        onClick={deleteAllSelectedSuppliers}
        style={{ height: theme.units.input.height['sm'] }}
        disabled={UIProps.ids.length <= 0}
        id="btn_multiple_delete"
      >
        <Icon.Trash />
        <span className="d-none d-sm-inline ml-2">
          <FormattedMessage id="GENERAL.DELETE" />
        </span>
      </Button>

    </>
  );
}
