import React from "react";
import { FormattedMessage } from 'react-intl';
import { bicRegExp, emailRegExp, nameRegExp, phoneRegExp, plzRegExp, streetRegExp, taxRegExp, vatRegExp } from "_metronic/_helpers";



export const checkImportCustomer = (values, checkData, emailsTable) => {

  const errors = [];
  const createError = (field, message, required = true) => {
    errors.push({ field, message, required });
  };

  for (let i = 0; i < values.length; i++) {

    const {
      type, name, company, phone, mobile,
      tax_number, vat_id, fax, email, bank, iban, bic,
      address_customer_name_1, address_company_name_1,
      address_line1_1, address_line2_1, post_code_1, city_1, country_code_1,
      address_customer_name_2, address_company_name_2,
      address_line1_2, address_line2_2, post_code_2, city_2, country_code_2,
      discount, notes
    } = values[i];

    // name
    if (!name || name?.length > 100 || !nameRegExp.test(name)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.CUSTOMER_NAME" />);
    };

    // Type
    if (type && type !== "standard" && type !== "private" && type !== "company") {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.CUSTOMER_TYPE" />);
    };
    if (type?.length > 100) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.CUSTOMER_LONG_TYPE" />);
    };

    // Company
    if (type === "company" && !company) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.COMPANY_REQUIRED" />);
    };
    if (company?.length > 200) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.COMPANY" />);
    };

    // Phone
    if (phone && (!phoneRegExp.test(phone) || phone.length > 25 || phone.length < 7)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.PHONE" />);
    };

    // Mobile
    if (mobile && (!phoneRegExp.test(mobile) || mobile?.length > 25 || mobile?.length < 7)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.MOBIL" />);
    };

    // Tax Number
    if (tax_number && (!taxRegExp.test(tax_number) || tax_number?.length > 15)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.TAX_NUMBER" />);
    };

    // Vat Id
    if (type === "company" && !vat_id) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.VAT_ID_REQUIRED" />);
    };
    if (vat_id && !vatRegExp.test(vat_id)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.VAT_ID" />);
    };

    // Fax
    if (fax && !phoneRegExp.test(fax)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG" values={{ title: 'Fax' }} />);
    };

    // Email
    const checkEmail = checkData.includes(email);
    const checkEmailTable = emailsTable.filter(e => e === email).length > 1 ? true : false;
    if (!email) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.EMAIL_REQUIRED" />);
    } else if (!emailRegExp.test(email.trim())) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG" values={{ title: 'Email' }} />);
    } else if (checkEmail || checkEmailTable) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.EMAIL_UNIQUE" />);
    }

    // Bank
    if (bank?.length > 100) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG" values={{ title: 'Bank' }} />);
    };

    if (iban && ( /*!ibanRegExp.test(iban)|| bu kisim iban karar regex karar verilince acilacak*/  iban.length > 27)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG" values={{ title: 'Iban' }} />);
    };

    if (bic && (!bicRegExp.test(bic) || bic.length > 50)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG" values={{ title: 'Bic' }} />);
    };

    // Delivery Address
    if (address_customer_name_1?.length > 50 || (address_customer_name_1 && !nameRegExp.test(address_customer_name_1))) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.DELIVERY.CUSTOMER_NAME" />);
    };

    if (address_company_name_1 && (address_company_name_1.length > 100 || !nameRegExp.test(address_company_name_1))) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.DELIVERY.COMPANY_NAME" />);
    };

    if (!address_line1_1 || address_line1_1?.length > 150 || !streetRegExp.test(address_line1_1)) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.DELIVERY.ADDRESS_LINE_1" />);
    };

    if (address_line2_1?.length > 150) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.DELIVERY.ADDRESS_LINE_2" />);
    };

    if (!post_code_1 || post_code_1?.length > 10 || (country_code_1 === "DE" && !plzRegExp.test(post_code_1))) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.DELIVERY.ADDRESS_POSTCODE" />);
    };

    if (!city_1 || city_1?.length > 50) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.DELIVERY.ADDRESS_CITY" />);
    };

    if (!country_code_1 || country_code_1?.length > 10) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.DELIVERY.ADDRESS_COUNTRY_CODE" />);
    };

    // Invoice Address
    if (address_customer_name_2?.length > 50 || (address_customer_name_2 && !nameRegExp.test(address_customer_name_2))) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.INVOICE.CUSTOMER_NAME" />);
    };

    if (address_company_name_2 && (address_company_name_2.length > 100 || !nameRegExp.test(address_company_name_2))) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.INVOICE.COMPANY_NAME" />);
    };

    if (address_line1_2?.length > 150 || (address_line1_2 && !streetRegExp.test(address_line1_2))) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.INVOICE.ADDRESS_LINE_1" />);
    };

    if (address_line2_2?.length > 150) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.INVOICE.ADDRESS_LINE_2" />);
    };

    if (post_code_2?.length > 10 || (post_code_2 && country_code_2 === "DE" && !plzRegExp.test(post_code_2))) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.INVOICE.ADDRESS_POSTCODE" />);
    };

    if (city_2?.length > 50) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.INVOICE.ADDRESS_CITY" />);
    };

    if (country_code_2?.length > 10) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.INVOICE.ADDRESS_COUNTRY" />);
    };

    if (discount < 0 || discount > 100) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.DISCOUNT" />);
    };

    if (notes?.length > 1000) {
      createError(<FormattedMessage id="IMPORT.TABLE_ROW" values={{ tableRow: i + 1 }} />, <FormattedMessage id="IMPORT.CUSTOMER.ALERT_DIALOG.NOTES" />);
    };

  }
  return errors;
};



