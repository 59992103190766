
export const company = {
    main: {
        title: "COMPANY.TITLE",
        location: 'company',
        description: "USER_GUIDE.COMPANY"
    },
    edit: {
        title: "COMPANY.EDIT.TITLE",
        description: "USER_GUIDE.COMPANY.EDIT"
    }
}
