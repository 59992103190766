import React from 'react';
import { AddressCard } from './AddressCard';
import { AddressDeleteDialog } from './AddressDeleteDialog';
import { AddressEditDialog } from './edit/AddressEditDialog';



export function Address() {
  return (
    <>
      <AddressEditDialog />
      <AddressDeleteDialog />
      <AddressCard />
    </>
  );
}