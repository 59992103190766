import React from "react";
import ActionTypes from "./actionTypes";
import { toast } from "react-toastify";
import { FormattedMessage } from "react-intl";



export class SupplierActions {

  // Create Supplier
  static createSupplier = (supplier) => {
    return {
      type: ActionTypes.CREATE_SUPPLIER,
      payload: {
        supplier,
      },
    };
  };

  static createSupplierSuccess = (supplier) => {
    toast.success(<FormattedMessage id='SUPPLIER.REDUX.CREATED_SUPPLIER.SUCCESS' values={{ supplier: supplier.name }} />);
    return {
      type: ActionTypes.CREATE_SUPPLIER_SUCCESS,
      payload: {
        supplier,
      },
    };
  };

  static createSupplierFail = () => {
    return {
      type: ActionTypes.CREATE_SUPPLIER_FAIL,
    };
  };


  // Find Supplier By Property
  static findSupplierByProperty = (key, value, supplierId) => {
    return {
      type: ActionTypes.FIND_SUPPLIER_BY_PROPERTY,
      payload: {
        key,
        value,
        supplierId,
      },
    };
  };

  static findSupplierByPropertySuccess = (supplier) => {
    return {
      type: ActionTypes.FIND_SUPPLIER_BY_PROPERTY_SUCCESS,
      payload: {
        supplier
      },
    };
  };

  static findSupplierByPropertyFail = () => {
    return {
      type: ActionTypes.FIND_SUPPLIER_BY_PROPERTY_FAIL,
    };
  };


  // Update Supplier
  static updateSupplier = (supplier) => {
    return {
      type: ActionTypes.UPDATE_SUPPLIER,
      payload: {
        supplier
      },
    };
  };

  static updateSupplierSuccess = (supplier) => {
    toast.success(<FormattedMessage id="SUPPLIER.REDUX.UPDATE_SUPPLIER.SUCCESS" />);
    return {
      type: ActionTypes.UPDATE_SUPPLIER_SUCCESS,
      payload: {
        supplier,
      },
    };
  };

  static updateSupplierFail = () => {
    //toast.error("Supplier updated failed");
    return {
      type: ActionTypes.UPDATE_SUPPLIER_FAIL,
    };
  };



  // Delete Supplier
  static deleteSupplier = (ids) => {
    return {
      type: ActionTypes.DELETE_SUPPLIER,
      payload: {
        ids,
      },
    };
  };

  static deleteSupplierSuccess = (ids) => {
    toast.success(<FormattedMessage id="SUPPLIER.REDUX.DELETE_SUPPLIER.SUCCESS" />);
    return {
      type: ActionTypes.DELETE_SUPPLIER_SUCCESS,
      payload: {
        ids,
      },
    };
  };

  static deleteSupplierFail = () => {
    return {
      type: ActionTypes.DELETE_SUPPLIER_FAIL,
    };
  };



  // Get All Supplier
  static getSupplier = (queryParams) => {
    return {
      type: ActionTypes.GET_SUPPLIER,
      payload: {
        queryParams
      },
    };
  };

  static getSupplierSuccess = (supplier, count) => {
    return {
      type: ActionTypes.GET_SUPPLIER_SUCCESS,
      payload: {
        supplier,
        count,
      },
    };
  };

  static getSupplierFail = () => {
    return {
      type: ActionTypes.GET_SUPPLIER_FAIL,
    };
  };



  // Get Supplier By Id
  static getSupplierById = (id) => {
    return {
      type: ActionTypes.GET_SUPPLIER_BY_ID,
      payload: {
        id,
      },
    };
  };

  static getSupplierByIdSuccess = (supplier) => {
    return {
      type: ActionTypes.GET_SUPPLIER_BY_ID_SUCCESS,
      payload: {
        supplier,
      },
    };
  };

  static getSupplierByIdFail = () => {
    return {
      type: ActionTypes.GET_SUPPLIER_BY_ID_FAIL,
    };
  };



  // Clean up handler
  static handleCleanUpForm = () => {
    return {
      type: ActionTypes.HANDLE_CLEAN_UP_FORM,
    };
  };
}