import React from 'react';
import { convertPriceCurrency } from '_metronic/_helpers';
import { QuantityStyle } from '_metronic/_helpers';


export function Ek1PriceFormatter(cell, row, rowIndex) {
  if (row.variants && row.variants.length > 0) {
    const prices = row.variants.map(variant => {
      return Number(variant.purchasePrice);
    });
    const startingPrice = prices && Math.min(...prices);
    return (
      <>
        <span className="label label-light-info label-inline">* ab</span>
        <span className="font-weight-bold">{convertPriceCurrency(startingPrice)}</span>
      </>
    );
  }
  return <>{convertPriceCurrency(row.purchasePrice)}</>;
}


export function SPNetFormatter(cell, row, rowIndex) {
  const currency = row.pricing?.currency;
  if (row.variants && row.variants.length > 0) {
    const prices = row.variants.map(variant => {
      return variant.pricing?.gross_price;
    });
    const startingPrice = prices && Math.min(...prices);
    const netPrice = (+startingPrice / (1 + +row.pricing?.tax / 100));
    return (
      <>
        <span className="label label-inline label-light-info">* ab</span>{' '}
        <span className="font-weight-bold">{convertPriceCurrency(netPrice, currency)}</span>
      </>
    );
  } else {
    const netPrice = +row.pricing?.gross_price / (1 + +row.pricing?.tax / 100);
    return <span className="ml-1">{convertPriceCurrency(netPrice, currency)}</span>;
  }
}


export function SPGrossFormatter(cell, row, rowIndex) {
  const currency = row.pricing?.currency;
  if (row.variants && row.variants.length > 0) {
    const prices = row.variants.map(variant => {
      return +variant?.pricing?.gross_price;
    });
    const startingPrice = prices && Math.min(...prices);
    return (
      <>
        <span className="ml-1 label label-light-info label-inline">* ab</span>{' '}
        <span className="font-weight-bold">{convertPriceCurrency(startingPrice, currency)}</span>
      </>
    );
  }

  return <>{convertPriceCurrency(+row.pricing?.gross_price, currency)}</>;
}



export function QuantityFormatter(cell, row) {
  let quantity = 0;

  if (row.variants && row.variants.length > 0) {
    quantity = row.variants.reduce((acc, variant) => acc + +variant.quantity, 0);
  } else {
    quantity = row.quantity;
  }

  return (QuantityStyle(quantity))
}

export function EK1Formatter(cell, row) {
  if (row.variants && row.variants.length > 0) {
    var purchasePrice = Math.max.apply(
      Math,
      row.variants.map(function (o) {
        return o.purchasePrice;
      })
    );

    return `* ab ${purchasePrice}`;
  }
  return row.purchasePrice;
}

export function editableFormatter(cell, row) {
  return row.variants && row.variants.length > 0 ? false : true;
}

