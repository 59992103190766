import { API } from 'constants/apiUrl';
import { UIDates } from '_metronic/_helpers';



export const dashboardMiddleware = {

  getDashboardData: async function (accessToken, { filter: { date_from, date_to, currency, marketplace } }) {
    return await API.get(`/dashboard`, {
      params: {
        dateRange: {
          startDate: date_from ?? UIDates.dayCalculation(-30),
          endDate: date_to ?? UIDates.getCurrentDate()
        },
        currency,
        marketplace
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

};
