import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { Formik, Form, Field } from "formik";
import { ConnectionsActions } from "../../_redux/actions";
import { SVFormControl } from '_metronic/_partials/controls';
import * as Yup from "yup";



const INPUTS = [
  {
    name: 'kaufland.market_name',
    label: 'CONNECTIONS.SHOP.MARKET_NAME',
    lg: "12"
  },
  {
    name: 'kaufland.ClientKey',
    label: 'CONNECTIONS.SHOP.KAUFLAND_CLIENT_KEY',
    editable: true
  },
  {
    name: 'kaufland.SecretKey',
    label: 'CONNECTIONS.SHOP.KAUFLAND_SECRET_KEY',
    type: "password",
    editable: true
  },
];


export default function KauflandForm({ children, connectionId, initialValue, loading, onHide }) {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const { kauflandForEdit, connections } = useSelector((state) => ({
    kauflandForEdit: state.connections.kauflandForEdit,
    connections: state.connections.entities.filter(connection => connection.name === "kaufland") ?? []
  }));

  const existingMarketName = connections.map(connection => connection.kaufland.market_name) ?? [];

  const validationSchema = Yup.object().shape({
    ...(!connectionId && {
      kaufland: Yup.object().shape({
        market_name: Yup.string()
          .required(intl({ id: 'GENERAL.REQUIRED' }))
          .test('match', intl({ id: "CONNECTIONS.SHOP.TOKEN_MATCH" }), (value) => !existingMarketName.includes(value)),
        ClientKey: Yup.string()
          .required(intl({ id: 'GENERAL.REQUIRED' })),
        SecretKey: Yup.string()
          .required(intl({ id: 'GENERAL.REQUIRED' }))
      })
    })
  });


  const handleSubmit = (values) => {
    connectionId
      ? dispatch(ConnectionsActions.updateSettings(values))
      : dispatch(ConnectionsActions.createSettings(values));
  };


  return (
    <Formik enableReinitialize={true} initialValues={connectionId ? kauflandForEdit : initialValue} validationSchema={validationSchema} onSubmit={handleSubmit}>
      <Form autoComplete='off'>

        <Modal.Body className='p-0'>
          <Row className='px-8'>
            {INPUTS.map((input, i) => (
              <Col key={i} lg={input.lg ?? "6"}>
                <Field component={SVFormControl}
                  {...{
                    name: input.name,
                    type: input.type,
                    label: intl({ id: input.label }),
                    withFeedbackLabel: true,
                    feedbackLabel: true,
                    required: true,
                    disabled: loading || (connectionId && !input.editable),
                  }}
                />
              </Col>
            ))}
          </Row>

          {children}

        </Modal.Body>

        <Modal.Footer>

          <Button variant="light" onClick={onHide} disabled={loading} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant="primary" type="submit" className="ml-2" disabled={loading} id="btn_save_modal">
            <FormattedMessage id="GENERAL.SAVE" />
          </Button>

        </Modal.Footer>
      </Form>
    </Formik>
  )
}
