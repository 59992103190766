import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Card, CardSubHeader, CardBody, CardHeaderToolbar } from '_metronic/_partials/controls';
import { CategoryExtendedList } from './category-list/CategoryExtendedList';
import { NewButton, UserInfoDropdown } from '_metronic/_partials';
import { BlankMessage } from '_metronic/_partials/components';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { CategoryActions } from './_redux/actions';
import { useGetCategories } from 'app/hooks';
import { Icon } from '_metronic/_icons';



export function CategoryCard() {

  const dispatch = useDispatch();

  const { entities, loading } = useSelector(state => state.categories);
  const categories = useGetCategories(entities)

  useEffect(() => {
    dispatch(CategoryActions.getCategories())
  }, [dispatch]);

  return (
    <Card>

      <CardSubHeader
        title={<FormattedMessage id='CATEGORY.LIST' />}
        icon={<Icon.Bookmarks />}
        info={<UserInfoDropdown
          title={<FormattedMessage id='CATEGORY.LIST' />}
          description={<FormattedMessage id='USER_GUIDE.SETTINGS.CATEGORY' />} />}
      >
        <CardHeaderToolbar>
          <NewButton link={ModuleRoutes.NEW_CATEGORY_FN(0, 0)} label="CATEGORY.NEW_CATEGORY" variant="outline-primary" disabled={loading} />
        </CardHeaderToolbar>
      </CardSubHeader>

      <CardBody className="pl-0 pb-15">

        {categories?.length === 0
          ? <BlankMessage
            icon={<Icon.Bookmarks />}
            title={<FormattedMessage id='CATEGORY.BLANK_MESSAGE_TITLE' />}
            message={<FormattedMessage id='CATEGORY.BLANK_MESSAGE' />}
            loading={loading}
            arrow
          />
          : <CategoryExtendedList categories={categories} />
        }

      </CardBody>
    </Card>
  );
}
