import React from "react";
import { Icon } from "_metronic/_icons";
import { FormattedMessage } from "react-intl";
import { ModuleRoutes } from "constants/moduleRoutes";



export const allQueryKeys = [
  'email_type',
  'date_to',
  'date_from',
  'search',
];

export const allQueryKeysOrderList = [
  'search',
];

export const EMAIL_TEMPLATE_TYPE = [
  {
    type: 'orders',
    label: <FormattedMessage id="DOCUMENT.ORDER_TITLE" />,
    icon: <Icon.Order />,
    to: ModuleRoutes.DOCUMENT_ORDER,
  },
  {
    type: 'offers',
    label: <FormattedMessage id="DOCUMENT.OFFER_TITLE" />,
    icon: <Icon.Document />,
    to: ModuleRoutes.DOCUMENT_OFFER,
  },
  {
    type: 'invoices',
    label: <FormattedMessage id="DOCUMENT.INVOICE_TITLE" />,
    icon: <Icon.Document />,
    to: ModuleRoutes.DOCUMENT_INVOICE,
  },
  {
    type: 'waybills',
    label: <FormattedMessage id="DOCUMENT.WAYBILL_TITLE" />,
    icon: <Icon.Document />,
    to: ModuleRoutes.DOCUMENT_WAYBILL,
  },
  {
    type: 'credits',
    label: <FormattedMessage id="DOCUMENT.CREDIT_TITLE" />,
    icon: <Icon.Document />,
    to: ModuleRoutes.DOCUMENT_CREDIT,
  },
  {
    type: 'reminder',
    label: <FormattedMessage id="DOCUMENT.ACTION.PAYMENT_REMINDER" />,
    icon: <Icon.Bell />
  },
  {
    type: 'warning_1',
    label: <FormattedMessage id="DOCUMENT.ACTION.REMINDER1" />,
    icon: <Icon.AppIndicator />
  },
  {
    type: 'warning_2',
    label: <FormattedMessage id="DOCUMENT.ACTION.REMINDER2" />,
    icon: <Icon.AppIndicator />
  },
  {
    type: 'return_label',
    label: <FormattedMessage id="DOCUMENT.SHIPPING.TYPE_RETURN" />,
    icon: <Icon.Truck />
  },
  {
    type: 'digital_product_confirm',
    label: <FormattedMessage id="REPORTS.EMAIL_LOG.DIGITAL_PRODUCT_CONFIRM" />,
    icon: <Icon.Password />,
    to: ModuleRoutes.DIGITAL_PRODUCT,
  },
  {
    type: 'digital_product_key',
    label: <FormattedMessage id="REPORTS.EMAIL_LOG.DIGITAL_PRODUCT_KEY" />,
    icon: <Icon.Password />,
    to: ModuleRoutes.DIGITAL_PRODUCT,
  }
];

export const EMAIL_TEMPLATE_STATUS = [
  ...EMAIL_TEMPLATE_TYPE,
  {
    type: 'all_types',
    label: <FormattedMessage id="GENERAL.TYPE_FILTER" />,
    icon: <Icon.Filter />
  }
];
