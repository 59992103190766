import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl';
import Spinner from '_metronic/_partials/loading/Spinner';
import { Portal } from 'react-portal';



export default function CustomerCompanyDeleteDialog({ show, onHide, toDelete, loading, isDeleted }) {

  useEffect(() => {
    isDeleted && onHide()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleted])

  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={show} onHide={onHide} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>

        <Modal.Body className="text-center line-height-md h6 py-10 mb-0">
          <FormattedMessage id='CUSTOMER.DELETE_COMPANY.QUESTION' />
        </Modal.Body>

        <Modal.Footer className="flex-nowrap overflow-hidden p-0">

          <Button variant='ios' className="border-right" onClick={onHide} id='btn_cancel_modal'>
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant='ios' className="text-danger" onClick={toDelete} id='btn_delete_modal'>
            <FormattedMessage id="GENERAL.DELETE" />
            {loading && <Spinner loading={loading} color="danger" />}
          </Button>

        </Modal.Footer>

      </Modal>
    </Portal>
  )
}