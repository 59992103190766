export const searchKeys = [
  'id',
  'order_id',
  'order_number',
  'marketplace',
  'marketplace_url',
  'customer_details.number',
  'customer_details.email',
  'customer_details.phone',
  'customer_details.name',
  'customer_details.company',
  'customer_details.delivery_address.post_code',
  'customer_details.delivery_address.city',
];