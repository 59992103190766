import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ConnectionsActions } from "../../_redux/actions";
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Formik, Form } from "formik";



export default function EtsyForm({ children, connectionId, initialValue, loading, onHide }) {

  const dispatch = useDispatch();

  const { etsyForEdit, success } = useSelector((state) => ({
    etsyForEdit: state.connections.etsyForEdit,
    success: state.connections.success
  }));


  const handleSubmit = () => {
    dispatch(ConnectionsActions.getEtsyAuthToken())
  };

  useEffect(() => {
    return () => {
      dispatch(ConnectionsActions.clearLoadingEffects())
    }
  }, [dispatch])


  return (
    <Formik enableReinitialize={true} initialValues={connectionId ? etsyForEdit : initialValue} onSubmit={handleSubmit}>
      <Form autoComplete='off'>

        <Modal.Body className='p-0'>
          {children}
        </Modal.Body>

        <Modal.Footer>

          <Button variant="light" onClick={onHide} disabled={loading} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant="primary" type="submit" className="ml-2" disabled={loading || success} id="btn_save_modal">
            <FormattedMessage id="GENERAL.GET_TOKEN" />
          </Button>

        </Modal.Footer>

      </Form>
    </Formik>
  )
}
