import React from 'react';
import styled from 'styled-components';
import { theme } from '_metronic/_helpers';
import { SVFormControlLabel } from './SVFormHelpers';
import { FormattedMessage } from 'react-intl';



export const SVFormSelect = props => (
  <StyledFormSelectWrapper className={props?.className}>
    {props.label && <SVFormControlLabel>{props.label}</SVFormControlLabel>}
    <StyledFormSelect {...props}>
      <SVFormSelectOption value={''} d_none>
        <FormattedMessage id='GENERAL.SELECT' />
      </SVFormSelectOption>
      {props?.options?.length > 0 &&
        props.options.map(option => (
          <SVFormSelectOption key={option.value} value={option.value}>
            {option.label}
          </SVFormSelectOption>
        ))}
    </StyledFormSelect>
  </StyledFormSelectWrapper>
);


const StyledFormSelectWrapper = styled.div`
  margin-bottom: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const StyledFormSelect = styled.select`
  position: relative;
  display: block;
  border: 1px solid ${theme.palette.gray['300']};
  border-radius: ${theme.shape.borderRadius};
  padding: ${theme.units.input.padding};
  height: ${p => theme.units.input.height[p.size || 'sm']};
  font-size: ${p => theme.typography.fontSize[p.size || 'md']};
  line-height: ${p => theme.typography.lineHeight[p.size || 'md']};
  background-color: ${theme.palette.base.white};
  background-clip: padding-box;
  color: ${theme.palette.base.black};
  width: 100%;
  box-shadow: ${theme.shadows.input};
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus {
    color: ${theme.palette.gray['700']};
    border-color: ${theme.palette.primary['300']};
    outline: 0;
    box-shadow: ${theme.shadows.inputHover};
  }
  &:disabled {
    color: ${theme.palette.gray['500']};
    background-color: ${theme.palette.gray['50']};
    border-color: ${theme.palette.gray['300']};
  }
  &:hover {
    cursor: pointer;
    border-color: ${theme.palette.primary['300']};
  }
  &:focus:hover {
    cursor: context-menu;
    border-color: ${theme.palette.primary['100']};
  }
  &:active {
    border-color: ${theme.palette.primary['300']};
  }
  &:active:hover,
  &:active:focus,
  &:active:focus:hover,
  &:focus:active,
  &:focus:active:hover,
  &:focus:active:focus {
    cursor: pointer;
    border-color: ${theme.palette.primary['100']};
  }
`;

const SVFormSelectOption = styled.option`
  font-size: ${p => theme.typography.fontSize[p.size || 'md']};
  margin: 2px;
  padding-bottom: 2px;
  display: ${p => (p.d_none ? 'none' : 'block')};
  &:hover,
  &:active,
  &:focus,
  &:checked {
    background-color: ${theme.palette.base.white};
    box-shadow: 0 0 10px 100px ${theme.palette.primary} inset;
  }
`;
