import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { RemotePaginationLinks } from "./RemotePaginationLinks";
import { RemotePaginationToolbar } from "./RemotePaginationToolbar";
import { saveLastLocation } from "_metronic/_helpers";



export function RemotePagination(props) {

  const location = useLocation();

  useEffect(() => {
    saveLastLocation(location)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.queryParams])


  return (
    <div className="d-flex justify-content-sm-between justify-content-center flex-column flex-sm-row align-items-center flex-wrap">

      <RemotePaginationLinks {...props} />

      <RemotePaginationToolbar {...props} />

    </div>
  );
}