import { call, put, select, takeEvery } from "redux-saga/effects";
import { documentMiddleware, tokenErrorHandler } from "middlewares";
import { digitalProductsMiddleware } from "middlewares/digital-products/middleware";
import { digitalProductsActions } from "./actions";
import ActionTypes from "./actionTypes";



// Get Digital Products
function* getDigitalProducts({ payload: { queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const countDigitalProduct = yield call(digitalProductsMiddleware.getCount, accessToken, queryParams);
    const response = yield call(digitalProductsMiddleware.getDigitalProducts, accessToken, queryParams);
    yield put(digitalProductsActions.getDigitalProductsSuccess(response.data, countDigitalProduct.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, digitalProductsActions.getDigitalProductsFail());
  }
}


// Get Customer Details
function* getCustomerDetails({ payload: { documentId } }) {
  try {

    const access = {
      accessToken: yield select(state => state.auth.access_token),
      documentModel: "orders",
    }

    const response = yield call(documentMiddleware.getDocumentById, access, documentId, 'customer_fields');
    yield put(digitalProductsActions.getCustomerDetailsSuccess(response.data));

  } catch (err) {
    yield put(digitalProductsActions.getCustomerDetailsFail(err));
  }
}


// Send Mail Key
function* sendMailKey({ payload: { ids, queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(digitalProductsMiddleware.sendMailKey, accessToken, ids);
    yield put(digitalProductsActions.sendMailKeySuccess());
    yield put(digitalProductsActions.getDigitalProducts(queryParams));

  } catch (err) {
    yield call(tokenErrorHandler, err, digitalProductsActions.sendMailKeyFail());
  }
}


// Send Mail Key
function* deleteKeyReserve({ payload: { ids, queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(digitalProductsMiddleware.deleteKeyReserve, accessToken, ids);
    yield put(digitalProductsActions.deleteKeyReserveSuccess());
    yield put(digitalProductsActions.getDigitalProducts(queryParams));

  } catch (err) {
    yield call(tokenErrorHandler, err, digitalProductsActions.deleteKeyReserveFail());
  }
}



export function* digitalProductSaga() {
  yield takeEvery(ActionTypes.GET_DIGITAL_PRODUCTS, getDigitalProducts);
  yield takeEvery(ActionTypes.GET_CUSTOMER_DETAILS, getCustomerDetails);
  yield takeEvery(ActionTypes.SEND_MAIL_KEY, sendMailKey);
  yield takeEvery(ActionTypes.DELETE_KEY_RESERVE, deleteKeyReserve);
}