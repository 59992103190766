import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import clsx from 'clsx';



export default function BlankMessage({ icon, title, message, button, arrow, className, loading }) {
  return (
    <div className={clsx("d-flex flex-column align-items-center justify-content-center position-relative h-100 py-10", className)}>

      {loading
        ? <div className='spinner spinner-primary spinner-xl' />
        : <>
          {icon &&
            <span className="svg-icon svg-icon-5x text-gray mb-5">
              {icon}
            </span>
          }

          <div className="text-center text-gray">
            <h4 className="font-weight-normal mb-0">
              {title}
            </h4>
            {message && message}
          </div>

          {button &&
            <Link to={button.link} className='font-weight-bold mt-3' id='btn_new'>
              <FormattedMessage id={button.label} />
            </Link>
          }

          {arrow &&
            <div className="svg-icon svg-icon-4x text-gray position-absolute top-0 right-0 mr-15 mt-3">
              <Icon.HandArrowUp />
            </div>}
        </>
      }

    </div>
  );
}
