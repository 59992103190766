import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { ActionsColumnFormatter } from "./ActionsColumnFormatter";
import { useSupplierUIContext } from "../../_context/SupplierUIContext";
import { BlankMessage } from "_metronic/_partials/components";
import { FormattedMessage, useIntl } from 'react-intl';
import { sortCaret } from "_metronic/_helpers";
import { Icon } from "_metronic/_icons";



export function BankTable() {

  const { formatMessage: intl } = useIntl();

  const UIContext = useSupplierUIContext();
  const UIProps = useMemo(() => ({
    openEditBankDialog: UIContext.openEditBankDialog,
    openDeleteBankDialog: UIContext.openDeleteBankDialog,
    bank: UIContext.bank,
  }), [
    UIContext.bank,
    UIContext.openDeleteBankDialog,
    UIContext.openEditBankDialog,
  ]);

  const COLUMNS = [
    {
      dataField: "name",
      text: intl({ id: "BANK.NAME" }),
      sort: true,
      sortCaret,
      classes: 'pl-9',
      headerClasses: 'pl-9',
    },
    {
      dataField: "iban",
      text: intl({ id: "BANK.IBAN" }),
    },
    {
      dataField: "bic",
      text: intl({ id: "BANK.BIC" }),
    },
    {
      dataField: "action",
      text: intl({ id: "GENERAL.ACTIONS" }),
      classes: "text-right pr-9",
      headerClasses: "text-right pr-9",
      headerStyle: { minWidth: '100px' },
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditBankDialog: UIProps.openEditBankDialog,
        openDeleteBankDialog: UIProps.openDeleteBankDialog,
      },
    },
  ];

  return (
    UIProps.bank.length === 0
      ? <BlankMessage
        icon={<Icon.Bank />}
        title={<FormattedMessage id="SUPPLIER.BANK.BLANK_MESSAGE_TITLE" />}
        message={<FormattedMessage id="SUPPLIER.BANK.BLANK_MESSAGE" />}
        arrow
      />
      : <BootstrapTable
        keyField="id"
        data={UIProps.bank || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        headerClasses="bg-info-o-10"
        classes="table table-head-custom table-vertical-center overflow-hidden mb-0"
        bootstrap4
        bordered={false}
        condensed
      // pagination={paginationFactory()}
      />

  );
}
