import { useCallback } from 'react';
import { isArray } from 'lodash';
import { useSelector } from 'react-redux';



export function useSkuValidationControl() {

  const { findProductBySku, findProductVariantBySku, productForEdit } = useSelector(state => ({
    findProductBySku: state.products.findProductBySku,
    findProductVariantBySku: state.products.findProductVariantBySku,
    productForEdit: state.products.productForEdit
  }));

  
  const checkIsSkuValid = useCallback(value => {
    if (isArray(findProductBySku) && findProductBySku.length > 0) {
      const isMatchedProduct = findProductBySku.find(prod => prod.sku === value);
      const isMatchedVariant = findProductBySku.find(prod =>
        prod.variants.find(variant => variant.sku === value)
      );
      return isMatchedProduct || isMatchedVariant ? true : false;
    }
    return false;
  },
    [findProductBySku]
  );

  const checkIsSkuValidForVariants = useCallback(
    value => {
      if (isArray(findProductVariantBySku)) {
        const isMatchedProduct = findProductVariantBySku.find(prod => prod.sku === value);
        const isMatchedVariant = findProductVariantBySku.find(prod =>
          prod.variants.find(variant => variant.sku === value)
        );
        return isMatchedProduct || isMatchedVariant ? true : false;
      }
      return false;
    },
    [findProductVariantBySku]
  );

  const checkIsSkuProductForEdit = useCallback(
    value => {
      if (productForEdit) {
        const isMatchedProduct = productForEdit.sku === value;
        return isMatchedProduct  ? true : false;
      }
      return false;
    },
    [productForEdit]
  );

  return { checkIsSkuValid, checkIsSkuValidForVariants , checkIsSkuProductForEdit};
}
