import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DropdownTopbarItemToggler } from '_metronic/_partials/dropdowns';
import { AuthRoutes, ModuleRoutes } from 'constants/moduleRoutes';
import { LicenseBadge } from '_metronic/_partials';
import { LANDING_PAGE } from 'constants/apiUrl';
import { Icon } from '_metronic/_icons';
import moment from 'moment';



export function AccountDetails() {

  const { user, license } = useSelector(state => ({
    user: state.auth.user,
    license: state.auth.license
  }));

  return (
    <Dropdown drop="down" alignRight>

      <Dropdown.Toggle as={DropdownTopbarItemToggler} id="kt_quick_notifications_toggle">

        <div className="topbar-item">
          <div className="btn btn-icon btn-clean w-auto btn-lg px-2" id="btn_account">

            <div className='d-flex flex-column justify-content-end font-size-base line-height-md mr-3'>
              <span className="text-muted text-right font-weight-bold d-none d-md-inline">{user?.name}</span>
              <span className="text-dark-50 text-right font-weight-bolder d-none d-md-inline">{user?.surname}</span>
            </div>

            <span className="symbol symbol-lg-35 symbol-25 symbol-light-primary">
              <span className="symbol-label font-size-h5 font-weight-bold">
                <Icon.Person />
              </span>
              {license?.type === 'trial' && moment(license?.updated_at).add(3, 'days') <= moment() && <i className="symbol-badge bg-danger"></i>}
            </span>

          </div>
        </div>

      </Dropdown.Toggle>


      <Dropdown.Menu className="dropdown-menu-right dropdown-menu-anim dropdown-menu-lg p-0" id="dropdown_account">

        <div
          className="d-flex align-items-center justify-content-between flex-wrap px-8 py-8 bgi-size-cover bgi-no-repeat rounded-top mb-4"
          style={{ backgroundImage: "url(/media/bg/bg-1.jpg)", }}
        >

          <span className="svg-icon svg-icon-3x text-light mr-4">
            <Icon.PersonFill />
          </span>

          <div className="text-white m-0 flex-grow-1 mr-3 font-size-h3">
            <FormattedMessage id="ACCOUNT.PROFILE_DETAILS" />
          </div>

          <OverlayTrigger placement="top" overlay={<Tooltip id="quick-actions-tooltip"><FormattedMessage id="GENERAL.EDIT" /></Tooltip>}>
            <div className="topbar-item">
              <Link to={ModuleRoutes.SETTINGS_COMPANY} className="btn btn-icon btn-lg text-white" id="btn_edit_profile">
                <span className="svg-icon svg-icon-lg">
                  <Icon.PencilSquare />
                </span>
              </Link>
            </div>
          </OverlayTrigger>

        </div>


        <div className="px-8 mb-4">

          <div className="d-flex align-items-center mb-4">

            <div className="symbol symbol-40 symbol-light-info mr-5">
              <div className="symbol-label">
                <span className="svg-icon svg-icon-lg text-dark-50">
                  <Icon.Person />
                </span>
              </div>
            </div>

            <div className="d-flex flex-column font-weight-bold">
              <span className="text-muted text-hover-primary mb-1">
                <FormattedMessage id="GENERAL.NAME" />
              </span>

              <h6>
                {`${user?.name} ${user?.surname ? user?.surname : ''}`}
              </h6>

            </div>

          </div>

          <div className="d-flex align-items-center mb-4">

            <div className="symbol symbol-40 symbol-light-info mr-5">
              <div className="symbol-label">
                <span className="svg-icon svg-icon-lg text-dark-50">
                  <Icon.EnvelopeAt />
                </span>
              </div>
            </div>

            <div className="d-flex flex-column font-weight-bold">
              <span className="text-muted text-hover-primary mb-1">
                <FormattedMessage id="GENERAL.EMAIL" />
              </span>

              <OverlayTrigger overlay={<Tooltip id="user-email-verification"><FormattedMessage id="ACCOUNT.VERIFIED" /></Tooltip>}>
                <h6 className="d-flex align-items-center">
                  {user?.email}
                  <Icon.PatchCheckFill className="text-success ml-3" />
                </h6>
              </OverlayTrigger>

            </div>
          </div>


          <div className="d-flex align-items-center mb-4">

            <div className="symbol symbol-40 symbol-light-info mr-5">
              <div className="symbol-label">
                <span className="svg-icon svg-icon-lg text-dark-50">
                  <Icon.Award />
                </span>
              </div>
            </div>

            <div className="d-flex flex-column font-weight-bold">
              <span className="text-muted text-hover-primary mb-1">
                <FormattedMessage id='HEADER.LICENSE' />
              </span>

              <LicenseBadge license={license} />

            </div>
          </div>

        </div>


        <div className="text-right px-9 py-4 border-top border-muted">

          <a href={`${LANDING_PAGE}/preise.html`} target="_blank" rel="noopener noreferrer" className="btn btn-clean  btn-hover-light-info font-weight-bold mr-2" id="btn_upgrade">
            <FormattedMessage id='HEADER.UPGRADE' />
          </a>

          <Link to={AuthRoutes.LOGOUT} type="button" className="btn btn-light-info font-weight-bold mr-2" id="btn_logout">
            <FormattedMessage id="HEADER.LOGOUT" />
          </Link>

        </div>

      </Dropdown.Menu>
    </Dropdown>
  );
}
