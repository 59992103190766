import { isArray, isLength, isInteger } from 'lodash';



export const searchParams = function (keys, event) {

  event = event.trim();

  if (isArray(keys) && isLength(keys.length)) {
    const searchForKeys = keys.map(key => {
      if (key === 'number' ||
        key === 'customer_details.number' ||
        key === 'pricing.purchase_price' ||
        key === 'price' ||
        key === 'weight'
      ) {
        return {
          [key]: { 'eq': parseInt(event) }
        }
      } else {
        return {
          [key]: { 'like': event, options: 'i' }
        }
      }
    });

    return { or: searchForKeys };
  };

  return { or: [] };
};



export const paginationParams = function (page, limit) {
  if (isInteger(page) && isInteger(limit)) {
    return {
      skip: (page - 1) * limit,
      limit: limit,
    };
  }
  return {};
};