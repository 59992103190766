import React, { useMemo } from 'react';
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import { ProductKeysTable } from './product-keys-table/ProductKeysTable';
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import { useProductEditUIContext } from '../../_context/ProductEditUIContext';
import { ProductKeysImport } from './product-keys-import/ProductKeysImport';
import { ProductKeysDeleteDialog } from './product-keys-dialog/ProductKeysDeleteDialog';
import { ProductKeysAddDialog } from './product-keys-dialog/ProductKeysAddDialog';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function ProductKeys({ productId, loading }) {

  const UIContext = useProductEditUIContext();
  const UIProps = useMemo(() => ({
    keyIds: UIContext.keyIds,
    openImportProductKeys: UIContext.openImportProductKeys,
    handleOpenKeysAddDialog: UIContext.handleOpenKeysAddDialog
  }),
    [
      UIContext.keyIds,
      UIContext.openImportProductKeys,
      UIContext.handleOpenKeysAddDialog
    ]);


  return (
    <Card className="sub-card-height gutter-b">

      <CardSubHeader title={<FormattedMessage id="PRODUCT.KEYS" />} icon={<Icon.Password />} id="keys">
        <Dropdown as={ButtonGroup} drop='down' size='sm'>

          <Button variant='outline-primary' size="sm" type="button" className="svg-icon svg-icon-sm" onClick={UIProps.openImportProductKeys} disabled={loading} id='btn_keys'>
            <Icon.Plus />
            <span className="d-none d-sm-inline font-weight-bold ml-2">
              <FormattedMessage id="PRODUCT.KEYS_IMPORT" />
            </span>
          </Button>

          <Dropdown.Toggle split variant="outline-primary" id="dropdown-split-basic" disabled={loading} />
          <Dropdown.Menu>
            <Dropdown.Item type='button' onClick={UIProps.handleOpenKeysAddDialog} id='btn_add_key'>
              <FormattedMessage id="PRODUCT.ADD_NEW_KEY" />
            </Dropdown.Item>
          </Dropdown.Menu>

        </Dropdown>
      </CardSubHeader>

      <CardBody>
        <ProductKeysTable productId={productId} />
        <ProductKeysImport productId={productId} />
        <ProductKeysAddDialog productId={productId}/>
        <ProductKeysDeleteDialog productId={productId}/>
      </CardBody>

    </Card>
  );
}
