import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { AuthLayout } from "_metronic/layout/components/layouts/AuthLayout";



export function Page404() {
  return (
    <AuthLayout>
      <div className="d-flex flex-column flex-root">
        <div
          className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
          style={{ backgroundImage: "url(/media/bg/404.webp)", filter: 'hue-rotate(62deg)' }}>

          <h1 className="font-size-sm-100 font-weight-bolder text-dark-75 mt-15" style={{ fontSize: "150px" }}>
            404
          </h1>

          <p className="font-size-h3 font-weight-light">
            OOPS! Something went wrong here
          </p>

          <Link to="/" id="btn_back_home">
            <Button variant="secondary" className="font-weight-bold font-size-h6 mt-10">
              Back to Home
            </Button>
          </Link>

        </div>
      </div>
    </AuthLayout>
  );
}
