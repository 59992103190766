import React, { useEffect, useState } from 'react';
import { CustomAlert, SVFormControl } from '_metronic/_partials/controls';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLoadingHandler, useQuery } from 'app/hooks';
import { AuthRoutes } from 'constants/moduleRoutes';
import { Link, useHistory } from 'react-router-dom';
import { emailRegExp, maxValidation } from '_metronic/_helpers';
import { Field, Form, Formik, } from 'formik';
import { AuthActions } from './_redux/actions';
import { Spinner } from '_metronic/_partials';
import { Button } from 'react-bootstrap';
import AuthHeader from './AuthHeader';
import * as Yup from 'yup';



export default function ForgotPassword({ recaptchaRef }) {

  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const { formatMessage: intl } = useIntl();

  const { request: { where, loading, status, error } } = useSelector(state => ({
    request: state.auth.request,
  }));

  const isLoading = useLoadingHandler(
    loading,
    AuthActions.clearLoadingEffects()
  );


  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .matches(emailRegExp, intl({ id: 'GENERAL.EMAIL_EMAIL' }))
      .email(intl({ id: 'AUTH.GENERAL.EMAIL_FORMAT' }))
      .min(3, intl({ id: 'AUTH.REGISTER.NAME.LENGTH_MIN' }))
      .max(50, maxValidation("50"))
      .required(intl({ id: 'AUTH.VALIDATION.EMAIL_REQUIRED' })),
  });

  const requestResult = () => {
    if (!isLoading && where === 'resetPassword') {
      return status
    }
  };

  const handleSubmit = (values) => {
    query.get('recaptcha_token')
      ? dispatch(AuthActions.resetPassword({ ...values, recaptcha_token: query.get('recaptcha_token') }))
      : onSubmitWithReCAPTCHA(values)
  }

  const onSubmitWithReCAPTCHA = async (values) => {
    const recaptcha_token = await recaptchaRef.current.executeAsync();
    dispatch(AuthActions.resetPassword({ ...values, recaptcha_token }))
  }


  useEffect(() => {
    recaptchaRef.current.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, error])

  const [timer, setTimer] = useState(10);

  useEffect(() => {
    let countDown;
    if (requestResult() === 'fulfilled' && timer > 0) {
      countDown = setInterval(() => {
        setTimer(timer - 1)
      }, 1000);
    }

    if (timer === 0) {
      clearInterval(countDown)
      history.push(AuthRoutes.LOGIN)
    }

    return () => clearInterval(countDown)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer, requestResult]);

  useEffect(() => {
    return () => {
      dispatch(AuthActions.cleanUpReducer())
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className="login-form" style={{ display: 'block' }}>

      <AuthHeader
        title={<FormattedMessage id="AUTH.FORGOT.TITLE" />}
        subtitle={intl({ id: 'AUTH.FORGOT.TITLE_TEXT' }).split("#").map((item, i) =>
          ["href=", "<b>"].some(el => item.includes(el))
            ? <FormattedHTMLMessage key={i} id={item} values={{ what: 'react-intl', a: chunks => <a href='/'>{chunks}</a>, b: chunks => <strong>{chunks}</strong> }} />
            : <span key={i}>{item}</span>
        )}
      />

      <Formik enableReinitialize={true} initialValues={{ email: '' }} validationSchema={ForgotPasswordSchema} onSubmit={handleSubmit}>
        <Form>

          <Field component={SVFormControl}
            {...{
              type: "email",
              name: "email",
              label: intl({ id: 'AUTH.INPUT.EMAIL' }),
              withFeedbackLabel: true,
              feedbackLabel: true,
              disabled: isLoading || requestResult() === 'success',
            }}
          />

          <Button variant='primary' type="submit" className="font-weight-bold w-100 py-4 my-3" disabled={isLoading || requestResult() === 'success'} id='btn_forgot'>
            <FormattedMessage id="GENERAL.SEND_EMAIL" />
            <Spinner loading={isLoading} />
          </Button>

        </Form>
      </Formik>


      <div className='d-flex text-center my-4 my-lg-6'>
        <Link to={AuthRoutes.LOGIN} className="text-hover-primary font-weight-bold w-100" id='btn_back_login'>
          <FormattedMessage id="AUTH.GENERAL.BACK_TO_LOGIN" /> {requestResult() === 'fulfilled' && `(${timer})`}
        </Link>
      </div>

      {requestResult() === 'fulfilled' &&
        <CustomAlert
          type="success"
          title={intl({ id: 'GENERAL.TEAM' })}
          message={intl({ id: "AUTH.FORGOT.CHECK_MAIL" })}
        />
      }

      {requestResult() === 'failed' &&
        <CustomAlert
          type="danger"
          title={intl({ id: 'AUTH.GENERAL.ERRORS.TITLE' })}
          message={intl({ id: `${error}` })}
          onClose={() => dispatch(AuthActions.cleanUpReducer())}
        />
      }

    </div>
  );
}