import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Col, Row } from "react-bootstrap";
import { Card, CardBody, CardSubHeader, SVFormControl, SVReactSelect } from "_metronic/_partials/controls";
import { isArray } from "lodash";
import { Field } from "formik";



export default function KauflandPolicies({ market_id, loading }) {

  const { formatMessage: intl } = useIntl();

  const { /*shop,*/ shippingGroups, warehouses } = useSelector(state => ({
    // shop: state.listings.marketPlaces,
    shippingGroups: state.listings.shippingGroups,
    warehouses: state.listings.warehouses,
  }), shallowEqual);

  const INPUTS = [
    {
      name: 'id_shipping',
      label: intl({ id: 'PRODUCT_LISTINGS.SHIPPING_GROUP' }),
      component: SVReactSelect,
      options: isArray(shippingGroups) &&
        shippingGroups.map(p => ({
          value: p.id_shipping_group,
          label: p.name,
        })),
      required: true,
    },
    {
      name: 'id_warehouse',
      label: 'PRODUCT.EBAY.LOCATION',
      component: SVReactSelect,
      options:
        isArray(warehouses) &&
        warehouses.map(p => ({
          value: p.id_warehouse,
          label: p.name,
        })),
      required: true,
    },
  ];

  // const getPolicies = () => {
  //     dispatch(ListingActions.updateEbayPolicies(market_id));
  // };

  return (
    <Card className="gutter-b">
      <CardSubHeader title={<FormattedMessage id="PRODUCT_LISTINGS.EBAY.POLICIES" />}>
        {/* <CardHeaderToolbar>
              <Button size="sm" variant='outline-primary' className="svg-icon svg-icon-sm" onClick={getPolicies} id='btn_policies'>
                  <Icon.ArrowRepeat />
                  <span className="d-none d-sm-inline font-weight-bold ml-2">
                    <FormattedMessage id="PRODUCT_LISTINGS.EBAY.POLICIES" />
                  </span>
              </Button>
            </CardHeaderToolbar> */}
      </CardSubHeader>

      <CardBody>
        <Row>
          {INPUTS.map((item, i) => (
            <Col sm="12" lg="6" key={i}>
              <Field
                component={item.component || SVFormControl}
                {...{
                  type: item.type,
                  name: item.name,
                  label: intl({ id: item.label }),
                  onFocus: item.onFocus,
                  withFeedbackLabel: true,
                  options: item.options,
                  feedbackLabel: true,
                  required: item.required,
                  disabled: loading,
                }}
              />
            </Col>
          ))}
        </Row>
      </CardBody>

    </Card>
  )
}
