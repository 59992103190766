import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isUndefined } from 'lodash';



const SettingsEditContext = createContext();

export const SettingsUIConsumer = SettingsEditContext.Consumer;

export const useSettingsEditContext = () => {
  return useContext(SettingsEditContext);
};


export function SettingsUIProvider({ children }) {

  const [connectionName, setConnectionName] = useState(null);
  const [productLists, setProductLists] = useState([]);
  const [layouts, setLayouts] = useState([]);

  //* Deutsche Post Configs
  const { deutschePostConfigs } = useSelector(state => ({
    deutschePostConfigs: state.connections.deutschePostConfigs,
  }));

  useEffect(() => {
    if (
      !isEmpty(deutschePostConfigs) &&
      !isUndefined(deutschePostConfigs.dpProductList) &&
      !isUndefined(deutschePostConfigs.dpLayout)
    ) {
      const layoutOptions = deutschePostConfigs.dpLayout.map(layout => ({
        value: layout.id,
        label: layout.name,
      }));
      setProductLists(deutschePostConfigs.dpProductList);
      setLayouts(layoutOptions);
    }
  }, [deutschePostConfigs]);


  // Fetch Orders Dialog
  const [showFetchOrdersDialog, setShowFetchOrdersDialog] = useState(false);
  const openFetchOrdersDialog = (name) => {
    setConnectionName(name)
    setShowFetchOrdersDialog(true)
  };
  const closeFetchOrdersDialog = () => {
    setConnectionName(null)
    setShowFetchOrdersDialog(false)
  };

  // Connections add Dialog
  const [connection, setConnection] = useState({ connectionName: null, connectionId: null });
  const [showConnectionAddDialog, setShowConnectionAddDialog] = useState(false);
  const openConnectionAddDialog = (connectionName, connectionId) => {
    setConnection({ connectionName, connectionId })
    setShowConnectionAddDialog(true)
  };
  const closeConnectionAddDialog = () => setShowConnectionAddDialog(false);

  const value = {
    connectionName,
    productLists,
    layouts,

    showFetchOrdersDialog,
    openFetchOrdersDialog,
    closeFetchOrdersDialog,

    connection,
    showConnectionAddDialog,
    openConnectionAddDialog,
    closeConnectionAddDialog,
  };

  return (
    <SettingsEditContext.Provider value={value}>
      {children}
    </SettingsEditContext.Provider>
  );
}
