import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { AnimateLoading } from "../../../_partials/loading";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { LanguageSelectorDropdown } from "./LanguageSelectorDropdown";
import { OrderNotification } from "./OrderNotification";
import { TicketDropdown } from "./TicketDropdown";
import { AccountDetails } from "./AccountDetails";
import { Notifications } from "./Notifications";
import objectPath from "object-path";
// import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";



export function Header() {

  const UIService = useHtmlClassService();
  const layoutProps = useMemo(() => ({
    headerClasses: UIService.getClasses("header", true),
    headerAttributes: UIService.getAttributes("header"),
    headerContainerClasses: UIService.getClasses("header_container", true),
    menuHeaderDisplay: objectPath.get(UIService.config, "header.menu.self.display"),
    viewLanguagesDisplay: objectPath.get(UIService.config, "extras.languages.display"),
    viewSearchDisplay: objectPath.get(UIService.config, "extras.search.display"),
  }), [UIService]);

  const { role, connections } = useSelector(state => ({
    role: state.auth.user.role,
    connections: state.connections.entities
  }));


  return (
    <>
      {/*begin::Header*/}
      <div className={`header ${layoutProps.headerClasses}`} id="kt_header" {...layoutProps.headerAttributes}>
        {/*begin::Container*/}
        <div className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}>

          <AnimateLoading />

          {/*begin::Topbar*/}
          <div className="topbar w-100 justify-content-end">

            {/* {layoutProps.viewSearchDisplay && <SearchDropdown />} */}

            <TicketDropdown />

            {(role !== "644c41e257d397eb064fe0f9" && connections?.length > 0) && <OrderNotification />}

            {role !== "644c41e257d397eb064fe0f9" && <Notifications connections={connections} />}

            {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />}

            <AccountDetails />

          </div>
          {/*end::Topbar*/}

        </div>
        {/*end::Container*/}
      </div>
      {/*end::Header*/}
    </>
  );
}
