import React from 'react'
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import { LastLoginFn } from '../../user-table/LastLoginFn';
import { getConnection } from 'constants/connectionSettings';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { flags, UIDates } from "_metronic/_helpers";
import { Icon } from '_metronic/_icons';
//import { Button } from 'react-bootstrap';
//import { API } from 'constants/apiUrl';
//import { useSelector } from 'react-redux';
//import { toast } from 'react-toastify';



export default function Info({ userBaseData, connections }) {

  const { formatMessage: intl } = useIntl();

  const connection = ['marketplace', 'shop', 'shipper'].map((type) => {
    const filteredItems = connections?.filter(item => item.type === type);
    if (!filteredItems || filteredItems.length === 0) return null;
    return (
      <div key={type} className='d-flex mr-3'>
        {filteredItems.map((item, i) => (
          <OverlayTrigger key={item.market_name + i} overlay={<Tooltip id="shipping-tooltip"><div className="font-weight-bolder">{item?.market_name}</div></Tooltip>}>
            <span className="symbol d-flex mr-3 bg-white svg-icon svg-icon-xl">
              {getConnection(item.name)?.icon}
            </span>
          </OverlayTrigger>
        ))}
      </div>
    );
  });

  const currentLanguage = flags.find((x) => x.lang === userBaseData?.language);
  const { newsletter, fail_log, success_login, license } = userBaseData;
  // const accessToken = useSelector(state => state.auth.access_token);

  /* const handleShippingMigration = async () => {
      try {
        const data = await API.get(`/migrations-shipping/${userBaseData.id}`, {
          headers: { Authorization: `Bearer ${accessToken}` }
        });
  
        if (data.status === 204) {
          toast.success('Shipping migration initiated successfully');
        }
  
      } catch (error) {
        toast.error('An error occurred while initiating shipping migration');
        console.error('An error occurred while initiating shipping migration:', error);
      }
    }; */

  const USER_INFO = [
    {
      label: "ADMIN.LANGUAGE",
      content: <>
        <span className="svg-icon svg-icon-md mr-2">{currentLanguage?.flag}</span>
        <span>{currentLanguage?.name}</span>
      </>,
    },
    {
      label: "Newsletter",
      content: <label className={`label label-lg label-inline label-rounded text-lowercase label-outline-${userBaseData?.newsletter ? 'success' : 'danger'}`}>
        {intl({ id: newsletter ? 'ADMIN.TEMP_USER.SUBSCRIBED' : 'ADMIN.TEMP_USER.NOT_SUBSCRIBED' })}
      </label>
    },
    success_login?.time && {
      label: "ADMIN.USER.LAST_LOGIN",
      content: LastLoginFn(success_login?.time),
    },
    success_login?.count !== 0 && {
      label: "ADMIN.USER.LOGIN_COUNT",
      content: <>
        <span className='text-success mr-1'>{success_login?.count}</span>
        {fail_log?.login?.count > 0 && <span className='text-danger'> / {fail_log?.login?.count}</span>}
      </>
    },
    {
      label: "GENERAL.REGISTRATION_DATE",
      content: UIDates.formatDateTime(license?.updated_at),
    },
    {
      label: "MENU.ASIDE.CONNECTIONS",
      icon: <Icon.Connection />,
      content:
        <div className="symbol-group justify-content-end">
          {connections?.length !== 0 ? connection : <FormattedMessage id="ADMIN.USER.NO_CONNECTION" />}
        </div>
    },
    /* ,{
      label: "GENERAL.SYNC",
      content: <Button variant="danger" onClick={handleShippingMigration}>WithoutTrackingId Shipping Migrations</Button>
    }, */
  ].filter(Boolean);


  return (
    <Card className="card-stretch gutter-b">

      <CardSubHeader icon={<Icon.PersonInfo />} title={<FormattedMessage id="ADMIN.USER.INFO" />} />

      <CardBody>
        {USER_INFO?.length && (
          <div className="d-flex flex-column py-2 px-4">
            {USER_INFO?.map((item, i) => (
              <h6 className={`d-flex align-items-start flex-column flex-md-row ${USER_INFO?.length - 1 === i ? "" : "mb-4"}`} key={i}>
                <span className="text-dark-50 mw-200px mb-3">
                  {item.label && <FormattedMessage id={item.label} />}
                </span>
                <span>
                  {item.content}
                </span>
              </h6>
            ))}
          </div>
        )}
      </CardBody>

    </Card>
  );
}
