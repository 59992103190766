import React from 'react';
import { Icon } from '_metronic/_icons';
import { FormattedMessage } from 'react-intl';



export const allQueryKeys = ['status', 'search', 'tag', 'priority'];


export const initialValues = {
  subject: '',
  message: '',
}


export const TICKET_STATUS = [
  {
    value: "open",
    label: <FormattedMessage id="GENERAL.OPEN" />,
    action: <FormattedMessage id="SUPPORT.OPEN_TICKET" />,
    className: 'danger',
  },
  {
    value: "reopened",
    label: <FormattedMessage id="GENERAL.REOPENED" />,
    action: <FormattedMessage id="SUPPORT.REOPEN_TICKET" />,
    className: 'danger',
  },
  {
    value: "cancelled",
    label: <FormattedMessage id="GENERAL.CANCELLED" />,
    action: <FormattedMessage id="SUPPORT.CANCEL_TICKET" />,
    className: 'warning',
  },
  {
    value: "archived",
    label: <FormattedMessage id="GENERAL.ARCHIVED" />,
    action: <FormattedMessage id="SUPPORT.ARCHIVE_TICKET" />,
    className: 'light',
  },
  {
    value: "completed",
    label: <FormattedMessage id="GENERAL.COMPLETED" />,
    action: <FormattedMessage id="SUPPORT.COMPLETE_TICKET" />,
    className: 'success',
  },
];


export const TICKET_STATUS_FILTER = [
  ...TICKET_STATUS,
  {
    value: "all_tickets",
    label: <FormattedMessage id="SUPPORT.FILTER.ALL_TICKETS" />,
    className: 'secondary',
  },
]


export const TICKET_TAG = [
  {
    value: "bug",
    label: <FormattedMessage id="SUPPORT.FILTER.TAG.BUG" />,
    className: 'danger',
  },
  {
    value: "feature",
    label: <FormattedMessage id="SUPPORT.FILTER.TAG.FEATURE" />,
    className: 'success',
  },
  {
    value: "marketing",
    label: <FormattedMessage id="SUPPORT.FILTER.TAG.MARKETING" />,
    className: 'warning',
  },
  {
    value: "recommendation",
    label: <FormattedMessage id="SUPPORT.FILTER.TAG.RECOMMENDATION" />,
    className: 'success',
  },
  {
    value: "other",
    label: <FormattedMessage id="SUPPORT.FILTER.TAG.OTHER" />,
    className: 'secondary',
  },
];


export const TICKET_TAG_FILTER = [
  ...TICKET_TAG,
  {
    value: "all_tags",
    label: <FormattedMessage id="SUPPORT.FILTER.ALL_TAGS" />,
    className: 'secondary',
  },
]


export const TICKET_PRIORITY = [
  {
    value: "highest",
    label: <FormattedMessage id="SUPPORT.FILTER.PRIORITY.HIGHEST" />,
    className: 'danger',
    icon: <Icon.ChevronDoubleUp />,
  },
  {
    value: "high",
    label: <FormattedMessage id="SUPPORT.FILTER.PRIORITY.HIGH" />,
    className: 'warning',
    icon: <Icon.ChevronUp />,
  },
  {
    value: "medium",
    label: <FormattedMessage id="SUPPORT.FILTER.PRIORITY.MEDIUM" />,
    className: 'primary',
    icon: <Icon.Dash />,
  },
  {
    value: "low",
    label: <FormattedMessage id="SUPPORT.FILTER.PRIORITY.LOW" />,
    className: 'info',
    icon: <Icon.ChevronDown />,
  },
  {
    value: "lowest",
    label: <FormattedMessage id="SUPPORT.FILTER.PRIORITY.LOWEST" />,
    className: 'info',
    icon: <Icon.ChevronDoubleDown />,
  },
];


export const TICKET_PRIORITY_FILTER = [
  ...TICKET_PRIORITY,
  {
    value: "all_priorities",
    label: <FormattedMessage id="SUPPORT.FILTER.ALL_PRIORITIES" />,
    className: 'secondary',
  },
]


export const TICKET_READ = [
  {
    value: "unread",
    label: <FormattedMessage id="SUPPORT.FILTER.READ.UNREAD" />,
    className: 'primary',
  },
  {
    value: "read",
    label: <FormattedMessage id="SUPPORT.FILTER.READ.READ" />,
    className: 'success',
  },
];


export const TICKET_READ_FILTER = [
  ...TICKET_READ,
  {
    value: "all_read",
    label: <FormattedMessage id="SUPPORT.FILTER.ALL_READ" />,
    className: 'secondary',
  },
]


export const TICKET_TOPIC = [
  {
    value: "dashboard",
    label: <FormattedMessage id="MENU.ASIDE.DASHBOARD" />
  },
  {
    value: "order",
    label: <FormattedMessage id="DOCUMENT.ORDER_TITLE" />
  },
  {
    value: "offer",
    label: <FormattedMessage id="DOCUMENT.OFFER_TITLE" />
  },
  {
    value: "invoice",
    label: <FormattedMessage id="DOCUMENT.INVOICE_TITLE" />
  },
  {
    value: "credit",
    label: <FormattedMessage id="DOCUMENT.CREDIT_TITLE" />
  },
  {
    value: "waybill",
    label: <FormattedMessage id="DOCUMENT.WAYBILL_TITLE" />
  },
  {
    value: "archive",
    label: <FormattedMessage id="MENU.ASIDE.SETTING.ARCHIVE" />
  },
  {
    value: "label",
    label: <FormattedMessage id="GENERAL.LABEL" />
  },
  {
    value: "customer",
    label: <FormattedMessage id="CUSTOMER_TITLE" />
  },
  {
    value: "supplier",
    label: <FormattedMessage id="PRODUCT.GENERAL.SUPPLIER" />
  },
  {
    value: "product",
    label: <FormattedMessage id="PRODUCT.TITLE" />
  },
  {
    value: "stock",
    label: <FormattedMessage id="SUPPORT.FILTER.TOPIC.STOCK" />
  },
  {
    value: "import_export",
    label: <FormattedMessage id="MENU.ASIDE.IMPORT_EXPORT" />
  },
  {
    value: "connection",
    label: <FormattedMessage id="MENU.ASIDE.CONNECTIONS" />
  },
  {
    value: "settings",
    label: <FormattedMessage id="SETTINGS.TITLE" />
  },
  {
    value: "filter",
    label: <FormattedMessage id="DOCUMENT.FILTER.CONTENT" />
  },
  {
    value: "function",
    label: <FormattedMessage id="SUPPORT.FILTER.TOPIC.FUNCTION" />
  },
  {
    value: "general",
    label: <FormattedMessage id="GENERAL.GENERAL" />
  },
  {
    value: "support",
    label: <FormattedMessage id="MENU.ASIDE.SUPPORT" />
  },
  {
    value: "test",
    label: <FormattedMessage id="SUPPORT.FILTER.TOPIC.TEST" />
  },
  {
    value: "other",
    label: <FormattedMessage id="GENERAL.OTHER" />
  }
];



export const getTopicLabel = (topic) => {
  return TICKET_TOPIC.find(item => item.value === topic)
}