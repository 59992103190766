import { select, call, put, takeEvery } from "redux-saga/effects";
import { supplierMiddleware, tokenErrorHandler } from "middlewares";
import { SupplierActions } from "./actions";
import actionTypes from "./actionTypes";



// Create Delivery
function* createSupplier({ payload: { supplier } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(supplierMiddleware.createSupplier, accessToken, supplier);
    yield put(SupplierActions.createSupplierSuccess(response.data));

  } catch (error) {
    yield call(tokenErrorHandler, error, SupplierActions.createSupplierFail());
  }
}


// Update Delivery
function* updateSupplier({ payload: { supplier } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(supplierMiddleware.updateSupplier, accessToken, supplier);
    yield put(SupplierActions.updateSupplierSuccess(response.data));

  } catch (error) {
    yield call(tokenErrorHandler, error, SupplierActions.updateSupplierFail());
  }
}


// Get Deliveries
function* getSupplier({ payload: { queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const countSupplier = yield call(supplierMiddleware.getCount, accessToken, queryParams);
    const response = yield call(supplierMiddleware.getSupplier, accessToken, queryParams);

    yield put(SupplierActions.getSupplierSuccess(response.data, countSupplier.data));

  } catch (error) {
    yield call(tokenErrorHandler, error, SupplierActions.getSupplierFail());
  }
}


// Get delivery by id
function* getSupplierById({ payload: { id } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(supplierMiddleware.getSupplierById, accessToken, id);
    yield put(SupplierActions.getSupplierByIdSuccess(response.data));

  } catch (error) {
    yield call(tokenErrorHandler, error, SupplierActions.getSupplierByIdFail());
  }
}


// Delete Delivery
function* deleteSupplier({ payload: { ids } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(supplierMiddleware.deleteSuppliers, accessToken, ids);

    yield put(SupplierActions.deleteSupplierSuccess(ids));

  } catch (error) {
    yield call(tokenErrorHandler, error, SupplierActions.deleteSupplierFail());
  }
}


// Find Supplier By Property
function* findSupplierByProperty({ payload: { key, value, supplierId } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const customer = yield call(supplierMiddleware.findSupplier, accessToken, key, value, supplierId);
    yield put(SupplierActions.findSupplierByPropertySuccess(customer.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, SupplierActions.findSupplierByPropertyFail());
  }
}


export function* supplierSaga() {
  yield takeEvery(actionTypes.CREATE_SUPPLIER, createSupplier);
  yield takeEvery(actionTypes.UPDATE_SUPPLIER, updateSupplier);
  yield takeEvery(actionTypes.GET_SUPPLIER, getSupplier);
  yield takeEvery(actionTypes.GET_SUPPLIER_BY_ID, getSupplierById);
  yield takeEvery(actionTypes.DELETE_SUPPLIER, deleteSupplier);
  yield takeEvery(actionTypes.FIND_SUPPLIER_BY_PROPERTY, findSupplierByProperty);
}
