import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardSubHeader, SVFormControl } from "_metronic/_partials/controls";
import { SupplierActions } from "../../_redux/actions";
import { FormattedMessage, useIntl } from "react-intl";
import { Field, useFormikContext } from "formik";
import { Col, Row } from "react-bootstrap";
import { Icon } from "_metronic/_icons";



export function General() {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();
  const { values: { email, id } } = useFormikContext();

  const { findSupplier } = useSelector(state => ({
    findSupplier: state.suppliers.findSupplierForMatching
  }));

  const INPUTS = [
    {
      id: "supplier_name",
      name: "name",
      label: "GENERAL.NAME",
      required: true,
    },
    {
      name: "company",
      label: "GENERAL.COMPANY",
      size: '8',
      required: true,
    },
    {
      type: "email",
      name: "email",
      label: "GENERAL.EMAIL",
      feedbackLabel: findSupplier.length > 0
        ? intl({ id: "GENERAL.EMAIL_HAVE" })
        : true,
      required: true,
    },
    {
      type: "tel",
      name: "phone",
      label: "GENERAL.PHONE",
    },
    {
      type: "tel",
      name: "mobile",
      label: "GENERAL.MOBILE",
    },
    {
      type: "tel",
      name: "fax",
      label: "GENERAL.FAX",
    },
    {
      name: "tax_number",
      label: "GENERAL.TAX_NUMBER",
    },
    {
      name: "vat_id",
      label: "GENERAL.US_NUMBER",
    },
  ];

  useEffect(() => {
    let delayFindSupplierByProperty;
    delayFindSupplierByProperty = setTimeout(() => {
      email && dispatch(SupplierActions.findSupplierByProperty('email', email, id));
    }, 500);

    return () => {
      clearTimeout(delayFindSupplierByProperty)
    };
  }, [id, dispatch, email]);

  return (
    <Card className="gutter-b">

      <CardSubHeader icon={<Icon.Tag />} title={<FormattedMessage id="GENERAL.GENERAL" />} />

      <CardBody>
        <Row>
          {INPUTS.map((item, i) => (
            <Col key={i} lg={item.size ?? "4"}>
              <Field component={SVFormControl}
                {...{
                  ...(item.id && { id: item.id }),
                  name: item.name,
                  type: item.type,
                  label: intl({ id: item.label }),
                  withFeedbackLabel: true,
                  feedbackLabel: item.feedbackLabel || true,
                  required: item.required
                }}
              />
            </Col>
          ))}
        </Row>
      </CardBody>
    </Card>
  );
}
