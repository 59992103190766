import React, { useContext, createContext, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { AdminRoutes } from 'constants/moduleRoutes';
import { useSearchQuery } from 'app/hooks';



const TempUserUIContext = createContext({});
export const useTempUserUIContext = () => useContext(TempUserUIContext);



export const TempUserUIProvider = ({ children }) => {

  const history = useHistory();
  const [ids, setIds] = useState([]);

  // query params
  const [queryParams, setQueryBase] = useState({
    pagination: {
      page: 1,
      limit: 10,
    },
    order: 'id DESC',
    search: '',
    filter: null,
  });

  const setQueryParams = useCallback((params) => setQueryBase(params), [])

  const { setSearchQuery, setFilterQuery, setPaginationQuery, setOrderQuery } = useSearchQuery(setQueryParams);

  const value = {
    ids,
    setIds,
    queryParams,
    setSearchQuery,
    setFilterQuery,
    setPaginationQuery,
    setOrderQuery,
    openDeleteTempUserDialog: id => history.push(AdminRoutes.DELETE_TEMP_USER_FN(id)),
    openEditTempUserDialog: id => history.push(AdminRoutes.ADMIN_TEMP_USERS_EDIT_FN(id)),
  };


  return (
    <TempUserUIContext.Provider value={value}>
      {children}
    </TempUserUIContext.Provider>
  );
};