export const INITIAL_SHIPPING = {
  type: '',
  service: '',
  label: '',
  tracking_id: '',
  delivery_address: {},
  relation_documents: [],
  price: '',
  weight: '',
  error: '',
  delivery_date: null,
};

export const defaultSorted = [{ dataField: 'id', order: 'desc' }];