
export const documentsOrder = {
    main: {
        location: 'orders',
        title: "DOCUMENT.ORDER_PLURAL",
        description: "USER_GUIDE.DOCUMENT.ORDER",
    },
    new: {
        title: "DOCUMENT.NEW",
        description: "USER_GUIDE.DOCUMENT.NEW",
        name: 'DOCUMENT.ORDER_TITLE',
    },
    edit: {
        title: "DOCUMENT.UPDATE_TITLE",
        description: "USER_GUIDE.DOCUMENT.EDIT",
        name: 'DOCUMENT.ORDER_TITLE',
    }
}

export const documentsOffer = {
    main: {
        location: 'offers',
        title: "DOCUMENT.OFFER_PLURAL",
        description: "USER_GUIDE.DOCUMENT.OFFER",
        name2: 'DOCUMENT.OFFER_PLURAL',
        name3: 'DOCUMENT.OFFER_TITLE',
    },
    new: {
        title: "DOCUMENT.NEUES",
        description: "USER_GUIDE.DOCUMENT.NEW",
        name: 'DOCUMENT.OFFER_TITLE',
    },
    edit: {
        title: "DOCUMENT.UPDATE_TITLE",
        description: "USER_GUIDE.DOCUMENT.EDIT",
        name: 'DOCUMENT.OFFER_TITLE',
    }
}

export const documentsInvoice = {
    main: {
        location: 'invoices',
        title: "DOCUMENT.INVOICE_PLURAL",
        description: "USER_GUIDE.DOCUMENT.INVOICE",
        name2: 'DOCUMENT.INVOICE_PLURAL',
        name3: 'DOCUMENT.INVOICE_TITLE',
    },
    new: {
        title: "DOCUMENT.NEW",
        description: "USER_GUIDE.DOCUMENT.NEW",
        name: 'DOCUMENT.INVOICE_TITLE',
    },
    edit: {
        title: "DOCUMENT.UPDATE_TITLE",
        description: "USER_GUIDE.DOCUMENT.EDIT",
        name: 'DOCUMENT.INVOICE_TITLE',
    }
}

export const documentsCredit = {
    main: {
        location: 'credits',
        title: "DOCUMENT.CREDIT_PLURAL",
        description: "USER_GUIDE.DOCUMENT.CREDIT",
        name2: 'DOCUMENT.CREDIT_PLURAL',
        name3: 'DOCUMENT.CREDIT_TITLE',
    },
    new: {
        title: "DOCUMENT.NEW",
        description: "USER_GUIDE.DOCUMENT.NEW",
        name: 'DOCUMENT.CREDIT_TITLE',
    },
    edit: {
        title: "DOCUMENT.UPDATE_TITLE",
        description: "USER_GUIDE.DOCUMENT.EDIT",
        name: 'DOCUMENT.CREDIT_TITLE',
    }
}

export const documentsWaybill = {
    main: {
        location: 'waybills',
        title: "DOCUMENT.WAYBILL_PLURAL",
        description: "USER_GUIDE.DOCUMENT.WAYBILL",
        name2: 'DOCUMENT.WAYBILL_PLURAL',
        name3: 'DOCUMENT.WAYBILL_TITLE',
    },
    new: {
        title: "DOCUMENT.NEUER",
        description: "USER_GUIDE.DOCUMENT.NEW",
        name: 'DOCUMENT.WAYBILL_TITLE',
    },
    edit: {
        title: "DOCUMENT.UPDATE_TITLE",
        description: "USER_GUIDE.DOCUMENT.EDIT",
        name: 'DOCUMENT.WAYBILL_TITLE',
    }
}

export const documentsOrderList = {
    main: {
        location: 'order-list',
        title: "REPORT.ORDER_LIST.CARD_TITLE",
        description: "USER_GUIDE.DOCUMENT.ORDER_LIST",

    },

}





