import React, { useRef } from 'react'
import { AuthLayout, ContentRoute } from '_metronic/layout'
import { AuthRoutes, BaseRoutes } from 'constants/moduleRoutes'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Login, Registration, ResetPassword, ForgotPassword, TermsConditions, Privacy, Imprint,  /*MaintenanceMode,*/ } from 'app/pages'
import ReCAPTCHA from 'react-google-recaptcha'



export default function AuthPage() {
  const recaptchaRef = useRef();
  return (
    <Route>

      <AuthLayout>
        <Switch>
          <ContentRoute path={AuthRoutes.LOGIN} render={() => <Login recaptchaRef={recaptchaRef} />} />
          <ContentRoute path={AuthRoutes.REGISTER} render={() => <Registration recaptchaRef={recaptchaRef} />} />
          <ContentRoute path={AuthRoutes.FORGOT_PASSWORD} render={() => <ForgotPassword recaptchaRef={recaptchaRef} />} />
          <ContentRoute path={AuthRoutes.RESET_PASSWORD} render={() => <ResetPassword recaptchaRef={recaptchaRef} />} />

          <ContentRoute path={BaseRoutes.TERMS} component={TermsConditions} />
          <ContentRoute path={BaseRoutes.PRIVACY} component={Privacy} />
          <ContentRoute path={BaseRoutes.IMPRINT} component={Imprint} />

          {/* <ContentRoute path={BaseRoutes.MAINTENANCE} component={MaintenanceMode} /> */}

          <Redirect from={AuthRoutes.path} exact={true} to={AuthRoutes.LOGIN} />
          <Redirect to={AuthRoutes.LOGIN} />
        </Switch>
      </AuthLayout>

      <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey="6LcUMn4kAAAAAMyKnzPddzF6GeT-geT43f8lkjoT" />

    </Route>
  )
}