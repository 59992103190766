import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { SuppliersPage } from './SuppliersPage';
import { ContentRoute } from '_metronic/layout';
import { SuppliersEdit } from './supplier-edit/SuppliersEdit';
import { ModuleRoutes, RELATIVE_PATH } from 'constants/moduleRoutes';
import { SupplierUIProvider } from './_context/SupplierUIContext';



export default function Suppliers() {
  return (
    <SupplierUIProvider>

      <Switch>
        <Redirect exact={true} from={RELATIVE_PATH} to={ModuleRoutes.SUPPLIER} />
        <ContentRoute path={ModuleRoutes.NEW_SUPPLIER} component={SuppliersEdit} />
        <ContentRoute path={ModuleRoutes.EDIT_SUPPLIER} component={SuppliersEdit} />
        <ContentRoute path={ModuleRoutes.SUPPLIER} component={SuppliersPage} />
      </Switch>

    </SupplierUIProvider>
  );
}