import React from 'react'



export default function AuthHeader({ title, subtitle }) {
  return (
    <div className="text-center mb-10 mb-lg-20">
      <h1 className="mb-3">
        {title}
      </h1>
      <h6 className="text-dark-50 font-weight-bold">
        {subtitle}
      </h6>
    </div>
  );
}
