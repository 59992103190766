import React from 'react';
import { Modal } from 'react-bootstrap';
import { CloseButton } from '_metronic/_partials';
import { Privacy, TermsConditions } from 'app/pages';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Portal } from 'react-portal';



export default function Contract({ show, onHide, contract }) {
  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={show} onHide={onHide} size='md' centered>

        <CloseButton className="modal-close-btn text-white text-hover-primary icon-xl" onClick={onHide} />

        <Modal.Body className='p-8'>
          <PerfectScrollbar className="scroll pr-5 mr-n5" style={{ maxHeight: '70vh' }}>

            <div className='text-center my-15'>
              <img width="200px" height="auto" src="/media/logo/salevali-logo-dark-beta.svg" alt="logo" />
            </div>

            {contract === 'privacy' && <Privacy />}
            {contract === 'conditions' && <TermsConditions />}

          </PerfectScrollbar>

        </Modal.Body>

      </Modal>
    </Portal>
  )
}
