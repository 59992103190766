import { INITIAL_DIGITAL_PRODUCT } from '../_context/DigitalProductsUIHelpers';
import ActionTypes from './actionTypes';



const INITIAL_STATE = {
  loading: false,
  customerLoading: false,
  emailSuccess: false,
  entities: [],
  countDigitalProduct: 0,
  totalDigitalProduct: 0,
  digitalProductForEdit: INITIAL_DIGITAL_PRODUCT,
  customerDetails: {},
  error: '',
};


export function digitalProductReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {

    // Get Digital Products
    case ActionTypes.GET_DIGITAL_PRODUCTS:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_DIGITAL_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        entities: payload.digitalProducts,
        countDigitalProduct: payload.count.count,
        totalDigitalProduct: payload.count.total,
      };

    case ActionTypes.GET_DIGITAL_PRODUCTS_FAIL:
      return {
        ...state,
        loading: false,
        error: 'GENERAL.ERROR_MESSAGE_GENERAL',
      };



    //* get customer details
    case ActionTypes.GET_CUSTOMER_DETAILS:
      return {
        ...state,
        customerLoading: true,
      };

    case ActionTypes.GET_CUSTOMER_DETAILS_SUCCESS:
      return {
        ...state,
        customerLoading: false,
        customerDetails: payload.data.customer_details,
      };

    case ActionTypes.GET_CUSTOMER_DETAILS_FAIL:
      return {
        ...state,
        customerLoading: false,
        error: 'GENERAL.ERROR_MESSAGE',
      };



    //* send mail key
    case ActionTypes.SEND_MAIL_KEY:
      return {
        ...state,
        loading: true,
        emailSuccess: false,
      };

    case ActionTypes.SEND_MAIL_KEY_SUCCESS:
      return {
        ...state,
        loading: false,
        emailSuccess: true,
      };

    case ActionTypes.SEND_MAIL_KEY_FAIL:
      return {
        ...state,
        loading: false,
        emailSuccess: false,
        error: 'GENERAL.ERROR_MESSAGE',
      };



    case ActionTypes.CLEAN_STATES_IN_STORE:
      return {
        ...state,
        ...payload.cleanedStates,
        success: false,
      };

    default:
      return state;
  }
}