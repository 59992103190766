import React from 'react';
import { Formik } from 'formik';

const formInputs = [
  {
    name: 'sku',
    label: 'SKU',
    type: 'text',
    placeholder: 'Enter SKU'
  },
  {
    name: 'price',
    label: 'Price',
    type: 'number',
    placeholder: 'Enter Price'
  },
  {
    name: 'quantity',
    label: 'Quantity',
    type: 'number',
    placeholder: 'Enter Quantity'
  },
  {
    name: 'condition',
    label: 'Condition',
    type: 'select',
    options: [{ value: 'new_new', label: 'New' }]
  },
  {
    name: 'fulfillmentStrategy',
    label: 'Fulfillment strategy',
    type: 'select',
    options: [
      { value: 'FBM', label: 'Merchant Fulfilled' },
      { value: 'FBA', label: 'Amazon Fulfilled' }
    ]
  }
];

export function AmazonListingsAddProductForm({ onAddListingToAmazon, initialValues, item }) {
  return (
    <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={(values, actions) => onAddListingToAmazon(values, actions)}>
      {({ handleSubmit, handleChange, handleBlur, values }) => (
        <form onSubmit={handleSubmit}>
          {/* Hidden inputs for ASIN and product type */}
          <input type="text" name="asin" value={item.asin} readOnly hidden />
          <input type="text" name="productType" value={item.productType} readOnly hidden />

          {/* Render form inputs dynamically */}
          {formInputs.map((input) => (
            <div className="form-group" key={`key__${input.name}`}>
              {input.label && <label>{input.label}</label>}
              {input.type === 'select' ? (
                <select className="form-control" name={input.name} onChange={handleChange} onBlur={handleBlur} value={values[input.name]}>
                  {input.options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type={input.type}
                  className="form-control"
                  name={input.name}
                  placeholder={input.placeholder}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values[input.name]}
                />
              )}
            </div>
          ))}
          <button type="submit" className="btn btn-primary">
            Save and Listing
          </button>
        </form>
      )}
    </Formik>
  );
}
