
export const searchKeys = [
  "order_id",
  "order_number",
  "marketplace",
  "marketplace_url",
  "sku",
  "key",
  "product",
  "name",
  "email",
  "email_sended_date ",
  "order_date "
];

export const fieldDigitalProduct = [
  "id",
  "order_id",
  "order_number",
  "marketplace",
  "marketplace_url",
  "sku",
  "key",
  "product",
  "name",
  "email",
  "email_sended_date",
  "email_sended_count",
  "confirmation_date",
  "order_date"
];