import React from 'react';
import Select, { components } from 'react-select';
import { SVFeedbackLabel, SVFormControlLabel, SVFormControlWrapper } from './SVFormHelpers';
import UserInfoDropdown from '_metronic/_partials/user-info/UserInfoDropdown';
import { FormattedMessage } from 'react-intl';
import { theme } from '_metronic/_helpers';
import styled from 'styled-components';




const NoOptionsMessage = props => {
  return (
    <components.NoOptionsMessage {...props}>
      {props?.children
        ? props?.children
        : <FormattedMessage id="GENERAL.NO_RESULT" />
      }
    </components.NoOptionsMessage>
  );
};

export const SVReactSelectInput = props => {
  return <StyledReactSelect {...props} />;
};


export function SVReactSelect({
  field,
  form: { setFieldValue, touched, errors, setFieldTouched },
  options,
  label,
  Option,
  onChangeAction,
  placeholder,
  isSearchable = true,
  isClearable = true,
  components,
  isMulti,
  disabled,
  isLoading,
  withFeedbackLabel,
  feedbackLabel = '',
  onInputChange,
  inputValue,
  required,
  className,
  menuPlacement,
  info,
  ...props
}) {

  const fieldCheck = (type, field) => {
    if (field.includes(".")) {
      return type[field.split(".")[0]]?.[field.split(".")[1]];
    } else {
      return type[field];
    }
  };

  return (
    <SVFormControlWrapper className={className}>

      {label && (
        <SVFormControlLabel errors={fieldCheck(touched, field.name) && fieldCheck(errors, field.name) && true}>
          {label} {required && <span className="text-danger">*</span>}
          {info && <UserInfoDropdown size="w-75" title={label} description={info} />}
        </SVFormControlLabel>
      )}

      <SVReactSelectInput
        {...field}
        {...props}
        fieldError={fieldCheck(touched, field.name) && fieldCheck(errors, field.name) ? true : false}
        options={options}
        value={options ? options.find(option => option.value === field.value) : ''}
        //getOptionLabel={options => options.icon}
        onChange={option => { setFieldValue(field.name, option != null ? option.value : '') }}
        isClearable={isClearable}
        isSearchable={isSearchable}
        // defaultMenuIsOpen={defaultMenuIsOpen}
        isRtl={false}
        isDisabled={disabled}
        isLoading={isLoading}
        placeholder={placeholder}
        isMulti={isMulti}
        components={{ NoOptionsMessage }}
        onInputChange={onInputChange}
        inputValue={inputValue}
        onBlur={() => { setFieldTouched(field.name, true) }}
        menuPlacement={menuPlacement || 'auto'}
      />

      {feedbackLabel &&
        <SVFeedbackLabel withFeedbackLabel={true} errors={fieldCheck(touched, field.name) && fieldCheck(errors, field.name) && true} label={label}>
          {fieldCheck(touched, field.name) && fieldCheck(errors, field.name) ? fieldCheck(errors, field.name) : <span className='text-danger'>{feedbackLabel}</span>}
        </SVFeedbackLabel>
      }

    </SVFormControlWrapper>

  );
}



const StyledReactSelect = styled(Select).attrs({
  theme: style => ({
    ...style,
    borderRadius: theme.shape.borderRadius,
    colors: {
      ...style.colors,
      primary: theme.palette.primary['400'],
      primary25: theme.palette.primary['100'],
      primary50: theme.palette.primary['200'],
      primary75: theme.palette.primary['300'],
      danger: theme.palette.danger['color'],
    },
  }),
  styles: {
    control: (styles, state) => ({
      // [state.selectProps] is reaching down to the props of the Select component
      ...styles,
      height: theme.units.input.height[state.selectProps.size ?? 'md'],
      border: state.selectProps.fieldError ? `1px solid ${theme.palette.danger['color']}` : `1px solid ${theme.palette.gray['300']}`,
      boxShadow: theme.shadows.input,
      '&:hover': {
        cursor: 'context-menu',
        outline: `1px solid ${theme.palette.primary['100']}`,
      },
      '&:focus': {
        outline: `1px solid ${theme.palette.primary['300']}`,
        boxShadow: theme.shadows.inputHover,
      },
      // fontSize: theme.typography.fontSize[state.selectProps.size ?? 'md'],
      fontSize: theme.typography.fontSize['md'],
      backgroundColor: state.isDisabled && `${theme.palette.gray['50']}`,
    }),
    menu: (base, state) => ({
      ...base,
      zIndex: 1000,
      // fontSize: theme.typography.fontSize[state.selectProps.size ?? 'md'],
      fontSize: theme.typography.fontSize['md'],
    }),
  },
})``;
