import { call, fork, put, select, takeEvery } from "redux-saga/effects";
import { getTicketCounts } from "app/admin/support/_redux/saga";
import { AuthActions } from "app/pages/auth/_redux/actions";
import packagejson from "../../../../../../package.json";
import { notificationMiddleware } from "middlewares";
import { NotificationActions } from "./actions";
import ActionTypes from "./actionTypes";



// Notification
export function* getNotification() {
  try {

    const { accessToken, version } = yield select(state => ({
      accessToken: state.auth.access_token,
      version: state.notification.version
    }));

    const response = yield call(notificationMiddleware.getNotification, accessToken);
    yield put(NotificationActions.getNotificationSuccess(response.data));

    // Check version
    if (process.env.NODE_ENV !== 'production' && version && (version?.replaceAll(".", "") > packagejson.version?.replaceAll(".", ""))) {
      console.warn("Yeni versiyon bulundu.", version, " >>> ", packagejson.version)
      //window.location.reload()
    }

    // Get ticket count
    return yield fork(getTicketCounts);

  } catch (err) {
    if (err.response && err.response.status === 401) {
      return yield put(AuthActions.logout());
    }
    yield put(NotificationActions.getNotificationFail(err));
  }
}



export function* notificationSaga() {
  yield takeEvery(ActionTypes.GET_NOTIFICATION, getNotification);
}