import React from "react";



function SelectionCheckbox({ isSelected, onChange, id }) {
  return (
    <>
      <input type="checkbox" className="d-none" />
      <label className="checkbox checkbox-outline checkbox-single">
        <input type="checkbox" checked={isSelected} onChange={onChange} id={`checkbox_${id}`} />
        <span />
      </label>
    </>
  );
}

function groupingItemOnSelect(props) {
  const { ids, setIds, _id } = props;
  if (ids.some((id) => id === _id)) {
    setIds(ids.filter((id) => id !== _id));
  } else {
    const newIds = [...ids];
    newIds.push(_id);
    setIds(newIds);
  }
}

function groupingAllOnSelect(props) {
  const { isSelected, setIds, entities } = props;
  if (!isSelected) {
    const allIds = [];
    entities.forEach((el) => allIds.push(el.id));
    setIds(allIds);
  } else {
    setIds([]);
  }

  return isSelected;
}

// check official documentations: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Row%20Selection&selectedStory=Custom%20Selection%20Column%20Header%20Style&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
export function getSelectRow(props) {
  const { entities, ids, setIds } = props;
  return {
    mode: "checkbox",
    // clickToSelect: true,
    clickToSelectAndEditCell: true, //edit islemi icin bu property gerekli
    hideSelectAll: false,
    selectionHeaderRenderer: () => {
      const isSelected = entities && entities.length > 0 && entities.length === ids.length;
      const props = { isSelected, entities, setIds };
      return (
        <SelectionCheckbox isSelected={isSelected} onChange={() => groupingAllOnSelect(props)} id="select_all" />
      );
    },
    selectionRenderer: ({ rowKey }) => {
      const isSelected = ids.some((el) => el === rowKey);
      const props = { ids, setIds, _id: rowKey };
      return (
        <SelectionCheckbox isSelected={isSelected} onChange={() => groupingItemOnSelect(props)} id={rowKey} />
      );
    },
  };
}
