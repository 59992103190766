import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectionsActions } from '../../../_redux/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { SVFormControl } from "_metronic/_partials/controls";
import { Formik, Form, Field } from "formik";
import { Col, Row, Button, Modal } from "react-bootstrap";
import * as Yup from "yup";



const INPUTS = [
  {
    name: "deutsche_post.Benutzername",
    label: "ACCOUNT.BENUTZERNAME",
    editable: true
  },
  {
    name: "deutsche_post.Kennwort",
    label: "AUTH.INPUT.PASSWORD",
    type: "password",
    editable: true
  },
]


export default function DeutschePostForm({ children, connectionId, initialValue, loading, onHide }) {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const deutschePostForEdit = useSelector(state => state.connections.deutschePostForEdit);

  const validationSchema = Yup.object().shape({
    ...(!connectionId && {
      deutsche_post: Yup.object().shape({
        Benutzername: Yup.string()
          .required(intl({ id: 'GENERAL.REQUIRED' })),
        Kennwort: Yup.string()
          .required(intl({ id: 'GENERAL.REQUIRED' })),
        // Layout: Yup.string()
        //   .required(intl({ id: 'GENERAL.REQUIRED' }))
      }),
    }),
  });


  const handleSubmit = values => {
    connectionId
      ? dispatch(ConnectionsActions.updateSettings(values))
      : dispatch(ConnectionsActions.createSettings(values))
  };


  return (
    <Formik enableReinitialize={true} initialValues={connectionId ? deutschePostForEdit : initialValue} validationSchema={validationSchema} onSubmit={handleSubmit}>
      <Form autoComplete='off'>

        <Modal.Body className='p-0'>
          <Row className='px-8'>
            {INPUTS.map((input, i) => (
              <Col key={i} lg={input.lg ?? "6"}>
                <Field component={SVFormControl}
                  {...{
                    name: input.name,
                    type: input.type,
                    label: intl({ id: input.label }),
                    withFeedbackLabel: true,
                    feedbackLabel: true,
                    required: true,
                    disabled: loading || (connectionId && !input.editable),
                  }}
                />
              </Col>
            ))}
          </Row>

          {children}

        </Modal.Body>

        <Modal.Footer>

          <Button variant="light" onClick={onHide} disabled={loading} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant="primary" type="submit" className="ml-2" disabled={loading} id="btn_save_modal">
            <FormattedMessage id="GENERAL.SAVE" />
          </Button>

        </Modal.Footer>

      </Form>
    </Formik>
  )
}
