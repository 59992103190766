export default class ActionTypes {
  static get PREFIX() {
    return '[archive]';
  }


  // Get Archive
  static get GET_ARCHIVE() {
    return `${this.PREFIX}GET_ARCHIVE`;
  }
  static get GET_ARCHIVE_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_ARCHIVE_SUCCESS`;
  }
  static get GET_ARCHIVE_FAIL() {
    return `${ActionTypes.PREFIX}GET_ARCHIVE_FAIL`;
  }


  // Print Archive
  static get PRINT_ARCHIVE() {
    return `${this.PREFIX}PRINT_ARCHIVE`;
  }
  static get PRINT_ARCHIVE_SUCCESS() {
    return `${ActionTypes.PREFIX}PRINT_ARCHIVE_SUCCESS`;
  }
  static get PRINT_ARCHIVE_FAIL() {
    return `${ActionTypes.PREFIX}PRINT_ARCHIVE_FAIL`;
  }

  /**
   * *update archive
   * @return action types for update archive
   */
  static get DELETE_ARCHIVE() {
    return `${ActionTypes.PREFIX}DELETE_ARCHIVE`;
  }
  static get DELETE_ARCHIVE_SUCCESS() {
    return `${ActionTypes.PREFIX}DELETE_ARCHIVE_SUCCESS`;
  }
  static get DELETE_ARCHIVE_FAIL() {
    return `${ActionTypes.PREFIX}DELETE_ARCHIVE_FAIL`;
  }


  // Clean Up Reducer
  static get CLEAN_UP_REDUCER() {
    return `${ActionTypes.PREFIX}CLEAN_UP_REDUCER`;
  }


  // Clear loading
  static get CLEAR_LOADING_EFFECTS() {
    return `${ActionTypes.PREFIX}CLEAR_LOADING_EFFECTS`;
  }

}
