import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { store, persistor } from "./redux/store";
import { MetronicLayoutProvider, MetronicSplashScreenProvider } from "_metronic/layout";
import { MetronicI18nProvider } from "_metronic/i18n";
import App from "./app/App";

import "./index.scss"; // Standard version
import "react-toastify/dist/ReactToastify.min.css"; // react-tostify
import "react-datepicker/dist/react-datepicker.css";// Datepicker


const { PUBLIC_URL } = process.env;


// react-intl "Missing Message" console error remove
// eslint-disable-next-line
const consoleError = console.error.bind(console);
// eslint-disable-next-line
console.error = (message, ...args) => {
  if (
    typeof message === 'string' && (
      message.startsWith('[React Intl] Missing message:') ||
      message.startsWith('[React Intl] Cannot format message'))
  ) {
    return;
  }
  consoleError(message, ...args);
};


ReactDOM.render(
  <MetronicI18nProvider>
    <MetronicLayoutProvider>
      <MetronicSplashScreenProvider>
        <App store={store} persistor={persistor} basename={PUBLIC_URL} />
      </MetronicSplashScreenProvider>
    </MetronicLayoutProvider>
  </MetronicI18nProvider>,
  document.getElementById("root")
);