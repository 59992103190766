import React from "react";
import { FormattedMessage } from 'react-intl';

const labelType = {
  PUBLISHED: 'success',
  DRAFT: 'info',
  NOT: 'warning',
};
const getLabelType = status => labelType[status] || 'light';

export function StatusColumnFormatter(cellContent, row, rowIndex, { market_id, isLoading }) {

  const inventory =
    Array.isArray(row?.inventories) &&
    row.inventories.length > 0 &&
    row.inventories.find(ei => ei.market_id === market_id);

  //"GENERAL.PENDING": "PENDING",
  //"GENERAL.INACTIVE": "INACTIVE",
  let status;
  if (inventory) {
    if (inventory.status === "PUBLISHED") { status = <FormattedMessage id="GENERAL.ACTIVE" /> }
    if (inventory.status === "DRAFT") { status = <FormattedMessage id="GENERAL.DRAFT" /> }
  } else { status = <FormattedMessage id="GENERAL.NOT_LISTED" /> }

  return (
      /*  (isLoading)
      ? <Spinner loading={isLoading} color="danger" className="mr-10" />
      : */ <label className={`label label-inline label-lg label-${getLabelType(inventory?.status ?? "NOT")}`}>
      {status}
    </label>
  );
}