import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '_metronic/_partials/controls'
import { CreationAndUpdateInfo, BackButton } from '_metronic/_partials'
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, FormControl } from 'react-bootstrap';
import SupportPreviewCard from './SupportPreviewCard';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { SupportActions } from '../_redux/actions';
import { getLastSearch } from '_metronic/_helpers';
import { Icon } from '_metronic/_icons'



export default function SupportEdit({ id }) {

  const history = useHistory();
  const dispatch = useDispatch();
  const lastSearch = getLastSearch();
  const { formatMessage: intl } = useIntl();

  useEffect(() => {
    dispatch(SupportActions.getTicketById(id));
  }, [dispatch, id]);

  const { ticket, loading, role } = useSelector((state) => ({
    ticket: state.supports.ticket,
    loading: state.supports.loading,
    role: state.auth.user.role,
  }))

  useEffect(() => {
    return () => {
      dispatch(SupportActions.cleanUpReducer());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Jira editable button
  const [jiraProps, setJiraProps] = useState({ isEditing: false, jira: ticket?.jira?.split('-')[1] });

  const handleDoubleClick = () => {
    setJiraProps(prev => ({ ...prev, isEditing: true }));
  };

  const handleBlur = (value) => {
    if (value === "") {
      dispatch(SupportActions.ticketProps(ticket.id, { jira: "" }));
    } else if (`SVW-${value}` !== ticket.jira && value.length >= 4 && value.length <= 5) {
      dispatch(SupportActions.ticketProps(ticket.id, { jira: `SVW-${value}` }));
    } else {
      setJiraProps(prev => ({ ...prev, jira: ticket?.jira?.split('-')[1] }));
    }
    setJiraProps(prev => ({ ...prev, isEditing: false }));
  };


  return (
    <Card className="card-box">

      <CardHeader
        back={<BackButton link={ModuleRoutes.SUPPORT + lastSearch} disabled={loading} />}
        title={intl({ id: "SUPPORT.TICKET" }, { ticket: ticket?.number ?? "View" })}
        id={'title_supplier_edit'}
        sticky
      >
        <CardHeaderToolbar>
          {role === "644c41e257d397eb064fe0f9"
            ? <Button
              variant='outline-secondary'
              className="d-flex align-items-center justify-content-center font-weight-bold ml-2"
              onDoubleClick={handleDoubleClick}
              id="btn_jira"
              disabled={loading}
            >
              <a className="svg-icon svg-icon-sm mr-2" href={`https://bcs-it.atlassian.net/browse/SVW-${jiraProps.jira}`} target="_blank" rel="noopener noreferrer" id="btn_jira_view">
                <Icon.Jira />
              </a>
              {jiraProps.isEditing
                ? <FormControl
                  className="d-inline ml-2"
                  value={jiraProps.jira}
                  onChange={(e) => {
                    const targetValue = e.target.value;
                    setJiraProps(prev => ({ ...prev, jira: targetValue }));
                  }}
                  onBlur={(e) => handleBlur(e.target.value)}
                  onKeyDown={(e) => { if (e.key === 'Enter') { handleBlur(e.target.value) } }}
                  style={{ width: "50px", height: "25px", padding: "0 5px" }}
                  autoFocus
                />
                : <span className="d-sm-inline">
                  {(jiraProps.jira === "" || !jiraProps.jira) ? <FormattedMessage id="SUPPORT.TICKET.JIRA_TASK_NO" /> : "SVW-" + jiraProps.jira}
                </span>
              }
            </Button>
            : null
          }

          {!['cancelled', 'completed', 'archived'].includes(ticket?.status) &&
            <Button variant='primary' className='d-flex font-weight-bold svg-icon svg-icon-sm ml-2' onClick={() => { history.push(ModuleRoutes.EDIT_TICKET_FN(id)) }} id='btn_reply_ticket' disabled={loading}>
              <Icon.Reply />
              <span className="d-none d-sm-inline ml-2">
                <FormattedMessage id='SUPPORT.REPLY' />
              </span>
            </Button>}

        </CardHeaderToolbar>

      </CardHeader>

      <CardBody>

        <SupportPreviewCard ticket={ticket} />

      </CardBody>

      <CreationAndUpdateInfo data={ticket} />

    </Card>
  )
}
