import React from 'react'
import { Route } from 'react-router-dom';
import { TempUserDeleteDialog } from './temp-user-dialogs/TempUserDeleteDialog';
import { AdminRoutes } from 'constants/moduleRoutes';
import { getLastSearch } from '_metronic/_helpers';
import { TempUserCard } from './TempUserCard';



export function TempUserPage() {

  const lastSearch = getLastSearch()

  return (
    <>
      <TempUserCard />
      <Route path={AdminRoutes.DELETE_TEMP_USER}>
        {({ history, match }) => (
          <TempUserDeleteDialog
            show={match != null}
            id={match && match.params.id}
            // name={match && match.params.name}
            onHide={() => history.push(AdminRoutes.ADMIN_TEMP_USERS + lastSearch)}
          // onClick={() => { dispatch(AdminActions.deleteTempUser(match.params.id, UIProps.queryParams)); history.push(AdminRoutes.ADMIN_TEMP_USERS) }}
          />
        )}
      </Route>
      <Route path={AdminRoutes.DELETE_TEMP_USER_LISTS}>
        {({ history, match }) => (
          <TempUserDeleteDialog
            show={match != null}
            id={match && match.params.ids}
            multiple={true}
            onHide={() => history.push(AdminRoutes.ADMIN_TEMP_USERS + lastSearch)}
          // onClick={() => { dispatch(AdminActions.deleteTempUser(match.params.id, UIProps.queryParams)); history.push(AdminRoutes.ADMIN_TEMP_USERS) }}
          />
        )}
      </Route>
    </>
  )
}
