import React from 'react'
import { Redirect, Switch } from 'react-router-dom';
import { ContentRoute } from '_metronic/layout';
import { AdminRoutes, RELATIVE_PATH } from 'constants/moduleRoutes';
import { UserContactUIProvider } from './_context/UserContactUIContext';
import { UserContactPage } from './UserContactPage';



export default function UserContact() {
  return (
    <UserContactUIProvider>

      <Switch>
        <Redirect exact={true} from={RELATIVE_PATH} to={AdminRoutes.ADMIN_USERS_CONTACT} />
        {/* <ContentRoute path={AdminRoutes.ADMIN_USERS_CONTACT_EDIT} component={UserContactEdit} /> */}
        <ContentRoute path={AdminRoutes.ADMIN_USERS_CONTACT} component={UserContactPage} />
      </Switch>

    </UserContactUIProvider>
  )
}
