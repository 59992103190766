export const utils = {

  uuidv4: () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  },

  uuid: () => {
    const head = Date.now().toString(36);
    const tail = Math.random()
      .toString(36)
      .substr(2);
    return head + tail;
  },

  getRandomColorHex: () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  },

  keyboardEventHandlers: {
    // (ctrl+p) event prevent in browser (Chrome)
    preventPrintMethod: e => {
      if (e.ctrlKey && e.keyCode === 80) {
        e.preventDefault();
        return false;
      }
    },
    // (ctrl+s) event prevent in browser (Chrome)
    preventSaveMethod: e => {
      if (e.ctrlKey && e.keyCode === 83) {
        e.preventDefault();
        return false;
      }
    },
  },

  numberFn: {
    numberWithCommas: num => {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    compactNumber: num => {
      const suffixes = ['', 'k', 'm', 'b', 't'];
      const suffixNum = Math.floor(('' + num).length / 3);
      let shortValue = parseFloat((suffixNum !== 0 ? num / Math.pow(1000, suffixNum) : num).toPrecision(2));
      if (shortValue % 1 !== 0) {
        shortValue = shortValue.toFixed(1);
      }
      return shortValue + suffixes[suffixNum];
    },
    ordinalSuffix: num => {
      const s = ['th', 'st', 'nd', 'rd'];
      const v = num % 100;
      return num + (s[(v - 20) % 10] || s[v] || s[0]);
    },
    getRandomInt: (min, max) => {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
    },

    getRandomFloat: (min, max) => {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.random() * (max - min) + min; //The maximum is exclusive and the minimum is inclusive
    },
  },

  generateSKU: () => {
    const sku =
      Math.random()
        .toString(36)
        .substring(2, 10) +
      Math.random()
        .toString(36)
        .substring(2, 10);
    return sku.toUpperCase();
  },
};
