import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { EnterPaymentSummary } from './EnterPaymentSummary';
import { EnterPaymentTable } from './enter-payment-table/EnterPaymentTable';
import { EnterPaymentAddDialog } from './enter-payment-add/EnterPaymentAddDialog';
import * as uiHelpers from '../_context/DocumentsUIHelpers';
import { CustomAlert } from '_metronic/_partials/controls';
import { DocumentActions } from '../_redux/actions';



export default function EnterPaymentBody({ documentId, setPaymentAmountDifference }) {

  const dispatch = useDispatch();

  const [payment, setPayment] = useState({ totalPaid: 0, totalProductPrice: 0, totalPaymentWarning: 0, totalPrice: 0, paymentAmountDifference: 0, shippingPrice: 0, currency: "", paymentStatus: "" });

  const { documentForEdit, positionTotals, actionController } = useSelector(state => ({
    documentForEdit: state.documents.documentForEdit,
    positionTotals: state.documents.positionTotals,
    actionController: state.documents.actionController,
  }));

  // FIXME: positionTotals kaldırılacak fonk global yapılacak
  useEffect(() => {

    // para birimi
    const currency = documentForEdit?.marketplace === "" ? positionTotals?.currency : documentForEdit?.pricing_summary.currency;

    // ödeme durumu
    const paymentStatus = documentForEdit.payment_status

    // toplam ödenen miktar
    const totalPaid = documentForEdit.entered_payment?.reduce((acc, payment) => acc + payment.amount, 0);

    // toplam payment warnings
    const totalPaymentWarning = documentForEdit?.payment_warnings?.reduce((acc, warning) => acc + +warning.amount, 0) ?? 0;

    // shipping price
    const shippingPrice = +positionTotals.shipping_price;

    // discount oranı
    const discountRate = +((+positionTotals.total * Math.max(0, positionTotals?.discount || 0)) / 100) ?? 0;

    // discount tutarı
    const discountAmount = +(positionTotals?.total_price_discount && positionTotals.total_price_discount > 0 ? -positionTotals.total_price_discount : 0)

    // discountlu product (pos+disc)
    const totalProductPrice = +positionTotals.total - discountRate - discountAmount;

    // toplam fiyat (pos+disc+payWar+shipping)
    const totalPrice = +positionTotals.total + +positionTotals.shipping_price + totalPaymentWarning - discountRate - discountAmount;

    // ödenen toplam fiyat ile toplam fiyat arasındaki fark
    const paymentAmountDifference = (+totalPrice.toFixed(2) - +totalPaid);

    setPayment({ totalPaid, totalProductPrice, totalPaymentWarning, totalPrice, paymentAmountDifference, shippingPrice, currency, paymentStatus })

    if (setPaymentAmountDifference) {
      setPaymentAmountDifference(paymentAmountDifference)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentForEdit.entered_payment, documentForEdit.pricing_summary, documentForEdit.payment_warnings, setPaymentAmountDifference, positionTotals]);


  return (
    <>
      {actionController.result === 'error' && actionController.id === documentId && (
        <CustomAlert
          title="Error"
          type="danger"
          message={actionController.error}
          className="mx-8 mt-8 mb-0"
          onClose={() => dispatch(DocumentActions.cleanStatesInStore({ actionController: uiHelpers.initialStatesForReducer.actionController }))}
        />
      )}

      <EnterPaymentTable documentId={documentId} />

      <EnterPaymentSummary payment={payment} />

      <EnterPaymentAddDialog documentId={documentId} paymentAmountDifference={payment.paymentAmountDifference} />
    </>
  )
}