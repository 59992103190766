import React, { useMemo } from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { SelectCounter } from "_metronic/_partials";
import { useUserSettingsUIContext } from "../../_context/UserSettingsUIContext";
import { Icon } from "_metronic/_icons";



export function ShippingSettingsGrouping() {

  const UIContext = useUserSettingsUIContext();

  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    openShippingDeleteDialog: UIContext.openShippingDeleteDialog,
  }), [UIContext]);


  return (
    <div className="d-flex justify-content-between">

      <SelectCounter count={UIProps.ids?.length} />

      <Button variant="light-primary" className="svg-icon svg-icon-sm font-weight-bold ml-auto" onClick={() => UIProps.openShippingDeleteDialog()} disabled={UIProps.ids?.length <= 0} id="btn_multiple_delete">
        <Icon.Trash />
        <span className="d-none d-sm-inline ml-2">
          <FormattedMessage id="GENERAL.DELETE" />
        </span>
      </Button>

    </div>
  )
}
