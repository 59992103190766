import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { importExportActions } from '../../_redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { validator } from './ImportCustomerValidators';
import * as columnFormatters from './ImportCustomerColumnFormatters';
import { customerTypeOptions1 } from 'app/modules/customers/_context/CustomersUIHelpers';
import { FormattedMessage, useIntl } from 'react-intl';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getCountryList } from '_metronic/_helpers';
import { useLang } from '_metronic/i18n';
import { Icon } from '_metronic/_icons';



export function ImportCustomersTable() {

  const dispatch = useDispatch();
  const locale = useLang();
  const { formatMessage: intl } = useIntl();

  const { importPreparation, checkData } = useSelector((state) => ({
    importPreparation: state.importExport.importPreparation,
    checkData: state.importExport.checkData,
  }));


  const tableRowHandler = (oldValue, newValue, row, column) => {

    const updatedRow = {
      ...row,
      [column.dataField]: newValue,
    };

    dispatch(importExportActions.importPreparationUpdate(updatedRow));
  };

  const options = customerTypeOptions1.map(option => ({
    value: option.value,
    label: intl({ id: option.label })
  }));

  const getCountryOptions = () => {
    const countryData = getCountryList(locale);
    const optionsArray = Array.isArray(countryData) ? countryData : [];

    return optionsArray?.map(option => ({
      value: option.value,
      label: intl({ id: option.label })
    }));
  };

  const editorStyle = () => ({ height: "32px", padding: "0.5rem 0.75rem" })
  const emailsTable = importPreparation.map(item => item.email)

  const [forceRerender, setForceRerender] = useState(false);
  useEffect(() => {
    setForceRerender(prev => !prev);
  }, [importPreparation]);


  const COLUMNS = [
    {
      dataField: '#',
      text: '#',
      headerStyle: { minWidth: '50px', width: '3%' },
      headerClasses: 'text-center',
      classes: 'font-weight-bold text-center',
      editorStyle,
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      editable: false,
    },
    {
      dataField: 'type',
      text: intl({ id: 'IMPORT.CUSTOMER.TYPE' }),
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.TypeFormatter,
      validator: (cell) => validator.typeValidator(cell, intl),
      editor: { type: Type.SELECT, options: options },
    },
    {
      dataField: 'name',
      text: intl({ id: 'IMPORT.CUSTOMER.NAME' }),
      headerFormatter: columnFormatters.HeaderFormatterRequired,
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.NameFormatter,
      validator: (cell) => validator.nameValidator(cell, intl),
    },
    {
      dataField: 'company',
      //FIXME: required olup olmadiğini kontrol edilecek
      text: intl({ id: 'GENERAL.COMPANY' }),
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.CompanyFormatter,
      validator: (cell, row) => validator.companyValidator(cell, row, intl),
    },
    {
      dataField: 'email',
      text: intl({ id: 'IMPORT.CUSTOMER.EMAIL' }),
      headerFormatter: columnFormatters.HeaderFormatterRequired,
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: (cell, row) => columnFormatters.EmailFormatter(cell, row, checkData, emailsTable),
      validator: (cell) => validator.emailValidator(cell, checkData, intl),
    },
    {
      dataField: 'phone',
      text: intl({ id: 'GENERAL.PHONE' }),
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.PhoneFormatter,
      validator: (cell) => validator.phoneValidator(cell, intl),
    },
    {
      dataField: 'mobile',
      text: intl({ id: 'GENERAL.MOBILE' }),
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.MobileFormatter,
      validator: (cell) => validator.mobileValidator(cell, intl),
    },
    {
      dataField: 'tax_number',
      text: intl({ id: 'GENERAL.TAX_NUMBER' }),
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.TaxFormatter,
      validator: (cell) => validator.taxValidator(cell, intl),
    },
    {
      dataField: 'vat_id',
      //FIXME: required olup olmadiğini kontrol edilecek
      text: intl({ id: 'GENERAL.US_NUMBER' }),
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.VatFormatter,
      validator: (cell, row) => validator.vatValidator(cell, row, intl),
    },
    {
      dataField: 'fax',
      text: intl({ id: 'GENERAL.FAX' }),
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.FaxFormatter,
      validator: (cell) => validator.faxValidator(cell, intl),
    },

    {
      dataField: 'bank',
      text: intl({ id: 'BANK.NAME' }),
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.BankFormatter,
      validator: (cell) => validator.bankValidator(cell, intl),
    },
    {
      dataField: 'iban',
      text: intl({ id: 'BANK.IBAN' }),
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.IbanFormatter,
      validator: (cell) => validator.ibanValidator(cell, intl),
    },
    {
      dataField: 'bic',
      text: intl({ id: 'BANK.BIC' }),
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.BicFormatter,
      validator: (cell) => validator.bicValidator(cell, intl),
    },
    {
      dataField: 'address_customer_name_1',
      text: intl({ id: 'IMPORT.CUSTOMER.DELIVERY.CUSTOMER_NAME' }),
      headerFormatter: columnFormatters.HeaderFormatter,
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.DeliveryCustomerNameFormatter,
      validator: (cell) => validator.deliveryCustomerNameValidator(cell, intl),
    },
    {
      dataField: 'address_company_name_1',
      text: intl({ id: 'IMPORT.CUSTOMER.DELIVERY.COMPANY_NAME' }),
      headerFormatter: columnFormatters.HeaderFormatter,
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.CompanyNameFormatter,
      validator: (cell) => validator.companyNameValidator(cell, intl),
    },
    {
      dataField: 'address_line1_1',
      text: intl({ id: 'IMPORT.CUSTOMER.DELIVERY.ADDRESS_LINE_1' }),
      headerFormatter: columnFormatters.HeaderFormatter,
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.DeliveryAddressLine1Formatter,
      validator: (cell) => validator.deliveryAddressLine1Validator(cell, intl),
    },
    {
      dataField: 'address_line2_1',
      text: intl({ id: 'IMPORT.CUSTOMER.DELIVERY.ADDRESS_LINE_2' }),
      headerFormatter: columnFormatters.HeaderFormatter,
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.AddressLine2Formatter,
      validator: (cell) => validator.addressLine2Validator(cell, intl),
    },
    {
      dataField: 'post_code_1',
      text: intl({ id: 'IMPORT.CUSTOMER.DELIVERY.ADDRESS_POSTCODE' }),
      headerFormatter: columnFormatters.HeaderFormatter,
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.DeliveryPostCodeFormatter,
      validator: (cell, row) => validator.deliveryPostCodeValidator(cell, row, intl),
    },
    {
      dataField: 'city_1',
      text: intl({ id: 'IMPORT.CUSTOMER.DELIVERY.ADDRESS_CITY' }),
      headerFormatter: columnFormatters.HeaderFormatter,
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.DeliveryCityFormatter,
      validator: (cell) => validator.deliveryCityValidator(cell, intl),
    },
    {
      dataField: 'country_code_1',
      text: intl({ id: 'IMPORT.CUSTOMER.DELIVERY.ADDRESS_COUNTRY_CODE' }),
      headerFormatter: columnFormatters.HeaderFormatter,
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.DeliveryCountryCodeFormatter,
      validator: (cell) => validator.deliveryCountryCodeValidator(cell, intl),
      editor: { type: Type.SELECT, options: getCountryOptions() || [] },
    },
    {
      dataField: 'address_customer_name_2',
      text: intl({ id: 'IMPORT.CUSTOMER.INVOICE.CUSTOMER_NAME' }),
      headerFormatter: columnFormatters.HeaderFormatter,
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.InvoiceCustomerNameFormatter,
      validator: (cell) => validator.invoiceCustomerName(cell, intl),
    },
    {
      dataField: 'address_company_name_2',
      text: intl({ id: 'IMPORT.CUSTOMER.INVOICE.COMPANY_NAME' }),
      headerFormatter: columnFormatters.HeaderFormatter,
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.CompanyNameFormatter,
      validator: (cell) => validator.companyNameValidator(cell, intl),
    },
    {
      dataField: 'address_line1_2',
      text: intl({ id: 'IMPORT.CUSTOMER.INVOICE.ADDRESS_LINE_1' }),
      headerFormatter: columnFormatters.HeaderFormatter,
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.InvoiceAddressLine1Formatter,
      validator: (cell) => validator.invoiceAddressLine1Validator(cell, intl),
    },
    {
      dataField: 'address_line2_2',
      text: intl({ id: 'IMPORT.CUSTOMER.INVOICE.ADDRESS_LINE_2' }),
      headerFormatter: columnFormatters.HeaderFormatter,
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.AddressLine2Formatter,
      validator: (cell) => validator.addressLine2Validator(cell, intl),
    },
    {
      dataField: 'post_code_2',
      text: intl({ id: 'IMPORT.CUSTOMER.INVOICE.ADDRESS_POSTCODE' }),
      headerFormatter: columnFormatters.HeaderFormatter,
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.InvoicePostCodeFormatter,
      validator: (cell, row) => validator.invoicePostCodeValidator(cell, row, intl),
    },
    {
      dataField: 'city_2',
      text: intl({ id: 'IMPORT.CUSTOMER.INVOICE.ADDRESS_CITY' }),
      headerFormatter: columnFormatters.HeaderFormatter,
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.InvoiceCityFormatter,
      validator: (cell) => validator.invoiceCityValidator(cell, intl),
    },
    {
      dataField: 'country_code_2',
      text: intl({ id: 'IMPORT.CUSTOMER.INVOICE.ADDRESS_COUNTRY_CODE' }),
      headerFormatter: columnFormatters.HeaderFormatter,
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.InvoiceCountryCodeFormatter,
      validator: (cell) => validator.invoiceCountryCodeValidator(cell, intl),
      editor: { type: Type.SELECT, options: getCountryOptions() || [] },
    },
    {
      dataField: 'discount',
      text: intl({ id: 'IMPORT.CSV.CUSTOMER.DISCOUNT' }),
      headerFormatter: columnFormatters.HeaderFormatter,
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.DiscountFormatter,
      validator: (cell) => validator.DiscountValidator(cell, intl),
    },
    {
      dataField: 'notes',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.NOTE' }),
      headerFormatter: columnFormatters.HeaderFormatter,
      headerStyle: { minWidth: '150px', width: '10%' },
      classes: 'font-weight-bold text-nowrap',
      headerClasses: 'text-nowrap',
      editorStyle,
      formatter: columnFormatters.NotesFormatter,
      validator: (cell) => validator.NotesValidator(cell, intl),
    },
    {
      dataField: 'action',
      text: intl({ id: 'GENERAL.ACTIONS' }),
      formatter: (cell, row) => (
        <OverlayTrigger overlay={<Tooltip id="products-delete-tooltip"><FormattedMessage id="GENERAL.DELETE" /></Tooltip>}>
          <button className="btn btn-icon btn-hover-danger btn-sm" onClick={() => dispatch(importExportActions.importPreparationDelete(row.id))}
            id={`btn_delete_${row.id}`}>
            <span className="svg-icon svg-icon-md">
              <Icon.Trash />
            </span>
          </button>
        </OverlayTrigger>
      ),
      classes: 'text-center pr-0',
      headerClasses: 'text-center pr-3',
      headerStyle: { minWidth: '110px' },
    },
  ];

  return (
    <BootstrapTable
      keyField="id"
      key={forceRerender}
      data={importPreparation || []}
      columns={COLUMNS}
      wrapperClasses="table-responsive"
      classes="table table-head-custom table-vertical-center overflow-hidden"
      bootstrap4
      striped
      condensed
      cellEdit={cellEditFactory({
        mode: 'dbclick',
        blurToSave: true,
        autoSelectText: true,
        afterSaveCell: tableRowHandler,
      })}
    />
  );
}
