import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ModuleRoutes, RELATIVE_PATH } from 'constants/moduleRoutes';
import { ContentRoute } from '../../../_metronic/layout';
import AmazonListings from './amazon-listings/AmazonListings';
// import AmazonListings from './amazon-listings/AmazonListings';
import EbayListings from './ebay-listings/EbayListings';
import KauflandListings from './kaufland-listings/KauflandListings';
import { ProductListingsPage } from './ProductListingsPage';
//import { ConnectionAddPage } from '../connections/ConnectionAddPage';



export default function ProductListings() {
  return (
    <>
      <Switch>
        <Redirect exact={true} from={RELATIVE_PATH} to={ModuleRoutes.LISTINGS} />

        <ContentRoute path={ModuleRoutes.EBAY_LISTINGS} component={EbayListings} />
        <ContentRoute path={ModuleRoutes.KAUFLAND_LISTINGS} component={KauflandListings} />
        <ContentRoute path={ModuleRoutes.AMAZON_LISTINGS} component={AmazonListings} />
        <ContentRoute path={ModuleRoutes.LISTINGS} component={ProductListingsPage} />

      </Switch>
    </>
  );
}
