import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { ConnectionsActions } from '../_redux/actions';
import { Portal } from 'react-portal';



export function ConnectionDeleteDialog({ id, show, onHide, confirmName }) {

  const dispatch = useDispatch();

  useEffect(() => {
    !id && confirmName && onHide()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);


  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={show} onHide={onHide} size="sm" backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered>

        <Modal.Body className="text-center line-height-md h6 py-10 mb-0">
          <FormattedMessage id="CONNECTIONS.DELETE_CONTENT" values={{ name: confirmName?.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase()) }} />

          {['ebay', 'kaufland', 'amazon'].includes(confirmName) &&
            <div className='my-1'>
              <FormattedMessage id="CONNECTIONS.DELETE_LISTING_WARNING" />
            </div>
          }

        </Modal.Body>

        <Modal.Footer className="flex-nowrap p-0 overflow-hidden">

          <Button variant='ios' className="border-right" onClick={onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant='ios' className="text-danger" onClick={() => { dispatch(ConnectionsActions.deleteSettings(id, confirmName)); onHide() }} id="btn_delete_modal">
            <FormattedMessage id="GENERAL.DELETE" />
          </Button>

        </Modal.Footer>

      </Modal>
    </Portal>
  );
}
