import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useUserSettingsUIContext } from '../../_context/UserSettingsUIContext';
import { AuthActions } from '../../../../pages/auth/_redux/actions';
import { passwordRedExp } from '_metronic/_helpers';
import PasswordEditForm from './PasswordEditForm';
import { CloseButton } from '_metronic/_partials';
import { Button, Modal } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';
import { Portal } from 'react-portal';
import { Form, Formik } from 'formik';
import * as Yup from 'yup'



export default function PasswordEdit() {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const { success, loading, message } = useSelector(state => ({
    loading: state.auth.loading,
    success: state.auth.success,
    message: state.auth.message,
  }));

  const UIContext = useUserSettingsUIContext();
  const UIProps = useMemo(() => ({
    closePasswordEditDialog: UIContext.closePasswordEditDialog,
    showPasswordEditDialog: UIContext.showPasswordEditDialog,
  }),
    [
      UIContext.closePasswordEditDialog,
      UIContext.showPasswordEditDialog,
    ]
  );

  const handleSubmitPassword = (values) => {

    const newValues = {
      password: values.password,
      new_password: values.new_password,
    }

    dispatch(AuthActions.changePassword(newValues))
  }

  useEffect(() => {

    if (success) {
      setTimeout(() => {
        UIProps.closePasswordEditDialog();
        dispatch(AuthActions.cleanUpReducer());
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, dispatch]);


  const passwordValidationSchema = Yup.object().shape({
    password: Yup.string()
      .trim(intl({ id: 'AUTH.GENERAL.BLANK_CHARACTER' }))
      .strict(true)
      // .min(6, intl({ id: 'AUTH.REGISTER.PASSWORD.LENGTH_MIN' }))
      .max(50, intl({ id: 'AUTH.REGISTER.PASSWORD.LENGTH_MAX' }))
      // .matches(passwordRedExp, intl({ id: 'AUTH.REGISTER.PASSWORD.MATCHES' }))
      .required(intl({ id: 'AUTH.VALIDATION.PASSWORD_REQUIRED' })),
    new_password: Yup.string()
      .trim(intl({ id: 'AUTH.GENERAL.BLANK_CHARACTER' }))
      .strict(true)
      .min(8, intl({ id: 'AUTH.REGISTER.PASSWORD.LENGTH_MIN' }))
      .max(50, intl({ id: 'AUTH.REGISTER.PASSWORD.LENGTH_MAX' }))
      .matches(passwordRedExp, intl({ id: 'AUTH.REGISTER.PASSWORD.MATCHES' }))
      .required(intl({ id: 'AUTH.VALIDATION.PASSWORD_REQUIRED' })),
  });


  return (
    <Portal node={document && document.getElementById('modal-portal')}>
      <Modal show={UIProps.showPasswordEditDialog} onHide={UIProps.closePasswordEditDialog} size="md" aria-labelledby="contained-modal-title-vcenter" backdrop="static" keyboard={false} centered>

        <Formik enableReinitialize={true} initialValues={{ password: '', new_password: '' }} validationSchema={passwordValidationSchema} onSubmit={handleSubmitPassword}>
          <Form>

            <Modal.Body className='p-10'>

              <CloseButton onClick={UIProps.closePasswordEditDialog} />

              <div className="d-flex flex-column align-items-center text-dark-75 my-8">
                <Icon.Password className="icon-5x" />
                <h3>
                  <FormattedMessage id='SETTINGS.LOGIN.CHANGE_PASSWORD' />
                </h3>
              </div>

              <PasswordEditForm success={success} loading={loading} message={message} />

            </Modal.Body>

            <Modal.Footer>
              <Button variant='light' type='button' onClick={UIProps.closePasswordEditDialog} id='btn_close_modal_password'>
                <FormattedMessage id="GENERAL.CLOSE" />
              </Button>

              <Button variant='primary' type="submit" className='ml-2' disabled={loading || success}>
                <FormattedMessage id='SETTINGS.LOGIN.CHANGE_SAVE' />
              </Button>
            </Modal.Footer>

          </Form>
        </Formik>

      </Modal>
    </Portal>
  )
}
