import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ArchiveUIProvider } from './_context/ArchiveUIContext';
import { ModuleRoutes, RELATIVE_PATH } from 'constants/moduleRoutes';
import { ContentRoute } from '_metronic/layout';
import { ArchivePage } from './ArchivePage';



export default function Archive() {
  return (
    <ArchiveUIProvider>

      <Switch>
        <Redirect exact={true} from={RELATIVE_PATH} to={ModuleRoutes.ARCHIVE} />
        <ContentRoute path={ModuleRoutes.ARCHIVE} component={ArchivePage} />
      </Switch>

    </ArchiveUIProvider>
  );
}
