import React from 'react';
import { AdminRoutes, RELATIVE_PATH } from 'constants/moduleRoutes';
import { UserUIProvider } from './_context/ReportsUserUIContext';
import { ReportsUserPage } from './ReportsUserPage';
import { Redirect, Switch } from 'react-router-dom';
import { ContentRoute } from '_metronic/layout';



export default function ReportsUser() {
  return (
    <UserUIProvider>

      <Switch>
        <Redirect exact={true} from={RELATIVE_PATH} to={AdminRoutes.ADMIN_USERS_REPORTS} />
        
         <ContentRoute path={AdminRoutes.ADMIN_USERS_REPORTS} component={ReportsUserPage} />
      </Switch>

    </UserUIProvider>
  );
}
