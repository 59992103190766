import React from 'react';
import { Route } from 'react-router-dom';
import { CategoryCard } from './CategoryCard';
import { CategoryDeleteDialog } from './category-dialogs/CategoryDeleteDialog';
import { CategoryEdit } from './category-dialogs/CategoryEdit';
import { CategoryAdd } from './category-dialogs/CategoryAdd';
import { ModuleRoutes } from 'constants/moduleRoutes';



export function CategoryPage() {
  return (
    <>

      <CategoryCard />

      <Route path={ModuleRoutes.DELETE_CATEGORY}>
        {({ history, match }) => (
          <CategoryDeleteDialog
            show={match != null}
            id={match && match.params.id}
            name={match && match.params.name}
            onHide={() => history.push(ModuleRoutes.SETTINGS_CATEGORY)}
          />
        )}
      </Route>
      <Route path={ModuleRoutes.EDIT_CATEGORY}>
        {({ history, match }) => (
          <CategoryEdit
            show={match != null}
            id={match && match.params.id}
            onHide={() => history.push(ModuleRoutes.SETTINGS_CATEGORY)}
          />
        )}
      </Route>
      <Route path={ModuleRoutes.NEW_CATEGORY}>
        {({ history, match }) => (
          <CategoryAdd
            show={match != null}
            id={match && match.params.id}
            level={match && match.params.level}
            onHide={() => history.push(ModuleRoutes.SETTINGS_CATEGORY)}
          />
        )}
      </Route>

    </>
  );
}
