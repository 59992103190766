import React from 'react';
import { Route } from 'react-router-dom';
import { SuppliersCard } from './SuppliersCard';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { SearchQueryProvider } from './_context/SearchQueryProvider';
import { SuppliersDeleteDialog } from './supplier-dialogs/SuppliersDeleteDialog';
import { getLastSearch } from '_metronic/_helpers';



export function SuppliersPage() {
  const lastSearch = getLastSearch()
  return (
    <SearchQueryProvider>
      <Route path={ModuleRoutes.DELETE_SUPPLIER}>
        {({ history, match }) => (
          <SuppliersDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => history.push(ModuleRoutes.SUPPLIER + lastSearch)}
          />
        )}
      </Route>

      <Route path={ModuleRoutes.DELETE_SUPPLIER_LISTS}>
        {({ history, match }) => (
          <SuppliersDeleteDialog
            show={match != null}
            id={match && match.params.ids}
            multiple={true}
            onHide={() => history.push(ModuleRoutes.SUPPLIER + lastSearch)}
          />
        )}
      </Route>
      <SuppliersCard />
    </SearchQueryProvider>
  );
}