import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { useDashboardUIContext } from '../_context/DashboardUIContext';
import { getCountryList, addressType, convertPriceCurrency } from '_metronic/_helpers';
import { CloseButton } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl';
import { useLang } from '_metronic/i18n';
import { Icon } from '_metronic/_icons';
import { Portal } from 'react-portal';
import { isEmpty } from 'lodash';



export function CustomerDetails() {

  const locale = useLang();

  const UIContext = useDashboardUIContext()
  const UIProps = useMemo(() => ({
    show: UIContext.showCustomerDetailsDialog,
    onHide: UIContext.closeCustomerDetailsDialog,
    customerId: UIContext.customerId,
  }), [
    UIContext.closeCustomerDetailsDialog,
    UIContext.showCustomerDetailsDialog,
    UIContext.customerId
  ]);

  const { top_costumer } = useSelector(state => state.dashboard)

  const customer = top_costumer.list?.find((customer) => customer.customerId === UIProps.customerId)

  useEffect(() => {
    !UIProps.customerId && UIProps.onHide();
  }, [UIProps]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(CustomerActions.resetFormHandler());
  //     UIProps.cleanUpContextHandler();
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <Portal node={document && document.getElementById('modal-portal')}>

      <Modal show={UIProps.show} onHide={UIProps.onHide} keyboard={false} aria-labelledby="example-modal-sizes-title-md" size="md" centered>

        <Modal.Header>

          <Modal.Title>
            <Icon.Person className="mr-3" />
            <FormattedMessage id="DOCUMENT.CUSTOMER_TITLE" />
          </Modal.Title>

          <CloseButton onClick={UIProps.onHide} />

        </Modal.Header>

        <Modal.Body className='overflow-hidden px-10 py-2'>

          {customer && !isEmpty(customer) && (

            <div className='d-flex flex-column font-weight-bold text-break'>

              {customer.number &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.CUSTOMER_NUMBER" />
                  </Col>
                  <Col lg='7'>
                    {customer.number}
                  </Col>
                </Row>
              }

              {customer.customer_address.name &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.NAME" />
                  </Col>
                  <Col lg='7'>
                    {customer.customer_address.name}
                  </Col>
                </Row>
              }

              {customer.order_count > 0 &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.QUANTITY" />
                  </Col>
                  <Col lg='7'>
                    {customer.order_count}
                  </Col>
                </Row>
              }

              <Row className='border-bottom py-3'>
                <Col lg='5' className="text-muted mb-2 mb-sm-0">
                  <FormattedMessage id="GENERAL.SALE" />
                </Col>
                <Col lg='7' className="text-primary">
                  {convertPriceCurrency(+customer?.total_sales?.toFixed(2), customer?.currency)}
                </Col>
              </Row>

              {[customer.customer_address].map((address, i) => (
                <Row className='py-3' key={i}>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    {addressType.find(item => item.value === address?.type)?.label ?? <FormattedMessage id="GENERAL.ADDRESS" />}
                  </Col>
                  <Col lg='7'>
                    <div>{address?.name}</div>
                    <div>{address?.company}</div>
                    <div>{address?.address_line1} {address?.address_line2}</div>
                    <div>{address?.post_code} {address?.city}</div>
                    <div>{getCountryList(locale).find(code => code.value === address?.country_code)?.label}</div>
                  </Col>
                </Row>
              ))}

            </div>
          )}
        </Modal.Body>

        <Modal.Footer>

          <Button variant='light' onClick={UIProps.onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          {/* <Link className="btn btn-primary mr-2" to={ModuleRoutes.EDIT_CUSTOMER_FN(UIProps.customerId)} id='btn_edit_modal'>
            <FormattedMessage id='GENERAL.EDIT' />
          </Link> */}

        </Modal.Footer>

      </Modal>
    </Portal>
  );
};
