import React, { useState } from "react";
import { ActionsColumnFormatter } from "./ActionsColumnFormatter"
import { makeStyles, List, ListItem, ListItemIcon, Collapse, ListItemText } from "@material-ui/core";
import { Icon } from '_metronic/_icons';



export const useStyles = makeStyles((style) => ({
  nested: { marginLeft: style.spacing(2) },
  level1: { marginLeft: style.spacing(4) },
  level2: { marginLeft: style.spacing(6) },
  level3: { marginLeft: style.spacing(8) },
  level4: { marginLeft: style.spacing(10) },
  level5: { marginLeft: style.spacing(12) },
  level6: { marginLeft: style.spacing(14) },
}));


export const CategoryExtendedList = ({ categories }) => {

  const classes = useStyles();

  const ExtendedCollapse = ({ child, open }) => {

    let nameClasses = classes[`level${child.level}`]
      ? classes[`level${child.level}`]
      : classes.level1;


    if (child.children.length === 0) {
      return (
        <Collapse key={child.id} in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem key={child.id} className={`border-bottom ${nameClasses}`} style={{ width: "unset" }}>
              <ListItemIcon>
                <Icon.CardText className="icon-lg" />
              </ListItemIcon>
              <ListItemText primary={child.name} className="font-weight-bold" />
              <ActionsColumnFormatter catId={child.id} catName={child.name} level={child.level} catKey={child.key}/>
            </ListItem>
          </List>
        </Collapse>
      );
    } else {
      return (
        <Collapse key={child.id} in={open} timeout="auto" unmountOnExit>
          <List key={child.id} component="div" disablePadding>
            <ListItemWithCollapse category={child} nameClasses={nameClasses} />
          </List>
        </Collapse>
      );
    }
  };



  const ListItemWithoutCollapse = ({ category, nameClasses }) => {
    return (
      <ListItem key={category.id} className={`border-bottom ${nameClasses}`} style={{ width: "unset" }}>
        <ListItemIcon>
          <Icon.CardText className="icon-lg" />
        </ListItemIcon>
        <ListItemText primary={category.name} className="font-weight-bold" />
        <ActionsColumnFormatter catId={category.id} catName={category.name} level={category.level} catKey={category.key}/>
      </ListItem>
    );
  };


  const ListItemWithCollapse = ({ category, nameClasses }) => {
    const [open, setOpen] = useState(false);
    return (
      <>
        <ListItem type="button" key={category.id} className={`border-bottom ${nameClasses}`} style={{ width: "unset" }}>
          <ListItemIcon onClick={() => setOpen(!open)}>
            {open
              ? <Icon.FolderOpen className="icon-lg" />
              : <Icon.Folder className="icon-lg" />
            }
          </ListItemIcon>
          <ListItemText onClick={() => setOpen(!open)} primary={category.name} className="font-weight-bold" />
          <ActionsColumnFormatter catId={category.id} catName={category.name} level={category.level} catKey={category.key}/>
        </ListItem>

        {category.children.map((child) => (
          <ExtendedCollapse key={child.id} child={child} open={open} />
        ))}

      </>
    );
  };


  return (
    <List component="nav" aria-labelledby="nested-list-subheader" className={`py-0 ${classes.root}`}>
      {categories.map((category) => (category.children.length === 0
        ? <ListItemWithoutCollapse category={category} nameClasses={classes.level1} key={category.id} />
        : <ListItemWithCollapse category={category} nameClasses={classes.level1} key={category.id} />
      ))}
    </List>
  );
};
