/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getConnection } from 'constants/connectionSettings';
import * as UIHelpers from '../../_context/DocumentsUIHelpers';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import clsx from 'clsx';



export function OrderIdFormatter(cell, row, rowIndex) {

  const order = <div className='d-flex align-items-center'>
    <span className='svg-icon svg-icon-md'>
      {getConnection(row?.marketplace)?.icon ?? <Icon.ShopWindow className="text-dark-25" />}
    </span>
    <span className='font-weight-bold ml-3'>
      {cell ? cell : row.id}
    </span>
  </div>

  return (
    row?.marketplace_url
      ? <OverlayTrigger overlay={<Tooltip id="market-tooltip">{row?.marketplace_url}</Tooltip>}>
        {order}
      </OverlayTrigger>
      : order
  )
}


export function ShippingFormatter(cell, row, rowIndex) {
  if (cell.length > 0) {
    // Diğer marketlerden farklı olarak OTTO'da nakliye servisi bilgisi zorunlu
    // O yüzden fulfillment formunda nakliyesiz OTTO varsa shipping kısmında buna yönelik bilgi veriyoruz.

    const firstShipping = cell[0];
    const connection = getConnection(firstShipping?.service);

    return (
      <OverlayTrigger
        overlay={
          <Tooltip id="first-shipping-tooltip">
            <div className={clsx('font-weight-bolder', (firstShipping?.tracking_id || firstShipping?.error) && 'border-bottom mb-2')}>
              {connection.label}
            </div>
            <div className={clsx('font-weight-bold', firstShipping?.error && 'text-danger')}>
              {firstShipping?.error ? firstShipping.error
                : firstShipping?.service ? firstShipping?.tracking_id : <FormattedMessage id="DOCUMENT.CONVERT.OTTO.NO_SHIPPING" />}
            </div>
          </Tooltip>
        }
      >
        <div className='symbol-group symbol-hover justify-content-center'>
          <div className='symbol d-flex bg-white' style={{ fontSize: "2rem" }}>
            {firstShipping?.error ? <Icon.ErrorFill />
              : (row.marketplace === "otto" && !firstShipping?.service)
                ? <Icon.ExclamationCircleFill className="text-danger" />
                : firstShipping?.unlabeled ? <Icon.SupplierFIll /> : connection?.icon}
          </div>
        </div>
      </OverlayTrigger>
    );
  } else {
    return
  }
}



export function PaymentStatusFormatter(cell, row, rowIndex) {
  return (
    <span className={`label label-inline label-lg label-${cell === 'paid' ? 'outline-success' : 'danger'}`}>
      {UIHelpers.paymentStatus.find(payment => payment.value === cell)?.label ?? <FormattedMessage id="GENERAL.OTHER" />}
    </span>
  )
}


export function InvoiceFormatter(cell, row, rowIndex) {
  return (
    <OverlayTrigger overlay={<Tooltip id="create_invoice">{<FormattedMessage id={row?.error?.invoice ?? 'DOCUMENT.FULFILLMENT.SUCCESS'} />} </Tooltip>}>
      <span className="svg-icon svg-icon-sm">
        {cell !== undefined && (
          cell
            ? <Icon.CheckCircleFill className="text-success" />
            : <Icon.CheckCircleFill className="text-warning" />
        )}
      </span>
    </OverlayTrigger>
  )
}


export function WaybillFormatter(cell, row, rowIndex) {
  return (
    <OverlayTrigger overlay={<Tooltip id="create_invoice">{<FormattedMessage id={row?.error?.waybill ?? 'DOCUMENT.FULFILLMENT.SUCCESS'} />} </Tooltip>}>
      <span className="svg-icon svg-icon-sm">
        {cell !== undefined && (
          cell
            ? <Icon.CheckCircleFill className="text-success" />
            : <Icon.CheckCircleFill className="text-warning" />
        )}
      </span>
    </OverlayTrigger>
  )
}


export function EmailFormatter(cell, row, rowIndex) {
  return (
    <OverlayTrigger overlay={<Tooltip id="create_invoice">{row?.error?.email ?? <FormattedMessage id='DOCUMENT.FULFILLMENT.SUCCESS' />}</Tooltip>}>
      <span className="svg-icon svg-icon-sm">
        {cell !== undefined && (
          cell
            ? <Icon.CheckCircleFill className="text-success" />
            : <Icon.BanFill className="text-warning" />
        )}
      </span>
    </OverlayTrigger>
  )
}


export function FulfillmentFormatter(cell, row, rowIndex) {
  return (
    <OverlayTrigger placement="bottom" overlay={<Tooltip id="create_invoice">{row?.error?.fulfillment ?? <FormattedMessage id='DOCUMENT.FULFILLMENT.SUCCESS' />} </Tooltip>}>
      <span className="svg-icon svg-icon-sm">
        {cell !== undefined && (
          cell
            ? <Icon.CheckCircleFill className="text-success" />
            : <Icon.BanFill className="text-danger" />
        )}
      </span>
    </OverlayTrigger>
  )
}


export function ActionFormatter(cellContent, row, rowIndex, { deleteCompleteList }) {
  return (
    <OverlayTrigger placement="top" overlay={<Tooltip id={`fulfillment-delete-tooltip-${rowIndex}`}><FormattedMessage id="DOCUMENT.FULFILLMENT.DELETE" /></Tooltip>}>
      <span className="d-inline-block">
        <a className="btn btn-icon btn-sm" onClick={() => deleteCompleteList(row?.id)} id={`btn_delete_${row?.id}`}>
          <Icon.Close className="text-danger" />
        </a>
      </span>
    </OverlayTrigger>
  );
}
