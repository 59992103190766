
import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { sortCaret, headerSortingClasses, NoRecordsFoundMessage, UIDates, convertPriceCurrency } from '_metronic/_helpers';
import { useIntl } from 'react-intl';
import { RemotePagination } from '_metronic/_partials/controls';
import { allQueryKeysOrderList } from '../../_context/ReportUIHelpers';



export function OrderListTable({ entities, loading, btnRef, UIProps, totalCount }) {

  const { formatMessage: intl } = useIntl();

  const COLUMNS = [
    {
      dataField: '#',
      text: 'No',
      headerStyle: { minWidth: '60px', width: "5%" },
      headerClasses: 'text-center text-nowrap',
      classes: 'font-weight-bold text-center py-5',
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      csvExport: false
    },
    {
      dataField: 'title',
      text: intl({ id: 'GENERAL.NAME' }),
      type: 'string',
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '300px' },
      classes: 'font-weight-bold text-left',
      formatter: (cell, row) => (
        <div className="text-dark-75 text-hover-primary">
          <span className='font-weight-bolder'>{cell}</span>
          <div className="font-weight-bold text-dark-50">
            {row.sku}
          </div>
        </div>
      ),
    },
    {
      dataField: 'quantity',
      text: intl({ id: 'PRODUCT.GENERAL.QUANTITY' }),
      type: 'number',
      csvType: Number,
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '70px', width: "8%" },
      headerClasses: 'text-center text-nowrap',
      classes: 'font-weight-bolder text-center',
    },
    {
      dataField: 'tax_rate',
      text: intl({ id: 'PRODUCT.TABLE.TAX' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '70px', width: "8%" },
      headerClasses: 'text-center text-nowrap',
      classes: 'font-weight-bolder text-center text-nowrap',
      formatter: (cell, row) => `${cell} %`
    },
    {
      dataField: 'net_price',
      text: intl({ id: 'PRODUCT.TABLE.PRICE_NET' }),
      type: 'number',
      csvType: Number,
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '100px', width: "10%" },
      headerClasses: 'text-right text-nowrap',
      classes: 'font-weight-bolder text-right text-nowrap pr-6',
      formatter: (cell, row) => {
        return convertPriceCurrency(cell, row.currency)
      },
    },
    {
      dataField: 'gross_price',
      text: intl({ id: 'PRODUCT.TABLE.PRICE_GROSS' }),
      type: 'number',
      csvType: Number,
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '100px', width: "10%" },
      headerClasses: 'text-right text-nowrap',
      classes: 'font-weight-bolder text-right text-nowrap pr-6',
      formatter: (cell, row) => {
        return convertPriceCurrency(cell, row.currency)
      }
    },
  ];

  const ExportCSV = (props) => (<button className="d-none" ref={btnRef} onClick={() => props.onExport()}></button>);

  return (
    <>
      <ToolkitProvider
        keyField="id"
        data={entities || []}
        columns={COLUMNS}
        exportCSV={{ fileName: `order_list_${UIDates.exportName()}.csv`, }}
      >
        {(props) => (
          <>
            <ExportCSV  {...props.csvProps} />
            <BootstrapTable
              wrapperClasses="table-responsive"
              classes="table table-head-custom table-vertical-center table-sm overflow-hidden"
              bootstrap4
              bordered={false}
              condensed
              striped
              defaultSorted={[{ dataField: 'quantity', order: 'desc' }]}
              noDataIndication={<NoRecordsFoundMessage loading={loading} onClick={() => { UIProps.setFilterQuery('DELETE_PARAMS', allQueryKeysOrderList) }} />}
              {...props.baseProps}
            />
          </>
        )
        }
      </ToolkitProvider>

      {entities.length
        ? <RemotePagination
          queryParams={UIProps.queryParams?.pagination}
          setQueryParams={UIProps.setPaginationQuery}
          totalCount={totalCount}
          entitiesCount={entities?.length}
          loading={loading}
        />
        : null
      }
    </>
  );
}
