import React, { useEffect, useState } from "react";
import { Card, CardBody, CardSubHeader } from "_metronic/_partials/controls";
import { UserGuideHelper } from "app/user-guides/UserGuideHelper";
import { importExportActions } from "../../_redux/actions";
import { EditErrorDialog } from "app/modules/product-listings/ebay-listings/ebay-listings-edit/EditErrorDialog";
import { ModuleRoutes } from "constants/moduleRoutes";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ImportCustomersTable } from "./ImportCustomerTable";
import { COUNTRY_DE, utils } from "_metronic/_helpers";
import { checkImportCustomer } from "./ImportCustomerHelper";
import { BlankMessage } from "_metronic/_partials/components";
import { FormattedMessage } from "react-intl";
import { useLang } from "_metronic/i18n";
import { Icon } from '_metronic/_icons';



export default function ImportCustomerPreparation({ submitBtnRef }) {

  const dispatch = useDispatch();
  const history = useHistory();
  const language = useLang();
  const [openErrorDialog, setOpenErrorDialog] = useState({ show: false, errors: [] });

  const { importPreparation, isImport, checkData } = useSelector((state) => ({
    importPreparation: state.importExport.importPreparation,
    isImport: state.importExport.isImport,
    checkData: state.importExport.checkData,
  }));

  const closeErrorDialog = () => setOpenErrorDialog({ show: false, errors: [] });

  const handlerCustomer = () => {

    // product create
    const customersCreate = [];
    for (const customerList of importPreparation) {
      const {
        type, name, company, phone, mobile,
        tax_number, vat_id, fax, email, bank, iban, bic,
        address_customer_name_1, address_company_name_1,
        address_line1_1, address_line2_1, post_code_1, city_1, country_code_1,
        address_customer_name_2, address_company_name_2,
        address_line1_2, address_line2_2, post_code_2, city_2, country_code_2,
        discount,notes
      } = customerList;

      let customerType = 'standard';

      if (type) {
        if (type === 'standard' || type === 'private' || type === 'company') {
          customerType = type;
        }
      }

      const checkCountryCode = (code) => COUNTRY_DE.some(country => country.value === code?.toUpperCase()) ? code?.toUpperCase() : '';
      const replaceFn = (value, newValue) => (value ? value : newValue);

      const customerAddresses = [
        {
          id: utils.uuidv4(),
          type: 'address_delivery',
          salutation: '',
          name: replaceFn(address_customer_name_1, name),
          company: replaceFn(address_company_name_1, company),
          address_line1: address_line1_1 ?? '',
          address_line2: address_line2_1 ?? '',
          post_code: post_code_1 ?? '',
          city: city_1 ?? '',
          country_code: checkCountryCode(country_code_1),
        },
        {
          id: utils.uuidv4(),
          type: 'address_invoice',
          salutation: '',
          name: replaceFn(address_customer_name_2, replaceFn(address_customer_name_1, name)),
          company: replaceFn(address_company_name_2, replaceFn(address_company_name_1, company)),
          address_line1: replaceFn(address_line1_2, address_line1_1),
          address_line2: replaceFn(address_line2_2, address_line2_1),
          post_code: replaceFn(post_code_2, post_code_1),
          city: replaceFn(city_2, city_1),
          country_code: checkCountryCode(replaceFn(country_code_2, country_code_1)),
        }
      ];

      const customer = {
        type: customerType,
        name: name ?? '',
        company: company ?? '',
        email: email ?? '',
        fax: fax ?? '',
        mobile: mobile ?? '',
        phone: phone ?? '',
        tax_number: tax_number ?? '',
        vat_id: vat_id ?? '',
        address: customerAddresses ?? [],
        bank: {
          name: bank ?? '',
          bic: bic ?? '',
          iban: iban ?? '',
        },
        discount: discount ?? 0,
        notes: notes ?? '',
      };

      customersCreate.push(customer);

    };
    const emailsTable = importPreparation.map(item => item.email)
    const errors = checkImportCustomer(importPreparation, checkData, emailsTable);
    if (Array.isArray(errors) && errors.length > 0) {
      setOpenErrorDialog({ show: true, errors: errors });
      return;
    };

    dispatch(importExportActions.importCustomerCreate(customersCreate, language))
  };

  useEffect(() => {
    if (isImport) {
      history.push(ModuleRoutes.CUSTOMER)
    }
    return () => {
      dispatch(importExportActions.resetFormHandler());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isImport]);


  return (
    <>
      <EditErrorDialog show={openErrorDialog.show} onHide={closeErrorDialog} errors={openErrorDialog.errors} />

      <Card className="card-height">

        <CardSubHeader
          icon={<Icon.PencilSquare />}
          title={<FormattedMessage id='IMPORT.CUSTOMER.PREPARE_LIST' />}
          info={<UserGuideHelper />}
          id={'import_product'}
        />

        <CardBody>
          {importPreparation.length > 0
            ? <ImportCustomersTable />
            : <BlankMessage
              icon={<Icon.TextIndentLeft />}
              title={<FormattedMessage id='IMPORT_EXPORT.IMPORT.BLANK_MESSAGE_TITLE' />}
              message={<FormattedMessage id='IMPORT_EXPORT.IMPORT.BLANK_MESSAGE' />}
            />}
          <button type="submit" className='d-none' ref={submitBtnRef} onClick={handlerCustomer} />
        </CardBody>

      </Card>
    </>
  )
}
