import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { useArchiveUIContext } from "../_context/ArchiveUIContext";
import { ArchiveActions } from "../_redux/actions";
import { FormattedMessage } from "react-intl";
import { theme } from "_metronic/_helpers";
import { Icon } from '_metronic/_icons';



export function ArchiveGrouping() {

  const dispatch = useDispatch();

  const UIContext = useArchiveUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    openArchiveRelocateDialog: UIContext.openArchiveRelocateDialog,
    requestedArchive: UIContext.requestedArchive,
    openArchivePrintDialog: UIContext.openArchivePrintDialog,
  }), [UIContext]);

  const openDeleteArchiveDialog = () => {
    const getIds = [];
    UIProps.ids.forEach(id => Object.values(id).forEach(i => getIds.push(i)));
    if (getIds.length > 0) {
      UIProps.openArchiveRelocateDialog(getIds)
    }
  };

  const printSelectedDocuments = () => {

    const getIds = UIProps.ids.flatMap(id => Object.values(id));

    const data = {
      ids: getIds,
      type: UIProps.requestedArchive.value
    };

    if (getIds.length > 0) {
      dispatch(ArchiveActions.printRequest(data));
      UIProps.openArchivePrintDialog();
    }
  };


  return (
    <>

      <Button variant="light-primary" className="svg-icon svg-icon-sm text-nowrap font-weight-bold ml-auto mb-2"
        style={{ height: theme.units.input.height['sm'], cursor: !UIProps.ids || (UIProps.ids.length <= 0 && 'not-allowed') }}
        onClick={openDeleteArchiveDialog}
        disabled={!UIProps.ids || UIProps.ids.length <= 0}
        id='btn_archive'
      >
        <Icon.Archive />
        <span className="d-none d-sm-inline ml-2">
          <FormattedMessage id="ARCHIVE.UNARCHIVE_MESSAGE" />
        </span>
      </Button>

      <Button variant="light-primary" className="svg-icon svg-icon-sm text-nowrap font-weight-bold ml-2 mb-2"
        style={{ height: theme.units.input.height['sm'], cursor: !UIProps.ids || (UIProps.ids.length <= 0 && "not-allowed") }}
        onClick={printSelectedDocuments}
        disabled={!UIProps.ids || UIProps.ids.length <= 0}
        id="btn_print"
      >
        <Icon.Printer />
        <span className="d-none d-sm-inline ml-2">
          <FormattedMessage id="GENERAL.PRINT" />
        </span>
      </Button>

    </>
  );
}
