/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Icon } from '_metronic/_icons';



export const ActionsColumnFormatter = (cellContent, row, rowIndex, { openEditBankDialog, openDeleteBankDialog }) => (
  <>
    <OverlayTrigger overlay={<Tooltip id="bank-edit-tooltip"><FormattedMessage id="GENERAL.EDIT" /></Tooltip>}>
      <a className="btn btn-icon btn-hover-primary btn-sm mx-3" onClick={() => openEditBankDialog(row.id)} id={`btn_edit_${row?.id}`}>
        <span className="svg-icon svg-icon-md">
          <Icon.PencilSquare />
        </span>
      </a>
    </OverlayTrigger>
    <OverlayTrigger overlay={<Tooltip id="bank-delete-tooltip"><FormattedMessage id="GENERAL.DELETE" /></Tooltip>}>
      <a className="btn btn-icon btn-hover-danger btn-sm" onClick={() => openDeleteBankDialog(row.id)} id={`btn_delete_${row?.id}`}>
        <span className="svg-icon svg-icon-md">
          <Icon.Trash />
        </span>
      </a>
    </OverlayTrigger>
  </>
);
