import React from 'react';
import { isArray, isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { eanFunctions, isbnFunctions, upcFunctions, utils } from '_metronic/_helpers';
import { getVariantSpecs } from '../../products/product-edit/variants/core/helpers';



export const searchProperties = ['name', 'sku', 'variants.sku', 'variants.ean', 'pricing.purchase_price', 'identities', 'brand'];

export const productQueryFields = [
  'id',
  'name',
  'sku',
  'identities',
  'pricing',
  'variants',
  'features',
  'quantity',
  'brand',
  // 'short_description',
  'description',
  'condition',
  'dimensions',
  // 'epid',
  'inventories',
  'images'
];

// FIXME: not used
export const lengthUnitEnum = [
  {
    value: 'INCH',
    label: 'Inch',
  },
  {
    value: 'FEET',
    label: 'Feet',
  },
  {
    value: 'CENTIMETER',
    label: 'Centimeter',
  },
  {
    value: 'METER',
    label: 'Meter',
  },
];

// FIXME: not used
export const weightUnitEnum = [
  {
    value: 'GRAM',
    label: 'Gram',
  },
  {
    value: 'KILOGRAM',
    label: 'Kilogram',
  },
  {
    value: 'OUNCE',
    label: 'Ounce',
  },
  {
    value: 'POUND',
    label: 'Pound',
  },
];

// FIXME: not used
export const packageTypeEnum = [
  {
    value: 'LETTER',
    label: 'Letter',
  },
  {
    value: 'BULKY_GOODS',
    label: 'Bulky Goods',
  },
  {
    value: 'CARAVAN',
    label: 'Caravan',
  },
  {
    value: 'CARS',
    label: 'Cars',
  },
  {
    value: 'EUROPALLET',
    label: 'Europallet',
  },
  {
    value: 'EXPANDABLE_TOUGH_BAGS',
    label: 'Expandable Tough Bags',
  },
  {
    value: 'EXTRA_LARGE_PACK',
    label: 'Extra Large Pack',
  },
  {
    value: 'FURNITURE',
    label: 'Furniture',
  },
  {
    value: 'INDUSTRY_VEHICLES',
    label: 'Industry Vehicles',
  },
  {
    value: 'LARGE_CANADA_POSTBOX',
    label: 'Large Canada Postbox',
  },
  {
    value: 'LARGE_CANADA_POST_BUBBLE_MAILER',
    label: 'Large Canada Post Bubble Mailer',
  },
  {
    value: 'MEDIUM_CANADA_POST_BUBBLE_MAILER',
    label: 'Medium Canada PostBubble Mailer',
  },
  {
    value: 'MEDIUM_CANADA_POST_BOX',
    label: 'Medium Canada Post Box',
  },
  {
    value: 'LARGE_ENVELOPE',
    label: 'Large Envelope',
  },
  {
    value: 'MAILING_BOX',
    label: 'Mailing Box',
  },
  {
    value: 'MOTORBIKES',
    label: 'Motorbikes',
  },
  {
    value: 'ONE_WAY_PALLET',
    label: 'One Way Pallet',
  },
  {
    value: 'PACKAGE_THICK_ENVELOPE',
    label: 'Package Thick Envelope',
  },
  {
    value: 'PADDED_BAGS',
    label: 'Package Bags',
  },
  {
    value: 'PARCEL_OR_PADDED_ENVELOPE',
    label: 'Parcel or Padded Envelope',
  },
  {
    value: 'ROLL',
    label: 'ROLL',
  },
  {
    value: 'SMALL_CANADA_POST_BOX',
    label: 'Small Canada Post Box',
  },
  {
    value: 'SMALL_CANADA_POST_BUBBLE_MAILER',
    label: 'Small Canada Post Bubble Mailer',
  },
  {
    value: 'TOUGH_BAGS',
    label: 'Tough Bags',
  },
  {
    value: 'UPS_LETTER',
    label: 'Ups Letter',
  },
  {
    value: 'USPS_FLAT_RATE_ENVELOPE',
    label: 'Usps Flat Rate Envelope',
  },
  {
    value: 'USPS_LARGE_PACK',
    label: 'Usps Large Pack',
  },
  {
    value: 'VERY_LARGE_PACK',
    label: 'Very Large Pack',
  },
  {
    value: 'WINE_PAK',
    label: 'Wine Pak',
  },
];

// FIXME: not used
const conditionsEnum = [
  'NEW',
  'LIKE_NEW',
  'NEW_OTHER',
  'NEW_WITH_DEFECTS',
  'MANUFACTURER_REFURBISHED',
  'CERTIFIED_REFURBISHED',
  'EXCELLENT_REFURBISHED',
  'VERY_GOOD_REFURBISHED',
  'GOOD_REFURBISHED',
  'SELLER_REFURBISHED',
  'USED_EXCELLENT',
  'USED_VERY_GOOD',
  'USED_GOOD',
  'USED_ACCEPTABLE',
  'FOR_PARTS_OR_NOT_WORKING',
];


export const getVariantOptionsKeysAndValues = variantOptionen => {
  if (!variantOptionen && isEmpty(variantOptionen)) {
    return [];
  }
  return Object.entries(variantOptionen)
    .map(([key, value]) => {
      if (value && typeof value === 'string' && value.trim().length > 0) {
        return { key, value };
      }
      return null;
    })
    .filter(item => item !== null);
};


export const getVariantOptionsColumns = variantOptionen => {
  const variantOptions = getVariantOptionsKeysAndValues(variantOptionen);
  if (variantOptions.length > 0) {
    return variantOptions.map(option => ({
      dataField: option.key,
      text: option.value,
      headerStyle: (column, colIndex) => {
        return { width: '15%', textAlign: 'center' };
      },
      style: { textAlign: 'center' },
      formatter: (cell, row) => {
        return row[option.key];
      },
    }));
  }
  return [];
};


export const getVariantSpecColumns = variants => {
  return variants.map(variant => {
    const specs = variant.specs;
    if (isArray(specs)) {
      return specs.map(spec => spec.value).join(' | ');
    }
    return '';
  });
};


export const getAspectRows = ({
  name = '',
  nameIsLocalized = false,
  isRequired = false,
  options = null,
  mode = 'FREE_TEXT',
  dataType = 'string',
  value = '',
  id = utils.uuidv4(),
}) => {
  return {
    id,
    isRequired,
    name,
    nameIsLocalized,
    options,
    value,
    mode,
    dataType,
  };
};


export const checkEbayInventorySync = values => {
  const errors = [];
  const createError = (field, message, tab, required = true) => {
    errors.push({ field, message, tab, required });
  };
  const { images, variants, name, condition, quantity, description, identities, pricing /* , variantsdescription */ } = values;

  const variant = variants.length > 0 ? true : false;

  if (variant) {
    const isEan = variants.filter((variant) => !eanFunctions(variant?.ean)).map((variant) => variant.sku);
    const variantFilter = variants.filter((a) => a.quantity !== 0);

    isEan.length > 0 &&
      createError(
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.IDENTITIES.LINK" values={{ name: 'EAN' }} />,
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.IDENTITIES" values={{ name: `EAN (SKU: ${isEan.join(', ')})` }} />,
        'variant'
      );

    variantFilter.length === 0 &&
      createError(
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.QUANTITY.LINK" />,
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.QUANTITY_VARIANT" />,
        'variant'
      );

    (variantFilter.length !== 0
      ? variantFilter?.every(a => a.pricing.gross_price < 1)
      : variants.every(a => a.pricing.gross_price < 1)) &&
      createError(
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.PRICING.LINK" />,
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.PRICING" />,
        'variant'
      );
  } else {

    !eanFunctions(identities?.ean) &&
      createError(
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.IDENTITIES.LINK" values={{ name: 'EAN' }} />,
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.IDENTITIES" values={{ name: 'EAN' }} />,
        'general'
      )

    pricing.gross_price < 1 &&
      createError(
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.PRICING.LINK" />,
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.PRICING" />,
        'general'
      );

    quantity < 1 &&
      createError(
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.QUANTITY.LINK" />,
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.QUANTITY" />,
        variant ? 'variants' : 'general'
      );
  }

  if (!isbnFunctions(identities?.isbn)) {
    createError(
      <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.IDENTITIES.LINK" values={{ name: 'Isbn' }} />,
      <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.IDENTITIES" values={{ name: 'Isbn' }} />,
      'general'
    )
  }
  if (!upcFunctions(identities?.upc)) {
    createError(
      <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.IDENTITIES.LINK" values={{ name: 'Upc' }} />,
      <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.IDENTITIES" values={{ name: 'Upc' }} />,
      'general'
    )
  }

  if (images?.length === 0)
    createError(
      <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.PICTURE.LINK" />,
      <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.PICTURE" />,
      'pictures'
    );
  if (!name)
    createError(
      <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.NAME.LINK" />,
      <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.NAME" />,
      'general'
    );
  if (!condition || !conditionsEnum.includes(condition))
    createError(
      <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.CONDITION.LINK" />,
      <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.CONDITION" />,
      'general'
    );

  if (!description)
    createError(
      <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.DESCRIPTION.LINK" />,
      <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.DESCRIPTION" />,
      'description'
    );
  return errors;
};


const checkEbayInventorySave = values => {

  const errors = [];

  const createError = (field, message, tab, required = true) => {
    errors.push({ field, message, tab, required });
  };

  const { variants, quantity, pricing } = values;
  const variant = variants.length > 0 ? true : false;

  if (variant) {
    variants.some((a) => a.quantity === 0) &&
      createError(
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.QUANTITY_VARIANT" />
      );

    variants.some((a) => a.pricing.gross_price < 1) &&
      createError(
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.PRICING" />
      );

  } else {
    pricing.gross_price < 1 &&
      createError(
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.PRICING" />
      );

    quantity < 1 &&
      createError(
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.QUANTITY" />
      );
  }

  return errors;
};


const createEbayProduct = (values, aspects, productId) => {

  const { identities, dimensions, condition, quantity, /* images, */ description, merchantLocationKey, name, pricing } = values;

  const isDimenishing = dimensions?.height > 0 && dimensions?.width > 0 && dimensions?.length > 0;

  const mpn = identities?.mpn || productId;

  const availabilityDistributions = merchantLocationKey && [{ quantity: +quantity, merchantLocationKey, }];

  const brand = aspects['Brand'] ? aspects['Brand'][0] : aspects['Marke'] ? aspects['Marke'][0] : null;

  const ean = typeof identities?.ean === 'string' && identities?.ean.trim().length > 0
    ? identities?.ean.trim()
    : 'Nicht zutreffend';  //TODO: formatMessage yapılmayacak * tekrar bakılacak 

  // const imageUrls = images?.map(image => image.url); BE'de eklenecek

  const inventory = {
    product: {
      title: name.toString().substring(0, 80),
      aspects,
      description: description.toString().substring(0, 4000),
      ean: [ean], // variant
      //imageUrls,
      ...(brand != null && { brand }),
      ...(mpn && { mpn }),
      ...(identities?.isbn && { isbn: [identities?.isbn] }),
      ...(identities?.upc && { upc: [identities?.upc] }),
      //...(values.epid && { epid: values.epid }),
      // ...(values.subtitle && { subtitle: values.subtitle }),
      // videoIds : ["string"]
    },
    condition,
    ...(isDimenishing &&
      +dimensions?.weight > 0 && {
      packageWeightAndSize: {
        dimensions: {
          height: +dimensions?.height,
          length: +dimensions?.length,
          width: +dimensions?.width,
          unit: 'CENTIMETER',
        },
        weight: {
          value: +dimensions?.weight ?? 2,
          unit: 'KILOGRAM',
        },
        // packageType: 'MAILING_BOX',
      },
    }),
    availability: {
      shipToLocationAvailability: {
        ...(isArray(availabilityDistributions) && availabilityDistributions.length > 0 && { availabilityDistributions }),
        quantity: +quantity,
      },
    },
    pricing: pricing
  };

  const shopValue = {
    pricing: pricing.gross_price,
    quantity: quantity,
    name: name,
  };

  const preparedInventory = {
    isGrouped: false,
    shopValue: shopValue,
    inventory: inventory
  };

  return preparedInventory;
};


const createEbayProductVariant = (values, aspects, aspectsImageVariesBy, productId) => {
  const { identities, dimensions, condition, quantity, /* images, */ description, merchantLocationKey, name, variants } = values;

  const preparedInventories = [];

  const variantShopValue = [];

  for (const variant of variants) {
    const variantAspects = {};
    for (const spec of variant.specs) {
      variantAspects[spec.name] = [spec.value];
    }

    const isDimenishing = dimensions?.height > 0 && dimensions?.width > 0 && dimensions?.length > 0;
    const mpn = identities?.mpn || productId;
    const availabilityDistributions = merchantLocationKey && [
      {
        quantity: +quantity,
        merchantLocationKey,
      },
    ];
    const brand = aspects['Brand'] ? aspects['Brand'][0] : aspects['Marke'] ? aspects['Marke'][0] : null;
    const ean = typeof variant.ean === 'string' && variant.ean.trim().length > 0
      ? variant.ean.trim()
      : 'Nicht zutreffend'; //TODO: formatMessage yapılmayacak * tekrar bakılacak 
    //const imageUrls = images?.map(image => image.url) BE'de eklenecek

    const inventory = {
      sku: variant.sku,
      pricing: variant.pricing,
      product: {
        title: name.toString().substring(0, 80),
        aspects: {
          ...aspects,
          ...variantAspects,
        },
        description: description.toString().substring(0, 4000),
        ean: [ean], // variant
        //imageUrls, //varyantın img eklenecek şimdi ana productın img gidiyor
        ...(brand != null && { brand }),
        ...(mpn && { mpn }),
        ...(identities?.isbn && { isbn: [identities?.isbn] }),
        // ...(identities?.epid && { epid: identities?.epid }),
        // ...(values.subtitle && { subtitle: values.subtitle }),
        ...(identities?.upc && { upc: [identities?.upc] }),
      },
      condition,
      ...(isDimenishing &&
        +dimensions?.weight > 0 && {
        packageWeightAndSize: {
          dimensions: {
            height: +dimensions?.height,
            length: +dimensions?.length,
            width: +dimensions?.width,
            unit: 'CENTIMETER',
          },
          weight: {
            value: dimensions?.weight ?? 2,
            unit: 'KILOGRAM',
          },
          // packageType: 'MAILING_BOX',
        },
      }),
      availability: {
        shipToLocationAvailability: {
          ...(isArray(availabilityDistributions) && availabilityDistributions.length > 0 && { availabilityDistributions }),
          quantity: +variant.quantity,
        },
      },
    };
    preparedInventories.push(inventory);

    const newVariant = {
      active: true,
      ean: variant.ean,
      id: variant.id,
      //image_urls:imageUrls,
      pricing: variant.pricing,
      sku: variant.sku,
      quantity: variant.quantity,
      specs: variant.specs
    }
    variantShopValue.push(newVariant);
  }

  const specifications = getVariantSpecs(values.variants);

  //const imageUrls = values?.images?.map(image => image.url);

  const inventoryGroupItem = {
    aspects: aspects,
    inventoryItemGroupKey: values.sku,
    title: values.name.toString().substring(0, 80),
    description: values.description.toString(),
    variantSKUs: values.variants.map(variant => variant.sku),
    variesBy: { ...(aspectsImageVariesBy && aspectsImageVariesBy), specifications: specifications },
    ...(values.subtitle && { subtitle: values.subtitle }),
    //...(values.images && { imageUrls }),
    ...(values.videoIds && { videoIds: values.videoIds }),
  };

  const shopValue = {
    pricing: variants.map(variant => variant.pricing.gross_price).sort((a, b) => a - b)[0],
    quantity: variantShopValue.reduce((a, b) => a + +b.quantity, 0),
    name: name,
    variants: variantShopValue,
  };

  const preparedInventory = {
    isGrouped: true,
    shopValue: shopValue,
    variantInventories: preparedInventories,
    inventoryGroupItem: inventoryGroupItem,
  }

  return preparedInventory;

};

export function prepareEbayInventoryListing({ values, aspects, aspectsImageVariesBy, productId }) {

  const errors = checkEbayInventorySave(values);
  if (errors.length > 0) {
    return [null, errors];
  }

  let preparedInventory = {};
  if (isArray(values.variants) && values.variants.length > 0) {
    preparedInventory = createEbayProductVariant(values, aspects, aspectsImageVariesBy, productId);
  } else {
    preparedInventory = createEbayProduct(values, aspects, productId);
  }
  return [preparedInventory, null];
}
