import React from "react";
import { Button, Modal, Col, Row } from "react-bootstrap";
import { SVFormControl } from "_metronic/_partials/controls";
import { bicRegExp, ibanRegExp, maxValidation } from '_metronic/_helpers';
import { FormattedMessage, useIntl } from "react-intl";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";



const INPUTS = [
  {
    id: "bank_name",
    name: "name",
    label: "BANK.NAME",
    size: 12
  },
  {
    name: "iban",
    label: "BANK.IBAN",
  },
  {
    name: "bic",
    label: "BANK.BIC",
  },
];


export function BanksEditForm({ handleSubmit, initialState, onHide, bank }) {

  const { formatMessage: intl } = useIntl();

  const BankEditSchema = Yup.object().shape({
    name: Yup.string()
      .max(100, maxValidation("100"))
      .required(intl({ id: "BANK.NAME_REQUIRED" })),
    iban: Yup.string()
      .max(50, maxValidation("50"))
      .matches(ibanRegExp, intl({ id: "BANK.IBAN_MATCHES" }))
      .required(intl({ id: "BANK.IBAN_REQUIRED" }))
      .test("match", intl({ id: "BANK.IBAN_ALREADY_USED" }), (value) => {
        if (bank.length > 1) {
          return !bank.some(item => item.iban === value);
        }
        return true;
      }),
    bic: Yup.string()
      .matches(bicRegExp, intl({ id: "BANK.BIC_MATCHES" }))
      .required(intl({ id: "BANK.BIC_REQUIRED" })),
  });


  return (
    <Formik enableReinitialize={true} initialValues={initialState} validationSchema={BankEditSchema} onSubmit={handleSubmit}>
      <Form>

        <Modal.Body>
          <Row>
            {INPUTS.map((item, i) => (
              <Col lg={item.size ?? "6"} key={i}>
                <Field component={SVFormControl}
                  {...{
                    ...(item.id && { id: item.id }),
                    name: item.name,
                    label: intl({ id: item.label }),
                    withFeedbackLabel: true,
                    feedbackLabel: true,
                  }}
                />
              </Col>
            ))}
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="light" onClick={onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant='primary' type="submit" className="ml-2" id="btn_add_modal">
            <FormattedMessage id="GENERAL.ADD" />
          </Button>
        </Modal.Footer>

      </Form>
    </Formik>
  );
}