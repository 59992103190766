import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { useProductEditUIContext } from '../../../_context/ProductEditUIContext';
import { ProductActions } from 'app/modules/products/_redux/actions';
import { SVFormControl } from '_metronic/_partials/controls';
import { FormattedMessage, useIntl } from 'react-intl';
import { CloseButton } from '_metronic/_partials';
import { Field, useFormikContext } from 'formik';
import { Icon } from '_metronic/_icons';
import { Portal } from 'react-portal';



export function ProductKeysAddDialog({ productId }) {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();
  const { values: { key }, setFieldValue } = useFormikContext();

  const UIContext = useProductEditUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showKeysAddDialog,
    onHide: UIContext.handleCloseKeysAddDialog,
  }), [UIContext.showKeysAddDialog, UIContext.handleCloseKeysAddDialog]);

  const onHideCallback = () => {
    UIProps.onHide();
  };

  const handleSave = () => {
    if (key) {
      productId
        ? dispatch(ProductActions.addProductKeys(productId, [key]))
        : dispatch(ProductActions.addProductKeysSuccess(null, [key]));
    }
    onHideCallback();
    setFieldValue('key', '');
  };


  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={UIProps.show} onHide={onHideCallback} size="md" backdrop="static" keyboard={false} centered>

        <Modal.Header>
          <Modal.Title>
            <Icon.Password className="mr-3" />
            <FormattedMessage id="PRODUCT.ADD_NEW_KEY" />
          </Modal.Title>
          <CloseButton onClick={onHideCallback} />
        </Modal.Header>

        <Modal.Body className="overlay overlay-block cursor-default">

          <Row>
            <Col lg="12" key="key">
              <Field component={SVFormControl}
                {...{
                  name: "key",
                  label: intl({ id: "PRODUCT.KEYS" }),
                  required: true,
                  withFeedbackLabel: true,
                  feedbackLabel: true,
                }}
              />
            </Col>
          </Row>

        </Modal.Body>

        <Modal.Footer>
          <Button variant='light' onClick={onHideCallback} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant='primary' className="ml-2" onClick={handleSave} disabled={!key} id="btn_save_modal">
            <FormattedMessage id="GENERAL.SAVE" />
          </Button>
        </Modal.Footer>

      </Modal>
    </Portal>
  );
}
