import React, { useEffect, useMemo, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { ReportActions } from "../_redux/actions";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "_metronic/_partials/controls";
import { FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";
import { documentContents } from "app/modules/documents/GenerateDocument";
import { OrderListTable } from "./order-list-table/OrderListTable";
import { OrderListFilter } from "./order-list-table/OrderListFilter";
import { UserGuideHelper } from "app/user-guides/UserGuideHelper";
import { BlankMessage } from "_metronic/_partials/components";
import { ModuleRoutes } from "constants/moduleRoutes";
import { Icon } from "_metronic/_icons";
import { useOrderListUIContext } from "../_context/OrderListUIContext";
// import OrderListSelect from "./OrderListSelect";



export default function OrderListCard() {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const { loading, entities, totalCount, count } = useSelector(state => ({
    loading: state.reports.loading,
    entities: state.reports.entities,
    totalCount: state.reports.totalCount,
    count: state.reports.count,
  }), shallowEqual);

  const UIContext = useOrderListUIContext();
  const UIProps = useMemo(() => ({
    queryParams: UIContext.queryParams,
    setPaginationQuery: UIContext.setPaginationQuery,
    setSearchQuery: UIContext.setSearchQuery,
    setOrderQuery: UIContext.setOrderQuery,
    setFilterQuery : UIContext.setFilterQuery,
    dateRange: UIContext.dateRange,
  }), [UIContext]);


  useEffect(() => {
    let delayDebounceFn;
    if (UIProps.queryParams?.search) {
      delayDebounceFn = setTimeout(() => {
        dispatch(ReportActions.getOrderList(UIProps.dateRange, UIProps.queryParams));
      }, 350);
    } else {
      dispatch(ReportActions.getOrderList(UIProps.dateRange, UIProps.queryParams));
    };

    return () => {
      clearTimeout(delayDebounceFn)
    };
  }, [dispatch, UIProps.dateRange, UIProps.queryParams]);


  const btnRef = useRef();
  const handleSubmit = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const orderModel = documentContents.find(item => item.type === 'orders');
  const instructions = intl({ id: 'REPORT.BLANK_MESSAGE' }, { ordersUrl: ModuleRoutes.DOCUMENT_NEW_FN(orderModel?.type) })

  return (
    <Card className="card-height">

      <CardHeader
        icon={<Icon.Order />}
        title={<FormattedMessage id='REPORT.ORDER_LIST.CARD_TITLE' />}
        info={<UserGuideHelper />}
        className="display-5"
        id='title_order_list'
      >
        <CardHeaderToolbar>
          {/* <OrderListSelect /> */}
          {entities?.length !== 0 && !loading &&
            <Button variant='primary' className="font-weight-bold svg-icon svg-icon-sm  ml-auto" onClick={handleSubmit}>
              <Icon.CloudDownload />
              <span className="d-none d-sm-inline ml-2">
                <FormattedMessage id='REPORT.EXPORT_TO_CSV' />
              </span>
            </Button>}
        </CardHeaderToolbar>

      </CardHeader>

      <CardBody>

        <OrderListFilter UIProps={UIProps} />
        {count === 0 && !loading && UIProps.queryParams.search === ''
          ? <BlankMessage
            icon={<Icon.Order />}
            title={<FormattedMessage id='DOCUMENT.BLANK_MESSAGE_TITLE' values={{ value: intl({ id: orderModel?.plural }) }} />}
            message={instructions.split("#").map((item, i) => item.includes("href=") ? <span key={i}><FormattedHTMLMessage id={item} values={{ a: chunks => <a href='/'>{chunks}</a> }} /></span> : <span key={i}>{item}</span>)}
            className='min-50-height'
            loading={loading}
          />
          :
          <OrderListTable
            entities={entities}
            loading={loading}
            btnRef={btnRef}
            UIProps={UIProps}
            totalCount={totalCount}
          />
        }
      </CardBody>

    </Card>
  )
}
