import React, { useMemo } from 'react';
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import { useSupplierUIContext } from '../../_context/SupplierUIContext';
import { FormattedMessage } from 'react-intl';
import { BankTable } from './BankTable';
import { Button } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';



export function BankCard() {

  // Address UI Context
  const UIContext = useSupplierUIContext();
  const UIProps = useMemo(() => ({
    openNewBankDialog: UIContext.openNewBankDialog,
    bank: UIContext.bank,
  }), [UIContext.openNewBankDialog, UIContext.bank]);


  return (
    <Card className="gutter-b">

      <CardSubHeader icon={<Icon.Bank />} title={<FormattedMessage id="GENERAL.BANK_DETAILS" />}>
        {UIProps.bank.length < 10 && (
          <Button variant='outline-primary' size="sm" className="font-weight-bold svg-icon svg-icon-sm" onClick={() => UIProps.openNewBankDialog()} id='btn_create'>
            <Icon.Plus />
            <span className="d-none d-sm-inline ml-2">
              <FormattedMessage id="GENERAL.BANK.CREATE" />
            </span>
          </Button>
        )}
      </CardSubHeader>

      <CardBody className="pt-0 px-0">
        <BankTable />
      </CardBody>
    </Card>
  );
}
