import React, { createContext, useState, useContext } from 'react';


const DashboardUIContext = createContext();
export const DashboardUIConsumer = DashboardUIContext.Consumer;
export const useDashboardUIContext = () => useContext(DashboardUIContext);



export function DashboardUIProvider({ children }) {

  const toggleHandler = () => { setToggle(!toggle); };
  const [toggle, setToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dateRange, setDateRange] = useState([])

  const changeDateRange = date => {
    setDateRange(date);
  };



  //* Product details dialog
  const [productId, setProductId] = useState()
  const [showProductDetailsDialog, setShowProductDetailsDialog] = useState(false);
  const openProductDetailsDialog = id => {
    setProductId(id);
    setShowProductDetailsDialog(true);
  };
  const closeProductDetailsDialog = () => {
    setProductId(undefined);
    setShowProductDetailsDialog(false);
  };
  

  //* Customer details dialog
  const [customerId, setCustomerId] = useState()
  const [showCustomerDetailsDialog, setShowCustomerDetailsDialog] = useState(false);
  const openCustomerDetailsDialog = id => {
    setCustomerId(id);
    setShowCustomerDetailsDialog(true);
  };
  const closeCustomerDetailsDialog = () => {
    setCustomerId(undefined);
    setShowCustomerDetailsDialog(false);
  };


  const value = {
    isLoading,
    toggle,
    toggleHandler,
    setIsLoading,
    changeDateRange,
    dateRange,

    productId,
    showProductDetailsDialog,
    closeProductDetailsDialog,
    openProductDetailsDialog,

    customerId,
    showCustomerDetailsDialog,
    closeCustomerDetailsDialog,
    openCustomerDetailsDialog
  };

  return (
    <DashboardUIContext.Provider value={value}>
      {children}
    </DashboardUIContext.Provider>
  );
}
