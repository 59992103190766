import React from 'react';
import { isArray, isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { eanFunctions, utils } from '_metronic/_helpers';



export const searchProperties = [
  'name',
  'sku',
  'variants.sku',
  'variants.ean',
  'identities.ean',
  'pricing.purchase_price',
  'identities.barcode',
  'identities.gtin',
  'identities.isbn',
];

export const productQueryFields = [
  'id',
  'name',
  'sku',
  'identities.ean',
  'pricing.net_price',
  'pricing.gross_price',
  'variants',
  'variantOptionen',
  'features',
  'quantity',
  //'short_description',
  'description',
  'identities.mpn',
  'identities.brand',
  'condition',
  'dimensions.height',
  'dimensions.width',
  'dimensions.length',
  'dimensions.weight',
];


export const getVariantOptionsKeysAndValues = variantOptionen => {

  if (!variantOptionen && isEmpty(variantOptionen)) {
    return [];
  }
  return Object.entries(variantOptionen)
    .map(([key, value]) => {
      if (value && value.length > 0) {
        return { key, value };
      }
      return null;
    })
    .filter(item => item !== null);
};


export const getVariantOptionsColumns = variantOptionen => {

  const variantOptions = getVariantOptionsKeysAndValues(variantOptionen);
  if (variantOptions.length > 0) {
    return variantOptions.map(option => ({
      dataField: option.key,
      text: option.value,
      formatter: (cell, row) => {
        return row[option.key];
      },
    }));
  }
  return [];
};


export const getAspectRows = ({
  name = '',
  nameIsLocalized = false,
  isRequired = false,
  options = null,
  mode = 'FREE_TEXT',
  dataType = 'string',
  value = '',
  id = utils.uuidv4(),
}) => {
  return {
    id,
    isRequired,
    name,
    nameIsLocalized,
    options,
    value,
    mode,
    dataType,
  };
};


export const checkKauflandInventorySync = values => {

  const errors = [];
  const createError = (field, message, tab, required = true) => {
    errors.push({ field, message, tab, required });
  };
  const { images, variants, name, condition, quantity, description, identities, pricing /* , variantsdescription */ } = values;
  const variant = variants.length > 0 ? true : false;

  if (variant) {
    const isEan = variants.filter((variant) => !eanFunctions(variant?.ean)).map((variant) => variant.sku);
    const variantFilter = variants.filter((a) => a.quantity !== 0);
    isEan.length > 0 &&
      createError(
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.IDENTITIES.LINK" values={{ name: 'EAN' }} />,
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.IDENTITIES" values={{ name: `EAN (SKU: ${isEan.join(', ')})` }} />,
        'variant'
      );

    variantFilter.length === 0 &&
      createError(
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.QUANTITY.LINK" />,
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.QUANTITY_VARIANT" />,
        'variant'
      );

    (variantFilter.length !== 0
      ? variantFilter?.every(a => a.pricing.gross_price < 1)
      : variants.every(a => a.pricing.gross_price < 1)) &&
      createError(
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.PRICING.LINK" />,
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.PRICING" />,
        'variant'
      );
  } else {
    // FIXME: ean required
    !identities?.ean &&
      createError(
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.IDENTITIES.LINK" values={{ name: 'EAN' }} />,
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.IDENTITIES" values={{ name: 'EAN' }} />,
        'general'
      )

    !eanFunctions(identities?.ean) &&
      createError(
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.IDENTITIES.LINK" values={{ name: 'EAN' }} />,
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.IDENTITIES" values={{ name: 'EAN' }} />,
        'general'
      )

    pricing.gross_price < 1 &&
      createError(
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.PRICING.LINK" />,
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.PRICING" />,
        'general'
      );

    quantity < 1 &&
      createError(
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.QUANTITY.LINK" />,
        <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.QUANTITY" />,
        variant ? 'variants' : 'general'
      );
  }

  if (images?.length === 0)
    createError(
      <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.PICTURE.LINK" />,
      <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.PICTURE" />,
      'pictures'
    );
  if (!name)
    createError(
      <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.NAME.LINK" />,
      <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.NAME" />,
      'general'
    );
  if (!condition)
    createError(
      <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.CONDITION.LINK" />,
      <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.CONDITION" />,
      'general'
    );

  if (!description)
    createError(
      <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.DESCRIPTION.LINK" />,
      <FormattedMessage id="PRODUCT_LISTINGS.UI_HELPERS.EBAY.ALERT_DIALOG.DESCRIPTION" />,
      'description'
    );

  return errors;
};


export function prepareKauflandInventoryListing({ values, aspects }) {
  const { identities, dimensions, condition, quantity, images, description, name } = values;
  const isDimenishing = dimensions?.height > 0 && dimensions?.width > 0 && dimensions?.length > 0;
  const imageUrls = images?.map(image => image.url)
  return {
    product: {
      title: name.toString().substring(0, 80),
      aspects,
      description: description.toString().substring(0, 4000),
      mpn: identities?.mpn ? identities?.mpn.toString().substring(0, 80) : 'nicht zutreffend',
      ...(isArray(images) && images.length > 0 && { imageUrls }),
      ...(aspects['Hersteller'] && { Hersteller: aspects['Hersteller'][0] }),
      ...(identities?.ean && { ean: [identities?.ean] }),
      ...(identities?.isbn && { isbn: [identities?.isbn] }),
      // ...(identities?.epid && { epid: identities?.epid }),
      ...(identities?.subtitle && { subtitle: identities?.subtitle }),
      ...(identities?.upc && { upc: [identities?.upc] }),
    },
    condition,
    ...(isDimenishing &&
      +dimensions?.weight > 0 && {
      packageWeightAndSize: {
        dimensions: {
          height: +dimensions?.height,
          length: +dimensions?.length,
          width: +dimensions?.width,
          unit: 'CENTIMETER',
        },
        weight: {
          value: dimensions?.weight ?? 2,
          unit: 'KILOGRAM',
        },
        // packageType: 'MAILING_BOX',
      },
    }),
    availability: {
      shipToLocationAvailability: {
        quantity: +quantity,
      },
    },
  };
}