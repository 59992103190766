import React from "react";
import { Icon } from "_metronic/_icons";


export const iconIndex = [
  {
    name: "Salevali",
    type: "dashboard",
    icon: <Icon.Salevali />
  },
  {
    name: "Dashboard",
    type: "dashboard",
    icon: <Icon.Dashboard />
  },
  {
    name: "Document",
    type: "dashboard",
    icon: <Icon.Document />
  },
  {
    name: "Order",
    type: "dashboard",
    icon: <Icon.Order />
  },
  {
    name: "Admin",
    type: "dashboard",
    icon: <Icon.Admin />
  },
  {
    name: "Support",
    type: "dashboard",
    icon: <Icon.Support />
  },
  {
    name: "Catalog",
    type: "dashboard",
    icon: <Icon.Catalog />
  },
  {
    name: "Connection",
    type: "dashboard",
    icon: <Icon.Connection />
  },
  {
    name: "Customer",
    type: "dashboard",
    icon: <Icon.Customer />
  },
  {
    name: "Supplier",
    type: "dashboard",
    icon: <Icon.Supplier />
  },
  {
    name: "Shop",
    type: "dashboard",
    icon: <Icon.Shop />
  },
  {
    name: "Settings",
    type: "dashboard",
    icon: <Icon.Settings />
  },
  {
    name: "ImportExport",
    type: "dashboard",
    icon: <Icon.ImportExport />
  },
  {
    name: "Logout",
    type: "dashboard",
    icon: <Icon.Logout />
  },
  {
    name: "MenuDoubleLeft",
    type: "dashboard",
    icon: <Icon.MenuDoubleLeft />
  },
  {
    name: "FlagGermany",
    type: "flag",
    icon: <Icon.FlagGermany className="rounded" />
  },
  {
    name: "FlagUnitedStates",
    type: "flag",
    icon: <Icon.FlagUnitedStates className="rounded" />
  },
  {
    name: "FlagTurkey",
    type: "flag",
    icon: <Icon.FlagTurkey className="rounded" />
  },
  {
    name: "BrowserChrome",
    type: "browser",
    icon: <Icon.BrowserChrome />
  },
  {
    name: "BrowserEdge",
    type: "browser",
    icon: <Icon.BrowserEdge />
  },
  {
    name: "BrowserFirefox",
    type: "browser",
    icon: <Icon.BrowserFirefox />
  },
  {
    name: "BrowserSafari",
    type: "browser",
    icon: <Icon.BrowserSafari />
  },
  {
    name: "Phone",
    type: "device",
    icon: <Icon.Phone />
  },
  {

    name: "Tablet",
    type: "device",
    icon: <Icon.Tablet />
  },
  {
    name: "Pc",
    type: "device",
    icon: <Icon.Pc />
  },
  {
    name: "Globe2",
    type: "device",
    icon: <Icon.Globe2 />
  },
  {
    name: "AmazonLong",
    type: "company",
    icon: <Icon.AmazonLong width="85%" />
  },
  {
    name: "Amazon",
    type: "company",
    icon: <Icon.Amazon />
  },
  {
    name: "AmazonPrime",
    type: "company",
    icon: <Icon.AmazonPrime />
  },
  {
    name: "Check24Long",
    type: "company",
    icon: <Icon.Check24Long width="85%" />
  },
  {
    name: "Check24",
    type: "company",
    icon: <Icon.Check24 />
  },
  {
    name: "DeutschePostLong",
    type: "company",
    icon: <Icon.DeutschePostLong width="85%" />
  },
  {
    name: "DeutschePost",
    type: "company",
    icon: <Icon.DeutschePost className="rounded" />
  },
  {
    name: "DhlLong",
    type: "company",
    icon: <Icon.DhlLong width="85%" />
  },
  {
    name: "DhlNewLong",
    type: "company",
    icon: <Icon.DhlNewLong width="85%" />
  },
  {
    name: "Dhl",
    type: "company",
    icon: <Icon.Dhl className="rounded" />
  },
  {
    name: "DhlNew",
    type: "company",
    icon: <Icon.DhlNew className="rounded" />
  },
  {
    name: "DhlWarenpost",
    type: "company",
    icon: <Icon.DhlWarenpost className="rounded" />
  },
  {
    name: "DhlWarenpostLong",
    type: "company",
    icon: <Icon.DhlWarenpostLong width="85%" />
  },
  {
    name: "DhlNewWarenpost",
    type: "company",
    icon: <Icon.DhlNewWarenpost className="rounded" />
  },
  {
    name: "DhlNewWarenpostLong",
    type: "company",
    icon: <Icon.DhlNewWarenpostLong width="85%" />
  },
  {
    name: "DpdLong",
    type: "company",
    icon: <Icon.DpdLong width="85%" />
  },
  {
    name: "Dpd",
    type: "company",
    icon: <Icon.Dpd />
  },
  {
    name: "EbayLong",
    type: "company",
    icon: <Icon.EbayLong width="85%" />
  },
  {
    name: "Ebay",
    type: "company",
    icon: <Icon.Ebay />
  },
  {
    name: "EtsyLong",
    type: "company",
    icon: <Icon.EtsyLong width="85%" />
  },
  {
    name: "Etsy",
    type: "company",
    icon: <Icon.Etsy />
  },
  {
    name: "GlsLong",
    type: "company",
    icon: <Icon.GlsLong width="85%" />
  },
  {
    name: "Gls",
    type: "company",
    icon: <Icon.Gls className="rounded" />
  },
  {
    name: "HoodLong",
    type: "company",
    icon: <Icon.HoodLong width="85%" />
  },
  {
    name: "Hood",
    type: "company",
    icon: <Icon.Hood />
  },
  {
    name: "KauflandLong",
    type: "company",
    icon: <Icon.KauflandLong width="85%" />
  },
  {
    name: "Kaufland",
    type: "company",
    icon: <Icon.Kaufland />
  },
  {
    name: "ManomanoLong",
    type: "company",
    icon: <Icon.ManomanoLong width="85%" />
  },
  {
    name: "Manomano",
    type: "company",
    icon: <Icon.Manomano />
  },
  {
    name: "OttoLong",
    type: "company",
    icon: <Icon.OttoLong width="85%" />
  },
  {
    name: "Otto",
    type: "company",
    icon: <Icon.Otto />
  },
  {
    name: "PrestaShopLong",
    type: "company",
    icon: <Icon.PrestaShopLong width="85%" />
  },
  {
    name: "PrestaShop",
    type: "company",
    icon: <Icon.PrestaShop />
  },
  {
    name: "ShopifyLong",
    type: "company",
    icon: <Icon.ShopifyLong width="85%" />
  },
  {
    name: "Shopify",
    type: "company",
    icon: <Icon.Shopify />
  },
  {
    name: "ShopwareLong",
    type: "company",
    icon: <Icon.ShopwareLong width="85%" />
  },
  {
    name: "Shopware6Long",
    type: "company",
    icon: <Icon.Shopware6Long width="85%" />
  },
  {
    name: "Shopware",
    type: "company",
    icon: <Icon.Shopware />
  },
  {
    name: "WoocommerceLong",
    type: "company",
    icon: <Icon.WoocommerceLong width="85%" />
  },
  {
    name: "Woocommerce",
    type: "company",
    icon: <Icon.Woocommerce />
  },
  {
    name: "Jira",
    type: "company",
    icon: <Icon.Jira />
  },
  {
    name: "PencilSquare",
    type: "general",
    icon: <Icon.PencilSquare />
  },
  {
    name: "Trash",
    type: "general",
    icon: <Icon.Trash />
  },
  {
    name: "InfoCircle",
    type: "general",
    icon: <Icon.InfoCircle />
  },
  {
    name: "Person",
    type: "general",
    icon: <Icon.Person />
  },
  {
    name: "PersonFill",
    type: "general",
    icon: <Icon.PersonFill />
  },
  {
    name: "Image",
    type: "general",
    icon: <Icon.Image />
  },
  {
    name: "ImagePlus",
    type: "general",
    icon: <Icon.ImagePlus />
  },
  {
    name: "FileText",
    type: "general",
    icon: <Icon.FileText />
  },
  {
    name: "FileEarmarkText",
    type: "general",
    icon: <Icon.FileEarmarkText />
  },
  {
    name: "Folder",
    type: "general",
    icon: <Icon.Folder />
  },
  {
    name: "FolderOpen",
    type: "general",
    icon: <Icon.FolderOpen />
  },
  {
    name: "CardText",
    type: "general",
    icon: <Icon.CardText />
  },
  {
    name: "FileTypeCsv",
    type: "general",
    icon: <Icon.FileTypeCsv />
  },
  {
    name: "Filter",
    type: "general",
    icon: <Icon.Filter />
  },
  {
    name: "EnvelopeAt",
    type: "general",
    icon: <Icon.EnvelopeAt />
  },
  {
    name: "Eye",
    type: "general",
    icon: <Icon.Eye />
  },
  {
    name: "EyeSlash",
    type: "general",
    icon: <Icon.EyeSlash />
  },
  {
    name: "Bell",
    type: "general",
    icon: <Icon.Bell />
  },
  {
    name: "BellOff",
    type: "general",
    icon: <Icon.BellOff />
  },
  {
    name: "Close",
    type: "general",
    icon: <Icon.Close />
  },
  {
    name: "Truck",
    type: "general",
    icon: <Icon.Truck />
  },
  {
    name: "CheckCircle",
    type: "general",
    icon: <Icon.CheckCircle />
  },
  {
    name: "CheckSquare",
    type: "general",
    icon: <Icon.CheckSquare />
  },
  {
    name: "BagCheck",
    type: "general",
    icon: <Icon.BagCheck />
  },
  {
    name: "ArrowRepeat",
    type: "general",
    icon: <Icon.ArrowRepeat />
  },
  {
    name: "ArrowReturnRight",
    type: "general",
    icon: <Icon.ArrowReturnRight />
  },
  {
    name: "Building",
    type: "general",
    icon: <Icon.Building />
  },
  {
    name: "PatchCheckFill",
    type: "general",
    icon: <Icon.PatchCheckFill />
  },
  {
    name: "PatchExclamationFill",
    type: "general",
    icon: <Icon.PatchExclamationFill />
  },
  {
    name: "Award",
    type: "general",
    icon: <Icon.Award />
  },
  {
    name: "ChevronLeft",
    type: "general",
    icon: <Icon.ChevronLeft />
  },
  {
    name: "ChevronDown",
    type: "general",
    icon: <Icon.ChevronDown />
  },
  {
    name: "ChevronUp",
    type: "general",
    icon: <Icon.ChevronUp />
  },
  {
    name: "ChevronRight",
    type: "general",
    icon: <Icon.ChevronRight />
  },
  {
    name: "ArrowClockWise",
    type: "general",
    icon: <Icon.ArrowClockWise />
  },
  {
    name: "Cloud",
    type: "general",
    icon: <Icon.Cloud />
  },
  {
    name: "CloudDownload",
    type: "general",
    icon: <Icon.CloudDownload />
  },
  {
    name: "CloudUpload",
    type: "general",
    icon: <Icon.CloudUpload />
  },
  {
    name: "CloudSlash",
    type: "general",
    icon: <Icon.CloudSlash />
  },
  {
    name: "ShopPlus",
    type: "general",
    icon: <Icon.ShopPlus />
  },
  {
    name: "ThreeDots",
    type: "general",
    icon: <Icon.ThreeDots />
  },
  {
    name: "Search",
    type: "general",
    icon: <Icon.Search />
  },
  {
    name: "Printer",
    type: "general",
    icon: <Icon.Printer />
  },
  {
    name: "CreditCard",
    type: "general",
    icon: <Icon.CreditCard />
  },
  {
    name: "Bookmark",
    type: "general",
    icon: <Icon.Bookmark />
  },
  {
    name: "Bookmarks",
    type: "general",
    icon: <Icon.Bookmarks />
  },
  {
    name: "BookmarkPlus",
    type: "general",
    icon: <Icon.BookmarkPlus />
  },
  {
    name: "BookmarkX",
    type: "general",
    icon: <Icon.BookmarkX />
  },
  {
    name: "Box",
    type: "general",
    icon: <Icon.Box />
  },
  {
    name: "BoxArrowUp",
    type: "general",
    icon: <Icon.BoxArrowUp />
  },
  {
    name: "BoxArrowDown",
    type: "general",
    icon: <Icon.BoxArrowDown />
  },
  {
    name: "ExclamationCircle",
    type: "general",
    icon: <Icon.ExclamationCircle />
  },
  {
    name: "ExclamationTriangle",
    type: "general",
    icon: <Icon.ExclamationTriangle />
  },
  {
    name: "Plus",
    type: "general",
    icon: <Icon.Plus />
  },
  {
    name: "List",
    type: "general",
    icon: <Icon.List />
  },
  {
    name: "Intersect",
    type: "general",
    icon: <Icon.Intersect />
  },
  {
    name: "AppIndicator",
    type: "general",
    icon: <Icon.AppIndicator />
  },
  {
    name: "PersonGear",
    type: "general",
    icon: <Icon.PersonGear />
  },
  {
    name: "Archive",
    type: "general",
    icon: <Icon.Archive />
  },
  {
    name: "CaretUpFill",
    type: "general",
    icon: <Icon.CaretUpFill />
  },
  {
    name: "CaretDownFill",
    type: "general",
    icon: <Icon.CaretDownFill />
  },
  {
    name: "SortDown",
    type: "general",
    icon: <Icon.SortDown />
  },
  {
    name: "SortDownAlt",
    type: "general",
    icon: <Icon.SortDownAlt />
  },
  {
    name: "Sliders",
    type: "general",
    icon: <Icon.Sliders />
  },
  {
    name: "GraphUp",
    type: "general",
    icon: <Icon.GraphUp />
  },
  {
    name: "GraphUpArrow",
    type: "general",
    icon: <Icon.GraphUpArrow />
  },
  {
    name: "GraphDownArrow",
    type: "general",
    icon: <Icon.GraphDownArrow />
  },
  {
    name: "Download",
    type: "general",
    icon: <Icon.Download />
  },
  {
    name: "HandUp",
    type: "general",
    icon: <Icon.HandUp />
  },
  {
    name: "ChevronDoubleLeft",
    type: "general",
    icon: <Icon.ChevronDoubleLeft />
  },
  {
    name: "ChevronDoubleRight",
    type: "general",
    icon: <Icon.ChevronDoubleRight />
  },
  {
    name: "ChevronDoubleUp",
    type: "general",
    icon: <Icon.ChevronDoubleUp />
  },
  {
    name: "ChevronDoubleDown",
    type: "general",
    icon: <Icon.ChevronDoubleDown />
  },
  {
    name: "Diagram3",
    type: "general",
    icon: <Icon.Diagram3 />
  },
  {
    name: "JustifyLeft",
    type: "general",
    icon: <Icon.JustifyLeft />
  },
  {
    name: "UpcScan",
    type: "general",
    icon: <Icon.UpcScan />
  },
  {
    name: "Tag",
    type: "general",
    icon: <Icon.Tag />
  },
  {
    name: "Pencil",
    type: "general",
    icon: <Icon.Pencil />
  },
  {
    name: "ChatSquareText",
    type: "general",
    icon: <Icon.ChatSquareText />
  },
  {
    name: "Layers",
    type: "general",
    icon: <Icon.Layers />
  },
  {
    name: "TextIndentLeft",
    type: "general",
    icon: <Icon.TextIndentLeft />
  },
  {
    name: "Cash",
    type: "general",
    icon: <Icon.Cash />
  },
  {
    name: "GeoAlt",
    type: "general",
    icon: <Icon.GeoAlt />
  },
  {
    name: "Dot",
    type: "general",
    icon: <Icon.Dot />
  },
  {
    name: "Bank",
    type: "general",
    icon: <Icon.Bank />
  },
  {
    name: "ArrowUp",
    type: "general",
    icon: <Icon.ArrowUp />
  },
  {
    name: "ArrowDown",
    type: "general",
    icon: <Icon.ArrowDown />
  },
  {
    name: "Asterisk",
    type: "general",
    icon: <Icon.Asterisk />
  },
  {
    name: "PersonBounding",
    type: "general",
    icon: <Icon.PersonBounding />
  },
  {
    name: "Toggles",
    type: "general",
    icon: <Icon.Toggles />
  },
  {
    name: "HandArrowUp",
    type: "general",
    icon: <Icon.HandArrowUp />
  },
  {
    name: "HandArrowRight",
    type: "general",
    icon: <Icon.HandArrowRight />
  },
  {
    name: "Compass",
    type: "general",
    icon: <Icon.Compass />
  },
  {
    name: "CheckCircleFill",
    type: "general",
    icon: <Icon.CheckCircleFill />
  },
  {
    name: "ExclamationCircleFill",
    type: "general",
    icon: <Icon.ExclamationCircleFill />
  },
  {
    name: "Tags",
    type: "general",
    icon: <Icon.Tags />
  },
  {
    name: "Percent",
    type: "general",
    icon: <Icon.Percent />
  },
  {
    name: "House",
    type: "general",
    icon: <Icon.House />
  },
  {
    name: "Clipboard",
    type: "general",
    icon: <Icon.Clipboard />
  },
  {
    name: "Password",
    type: "general",
    icon: <Icon.Password />
  },
  {
    name: "SearchNot",
    type: "general",
    icon: <Icon.SearchNot />
  },
  {
    name: "Reply",
    type: "general",
    icon: <Icon.Reply />
  },
  {
    name: "LeftText",
    type: "general",
    icon: <Icon.LeftText />
  },
  {
    name: "PersonExclamation",
    type: "general",
    icon: <Icon.PersonExclamation />
  },
  {
    name: "CircleSlash",
    type: "general",
    icon: <Icon.CircleSlash />
  },
  {
    name: "PersonInfo",
    type: "general",
    icon: <Icon.PersonInfo />
  },
  {
    name: "Lock",
    type: "general",
    icon: <Icon.Lock />
  },
  {
    name: "Check",
    type: "general",
    icon: <Icon.Check />
  },
  {
    name: "PersonX",
    type: "general",
    icon: <Icon.PersonX />
  },
  {
    name: "ChatLeft",
    type: "general",
    icon: <Icon.ChatLeft />
  },
  {
    name: "BanFill",
    type: "general",
    icon: <Icon.BanFill />
  },
  {
    name: "EnvelopeAtFill",
    type: "general",
    icon: <Icon.EnvelopeAtFill />
  },
  {
    name: "Clipboard2Data",
    type: "general",
    icon: <Icon.Clipboard2Data />
  },
  {
    name: "Calendar",
    type: "general",
    icon: <Icon.Calendar />
  },
  {
    name: "Save",
    type: "general",
    icon: <Icon.Save />
  },
  {
    name: "FileEarmarkCheck",
    type: "general",
    icon: <Icon.FileEarmarkCheck />
  },
  {
    name: "FileTypePdf",
    type: "general",
    icon: <Icon.FileTypePdf />
  },
  {
    name: "Copy",
    type: "general",
    icon: <Icon.Copy />
  },
  {
    name: "ShopFill",
    type: "general",
    icon: <Icon.ShopFill />
  },
  {
    name: "ShopWindow",
    type: "general",
    icon: <Icon.ShopWindow />
  },
  {
    name: "PieChart",
    type: "general",
    icon: <Icon.PieChart />
  },
  {
    name: "GraphUpNew",
    type: "general",
    icon: <Icon.GraphUpNew />
  },
  {
    name: "CircleFill",
    type: "general",
    icon: <Icon.CircleFill />
  },
  {
    name: "Dash",
    type: "general",
    icon: <Icon.Dash />
  },
  {
    name: "CurrencyExchange",
    type: "general",
    icon: <Icon.CurrencyExchange />
  },
  {
    name: "FileEarmarkArrowUp",
    type: "general",
    icon: <Icon.FileEarmarkArrowUp />
  },
  {
    name: "FileEarmarkMinus",
    type: "general",
    icon: <Icon.FileEarmarkMinus />
  },
  {
    name: "Eraser",
    type: "general",
    icon: <Icon.Eraser />
  },
  {
    name: "ToggleOn",
    type: "general",
    icon: <Icon.ToggleOn />
  },
  {
    name: "ToggleOff",
    type: "general",
    icon: <Icon.ToggleOff />
  },
  {
    name: "EnvelopeSlash",
    type: "general",
    icon: <Icon.EnvelopeSlash />
  },
  {
    name: "EnvelopeCheck",
    type: "general",
    icon: <Icon.EnvelopeCheck />
  },
]