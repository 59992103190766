import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useCategoriesUIContext } from '../_context/CategoriesUIContext';
import { SVFormControl } from '_metronic/_partials/controls';
import { CancelButton, SaveButton } from '_metronic/_partials';
import { FormattedMessage, useIntl } from 'react-intl';
import { CategoryActions } from '../_redux/actions';
import { maxValidation } from '_metronic/_helpers';
import { Formik, Form, Field } from "formik";
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Portal } from 'react-portal';
import * as Yup from "yup";



export function CategoryAdd({ id, show, onHide, level }) {

  const { formatMessage: intl } = useIntl();

  // Categories UI Context
  const UIContext = useCategoriesUIContext();
  const UIProps = useMemo(() => ({
    setIds: UIContext.setIds,
  }), [UIContext]);

  // Categories Redux state
  const dispatch = useDispatch();
  const { category, categories } = useSelector(state => ({
    loading: state.categories.loading,
    categories: state.categories.entities,
    category:
      state.categories.entities &&
      state.categories.entities.find(x => x.id === id),
  }),
    shallowEqual
  );

  // const inputRef = useRef(null);

  useEffect(() => {
    if (id == null || level == null) {
      return onHide();
    }
    // if (inputRef.current) {
    //   inputRef.current.focus();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, level]);


  const handleSubmit = (value) => {

    const categoryName = value.name;

    const parentName = categories.filter(x => x.id === id).map(x => x.name)[0];
    const siblingName = categories.filter(x => x.parent_id === id).map(x => x.name);
    const newExistingCategory = siblingName.includes(categoryName);

    if (parentName === categoryName) {
      toast.warning(intl({ id: 'CATEGORY.PARENT_NAME_EXISTS' }));
      return;
    };

    if (newExistingCategory) {
      toast.warning(intl({ id: 'CATEGORY.CATEGORY_NAME_EXISTS' }));
      return;
    };

    const categoryItems = {
      level: (+level + 1).toString(),
      name: value.name.trim(),
      path: category
        ? category.path === ''
          ? value.name
          : category.path + ' > ' + value.name
        : value.name,
      is_leaf: true,
      parent_id: id,
      ...(category?.key && { key: category.key }),
    };

    dispatch(CategoryActions.createCategory(categoryItems));

    UIProps.setIds([]);

    onHide();
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(80, maxValidation("80"))
      .trim()
      .required(intl({ id: 'GENERAL.REQUIRED' }))
      .test('not-only-spaces', 'Category name cannot consist of only spaces', function (value) {
        return value.trim() !== '';
      }),
  });


  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg" backdrop="static" keyboard={false} centered>

        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="CATEGORY.TITLE" />
          </Modal.Title>
        </Modal.Header>

        <Formik enableReinitialize={true} initialValues={{ name: '' }} validationSchema={validationSchema} onSubmit={handleSubmit}>
          <Form>

            <Modal.Body>
              <Field component={SVFormControl}
                {...{
                  name: 'name',
                  type: 'text',
                  label: intl({ id: "CATEGORY.CATEGORY_NAME" }),
                  withFeedbackLabel: true,
                  feedbackLabel: true,
                  required: true,
                }}
              />
            </Modal.Body>

            <Modal.Footer>
              <CancelButton onClick={onHide} id="modal" />
              <SaveButton id="modal" />
            </Modal.Footer>

          </Form>
        </Formik>

      </Modal>
    </Portal>
  );
}


