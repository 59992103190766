import React, { useMemo } from 'react';
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls'
import { useUserSettingsUIContext } from '../../_context/UserSettingsUIContext'
import { UserInfoDropdown } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl'
import { Button } from 'react-bootstrap'
import { Icon } from '_metronic/_icons';



export default function AccountDelete() {

  const UIContext = useUserSettingsUIContext();
  const UIProps = useMemo(() => ({
    openAccountDeleteDialog: UIContext.openAccountDeleteDialog,
  }),
    [UIContext.openAccountDeleteDialog]
  );


  return (
    <Card className='card card-stretch gutter-b'>

      <CardSubHeader
        title={<FormattedMessage id='SETTINGS.LOGIN.ACCOUNT.CARD_TITLE' />}
        icon={<Icon.PersonX />}
        info={<UserInfoDropdown
          title={<FormattedMessage id='SETTINGS.LOGIN.ACCOUNT.CARD_TITLE' />}
          description={<FormattedMessage id='USER_GUIDE.SETTINGS.LOGIN_DELETE_ACCOUNT' />} />}
      />

      <CardBody>

        <div className='d-flex justify-content-between align-items-center'>

          <div className='d-flex flex-column mr-3'>
            <span className='font-weight-bolder mb-1'>
              <FormattedMessage id='SETTINGS.LOGIN.ACCOUNT.CONTENT_TITLE' />
            </span>
            <span>
              <FormattedMessage id='SETTINGS.LOGIN.ACCOUNT.DESCRIPTION' />
            </span>
          </div>

          <Button variant='primary' size='sm' className='font-weight-bold text-nowrap' onClick={UIProps.openAccountDeleteDialog}>
            <FormattedMessage id='SETTINGS.LOGIN.DELETE_ACCOUNT' />
          </Button>

        </div>

      </CardBody>
    </Card>
  )
}
