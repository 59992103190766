import React, { useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';



function ProductItem({ pos }) {

  const combinedText = `${pos?.quantity}x ${pos?.sku ? `[${pos?.sku}]` : ""} ${pos?.title}`;
  const productName = `${combinedText.length > 50 ? combinedText.substring(0, 50) + '...' : combinedText}`

  return (
    <OverlayTrigger overlay={
      <Tooltip id="document-print-tooltip">
        <div className='text-left'>
          <div className='font-weight-bold'>{`${pos?.sku ? `[${pos?.sku}]` : "Sku not found"}`}</div>
          {pos?.id_order_unit && <div className='mb-2'>{"line Item Id: " + pos?.id_order_unit}</div>}
          <div className='border-top pt-2'>{`${pos?.quantity}x ${pos?.title}`}</div>
        </div>
      </Tooltip>
    }>
      {pos.product_url
        ? <a href={pos.product_url.toString()} className='d-block text-dark-75 text-hover-primary' target='_blank' rel="noopener noreferrer">
          {productName}
        </a>
        : <div>
          {productName}
        </div>
      }
    </OverlayTrigger>
  );
}


export function ProductColumnFormatter({ cell, row, rowIndex }) {

  const [collapse, setCollapse] = useState(false);

  if (Array.isArray(row.position) && row.position.length > 0) {
    if (row.position.length > 2) {
      return (
        <div className='pt-3'>

          {row.position.slice(0, 2).map((pos, i) => (
            <ProductItem key={i} pos={pos} />
          ))}

          <div id="collapse" className={`collapse ${collapse ? 'show' : ''}`}>
            {row.position.slice(2).map((pos, i) => (
              <ProductItem key={i} pos={pos} />
            ))}
          </div>

          <Button variant='link' onClick={() => setCollapse(prev => !prev)} className={`svg-icon-sm text-muted text-hover-primary w-75 mt-1 py-0 ${collapse ? "" : "border-top"}`} id='btn_chevron'>
            <div style={{ transform: collapse ? "" : "rotate(-180deg)", transition: "transform 333ms" }}>
              <Icon.ChevronUp />
            </div>
          </Button>

        </div>
      );
    } else {
      return row.position.map((pos, i) => (
        <ProductItem key={i} pos={pos} />
      ));
    }
  }
}