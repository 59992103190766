import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { UIContextProvider } from "./modules/_context/UIContext";
import { I18nProvider, useLang } from "_metronic/i18n";
import { LayoutSplashScreen } from "_metronic/layout";
import { Routes } from "./routes/Routes";
import moment from "moment";
import 'moment/locale/de';
import 'moment/locale/tr';



export default function App({ store, persistor, basename }) {

  //moment dil paketi değiştirme
  const locale = useLang(); // "de" | "en" | "tr"
  moment.locale(locale ?? "de");

  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Override `basename` (e.g: `homepage` in `package.json`) */}
        <BrowserRouter basename={basename}>
          {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
          {/* Provide `react-intl` context synchronized with Redux state.  */}
          <I18nProvider>
            <UIContextProvider>
              {/* Render routes with provided `Layout`. */}
              <Routes />
            </UIContextProvider>
          </I18nProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}