import React, { createContext, useContext, useState } from 'react';


const ShippingsUIContext = createContext();
export const useShippingsUIContext = () => useContext(ShippingsUIContext)


export const ShippingsUIProvider = ({ children }) => {

  const [ids, setIds] = useState([]);

  // Shipping
  const [showPrintLabel, setShowPrintLabel] = useState(false);
  const [shipperName, setShipperName] = useState(null);
  const openPrintLabelsDialog = (service) => {
    setShipperName(service);
    setShowPrintLabel(true);
  };
  const closePrintLabelsDialog = () => {
    setShipperName(null);
    setShowPrintLabel(false);
  };

  // Shipping delete modal
  const [showDeleteLabel, setShowDeleteLabel] = useState(false);
  const [deleteLabelParams, setDeleteLabelParams] = useState(null);
  const openDeleteLabelDialog = (params) => {
    setShowDeleteLabel(true);
    setDeleteLabelParams(params);
  };
  const closeDeleteLabelDialog = () => {
    setShowDeleteLabel(false);
    setDeleteLabelParams(null)
  };

  const value = {
    ids,
    setIds,

    shipperName,
    showPrintLabel,
    openPrintLabelsDialog,
    closePrintLabelsDialog,

    openDeleteLabelDialog,
    closeDeleteLabelDialog,
    showDeleteLabel,
    deleteLabelParams
  };

  return (
    <ShippingsUIContext.Provider value={value}>
      {children}
    </ShippingsUIContext.Provider>
  );
};
