import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ModuleRoutes, RELATIVE_PATH } from 'constants/moduleRoutes';
import { ShippingsUIProvider } from './_context/ShippingsUIContext';
import { ContentRoute } from '_metronic/layout';
import ShippingsPage from './ShippingsPage';



export default function Shipping() {
  return (
    <ShippingsUIProvider>

      <Switch>
        <Redirect exact={true} from={RELATIVE_PATH} to={ModuleRoutes.SHIPPING} />
        <ContentRoute path={ModuleRoutes.SHIPPING} component={ShippingsPage} />
      </Switch>

    </ShippingsUIProvider>
  );
}
