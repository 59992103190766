import React, { useEffect, useState } from 'react';
import { SVFormControl, SVReactSelect, SVFormTextarea } from '_metronic/_partials/controls';
import { paymentMethods } from '../../_context/DocumentsUIHelpers';
import { FormattedMessage, useIntl } from 'react-intl';
import { maxValidation } from '_metronic/_helpers';
import { Formik, Form, Field } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';



export function EnterPaymentAddForm({ initialValues, btnRef, savePayment, autoComplete, paymentAmountDifference, documentType }) {

  const { formatMessage: intl } = useIntl();

  const validationSchema = Yup.object().shape({
    date: Yup.date(),
    payment_method: Yup.string()
      .required(intl({ id: 'GENERAL.REQUIRED' })),
    amount: Yup.number()
      .test('min', intl({ id: 'DOCUMENT.PAYMENT.AMOUNT.VAL_MIN_TEST' }), (value) => (value > 0))
      .test('max', intl({ id: 'DOCUMENT.PAYMENT.AMOUNT.VAL_TEST' }, { restPayment: paymentAmountDifference }), (value) => (value <= paymentAmountDifference))
      .required(intl({ id: 'GENERAL.REQUIRED' })),
    notes: Yup.string()
      .max(250, maxValidation("250")),
  });

  const { orderSettings, creditSettings, invoiceSettings } = useSelector(state => ({
    creditSettings: state.settings.creditSettings,
    orderSettings: state.settings.orderSettings,
    invoiceSettings: state.settings.invoiceSettings,
  }));
  const [documentSettingsForEdit, setDocumentSettingsForEdit] = useState(orderSettings);

  useEffect(() => {
    switch (documentType) {
      case 'orders':
        setDocumentSettingsForEdit(orderSettings);
        break;
      case 'invoices':
        setDocumentSettingsForEdit(invoiceSettings);
        break;
      case 'credits':
        setDocumentSettingsForEdit(creditSettings);
        break;
      default:
        break;
    }
  }, [documentSettingsForEdit, documentType, orderSettings, creditSettings, invoiceSettings]);

  const defaultPaymentMethod = paymentMethods.find(item => item.value === documentSettingsForEdit?.default_payment?.method)?.value ?? "";

  const INPUTS = [
    {
      name: "date",
      type: 'date',
      label: 'DOCUMENT.PAYMENT_DATE',
      lg: '12',
    },
    {
      name: "payment_method",
      label: 'DOCUMENT.TABLE.COLUMN.PAYMENT_TYPE',
      options: paymentMethods,
      lg: '7',
      required: true,
      component: SVReactSelect,
    },
    {
      name: "amount",
      type: 'number',
      label: 'DOCUMENT.PAYMENT.AMOUNT_IN_CURRENCY',
      lg: '5',
      min: 0,
      step: 0.01,
      required: true,
    },
    {
      name: "notes",
      label: 'DOCUMENT.TABLE.COLUMN.NOTE',
      lg: '12',
      rows: 6,
      className: 'mb-0',
      component: SVFormTextarea,
    },
  ];


  return (
    <Formik enableReinitialize={true} initialValues={{ ...initialValues, payment_method: defaultPaymentMethod, amount: +paymentAmountDifference ?? 0 }} validationSchema={validationSchema} onSubmit={savePayment}>
      {({ values }) => (
        <Form autoComplete={autoComplete}>

          <Row>
            {INPUTS.map((item, index) => (
              <Col lg={item.lg} key={index}>
                <Field component={item.component || SVFormControl}
                  {...{
                    name: item.name,
                    type: item.type,
                    label: intl({ id: item.label }),
                    options: item.options,
                    className: item.className,
                    rows: item.rows,
                    min: item.min,
                    step: item.step,
                    withFeedbackLabel: true,
                    feedbackLabel: (item.name === 'notes' && values.notes?.length)
                      ? <span className={`${values.notes && values.notes?.length >= 250 ? 'text-danger' : values.notes && values.notes?.length >= (250 * 80 / 100) ? 'text-warning' : 'text-success'}`}>
                        <FormattedMessage id="SETTINGS.DOCUMENT.REMAINING_CHARACTER" values={{ character: 250 - values.notes?.length }} />
                      </span>
                      : true,
                    defaultValue: item.defaultValue,
                    required: item.required,
                  }}
                />
              </Col>
            ))}
          </Row>

          <button type="submit" className='d-none' ref={btnRef} />

        </Form>
      )}
    </Formik>
  );
}
