import React from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Icon } from '_metronic/_icons'
import clsx from 'clsx'



export default function SaveButton({ onClick, disabled, id, className }) {
  return (
    <Button variant='primary' type="submit" className={clsx('d-flex align-items-center font-weight-bold ml-2', className)} onClick={onClick} id={`btn_save${id ? "_" + id : ""}`} disabled={disabled}>
      <Icon.Save />
      <span className="d-none d-sm-inline ml-2">
        <FormattedMessage id="GENERAL.SAVE" />
      </span>
    </Button>
  )
}