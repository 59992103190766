export default class ActionTypes {
  static get PREFIX() {
    return '[digitalProducts]';
  }


  // Get shippings action types
  static get GET_DIGITAL_PRODUCTS() {
    return `${ActionTypes.PREFIX}GET_DIGITAL_PRODUCTS`;
  }
  static get GET_DIGITAL_PRODUCTS_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_DIGITAL_PRODUCTS_SUCCESS`;
  }
  static get GET_DIGITAL_PRODUCTS_FAIL() {
    return `${ActionTypes.PREFIX}GET_DIGITAL_PRODUCTS_FAIL`;
  }


  static get GET_CUSTOMER_DETAILS() {
    return `${ActionTypes.PREFIX}GET_CUSTOMER_DETAILS`;
  }
  static get GET_CUSTOMER_DETAILS_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_CUSTOMER_DETAILS_SUCCESS`;
  }
  static get GET_CUSTOMER_DETAILS_FAIL() {
    return `${ActionTypes.PREFIX}GET_CUSTOMER_DETAILS_FAIL`;
  }


  static get SEND_MAIL_KEY() {
    return `${ActionTypes.PREFIX}SEND_MAIL_KEY`;
  }
  static get SEND_MAIL_KEY_SUCCESS() {
    return `${ActionTypes.PREFIX}SEND_MAIL_KEY_SUCCESS`;
  }
  static get SEND_MAIL_KEY_FAIL() {
    return `${ActionTypes.PREFIX}SEND_MAIL_KEY_FAIL`;
  }


  static get DELETE_KEY_RESERVE() {
    return `${ActionTypes.PREFIX}DELETE_KEY_RESERVE`;
  }
  static get DELETE_KEY_RESERVE_SUCCESS() {
    return `${ActionTypes.PREFIX}DELETE_KEY_RESERVE_SUCCESS`;
  }
  static get DELETE_KEY_RESERVE_FAIL() {
    return `${ActionTypes.PREFIX}DELETE_KEY_RESERVE_FAIL`;
  }


  static get CLEAN_STATES_IN_STORE() {
    return `${ActionTypes.PREFIX}CLEAN_STATES_IN_STORE`;
  }

}