/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function ActionFormatter(cellContent, row, rowIndex, { openSendMailKeyDialog, openReserveDialog }) {
  return (
    <>
      <OverlayTrigger overlay={<Tooltip id="send_email-tooltip">{<FormattedMessage id="GENERAL.SEND_EMAIL" />}</Tooltip>}>
        <a className="btn btn-icon btn-hover-success btn-sm" onClick={() => openSendMailKeyDialog([{ id: row?.id, orderId: row.order_id, paymentStatus: row.email_sended_date ? 'paid' : 'open' }])} id={`btn_send_email_${row?.id}`}>
          <span className="svg-icon svg-icon-md svg-icon-success">
            <Icon.EnvelopeAt />
          </span>
        </a>
      </OverlayTrigger>

      {/*       <OverlayTrigger overlay={<Tooltip id="send_email-tooltip">{<FormattedMessage id="PRODUCT.KEYS.RESERVE_DELETE" />}</Tooltip>}>
        <a  className={`btn btn-icon btn-hover-danger btn-sm ${row.confirmation_date ? 'disabled' : ''}`} onClick={() => !row.confirmation_date && openReserveDialog([{ id: row?.id, orderId: row.order_id, confirmationDate: row.confirmation_date }])}>
          <span className="svg-icon svg-icon-md svg-icon-danger">
          <Icon.Reply />
          </span>
        </a>
      </OverlayTrigger> */}

    </>
  )
}
