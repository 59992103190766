import React from "react";
import clsx from "clsx";



export function QuantityStyle(quantity, disable) {
  return (
    <div
      type={disable ? "" : "button"}
      className={clsx('label label-xl label-inline label-rounded font-weight-bolder px-2',
        quantity === 0 ? "label-light-danger text-danger" : quantity < 10 ? "label-light-warning text-warning" : "label-light-success text-success",
        disable ? "" : "edit-table")}
    >
      <span className={`label label-xl label-dot mr-2 ${quantity === 0 ? "label-danger" : quantity < 10 ? "label-warning" : "label-success"}`}></span>
      {quantity}
    </div>
  )
}