import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useTempUserUIContext } from "../_context/TempUserUIContext";
import { AdminActions } from "app/admin/_redux/actions";
import { Spinner } from "_metronic/_partials";
import { Portal } from "react-portal";



export function TempUserDeleteDialog({ id, show, multiple, onHide }) {

  const dispatch = useDispatch();

  const UIContext = useTempUserUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    queryParams: UIContext.queryParams,
  }), [UIContext]);

  const loading = useSelector(state => state.admin.loading, shallowEqual);

  // if !id we should close modal
  useEffect(() => {
    !id && onHide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const deleteTempUserAction = () => {
    const newIds = multiple ? UIProps.ids : [id];
    dispatch(AdminActions.deleteTempUser(newIds, UIProps.queryParams));
    UIProps.setIds([]);
    onHide();
  };


  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={show} onHide={onHide} size="sm" backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered>

        <Modal.Body className="text-center line-height-md h6 py-10 mb-0">
          {multiple
            ? <>
              <FormattedMessage id="ADMIN.TEMP_USERS.DELETE_CONTENT_PLURAL_1" />
              <span className="label label-xl label-inline label-light-danger mx-1">{UIProps.ids.length}</span>
              <FormattedMessage id="ADMIN.TEMP_USERS.DELETE_CONTENT_PLURAL_2" />
            </>
            : <FormattedMessage id="ADMIN.TEMP_USERS.DELETE_CONTENT" />
          }
        </Modal.Body>
        <Modal.Footer className="flex-nowrap overflow-hidden p-0">

          <Button variant='ios' className="border-right" onClick={onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant='ios' className="text-danger" onClick={deleteTempUserAction} id="btn_delete_modal">
            <FormattedMessage id="GENERAL.DELETE" />
            <Spinner loading={loading} color="danger" />
          </Button>

        </Modal.Footer>

      </Modal>
    </Portal>
  );
}
