/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Collapse } from '@material-ui/core';
import { useStyles } from 'app/modules/settings/category/category-list/CategoryExtendedList';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import clsx from 'clsx';



export const CategoryExtendedList = ({ categories, onHideCallback }) => {

  const classes = useStyles();

  const ExtendedCollapse = ({ child, open, onHideCallback }) => {

    let nameClasses = classes[`level${child.level}`]
      ? classes[`level${child.level}`]
      : classes.level1;


    if (child.children.length === 0) {

      return (
        <Collapse key={child.id} in={open} timeout="auto" unmountOnExit>
          <div className={clsx("d-flex justify-content-between align-items-center", nameClasses)} key={child.id} id={child.level}>

            <div type="button" className='font-weight-bold text-hover-primary font-size-lg'>
              <span className='font-weight-bold ml-5'>
                {child.name}
              </span>
            </div>

            <OverlayTrigger overlay={<Tooltip id="category-document-tooltip"><FormattedMessage id="GENERAL.ADD" /></Tooltip>}>
              <a className="btn btn-icon btn-hover-primary btn-sm my-2" onClick={() => onHideCallback(child)} id={`btn_add_${child?.id}`}>
                <span className="svg-icon svg-icon-md">
                  <Icon.Plus />
                </span>
              </a>
            </OverlayTrigger>

          </div>
        </Collapse>
      )
    } else {

      return (
        <Collapse key={child.id} in={open} timeout="auto" unmountOnExit>
          <ListItemWithCollapse category={child} nameClasses={nameClasses} onHideCallback={onHideCallback} />
        </Collapse>
      );
    }
  };



  const ListItemWithoutCollapse = ({ category, onHideCallback, nameClasses }) => (
    <div className={clsx("d-flex justify-content-between align-items-center ml-17", nameClasses)} key={category.id}>

      <div type="button" className='font-weight-bold text-hover-primary font-size-lg'>
        <span className='font-weight-bold'>
          {category.name}
        </span>
      </div>

      <OverlayTrigger overlay={<Tooltip id="category-document-tooltip"><FormattedMessage id="GENERAL.ADD" /></Tooltip>}>
        <a className="btn btn-icon btn-hover-primary btn-sm my-2" onClick={() => onHideCallback(category)} id={`btn_add_${category?.name}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.Plus />
          </span>
        </a>
      </OverlayTrigger>

    </div>
  );


  const ListItemWithCollapse = ({ category, nameClasses, onHideCallback }) => {
    const [open, setOpen] = useState(false);
    return (
      <>
        <div className={clsx("d-flex justify-content-between align-items-center", nameClasses)} key={category.id} id={category.level}>

          <div type="button" className='font-weight-bold text-hover-primary font-size-lg' onClick={() => setOpen(!open)} id={`btn_open_${category?.name}`}>
            <span className="mr-2">
              {open ? <Icon.ChevronDown /> : <Icon.ChevronRight />}
            </span>
            <span className='font-weight-bold'>
              {category.name}
            </span>
          </div>

          <OverlayTrigger overlay={<Tooltip id="category-document-tooltip"><FormattedMessage id="GENERAL.ADD" /></Tooltip>}>
            <a className="btn btn-icon btn-hover-primary btn-sm my-2" onClick={() => onHideCallback(category)} id={`btn_add_${category?.name}`}>
              <span className="svg-icon svg-icon-md">
                <Icon.Plus />
              </span>
            </a>
          </OverlayTrigger>

        </div>

        {category.children?.map(child => (
          <ExtendedCollapse key={child.id} child={child} open={open} onHideCallback={onHideCallback} />
        ))}
      </>
    );
  };


  return (
    categories?.map((item, i) => (item.children.length === 0
      ? <ListItemWithoutCollapse category={item} onHideCallback={onHideCallback} nameClasses={classes.level1} key={i} />
      : <ListItemWithCollapse category={item} onHideCallback={onHideCallback} nameClasses={classes.level1} key={i} />
    ))
  );
};

