import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AuthActions } from 'app/pages/auth/_redux/actions';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import { Portal } from 'react-portal';



export default function EmailVerifyDialog({ show }) {

  const dispatch = useDispatch();
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    let timer;

    if (show && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }

    if (countdown === 0) {
      dispatch(AuthActions.logout());
    }

    return () => clearTimeout(timer);
  }, [show, countdown, dispatch]);



  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={show} backdrop="static" centered>

        <Modal.Body className='text-center p-10'>

          <Icon.CheckCircle className="icon-6x mb-6 text-success" />
          <h3 className='font-weight-bold mb-5'>
            <FormattedMessage id='SETTINGS.LOGIN.NEW_EMAIL_UPDATE.SUCCESS' />
          </h3>
          <div className="text-dark-50 mb-3">
            <FormattedMessage id='SETTINGS.LOGIN.REDIRECTING_TO_LOGIN' values={{ time: countdown }} />
          </div>
          <Button variant="link" className='font-weight-bold' onClick={() => dispatch(AuthActions.logout())}>
            <FormattedMessage id='SETTINGS.LOGIN.BACK_TO_LOGIN' />
          </Button>
        </Modal.Body>

      </Modal>
    </Portal>
  );
}
