import React, { useEffect, useState } from 'react';
import { SearchControl } from '_metronic/_partials/controls';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from 'app/hooks';



export function AmazonListingsFilter({ queryParams, setSearchQuery }) {

  const query = useQuery();
  const [search, setSearchBase] = useState(query.get('search') ?? '');

  useEffect(() => {
    setSearchBase(query.get('search') ?? '');
  }, [query]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setSearchBase(e.target.value);
  };


  return (
    <Row className="mb-3">

      <Col lg="4">
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col>

    </Row>
  );
}
