import { AuthActions } from "app/pages/auth/_redux/actions";
import { put } from "redux-saga/effects";



export function* tokenErrorHandler(error, cbAction) {
  if (error.response && error.response.status === 401) {
    yield put(AuthActions.logout());
  } else {
    yield put(cbAction);
  }
}
