import React from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import ActionTypes from "./actionTypes";



export class ShippingsActions {

  // Get shippings action types
  static getShippings = (queryParams) => {
    return {
      type: ActionTypes.GET_SHIPPINGS,
      payload: {
        queryParams
      },
    };
  };

  static getShippingsSuccess = (shippings, count) => {
    return {
      type: ActionTypes.GET_SHIPPINGS_SUCCESS,
      payload: {
        shippings,
        count
      },
    };
  };

  static getShippingsFail = () => {
    return {
      type: ActionTypes.GET_SHIPPINGS_FAIL,
    };
  };



  // Get shipping action types
  static getShipping = (shippingIds) => {
    return {
      type: ActionTypes.GET_SHIPPING,
      payload: {
        shippingIds
      },
    };
  };

  static getShippingSuccess = shipping => {
    return {
      type: ActionTypes.GET_SHIPPING_SUCCESS,
      payload: {
        shipping,
      },
    };
  };

  static getShippingFail = () => {
    return {
      type: ActionTypes.GET_SHIPPING_FAIL,
    };
  };



  // Delete shippings action types
  static deleteShipping = (shippingId) => {
    return {
      type: ActionTypes.DELETE_SHIPPING,
      payload: {
        shippingId
      },
    };
  };

  static deleteShippingSuccess = (shippingId) => {
    toast.success(<FormattedMessage id='DOCUMENT.REDUX.LABEL.DELETE_SUCCESS' />);
    return {
      type: ActionTypes.DELETE_SHIPPING_SUCCESS,
      payload: {
        shippingId
      },
    };
  };

  static deleteShippingFail = (err) => {
    toast.error(<FormattedMessage id='DOCUMENT.REDUX.LABEL.DELETE_FAIL' />);
    return {
      type: ActionTypes.DELETE_SHIPPING_FAIL,
    };
  };

  /**
  * Clear Loading Handler
  * @returns
  */
  static cleanStatesInStore = (cleanedStates) => {
    return {
      type: ActionTypes.CLEAN_STATES_IN_STORE,
      payload: {
        cleanedStates
      },
    };
  };
}
