import React, { useEffect, useState } from "react";
import { Card, CardSubHeader } from "_metronic/_partials/controls";
import { CardBody } from "_metronic/_partials/controls";
import { UserGuideHelper } from "app/user-guides/UserGuideHelper";
import { useDispatch, useSelector } from "react-redux";
import { importExportActions } from "../../_redux/actions";
import { EditErrorDialog } from "app/modules/product-listings/ebay-listings/ebay-listings-edit/EditErrorDialog";
import { ModuleRoutes } from "constants/moduleRoutes";
import { useHistory } from "react-router-dom";
import { checkImportCategory } from "./ImportCategoryHelper";
import { ImportCategoriesTable } from "./ImportCategoryTable";
import { Icon } from "_metronic/_icons";
import { BlankMessage } from "_metronic/_partials/components";
import { FormattedMessage } from "react-intl";
import { useLang } from "_metronic/i18n";



export default function ImportCategoryPreparation({ submitBtnRef }) {

  const dispatch = useDispatch();
  const history = useHistory();
  const language = useLang();
  const [openErrorDialog, setOpenErrorDialog] = useState({ show: false, errors: [] });

  const { importPreparation, isImport, checkData } = useSelector((state) => ({
    importPreparation: state.importExport.importPreparation,
    isImport: state.importExport.isImport,
    checkData: state.importExport.checkData,
  }));

  const closeErrorDialog = () => setOpenErrorDialog({ show: false, errors: [] });

  const handlerCategory = () => {

    // category create
    const categoriesCreate = [];
    for (const categoryList of importPreparation) {
      const { id, name, parent_id, level, path } = categoryList;
      const category = {
        id: id ?? "",
        name: name ?? "",
        parent_id: parent_id ?? "",
        level: level ?? "",
        path: path ?? "",
        is_leaf: true,
      };

      categoriesCreate.push(category);
    };


    const errors = checkImportCategory(importPreparation, checkData);
    if (Array.isArray(errors) && errors.length > 0) {
      setOpenErrorDialog({ show: true, errors: errors });
      return;
    };

    dispatch(importExportActions.importCategoryCreate(categoriesCreate, language))
  };


  useEffect(() => {
    if (isImport) {
      history.push(ModuleRoutes.SETTINGS_CATEGORY)
    }
    return () => {
      dispatch(importExportActions.resetFormHandler());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isImport]);


  return (
    <>
      <EditErrorDialog show={openErrorDialog.show} onHide={closeErrorDialog} errors={openErrorDialog.errors} />

      <Card className="card-height">

        <CardSubHeader
          icon={<Icon.PencilSquare />}
          title={<FormattedMessage id='IMPORT.CATEGORY.PREPARE_LIST' />}
          info={<UserGuideHelper />}
          id={'import_product'}
        />

        <CardBody>
          {importPreparation.length > 0
            ? <ImportCategoriesTable />
            : <BlankMessage
              icon={<Icon.TextIndentLeft />}
              title={<FormattedMessage id='IMPORT_EXPORT.IMPORT.BLANK_MESSAGE_TITLE' />}
              message={<FormattedMessage id='IMPORT_EXPORT.IMPORT.BLANK_MESSAGE' />}
            />}
          <button type="submit" className='d-none' ref={submitBtnRef} onClick={handlerCategory} />
        </CardBody>

      </Card>
    </>
  )
}
